<template>
    <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card card-secondary card-outline">
              <div class="card-header">
                <h3 class="card-title"><i class="fas fa-edit"></i> Purchase/Job Order</h3>

                <div class="card-tools">
                  <div>
                  </div>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="row m-0 bg-secondary disabled color-palette">
                  <div class="col-md-2">
                    <div class="btn-group mt-2 mb-2 d-flex justify-content-start">
                        <button type="button" class="btn btn-success" @click="newModal()"><i class="fa fa-plus"></i> NEW ORDER</button>
                    </div>
                  </div>
                  <div class="col-md-8 mt-2 mb-2">

                  </div>
                  <div class="col-md-2">
                    <div class="input-group input-group-md mt-2 mb-2 d-flex justify-content-end">
                      <div class="input-group-prepend">
                        <span class="input-group-text">CALENDAR YEAR</span>
                      </div>
                      <select name="type" @change="loadData(year)" v-model="year" class="form-control">
                          <option v-for="year in years" :value="year" :key="year">{{ year }}</option>
                      </select>
                    </div>
                  </div>
              </div>

              <div class="card-body table-responsive p-0">
                <table class="table responsive-table table-striped">
                  <thead>
                    <tr>
                      <th scope="col" class="pl-2">Reference Number</th>
                      <th scope="col">Date</th>
                      <th scope="col">PR Number</th>
                      <th scope="col">Supplier Name</th>
                      <th scope="col">Date Received</th>
                      <th scope="col">Delivery Date</th>
                      <th scope="col">Status</th>
                      <th scope="col">Remarks</th>
                    </tr>
                  </thead>
                  <tbody>
                      <tr v-for="pj in pojos" :key="pj.id">
                          <td data-title="Reference Number"><a style="cursor: pointer;" class="text-primary font-weight-bold" @click="editModal(pj)">{{ pj.refno }}</a></td>
                          <td data-title="Date">{{ pj.created_at | shortdaytime }}</td>
                          <td data-title="PR Number"><small>{{ pj.prnos }}</small></td>
                          <td data-title="Supplier Name">{{ pj.procsupplier.name }}</td>
                          <td data-title="Date Received">{{ pj.date_received }}</td>
                          <td data-title="Delivery Date">{{ pj.date_delivered }}</td>
                          <td data-title="Status">
                            <span v-if="pj.status == 0" class="badge badge-primary">In-preparation</span>
                            <span v-if="pj.status == 1" class="badge badge-warning">Submitted</span>
                            <span v-else-if="pj.status == 2" class="badge badge-danger">For revision</span>
                            <span v-else-if="pj.status == 3" class="badge badge-success">Completed</span>
                          </td>
                          <td data-title="Remarks">{{ pj.remarks  }}</td>
                      </tr>
                  </tbody>
                  <tbody v-if="pojos" v-show="pojos.length==0">
                      <tr><td colspan="10" style="text-align: center;">*** NO ITEMS TO DISPLAY ***</td></tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>


        <!-- Modals -->
        <div class="modal fade" id="newactionmodal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="newactionModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
                <div class="modal-content">
                    <div class="modal-header bg-warning">
                        <h5 class="modal-title text-dark" id="newactionModalLabel">Create New PO/JO</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div class="modal-body">

                        <div class="row">

                            <div class="col-sm-3">

                                <div class="form-group">
                                    <label>Description</label>
                                    <input type="text" class="form-control" v-model="form.description" :class="{ 'is-invalid': form.errors.has('description') }">
                                    <has-error :form="form" field="description"></has-error>
                                </div>

                                <div class="form-group">
                                    <label>Supplier/Winning Bidder</label>
                                    <select v-model="form.procsupplier_id" name="type" class="form-control" :class="{ 'is-invalid': form.errors.has('procsupplier_id') }">
                                    <option value="">- Select Supplier -</option>
                                    <option v-for="supp in suppliers" :key="supp.supplier.id" v-bind:value="supp.supplier.id" >{{ supp.supplier.name }}</option>
                                    </select>
                                    <has-error :form="form" field="procsupplier_id"></has-error>
                                </div>

                                <div class="form-group">
                                    <label>Delivery Period (Days)</label>
                                    <input type="text" class="form-control" v-model="form.delivery_days" :class="{ 'is-invalid': form.errors.has('delivery_days') }">
                                    <has-error :form="form" field="delivery_days"></has-error>
                                </div>

                            </div>

                            <div class="col-sm-3">

                                <div class="form-group">
                                    <label>Delivery Place</label>
                                    <input type="text" class="form-control" v-model="form.delivery_place" :class="{ 'is-invalid': form.errors.has('delivery_place') }">
                                    <has-error :form="form" field="delivery_place"></has-error>
                                </div>

                                <div class="form-group">
                                    <label>Delivery Term</label>
                                    <input type="text" class="form-control" v-model="form.delivery_term" :class="{ 'is-invalid': form.errors.has('delivery_term') }">
                                    <has-error :form="form" field="delivery_term"></has-error>
                                </div>

                                <div class="form-group">
                                    <label>Payment Term</label>
                                    <input type="text" class="form-control" v-model="form.payment_term" :class="{ 'is-invalid': form.errors.has('payment_term') }">
                                    <has-error :form="form" field="payment_term"></has-error>
                                </div>

                            </div>


                            <div class="col-sm-3">

                                <div class="form-group">
                                    <label>Date Received</label>
                                    <input type="date" id="prdate-date-input" class="form-control" v-model="form.date_received" :class="{ 'is-invalid': form.errors.has('date_received') }">
                                    <has-error :form="form" field="date_received"></has-error>
                                </div>

                                <div class="form-group">
                                    <label>Delivery Date</label>
                                    <input type="date" id="prdate-date-input" class="form-control" v-model="form.date_delivered" :class="{ 'is-invalid': form.errors.has('date_delivered') }">
                                    <has-error :form="form" field="date_delivered"></has-error>
                                </div>
                            </div>

                            <div class="col-sm-3">
                                <div class="form-group">
                                    <label>Remarks</label>
                                    <textarea class="form-control" rows="4" data-gramm="false" wt-ignore-input="true" v-model="form.remarks" :class="{ 'is-invalid': form.errors.has('remarks') }" />
                                    <has-error :form="form" field="remarks"></has-error>
                                </div>
                            </div>

                        </div>

                        <div class="card-footer">
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="d-flex justify-content-end">

                                    </div>
                                </div>
                                <div class="col-md-5">
                                    <div class="d-flex justify-content-start">

                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="d-flex justify-content-end">
                                        <button class="btn btn-primary mr-1" data-container="body" type="button" @click="savePojo()"><i class="fa fa-save"></i> Save</button>
                                        <button class="btn btn-danger" data-container="body" type="button" data-dismiss="modal" aria-label="Close"><i class="fa fa-times"></i> Close</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="editactionmodal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="editactionModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title text-dark" id="editactionModalLabel">PO# {{ form.refno }}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div class="modal-body">

                        <div class="row">

                            <div class="col-sm-3">

                                <div class="form-group">
                                    <label>Description</label>
                                    <input type="text" class="form-control" v-model="form.description" :class="{ 'is-invalid': form.errors.has('description') }">
                                    <has-error :form="form" field="description"></has-error>
                                </div>

                                <div class="form-group">
                                    <label>Supplier/Winning Bidder</label>
                                    <select v-model="form.procsupplier_id" :disabled="pojo.procprojectitems && pojo.procprojectitems.length != 0" name="type" class="form-control" :class="{ 'is-invalid': form.errors.has('procsupplier_id') }">
                                    <option value="">- Select Supplier -</option>
                                    <option v-for="supp in suppliers" :key="supp.supplier.id" v-bind:value="supp.supplier.id" >{{ supp.supplier.name }}</option>
                                    </select>
                                    <has-error :form="form" field="procsupplier_id"></has-error>
                                </div>

                                <div class="form-group">
                                    <label>Delivery Period (Days)</label>
                                    <input type="text" class="form-control" v-model="form.delivery_days" :class="{ 'is-invalid': form.errors.has('delivery_days') }">
                                    <has-error :form="form" field="delivery_days"></has-error>
                                </div>

                            </div>

                            <div class="col-sm-3">

                                <div class="form-group">
                                    <label>Delivery Place</label>
                                    <input type="text" class="form-control" v-model="form.delivery_place" :class="{ 'is-invalid': form.errors.has('delivery_place') }">
                                    <has-error :form="form" field="delivery_place"></has-error>
                                </div>

                                <div class="form-group">
                                    <label>Delivery Term</label>
                                    <input type="text" class="form-control" v-model="form.delivery_term" :class="{ 'is-invalid': form.errors.has('delivery_term') }">
                                    <has-error :form="form" field="delivery_term"></has-error>
                                </div>

                                <div class="form-group">
                                    <label>Payment Term</label>
                                    <input type="text" class="form-control" v-model="form.payment_term" :class="{ 'is-invalid': form.errors.has('payment_term') }">
                                    <has-error :form="form" field="payment_term"></has-error>
                                </div>

                            </div>


                            <div class="col-sm-3">

                                <div class="form-group">
                                    <label>Date Received</label>
                                    <input type="date" id="prdate-date-input" class="form-control" v-model="form.date_received" :class="{ 'is-invalid': form.errors.has('date_received') }">
                                    <has-error :form="form" field="date_received"></has-error>
                                </div>

                                <div class="form-group">
                                    <label>Delivery Date</label>
                                    <input type="date" id="prdate-date-input" class="form-control" v-model="form.date_delivered" :class="{ 'is-invalid': form.errors.has('date_delivered') }">
                                    <has-error :form="form" field="date_delivered"></has-error>
                                </div>

                                <div class="form-group">
                                    <label>Status</label>
                                    <select v-model="form.status" name="type" class="form-control" :class="{ 'is-invalid': form.errors.has('status') }">
                                    <option value="0">In-preparation</option>
                                    <option value="3">Completed</option>
                                    </select>
                                    <has-error :form="form" field="status"></has-error>
                                </div>
                            </div>

                            <div class="col-sm-3">
                                <div class="form-group">
                                    <label>Remarks</label>
                                    <textarea class="form-control" rows="8" data-gramm="false" wt-ignore-input="true" v-model="form.remarks" :class="{ 'is-invalid': form.errors.has('remarks') }" />
                                    <has-error :form="form" field="remarks"></has-error>
                                </div>
                            </div>

                        </div>

                        <div class="p-1">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="d-flex justify-content-end">
                                    <button class="btn btn-primary mr-1" data-container="body" type="button" @click="updatePojo()"><i class="fa fa-save"></i> Save Details</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row p-1">
                            <table class="table table-striped m-0">
                                <thead class="bg-info">
                                    <tr>
                                        <th>Office</th>
                                        <th>PR Number</th>
                                        <th>Item Description</th>
                                        <th>Unit of Measure</th>
                                        <th>Quantity</th>
                                        <th>Cost</th>
                                        <th>Total Cost</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="itm in pojo.procprojectitems" :key="itm.id">
                                        <td>{{ itm.purchaserequest.office.alias }}</td>
                                        <td>{{ itm.purchaserequest.prno }}</td>
                                        <td>{{ itm.procurementitem.description }}</td>
                                        <td>{{ itm.procurementitem.uom_name }}</td>
                                        <td>{{ itm.quantity }}</td>
                                        <td>{{ itm.bid_price / itm.quantity }}</td>
                                        <td>{{ itm.bid_price | toMoney }}</td>
                                        <td><button title="Remove" @click="removeItem(itm.id)" :disabled="form.status == 3" type="button" class="btn btn-default"><i class="fa fa-trash"></i></button></td>
                                    </tr>
                                    <tr>
                                        <td colspan="6" class="text-right"><b>TOTAL: </b></td><td colspan="2"><b>{{ getTotalAmount() | toMoney }}</b></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="card-footer">
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="d-flex justify-content-start">
                                        <button class="btn btn-success mr-1" data-container="body" type="button" @click="printModalPo()"><i class="fa fa-print"></i> Print</button>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="d-flex justify-content-start">

                                    </div>
                                </div>
                                <div class="col-md-5">
                                    <div class="d-flex justify-content-end">
                                        <button class="btn btn-primary mr-1" data-container="body" type="button" @click="addItems()"><i class="fa fa-plus"></i> Add items</button>
                                        <button class="btn btn-danger" data-container="body" type="button" data-dismiss="modal" aria-label="Close"><i class="fa fa-times"></i> Close</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade" id="actionadditemmodal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="actionadditemModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
            <div class="modal-content">
              <div class="modal-header bg-orange">
                <h5 v-if="winneritems.supplier" class="modal-title" id="actionadditemModalLabel">Select item(s) from supplier {{ winneritems.supplier.name }}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body bg-orange">
                <!--<div class="row m-0 bg-secondary disabled color-palette sticky-top">
                  <div class="col-md-4">

                  </div>
                  <div class="col-md-4 mt-2 mb-2">

                  </div>
                  <div class="col-md-4">
                    <div class="input-group input-group-md mt-2 mb-2 d-flex justify-content-end">

                    </div>
                  </div>
                </div>-->
                <div class="row m-0 table-responsive p-0" style="height: 300px;">
                    <table class="table table-head-fixed text-nowrap">
                        <thead>
                            <tr class="bg-secondary">
                                <th class="bg-secondary"><i class="fa fa-check" aria-hidden="true"></i></th>
                                <th class="bg-secondary">DESCRIPTION</th>
                                <th class="bg-secondary">PR NO.</th>
                                <th class="bg-secondary">UOM</th>
                                <th class="bg-secondary">QTY</th>
                                <th class="bg-secondary">PRICE</th>
                                <th class="bg-secondary">AMOUNT</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-if="winneritems" v-for="itms in winneritems.items" :key="itms.item.id">
                                <td><span><input v-bind:id="itms.item.id" type="checkbox" :value="itms.item.id" v-model="selected_items_form.selected_items" /></span></td>
                                <td><span>{{ itms.item.ppmpitem.procurementitem.description }}</span></td>
                                <td><span>{{ itms.prno }}</span></td>
                                <td><span>{{ itms.uom_name }}</span></td>
                                <td><span>{{ itms.item.quantity }}</span></td>
                                <td><span>{{ itms.unitCost | toMoney }}</span></td>
                                <td><span>{{ itms.price | toMoney }}</span></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
              </div>
              <div class="card-footer bg-orange">
                <button class="btn btn-primary" v-if="selected_items_form.selected_items.length !== 0" data-container="body" type="button" @click="addSelectedItems(form.id)"><i class="fa fa-save"></i> Add Selected Items</button>
                <button class="btn btn-primary" v-else data-container="body" type="button" disabled><i class="fa fa-save"></i> Add Selected Items</button>
                <button class="btn btn-primary" data-container="body" type="button" data-dismiss="modal" aria-label="Close"><i class="fa fa-times"></i> Close</button>
              </div>
            </div>
          </div>
        </div>

        <div class="modal fade" id="printmodalpo" tabindex="-1" role="dialog" aria-labelledby="printModalPoLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-header bg-success">
                <h5 class="modal-title text-dark" id="printModalPoLabel">Purchase Order (Print Preview)</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                </div>

                <div class="modal-body">
                <div id="printAppendix61">
                    <div class="card-body" style="font-family: Times New Roman;">

                    <table class="table table-borderless customtextsize">
                        <tr>
                        <td colspan="8" align="right">
                            <i style="font-size: 17px">Appendix 61</i>
                        </td>
                        </tr>
                    </table>
                    <br/>
                    <table class="table table-borderless customtextsize" style="border:2px solid #000000">
                        <tbody>
                        <tr>
                            <td colspan="2" align="center"><h2>PURCHASE ORDER</h2></td>
                        </tr>
                        <tr>
                            <td colspan="2" align="center">
                                <u><b><span style="font-size: 18px;">Technology Application & Promotion Institute</span></b></u><br/>
                                Entity Name
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <table class="table table-borderless customtextsize" style="border:2px solid #000000">
                        <tbody>
                        <tr>

                        </tr>
                        </tbody>
                    </table>
                    <table class="table table-borderless customtextsize" style="border:2px solid #000000">
                        <thead>
                            <tr>
                                <td v-if="pojo.id" style="border:2px solid #000000" colspan="3" align="left">
                                    <b>Supplier:</b> <u>{{ pojo.procsupplier.name }}</u><br/>
                                    <b>Address:</b> <u>{{ pojo.procsupplier.address }}</u><br/>
                                    <b>Contact No:</b> <u>Tel No. {{ pojo.procsupplier.tel }} / Cel No. {{ pojo.procsupplier.cel }}</u><br/>
                                    <b>TIN:</b> <u>{{ pojo.procsupplier.tin_vat }}</u>
                                </td>
                                <td v-if="pojo.id" style="border:2px solid #000000" colspan="3" align="left">
                                    <b>P.O. No.:</b> <u>{{ pojo.refno }}</u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>Date:</b> <u>{{ pojo.created_at | customdate2 }}</u><br/>
                                    <b>Mode of Procurement:</b> <u>{{ pojo.refno }}</u><br/>
                                    <b>Request No.:</b> <u>{{ pojo.prnos }}</u><br/>
                                    <b>End User:</b> <u>{{ pojo.endusers }}</u>
                                </td>
                            </tr>
                            <tr>
                                <td v-if="pojo.id" style="border:2px solid #000000" colspan="6" align="left">
                                    <b>Gentlemen:</b> Please furnish this Office the following articles subject to the terms and conditions contained herein:
                                </td>
                            </tr>
                            <tr>
                                <td v-if="pojo.id" style="border:2px solid #000000" colspan="3" align="left">
                                    <b>Place of Delivery:</b> <u>{{ pojo.delivery_place }}</u><br/>
                                    <b>Date of Delivery:</b> <u>{{ pojo.date_delivered }}</u>
                                </td>
                                <td v-if="pojo.id" style="border:2px solid #000000" colspan="3" align="left">
                                    <b>Delivery Term:</b> <u>{{ pojo.delivery_term }}</u><br/>
                                    <b>Payment Term:</b> <u>{{ pojo.payment_term }}</u>
                                </td>
                            </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td style="border:2px solid #000000;width: 130px;" align="center"><b>Stock/Property No.</b></td>
                            <td style="border:2px solid #000000" align="center"><b>Unit</b></td>
                            <td style="border:2px solid #000000;width: 400px;" align="center"><b>Item Description</b></td>
                            <td style="border:2px solid #000000" align="center"><b>Quantity</b></td>
                            <td style="border:2px solid #000000" align="center"><b>Unit Cost</b></td>
                            <td style="border:2px solid #000000" align="center"><b>Amount</b></td>
                        </tr>
                        <tr v-for="itm in pojo.procprojectitems" :key="itm.id">
                            <td style="border:2px solid #000000" align="center"></td>
                            <td style="border:2px solid #000000" align="center">{{ itm.procurementitem.uom_name }}</td>
                            <td style="border:2px solid #000000" align="left">{{ itm.procurementitem.description }}</td>
                            <td style="border:2px solid #000000" align="center">{{ itm.quantity }}</td>
                            <td style="border:2px solid #000000" align="right">{{ itm.bid_price / itm.quantity }}</td>
                            <td style="border:2px solid #000000" align="right">{{ itm.bid_price | toMoney }}</td>
                        </tr>
                        <tr class="lastchild">
                            <td colspan="6" style="border:2px solid #000000" align="center">*** NOTHING FOLLOWS *** <br/><br/><br/><br/>{{ pojo.remarks }}</td>
                        </tr>
                        <tr class="footer">
                            <td style="border:2px solid #000000" align="left" colspan="4"><small>Note to Supplier: Please provide us the company's Bank Certificate upon signing the Purchase Order</small></td>
                            <td style="border:2px solid #000000" align="right"><b>TOTAL</b></td>
                            <td v-if="!this.pojo.procprojectitems" style="border:2px solid #000000" align="right"></td><td v-else style="border:2px solid #000000" align="right"><b>{{ getTotalAmount() | toMoney }}</b></td>
                        </tr>
                        <tr class="lastchild">
                            <td colspan="6" style="border:2px solid #000000" align="left">(Total Amount in Words) <b class="mr-4">{{ getTotalAmount() | toWordsPesos | capitalize }} {{ getTotalAmount() | toWordsCentavos | capitalize }}</b></td>
                        </tr>
                        <tr>
                            <td style="border:2px solid #000000" colspan="3" align="left">
                                Conforme:<br/><br/>
                                <div style="text-align: center;">
                                __________________________________<br/>
                                Signature over Printed Name of Supplier<br/><br/>
                                ________________________<br/>
                                Date
                                </div>
                            </td>
                            <td style="border:2px solid #000000" colspan="3" align="left">
                                Very truly yours,<br/><br/>
                                <div style="text-align: center;">
                                __________________________________________<br/>
                                Signature over Printed Name of Authorized Official<br/><br/>
                                ________________________<br/>
                                Designation
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td style="border:2px solid #000000" colspan="3" align="left">
                                Fund Cluster: <u>Regular Agency Fund</u><br/>
                                Funds Available: ________________________<br/><br/>

                                ___________________________________________<br/>
                                Chief Accountant/Head of Accounting Division/Unit
                            </td>
                            <td style="border:2px solid #000000" colspan="3" align="left">
                                ORS/BURS No. : _______________________________<br/>
                                Date of the ORS/BURS: __________________________<br/><br/>

                                Amount: __________________________
                            </td>
                        </tr>

                        </tbody>
                    </table>
                    </div>
                </div>

                </div>
                <div class="card-footer">
                <button v-print="'#printAppendix61'" @click="print" class="btn btn-primary" data-container="body" type="button"><i class="fa fa-print"></i> Print</button>
                <button class="btn btn-primary" data-container="body" type="button" data-dismiss="modal" aria-label="Close"><i class="fa fa-times"></i> Close</button>
                </div>
            </div>
            </div>
        </div>



      </div>

</template>
<script>

    import Form from 'vform';
    import { VueEditor } from "vue2-editor";
    import moment from 'moment';

    export default {
        name: 'PoJo',

        components: {
            VueEditor,
        },

        data() {
          return {
            editmode: false,
            loading: false,
            emptyflag: false,
            pojos: {},
            pojo: {},
            suppliers: {},
            winneritems: {},
            poitems: {},
            selected_items_form: new Form({
              selected_items: [],
            }),
            year: new Date().getFullYear(),

            form: new Form({
                id: 0,
                refno: '',
                description: '',
                procsupplier_id: null,
                delivery_days: 0,
                delivery_place: '',
                delivery_term: '',
                payment_term: '',
                date_received: null,
                date_delivered: null,
                status: 0,
                remarks: '',
                procprojectitems: {}
            }),
        }
    },

    computed: {

        years() {
            const year = new Date().getFullYear()
            return Array.from({length: year - 2018}, (value, index) => 2019 + index)
        },

    },

    methods: {

        print(){
        var style = document.createElement('style');
        style.innerHTML = "@page{size: A4 portrait !important;margin: 25mm !important;}" +
                " html{background-color: #FFFFFF;}" +
                " body{border: solid 0px #FFFFFF;margin: 0;}";
        window.document.head.appendChild(style);
        },

        printModalPo() {
          $('#printmodalpo').modal('show');
        },

        newModal() {
          this.form.reset();
          this.form.clear();
          $('#newactionmodal').modal('show');
        },

        editModal(payload) {
          this.form.reset();
          this.form.clear();
          this.form.fill(payload);
          this.loadPojo(payload.id)
          $('#editactionmodal').modal('show');
        },

        addItems() {
            this.loadWinnerItems(this.form.procsupplier_id)
            this.selected_items_form.selected_items = [];
            $('#actionadditemmodal').modal('show');
        },

        removeItem(id) {
            swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, remove item from PO!'
            }).then((result)=>{
                if (result.value) {
                this.form.post('api/poitems-remove/'+id).then(()=>{
                    swal.fire(
                    'Removed!',
                    'Item has been removed.',
                    'success'
                    );
                    this.loadPojo(this.form.id)
                }).catch(()=>{
                    swal.fire(
                    'Failed!',
                    'There was something wrong',
                    'warning'
                    )
                });
                }
            })
        },

        getTotalAmount() {
            if (!this.pojo || !Array.isArray(this.pojo.procprojectitems)) {
                return 0.00; // Return 0 if pojo or procprojectitems is not defined
            }

            return this.pojo.procprojectitems.reduce((accum, item) => {
                return accum + (parseFloat(item.bid_price) || 0); // Safely parse bid_price, default to 0 if NaN
            }, 0.00);
        },



        updatePojo() {
          this.$Progress.start();
          this.form.put('api/pojos/'+this.form.id).then(()=>{
            Trigger.$emit('RefreshData');
            toastr.fire({
              icon: 'success',
              title: 'PO/JO details Updated'
            })
            this.$Progress.finish();
          }).catch(()=>{
            toastr.fire({
              icon: 'error',
              title: 'PR details updating failed'
            });
            this.$Progress.fail();
          });
        },

        savePojo() {
          this.$Progress.start();
          this.form.post('api/pojos').then(()=>{
            Trigger.$emit('RefreshData');
            toastr.fire({
              icon: 'success',
              title: 'New PO/JO Saved'
            })
            this.$Progress.finish();
            $('#newactionmodal').modal('hide')
          }).catch(()=>{
            toastr.fire({
              icon: 'error',
              title: 'New PO/JO saving failed'
            });
            this.$Progress.fail();
          });
        },

        addSelectedItems(pojo_id) {
            this.$Progress.start();
            this.selected_items_form.post('api/poitems-add/' + pojo_id)
            .then(()=>{
                this.loadPojo(pojo_id)
                $('#actionadditemmodal').modal('hide')
                toastr.fire({
                    icon: 'success',
                    title: 'Items added in PO'
                })
                this.$Progress.finish();
            }).catch(()=>{
                toastr.fire({
                    icon: 'error',
                    title: 'Items adding failed'
                });
                this.$Progress.fail();
            })
        },

        getCurrentDate() {
            const now = new Date();
            const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are 0-based
            const day = String(now.getDate()).padStart(2, '0');
            const year = now.getFullYear();

            return `${month}-${day}-${year}`;
        },

        loadWinnerItems(id) {
            axios.get('api/winning-bidders/' + id).then(({ data }) => (this.winneritems = data));
        },

        loadPojo(id) {
            axios.get('api/pojos/' + id).then(({ data }) => (this.pojo = data));
        },

        loadSuppliers() {
            axios.get('api/winning-bidders').then(({ data }) => (this.suppliers = data));
        },

        loadData(year) {
            axios.get('api/pojos/year/' + year).then(({ data }) => (
              this.pojos = data
            ));
        },

        loadProjItems(projid) {
            axios.get('api/proc-project-items/project/' + projid).then(({ data }) => (
              this.projitems = data
              //this.getTotalAmount()
            ));
        },

    },

    created() {
        this.loadData(this.year)
        this.loadSuppliers()

        Trigger.$on('RefreshData',() => {
            this.loadData(this.year);
        });
    },

}
</script>
