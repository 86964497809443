<template>
    <div class="container-fluid">
        

        <div class="row">
            <div class="col-md-12">
                <div class="card card-secondary card-outline" >
                <div class="card-header bg-yellow">
                    <h3 class="card-title"> LDDAP-ADA REPORTS</h3>
                </div>

                <div class="card-body">
                    <div class="row">
                        <div class="col-md-4">
                            
                            <div class="form-group">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                    <span class="input-group-text bg-default text-danger font-weight-bold">PAYMENT DATE*</span>
                                    </div>
                                    <input v-model="payment_date" @change="getReport" type="date" name="payment_date" id="dob-date-input" class="form-control" :class="{ 'is-invalid': form.errors.has('payment_date') }">
                                    <has-error :form="form" field="payment_date"></has-error>
                                </div>
                            </div>

                        </div>
                        <div class="col-md-4">

                            <div class="form-group">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                    <span class="input-group-text bg-default text-danger font-weight-bold">PAYMENT TYPE</span>
                                    </div>
                                    <select v-model="payment_type" @change="getReport" name="type" class="form-control" :class="{ 'is-invalid': form.errors.has('payment_type') }">
                                    <option value="General Fund">General Fund</option>
                                    <option value="General Fund - IC">General Fund - IC</option>
                                    <option value="MDS Trust">MDS Trust</option>
                                    <option value="MDS Trust - IC">MDS Trust - IC</option>
                                    </select>
                                    <has-error :form="form" field="status"></has-error>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
    
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <div class="card card-secondary card-outline" >
                <div class="card-header bg-yellow">
                    <h3 class="card-title"> REPORT OF ADVICE TO DEBIT ACCOUNT ISSUED
                        <span v-if="this.payment_type=='MDS Trust - IC'"> - IC</span>
                        <span v-else-if="this.payment_type=='General Fund - IC'"> - IC</span>
                    </h3>
                    <div class="card-tools">
                        <vue-excel-xlsx class="btn btn-tool"
                            :data="creditors"
                            :columns="columns"
                            :file-name="payment_date + ' ' + payment_type"
                            :file-type="'xlsx'"
                            :sheet-name="payment_date"
                            >
                            <i class="fa fa-file-excel" aria-hidden="true"></i> Export to Excel
                        </vue-excel-xlsx>
                    </div>
                    
                </div>
                <div id="printAppendix13">
                    <div class="card-body" style="font-family: Times New Roman;">
                        <table class="table table-borderless">
                            <tr>
                            <td colspan="8" align="right">
                                <i>Appendix 13</i>
                            </td>
                            </tr>
                        </table>
                        <br/><br/>
                        <table class="table table-borderless">
                            <thead>
                            <tr>
                                <td colspan="9" align="center">
                                <b>REPORT OF ADVICE TO DEBIT ACCOUNT ISSUED
                                    <span v-if="this.payment_type=='MDS Trust - IC'"> - IC</span>
                                    <span v-else-if="this.payment_type=='General Fund - IC'"> - IC</span>
                                </b><br/>
                                <b>Period Covered: <u>{{ payment_date | customdate }}</u></b><br/>
                                <b>LDDAP-ADA<br/>
                                    <span v-if="this.payment_type=='MDS Trust'">MDS Trust</span>
                                    <span v-else-if="this.payment_type=='MDS Trust - IC'">MDS Trust - IC</span>
                                    <span v-else-if="this.payment_type=='General Fund'">General Fund</span>
                                    <span v-else-if="this.payment_type=='General Fund - IC'">General Fund - IC</span>
                                </b>
                                </td>
                            </tr>                 
                            </thead>
                        </table>

                        <table class="table table-borderless" style="border:2px solid #dee2e6" width="100%">
                            <thead>
                             <tr>
                                <td colspan="6">
                                <b>
                                    Entity Name : <u>Technology Application & Promotion Institute</u><br/>
                                    Fund Cluster : 
                                    <span v-if="this.payment_type=='MDS Trust'"><u>07-308601</u></span>
                                    <span v-else-if="this.payment_type=='MDS Trust - IC'"><u>07-308601</u></span>
                                    <span v-else-if="this.payment_type=='General Fund'"><u>01-101101</u></span>
                                    <span v-else-if="this.payment_type=='General Fund - IC'"><u>01-101101</u></span>
                                    <br/>
                                    Bank Name/Account No. :
                                    <span v-if="this.payment_type=='MDS Trust'"><u>2182-9006-40</u></span>
                                    <span v-else-if="this.payment_type=='MDS Trust - IC'"><u>2182-9006-40</u></span>
                                    <span v-else-if="this.payment_type=='General Fund'"><u>2182-9000-80</u></span>
                                    <span v-else-if="this.payment_type=='General Fund - IC'"><u>2182-9000-80</u></span>
                                </b>
                                </td>
                                <td colspan="5" align="right">
                                <b>
                                <br/>
                                Report No. : 
                                <input style="border: 0;
                                            width: 80px;
                                            outline: 0;
                                            background: transparent;
                                            border-bottom: 1px solid black;" 
                                    type="text" name="report_no" :maxlength="11" autocomplete="off" />
                                <br/>
                                Sheet No. :
                                <input value="" style="border: 0;
                                            width: 40px;
                                            outline: 0;
                                            background: transparent;
                                            border-bottom: 1px solid black;" 
                                    type="number" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" name="report_no" maxlength="2" autocomplete="off" />
                                </b>
                                </td>
                            </tr>
                            <tr>
                                <td align="center" colspan="2" style="border:2px solid #dee2e6">
                                    <b>Check</b>
                                </td>
                                <td align="center" rowspan="2" style="border:2px solid #dee2e6">
                                    <b>DV/Payroll No.</b>
                                </td>
                                <td align="center" rowspan="2" style="border:2px solid #dee2e6">
                                    <b>ORS/BURS No.</b>
                                </td>
                                <td align="center" rowspan="2" style="border:2px solid #dee2e6">
                                    <b>Responsibility<br/>Center Code</b>
                                </td>
                                <td align="center" rowspan="2" style="border:2px solid #dee2e6">
                                    <b>Payee</b>
                                </td>
                                <td align="center" rowspan="2" style="border:2px solid #dee2e6">
                                    <b>UACS<br/>Object Code</b>
                                </td>
                                <td align="center" rowspan="2" style="border:2px solid #dee2e6">
                                    <b>Nature of Payment</b>
                                </td>
                                <td align="center" rowspan="2" style="border:2px solid #dee2e6">
                                    <b>Amount</b>
                                </td>
                            </tr>
                            <tr>
                                <td align="center" style="border:2px solid #dee2e6">
                                    Date
                                </td>
                                <td align="center" style="border:2px solid #dee2e6">
                                    Serial No.
                                </td>
                            </tr>
                            </thead>
                            <tbody v-for="creditor in creditors" :key="creditor.id">
                                <tr >
                                    <td align="center" style="border:2px solid #dee2e6">{{ creditor.payment_date }}</td>
                                    <td align="center" style="border:2px solid #dee2e6">{{ creditor.check_no }}</td>
                                    <td align="center" style="border:2px solid #dee2e6"><span v-for="ci in creditor.creditorinfos" :key="ci.id">{{ ci.dv_no }}<br/></span></td>
                                    <td align="center" style="border:2px solid #dee2e6"><span v-for="ci in creditor.creditorinfos" :key="ci.id">{{ ci.ors_no }}<br/></span></td>
                                    <td align="center" style="border:2px solid #dee2e6"><span v-for="ci in creditor.creditorinfos" :key="ci.id">{{ ci.responsibility_center }}<br/></span></td>
                                    <td align="left" style="border:2px solid #dee2e6">{{ creditor.acct_name }}</td>
                                    <td align="center" style="border:2px solid #dee2e6"><span v-for="ci in creditor.creditorinfos" :key="ci.id">{{ ci.uacs }}<br/></span></td>
                                    <td align="left" style="border:2px solid #dee2e6">{{ creditor.nature }}</td>
                                    <td align="center" style="border:2px solid #dee2e6">{{ creditor.net }}</td>
                                </tr>
                            </tbody>
                            <tbody>
                                <tr >
                                    <td align="center" style="border:2px solid #dee2e6"></td>
                                    <td align="center" style="border:2px solid #dee2e6"></td>
                                    <td align="center" style="border:2px solid #dee2e6"></td>
                                    <td align="center" style="border:2px solid #dee2e6"></td>
                                    <td align="center" style="border:2px solid #dee2e6"></td>
                                    <td align="center" style="border:2px solid #dee2e6"></td>
                                    <td align="center" style="border:2px solid #dee2e6"></td>
                                    <td align="right" style="border:2px solid #dee2e6">TOTAL:</td>
                                    <td align="center" style="border:2px solid #dee2e6"><b>{{ total_net(this.creditors) | toMoney}}</b></td>
                                </tr>
                            </tbody>
                        </table>
                        <table class="table table-borderless" style="border:2px solid #dee2e6" width="100%">
                                <tr>
                                    <td align="center" colspan="9" style="border:2px solid #dee2e6; border-bottom: 1px solid #fff;">
                                        C E R T I F I C A T I O N<br/>
                                        I hereby certify on my official oath that the above is a true statement of all ADAs issued by me during<br/>
                                        the period stated above for which ADA Nos. <u><b>{{ this.first_checkno }} to {{ this.last_checkno }}</b></u> inclusive, were actually issued by me in the amounts shown thereon.<br/><br/><br/>
                                        <b><u>{{ head_cashier }}</u></b><br/>
                                        Name and Signature of Disbursing Officer/Cashier<br/>
                                    </td>
                                </tr>
                                <tr>
                                    <td align="center" colspan="5" style="border-top-style:none;">
                                        <u>Head, Cashier</u><br/>
                                        Official Designation
                                    </td>
                                    <td align="center" colspan="4">
                                        <u>{{ payment_date | customdate }}</u><br/>
                                        Date
                                    </td>
                                </tr>
                        </table>
                        
                    </div>
                </div>
                
                <div class="modal-footer">
                    <button v-print="'#printAppendix13'" @click="print" class="btn btn-default"><i class="fas fa-print"></i> PRINT</button>
                </div>
    
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <div class="card card-secondary card-outline" >
                <div class="card-header bg-yellow">
                    <h3 class="card-title"> LDDAP-ADA - 
                        <span v-if="this.payment_type=='MDS Trust'"> MDS Trust</span>
                        <span v-else-if="this.payment_type=='MDS Trust - IC'"> MDS Trust - IC</span>
                        <span v-else-if="this.payment_type=='General Fund'"> General Fund</span>
                        <span v-else-if="this.payment_type=='General Fund - IC'"> General Fund - IC</span>
                    </h3>
                </div>

                <div id="printAppendix13101">
                    <div class="card-body" style="font-family: Times New Roman;">
                        <table class="table table-borderless custom-font">
                            <tbody>
                            <tr>
                                <td colspan="2" align="center">
                                <b>FAD</b><br/>
                                Monitoring Sheet for # 3 Functional Objectives<br/>
                                <b>LDDAP-ADA<br/>
                                    <span v-if="this.payment_type=='MDS Trust'"> MDS Trust</span>
                                    <span v-else-if="this.payment_type=='MDS Trust - IC'"> MDS Trust - IC</span>
                                    <span v-else-if="this.payment_type=='General Fund'"> General Fund</span>
                                    <span v-else-if="this.payment_type=='General Fund - IC'"> General Fund - IC</span>
                                </b><br/>
                                {{ payment_date | customdate }}
                                </td>
                            </tr>
                            </tbody>
                        </table>

                        <table class="table table-borderless" style="border:2px solid #dee2e6" width="100%">
                            <thead>
                            <tr>
                                <td align="center" rowspan="2" style="border:2px solid #dee2e6">
                                    <b>No.</b>
                                </td>
                                <td align="center" rowspan="2" style="border:2px solid #dee2e6">
                                    <b>Date<br/>Received by<br/>Accounting</b>
                                </td>
                                <td align="center" colspan="2" style="border:2px solid #dee2e6">
                                    <b>LDDAP-ADA</b>
                                </td>
                                <td align="center" rowspan="2" style="border:2px solid #dee2e6">
                                    <b>DV/Payroll No.</b>
                                </td>
                                <td align="center" rowspan="2" style="border:2px solid #dee2e6">
                                    <b>ORS/BURS No.</b>
                                </td>
                                <td align="center" rowspan="2" style="border:2px solid #dee2e6">
                                    <b>Responsibility<br/>Center Code</b>
                                </td>
                                <td align="center" rowspan="2" style="border:2px solid #dee2e6">
                                    <b>Payee</b>
                                </td>
                                <td align="center" rowspan="2" style="border:2px solid #dee2e6">
                                    <b>UACS<br/>Object Code</b>
                                </td>
                                <td align="center" rowspan="2" style="border:2px solid #dee2e6">
                                    <b>Nature of Payment</b>
                                </td>
                                <td align="center" rowspan="2" style="border:2px solid #dee2e6">
                                    <b>Amount</b>
                                </td>
                                <td align="center" rowspan="2" style="border:2px solid #dee2e6">
                                    <b>Remarks</b>
                                </td>
                                <td align="center" rowspan="2" style="border:2px solid #dee2e6">
                                    <b>No. of<br/>Days</b>
                                </td>
                            </tr>
                            <tr>
                                <td align="center" style="border:2px solid #dee2e6">
                                    Date
                                </td>
                                <td align="center" style="border:2px solid #dee2e6">
                                    Serial No.
                                </td>
                            </tr>
                            </thead>
                            <tbody v-for="(creditor, index) in creditors" :key="creditor.id">
                                <tr>
                                    <td align="center" style="border:2px solid #dee2e6">{{ index + 1 }}</td>
                                    <td align="center" style="border:2px solid #dee2e6">{{ creditor.received_acctg }}</td>
                                    <td align="center" style="border:2px solid #dee2e6">{{ creditor.payment_date }}</td>
                                    <td align="center" style="border:2px solid #dee2e6">{{ creditor.check_no }}</td>
                                    <td align="center" style="border:2px solid #dee2e6"><span v-for="ci in creditor.creditorinfos" :key="ci.id">{{ ci.dv_no }}<br/></span></td>
                                    <td align="center" style="border:2px solid #dee2e6"><span v-for="ci in creditor.creditorinfos" :key="ci.id">{{ ci.ors_no }}<br/></span></td>
                                    <td align="left" style="border:2px solid #dee2e6"><span v-for="ci in creditor.creditorinfos" :key="ci.id">{{ ci.responsibility_center }}<br/></span></td>
                                    <td align="center" style="border:2px solid #dee2e6">{{ creditor.acct_name }}</td>
                                    <td align="left" style="border:2px solid #dee2e6"><span v-for="ci in creditor.creditorinfos" :key="ci.id">{{ ci.uacs }}<br/></span></td>
                                    <td align="left" style="border:2px solid #dee2e6">{{ creditor.nature }}</td>
                                    <td align="center" style="border:2px solid #dee2e6">{{ creditor.net }}</td>
                                    <td align="center" style="border:2px solid #dee2e6">{{ creditor.remarks }}</td>
                                    <td align="center" style="border:2px solid #dee2e6"><input value="1" style='border: 0;
                                            width: 40px;
                                            outline: 0;
                                            background: transparent;' 
                                    type="number" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" name="days_no" maxlength="2" autocomplete="off" /></td>
                                </tr>
                            </tbody>
                        </table>
                        <table class="table table-borderless" style="border:2px solid #fff" width="100%">
                                <tr>
                                    <td align="left" width="50%" style="border-top-style:none;">
                                        Prepared by:<br/>
                                        _______________________________<br/>
                                        <b>{{ profile.fname }} {{ profile.mname | midname }} {{ profile.lname }}</b><br/>
                                        {{ profile.position  }}
                                    </td>
                                    <td align="left" width="50%">
                                        Certified correct:<br/>
                                        _______________________________<br/>
                                        <b>{{ head_accountant | capitalize }}</b><br/>
                                        {{ chief_accountant_designation }}
                                    </td>
                                </tr>
                        </table>
                        
                    </div>
                </div>
                
                <div class="modal-footer">
                     <button v-print="'#printAppendix13101'" @click="print" class="btn btn-default"><i class="fas fa-print"></i> PRINT</button>
                </div>
    
                </div>
            </div>
        </div>

        


    </div>
</template>

<script>
    import Form from 'vform';
    import moment from 'moment';

    export default {
        data() {
          return {
            first_checkno: null,
            last_checkno: null,
            timer: 30,
            fund_cluster: '',
            head_accountant: '',
            head_cashier: '',
            creditors: [],
            settings: '',
            payments: {},
            profile: {},
            payment_date: '',
            payment_type: '',
            head_cashier: '',
            chief_accountant_designation: '',
            form: new Form({
                id: '',
            }),
            columns : [
                {
                    label: "Check Date",
                    field: "payment_date",
                },
                {
                    label: "Check Serial No.",
                    field: "check_no",
                },
                {
                    label: "DV/Payroll No.",
                    field: "creditorinfos[0].dv_no",
                },
                {
                    label: "ORS/BURS No.",
                    field: "creditorinfos[0].ors_no",
                },
                {
                    label: "Responsibility Center Code",
                    field: "creditorinfos[0].responsibility_center",
                },
                {
                    label: "Payee",
                    field: "acct_name",
                },
                {
                    label: "Nature of Payment",
                    field: "nature",
                },
                {
                    label: "Amount",
                    field: "net",
                }
            ],
            
          }
        },

        computed: {
          
          second() {
            return this.time / 1000;
          },

          isIdle() {
            const idlestat =  this.$store.state.idleVue.isIdle;
            if (idlestat == true) {
              $('#sessionmodal').modal('show');
              this.countDownTimer();
            } else {
              $('#sessionmodal').modal('hide');
            }

            return idlestat;

          },

        },

        methods: {

          print(){
              var style = document.createElement('style');
              style.innerHTML = "@page{size: A4 landscape !important;margin: 25mm !important;}" +
                      " html{background-color: #FFFFFF;}" +
                      " body{border: solid 0px #FFFFFF;margin: 0;}";
              window.document.head.appendChild(style);
          },

          countDownTimer() {
            let timerId = setInterval(() => {
              this.timer -= 1;
              if (!this.$store.state.idleVue.isIdle) clearInterval(timerId);

              if (this.timer < 1) {
                clearInterval(timerId);
                document.getElementById('logout-form').submit();
              }
            }, 1000);

            this.timer = 30;
          },

          total_net(netparam) {
            if (!netparam) {
              //
            } else {
              return netparam.reduce((tot, val) => {
                var sum;
                sum = tot + parseFloat(val.net.replace(/,/g, ''));
                return sum;
              }, 0);
            }
          },

          async getReport() {
            this.$Progress.start();
            await axios.get(
                'api/creditors/' + this.payment_date + '/' + this.payment_type)
                .then(({ data }) => (
                    this.creditors = data
                )
            );

            if(this.creditors.length != 0) {
                this.first_checkno = this.creditors[0].check_no;
                this.last_checkno = this.creditors[this.creditors.length - 1].check_no;
            }

            this.$Progress.finish();
          },

          loadSettings() {
            this.$Progress.start();

            const current = new Date();
            const current_year =  current.getFullYear();
            const current_month = moment(String(current)).format('MM');
            const current_day = moment(String(current)).format('DD');

            this.payment_date = current_year  + '-' + current_month + '-' + current_day;
            this.payment_type = 'General Fund';
            axios.get('api/creditors/' + this.payment_date + '/' + this.payment_type).then(({ data }) => (this.creditors = data));
            axios.get("api/users/pds/" + this.$gate.showMyID()).then(({ data }) => (this.profile = data));
            axios.get('api/cashiersetting/1').then(({ data }) => (
                //this.settings = data;
                this.fund_cluster = data.fund_cluster,
                this.head_accountant = data.chief_accountant,
                this.head_cashier = data.head_cashier,
                this.chief_accountant_designation = data.chief_accountant_designation
            ));

            //this.fund_cluster = this.settings.fund_cluster;
            //this.head_accountant = this.settings.chief_accountant;
            //this.head_cashier = this.settings.head_cashier;

            this.$Progress.finish();
          },

        },


        created() {

          this.loadSettings();

          Trigger.$on('RefreshData',() => {
            this.loadSettings();
          });

        },

    }
</script>
<style scoped>
.custom-font {
    font-size: 11px !important;
}

@page {
  size: A4 landscape !important;
  margin: 17mm 17mm 17mm 17mm;
}

@media print {
    .page-number:before {
        content: counter(page);
    }
}
</style>
