/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue');
import moment from 'moment';
moment.suppressDeprecationWarnings = true;
import VueMoment from 'vue-moment';

Vue.use(VueMoment, { moment });

import Gate from "./gate";
Vue.prototype.$gate = new Gate(window.user);

import qs from 'qs';
Vue.prototype.qs = qs;

//Pusher
window.Pusher = require('pusher-js');

import Echo from 'laravel-echo'
window.Echo = new Echo({
    broadcaster: 'pusher',
    key: process.env.MIX_PUSHER_APP_KEY,
    cluster: process.env.MIX_PUSHER_APP_CLUSTER,
    encrypted: true
});

import JsonExcel from 'vue-json-excel'
Vue.component('downloadExcel', JsonExcel)

//vue sweet alert
import swal from 'sweetalert2'
window.swal = swal;
const toastr = swal.mixin({
  toast: true,
  position: 'bottom-start',
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  onOpen: (toast) => {
    toast.addEventListener('mouseenter', swal.stopTimer)
    toast.addEventListener('mouseleave', swal.resumeTimer)
  }
})
window.toastr = toastr;

//globalize in header meta tag the current userid logged in

//Vue.prototype.$userId = document.querySelector("meta[name='user-id']").getAttribute('content');
//Vue.prototype.$userRole = document.querySelector("meta[name='user-role']").getAttribute('content');

//vue v-form

import { Form, HasError, AlertError } from 'vform';
window.form = Form;
Vue.component(HasError.name, HasError)
Vue.component(AlertError.name, AlertError)

import { BootstrapVue } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.use(BootstrapVue)

import VueExcelXlsx from "vue-excel-xlsx";
Vue.use(VueExcelXlsx);

Vue.component('pagination', require('laravel-vue-pagination'));

import VueTimeago from 'vue-timeago'
Vue.use(VueTimeago, {
  name: 'Timeago', // Component name, `Timeago` by default
  locale: 'en', // Default locale
})

import Selectize from 'vue2-selectize'
Vue.use(Selectize)
Vue.component("selectize", Selectize)

import 'selectize/dist/css/selectize.default.css'
import VSelectize from '@isneezy/vue-selectize'
Vue.component('v-selectize', VSelectize)

import Multiselect from 'vue-multiselect'
Vue.use(Multiselect)
Vue.component("multiselect", Multiselect)

import Vue2Editor from "vue2-editor";
Vue.use(Vue2Editor);

import VueQRCodeComponent from 'vue-qrcode-component'
Vue.component('qr-code', VueQRCodeComponent)

import VTooltip from 'v-tooltip'
Vue.use(VTooltip)

import VCalendar from 'v-calendar';
Vue.use(VCalendar);

//vue router start
import VueRouter from 'vue-router'
Vue.use(VueRouter)

import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
const store = new Vuex.Store({
  state: {
    count: 0
  },
  mutations: {
    increment (state) {
      state.count++
    }
  }
})

import IdleVue from 'idle-vue';
const eventsHub = new Vue();
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: 600000, // 600000 milliseconds = 600 seconds = 10 minutes
  startAtIdle: false
});

import Print from 'vue-print-nb'
Vue.use(Print)

//Vue Progress bar
import VueProgressBar from 'vue-progressbar'
Vue.use(VueProgressBar, {
  //color: 'rgb(143, 255, 199)',
  color: 'rgb(0,255,0)',
  failedColor: 'red',
  height: '10px'
})

import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)

import VueTimepicker from 'vue2-timepicker/src/vue-timepicker.vue'
Vue.use(VueTimepicker)
Vue.component("vue-timepicker", VueTimepicker )

import money from 'v-money'
// register directive v-money and component <money>
Vue.use(money, {precision: 4})


let routes  = [
    { path: '/', component: require('./components/Dashboard.vue').default },
    { path: '/dashboard', component: require('./components/Dashboard.vue').default },
    { path: '/developer', component: require('./components/Developer.vue').default },
    { path: '/users', component: require('./components/Users.vue').default },
    { path: '/profile', component: require('./components/Profile.vue').default },
    { path: '/attendance', component: require('./components/Attendance.vue').default },
    { path: '/obforms', component: require('./components/Obforms.vue').default },
	{ path: '/tickets', component: require('./components/Tickets.vue').default },
    { path: '/payroll', component: require('./components/Payroll.vue').default },
    { path: '/banks', component: require('./components/Banks.vue').default },
    { path: '/payments', component: require('./components/Payments.vue').default },
    { path: '/cashier', component: require('./components/Cashier.vue').default },
    { path: '/lddap-reports', component: require('./components/CashierReports.vue').default },
    { path: '/loans', component: require('./components/Loans.vue').default },
    { path: '/tapiea', component: require('./components/Tapiea.vue').default },
    { path: '/projects', component: require('./components/pems/Projects.vue').default },
    { path: '/deliberation', component: require('./components/Execom.vue').default },
    { path: '/dpmis', component: require('./components/Dpmis.vue').default },
    { path: '/certificates', component: require('./components/Certificates.vue').default },
    { path: '/certificate-settings', component: require('./components/CertificateSettings.vue').default },
    { path: '/alerts', component: require('./components/Alerts.vue').default },

    { path: '/programs', component: require('./components/project_monitoring/Programs.vue').default },
    { path: '/indicators', component: require('./components/project_monitoring/Indicators.vue').default },
    { path: '/search-data', component: require('./components/project_monitoring/SearchData.vue').default },

    { path: '/targets', component: require('./components/project_monitoring/Targets.vue').default },
    { path: '/actual-amounts', component: require('./components/project_monitoring/ActualAmounts.vue').default },
    { path: '/monthly-reports', component: require('./components/project_monitoring/MonthlyReports.vue').default },
    { path: '/annual-reports', component: require('./components/project_monitoring/AnnualReports.vue').default },
    { path: '/program-managers', component: require('./components/project_monitoring/ProgramManagers.vue').default },

    { path: '/ppmp', component: require('./components/procurement/Ppmp.vue').default },
    { path: '/procurement-items', component: require('./components/procurement/ProcurementItems.vue').default },
    { path: '/suppliers', component: require('./components/procurement/Suppliers.vue').default },
    { path: '/offices', component: require('./components/procurement/Offices.vue').default },
    { path: '/fund-source', component: require('./components/procurement/FundSource.vue').default },
    { path: '/proc-signatories', component: require('./components/procurement/ProcSignatories.vue').default },
    { path: '/proc-stages', component: require('./components/procurement/ProcStages.vue').default },
    { path: '/po-jo', component: require('./components/procurement/PoJo.vue').default },
    { path: '/proc-projects', component: require('./components/procurement/ProcProjects.vue').default },
    { path: '/ppmp', component: require('./components/procurement/Ppmp.vue').default },
    { path: '/ppmp-admin', component: require('./components/procurement/PpmpAdmin.vue').default },
    { path: '/pr', component: require('./components/procurement/PurchaseRequest.vue').default },
    { path: '/pr-admin', component: require('./components/procurement/PurchaseRequestAdmin.vue').default },
    { path: '/items-categories', component: require('./components/procurement/ProcItemsCategories.vue').default },
    { path: '/uom', component: require('./components/procurement/Uom.vue').default },
    { path: '/app', component: require('./components/procurement/App.vue').default },

    // { path: '/projects', component: require('./components/pems/Projects.vue').default },
    { path: '/project-actions', component: require('./components/pems/ProjectActions.vue').default },
    { path: '/project-industry', component: require('./components/pems/ProjectIndustry.vue').default },
    { path: '/project-roles', component: require('./components/pems/ProjectRoles.vue').default },
    { path: '/timeline', params: true, props: true, component: require('./components/pems/Timeline.vue').default },
    { path: '/project-analytics', component: require('./components/pems/ProjectAnalytics.vue').default },

    { path: '/access', component: require('./components/Access.vue').default },
    { path: '/not-found', component: require('./components/notfound.vue').default, name: 'not-found' },
    { path: '/*', component: require('./components/notfound.vue').default },
]

const router = new VueRouter({
	mode: 'history',
	routes //short for 'routes: routes'
})
//vue router end


//vue filters start
Vue.filter('toCurrency', function (value) {
  Number(value)
  if (typeof value !== "number") {
    value = Number(value);
      //return value;
  }
  var formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'PHP',
      minimumFractionDigits: 2
  });
  return formatter.format(value);
});

Vue.filter('toNumberComma', function (value) {
  Number(value)
  if (typeof value !== "number") {
    value = Number(value);
  }
  var formatter = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 0
  });
  return formatter.format(value);
});

var converter = require('number-to-words');

Vue.filter('toWordsPesos', function(value) {
  if (!value) return '';
  return converter.toWords(value);
})

Vue.filter('stringlimit', function (value, size) {
  if (!value) return '';
  value = value.toString();

  if (value.length <= size) {
    return value;
  }
  return value.substr(0, size) ;
});

Vue.filter('stringlimitinverted', function (value, size) {
  if (!value) return '';
  value = value.toString();

  if (value.length <= size) {
    return value;
  }
  return '...' + value.substr(0, size);
});

Vue.filter('convertnewline', function (value) {
  if (!value) return '';
  value = value.toString();

  return value.replace('|', '<br />');
});

Vue.filter('removeLastChar', function (value) {
  return value.replace(/.$/, '');
});

Vue.filter('toFlatString', function (value) {

  let str = [];

  // push account anmes to str array
  if(value && value.length != 1) {
    for(var val in value) {
      if (value.hasOwnProperty(val)) {
        str.push(value[val].acct_name);
      }
    }
  }

  // remove duplicates
  let uniqueChars = str.filter((c, index) => {
    return str.indexOf(c) === index;
  });

  return uniqueChars.join(', ');
});

Vue.filter('toWordsCentavos', function(value) {
  if (!value) return ' pesos';
  if (value % 1 != 0) {
    return ' pesos and ' + converter.toWords(value.toFixed(2).toString().split('.').pop()) + ' centavos';
  } else {
    return ' pesos';
  }
})

//vue filters start
Vue.filter('toMoney', function (value) {
  Number(value)
  if (typeof value !== "number") {
    value = Number(value);
      //return value;
  }
  var formatter = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2
  });
  return formatter.format(value);
});


Vue.filter('fixdecimal', function (value) {
	return value.toFixed(2);
})

Vue.filter('capitalize', function (value) {
  if (!value) return ''
	return value.charAt(0).toUpperCase() + value.slice(1);
})

Vue.filter('capitalizewords', function (value) {
  if (!value) return ''
  var capitalized = []
  value.split(' ').forEach(word => {
    capitalized.push(
        word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
    )
  })
	return capitalized.join(' ');
})

Vue.filter('midname', function (value) {
  if (!value) return ''
  return value.charAt(0).toUpperCase() + '.';
})

Vue.filter('sgformat', function (value) {
	return value.charAt(0) + value.charAt(1) + '-' + value.charAt(2);
})

Vue.filter('allcaps', function (value) {
	return value.toUpperCase();
})

Vue.filter('customdate', function (value) {
	return moment(value).format('MMMM D, YYYY');
})

Vue.filter('customdate2', function (value) {
	return moment(value).format('DD MMMM YYYY');
})

Vue.filter('fulldate', function (value) {
	return moment(value).format('MMM D, YYYY hh:mm a');
})

Vue.filter('shortdaytime', function (value) {
	return moment(value).format('YYYY-MM-DD hh:mm a');
})

Vue.filter('dobformat', function (value) {
	return moment(value).format('MM/DD/YYYY');
})

Vue.filter('tomonthword', function (value) {
	return moment(value).format('MMMM');
})

Vue.filter('birthdayformat', function (value) {
	return moment(value).format('MMMM D');
})

Vue.filter('getday', function (value) {
	return moment(value).format('dddd');
})

Vue.filter('military', function (value) {
  let tf = moment(value).format('HH:mm');

  if (tf == "Invalid date") {
    return "-";
  }
  else {
    return moment(value).format('hh:mm a');
  }

})

Vue.filter('timeformat', function (value) {
  let tf = moment(value).format('hh:mm a');

  if (tf == "Invalid date") {
    return "-";
  }
  else {
    return moment(value).format('hh:mm a');
  }

})

Vue.filter('monthwordformat', function (value) {
  let tf = moment(value).format('hh:mm a');

  if (value == "01" || value == "1") {
    return "January";
  }
  else if (value == "02" || value == "2") {
    return "February";
  }
  else if (value == "03" || value == "3") {
    return "March";
  }
  else if (value == "04" || value == "4") {
    return "April";
  }
  else if (value == "05" || value == "5") {
    return "May";
  }
  else if (value == "06" || value == "6") {
    return "June";
  }
  else if (value == "07" || value == "7") {
    return "July";
  }
  else if (value == "08" || value == "8") {
    return "August";
  }
  else if (value == "09" || value == "9") {
    return "September";
  }
  else if (value == "10") {
    return "October";
  }
  else if (value == "11") {
    return "November";
  }
  else if (value == "12") {
    return "December";
  } else {
    return "Incorrect Month Format";
  }

})

Vue.filter('duration', function (value_out, value_in) {

  let vo = moment(value_out).format('hh:mm a');
  let vi = moment(value_in).format('hh:mm a');

  if (vo == "Invalid date" || vi == "Invalid date") {
    return 0;
  }
  else {
    let total = moment(value_out).diff(value_in, 'hours');
    return total;
  }
})

Vue.filter('computelate', function (value) {

  let tf = moment(value).format('hh:mm a');
  let tme = moment(moment(value).format('YYYY-MM-DD') + ' 9:00 AM').toDate();

  if (tf == "Invalid date") {
    return "-";
  }
  else {
    let df = moment(value).diff(tme, 'minutes');
    if (df > 0) {
      return df;
    } else {
      return 0;
    }
  }
})


//vue filter end

//vue custom events
window.Trigger = new Vue();


//laravel vue passport auth
Vue.component(
    'passport-clients',
    require('./components/passport/Clients.vue').default
);

Vue.component(
    'passport-authorized-clients',
    require('./components/passport/AuthorizedClients.vue').default
);

Vue.component(
    'passport-personal-access-tokens',
    require('./components/passport/PersonalAccessTokens.vue').default
);

Vue.component(
  'not-found',
  require('./components/notfound.vue').default
);


Vue.component(
  'UserDivisionChart',
  require('./components/charts/UserDivisionChart.vue').default
);


Vue.component(
  'DoughnutChart',
  require('./components/charts/DoughnutChart.vue').default
);

Vue.component(
  'LineChart',
  require('./components/charts/LineChart.vue').default
);

Vue.component(
  'UserDivisionChart',
  require('./components/charts/UserDivisionChart.vue').default
);

Vue.component(
  'UserAppointmentChart',
  require('./components/charts/UserAppointmentChart.vue').default
);

Vue.component(
  'UserGenderChart',
  require('./components/charts/UserGenderChart.vue').default
);

Vue.component(
  'UserBirthdayMonth',
  require('./components/charts/UserBirthdayMonth.vue').default
);

Vue.component(
  'WebsiteMonitor',
  require('./components/charts/WebsiteMonitor.vue').default
);

Vue.component(
  'Covid19Chart',
  require('./components/charts/Covid19Chart.vue').default
);

Vue.component(
    'PhilippinesMap',
    require('./components/charts/PhilippinesMap.vue').default
);

Vue.component(
    'RegionalProjects',
    require('./components/charts/RegionalProjects.vue').default
);

Vue.component(
  'FileUploader',
  require('./components/FileUploader.vue').default
);

Vue.component(
  'CertificateFileUploader',
  require('./components/uploaders/CertificateFileUploader.vue').default
);

Vue.component(
    'ProjectFileUploader',
    require('./components/uploaders/ProjectFileUploader.vue').default
  );

Vue.component(
  'Notifications',
  require('./components/Notifications.vue').default
);

Vue.component(
  'Online',
  require('./components/Online.vue').default
);


/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component('example-component', require('./components/ExampleComponent.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',
    router,
    store: store,
    data:{
      search: '',
    },
    methods: {
      searchit(){
        //console.log("searching...")
        Trigger.$emit('searching');
      },
      clearit(){
        //console.log("searching...")
        this.search = '';
        Trigger.$emit('searching');
      },
      go_full_screen() {
        if ((document.fullScreenElement && document.fullScreenElement !== null) ||
        (!document.mozFullScreen && !document.webkitIsFullScreen)) {
          if (document.documentElement.requestFullScreen) {
            document.documentElement.requestFullScreen();
          } else if (document.documentElement.mozRequestFullScreen) {
            document.documentElement.mozRequestFullScreen();
          } else if (document.documentElement.webkitRequestFullScreen) {
            document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
          }
        } else {
          if (document.cancelFullScreen) {
            document.cancelFullScreen();
          } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
          } else if (document.webkitCancelFullScreen) {
            document.webkitCancelFullScreen();
          }
        }
      },
    }
});
