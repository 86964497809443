<template>
  <div class="col-md-4">
    <!-- USERS LIST -->
    <div class="card">
      <div class="card-header">
        <h3 class="card-title">Employees per appointment</h3>

        <div class="card-tools">
          <!--<span class="badge badge-warning">Employees</span>
          <button type="button" class="btn btn-tool" data-card-widget="collapse">
            <i class="fas fa-minus"></i>
          </button>
          -->
          <!--
          <button type="button" class="btn btn-tool" data-card-widget="remove">
            <i class="fas fa-times"></i>
          </button>
          -->
        </div>
      </div>
      <!-- /.card-header -->
      <div class="card-body p-0 mb-4">
        <doughnut-chart v-if="loaded" :chartdata="chartdata" :options="options"></doughnut-chart>
      </div>
      <!-- /.card-body -->
      <!--<div class="card-footer text-center">
        <a href="javascript:">View All Users</a>
      </div>-->
      <!-- /.card-footer -->
    </div>
    <!--/.card -->
  </div>
</template>

<script>
import DoughnutChart from './DoughnutChart'
import axios from 'axios'

export default {
  name: 'UserAppointmentChart',
  components: {
    DoughnutChart
  },

  data () {
      return {
        chartdata: null,
        options: null,
        loaded: false,
        data: {},
      }
  },

  async mounted () {
      this.loaded = false;
      await axios.get("api/dashboardhr").then(res => {
        //console.log(res);
        this.data = res.data,
        this.loaded = true;
        this.chartdata = {
          labels: ["Permanent", "Contractual", "Job Order",],
          datasets: [
            {
              label: "# users",
              data: [res.data.userRegular, res.data.userContractual, res.data.userJoborder],
              backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 206, 86, 0.2)'
              ],
              borderColor: [
                'rgba(255,99,132,1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
              ],
              borderWidth: 1
            }
          ]
        };
        this.options = {
          /*
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true
                }
              }
            ]
          },
          */
          legend: {
            display: true
          },
          responsive: true,
          maintainAspectRatio: false,
          title: {
            display: false,
            text: " User Count Per Appointment"
          }
        };
        
      })
      .catch(err => {
        console.log(err);
      });

    }

}
</script>