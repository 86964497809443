<template>
    <div class="container-fluid">
        <div class="row" v-if="!accessflag === 1"><not-found></not-found></div>
        <div class="row" v-else>
          <div class="col-md-12">
            <div class="card card-secondary card-outline">
              <div class="card-header">
                <h3 class="card-title"><i class="fas fa-users"></i> User Access Management</h3>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <table class="table responsive-table table-striped">
                  <thead>
                    <tr>
                      <th scope="col" class="pl-2">Name</th>
                      <th scope="col" class="pl-2">Photo</th>
                      <th scope="col">Username</th>
                      <th scope="col">Account Status</th>
                      <th scope="col" class="text-right">Accesses</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="user in users.data" :key="user.id">
                      <th scope="row" class="pl-2" v-if="user.sname === 'NA'">{{ user.lname }}, {{ user.fname }} {{ user.mname | midname }}</th>
                      <th scope="row" class="pl-2" v-else>{{ user.lname }}, {{ user.fname }} {{ user.mname | midname }}, {{ user.sname }}</th>
                      <td data-title="Photo" class="pl-2"><img alt="Avatar" class="img-circle elevation-2" height="25px" width="25px" :src="'img/profile/' + user.photo" /></td>
                      <td data-title="Username">{{ user.username }}</td>
                      <td data-title="Account Status">{{ user.acct_status }}</td>
                      <td>
                        <div class="btn-group btn-group-sm">
                          <button class="btn btn-primary" aria-label="edit" type="button" @click="editModal(user.id, user.lname + ', ' + user.fname)"><i class="fa fa-edit"></i></button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-show="users.total==0">
                      <tr><td colspan="10">No employees found.</td></tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                <pagination :data="users" @pagination-change-page="getResults" show-disabled :limit="2"></pagination>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>


        <!-- Modal -->
        <div class="modal fade" id="usermodal" tabindex="-1" role="dialog" aria-labelledby="userModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="userModalLabel"> Manage access permissions of <span class="text-danger modal-title">{{ selectedname }}</span></h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <form @submit.prevent="updateTapieaUser()" @keydown="form.onKeydown($event)">
              <div class="modal-body">
                  <div class="row">
                    <div class="col-md-3">
                        <div class="card card-primary">
                            <div class="card-header border-transparent">
                                <h3 class="card-title">HR permissions</h3>

                                <div class="card-tools">
                                <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                    <i class="fas fa-minus"></i>
                                </button>
                                </div>
                            </div>
                            <div class="card-body p-0">
                                <div class="table-responsive">
                                <table class="table table-striped m-0">
                                    <thead>
                                        <tr>
                                            <th>Control</th>
                                            <th>Access</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="access in accesses" :key="access.id">
                                            <td v-if="access.module_name=='HR'">{{ access.control }}</td>
                                            <td v-if="access.module_name=='HR'">
                                              <div>
                                                  <div class="custom-control custom-switch">
                                                    <!--
                                                    <input v-if="access.isChecked == 1" @click="uncheckPermission(access.acc_id)" type="checkbox" class="custom-control-input" v-bind:id="access.id + '_AccessSwitch_' + selectedid" :checked="access.isChecked" />
                                                    <input v-else type="checkbox" @click="checkPermission(access.id, selectedid)" class="custom-control-input" v-bind:id="access.id + '_AccessSwitch_' + selectedid" />
                                                    -->

                                                    <input type="checkbox" class="custom-control-input" v-bind:id="access.id + '_AccessSwitch_' + selectedid" :checked="access.isChecked" @click="assignPermission(access.acc_id, access.isChecked, access.id, selectedid)"  />
                                                    <label class="custom-control-label" v-bind:for="access.id + '_AccessSwitch_' + selectedid"></label>
                                                  </div>
                                              </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-3">
                        <div class="card card-primary">
                            <div class="card-header border-transparent">
                                <h3 class="card-title">MIS permissions</h3>

                                <div class="card-tools">
                                <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                    <i class="fas fa-minus"></i>
                                </button>
                                </div>
                            </div>
                            <div class="card-body p-0">
                                <div class="table-responsive">
                                <table class="table table-striped m-0">
                                    <thead>
                                        <tr>
                                            <th>Control</th>
                                            <th>Access</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="access in accesses" :key="access.id">
                                            <td v-if="access.module_name=='MIS'">{{ access.control }}</td>
                                            <td v-if="access.module_name=='MIS'">
                                              <div>
                                                  <div class="custom-control custom-switch">
                                                    <input type="checkbox" class="custom-control-input" v-bind:id="access.id + '_AccessSwitch_' + selectedid" :checked="access.isChecked" @click="assignPermission(access.acc_id, access.isChecked, access.id, selectedid)"  />
                                                    <label class="custom-control-label" v-bind:for="access.id + '_AccessSwitch_' + selectedid"></label>
                                                  </div>
                                              </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-3">
                        <div class="card card-primary">
                            <div class="card-header border-transparent">
                                <h3 class="card-title">Cashier permissions</h3>

                                <div class="card-tools">
                                <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                    <i class="fas fa-minus"></i>
                                </button>
                                </div>
                            </div>
                            <div class="card-body p-0">
                                <div class="table-responsive">
                                <table class="table table-striped m-0">
                                    <thead>
                                        <tr>
                                            <th>Control</th>
                                            <th>Access</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="access in accesses" :key="access.id">
                                            <td v-if="access.module_name=='CASHIER'">{{ access.control }}</td>
                                            <td v-if="access.module_name=='CASHIER'">
                                              <div>
                                                  <div class="custom-control custom-switch">
                                                    <input type="checkbox" class="custom-control-input" v-bind:id="access.id + '_AccessSwitch_' + selectedid" :checked="access.isChecked" @click="assignPermission(access.acc_id, access.isChecked, access.id, selectedid)"  />
                                                    <label class="custom-control-label" v-bind:for="access.id + '_AccessSwitch_' + selectedid"></label>
                                                  </div>
                                              </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!--
                    <div class="col-md-3">
                        <div class="card card-primary">
                            <div class="card-header border-transparent">
                                <h3 class="card-title">ExeCom permissions</h3>

                                <div class="card-tools">
                                <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                    <i class="fas fa-minus"></i>
                                </button>
                                </div>
                            </div>
                            <div class="card-body p-0">
                                <div class="table-responsive">
                                <table class="table table-striped m-0">
                                    <thead>
                                        <tr>
                                            <th>Control</th>
                                            <th>Access</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="access in accesses" :key="access.id">
                                            <td v-if="access.module_name=='EXECOM'">{{ access.control }}</td>
                                            <td v-if="access.module_name=='EXECOM'">
                                              <div>
                                                  <div class="custom-control custom-switch">
                                                    <input type="checkbox" class="custom-control-input" v-bind:id="access.id + '_AccessSwitch_' + selectedid" :checked="access.isChecked" @click="assignPermission(access.acc_id, access.isChecked, access.id, selectedid)"  />
                                                    <label class="custom-control-label" v-bind:for="access.id + '_AccessSwitch_' + selectedid"></label>
                                                  </div>
                                              </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    -->

                    <div class="col-md-3">
                        <div class="card card-primary">
                            <div class="card-header border-transparent">
                                <h3 class="card-title">Project Eval. & Monitoring</h3>

                                <div class="card-tools">
                                <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                    <i class="fas fa-minus"></i>
                                </button>
                                </div>
                            </div>
                            <div class="card-body p-0">
                                <div class="table-responsive">
                                <table class="table table-striped m-0">
                                    <thead>
                                        <tr>
                                            <th>Control</th>
                                            <th>Access</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="access in accesses" :key="access.id">
                                            <td v-if="access.module_name=='PEMS'">{{ access.control }}</td>
                                            <td v-if="access.module_name=='PEMS'">
                                              <div>
                                                  <div class="custom-control custom-switch">
                                                    <input type="checkbox" class="custom-control-input" v-bind:id="access.id + '_AccessSwitch_' + selectedid" :checked="access.isChecked" @click="assignPermission(access.acc_id, access.isChecked, access.id, selectedid)"  />
                                                    <label class="custom-control-label" v-bind:for="access.id + '_AccessSwitch_' + selectedid"></label>
                                                  </div>
                                              </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>

                <div class="row">
                  <div class="col-md-3">
                        <div class="card card-primary">
                            <div class="card-header border-transparent">
                                <h3 class="card-title">DPAMS permissions</h3>

                                <div class="card-tools">
                                <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                    <i class="fas fa-minus"></i>
                                </button>
                                </div>
                            </div>
                            <div class="card-body p-0">
                                <div class="table-responsive">
                                <table class="table table-striped m-0">
                                    <thead>
                                        <tr>
                                            <th>Control</th>
                                            <th>Access</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="access in accesses" :key="access.id">
                                            <td v-if="access.module_name=='DPAMS'">{{ access.control }}</td>
                                            <td v-if="access.module_name=='DPAMS'">
                                              <div>
                                                  <div class="custom-control custom-switch">
                                                    <input type="checkbox" class="custom-control-input" v-bind:id="access.id + '_AccessSwitch_' + selectedid" :checked="access.isChecked" @click="assignPermission(access.acc_id, access.isChecked, access.id, selectedid)"  />
                                                    <label class="custom-control-label" v-bind:for="access.id + '_AccessSwitch_' + selectedid"></label>
                                                  </div>
                                              </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                </div>
                            </div>
                        </div>
                    </div>

                  <div class="col-md-3">
                        <div class="card card-primary">
                            <div class="card-header border-transparent">
                                <h3 class="card-title">e-Certificates permissions</h3>

                                <div class="card-tools">
                                <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                    <i class="fas fa-minus"></i>
                                </button>
                                </div>
                            </div>
                            <div class="card-body p-0">
                                <div class="table-responsive">
                                <table class="table table-striped m-0">
                                    <thead>
                                        <tr>
                                            <th>Control</th>
                                            <th>Access</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="access in accesses" :key="access.id">
                                            <td v-if="access.module_name=='CERTIFICATES'">{{ access.control }}</td>
                                            <td v-if="access.module_name=='CERTIFICATES'">
                                              <div>
                                                  <div class="custom-control custom-switch">
                                                    <input type="checkbox" class="custom-control-input" v-bind:id="access.id + '_AccessSwitch_' + selectedid" :checked="access.isChecked" @click="assignPermission(access.acc_id, access.isChecked, access.id, selectedid)"  />
                                                    <label class="custom-control-label" v-bind:for="access.id + '_AccessSwitch_' + selectedid"></label>
                                                  </div>
                                              </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-3">
                        <div class="card card-primary">
                            <div class="card-header border-transparent">
                                <h3 class="card-title">DPMIS permissions</h3>

                                <div class="card-tools">
                                <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                    <i class="fas fa-minus"></i>
                                </button>
                                </div>
                            </div>
                            <div class="card-body p-0">
                                <div class="table-responsive">
                                <table class="table table-striped m-0">
                                    <thead>
                                        <tr>
                                            <th>Control</th>
                                            <th>Access</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="access in accesses" :key="access.id">
                                            <td v-if="access.module_name=='DPMIS'">{{ access.control }}</td>
                                            <td v-if="access.module_name=='DPMIS'">
                                              <div>
                                                  <div class="custom-control custom-switch">
                                                    <input type="checkbox" class="custom-control-input" v-bind:id="access.id + '_AccessSwitch_' + selectedid" :checked="access.isChecked" @click="assignPermission(access.acc_id, access.isChecked, access.id, selectedid)"  />
                                                    <label class="custom-control-label" v-bind:for="access.id + '_AccessSwitch_' + selectedid"></label>
                                                  </div>
                                              </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-3">
                        <div class="card card-primary">
                            <div class="card-header border-transparent">
                                <h3 class="card-title">ProcMS permissions</h3>

                                <div class="card-tools">
                                <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                    <i class="fas fa-minus"></i>
                                </button>
                                </div>
                            </div>
                            <div class="card-body p-0">
                                <div class="table-responsive">
                                <table class="table table-striped m-0">
                                    <thead>
                                        <tr>
                                            <th>Control</th>
                                            <th>Access</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="access in accesses" :key="access.id">
                                            <td v-if="access.module_name=='PROCMS'">{{ access.control }}</td>
                                            <td v-if="access.module_name=='PROCMS'">
                                              <div>
                                                  <div class="custom-control custom-switch">
                                                    <input type="checkbox" class="custom-control-input" v-bind:id="access.id + '_AccessSwitch_' + selectedid" :checked="access.isChecked" @click="assignPermission(access.acc_id, access.isChecked, access.id, selectedid)"  />
                                                    <label class="custom-control-label" v-bind:for="access.id + '_AccessSwitch_' + selectedid"></label>
                                                  </div>
                                              </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>



            </div>
            <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            </div>

            </form>

            </div>
          </div>
        </div>

        <div class="modal fade" id="sessionmodal" tabindex="-1" role="dialog" aria-labelledby="sessionModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-md" role="document">
            <div class="modal-content">
              <div class="modal-header bg-orange">
                <h5 class="modal-title" id="sessionModalLabel"><i class="icon fas fa-exclamation-triangle"></i> Security Auto-logout</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
              <div class="row-aligncenter">
                <div id="printPayslip2">
                  <div class="card-body">
                    You have left this browser idle for 10 minutes. Due to our data privacy policy, you will be
                    automatically logged out in <b>{{ timer }}</b> seconds.
                  </div>
                </div>
              </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
        <span style="display:none;">{{ isIdle }}</span>



    </div>
</template>

<script>
    import Form from 'vform';
import notfound from './notfound.vue';

    export default {
  components: { notfound },
        data() {
          return {
            timer: 30,
            editmode: false,
            emptyflag: false,
            accessflag: 0,
            users : {},
            accesses: {},
            //profile : {},
            selectedname: '',
            selectedid: '',
            form: new Form({
              module_id: '',
              user_id: '',
              user_module: '',
            })
          }
        },
        methods: {

          countDownTimer() {
            let timerId = setInterval(() => {
              this.timer -= 1;
              if (!this.$store.state.idleVue.isIdle) clearInterval(timerId);

              if (this.timer < 1) {
                clearInterval(timerId);
                // Your logout function should be over here
                document.getElementById('logout-form').submit();
              }
            }, 1000);

            this.timer = 30;
          },

          getResults(page = 1) {
              let query = this.$parent.search;

              if(query == ''){
                axios.get('api/users?page=' + page).then(response => { this.users = response.data; });
              }else{
                axios.get('api/findUser?q=' + query + '&page=' + page).then(response => { this.users = response.data; });
              }
          },

           updateTapieaUser() {

            this.$Progress.start();

            this.form.put('api/profile/updatetapiea/'+this.form.id).then(()=>{

                    Trigger.$emit('RefreshData');
                    $('#usermodal').modal('hide')

                    toastr.fire({
                      icon: 'success',
                      title: 'Member info saved'
                    })

                    this.$Progress.finish();

                  }).catch(()=>{

                    toastr.fire({
                      icon: 'error',
                      title: 'Member updating failed'
                    });

                    this.$Progress.fail();

                  });
          },

          editModal(uid, uname) {
            //this.editmode = true;
            this.form.reset();
            this.form.clear();
            this.selectedname = uname;
            this.selectedid = uid;
            axios.get('api/modules/access/' + uid).then(({ data }) => (this.accesses = data));
            $('#usermodal').modal('show');

          },

          assignPermission(id, sel, mid, uid) {
            //console.log(id + "-" + sel + "-" + mid + "-" + uid);
            if (id != null && sel != null) {
              this.uncheckPermission(id);
            } else {
              this.checkPermission(mid, uid);
            }
          },

          checkPermission(mid, uid) {
            this.$Progress.start();
            this.form.module_id = mid;
            this.form.user_id = uid;
            this.form.user_module = uid + '_' + mid;
            this.form.post('api/accesses').then(()=>{
              toastr.fire({
                icon: 'success',
                title: 'Access permision granted'
              });

              this.$Progress.finish();
              Trigger.$emit('RefreshData');

            }).catch(()=>{
              toastr.fire({
                icon: 'error',
                title: 'Access permision setting failed'
              });

              this.$Progress.fail();

            });

          },

          uncheckPermission(id) {

            this.$Progress.start();
            this.form.delete('api/accesses/'+id).then(()=>{
              toastr.fire({
                icon: 'info',
                title: 'Access permision removed'
              });

              this.$Progress.finish();
              Trigger.$emit('RefreshData');

            }).catch(()=>{
              toastr.fire({
                icon: 'error',
                title: 'Access permision setting failed'
              });

              this.$Progress.fail();

            });
          },


          loadUsers() {
            this.$Progress.start();

            let query = this.$parent.search;
            let page = this.users.current_page;

            if(query == ''){
              axios.get('api/users?page=' + page).then(({ data }) => (this.users = data));
            }else{
              axios.get('api/findUser?q=' + query + '&page=' + page).then(({ data }) => (this.users = data));
            }

            this.$Progress.finish();
          },

          isPermitted(moduleid) {
            axios.get('api/permission/' + this.$gate.showMyID() + '/' + moduleid)
            .then(data => {
                // here you can access the data
                this.accessflag = data.data.isChecked;
            });
          },


        },

        computed: {
          second() {
            return this.time / 1000;
          },
          isIdle() {
            const idlestat =  this.$store.state.idleVue.isIdle;
            if (idlestat == true) {
              $('#sessionmodal').modal('show');
              this.countDownTimer();
            } else {
              $('#sessionmodal').modal('hide');
            }
            return idlestat;
          },
        },

        created() {
          Trigger.$on('searching',() => {
            let query = this.$parent.search;
            let emptyflag = false;

            if(query == ''){
              Trigger.$emit('RefreshData');
            }else{
              this.$Progress.start();
              axios.get("api/findUser?q=" + query)
              .then((data) => {
                this.users = data.data;

                if (data.data.total == 0){
                  //console.log("empty");
                  this.emptyflag = true;
                }

                this.$Progress.finish();
              })
              .catch(() => {
                this.$Progress.fail();
              })
            }
          });


          //axios.get("api/profile").then(({ data }) => (this.profile = data));
          this.loadUsers();
          this.isPermitted(16);

          Trigger.$on('RefreshData',() => {
            this.loadUsers();
            axios.get('api/modules/access/' + this.selectedid).then(({ data }) => (this.accesses = data));
          });
        }
    }



</script>
