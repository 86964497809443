<template>
    <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card card-secondary card-outline">
              <div class="card-header bg-info">
                <h4 class="card-title"><i class="fas fa-shopping-cart"></i> Procurement Items</h4>

                <div class="card-tools">
                  <b>Items Displayed:</b> {{procurementitems.total}} <!-- | Auth Token:  {{ bearer_token }}  <button class="btn btn-outline-secondary" type="button" @click="pushToDpmis()" >Get Token</button>-->


                    <button v-show="can_add" @click="newModal" data-toggle="tooltip" data-container="body" title="Add New" class="btn btn-success btn-sm ml-2"><i class="fa fa-plus"></i> ADD ITEM</button>
  
                  
                </div>
              </div>
              
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0 m-0">
                

                <b-table :items="procurementitems.data" :fields="fields" show-empty empty-text="No items" responsive="sm" stacked="md" sort-icon-left class="table b-table table-striped table-sm">

                  <template #cell(specs)="row">
                    <b-button variant="outline-default" style="width: 80px;" size="sm" @click="row.toggleDetails" onclick="this.blur()" class="mr-2 btn btn-block">
                      <i v-if="row.detailsShowing" class='fa fa-minus-square text-primary'></i>
                      <i v-else class='fa fa-plus-square text-secondary'></i>
                    </b-button>
                  </template>

                  <template #cell(in_pss)="row">
                    <i v-if="row.item.in_pss==1" class='fa fa-check text-success'></i>
                    <i v-else class='fa fa-times text-danger'></i>
                  </template>

                  <template #cell(options)="row">
                    <div class="btn-group">
                      <button v-if="can_edit" type="button" @click="viewModal(row.item)" class="btn btn-default"><i class="fas fa-edit text-primary"></i></button><button v-else type="button" class="btn btn-default" disabled><i class="fas fa-edit text-primary"></i></button>
                      <button v-if="can_delete" type="button" @click="deleteItem(row.item.id)" class="btn btn-default"><i class="fas fa-trash text-danger"></i></button><button v-else type="button" class="btn btn-default" disabled><i class="fas fa-trash text-danger"></i></button>
                    </div>
                  </template>

                  <template #row-details="row">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="card">
                            <div class="card-body p-4">
                               <b>Specification Details:</b> <span class="text-dark" v-html="row.item.specs"></span>
                            </div>
                            <b-button size="sm" variant="outline-default" class="btn btn-block" @click="row.toggleDetails"><i class="fa fa-chevron-up"></i> Hide Details</b-button>
                          </div>
                        </div>
                      </div>
                      
                  </template>

                </b-table>
              </div>
              <div class="card-footer">
                  <pagination :data="procurementitems" @pagination-change-page="getResults" show-disabled :limit="2"></pagination>
              </div>
              
            </div>
            <!-- /.card -->
          </div>
        </div>

        <!-- Modal -->
      <div class="modal fade" id="actionmodal" tabindex="-1" role="dialog" aria-labelledby="actionModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 v-if="this.editmode" class="modal-title" id="actionModalLabel">Update Procurement Item</h5>
              <h5 v-else class="modal-title" id="actionModalLabel">Add New Procurement Item</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div class="modal-body">
              <div class="row">

                <div class="col-sm-6">
                  <div class="form-group">
                    <label>Item Code</label>
                    <input type="text" class="form-control" v-model="form.item_code" :class="{ 'is-invalid': form.errors.has('item_code') }">
                    <has-error :form="form" field="item_code"></has-error>
                  </div>
                  <div class="form-group">
                    <label>Category</label>
                    <selectize class="form-control" v-model="form.category_id" placeholder="- Select Category -" :class="{ 'is-invalid': form.errors.has('category_id') }">
                      <option v-for="(itm,idx) in procurementitemcategories" :value="itm.id" :key="idx">{{ itm.name }}</option>
                    </selectize>
                    <has-error :form="form" field="category_id"></has-error>
                  </div>
                  <div class="form-group">
                    <label>Description</label>
                    <textarea class="form-control" rows="3" data-gramm="false" wt-ignore-input="true" v-model="form.description" :class="{ 'is-invalid': form.errors.has('description') }"></textarea>
                    <has-error :form="form" field="description"></has-error>
                  </div>
                  <div class="form-group">
                    <label>Unit of Measure</label>
                    <selectize class="form-control" v-model="form.uom_id" placeholder="- Select Unit -" :class="{ 'is-invalid': form.errors.has('uom_id') }">
                      <option v-for="(unt,idx) in units" :value="unt.id" :key="idx">{{ unt.name }}</option>
                    </selectize>
                    <has-error :form="form" field="uom_id"></has-error>
                  </div>
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label class="mb-3">Available at PS Store</label><br/>
                        <input type="radio" id="yes" value="1" v-model="form.in_pss" /><label class="text-success ml-1" for="yes">Yes</label>
                        <input class="ml-3" type="radio" id="no" value="0" v-model="form.in_pss" /><label class="text-danger ml-1" for="no">No</label>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label>PS Code</label>
                        <input type="text" class="form-control" v-model="form.ps_code">
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label>Price</label>
                        <input type="text" class="form-control" v-model="form.ps_price" :class="{ 'is-invalid': form.errors.has('ps_price') }">
                        <has-error :form="form" field="ps_price"></has-error>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <label>As of</label>
                        <input type="date" class="form-control" placeholder="yyyy-mm-dd" v-model="form.ps_date" :class="{ 'is-invalid': form.errors.has('ps_date') }">
                        <has-error :form="form" field="ps_date"></has-error>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-sm-6">
                  <div class="form-group">
                    <label>Item Specifications</label>
                    <textarea class="form-control" rows="11" data-gramm="false" wt-ignore-input="true" v-model="form.specs" :class="{ 'is-invalid': form.errors.has('specs') }"></textarea>
                    <has-error :form="form" field="specs"></has-error>
                  </div>
                  <div class="form-group">
                    <label>Remarks</label>
                    <input type="text" class="form-control" v-model="form.remarks">
                  </div>
                  <div class="form-group">
                    <label class="mb-3">Status</label><br/>
                    <input type="radio" id="yes" value="0" v-model="form.inactive" /><label class="text-success ml-1" for="yes">Active</label>
                    <input class="ml-3" type="radio" id="no" value="1" v-model="form.inactive" /><label class="text-danger ml-1" for="no">Incative</label>
                  </div>
                  <div class="form-group">
                    <label>Created By</label>
                    <selectize class="form-control" v-model="form.ofc_id" placeholder="- Select Office -" :class="{ 'is-invalid': form.errors.has('ofc_id') }">
                      <option v-for="(ofc,idx) in offices" :value="ofc.id" :key="idx">{{ ofc.alias }}</option>
                    </selectize>
                    <has-error :form="form" field="ofc_id"></has-error>
                  </div>
                </div>

              </div>
              
              

              <div class="row">
                
              </div>

              <div class="row">
                
              </div>

            </div>
            <div class="card-footer">
              <button v-if="editmode" class="btn btn-primary" data-container="body" type="button" @click="updateItem()"><i class="fa fa-save"></i> Update</button>
              <button v-else class="btn btn-primary" data-container="body" type="button" @click="saveItem()"><i class="fa fa-save"></i> Save</button>
              <button class="btn btn-primary" data-container="body" type="button" data-dismiss="modal" aria-label="Close"><i class="fa fa-times"></i> Close</button>
            </div>
          </div>
        </div>
      </div>


        

      </div>

</template>

<script>

    import Form from 'vform';
    import { VueEditor } from "vue2-editor";
    import moment from 'moment';
    
    export default {
        name: 'ProcurementItems',

        components: {
            VueEditor,
        },

        data() {
          return {
            timer: 30,
            editmode: false,
            loading: false,
            emptyflag: false,
            can_access: false,
            can_add: false,
            can_edit: false,
            can_delete: false,
            procurementitems : {},
            procurementitemcategories: {},
            units: {},
            offices: {},
            fields: ['_', 'description', 'item_code', 'category_name.name', 'specs', 'unit_name.abbr', 'in_pss','ps_code','ps_price','ps_date'],
            fields: [
              {
                key: 'specs',
                label: '',
                sortable: false
              },
              {
                key: 'description',
                label: 'Description',
                sortable: false,
              },
              {
                key: 'item_code',
                sortable: false
              },
              {
                key: 'category_name.name',
                label: 'Category',
                sortable: false
              },
              {
                key: 'unit_name.abbr',
                label: 'Unit',
                sortable: false,         
              },
              {
                key: 'in_pss',
                label: 'In-PSS',
                sortable: false,
              },
              {
                key: 'ps_code',
                label: 'PS-Code',
                sortable: false,
              },
              {
                key: 'ps_price',
                label: 'Price',
                sortable: false,
              },
              {
                key: 'ps_date',
                label: 'Date',
                sortable: false,
              },
              {
                key: 'options',
                label: '',
                sortable: false,
              }
            ],
            form: new Form({
              id: 0,
              item_code: '',
              category_id: 0,
              object_id: 0,
              description: '',
              specs: '',
              uom_id: 55,
              remarks: '',
              in_pss: 0,
              ps_code: '',
              ps_price: 0.00,
              ps_date: '',
              ofc_id: 0,
              inactive: 0,
            }),
          }
        },

        props: [
          'todo'
        ],

        computed: {
          
          second() {
            return this.time / 1000;
          },

          isIdle() {
            const idlestat =  this.$store.state.idleVue.isIdle;
            if (idlestat == true) {
              $('#sessionmodal').modal('show');
              this.countDownTimer();
            } else {
              $('#sessionmodal').modal('hide');
            }
            return idlestat;
          },

        },

        methods: {

          countDownTimer() {
            let timerId = setInterval(() => {
              this.timer -= 1;
              if (!this.$store.state.idleVue.isIdle) clearInterval(timerId);

              if (this.timer < 1) {
                clearInterval(timerId);
                document.getElementById('logout-form').submit();
              }
            }, 1000);

            this.timer = 30;
          },

          updateItem() {
            this.$Progress.start();
            this.form.put('api/procurementitems/'+this.form.id).then(()=>{
              Trigger.$emit('RefreshData');
              $('#actionmodal').modal('hide')
              toastr.fire({
                icon: 'success',
                title: 'Item Saved'
              })
              this.$Progress.finish();
            }).catch(()=>{
              toastr.fire({
                icon: 'error',
                title: 'Item saving failed'
              });
              this.$Progress.fail();
            });
          },

          saveItem() {
            this.$Progress.start();
            this.form.post('api/procurementitems').then(()=>{
              Trigger.$emit('RefreshData');
              $('#actionmodal').modal('hide')
              toastr.fire({
                icon: 'success',
                title: 'Item Added'
              })
              this.$Progress.finish();
            }).catch(()=>{
              toastr.fire({
                icon: 'error',
                title: 'Item adding failed'
              });
              this.$Progress.fail();
            });
          },

          deleteItem(id) {
            swal.fire({
              title: 'Are you sure?',
              text: "You won't be able to revert this!",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes, delete item!'
              }).then((result)=>{
                if (result.value) {
                  this.form.delete('api/procurementitems/'+id).then(()=>{
                    swal.fire(
                      'Deleted!',
                      'Item has been deleted.',
                      'success'
                    );
                    Trigger.$emit('RefreshData');
                  }).catch(()=>{
                    swal.fire(
                      'Failed!', 
                      'There was something wrong', 
                      'warning'
                    )
                  });
                }
            })
          },

          viewModal(payload) {
            this.editmode = true;
            this.form.reset();
            this.form.clear();
            $('#actionmodal').modal('show');
            this.form.fill(payload);
          },

          newModal() {
            this.editmode = false;
            this.form.reset();
            this.form.clear();
            $('#actionmodal').modal('show');
            this.form.ofc_id = 31;
            this.form.category_id = 1;
            this.form.uom_id = 55;
          },

          getResults(page = 1) {

            this.$Progress.start();

            let query = this.$parent.search;

            if(query == ''){
              axios.get('api/procurementitems?page=' + page).then(response => { this.procurementitems = response.data; }); 
            }else{
              axios.get('api/findProcurementitem?q=' + query + '&page=' + page).then(response => { this.procurementitems = response.data; }); 
            }

            this.$Progress.finish();

            //console.log(query);

            
          },

          loadProcurementitems() {
            this.$Progress.start();
            
            let query = this.$parent.search;
            let page = this.procurementitems.current_page;
            
            if(query == ''){
              axios.get('api/procurementitems?page=' + page).then(({ data }) => (this.procurementitems = data));
            }else{
              axios.get('api/findProcurementitem?q=' + query + '&page=' + page).then(({ data }) => (this.procurementitems = data));
            }

            this.$Progress.finish();
          },

          async loadPermission(mid) {
              const response = await axios.get("api/permission/" + this.$gate.showMyID() + "/" + mid).then( 
              res => {
                if (!res.data || res.data.length == 0) {
                  return false;
                } else {
                  return true;
                }
              })

              const getResponse = async () => {
                const a = await response;

                switch (mid) {
                case 61:
                  this.can_access = a;
                  if (!this.can_access) {
                    this.$router.push({ name: 'not-found' }) //redirect to not found
                  }
                  break;
                case 68:
                  this.can_add = a;
                  break;
                case 67:
                  this.can_edit = a;
                  break;
                case 66:
                  this.can_delete = a;
                }
              };
              return getResponse()
          },

          
        },

        created() {
          Trigger.$on('searching',() => {
            let query = this.$parent.search;
            let emptyflag = false;

            if(query == ''){
              Trigger.$emit('RefreshData');
            }else{
              this.$Progress.start();
              axios.get("api/findProcurementitem?q=" + query)
              .then((data) => {
                this.procurementitems = data.data;
                
                if (data.data.total == 0){
                  this.emptyflag = true;
                }

                this.$Progress.finish();
              })
              .catch(() => {
                this.$Progress.fail();
              })
            }
            
          });

          axios.get('api/offices').then(response => { this.offices = response.data; }); 
          axios.get('api/procurementitemcategories/all').then(response => { this.procurementitemcategories = response.data; });
          axios.get('api/units/all').then(response => { this.units = response.data; });
          
          this.loadProcurementitems();
          this.loadPermission(61); //Can Manage Items
          this.loadPermission(66); //Can Delete Items
          this.loadPermission(67); //Can Edit Items
          this.loadPermission(68); //Can Add Items
          
          Trigger.$on('RefreshData',() => {
            this.loadProcurementitems();
          });
        },

    }
</script>
<style scoped>
div >>> p {
  font-size: 15px;
}

div >>> strong {
  font-size: 15px;
}

div >>> em {
  font-size: 15px;
}

div.header >>> p {
  line-height: 0.5;
  font-size: 20px;
  font-family: "Helvetica Narrow","Arial Narrow";
}

div.header >>> strong {
  line-height: 0.5;
  font-size: 20px;
  font-family: "Helvetica Narrow","Arial Narrow";
}

div.header >>> em {
  line-height: 0.5;
  font-size: 20px;
  font-family: "Helvetica Narrow","Arial Narrow";
}

.text-selection-none{
   user-select: none; /* supported by Chrome and Opera */
   -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
   -moz-user-select: none; /* Firefox */
   -ms-user-select: none;
}
div.tblProject table {
  width: 100% !important;
}

div.tblProject table td, div.tblProject table th {
  border: 1px solid #6C757D !important;
  border-collapse: collapse !important;
  padding: 5px !important;
}

div.tblProject >>> table {
  width: 100% !important;
}

div.tblProject >>> table td, div.tblProject >>> table th {
  border: 1px solid #6C757D !important;
  border-collapse: collapse !important;
  padding: 5px !important;
}

div.tblProject >>> br {
  display: block; /* makes it have a width */
  content: ""; /* clears default height */
  margin-top: 15px; /* change this to whatever height you want it */
}
</style>
