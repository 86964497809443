<template>
    <div class="container-fluid">
        <div class="row">
          <div class="col-md-3">
            <v-calendar is-expanded :attributes='attributes' @dayclick="onDayClick"></v-calendar>
          </div>
          <div class="col-md-9">
            <div class="card card-secondary card-outline">
              <div class="card-header bg-yellow">
                <h4 class="card-title"><i class="fas fa-calendar-alt"></i> ExeCom Deliberations for {{ this.selected.id | customdate }}</h4>

                <div class="card-tools">


                </div>
              </div>
              <!-- /.card-header -->

              <div class="card-body table-responsive p-0">
                <table class="table responsive-table">
                  <thead>
                    <tr>
                      <th scope="col" class="pl-2">Proposal</th>
                      <th scope="col" class="pl-2">Seq #</th>
                      <th scope="col">Classification</th>
                      <th scope="col">Creator</th>
                      <th scope="col">Date Submitted</th>
                      <th scope="col">Options</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="exec in execoms.data" :key="exec.id">
                      <th scope="row" class="pl-2">{{ exec.proposal }}</th>
                      <td data-title="Seq #" class="pl-2">{{ exec.order }}</td>
                      <td data-title="Classification">{{ exec.classification }}</td>
                      <td data-title="Creator">{{ exec.user.fname }} {{ exec.user.lname }}</td>
                      <td data-title="Date Submitted">{{ exec.created_at | fulldate}}</td>
                      <td data-title="Options">
                        <button class="btn btn-outline-primary" data-toggle="tooltip" data-container="body" title="View Attachments" type="button" @click="uploadModal(exec)" ><i class="fa fa-folder-open"></i> OPEN</button>
                      </td>
                      <!--
                      <td>
                        <span v-if="exec.status === 'Pending'" class="badge badge-warning">{{ exec.status }}</span>
                        <span v-if="exec.status === 'Active'" class="badge badge-success">{{ exec.status }}</span>
                        <span v-if="exec.status === 'Cancelled'" class="badge badge-danger">{{ exec.status }}</span>
                      </td>
                      <td>{{ exec.remarks }}</td>
                      <td>{{ exec.schedule | customdate }}</td>
                      -->
                    </tr>
                  </tbody>
                  <tbody v-show="execoms.total==0">
                      <tr><td colspan="10" class="controls">No schedules found on this date.</td></tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                <pagination :data="execoms" @pagination-change-page="getResults" show-disabled :limit="10"></pagination>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <!-- Modal : Upload Files -->
        <div class="modal fade" id="uploadmodal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="uploadModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="execomModalLabel">Attachments for "{{ form.proposal }}" scheduled on {{ form.schedule | customdate }}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div class="modal-body">
              <div class="row">

                  <div class="col-md-12">
                      <div class="col-md-12">
                        <div class="card">
                            <div class="card-body table-responsive p-0">
                                <table class="table responsive-table">
                                <thead>
                                    <tr>
                                    <th scope="col" class="pl-2"></th>
                                    <th scope="col" class="pl-2">File Name</th>
                                    <th scope="col">File Size</th>
                                    <th scope="col">File Type</th>
                                    <th scope="col">Uploader</th>
                                    <th scope="col">Upload Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="fle in execfiles.data" :key="fle.id">
                                      <th scope="row" class="controls pl-2">
                                        <div class="btn-group btn-group-sm">
                                          <button class="btn btn-outline-secondary" data-toggle="tooltip" data-container="body" title="Download" type="button" @click="downloadFile(fle.id, fle.filename)" ><i class="fa fa-download"></i> DOWNLOAD</button>
                                          <button v-if="fle.filetype === 'pdf'" class="btn btn-outline-secondary" data-toggle="tooltip" data-container="body" title="View" type="button"  @click.prevent="viewPDF(fle.uploader, fle.filepath)" ><i class="fa fa-eye"></i> VIEW</button>
                                        <!--<button class="btn btn-outline-secondary" data-toggle="tooltip" data-container="body" title="Delete" type="button" ><i class="fa fa-trash"></i></button>-->
                                        </div>
                                      </th>
                                      <td data-title="File Name">
                                          <i v-if="fle.filetype==='pdf'" class="fas fa-file-pdf text-danger"></i>
                                          <i v-else-if="fle.filetype==='doc'" class="fas fa-file-word text-primary"></i>
                                          <i v-else-if="fle.filetype==='docx'" class="fas fa-file-word text-primary"></i>
                                          <i v-else-if="fle.filetype==='xls'" class="fas fa-file-word text-success"></i>
                                          <i v-else-if="fle.filetype==='xlsx'" class="fas fa-file-word text-success"></i>
                                          <i v-else-if="fle.filetype==='ppt'" class="fas fa-file-word text-orange"></i>
                                          <i v-else-if="fle.filetype==='pptx'" class="fas fa-file-word text-orange"></i>
                                          {{ fle.filename }}
                                      </td>
                                      <td data-title="File Size">{{ fle.filesize }}</td>
                                      <td data-title="File Type">{{ fle.filetype }}</td>
                                      <td data-title="Uploader">{{ fle.user.fname }} {{ fle.user.lname }}</td>
                                      <td data-title="Upload Date">{{ fle.created_at | fulldate }}</td>
                                    </tr>
                                </tbody>
                                <tbody v-show="execfiles.total==0">
                                    <tr><td colspan="10">No files found.</td></tr>
                                </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


    </div>
</template>

<script>
    import Form from 'vform';

    export default {
        data() {
          return {
            timer: 30,
            can_access: false,
            execoms : {},
            execfiles : {},
            schedules : [],
            selected: {
              id: new Date(),
            },
            form: new Form({
              id: '',
              proposal: '',
              classification: '',
              schedule: '',
              status: '',
              remarks: '',
              order: '',
              user_id: '',
            }),

          }
        },

        components: {

        },

        computed: {

          attributes() {
            return [
              // Attributes for todos
              {
                highlight: {
                  color: 'gray',
                  fillMode: 'outline',
                },
                dates: new Date(),
              },

              ...this.schedules.map(sched => ({
                dates: sched.schedule,
                //dot: {
                //  color: 'purple',
                //},
                highlight: {
                  color: 'orange',
                  fillMode: 'light',
                },
                popover: {
                  label: sched.proposal,
                },
                customData: sched,
              })),

              {
                highlight: {
                  color: 'blue',
                  fillMode: 'solid',
                },
                dates: this.selected.id,
              },


            ];
          },

          second() {
            return this.time / 1000;
          },

          isIdle() {
            const idlestat =  this.$store.state.idleVue.isIdle;
            if (idlestat == true) {
              $('#sessionmodal').modal('show');
              this.countDownTimer();
            } else {
              $('#sessionmodal').modal('hide');
            }

            return idlestat;

          },

        },

        methods: {

            onDayClick(day) {
              this.selected = day;

              //console.log(this.selected.id);
              this.setCalendarDate(this.selected.id)
              this.$Progress.start();
              axios.get('api/execoms/active/' + this.selected.id).then(({ data }) => (this.execoms = data));
              this.$Progress.finish();
            },

            setCalendarDate(day) {
                return day;
            },

            countDownTimer() {
                let timerId = setInterval(() => {
                this.timer -= 1;
                if (!this.$store.state.idleVue.isIdle) clearInterval(timerId);

                if (this.timer < 1) {
                    clearInterval(timerId);
                    document.getElementById('logout-form').submit();
                }
                }, 1000);

                this.timer = 30;
            },

            //handleDateClick: function(arg) {
            //  alert('date click! ' + arg.dateStr)
            //},

            uploadModal(execom) {
                $('#uploadmodal').modal('show')
                this.form.fill(execom);
                this.loadExecfiles();
            },

            getResults(page = 1) {

                let query = this.$parent.search;

                if(query == ''){
                    axios.get('api/execoms?page=' + page).then(response => { this.execoms = response.data; });
                } else {
                    axios.get('api/findExecom?q=' + query + '&page=' + page).then(response => { this.execoms = response.data; });
                }

            },


            loadExecoms() {
                var todayDate = new Date().toISOString().slice(0, 10);

                this.$Progress.start();
                axios.get('api/execoms/active/' + todayDate).then(({ data }) => (this.execoms = data));
                axios.get('api/execomsched/active').then(({ data }) => (this.schedules = data));
                this.$Progress.finish();
            },

            loadExecfiles() {
                this.$Progress.start();
                axios.get('api/execomfiles/' + this.form.id).then(({ data }) => (this.execfiles = data));
                this.$Progress.finish();
            },

            viewPDF(uploader, filepath) {
              window.open("/storage/execom_files/" + uploader + '/' + filepath, "_blank");
            },

            downloadFile(id, title) {
              axios({
                url: 'api/download/' + id,
                method: 'GET',
                responseType: 'blob', // important
              }).then((response) => {
                if (!window.navigator.msSaveOrOpenBlob){
                  // BLOB NAVIGATOR
                  const url = window.URL.createObjectURL(new Blob([response.data]));
                  const link = document.createElement('a');
                  link.href = url;
                  link.setAttribute('download', title);
                  document.body.appendChild(link);
                  link.click();
                  toastr.fire({
                        icon: 'success',
                        title: 'File downloaded'
                      });
                }else{
                  // BLOB FOR EXPLORER 11
                  const url = window.navigator.msSaveOrOpenBlob(new Blob([response.data]), title);
                }
              });
            },

            async loadPermission(mid) {
              const response = await axios.get("api/permission/" + this.$gate.showMyID() + "/" + mid).then(
              res => {
                if (!res.data || res.data.length == 0) {
                  return false;
                } else {
                  return true;
                }
              })

              const getResponse = async () => {
                const a = await response;

                switch (mid) {
                case 28:
                  this.can_access = a;
                  if (!this.can_access) {
                    this.$router.push({ name: 'not-found' }) //redirect to not found
                  }
                }
              };
              return getResponse()
          },



        },

        created() {

          this.loadExecoms();
          this.loadPermission(28); //Can access execom deliberation

          Trigger.$on('RefreshData',() => {
            this.loadExecoms();
          });
        },
    }
</script>

<style scoped>
.controls {
  text-align: left;
}
</style>
