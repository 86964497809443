<template>
    <div class="">

        <div class="row mb-2">
            <div class="col-sm-12">
                <h2>Project Data Analytics</h2>
            </div>
        </div>


                <div class="row">
                    <div class="col-md-3">
                        <div class="card">
                            <div class="card-header">
                                <h3 class="card-title">
                                <i class="fa fa-list-ol"></i>
                                Dimensions
                                </h3>
                                <div class="card-tools">
                                    <button type="button" class="btn btn-tool" data-card-widget="collapse"><i class="fas fa-minus"></i></button>
                                </div>
                            </div>

                            <div class="card-body p-2">
                                <div class="form-group">
                                    <label>Time Period Type</label>
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" name="month" value="month" v-model="range">
                                        <label class="form-check-label">Month</label>
                                    </div>
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" name="year" value="year" v-model="range">
                                        <label class="form-check-label">Year</label>
                                    </div>
                                </div>
                                <hr>
                                <div class="form-group">
                                    <label>Chart Time Period</label>
                                    <select class="form-control" v-model="num">
                                        <template v-if="range==='month'">
                                            <option value="1">This Month</option>
                                            <option value="12">Last 12 Months</option>
                                            <option value="24">Last 24 Months</option>
                                            <option value="36">Last 36 Months</option>
                                            <option value="48">Last 48 Months</option>
                                            <option value="60">Last 60 Months</option>
                                            <option value="72">Last 72 Months</option>
                                            <option value="84">Last 84 Months</option>
                                            <option value="100">Last 100 Months</option>
                                            <option value="all">ALL</option>
                                        </template>
                                        <template v-else>
                                            <option value="1">This Year</option>
                                            <option value="2">Last 2 Years</option>
                                            <option value="3">Last 3 Years</option>
                                            <option value="5">Last 5 Years</option>
                                            <option value="10">Last 10 Years</option>
                                            <option value="15">Last 15 Years</option>
                                            <option value="20">Last 20 Years</option>
                                            <option value="30">Last 30 Years</option>
                                            <option value="all">ALL</option>
                                        </template>
                                    </select>
                                </div>
                                <hr>
                                <div class="form-group">
                                    <label>Amount Requested</label><a class="float-right" href="#" role="button" @click.prevent="clearAmount">[clear]</a>
                                    <div class="container">
                                        <div class="row">
                                            <div class="col-lg-5 pl-0">
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text">MIN</span>
                                                    </div>
                                                    <input type="number" v-model="min" class="form-control">
                                                </div>
                                            </div>
                                            <div class="col-lg-5 pl-0">
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text">MAX</span>
                                                    </div>
                                                    <input type="number" v-model="max" class="form-control">
                                                </div>
                                            </div>
                                            <div class="col-lg-2 pl-0 pr-0">
                                                <div class="input-group">
                                                    <button type="button" v-if="min === null || max === null" disabled class="btn btn-block btn-default" @click.prevent="setAmount"><i class="fa fa-play" aria-hidden="true"></i></button>
                                                    <button type="button" v-else :disabled="parseInt(min) > parseInt(max)" class="btn btn-block btn-default" @click.prevent="setAmount"><i class="fa fa-play" aria-hidden="true"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr>
                                <div class="form-group mb-0">
                                    <label>Industry</label><a class="float-right" href="#" role="button" @click.prevent="clearIndustries">[clear]</a>
                                    <ul class="products-list product-list-in-card pl-0 pr-2">
                                        <li class="item p-0 pb-1" v-for="(ind, index) in displayedIndustries" :key="ind.id">
                                            <div class="">
                                                <span class="product-title">
                                                    <span class="float-left pt-1"><input type="checkbox" :id="'chk' + ind.id" :value="ind.id" v-model="selectedindustries" /></span>
                                                </span>
                                                <span class="product-description pt-1 pl-2">{{ ind.industryname }}</span>
                                            </div>
                                        </li>
                                    </ul>
                                    <div v-if="projectindustries.length > maxDisplayedIndustries">
                                        <button @click="toggleShowMoreIndustries" class="btn btn-link">{{ showMoreIndustries ? "Show Less" : "Show More" }}</button>
                                    </div>
                                </div>
                                <hr>
                                <div class="form-group mb-0">
                                    <label>Action Events</label><a class="float-right" href="#" role="button" @click.prevent="clearActions">[clear]</a>
                                    <ul class="products-list product-list-in-card pl-0 pr-2">
                                        <li class="item p-0 pb-1" v-for="(pa, index) in displayedActions" :key="pa.id">
                                            <div class="">
                                                <span class="product-title">
                                                    <span class="float-left pt-1"><input type="checkbox" :id="'chk' + pa.id" :value="pa.id" v-model="selectedactions" /></span>
                                                </span>
                                                <span class="product-description pt-1 pl-2">{{ pa.action }}</span>
                                            </div>
                                        </li>
                                    </ul>
                                    <div v-if="projectactions.length > maxDisplayedActions">
                                        <button @click="toggleShowMoreActions" class="btn btn-link">{{ showMoreActions ? 'Show Less' : 'Show More' }}</button>
                                    </div>
                                </div>
                                <hr>
                                <div class="form-group mb-0">
                                    <label>Filter Action By Year & Month</label><a :disabled="selectedactions.length == 0" class="float-right" href="#" role="button" @click.prevent="clearActionsDate">[clear]</a>
                                    <div class="container mb-2">
                                    <div class="row mb-2"><span class="text-info"><i class="fas fa-info-circle"></i> Select any action to enable filtering</span></div>
                                    <div class="row">
                                        <div class="col-lg pl-0">
                                            <div class="input-group">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text">MONTH</span>
                                                </div>
                                                <select name="type" :disabled="selectedactions.length == 0" v-model="action_month" class="form-control">
                                                    <option value=''>- MONTH -</option>
                                                    <option value='01'>JANUARY</option>
                                                    <option value='02'>FEBRUARY</option>
                                                    <option value='03'>MARCH</option>
                                                    <option value='04'>APRIL</option>
                                                    <option value='05'>MAY</option>
                                                    <option value='06'>JUNE</option>
                                                    <option value='07'>JULY</option>
                                                    <option value='08'>AUGUST</option>
                                                    <option value='09'>SEPTEMBER</option>
                                                    <option value='10'>OCTOBER</option>
                                                    <option value='11'>NOVEMBER</option>
                                                    <option value='12'>DECEMBER</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-lg pl-0">
                                            <div class="input-group">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text">YEAR</span>
                                                </div>
                                                <select name="type" :disabled="selectedactions.length == 0" v-model="action_year" class="form-control">
                                                    <option value=''>- YEAR -</option>
                                                    <option v-for="year in years" :value='year' :key='year'>{{ year }}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header">
                                <h3 class="card-title"><i class="fa fa-list mr-1"></i>Recently Added Projects</h3>
                                <div class="card-tools">
                                    <button type="button" class="btn btn-tool" data-card-widget="collapse"><i class="fas fa-minus"></i></button>
                                </div>
                            </div>

                            <div class="card-body p-0">
                                <ul class="products-list product-list-in-card pl-2 pr-2">
                                    <li class="item" v-for="exec in execoms.data" :key="exec.id">
                                        <div class="">
                                            <span class="product-title">{{ exec.classification }}
                                                <span class="badge badge-warning float-right">Region {{ exec.region }}</span>
                                            </span>
                                            <span class="product-description">{{ exec.proposal }}</span>
                                        </div>
                                    </li>
                                </ul>
                            </div>

                            <div class="card-footer text-center">
                                <router-link class="" :to='"projects"'>View All Projects</router-link>
                            </div>

                        </div>
                    </div>

                    <div class="col-md-9">

                            <div class="col-md-12 p-0">
                                <div class="card">
                                    <div class="card-header">
                                        <h3 class="card-title">
                                        <i class="fa fa-clock"></i>
                                        Projects in Time Period Metrics
                                        </h3>
                                        <div class="card-tools">
                                            <button type="button" class="btn btn-tool" data-card-widget="collapse"><i class="fas fa-minus"></i></button>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <div class="d-flex justify-content-between">
                                            <p class="d-flex flex-column">
                                                <span class="text-bold text-lg" v-if="execoms">{{ execoms.total }}</span>
                                                <span>Projects Over Time</span>
                                            </p>
                                            <p class="d-flex flex-column">
                                                <span class="text-bold text-lg">{{ totalRequest }}</span>
                                                <span>Projects On Dimension</span>
                                            </p>
                                            <p class="d-flex flex-column text-right">
                                                <span :class="{
                                                    'text-danger': percentageChange(metrics[metrics.length - 1].project_count, metrics[metrics.length - 2].project_count) < 0,
                                                    'text-success': percentageChange(metrics[metrics.length - 1].project_count, metrics[metrics.length - 2].project_count) > 0,
                                                    'text-warning': percentageChange(metrics[metrics.length - 1].project_count, metrics[metrics.length - 2].project_count) === 0
                                                    }" v-if="metrics[metrics.length - 1] && metrics.length != 1">
                                                    <i class="fas" :class="{
                                                        'fa-arrow-down': percentageChange(metrics[metrics.length - 1].project_count, metrics[metrics.length - 2].project_count) < 0,
                                                        'fa-arrow-up': percentageChange(metrics[metrics.length - 1].project_count, metrics[metrics.length - 2].project_count) > 0,
                                                        'fa-arrow-left': percentageChange(metrics[metrics.length - 1].project_count, metrics[metrics.length - 2].project_count) === 0
                                                    }"></i> {{ percentageChange(metrics[metrics.length - 1].project_count, metrics[metrics.length - 2].project_count) }}<span v-show="percentageChange(metrics[metrics.length - 1].project_count, metrics[metrics.length - 2].project_count) != '♾'">%</span>
                                                </span>
                                                <span class="text-warning" v-else>
                                                    <i class="fas fa-arrow-left"></i> 0%
                                                </span>
                                                <span class="text-muted">Since last {{ range }}</span>
                                            </p>
                                        </div>
                                        <line-chart v-if="loaded" :chartdata="chartdata" :options="options"></line-chart>
                                    </div>
                                    <div class="m-4 text-primary">
                                        <b>Projects Trend Summary</b>
                                        <p v-if="summaryText"><i>{{ summaryText }}</i></p>
                                        <p v-else>No project data available.</p>
                                    </div>
                                    <div class="card-footer table-responsive p-0 mb-0" style="max-height: 225px;">
                                        <table class="table table-head-fixed table-hover mb-0" v-if="maploaded">

                                            <tbody>
                                                <tr v-for="(pr, index) in reversedData">
                                                    <td><b>{{ pr.date }}</b></td>
                                                    <td>{{ pr.project_count }} Projects</td>
                                                    <td>
                                                        <template  v-if="index === reversedData.length - 1">
                                                            <span class="float-right text-warning">
                                                                <i class="fas fa-arrow-left text-sm"></i>
                                                            </span>
                                                        </template>
                                                        <template v-else>
                                                            <span class="float-right" :class="{
                                                                'text-danger': pr.project_count - reversedData[index + 1].project_count < 0,
                                                                'text-success': pr.project_count - reversedData[index + 1].project_count > 0,
                                                                'text-warning': pr.project_count - reversedData[index + 1].project_count === 0
                                                                }">
                                                                {{ percentageChange(pr.project_count, reversedData[index + 1].project_count) }}<span v-show="percentageChange(pr.project_count, reversedData[index + 1].project_count) != '♾'">%</span>
                                                                <i class="fas" :class="{
                                                                    'fa-arrow-down': pr.project_count - reversedData[index + 1].project_count < 0,
                                                                    'fa-arrow-up': pr.project_count - reversedData[index + 1].project_count > 0,
                                                                    'fa-arrow-left': pr.project_count - reversedData[index + 1].project_count === 0
                                                                }"></i>
                                                            </span>
                                                        </template>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-12">
                                <div class="row">

                                        <div class="col-md-7">
                                            <div class="card">
                                                <div class="card-header">
                                                    <h3 class="card-title">
                                                    <i class="fa fa-map"></i>
                                                    Projects in Geographic Bubble
                                                    </h3>
                                                    <div class="card-tools">
                                                        <button type="button" class="btn btn-tool" data-card-widget="collapse"><i class="fas fa-minus"></i></button>
                                                    </div>
                                                </div>
                                                <div class="card-body p-0">
                                                    <PhilippinesMap v-if="maploaded" :geojson="geodata" />
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-5">
                                            <div class="card">
                                                <div class="card-header">
                                                    <h3 class="card-title"><i class="fa fa-globe"></i> Projects in Regional Metrics</h3>
                                                    <div class="card-tools">
                                                        <button type="button" class="btn btn-tool" data-card-widget="collapse"><i class="fas fa-minus"></i></button>
                                                    </div>
                                                </div>
                                                <div class="card-body">
                                                    <RegionalProjects v-if="maploaded" :datajson="geodata" />
                                                </div>
                                            </div>
                                            <div class="card">
                                                <div class="card-header">
                                                    <h3 class="card-title"><i class="fa fa-globe"></i> Last {{ range | capitalizewords }}'s Regional Projects Trend</h3>
                                                    <div class="card-tools">
                                                        <button type="button" class="btn btn-tool" data-card-widget="collapse"><i class="fas fa-minus"></i></button>
                                                    </div>
                                                </div>
                                                <div class="card-body table-responsive p-0 mb-0" style="max-height: 455px;">
                                                    <table class="table table-head-fixed table-hover table-striped mb-0" v-if="maploaded">
                                                        <thead>
                                                            <tr>
                                                                <th>Region</th>
                                                                <th>Last {{ range | capitalizewords }}</th>
                                                                <th>This {{ range | capitalizewords }}</th>
                                                                <th><span class="float-right">+/-</span></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="(geo, index) in geodata.features">
                                                                <td>Region {{ geo.properties.name }}</td>
                                                                <td>{{ geo.properties.projects_last }}</td>
                                                                <td>{{ geo.properties.projects_now }}</td>
                                                                <td>
                                                                    <span :class="{
                                                                        'text-danger': geo.properties.projects_now - geo.properties.projects_last < 0,
                                                                        'text-success': geo.properties.projects_now - geo.properties.projects_last > 0,
                                                                        'text-warning': geo.properties.projects_now - geo.properties.projects_last === 0
                                                                        }" class="float-right">
                                                                        {{ geo.properties.percentage_change }}<span v-show="geo.properties.percentage_change != '♾'">%</span><i class="fas" :class="{
                                                                            'fa-arrow-down': geo.properties.projects_now - geo.properties.projects_last < 0,
                                                                            'fa-arrow-up': geo.properties.projects_now - geo.properties.projects_last > 0,
                                                                            'fa-arrow-left': geo.properties.projects_now - geo.properties.projects_last === 0
                                                                        }" />
                                                                    </span>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>

                                </div>
                            </div>



                    </div>
                </div>




    </div>

</template>

<script>
    import LineChart from '../charts/LineChart'
    import moment from 'moment';
    import PhilippinesMap from '../charts/PhilippinesMap'
    import UserDivisionChart from '../charts/UserDivisionChart'
    import RegionalProjects from '../charts/RegionalProjects'

    export default {

        components: {
            LineChart, PhilippinesMap, UserDivisionChart, RegionalProjects
        },

        data() {
          return {
            timer: 30,
            editmode: false,
            emptyflag: false,
            can_access: false,
            analytics : [],
            projectindustries: [],
            projectactions: [],
            selectedactions: [],
            selectedindustries: [],
            chartdata: [],
            geodata: null,
            options: null,
            loaded: false,
            maploaded: false,
            range: 'month',
            num: 24,
            metrics: [],
            execoms : {},
            maxDisplayedActions: 10,
            maxDisplayedIndustries: 5,
            showMoreActions: false,
            showMoreIndustries: false,
            min: null,
            max: null,
            summaryText: '',
            action_month: '',
            action_year: '',
            }
        },

        computed: {

          second() {
            return this.time / 1000;
          },

          totalRequest() {
            return this.metrics.reduce((acc, item) => acc + item.project_count, 0);
          },

          reversedData() {
            return this.metrics.slice().reverse();
          },

          isIdle() {
            const idlestat =  this.$store.state.idleVue.isIdle;
            if (idlestat == true) {
              $('#sessionmodal').modal('show');
              this.countDownTimer();
            } else {
              $('#sessionmodal').modal('hide');
            }

            return idlestat;

          },

          years() {
            const year = new Date().getFullYear()
            return Array.from({length: year - 1999}, (value, index) => 2000 + index)
          },

          displayedActions() {
            if (this.showMoreActions) {
                return this.projectactions;
            } else {
                return this.projectactions.slice(0, this.maxDisplayedActions);
            }
          },

          displayedIndustries() {
            if (this.showMoreIndustries) {
                return this.projectindustries;
            } else {
                return this.projectindustries.slice(0, this.maxDisplayedIndustries);
            }
          },
        },

        watch: {
            selectedindustries(newValue, oldValue) {
                // Trigger your function here
                this.handleSelectedIndustries(newValue);
            },
            selectedactions(newValue, oldValue) {
                // Trigger your function here
                this.handleSelectedActions(newValue);
            },
            action_year(newValue, oldValue) {
                // Trigger your function here
                this.handleYearActions(newValue);
            },
            action_month(newValue, oldValue) {
                // Trigger your function here
                this.handleMonthActions(newValue);
            },
            range(newValue, oldValue) {
                // Trigger your function here
                this.handleSelectedRange(newValue);
            },
            num(newValue, oldValue) {
                // Trigger your function here
                this.handleSelectedPeriod(newValue);
            }
        },

        methods: {

            generateEnhancedSummary() {
                const projectCounts = this.metrics.map(item => item.project_count);
                const totalProjects = projectCounts.reduce((a, b) => a + b, 0);
                const averageProjects = totalProjects / projectCounts.length;

                const maxProjects = Math.max(...projectCounts);
                const minProjects = Math.min(...projectCounts);

                const maxRange = this.metrics.filter(item => item.project_count === maxProjects).map(item => item.date);
                const minRange = this.metrics.filter(item => item.project_count === minProjects).map(item => item.date);

                let summary = '';

                if (totalProjects === 0) {
                    summary = `During the analyzed period, no projects were recorded. All ${this.range}s reported a project count of 0.`;
                } else {
                    summary = `Over the period analyzed, there were a total of ${totalProjects} projects, averaging ${averageProjects.toFixed(2)} projects per ${this.range}. `;

                    if (maxRange.length > 1) {
                        summary += `The highest project count was ${maxProjects} projects in the ${this.range}s ${maxRange.join(', ')}. `;
                    } else {
                        summary += `The highest project count was in ${maxRange[0]} with ${maxProjects} projects. `;
                    }

                    if (minProjects === 0) {
                        summary += `There were multiple ${this.range}s with the lowest project count of 0 projects: ${minRange.join(', ')}. `;
                    } else {
                        summary += `The lowest project count was in ${minRange.join(', ')} with ${minProjects} projects. `;
                    }

                    summary += this.generateTrendDescription(projectCounts);
                }

                return summary;
            },

            generateTrendDescription(projectCounts) {
                let trendDescription = '';
                let increases = 0;
                let decreases = 0;
                let stable = 0;

                for (let i = 1; i < projectCounts.length; i++) {
                    if (projectCounts[i] > projectCounts[i - 1]) {
                        increases++;
                    } else if (projectCounts[i] < projectCounts[i - 1]) {
                        decreases++;
                    } else {
                        stable++;
                    }
                }

                if (increases > decreases) {
                    trendDescription += 'The data indicates an overall increasing trend, with more years showing growth than decline. ';
                } else if (decreases > increases) {
                    trendDescription += 'The data suggests a decreasing trend, with more years experiencing a drop in project counts. ';
                } else {
                    trendDescription += 'The project counts have remained relatively stable over the period, with fluctuations but no clear upward or downward trend. ';
                }

                trendDescription += `The highest project count recorded was ${Math.max(...projectCounts)} projects, while the lowest was ${Math.min(...projectCounts)} projects.`;

                return trendDescription;
            },

            setAmount() {
                // Perform actions based on the updated selectedactions array
                this.loadData(this.range, this.num, this.selectedactions, this.selectedindustries, this.min, this.max, this.action_year, this.action_month)
                this.loadMap(this.range, this.num, this.selectedactions, this.selectedindustries, this.min, this.max, this.action_year, this.action_month)
            },

            clearAmount() {
                this.min = null;
                this.max = null;
                this.setAmount()
            },

            clearActions() {
                this.selectedactions = [];
            },

            clearActionsDate() {
                this.action_year = '';
                this.action_month = '';
            },

            clearIndustries() {
                this.selectedindustries = [];
            },

            toggleShowMoreActions() {
                this.showMoreActions = !this.showMoreActions;
            },

            toggleShowMoreIndustries() {
                this.showMoreIndustries = !this.showMoreIndustries;
            },

            countDownTimer() {
                let timerId = setInterval(() => {
                this.timer -= 1;
                if (!this.$store.state.idleVue.isIdle) clearInterval(timerId);

                if (this.timer < 1) {
                    clearInterval(timerId);
                    document.getElementById('logout-form').submit();
                }
                }, 1000);
                this.timer = 30;
            },

            percentageChange(newValue, oldValue) {
                if (oldValue === 0) {
                    return '♾';
                } else {
                    const change = newValue - oldValue;
                    return parseInt(Math.round(((change / oldValue) * 100)).toFixed(2)); // Fixed to 2 decimal places
                }
            },

            handleSelectedActions(selectedactions) {
                // Perform actions based on the updated selectedactions array
                if (selectedactions.length == 0)
                {
                    this.action_year = '';
                    this.action_month = '';
                }
                this.loadData(this.range, this.num, selectedactions, this.selectedindustries, this.min, this.max, this.action_year, this.action_month)
                this.loadMap(this.range, this.num, selectedactions, this.selectedindustries, this.min, this.max, this.action_year, this.action_month)
            },

            handleYearActions(action_year) {
                // Perform actions based on the updated selectedactions array
                this.loadData(this.range, this.num, this.selectedactions, this.selectedindustries, this.min, this.max, action_year, this.action_month)
                this.loadMap(this.range, this.num, this.selectedactions, this.selectedindustries, this.min, this.max, action_year, this.action_month)
            },

            handleMonthActions(action_month) {
                // Perform actions based on the updated selectedactions array
                this.loadData(this.range, this.num, this.selectedactions, this.selectedindustries, this.min, this.max, this.action_year, action_month)
                this.loadMap(this.range, this.num, this.selectedactions, this.selectedindustries, this.min, this.max, this.action_year, action_month)
            },

            handleSelectedIndustries(selectedindustries) {
                this.loadData(this.range, this.num, this.selectedactions, selectedindustries, this.min, this.max, this.action_year, this.action_month)
                this.loadMap(this.range, this.num, this.selectedactions, selectedindustries, this.min, this.max, this.action_year, this.action_month)
            },

            handleSelectedRange(selectedrange) {
                if(selectedrange == 'month') {
                    this.num = 24;
                } else {
                    this.num = 5;
                }
                this.loadData(selectedrange, this.num, this.selectedactions, this.selectedindustries, this.min, this.max, this.action_year, this.action_month)
                this.loadMap(selectedrange, this.num, this.selectedactions, this.selectedindustries, this.min, this.max, this.action_year, this.action_month)
            },

            handleSelectedPeriod(selectedperiod) {
                this.loadData(this.range, selectedperiod, this.selectedactions, this.selectedindustries, this.min, this.max, this.action_year, this.action_month)
                this.loadMap(this.range, selectedperiod, this.selectedactions, this.selectedindustries, this.min, this.max, this.action_year, this.action_month)
            },

            async loadData(range, num, actionIds, industryIds, min, max, action_year, action_month) {
                this.$Progress.start();
                this.loaded = false;

                let url;
                url = 'api/projectanalytics/' + num;

                await axios.get(url, {
                    params: {
                        action_ids: actionIds,
                        range_type: range,
                        industry_ids: industryIds,
                        min: min,
                        max: max,
                        action_year: action_year,
                        action_month: action_month
                    }
                }).then(res => {
                        this.metrics = res.data;
                        this.loaded = true;

                        // Dynamically generate labels based on the selected range
                        const labels = [];
                        for (let i = 0; i < res.data.length; i++) {
                            const monthYear = `${res.data[i].date}`;
                            labels.push(monthYear);
                        }

                        const projectCounts = [];
                        for (let i = 0; i < res.data.length; i++) {
                            // Extract the project count from each data object and add it to the array
                            projectCounts.push(res.data[i].project_count);
                        }

                        this.chartdata = {
                            labels: labels,
                            datasets: [
                                {
                                    label: 'All Projects',
                                    data: projectCounts,
                                    fill: true,
                                    borderColor: '#A57DDD40',
                                    pointBackgroundColor: '#DC3545',
                                    backgroundColor: '#A57DFC40',
                                    borderWidth: 2,
                                    pointBackgroundColor: '#A57DFC',
                                    pointBorderColor: '#fff',
                                    pointHoverBorderColor: '#fff',
                                    pointHoverBorderWidth: 1,
                                    pointHoverRadius: 8,
                                    pointBorderWidth: 1,
                                    pointRadius: 5,
                                },
                            ]
                        };

                        this.options = {
                            scales: {
                                yAxes: [{
                                    ticks: {
                                        beginAtZero: true
                                    },
                                    gridLines: {
                                        display: true,
                                    }
                                }],
                                xAxes: [{
                                    gridLines: {
                                        display: false
                                    },
                                }]
                            },
                            legend: {
                                display: false
                            },
                            responsive: true,
                            maintainAspectRatio: false
                        };

                    })
                    .catch(err => {
                        console.log(err);
                    });

                this.summaryText = this.generateEnhancedSummary();
                this.$Progress.finish();
            },


            updateCharts() {
                this.loadData(this.range, this.num, this.selectedactions, this.selectedindustries, min, max, this.action_year, this.action_month)
            },

            async loadMap(range, num, actionIds, industryIds, min, max, action_year, action_month) {
                this.$Progress.start();
                this.maploaded = false;

                let url;
                url = 'api/projectanalytics/map/' + num;;

                await axios.get(url, {
                    params: {
                        action_ids: actionIds,
                        range_type: range,
                        industry_ids: industryIds,
                        min: min,
                        max: max,
                        action_year: action_year,
                        action_month: action_month
                    }
                }).then(response => {
                        this.maploaded = true;
                        this.geodata = response.data
                    })
                    .catch(err => {
                        console.log(err);
                    });

                this.$Progress.finish();
            }

        },


        created() {0

            // const today = new Date();
            // const curr_year = today.getFullYear();
            // const curr_month = moment(String(today.getMonth() + 1)).format('MM');

            // if(this.action_year == '' || this.action_month == '') {
            // //   yr = curr_year;
            // //   mo = curr_month;
            //   this.action_year = '0000';
            //   this.action_month = '00';
            // }
            // } else {
            //   yr = this.action_year;
            //   mo = this.action_month;
            // }

            axios.get('api/projectactions').then(({ data }) => ( this.projectactions = data ));
            axios.get('api/projectindustries').then(({ data }) => ( this.projectindustries = data ));
            axios.get('api/execoms').then(({ data }) => ( this.execoms = data ));
            this.loadData(this.range, this.num, this.selectedactions, this.selectedindustries, this.min, this.max, this.action_year, this.action_month)
            this.loadMap(this.range, this.num, this.selectedactions, this.selectedindustries, this.min, this.max, this.action_year, this.action_month)

            Trigger.$on('RefreshData',() => {
                this.loadData(this.range, this.num, this.selectedactions, this.selectedindustries, this.min, this.max, this.action_year, this.action_month);
            });
        },

    }
</script>

<style scoped>
.product-list-in-card>.item {
    border-bottom:none;
}

input[type="checkbox"] {
    margin-top: 2px !important;
}

.product-description:hover {
    color: #007BFF !important;
}
</style>
