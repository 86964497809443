<template>
    <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card card-secondary card-outline">
              <div class="card-header bg-info">
                <h4 class="card-title"><i class="fas fa-file-alt"></i> DOST Project Management Information System (DPMIS)</h4>

                <div class="card-tools">
                  <!--
                  <div class="input-group input-group-sm" style="width: 150px;">
                    <input type="text" name="table_search" class="form-control float-right" placeholder="Search">

                    <div class="input-group-append">
                      <button type="submit" class="btn btn-default"><i class="fas fa-search"></i></button>
                    </div>
                  </div>
                  -->
                  <b>Record Count:</b> {{proposals.total}} <!-- | Auth Token:  {{ bearer_token }}  <button class="btn btn-outline-secondary" type="button" @click="pushToDpmis()" >Get Token</button>-->

                  <div>
                    <button v-show="can_add" data-toggle="tooltip" data-container="body" title="Add New" class="btn btn-outline-secondary btn-sm" @click="newModal"><i class="fa fa-plus"></i></button>
                  </div>

                </div>
              </div>

              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">


                <b-table :items="proposals.data" :fields="fields" show-empty empty-text="No proposals" responsive="sm" stacked="md" sort-icon-left class="table b-table table-striped table-sm">

                  <template #cell(status_history)="row">
                    <b-button variant="outline-default" style="width: 80px;" size="sm" @click="row.toggleDetails" onclick="this.blur()" class="mr-2 btn btn-block">
                      <i v-if="row.detailsShowing" class='fa fa-minus-square text-primary'></i>
                      <i v-else class='fa fa-plus-square text-secondary'></i>
                    </b-button>
                  </template>

                  <template #cell(program_id)="row">
                    <span @click="viewProgram(row.item.program_id)" class="text-primary"><strong>{{ row.item.program_id }}</strong></span>
                  </template>

                  <template #cell(project_id)="row">
                    <span @click="row.toggleDetails" class="text-dark">{{ row.item.project_id }}</span>
                  </template>

                  <template #cell(project_title)="row">
                    <span @click="row.toggleDetails" class="text-dark">{{ row.item.project_title }}</span>
                  </template>

                  <template #cell(start_date)="row">
                    <span class="text-dark">{{ row.item.start_date }}</span>
                  </template>

                  <template #cell(end_date)="row">
                    <span class="text-dark">{{ row.item.end_date }}</span>
                  </template>

                  <template #cell(submitted_date)="row">
                    <span class="text-dark">{{ row.item.submitted_date }}</span>
                  </template>

                  <template #cell(status)="row" variant="danger">
                    <span v-if="row.item.statuses.length != 0 && row.item.statuses[row.item.statuses.length - 1].status_type === '1'" class="badge badge-primary" >In-progress</span>
                    <span v-else-if="row.item.statuses.length != 0 && row.item.statuses[row.item.statuses.length - 1].status_type === '2'" class="badge badge-success" >Approved for COUNCIL-GIA Funding</span>
                    <span v-else-if="row.item.statuses.length != 0 && row.item.statuses[row.item.statuses.length - 1].status_type === '3'" class="badge badge-danger" >Disapproved</span>
                    <span v-else-if="row.item.statuses.length != 0 && row.item.statuses[row.item.statuses.length - 1].status_type === '4'" class="badge badge-danger" >Returned</span>
                    <span v-else-if="row.item.statuses.length != 0 && row.item.statuses[row.item.statuses.length - 1].status_type === '5'" class="badge badge-danger" >Withdrawn</span>
                    <span v-else-if="row.item.statuses.length != 0 && row.item.statuses[row.item.statuses.length - 1].status_type === '6'" class="badge badge-danger" >Deffered</span>
                    <span v-else-if="row.item.statuses.length != 0 && row.item.statuses[row.item.statuses.length - 1].status_type === '7'" class="badge badge-danger" >Suspended</span>
                    <span v-else-if="row.item.statuses.length != 0 && row.item.statuses[row.item.statuses.length - 1].status_type === '8'" class="badge badge-danger" >Terminated</span>
                    <span v-else-if="row.item.statuses.length != 0 && row.item.statuses[row.item.statuses.length - 1].status_type === '9'" class="badge badge-warning" >Ongoing</span>
                    <span v-else-if="row.item.statuses.length != 0 && row.item.statuses[row.item.statuses.length - 1].status_type === '10'" class="badge badge-warning" >Extended</span>
                    <span v-else-if="row.item.statuses.length != 0 && row.item.statuses[row.item.statuses.length - 1].status_type === '11'" class="badge badge-orange" >Returned for revision</span>
                    <span v-else-if="row.item.statuses.length != 0 && row.item.statuses[row.item.statuses.length - 1].status_type === '12'" class="badge badge-danger" >Excluded from program</span>
                    <span v-else-if="row.item.statuses.length != 0 && row.item.statuses[row.item.statuses.length - 1].status_type === '13'" class="badge badge-success" >Endorsed for DOST-GIA Funding</span>
                    <span v-else-if="row.item.statuses.length != 0 && row.item.statuses[row.item.statuses.length - 1].status_type === '14'" class="badge badge-success" >Approved for DOST-GIA Funding</span>
                  </template>

                  <template #cell(options)="row">
                    <div class="btn-group btn-group-sm">
                      <button class="btn btn-outline-secondary" vdata-toggle="tooltip" data-container="body" title="Edit" type="button" @click="viewModal(row.item)" ><i class="fa fa-folder"></i> VIEW DETAILS</button>
                      <button class="btn btn-outline-secondary" vdata-toggle="tooltip" data-container="body" title="Edit" type="button" @click="newActionModal(row.item.project_id)" ><i class="fa fa-plus"></i> NEW ACTION</button>
                    </div>
                  </template>

                  <template #row-details="row">
                      <div class="row">
                        <div class="col-md-9">
                          <div class="card">
                            <div class="card-body p-0">
                              <table class="table table-sm table-dark">
                                <thead>
                                  <tr>
                                    <th>Action</th>
                                    <th>By</th>
                                    <th>Council/Committee</th>
                                    <th>Date & Time</th>
                                    <th>Remarks</th>
                                    <th>Evaluation Level</th>
                                    <th class="text-right">Status</th>
                                  </tr>
                                </thead>
                              <tbody>
                                <tr v-if="row.item.statuses.length==0">
                                  <td colspan="7">No status history</td>
                                </tr>
                                <tr v-else v-for="status in row.item.statuses" :key="status.id">
                                  <td>{{ status.status_name }}</td>
                                  <td>{{ status.created_by }}</td>
                                  <td>{{ status.created_council }}</td>
                                  <td>{{ status.date_created }}</td>
                                  <td>{{ status.remarks }}</td>
                                  <td>
                                    <span v-if="status.evaluation_level === '1'">Project Manager</span>
                                    <span v-else-if="status.evaluation_level === '2'">Technical Panel</span>
                                    <span v-else-if="status.evaluation_level === '3'">PMT/Executive Director</span>
                                    <span v-else-if="status.evaluation_level === '4'">Governing Council</span>
                                  </td>
                                  <td class="text-right">
                                    <span v-if="status.status_type === '1'" class="badge badge-primary" >In-progress</span>
                                    <span v-else-if="status.status_type === '2'" class="badge badge-success" >Approved for COUNCIL-GIA Funding</span>
                                    <span v-else-if="status.status_type === '3'" class="badge badge-danger" >Disapproved</span>
                                    <span v-else-if="status.status_type === '4'" class="badge badge-danger" >Returned</span>
                                    <span v-else-if="status.status_type === '5'" class="badge badge-danger" >Withdrawn</span>
                                    <span v-else-if="status.status_type === '6'" class="badge bg-danger" >Deffered</span>
                                    <span v-else-if="status.status_type === '7'" class="badge bg-danger" >Suspended</span>
                                    <span v-else-if="status.status_type === '8'" class="badge badge-danger" >Terminated</span>
                                    <span v-else-if="status.status_type === '9'" class="badge badge-warning" >Ongoing</span>
                                    <span v-else-if="status.status_type === '10'" class="badge badge-warning" >Extended</span>
                                    <span v-else-if="status.status_type === '11'" class="badge badge-orange" >Returned for revision</span>
                                    <span v-else-if="status.status_type === '12'" class="badge badge-danger" >Excluded from program</span>
                                    <span v-else-if="status.status_type === '13'" class="badge bg-success" >Endorsed for DOST-GIA Funding</span>
                                  </td>
                                </tr>

                              </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="card card-info">
                            <div class="card-header" style="padding: 0 0.25rem !important;">
                              <h3 class="card-title"><i class="fas fa-paperclip"></i> File Attachments</h3>
                            </div>
                            <div class="card-body p-0">
                              <table class="table">
                                <thead>
                                  <tr>
                                    <th>File Name</th>
                                    <th>Doctype</th>
                                    <th>Uploaded</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr v-if="row.item.dpmisfiles.length==0">
                                    <td colspan="3">No attachments</td>
                                  </tr>
                                  <tr v-else v-for="file in row.item.dpmisfiles" :key="file.id">
                                    <td><a :href="file.file_src" target="_blank">{{ file.file_name }}</a></td>
                                    <td>{{ file.document_type }}</td>
                                    <td class="text-right py-0 align-middle text-right">
                                      {{ file.created_at | dobformat }}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>

                      <b-button size="sm" variant="outline-default" class="btn btn-block" @click="row.toggleDetails"><i class="fa fa-chevron-up"></i> Hide Status History</b-button>
                  </template>

                </b-table>
              </div>
              <div class="card-footer">
                  <pagination :data="proposals" @pagination-change-page="getResults" show-disabled :limit="2"></pagination>
              </div>

            </div>
            <!-- /.card -->
          </div>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="actionmodal" tabindex="-1" role="dialog" aria-labelledby="actionModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-md" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="actionModalLabel">Add New Action</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div class="modal-body">
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                    <label>Action</label>
                    <select v-model="form_action.status_name" class="custom-select">
                      <option value="Submitted">Submitted</option>
                      <option value="Forwarding">Forwarding</option>
                      <option value="For Evaluation after Call for Proposals">For Evaluation after Call for Proposals</option>
                      <option value="Evaluation in Progress (Eligibility)">Evaluation in Progress (Eligibility)</option>
                      <option value="Evaluation in Progress (Veracity)">Evaluation in Progress (Veracity)</option>
                      <option value="Evaluation in Progress (TCVE)">Evaluation in Progress (TCVE)</option>
                      <option value="Evaluation in Progress (Final)">Evaluation in Progress (Final)</option>
                      <option value="For Revision">For Revision</option>
                      <option value="Evaluation in Progress">Evaluation in Progress</option>
                      <option value="Approved">Approved</option>
                      <option value="Disapproved">Disapproved</option>
                      <option value="Processing Fund Release">Processing Fund Release</option>
                      <option value="Fund Release">Fund Release</option>
                    </select>
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div class="form-group">
                    <label>Action By</label>
                    <select v-model="form_action.created_council" class="custom-select">
                      <option value="System Update">System Update</option>
                      <option value="Proponent">Proponent</option>
                      <option value="TAPI MIS Unit">TAPI MIS Unit</option>
                      <option value="TECHNiCOM Program Advisory Committee">TECHNiCOM Program Advisory Committee</option>
                      <option value="TECHNiCOM Program Unit">TECHNiCOM Program Unit</option>
                      <option value="TAPLI Legal Unit">TAPLI Legal Unit</option>
                      <option value="Expert Panel">Expert Panel</option>
                      <option value="DOST-TAPI Executive Committee">DOST-TAPI Executive Committee</option>
                      <option value="DOST-TAPI Finance and Administrative Division">DOST-TAPI Finance and Administrative Division</option>
                    </select>
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div class="form-group">
                    <label>Evaluation Level</label>
                    <select v-model="form_action.evaluation_level" class="custom-select">
                      <option value="1">Project Manager</option>
                      <option value="2">Technical Panel</option>
                      <option value="3">PMT/Executive Director</option>
                      <option value="4">Governing Council</option>
                    </select>
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div class="form-group">
                    <label>Status</label>
                    <select v-model="form_action.status_type" class="custom-select">
                      <option value="1">In-progress</option>
                      <option value="2">Approved for COUNCIL-GIA Funding</option>
                      <option value="3">Disapproved</option>
                      <option value="4">Returned</option>
                      <option value="5">Withdrawn</option>
                      <option value="6">Deferred</option>
                      <option value="7">Suspended</option>
                      <option value="8">Terminated</option>
                      <option value="9">Ongoing</option>
                      <option value="10">Extended</option>
                      <option value="11">Returned for revision</option>
                      <option value="12">Excluded from program</option>
                      <option value="13">Endorsed for DOST-GIA Funding</option>
                      <option value="14">Approved for DOST-GIA Funding</option>
                    </select>
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div class="form-group">
                    <label>Remarks</label>
                    <textarea v-model="form_action.remarks" class="form-control" rows="3" placeholder="Remarks..."></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <button :disabled="loading||!form_action.status_name||!form_action.evaluation_level||!form_action.status_type" class="btn btn-primary" data-container="body" type="button" @click="pushToDpmis()" ><i v-if="!loading" class="fa fa-save"></i><i v-else class="fas fa-spinner fa-pulse"></i> Submit</button>
              </div>
            </div>
          </div>
        </div>

        <div class="modal fade" id="programmodal" tabindex="-1" role="dialog" aria-labelledby="programModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="programModalLabel">Program Details</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="row-aligncenter">
                  <div v-if="program" class="card-body">
                    <div class="p-3 border">
                      <strong>{{ program.program_id }}</strong>
                    </div>
                    <div v-if="program" class="p-3 border">
                      <p>Program Title: <strong>{{ program.program_title }}</strong></p>
                      <p>
                        Program Leader/Sex: <strong>{{ program.researcher.first_name }} {{ program.researcher.middle_name }} {{ program.researcher.last_name }} {{ program.researcher.suffix }}</strong> /
                        <span v-if="program.researcher.sex == 0">Female</span><span v-else>Male</span>
                      </p>
                      <p>Agency/Affiliation: <strong>{{ program.researcher.affiliation }}</strong></p>
                      <p>Expertise: <strong><span v-html="program.researcher.expertise"></span></strong></p>
                      <p>
                        Address/Telephone/Fax/Email: <strong>{{ program.researcher.address }} / Tel. {{ program.researcher.tel_number }} / {{ program.researcher.email }}</strong>
                      </p>
                    </div>
                    <div class="pl-3 pr-3 pt-2 pb-1 border" style="background-color: #F4F6F9"><h6>PROGRAM INFORMATION</h6></div>
                    <div class="p-3 border">
                      <p>
                        Program Duration: <strong>{{ program.start_date | customdate}}</strong> to <strong>{{ program.end_date | customdate }}</strong>
                      </p>
                    </div>

                    <div class="pl-3 pr-3 pt-2 pb-1 border" style="background-color: #F4F6F9"><h6>SIGNIFICANCE/RATIONALE</h6></div>
                    <div class="p-3 border">
                      <p><span v-html="program.significance"></span></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="modal fade" id="proposalmodal" tabindex="-1" role="dialog" aria-labelledby="proposalModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="proposalModalLabel">Detailed Project Proposal</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>


              <div class="modal-body">

                <div class="row-aligncenter">

                <div class="card card-primary card-tabs">
                  <div class="card-header p-0 pt-1">
                    <ul class="nav nav-tabs" id="custom-tabs-one-tab" role="tablist">
                      <li class="nav-item">
                        <a class="nav-link active" id="custom-tabs-one-home-tab" data-toggle="pill" href="#custom-tabs-one-home" role="tab" aria-controls="custom-tabs-one-home" aria-selected="true">Project Details</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" id="custom-tabs-one-profile-tab" data-toggle="pill" href="#custom-tabs-one-profile" role="tab" aria-controls="custom-tabs-one-profile" aria-selected="false">Line-Item Budget (LIB)</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" id="custom-tabs-one-pm-tab" data-toggle="pill" href="#custom-tabs-one-pm" role="tab" @click="getProjectmanager(form.project_id)" aria-controls="custom-tabs-one-pm" aria-selected="false">Project Manager</a>
                      </li>
                    </ul>
                  </div>
                  <div class="card-body">
                    <div class="tab-content" id="custom-tabs-one-tabContent">
                      <div class="tab-pane fade active show" id="custom-tabs-one-home" role="tabpanel" aria-labelledby="custom-tabs-one-home-tab">
                        <div id="printProject" class="tblProject">
                          <div>

                            <div class="pl-3 pr-3 pt-2 pb-1 border" style="background-color: #F4F6F9"><h6>(1) PROJECT PROFILE</h6></div>
                            <div class="p-3 border">
                              <p>Project Title: <strong>{{ form.project_title }}</strong></p>
                              <p>
                                Project Leader/Sex: <strong>{{ form.researcher.first_name }} {{ form.researcher.middle_name }} {{ form.researcher.last_name }} {{ form.researcher.suffix }}</strong> /
                                <span v-if="form.researcher.sex == 0">Female</span><span v-else>Male</span>
                              </p>
                              <p>Agency/Affiliation: <strong>{{ form.researcher.affiliation }}</strong></p>
                              <p>Expertise: <strong><span v-html="form.researcher.expertise"></span></strong></p>
                              <p>
                                Address/Telephone/Fax/Email: <strong>{{ form.researcher.address }} / Tel. {{ form.researcher.tel_number }} / {{ form.researcher.email }}</strong>
                              </p>
                              <p>NRCP Member: <strong><span v-if="form.researcher.nrcp_member == 0">No</span><span v-else>Yes</span></strong></p>
                            </div>

                            <div class="pl-3 pr-3 pt-2 pb-1 border" style="background-color: #F4F6F9"><h6>(2) AGENCIES</h6></div>
                            <div class="p-3 border">
                              <strong>Cooperating Agency(ies):</strong>
                              <p>
                                <ul>
                                  <li v-for="(item, index) in form.cooperating_agency" :key="index">
                                    {{ item.name }} ({{ item.acronym }})
                                  </li>
                                </ul>
                              </p>
                              <strong>Implementing Agency(ies):</strong>
                              <p>
                                <ul>
                                  <li v-for="(item, index) in form.lead_implementing_agency" :key="index">
                                    {{ item.name }} ({{ item.acronym }})
                                  </li>
                                </ul>
                              </p>
                              <strong>Co-implementing Agency(ies):</strong>
                              <p>
                                <ul>
                                  <li v-for="(item, index) in form.implementing_agency" :key="index">
                                    {{ item.name }} ({{ item.acronym }})
                                  </li>
                                </ul>
                              </p>
                            </div>

                            <div class="pl-3 pr-3 pt-2 pb-1 border" style="background-color: #F4F6F9"><h6>(3) SITE(S) OF IMPLEMENTATION</h6></div>
                            <div class="p-3 border">
                                <table>
                                  <thead>
                                    <tr>
                                      <th>IMPLEMENTATION SITES NO.</th>
                                      <th>BARANGAY</th>
                                      <th>MUNICIPALITY/CITY</th>
                                      <th>DISTRICT</th>
                                      <th>PROVINCE</th>
                                      <th>REGION</th>
                                      <th>COUNTRY</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr v-for="(item, index) in form.sites" :key="index">
                                      <td>Site {{ index + 1 }}</td>
                                      <td>{{ item.barangay }}</td>
                                      <td>{{ item.municipality }}</td>
                                      <td>District {{ item.district }}</td>
                                      <td>{{ item.province }}</td>
                                      <td>{{ item.region }}</td>
                                      <td>{{ item.country }}</td>
                                    </tr>
                                  </tbody>
                                </table>
                            </div>

                            <div class="pl-3 pr-3 pt-2 pb-1 border" style="background-color: #F4F6F9"><h6>(4) CLASSIFICATION</h6></div>
                            <div class="p-3 border">
                                <ul>
                                  <li v-for="(item, index) in form.technicom_classification" :key="index">
                                    {{ item.title }}
                                  </li>
                                </ul>
                            </div>

                            <div class="pl-3 pr-3 pt-2 pb-1 border" style="background-color: #F4F6F9"><h6>(5) R&D PRIORITY AREA & PROGRAM (HNRDA)</h6></div>
                            <div class="p-3 border">
                              <p>
                                <strong>{{ form.hnrda_classification }}<br/>Sector: <u>{{ form.hnrda_item }}</u></strong>
                              </p>
                            </div>

                            <div class="pl-3 pr-3 pt-2 pb-1 border" style="background-color: #F4F6F9"><h6>(6) MODE OF IMPLEMENTATION</h6></div>
                            <div class="p-3 border">
                              <p><strong><span v-if="form.implementing_agency.length > 1">Multi Agency</span><span v-else>Single Agency</span></strong></p>
                            </div>

                            <div class="pl-3 pr-3 pt-2 pb-1 border" style="background-color: #F4F6F9"><h6>(7) PROJECT INFORMATION</h6></div>
                            <div class="p-3 border">
                              <p>
                                Project Duration: <strong>{{ form.start_date | customdate}}</strong> to <strong>{{ form.end_date | customdate }}</strong>
                              </p>
                            </div>

                            <div class="pl-3 pr-3 pt-2 pb-1 border" style="background-color: #F4F6F9"><h6>(8) SIGNIFICANCE/RATIONALE</h6></div>
                            <div class="p-3 border">
                              <p><span v-html="form.significance"></span></p>
                            </div>

                            <div class="pl-3 pr-3 pt-2 pb-1 border" style="background-color: #F4F6F9"><h6>(9) RESULTS OF PREVIOUS R&D CONDUCTED</h6></div>
                            <div class="p-3 border">
                              <p><span v-html="form.results_rd_conducted"></span></p>
                            </div>

                            <div class="pl-3 pr-3 pt-2 pb-1 border" style="background-color: #F4F6F9"><h6>(10) REVIEW OF LITERATURE</h6></div>
                            <div class="p-3 border">
                              <p><span v-html="form.literature"></span></p>
                            </div>

                            <div class="pl-3 pr-3 pt-2 pb-1 border" style="background-color: #F4F6F9"><h6>(11) MARKETING AND COMMERCIAL VIABILITY</h6></div>
                            <div class="p-3 border">
                              <p><span v-html="form.marketing_commercial_viability"></span></p>
                            </div>

                            <div class="pl-3 pr-3 pt-2 pb-1 border" style="background-color: #F4F6F9"><h6>(12) OBJECTIVES</h6></div>
                            <div class="p-3 border">
                              <strong>General Objective:</strong>
                              <p><span v-html="form.general_objective"></span></p>
                              <strong>Specific Objectives:</strong>
                              <p><span v-html="form.specific_objectives"></span></p>
                            </div>

                            <div class="pl-3 pr-3 pt-2 pb-1 border" style="background-color: #F4F6F9"><h6>(13) METHODOLOGY</h6></div>
                            <div class="p-3 border">
                              <p><span v-html="form.methodology"></span></p>
                            </div>

                            <div class="pl-3 pr-3 pt-2 pb-1 border" style="background-color: #F4F6F9"><h6>(14) WORKPLAN</h6></div>
                            <div class="p-3 border">
                              <div v-for="file in form.dpmisfiles" :key="file.id">
                                <p><a v-if="file.document_type === 'Workplan'" :href="file.file_src" target="_blank">{{ file.file_name }}</a></p>
                              </div>
                            </div>

                            <div class="pl-3 pr-3 pt-2 pb-1 border" style="background-color: #F4F6F9"><h6>(15) TARGET ACCOMPLISHMENTS AND EXPECTED OUTPUTS (6Ps)</h6></div>
                            <div class="p-3 border">

                              <strong>Publication</strong>
                              <p><span v-html="form.sixp_publication"></span></p>

                              <strong>Patent/Intellectual Property</strong>
                              <p><span v-html="form.sixp_patent"></span></p>

                              <strong>Product</strong>
                              <p><span v-html="form.sixp_product"></span></p>

                              <strong>People Service</strong>
                              <p><span v-html="form.sixp_people"></span></p>

                              <strong>Place and Partnership</strong>
                              <p><span v-html="form.sixp_place"></span></p>

                              <strong>Policy</strong>
                              <p><span v-html="form.sixp_policy"></span></p>
                              <!--
                              <strong><i class="fas fa-pencil-alt mr-1"></i> Outcomes</strong>
                              <p class="text-muted"><span v-html="form.outcomes"></span></p>
                              <strong><i class="fas fa-pencil-alt mr-1"></i> Social Impact</strong>
                              <p class="text-muted"><span v-html="form.social_impact"></span></p>
                              <strong><i class="fas fa-pencil-alt mr-1"></i> Economic Impact</strong>
                              <p class="text-muted"><span v-html="form.economic_impact"></span></p>
                              -->
                            </div>

                            <div class="pl-3 pr-3 pt-2 pb-1 border" style="background-color: #F4F6F9"><h6>(16) TARGET BENEFICIARIES</h6></div>
                            <div class="p-3 border">
                              <p><span v-html="form.target_beneficiaries"></span></p>
                            </div>

                            <div class="pl-3 pr-3 pt-2 pb-1 border" style="background-color: #F4F6F9"><h6>(17) GENDER AND DEVELOPMENT (GAD) SCORE</h6></div>
                            <div class="p-3 border">
                              <p><strong>{{ form.gad_score }}</strong></p>
                            </div>

                            <div class="pl-3 pr-3 pt-2 pb-1 border" style="background-color: #F4F6F9"><h6>(18) LIMITATIONS OF THE PROJECT</h6></div>
                            <div class="p-3 border">
                              <p><span v-html="form.limitations"></span></p>
                            </div>

                            <div class="pl-3 pr-3 pt-2 pb-1 border" style="background-color: #F4F6F9"><h6>(19) LITERATURE CITED</h6></div>
                            <div class="p-3 border">
                              <p><span v-html="form.literature_cited"></span></p>
                            </div>


                          </div>
                        </div>
                        <div class="modal-footer">
                          <button v-print="'#printProject'" type="submit" class="btn btn-default"><i class="fa fa-print"></i> PRINT</button>
                        </div>
                      </div>
                      <div class="tab-pane fade" id="custom-tabs-one-profile" role="tabpanel" aria-labelledby="custom-tabs-one-profile-tab">
                        <div id="printLib">

                          <div class="box box-solid collapsed-box">
                            <div class="box-body">
                              <div class="box-group" id="accordion">

                                <div class="panel box box-primary" v-for="(lb, index) in form.lib" :key="index">
                                  <div data-toggle="collapse" data-parent="#accordion" :href="'#collapse' + index" aria-expanded="false" class="pl-3 pr-3 pt-2 pb-1 bg-orange border collapsed">
                                    <h5 class="box-title">
                                      LINE-ITEM BUDGET YEAR {{ lb.year }}<span class="badge badge-success float-right">TOTAL PROPOSED BUDGET: {{total_ps_df(lb.ps) + total_df(lb.mooe) + total_df(lb.co) + total_ps_cf(lb.ps) + total_cf(lb.mooe) + total_cf(lb.co) | toMoney}}</span>
                                    </h5>
                                  </div>
                                  <div :id="'collapse' + index" class="panel-collapse collapse border" aria-expanded="false">
                                    <div class="box-body">
                                      <table class="table">
                                        <thead>
                                          <tr>
                                            <td colspan="6" style="background-color: #F4F6F9"><h5>I. Personnel Services</h5></td>
                                          </tr>
                                          <tr>
                                            <th>COST TYPE</th>
                                            <th>POSITION</th>
                                            <th>PAYMENT MODE</th>
                                            <th>FUNDING AGENCY</th>
                                            <th>COUNTERPART FUND</th>
                                            <th>DOST FUND</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr v-for="(ps, index) in lb.ps" :key="index">
                                            <td><span v-if="ps.cost_type == 0">Direct Cost</span><span v-else>Indirect Cost</span></td>
                                            <td>{{ ps.num_position | toWordsPesos | allcaps}} ({{ ps.num_position }}) {{ ps.position.position_name }}</td>
                                            <td><span v-if="ps.ps_type == 0">Salary</span><span v-else>Honoraria</span> at <span v-if="ps.funding_agency.acronym == 'DOST-TAPI' || ps.funding_agency.acronym == 'TAPI'">P{{ ps.position.rate | toMoney }} x</span> {{ ps.num_duration }} {{ ps.mode_payment }}(s)</td>
                                            <td><span v-if="!ps.funding_agency.acronym">{{ ps.funding_agency.name }}</span><span v-else>{{ ps.funding_agency.acronym }}</span></td>
                                            <td><span v-if="ps.funding_agency.acronym == 'DOST-TAPI' || ps.funding_agency.acronym == 'TAPI'">-</span><span v-else>{{ ps.amount | toMoney }}</span></td>
                                            <td><span v-if="ps.funding_agency.acronym == 'DOST-TAPI' || ps.funding_agency.acronym == 'TAPI'">{{ (ps.position.rate * ps.num_position) * ps.num_duration | toMoney }}</span><span v-else>-</span></td>
                                          </tr>
                                          <tr>
                                            <td colspan="3"></td>
                                            <td><strong>PS Sub-total</strong></td>
                                            <td>{{total_ps_cf(lb.ps) | toMoney}}</td>
                                            <td>{{total_ps_df(lb.ps) | toMoney}}</td>
                                          </tr>
                                        </tbody>
                                        <thead>
                                          <tr>
                                            <td colspan="6" style="background-color: #F4F6F9"><h5>II. Maintenance and Other Operating Expenses</h5></td>
                                          </tr>
                                          <tr>
                                            <th>COST TYPE</th>
                                            <th>CLASSIFICATION</th>
                                            <th></th>
                                            <th>FUNDING AGENCY</th>
                                            <th>COUNTERPART FUND</th>
                                            <th>DOST FUND</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr v-for="(mooe, index) in lb.mooe" :key="index">
                                            <td><span v-if="mooe.cost_type == 0">Direct Cost</span><span v-else>Indirect Cost</span></td>
                                            <td>{{ mooe.mooe_classification }}</td>
                                            <td></td>
                                            <td><span v-if="!mooe.funding_agency.acronym">{{ mooe.funding_agency.name }}</span><span v-else>{{ mooe.funding_agency.acronym }}</span></td>
                                            <td><span v-if="mooe.funding_agency.acronym == 'DOST-TAPI' || mooe.funding_agency.acronym == 'TAPI'">-</span><span v-else>{{ mooe.amount | toMoney }}</span></td>
                                            <td><span v-if="mooe.funding_agency.acronym == 'DOST-TAPI' || mooe.funding_agency.acronym == 'TAPI'">{{ mooe.amount | toMoney }}</span><span v-else>-</span></td>
                                          </tr>
                                          <tr>
                                            <td colspan="3"></td>
                                            <td><strong>MOOE Sub-total</strong></td>
                                            <td>{{total_cf(lb.mooe) | toMoney}}</td>
                                            <td>{{total_df(lb.mooe) | toMoney}}</td>
                                          </tr>
                                        </tbody>
                                        <thead>
                                          <tr>
                                            <td colspan="6" style="background-color: #F4F6F9"><h5>II. Capital Outlay</h5></td>
                                          </tr>
                                          <tr>
                                            <th>COST TYPE</th>
                                            <th>CLASSIFICATION</th>
                                            <th></th>
                                            <th>FUNDING AGENCY</th>
                                            <th>COUNTERPART FUND</th>
                                            <th>DOST FUND</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr v-for="(co, index) in lb.co" :key="index">
                                            <td><span v-if="co.cost_type == 0">Direct Cost</span><span v-else>Indirect Cost</span></td>
                                            <td>{{ co.co_quantity | toWordsPesos | allcaps }} ({{ co.co_quantity }}) {{ co.co_description }} ({{ co.co_amount }}/per item)</td>
                                            <td></td>
                                            <td><span v-if="!co.funding_agency.acronym">{{ co.funding_agency.name }}</span><span v-else>{{ co.funding_agency.acronym }}</span></td>
                                            <td><span v-if="co.funding_agency.acronym == 'DOST-TAPI' || co.funding_agency.acronym == 'TAPI'">-</span><span v-else>{{ co.amount | toMoney }}</span></td>
                                            <td><span v-if="co.funding_agency.acronym == 'DOST-TAPI' || co.funding_agency.acronym == 'TAPI'">{{ co.amount | toMoney }}</span><span v-else>-</span></td>
                                          </tr>
                                          <tr>
                                            <td colspan="3"></td>
                                            <td><strong>CO Sub-total</strong></td>
                                            <td>{{total_cf(lb.co) | toMoney}}</td>
                                            <td>{{total_df(lb.co) | toMoney}}</td>
                                          </tr>
                                        </tbody>
                                      </table>
                                      <div class="pl-3 pr-3 pt-2 pb-1 border" style="background-color: #F4F6F9">
                                        <p class="text-right"><strong>Total Proposed DOST Fund: {{total_ps_df(lb.ps) + total_df(lb.mooe) + total_df(lb.co) | toMoney}}</strong></p>
                                        <p class="text-right"><strong>Total Proposed Counterpart Fund: {{total_ps_cf(lb.ps) + total_cf(lb.mooe) + total_cf(lb.co) | toMoney}}</strong></p>
                                        <p class="text-right"><strong>Total Proposed Budget: {{total_ps_df(lb.ps) + total_df(lb.mooe) + total_df(lb.co) + total_ps_cf(lb.ps) + total_cf(lb.mooe) + total_cf(lb.co) | toMoney}}</strong></p>
                                      </div>
                                    </div>
                                  </div>

                                </div>

                              </div>
                            </div>
                          </div>

                        </div>

                        <div class="modal-footer">
                          <button v-print="'#printLib'" type="submit" class="btn btn-default"><i class="fa fa-print"></i> PRINT</button>
                        </div>
                      </div>
                      <div class="tab-pane fade" id="custom-tabs-one-pm" role="tabpanel" aria-labelledby="custom-tabs-one-pm-tab">
                        <div v-if="!loading">
                          Project Manager :<br/>
                          Project Manager ID in DPMIS:
                        </div>
                        <div v-else>
                          <i class="fas fa-spinner fa-pulse"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- /.card -->
                </div>

                </div>

              </div>



            </div>
          </div>
        </div>



      </div>

</template>

<script>

    import Form from 'vform';
    import { VueEditor } from "vue2-editor";
    import moment from 'moment';

    export default {
        name: 'DPMIS',

        components: {
            VueEditor,
        },

        data() {
          return {
            timer: 30,
            editmode: false,
            bearer_token: '',
            loading: false,
            emptyflag: false,
            can_access: false,
            can_add: false,
            can_edit: false,
            can_delete: false,
            pm: {},
            profile: {},
            proposals : {},
            program: null,
            fields: ['_', 'project_id', 'project_title', 'start_date', 'end_date', 'submitted_date', 'status','options'],
            fields: [
              {
                key: 'status_history',
                sortable: false
              },
              {
                key: 'program_id',
                label: 'Program',
                sortable: true
              },
              {
                key: 'project_id',
                label: 'Project ID',
                sortable: true
              },
              {
                key: 'project_title',
                label: 'Title',
                sortable: true,
              },
              {
                key: 'start_date',
                label: 'Start Date',
                sortable: true,
              },
              {
                key: 'end_date',
                label: 'End',
                sortable: true,
              },
              {
                key: 'created_at',
                label: 'Submitted Date',
                sortable: false,
              },
              {
                key: 'status',
                label: 'Status',
                sortable: false,
              },
              {
                key: 'options',
                label: 'Options',
                sortable: false,
              }
            ],
            form: new Form({
              proposal_type: '',
              program_id: '',
              project_id: '',
              project_title: '',
              researcher_id: '',
              researcher: {},
              start_date: '',
              end_date: '',
              hnrda_id: '',
              hnrda_classification: '',
              hnrda_item_id: '',
              hnrda_item: '',
              significance: '',
              results_rd_conducted: '',
              literature: '',
              marketing_commercial_viability: '',
              general_objective: '',
              specific_objectives: '',
              methodology: '',
              sixp_publication: '',
              sixp_patent: '',
              sixp_product: '',
              sixp_people: '',
              sixp_place: '',
              sixp_policy: '',
              outcomes: '',
              social_impact: '',
              economic_impact: '',
              target_beneficiaries: '',
              limitations: '',
              literature_cited: '',
              gad_score: '',
              dpmisfiles: [],
              lead_implementing_agency: {},
              implementing_agency: [],
              cooperating_agency: [],
              sites: [],
              technicom_classification: [],
              lib: [],
            }),
            form_action: new Form({
              project_id: null,
              created_by: null,
              status_id: null,
              status_name: null,
              status_type: null,
              remarks: '',
              date_created: null,
              created_council: null,
              evaluation_level: null,
              is_closed: '1',
            })
          }
        },

        props: [
          'todo'
        ],

        computed: {

          second() {
            return this.time / 1000;
          },

          isIdle() {
            const idlestat =  this.$store.state.idleVue.isIdle;
            if (idlestat == true) {
              $('#sessionmodal').modal('show');
              this.countDownTimer();
            } else {
              $('#sessionmodal').modal('hide');
            }
            return idlestat;
          },

          colorStatus (status_type) {
            //console.log(status_type)
            if(status_type === "1") {
              return "badge badge-info";
            } else if (status_type === "2") {
              return "badge badge-warning";
            } else if (status_type === "3") {
              return "badge badge-success";
            } else {
              return "badge badge-danger";
            }
          },

        },

        methods: {

          countDownTimer() {
            let timerId = setInterval(() => {
              this.timer -= 1;
              if (!this.$store.state.idleVue.isIdle) clearInterval(timerId);

              if (this.timer < 1) {
                clearInterval(timerId);
                document.getElementById('logout-form').submit();
              }
            }, 1000);

            this.timer = 30;
          },

          total_ps_cf(param) {
            if (param) {
              return param.reduce((total, item) => {
                var sum;
                if (item.funding_agency.acronym != 'DOST-TAPI' && item.funding_agency.acronym != 'TAPI') {
                  //sum = total + parseFloat(item.position.rate * item.num_position * item.num_duration);
                  //sum = total + parseFloat(item.position.rate);
                  sum = total + parseFloat(item.amount);
                } else {
                  sum = total + parseFloat(0);
                }
                return sum;
              }, 0);
            }
          },

          total_ps_df(param) {
            if (param) {
              return param.reduce((total, item) => {
                var sum;
                if (item.funding_agency.acronym == 'DOST-TAPI' || item.funding_agency.acronym == 'TAPI') {
                  sum = total + parseFloat(item.position.rate * item.num_position * item.num_duration);
                } else {
                  sum = total + parseFloat(0);
                }
                return sum;
              }, 0);
            }
          },

          total_cf(param) {
            if (param) {
              return param.reduce((total, item) => {
                var sum;
                if (item.funding_agency.acronym != 'DOST-TAPI' && item.funding_agency.acronym != 'TAPI') {
                  sum = total + parseFloat(item.amount);
                } else {
                  sum = total + parseFloat(0);
                }
                return sum;
              }, 0);
            }
          },

          total_df(param) {
            if (param) {
              return param.reduce((total, item) => {
                var sum;
                if (item.funding_agency.acronym == 'DOST-TAPI' || item.funding_agency.acronym == 'TAPI') {
                  sum = total + parseFloat(item.amount);
                } else {
                  sum = total + parseFloat(0);
                }
                return sum;
              }, 0);
            }
          },

          async createAction() {
            this.$Progress.start();
            this.form_action.post('api/dpmis/status')
            .then(()=>{
              Trigger.$emit('RefreshData');
              $('#actionmodal').modal('hide')
              toastr.fire({
                icon: 'success',
                title: 'Status/Action created successfully'
              })
              this.$Progress.finish();
            }).catch(()=>{
              toastr.fire({
                icon: 'error',
                title: 'Status/Action saving failed'
              });
              this.$Progress.fail();
            })
          },

          async getOAuthToken() {

          },

          async getProjectmanager(project_id) {

            //console.log(project_id)

            this.loading = true;
            const formData = new FormData();
            formData.append("client_id", "tapi");
            formData.append("client_secret", "IvwUPJBF7Gp");
            formData.append("grant_type", "client_credentials");
            await axios.post("http://dtoms.tapidost.net:5000/https://api.dpmis.dost.gov.ph/dpmisoauth", formData).then(({ data }) => (
              this.bearer_token = data.access_token
            ));

            await axios({
              method: "get",
              url: "http://dtoms.tapidost.net:5000/https://api.dpmis.dost.gov.ph/v1/projectManagersAssignment/" + project_id,
              headers: {
                "Authorization": "Bearer " + this.bearer_token
              }
            }).then(({ response }) => (
              console.log(response),
              this.pm = response
            ))
            .catch(({ response }) => (
              console.log(response)
            ));

            this.loading = false;

          },

          async pushToDpmis() {
            this.loading = true;
            this.$Progress.start();

            // Set status_id
            await axios.get('api/dpmis/newstatusid').then(({ data }) => (this.form_action.status_id = data));
            // Set date_created
            const timestamp = moment(String(new Date())).format('YYYY-MM-DD HH:mm:ss');
            this.form_action.date_created = timestamp;

            const formData = new FormData();
            formData.append("client_id", "tapi");
            formData.append("client_secret", "IvwUPJBF7Gp");
            formData.append("grant_type", "client_credentials");
            await axios.post("http://dtoms.tapidost.net:5000/https://api.dpmis.dost.gov.ph/dpmisoauth", formData).then(({ data }) => (
              this.bearer_token = data.access_token
            ));

            // this.getAgencies();

            const headers = {
              "Authorization": "Bearer " + this.bearer_token
            };

            console.log(this.token)

            await this.form_action.post('http://dtoms.tapidost.net:5000/https://api.dpmis.dost.gov.ph/v1/statuses', { headers }).then(()=>{
              console.log('Sent to DPMIS Server')
              this.$Progress.finish();
              this.createAction();
              toastr.fire({
                icon: 'success',
                title: 'Status created successfully'
              })
              this.loading = false;
            }).catch(()=>{
              console.log('Status saving failed in DPMIS Server')
              toastr.fire({
                icon: 'error',
                title: 'Status saving failed'
              });
              this.$Progress.fail();
              this.loading = false;
            })

          },

          async getAgencies() {

            await axios({
              method: "get",
              url: "http://dtoms.tapidost.net:5000/https://api.dpmis.dost.gov.ph/v1/agencies",
              headers: {
                "Authorization": "Bearer " + this.bearer_token
              }
            }).then(function (response) {
              console.log(response.data);
            })
            .catch(function (response) {
              console.log(response);
            });
          },


          viewModal(proposals) {
            this.editmode = true;
            this.form.reset();
            this.form.clear();
            $('#proposalmodal').modal('show');
            //this.getProjectmanager(proposals.project_id)
            this.form.fill(proposals);
          },

          async viewProgram(program_id) {
            await axios.get('api/dpmis/programs/' + program_id).then(response => { this.program = response.data; });

            $('#programmodal').modal('show');
          },

          newActionModal(project_id) {
            this.form_action.reset();
            this.form_action.clear();
            $('#actionmodal').modal('show');
            this.form_action.created_by = this.$gate.showFullName();
            this.form_action.project_id = project_id;
          },

          newModal() {
            //
          },

          getResults(page = 1) {

            let query = this.$parent.search;

            if(query == ''){
              axios.get('api/dpmis/proposals?page=' + page).then(response => { this.proposals = response.data; });
            }else{
              axios.get('api/dpmis/findProposal?q=' + query + '&page=' + page).then(response => { this.proposal = response.data; });
            }

            //console.log(query);


          },

          loadProposals() {
            this.$Progress.start();

            let query = this.$parent.search;
            let page = this.proposals.current_page;

            axios.get("api/users/pds/" + this.$gate.showMyID()).then(({ data }) => (this.profile = data));

            if(query == ''){
              axios.get('api/dpmis/proposals?page=' + page).then(({ data }) => (this.proposals = data));
            }else{
              axios.get('api/findProposal?q=' + query + '&page=' + page).then(({ data }) => (this.proposals = data));
            }

            this.$Progress.finish();
          },

          async loadPermission(mid) {
              const response = await axios.get("api/permission/" + this.$gate.showMyID() + "/" + mid).then(
              res => {
                if (!res.data || res.data.length == 0) {
                  return false;
                } else {
                  return true;
                }
              })

              const getResponse = async () => {
                const a = await response;

                switch (mid) {
                case 49:
                  this.can_access = a;
                  if (!this.can_access) {
                    this.$router.push({ name: 'not-found' }) //redirect to not found
                  }
                  break;
                case 50:
                  this.can_edit = a;
                  break;
                case 51:
                  this.can_delete = a;
                }
              };
              return getResponse()
          },


        },

        created() {
          Trigger.$on('searching',() => {
            let query = this.$parent.search;
            let emptyflag = false;

            if(query == ''){
              Trigger.$emit('RefreshData');
            }else{
              this.$Progress.start();
              axios.get("api/findProposal?q=" + query)
              .then((data) => {
                this.proposals = data.data;

                if (data.data.total == 0){
                  this.emptyflag = true;
                }

                this.$Progress.finish();
              })
              .catch(() => {
                this.$Progress.fail();
              })
            }

          });

          this.form_action.created_by = this.$gate.showFullName();

          this.loadProposals();
          this.loadPermission(44); //Can access Certificates list
          this.loadPermission(45); //Can add new Certificate
          this.loadPermission(46); //Can edit Certificate details
          this.loadPermission(47); //Can delete Certificate
          this.loadPermission(48); //Can access Certificates settings

          Trigger.$on('RefreshData',() => {
            this.loadProposals();
          });
        },

    }
</script>
<style scoped>
div >>> p {
  font-size: 15px;
}

div >>> strong {
  font-size: 15px;
}

div >>> em {
  font-size: 15px;
}

div.header >>> p {
  line-height: 0.5;
  font-size: 20px;
  font-family: "Helvetica Narrow","Arial Narrow";
}

div.header >>> strong {
  line-height: 0.5;
  font-size: 20px;
  font-family: "Helvetica Narrow","Arial Narrow";
}

div.header >>> em {
  line-height: 0.5;
  font-size: 20px;
  font-family: "Helvetica Narrow","Arial Narrow";
}

.text-selection-none{
   user-select: none; /* supported by Chrome and Opera */
   -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
   -moz-user-select: none; /* Firefox */
   -ms-user-select: none;
}
div.tblProject table {
  width: 100% !important;
}

div.tblProject table td, div.tblProject table th {
  border: 1px solid #6C757D !important;
  border-collapse: collapse !important;
  padding: 5px !important;
}

div.tblProject >>> table {
  width: 100% !important;
}

div.tblProject >>> table td, div.tblProject >>> table th {
  border: 1px solid #6C757D !important;
  border-collapse: collapse !important;
  padding: 5px !important;
}

div.tblProject >>> br {
  display: block; /* makes it have a width */
  content: ""; /* clears default height */
  margin-top: 15px; /* change this to whatever height you want it */
}
</style>
