import { render, staticRenderFns } from "./ProcSignatories.vue?vue&type=template&id=649fe11b&scoped=true&"
import script from "./ProcSignatories.vue?vue&type=script&lang=js&"
export * from "./ProcSignatories.vue?vue&type=script&lang=js&"
import style0 from "./ProcSignatories.vue?vue&type=style&index=0&id=649fe11b&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "649fe11b",
  null
  
)

export default component.exports