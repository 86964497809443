<template>
  <div class="col-md-6">
    <!-- USERS LIST -->
    <div class="card">
      <div class="card-header">
        <h3 class="card-title">TAPI Website Monitoring</h3>

        <div class="card-tools">
          <!--<span class="badge badge-warning">Employees</span>
          <button type="button" class="btn btn-tool" data-card-widget="collapse">
            <i class="fas fa-minus"></i>
          </button>-->
          <!--
          <button type="button" class="btn btn-tool" data-card-widget="remove">
            <i class="fas fa-times"></i>
          </button>
          -->
        </div>
      </div>
      <!-- /.card-header -->
      <div class="card-body p-0">
        <table class="table table-striped projects">
              <thead>
                  <tr>
                      <th style="width: 40%">
                          Server Name
                      </th>
                      <th style="width: 40%">
                          URL
                      </th>
                      <th style="width: 20%">
                          Status
                      </th>
                  </tr>
              </thead>
              <tbody>
                  <tr v-for="monitor in data.monitors"  v-bind:key="monitor.id">
                      <td>
                          <a target="new" :href="monitor.url">{{ monitor.friendly_name }}</a>
                      </td>
                      <td>
                          <a target="new" :href="monitor.url">{{ monitor.url }}</a>
                      </td>
                      <td>
                          <span v-if="monitor.status==0" class="badge badge-secondary">Server is paused</span>
                          <span v-else-if="monitor.status==1" class="badge badge-primary">Not checked yet</span>
                          <span v-else-if="monitor.status==2" class="badge badge-success">Server is up</span>
                          <span v-else-if="monitor.status==8" class="badge badge-warning">Server seems down</span>
                          <span v-else-if="monitor.status==9" class="badge badge-danger">Server is down</span>
                      </td>
                  </tr>
              </tbody>
        </table>
      </div>
      <!-- /.card-body -->
      <!--<div class="card-footer text-center">
        
        <a href="javascript:">View All Users</a>
        
      </div>-->
      <!-- /.card-footer -->
    </div>
    <!--/.card -->
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'WebsiteMonitor',
  components: {
    
  },

  data () {
      return {
        data: {},
      }
  },

  async mounted () {
    const qs = require('querystring');
    
    await axios.post('https://api.uptimerobot.com/v2/getMonitors', qs.stringify({ 
        api_key: 'ur1430419-12a3d400383730a08a83b3e9', 
        monitors: '789116327-789116468-789116474-789116478-789116255-789201211-789201222-789201220-789201233-789201226-789201110',
        format: 'json', 
        logs: '1'
      }), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      }, 
      transformRequest: (data, headers) => {
          delete headers.common['X-Requested-With'];
          return data;
        }

    })
    .then((result) => {
        this.data = result.data;
    })
    .catch((err) => {
        console.log(err);
        console.log(qs.stringify({ api_key: 'ur1430419-12a3d400383730a08a83b3e9', format: 'json', logs: '1' }));
    })

  }

}
</script>