<template>
  <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card card-secondary card-outline">
            <div class="card-header">
              <h3 class="card-title" v-if="ppmpdetails.office && ppmpdetails.fundsource">
                <i class="fas fa-book"></i>
                {{ this.ppmpdetails.office.name }} Project Procurement Mgmt Plan (PPMP) for {{ this.year }} under {{ this.ppmpdetails.fundsource.alias }}
              </h3>
              <small v-show="can_add && this.ppmpdetails.status == 1 || this.ppmpdetails.status == 0" class="ml-2"><a href="#" style="font-size: 90%" @click="editPpmp(ppmpdetails.id, ppmpdetails.fundsource_id)">[EDIT]</a></small>

              <div class="card-tools">
                <span v-if="(this.ppmpdetails.status == 0)" class="badge badge-info"><i>Status:</i> <b>In-Preparation</b></span>
                <span v-else-if="(this.ppmpdetails.status == 1)" class="badge badge-warning"><i>Status:</i> <b>Revision</b></span>
                <span v-else-if="(this.ppmpdetails.status == 2)" class="badge badge-primary"><i>Status:</i> <b>Pending</b></span>
                <span v-else-if="(this.ppmpdetails.status == 3)" class="badge badge-success"><i>Status:</i> <b>Approved</b></span>
                <span v-if="ppmpdetails.ppmpitems" class="badge badge-secondary ml-1 mr-1"><i>No. of Items:</i> <b>{{ ppmpdetails.ppmpitems.length }}</b></span>
                <span v-if="ppmpdetails.ppmpitems" class="badge badge-secondary"><i>Total Amount:</i> <b>{{ this.total_expenses | toMoney }}</b></span>

              </div>
            </div>
            <!-- /.card-header -->
            <div class="row m-0 bg-secondary disabled color-palette">
                <div class="col-md-4">
                  <div class="btn-group mt-2 mb-2 d-flex justify-content-start">
                      <download-excel       v-show="can_add" class="btn btn-default" data-container="body" :data="ppmpdetails.ppmpitems" :fields="excel_fields" :name="file_name" :header="excel_header" :title="excel_title" :footer="excel_footer"><i class="fa fa-file-excel"></i> EXPORT TO EXCEL</download-excel>
                      <button type="button" v-show="can_add" class="btn btn-default" @click="printModal()"><i class="fa fa-print"></i> PRINT</button>
                      <button type="button" v-show="can_add && this.ppmpdetails.status == 1 || this.ppmpdetails.status == 0" class="btn btn-default" @click="actionAddItem()"><i class="fa fa-plus"></i> ADD ITEM</button>
                  </div>
                </div>
                <div class="col-md-4 mt-2 mb-2">

                </div>
                <div class="col-md-4">
                  <div class="input-group input-group-md mt-2 mb-2 d-flex justify-content-end">
                    <div class="input-group-prepend">
                      <button type="button" v-show="can_add && this.ppmpdetails.status == 1 || this.ppmpdetails.status == 0" class="btn btn-primary" @click="changeStatus(2, 'Submitting finalizes PPMP and will disabled editing of data (except item specifications). Do you really want to submit PPMP for the selected year?')"><i class="fa fa-paper-plane"></i> SUBMIT PPMP YR</button>
                      <button type="button" v-show="can_add && this.ppmpdetails.status == 2"  class="btn btn-primary" @click="changeStatus(1, 'This will change the status of the PPMP to Revise. Do you really want to proceed?')"><i class="fa fa-undo"></i> REVISE PPMP YR</button>
                      <button type="button" v-show="can_delete && this.ppmpdetails.status == 1 || this.ppmpdetails.status == 0" class="btn btn-danger" @click="actionDelete()"><i class="fa fa-trash"></i> DELETE PPMP YR</button>
                      <button type="button" v-show="can_add" class="btn btn-success btn-md" @click="addNewPpmp()"><i class="fa fa-plus"></i> NEW PPMP YR</button>
                    </div>
                    <select style="width:200px !important" @change="loadPpmpitems(year)" name="type" v-model="year" class="form-control">
                      <option v-for="(yr,idx) in ppmpyears" :value="yr.year" :key="idx">{{ yr.year }}</option>
                    </select>
                  </div>
                </div>

            </div>

            <div class="card-body table-responsive p-0 m-0">
              <table class="table responsive-table table-sm table-striped">
                <thead>
                    <tr>
                    <th class="text-left pl-2" scope="col">Date</th>
                    <th class="text-left" scope="col">Code</th>
                    <th class="text-left" scope="col">Category</th>
                    <th class="text-left" scope="col">Description</th>
                    <th class="text-left" scope="col">UOM</th>
                    <th class="text-center border-left" scope="col">Cost</th>
                    <th class="text-center border-left" scope="col">Jan</th>
                    <th class="text-center" scope="col">Feb</th>
                    <th class="text-center" scope="col">Mar</th>
                    <th class="text-center border-left" scope="col">Apr</th>
                    <th class="text-center" scope="col">May</th>
                    <th class="text-center" scope="col">Jun</th>
                    <th class="text-center border-left" scope="col">Jul</th>
                    <th class="text-center" scope="col">Aug</th>
                    <th class="text-center" scope="col">Sep</th>
                    <th class="text-center border-left" scope="col">Oct</th>
                    <th class="text-center" scope="col">Nov</th>
                    <th class="text-center" scope="col">Dec</th>
                    <th class="text-center border-left" scope="col">Qty</th>
                    <th class="text-center border-left border-right" scope="col">Amt</th>
                    <th class="text-center" scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="itms in ppmpitems" :key="itms.id">
                        <td data-title="Date">{{ itms.date }}</td>
                        <td data-title="Code">{{ itms.item_code }}</td>
                        <td data-title="Category"><small>{{ itms.name }}</small></td>
                        <td data-title="Description"><small>{{ itms.description }}</small></td>
                        <td data-title="UOM"><small>{{ itms.uom_name }}</small></td>
                        <td data-title="Cost" class="text-right border-left">{{ itms.unit_cost | toMoney  }}</td>
                        <td data-title="Jan" v-if="itms.jan !== 0" class="text-center border-left">{{ itms.jan }}</td><td data-title="Jan" v-else>-</td>
                        <td data-title="Feb" v-if="itms.feb !== 0" class="text-center">{{ itms.feb }}</td><td data-title="Feb" v-else>-</td>
                        <td data-title="Mar" v-if="itms.mar !== 0" class="text-center">{{ itms.mar }}</td><td data-title="Mar" v-else>-</td>
                        <td data-title="Apr" v-if="itms.apr !== 0" class="text-center border-left">{{ itms.apr }}</td><td data-title="Apr" v-else>-</td>
                        <td data-title="May" v-if="itms.may !== 0" class="text-center">{{ itms.may }}</td><td data-title="May" v-else>-</td>
                        <td data-title="Jun" v-if="itms.jun !== 0" class="text-center">{{ itms.jun }}</td><td data-title="Jun" v-else>-</td>
                        <td data-title="Jul" v-if="itms.jul !== 0" class="text-center border-left">{{ itms.jul }}</td><td data-title="Jul" v-else>-</td>
                        <td data-title="Aug" v-if="itms.aug !== 0" class="text-center">{{ itms.aug }}</td><td data-title="Aug" v-else>-</td>
                        <td data-title="Sep" v-if="itms.sep !== 0" class="text-center">{{ itms.sep }}</td><td data-title="Sep" v-else>-</td>
                        <td data-title="Oct" v-if="itms.oct !== 0" class="text-center border-left">{{ itms.oct }}</td><td data-title="Oct" v-else>-</td>
                        <td data-title="Nov" v-if="itms.nov !== 0" class="text-center">{{ itms.nov }}</td><td data-title="Nov" v-else>-</td>
                        <td data-title="Dec" v-if="itms.dec !== 0" class="text-center">{{ itms.dec }}</td><td data-title="Dec" v-else>-</td>
                        <td data-title="Qty" class="text-center border-left">{{ itms.tot_qty }}</td>
                        <td data-title="Amt" class="text-right border-left border-right"><b>{{ itms.tot_amount | toMoney  }}</b></td>
                        <td data-title="" v-if="can_edit">
                          <div class="btn-group">
                            <button :disabled="ppmpdetails.status == 3" type="button" class="btn btn-default" @click="editModal(itms)"><i class="fas fa-edit text-primary"></i></button>
                            <button :disabled="ppmpdetails.status == 3" type="button" class="btn btn-default" @click="deletePpmpItem(itms.id)"><i class="fas fa-times text-danger"></i></button>
                          </div>
                        </td>
                        <td data-title="" v-else>
                          <button disabled type="button" class="btn btn-default"><i class="fas fa-edit text-primary"></i></button>
                          <button disabled type="button" class="btn btn-default"><i class="fas fa-times text-danger"></i></button>
                        </td>
                    </tr>
                </tbody>
                <tbody v-if="ppmpitems" v-show="ppmpitems.length==0">
                    <tr><td colspan="21">No procurement items found.</td></tr>
                </tbody>
              </table>
            </div>
            <!-- /.card-body <pagination :data="ppmpitems" @pagination-change-page="getResults" show-disabled :limit="2"></pagination> -->
            <div class="card-footer">
              <div class="card-tools d-flex justify-content-end">
                <span v-if="(this.ppmpdetails.status == 0)" class="badge badge-info"><i>Status:</i> <b>In-Preparation</b></span>
                <span v-else-if="(this.ppmpdetails.status == 1)" class="badge badge-warning"><i>Status:</i> <b>Revision</b></span>
                <span v-else-if="(this.ppmpdetails.status == 2)" class="badge badge-primary"><i>Status:</i> <b>Pending</b></span>
                <span v-else-if="(this.ppmpdetails.status == 3)" class="badge badge-success"><i>Status:</i> <b>Approved</b></span>
                <span v-if="ppmpdetails.ppmpitems" class="badge badge-secondary ml-1 mr-1"><i>No. of Items:</i> <b>{{ ppmpdetails.ppmpitems.length }}</b></span>
                <span v-if="ppmpdetails.ppmpitems" class="badge badge-secondary"><i>Total Amount:</i> <b>{{ this.total_expenses | toMoney }}</b></span>

              </div>
            </div>
          </div>
          <!-- /.card -->
        </div>
      </div>

      <!-- Modal -->
      <div class="modal fade" id="addppmpmodal" tabindex="-1" role="dialog" aria-labelledby="addppmpModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="addppmpModalLabel">Add PPMP Year</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div class="modal-body">
              <div class="row">

                <div class="col">
                  <div class="form-group">
                    <label>PPMP Year</label>
                    <input type="number" class="form-control" v-model="formPpmpyr.year" :class="{ 'is-invalid': formPpmpyr.errors.has('year') }">
                    <has-error :form="formPpmpyr" field="year"></has-error>
                  </div>
                  <div class="form-group">
                    <label>Source of Fund</label>
                    <select v-model="formPpmpyr.fundsource_id" name="type" class="form-control" :class="{ 'is-invalid': formPpmpyr.errors.has('fundsource_id') }">
                      <option v-for="(src,idx) in fundsources" :value="src.id" :key="idx">{{ src.alias }}</option>
                    </select>
                    <has-error :form="formPpmpyr" field="fundsource_id"></has-error>
                  </div>
                </div>
                </div>
            </div>
            <div class="card-footer">
              <button class="btn btn-primary" data-container="body" type="button" @click="addppmpYear()"><i class="fa fa-save"></i> Save</button>
              <button class="btn btn-danger" data-container="body" type="button" data-dismiss="modal" aria-label="Close"><i class="fa fa-times"></i> Close</button>
            </div>

        </div>
      </div>
      </div>

      <!-- Modal -->
      <div class="modal fade" id="editppmpmodal" tabindex="-1" role="dialog" aria-labelledby="editppmpModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="editppmpModalLabel">Change PPMP Fund Source</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div class="modal-body">
              <div class="row">

                <div class="col">
                  <div class="form-group">
                    <label>Source of Fund</label>
                    <select v-model="formPpmpyr.fundsource_id" name="type" class="form-control" :class="{ 'is-invalid': formPpmpyr.errors.has('fundsource_id') }">
                      <option v-for="(src,idx) in fundsources" :value="src.id" :key="idx">{{ src.alias }}</option>
                    </select>
                    <has-error :form="formPpmpyr" field="fundsource_id"></has-error>
                  </div>
                </div>
                </div>
            </div>
            <div class="card-footer">
              <button class="btn btn-primary" data-container="body" type="button" @click="updateppmpYear()"><i class="fa fa-save"></i> Update</button>
              <button class="btn btn-danger" data-container="body" type="button" data-dismiss="modal" aria-label="Close"><i class="fa fa-times"></i> Close</button>
            </div>

        </div>
      </div>
      </div>

      <!-- Modal -->
      <div class="modal fade" id="actionmodal" tabindex="-1" role="dialog" aria-labelledby="actionModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="actionModalLabel">PPMP Item</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div class="modal-body">
              <div class="row">

                <div class="col-sm-4">
                  <div class="form-group">
                    <label>Item Code</label>
                    <input type="text" class="form-control" v-model="form.item_code" disabled>
                  </div>
                  <div class="form-group">
                    <label>Description</label>
                    <textarea class="form-control" rows="10" data-gramm="false" wt-ignore-input="true" v-model="form.description" disabled></textarea>
                  </div>
                  <div class="form-group">
                    <label>Category</label>
                    <input type="text" class="form-control" v-model="form.name" disabled>
                  </div>
                  <div class="form-group">
                    <label>Unit of Measure</label>
                    <input type="text" class="form-control" v-model="form.uom_name" disabled>
                  </div>
                  <div class="form-group">
                    <label>Estimated Cost</label>
                    <input type="text" class="form-control" v-model="form.unit_cost">
                  </div>
                  <div class="form-group">
                    <label>Source of Fund</label>
                    <select v-model="form.src_id" name="type" class="form-control">
                      <option v-for="(src,idx) in fundsources" :value="src.id" :key="idx">{{ src.alias }}</option>
                    </select>
                  </div>
                  <div class="form-group">
                      <label>Mode of Procurement</label>
                      <select v-model="form.mod_code" name="type" class="form-control">
                        <option v-for="(src,idx) in procmodes" :value="src.code" :key="idx">{{ src.alias }}</option>
                      </select>
                    </div>
                  <!--
                  <div class="form-group">
                    <label>Mode of Procurement</label>
                    <select class="form-control" v-model="form.mod_code">
                      <option value=""> - Select Mode - </option>
                      <option value="PB">Public Bidding</option>
                      <option value="LS">Limited Source Bidding</option>
                      <option value="DC">Direct Contracting</option>
                      <option value="RO">Repeat Order</option>
                      <option value="SA">Shopping 52.1a</option>
                      <option value="SB">Shopping 52.1b (above 50T)</option>
                      <option value="SS">Shopping 52.1b (50T & below)</option>
                      <option value="2F">2 Failed Biddings</option>
                      <option value="EC">Negotiated Procurement (Emergency Cases)</option>
                      <option value="TO">Take-Over of Contracts</option>
                      <option value="AC">Adjacent/Contiguous</option>
                      <option value="AA">Agency-to-Agency</option>
                      <option value="ES">Exclusive Services</option>
                      <option value="HT">Highly Technical Consultant</option>
                      <option value="SV">Small Value Procurement</option>
                      <option value="LV">Lease of Venue</option>
                      <option value="NP">NGO Participation</option>
                      <option value="CP">Community Participation</option>
                      <option value="IA">International Agencies</option>
                      <option value="DP">Direct Retail Purchase</option>
                    </select>
                  </div>
                  -->
                </div>
                <div class="col-sm-4">
                  <div class="form-group">
                    <label>Contract Date Start</label>
                    <input type="date" class="form-control" placeholder="yyyy-mm-dd" v-model="form.date_start">
                  </div>
                  <div class="form-group">
                    <label>Contract Date End</label>
                    <input type="date" class="form-control" placeholder="yyyy-mm-dd" v-model="form.date_end">
                  </div>
                  <div class="form-group">
                    <label>Item Specifications</label>
                    <textarea class="form-control" rows="24" data-gramm="false" wt-ignore-input="true" v-model="form.specs"></textarea>
                  </div>
                </div>
                <div class="col-sm-4 alert alert-warning" style="margin-left: -10px !important;">
                  <div class="form-group">
                    <label><u>Schedule/Milestone of Activity (in quantity)</u></label>
                  </div>
                  <div class="row">
                    <div class="col-sm-5">
                    <div class="form-group">
                      <label>January</label>
                    </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <input type="number" class="form-control" v-model="form.jan">
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-5">
                    <div class="form-group">
                      <label>February</label>
                    </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <input type="number" class="form-control" v-model="form.feb">
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-5">
                    <div class="form-group">
                      <label>March</label>
                    </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <input type="number" class="form-control" v-model="form.mar">
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-5">
                    <div class="form-group">
                      <label>April</label>
                    </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <input type="number" class="form-control" v-model="form.apr">
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-5">
                    <div class="form-group">
                      <label>May</label>
                    </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <input type="number" class="form-control" v-model="form.may">
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-5">
                    <div class="form-group">
                      <label>June</label>
                    </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <input type="number" class="form-control" v-model="form.jun">
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-5">
                    <div class="form-group">
                      <label>July</label>
                    </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <input type="number" class="form-control" v-model="form.jul">
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-5">
                    <div class="form-group">
                      <label>August</label>
                    </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <input type="number" class="form-control" v-model="form.aug">
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-5">
                    <div class="form-group">
                      <label>September</label>
                    </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <input type="number" class="form-control" v-model="form.sep">
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-5">
                    <div class="form-group">
                      <label>October</label>
                    </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <input type="number" class="form-control" v-model="form.oct">
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-5">
                    <div class="form-group">
                      <label>November</label>
                    </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <input type="number" class="form-control" v-model="form.nov">
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-sm-5">
                    <div class="form-group">
                      <label>December</label>
                    </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="form-group">
                        <input type="number" class="form-control" v-model="form.dec">
                      </div>
                    </div>
                  </div>
                </div>

              </div>



              <div class="row">

              </div>

              <div class="row">

              </div>

            </div>
            <div class="card-footer">
              <button class="btn btn-primary" data-container="body" type="button" @click="updateItem()"><i class="fa fa-save"></i> Save</button>
              <button class="btn btn-primary" data-container="body" type="button" data-dismiss="modal" aria-label="Close"><i class="fa fa-times"></i> Close</button>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" id="actionadditemmodal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="actionadditemModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="actionadditemModalLabel">Select Procurement Item</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="row m-0 bg-secondary disabled color-palette sticky-top">
                <div class="col-md-4">

                </div>
                <div class="col-md-4 mt-2 mb-2">

                </div>
                <div class="col-md-4">
                  <div class="input-group input-group-md mt-2 mb-2 d-flex justify-content-end">
                    <div class="input-group input-group-sm" style="width: 150px;">
                    <input type="text" name="table_search" class="form-control float-right" v-model="searchkey" placeholder="Filter" />
                    <div class="input-group-append">
                    <button v-if="!this.searchkey" type="submit" class="btn btn-default"><i class="fas fa-filter"></i></button>
                    <button v-else type="submit" @click="clearSearchkey()" class="btn btn-default"><i class="fas fa-undo"></i></button>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row m-0 table-responsive p-0" style="height: 300px;">
                <table class="table table-head-fixed text-nowrap">
              <thead>
                <tr>
                  <th><i class="fa fa-check" aria-hidden="true"></i></th>
                  <th>DESCRIPTION</th>
                  <th>ITEM CODE</th>
                  <th>UOM</th>
                  <th>PRICE</th>
                  <th>As of</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="itms in filteredProcurementitems" :key="itms.id">
                  <td><input v-bind:id="itms.id" type="checkbox" :value="itms" v-model="selected_items_form.selected_items" /></td>
                  <td>{{ itms.description }}</td>
                  <td>{{ itms.item_code }}</td>
                  <td>{{ itms.unit_name.abbr }}</td>
                  <td>{{ itms.ps_price }}</td>
                  <td>{{ itms.ps_date }}</td>
                </tr>
              </tbody>
              </table>
              </div>
            </div>
            <div class="card-footer">
              <button class="btn btn-primary" v-if="selected_items_form.selected_items.length !== 0" data-container="body" type="button" @click="addToFormSelectedItems()"><i class="fa fa-save"></i> Add Selected Items</button>
              <button class="btn btn-primary" v-else data-container="body" type="button" disabled><i class="fa fa-save"></i> Add Selected Items</button>
              <button class="btn btn-danger" data-container="body" type="button" data-dismiss="modal" aria-label="Close"><i class="fa fa-times"></i> Close</button>
            </div>
          </div>
        </div>
      </div>


      <!-- Print Modal -->
      <div class="modal fade" id="printmodal" tabindex="-1" role="dialog" aria-labelledby="printModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
          <div class="modal-content">
            <div class="modal-header bg-success">
              <h5 class="modal-title text-dark" id="printModalLabel">PPMP (Print Preview)</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div class="modal-body">
              <div id="printAppendix60">
                <div class="card-body" style="font-family: Times New Roman;">
                  <table class="table table-borderless customtextsize" style="border:2px solid #000000;">
                      <tr>
                        <td colspan="17" align="center"><b>PROJECT PROCUREMENT MANAGEMENT PLAN (PPMP)</b></td>
                      </tr>
                      <tr>
                        <td colspan="12" align="left">
                            <b><i>END USER/UNIT:</i> {{ this.office_name }}</b><br/>
                            <b><i>FUNDING SOURCE: {{ this.fund_source }}</i></b><br/>
                            <i>Projects, Programs and Activities (PAPs)</i>
                        </td>
                        <td colspan="5" align="right">
                            <b><i>CY:</i> <u>{{ this.year }}</u></b><br/>
                        </td>
                      </tr>
                  </table>
                  <table class="table table-borderless customtextsize" style="border:2px solid #000000;">
                    <thead>
                      <tr>
                        <td rowspan="2" style="border:2px solid #000000;padding:2px;width:110px;" align="center"><b>CODE/<br>ITEM NO.</b></td>
                        <td rowspan="2" style="border:2px solid #000000;padding:2px;" align="center"><b>GENERAL DESCRIPTION</b></td>
                        <td rowspan="2" style="border:2px solid #000000;padding:2px;" align="center"><b>QUANTITY/<br>SIZE</b></td>
                        <td rowspan="2" style="border:2px solid #000000;padding:2px;" align="center"><b>UNIT</b></td>
                        <td rowspan="2" style="border:2px solid #000000;padding:2px;" align="center"><b>UNIT PRICE</b></td>
                        <td rowspan="2" style="border:2px solid #000000;padding:2px;" align="center"><b>ESTIMATED BUDGET</b></td>
                        <td rowspan="2" style="border:2px solid #000000;padding:2px;" align="center"><b>MODE OF PROCUREMENT</b></td>
                        <td rowspan="1" colspan="12" style="border:2px solid #000000;padding:2px;" align="center"><b>SCHEDULE/MILESTONE OF ACTIVITIES</b></td>
                      </tr>
                      <tr>
                        <!-- <td style="border:2px solid #000000;padding:2px;" align="center"><b>SIZE</b></td> -->
                        <td style="border:2px solid #000000;padding:2px;" align="center"><b>Jan</b></td>
                        <td style="border:2px solid #000000;padding:2px;" align="center"><b>Feb</b></td>
                        <td style="border:2px solid #000000;padding:2px;" align="center"><b>Mar</b></td>
                        <td style="border:2px solid #000000;padding:2px;" align="center"><b>Apr</b></td>
                        <td style="border:2px solid #000000;padding:2px;" align="center"><b>May</b></td>
                        <td style="border:2px solid #000000;padding:2px;" align="center"><b>Jun</b></td>
                        <td style="border:2px solid #000000;padding:2px;" align="center"><b>Jul</b></td>
                        <td style="border:2px solid #000000;padding:2px;" align="center"><b>Aug</b></td>
                        <td style="border:2px solid #000000;padding:2px;" align="center"><b>Sep</b></td>
                        <td style="border:2px solid #000000;padding:2px;" align="center"><b>Oct</b></td>
                        <td style="border:2px solid #000000;padding:2px;" align="center"><b>Nov</b></td>
                        <td style="border:2px solid #000000;padding:2px;" align="center"><b>Dec</b></td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="itms in ppmpitems" :key="itms.id">
                        <td style="border:2px solid #000000;padding:2px;">{{ itms.item_code }}</td>
                        <td style="border:2px solid #000000;padding:2px;">{{ itms.description }}</td>
                        <td style="border:2px solid #000000;padding:2px;" class="text-center">{{ itms.tot_qty }}</td>
                        <td style="border:2px solid #000000;padding:2px;" class="text-center">{{ itms.uom_name }}</td>
                        <td style="border:2px solid #000000;padding:2px;" class="text-center">{{ itms.unit_cost }}</td>
                        <td style="border:2px solid #000000;padding:2px;" class="text-right">{{ itms.tot_amount | toMoney  }}</td>
                        <td style="border:2px solid #000000;padding:2px;" class="text-right">{{ itms.procmode.alias  }}</td>

                        <!--
                        <td v-if="itms.mod_code == 'PB'" style="border:2px solid #000000;padding:2px;">Public Bidding</td>
                        <td v-else-if="itms.mod_code == 'LS'" style="border:2px solid #000000;padding:2px;">Limited Source Bidding</td>
                        <td v-else-if="itms.mod_code == 'DC'" style="border:2px solid #000000;padding:2px;">Direct Contracting</td>
                        <td v-else-if="itms.mod_code == 'RO'" style="border:2px solid #000000;padding:2px;">Repeat Order</td>
                        <td v-else-if="itms.mod_code == 'SA'" style="border:2px solid #000000;padding:2px;">Shopping 52.1a</td>
                        <td v-else-if="itms.mod_code == 'SB'" style="border:2px solid #000000;padding:2px;">Shopping 52.1b (above 50T)</td>
                        <td v-else-if="itms.mod_code == 'SS'" style="border:2px solid #000000;padding:2px;">Shopping 52.1b (50T & below)</td>
                        <td v-else-if="itms.mod_code == '2F'" style="border:2px solid #000000;padding:2px;">2 Failed Biddings</td>
                        <td v-else-if="itms.mod_code == 'EC'" style="border:2px solid #000000;padding:2px;">Negotiated Procurement (Emergency Cases)</td>
                        <td v-else-if="itms.mod_code == 'TO'" style="border:2px solid #000000;padding:2px;">Take-Over of Contracts</td>
                        <td v-else-if="itms.mod_code == 'AC'" style="border:2px solid #000000;padding:2px;">Adjacent/Contiguous</td>
                        <td v-else-if="itms.mod_code == 'AA'" style="border:2px solid #000000;padding:2px;">Agency-to-Agency</td>
                        <td v-else-if="itms.mod_code == 'ES'" style="border:2px solid #000000;padding:2px;">Exclusive Services</td>
                        <td v-else-if="itms.mod_code == 'HT'" style="border:2px solid #000000;padding:2px;">Highly Technical Consultant</td>
                        <td v-else-if="itms.mod_code == 'SV'" style="border:2px solid #000000;padding:2px;">Small Value Procurement</td>
                        <td v-else-if="itms.mod_code == 'LV'" style="border:2px solid #000000;padding:2px;">Lease of Venue</td>
                        <td v-else-if="itms.mod_code == 'NP'" style="border:2px solid #000000;padding:2px;">NGO Participation</td>
                        <td v-else-if="itms.mod_code == 'CP'" style="border:2px solid #000000;padding:2px;">Community Participation</td>
                        <td v-else-if="itms.mod_code == 'IA'" style="border:2px solid #000000;padding:2px;">International Agencies</td>
                        <td v-else-if="itms.mod_code == 'DP'" style="border:2px solid #000000;padding:2px;">Direct Retail Purchase</td>
                        <td v-else style="border:2px solid #000000;padding:2px;">NA</td>
                        -->

                        <td v-if="itms.jan !== 0" style="border:2px solid #000000;padding:2px;" class="text-center">{{ itms.jan }}</td><td data-title="Jan" v-else>-</td>
                        <td v-if="itms.feb !== 0" style="border:2px solid #000000;padding:2px;" class="text-center">{{ itms.feb }}</td><td data-title="Feb" v-else>-</td>
                        <td v-if="itms.mar !== 0" style="border:2px solid #000000;padding:2px;" class="text-center">{{ itms.mar }}</td><td data-title="Mar" v-else>-</td>
                        <td v-if="itms.apr !== 0" style="border:2px solid #000000;padding:2px;" class="text-center">{{ itms.apr }}</td><td data-title="Apr" v-else>-</td>
                        <td v-if="itms.may !== 0" style="border:2px solid #000000;padding:2px;" class="text-center">{{ itms.may }}</td><td data-title="May" v-else>-</td>
                        <td v-if="itms.jun !== 0" style="border:2px solid #000000;padding:2px;" class="text-center">{{ itms.jun }}</td><td data-title="Jun" v-else>-</td>
                        <td v-if="itms.jul !== 0" style="border:2px solid #000000;padding:2px;" class="text-center">{{ itms.jul }}</td><td data-title="Jul" v-else>-</td>
                        <td v-if="itms.aug !== 0" style="border:2px solid #000000;padding:2px;" class="text-center">{{ itms.aug }}</td><td data-title="Aug" v-else>-</td>
                        <td v-if="itms.sep !== 0" style="border:2px solid #000000;padding:2px;" class="text-center">{{ itms.sep }}</td><td data-title="Sep" v-else>-</td>
                        <td v-if="itms.oct !== 0" style="border:2px solid #000000;padding:2px;" class="text-center">{{ itms.oct }}</td><td data-title="Oct" v-else>-</td>
                        <td v-if="itms.nov !== 0" style="border:2px solid #000000;padding:2px;" class="text-center">{{ itms.nov }}</td><td data-title="Nov" v-else>-</td>
                        <td v-if="itms.dec !== 0" style="border:2px solid #000000;padding:2px;" class="text-center">{{ itms.dec }}</td><td data-title="Dec" v-else>-</td>
                      </tr>
                      <tr class="footer">
                        <td style="padding:2px;" align="left" colspan="17" ><br/><b><u>TOTAL BUDGET:</u> {{ this.total_expenses | toMoney }}</b><br/><br/></td>
                      </tr>
                      <tr>
                        <td style="padding:2px;" align="left" colspan="17" >NOTE:  Technical Specifications for each Item/Project being proposed shall be submitted as part of the PPMP<br/><br/></td>
                      </tr>
                      <tr>
                        <td colspan="3" style="padding:2px;" align="left">Prepared By:<br/><br/></td>
                        <td colspan="4" style="padding:2px;" align="left">Submitted By:<br/><br/></td>
                        <td colspan="12" style="padding:2px;" align="left">Noted By:<br/><br/></td>
                      </tr>
                      <tr>
                        <td colspan="3" style="padding:2px;" align="left"><b><u>{{ this.$gate.showFname() }} {{ this.$gate.showMname() | midname }} {{ this.$gate.showLname() }}</u></b></td>
                        <td colspan="4" style="padding:2px;" align="left"><input style="border:0;width:320px;outline:0;background:transparent;border-bottom:1px solid black;" type="text" name="head_name" placeholder="[Name]" autocomplete="off" /></td>
                        <td colspan="12" style="padding:2px;" align="left"><input style="border:0;width:320px;outline:0;background:transparent;border-bottom:1px solid black;" type="text" name="head_name" placeholder="[Name]" autocomplete="off" /></td>
                      </tr>
                      <tr>
                        <td colspan="3" style="padding:2px;" align="left"><b>{{ this.$gate.showPosition() }}</b><br/></td>
                        <td colspan="4" style="padding:2px;" align="left"><input style="border:0;width:320px;outline:0;background:transparent;border-bottom:0px solid black;" type="text" name="head_position" placeholder="[Position]" autocomplete="off" /><br/></td>
                        <td colspan="12" style="padding:2px;" align="left"><input style="border:0;width:320px;outline:0;background:transparent;border-bottom:0px solid black;" type="text" value="Budget Section" name="head_position" placeholder="[Position]" autocomplete="off" /><br/></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

            </div>
            <div class="card-footer">
              <button v-print="'#printAppendix60'" @click="print" class="btn btn-primary" data-container="body" type="button"><i class="fa fa-print"></i> Print</button>
              <button class="btn btn-primary" data-container="body" type="button" data-dismiss="modal" aria-label="Close"><i class="fa fa-times"></i> Close</button>
            </div>
          </div>
        </div>
      </div>



    </div>

</template>

<script>

  import Form from 'vform';
  import { VueEditor } from "vue2-editor";
  import moment from 'moment';

  export default {
      name: 'Ppmp',

      components: {
          VueEditor,
      },

      data() {
        return {
          excel_header: 'PROJECT PROCUREMENT MANAGEMENT PLAN (PPMP)',
          excel_title: 'TTILE',
          excel_footer: 'FOOTER',
          file_name: 'PPMP_.xls',
          excel_fields: {
              'CODE / ITEM NO.': 'item_code',
              'GENERAL DESCRIPTION': 'description',
              'QUANTITY / SIZE': 'tot_qty',
              'UNIT': 'uom_name',
              'UNIT PRICE': {
                  field: 'unit_cost',
                  callback: (value) => {
                      return this.$options.filters.toMoney(value);
                  }
              },
              'ESTIMATED BUDGET': {
                  field: 'tot_amount',
                  callback: (value) => {
                      return this.$options.filters.toMoney(value);
                  }
              },
              'MODE OF PROCUREMENT': 'procmode.alias',
            //   'Mode of Procurement': {
            //       field: 'mod_code',
            //       callback: (value) => {
            //         if(value == 'PB'){
            //           return `Public Bidding`;
            //         } else if (value == 'LS') {
            //           return `Limited Source Bidding`;
            //         } else if (value == 'DC') {
            //           return `Direct Contracting`;
            //         } else if (value == 'RO') {
            //           return `Repeat Order`;
            //         } else if (value == 'SA') {
            //           return `Shopping 52.1a`;
            //         } else if (value == 'SB') {
            //           return `Shopping 52.1b (above 50T)`;
            //         } else if (value == 'SS') {
            //           return `Shopping 52.1b (50T & below)`;
            //         } else if (value == '2F') {
            //           return `2 Failed Biddings`;
            //         } else if (value == 'EC') {
            //           return `Negotiated Procurement (Emergency Cases)`;
            //         } else if (value == 'TO') {
            //           return `Take-Over of Contracts`;
            //         } else if (value == 'AC') {
            //           return `Adjacent/Contiguous`;
            //         } else if (value == 'AA') {
            //           return `Agency-to-Agency`;
            //         } else if (value == 'ES') {
            //            return `Exclusive Services`;
            //         } else if (value == 'HT') {
            //            return `Highly Technical Consultant`;
            //         } else if (value == 'SV') {
            //            return `Small Value Procurement`;
            //         } else if (value == 'LV') {
            //            return `Lease of Venue`;
            //         } else if (value == 'NP') {
            //            return `NGO Participation`;
            //         } else if (value == 'CP') {
            //            return `Community Participation`;
            //         } else if (value == 'IA') {
            //            return `International Agencies`;
            //         } else if (value == 'DP') {
            //            return `Direct Retail Purchase`;
            //         } else {
            //           return `NA`;
            //         }

            //       }
            //     },
              'Jan': 'jan',
              'Feb': 'feb',
              'Mar': 'mar',
              'Apr': 'apr',
              'May': 'may',
              'Jun': 'jun',
              'Jul': 'jul',
              'Aug': 'aug',
              'Sep': 'sep',
              'Oct': 'oct',
              'Nov': 'nov',
              'Dec': 'dec',
              /*
              'Category': 'name',
              'Unit': 'uom_name',
              'Total Order': 'tot_qty',
              'Unit Price': 'unit_cost',
              'Estimated Budget': 'tot_amount',
              */
          },
          timer: 30,
          searchkey: '',
          editmode: false,
          loading: false,
          emptyflag: false,
          can_access: false,
          can_add: false,
          can_edit: false,
          can_delete: false,
          procmodes: {},
          procurementitems: {},
          ppmpdetails : {},
          ppmpitems : {},
          ppmpyears : {},
          fundsources: {},
          year: '',
          officeid: 0,
          userlevelid: 0,
          total_expenses: 0,
          role: {},
          office_name: '',
          fund_source: '',
          ppmp_id: null,
          form: new Form({
            id: 0,
            description: '',
            item_code: '',
            name: '',
            uom_name: '',
            unit_cost: '',
            mod_code: '',
            date_start: '',
            date_end: '',
            specs: '',
            src_id: 0,
            jan: 0,
            feb: 0,
            mar: 0,
            apr: 0,
            may: 0,
            jun: 0,
            jul: 0,
            aug: 0,
            sep: 0,
            oct: 0,
            nov: 0,
            dec: 0,
          }),
          formPpmpyr: new Form({
            id: null,
            year: null,
            office_id: null,
            fundsource_id: null,
          }),
          selected_items_form: new Form({
            ppmp_id: 0,
            selected_items: [],
          }),
          ppmp_form: new Form({
            year: 0,
            office_id: 0,
          }),
        }
      },

      props: [
        'todo'
      ],

      computed: {

        second() {
          return this.time / 1000;
        },

        filteredProcurementitems() {
          let tempItems = this.procurementitems;
          // Process search input
          if (this.searchkey != '' && this.searchkey) {
            tempItems = tempItems.filter((item) => {
              return item.description.toUpperCase().includes(this.searchkey.toUpperCase())
            })
          }

          return tempItems;
        },

        isIdle() {
          const idlestat =  this.$store.state.idleVue.isIdle;
          if (idlestat == true) {
            $('#sessionmodal').modal('show');
            this.countDownTimer();
          } else {
            $('#sessionmodal').modal('hide');
          }
          return idlestat;
        },

        years() {
          const year = new Date().getFullYear()
          return Array.from({length: year - 2018}, (value, index) => 2019 + index + 1)
        },

      },

      methods: {

        print(){
            var style = document.createElement('style');
            style.innerHTML = "@page{size: A4 landscape !important;margin: 25mm !important;}" +
                    " html{background-color: #FFFFFF;}" +
                    " body{border: solid 0px #FFFFFF;margin: 0;}";
            window.document.head.appendChild(style);
        },

        countDownTimer() {
          let timerId = setInterval(() => {
            this.timer -= 1;
            if (!this.$store.state.idleVue.isIdle) clearInterval(timerId);

            if (this.timer < 1) {
              clearInterval(timerId);
              document.getElementById('logout-form').submit();
            }
          }, 1000);

          this.timer = 30;
        },

        clearSearchkey() {
          this.searchkey = '';
        },

        computeExpenses() {
            return this.ppmpdetails.ppmpitems.reduce((accum, item) => {
                return accum + parseFloat(item.tot_amount)
            }, 0.00)
          },

        newPpmpCy() {
          this.editmode = true;
          this.form.reset();
          this.form.clear();
          this.form.fill(payload);
          $('#actionmodal').modal('show');
        },

        printModal() {
          $('#printmodal').modal('show');
        },

        editModal(payload) {
          //console.log(payload)
          this.editmode = true;
          this.form.reset();
          this.form.clear();
          this.form.fill(payload);
          $('#actionmodal').modal('show');
        },

        updateItem() {
          this.$Progress.start();
          this.form.put('api/ppmpitems/'+this.form.id).then(()=>{
            Trigger.$emit('RefreshItems');
            $('#actionmodal').modal('hide')
            toastr.fire({
              icon: 'success',
              title: 'Item Saved'
            })
            this.$Progress.finish();
          }).catch(()=>{
            toastr.fire({
              icon: 'error',
              title: 'Item saving failed'
            });
            this.$Progress.fail();
          });
        },

        loadProcmodes() {
            axios.get('api/procmodes').then(({ data }) => (this.procmodes = data));
        },

        async loadOffice() {
          await axios.get('api/procurement-role/user/' + this.$gate.showMyID()).then(({ data }) => (
            this.officeid = data.officeid,
            this.ppmp_form.office_id = data.officeid,
            this.formPpmpyr.office_id = data.officeid,
            this.userlevelid = data.userlevelid,
            this.role = data,
            this.loadFundSources(),
            this.loadPpmpitemsYears()
          ));
        },

        loadPpmpitemsYears() {

          if(!this.officeid) { //check if user is in role/signatory
            this.$router.push({ name: 'not-found' })
          } else {
            if(this.role.userlevelid !== 7) { //check if user is in PMO/End-User level
              this.$router.push({ name: 'not-found' })
            } else {
              axios.get('api/ppmps/years/' + this.officeid).then(({ data }) => (
                this.ppmpyears = data,
                this.year = data[0].year,
                this.loadPpmpitems(this.year)
              ));
            }
          }

        },

        loadFundSources() {
          axios.get('api/fundsources').then(({ data }) => (
            this.fundsources = data
          ));
        },

        changeStatus(status, txt) {
          swal.fire({
          title: 'Warning',
          text: txt,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes'
          }).then((result)=>{
            if (result.value) {
              axios.put('api/ppmps/status/'+ status + '/0/' + this.ppmpdetails.id).then(()=>{
                swal.fire(
                  'Confirmation',
                  'PPMP status has been updated.',
                  'success'
                );
                Trigger.$emit('RefreshItems');
              }).catch(()=>{
                swal.fire(
                  'Failed!',
                  'There was something wrong',
                  'warning'
                )
              });
            }
          })
        },


        loadPpmpitems(yr) {
          this.$Progress.start();

          axios.get('api/ppmps/' + this.officeid + '/' + yr).then(({ data }) => (
            this.ppmpitems = data.data[0].ppmpitems,
            this.ppmpdetails = data.data[0],
            this.selected_items_form.ppmp_id = data.data[0].id,
            this.total_expenses = this.computeExpenses(data.data[0]),
            this.office_name = data.data[0].office.name,
            this.fund_source = data.data[0].fundsource.alias,
            this.ppmp_id = data.data[0].id,
            this.file_name = 'PPMP - ' + yr + ' - ' + data.data[0].office.name + '.xls',
            this.excel_header = ["PROJECT PROCUREMENT MANAGEMENT PLAN (PPMP)", "END USER/UNIT: " + data.data[0].office.name, "Charged to " + data.data[0].fundsource.alias + " CY " + yr, "Projects, Programs and Activities (PAPs)", "SCHEDULE/MILESTONE OF ACTIVITIES"],
            this.excel_footer = ["&nbsp;",'TOTAL BUDGET: ' + this.$options.filters.toMoney(this.computeExpenses(data.data[0])),
                                "NOTE: Technical Specifications for each Item/Project beign proposed shall be submitted as part of the PPMP", "&nbsp;",
                                "Prepared By: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Submitted By:  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Noted By:",
                                "End-user &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (name)  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (name)",
                                "(position) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (position) &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (position)",
                                ""],
            this.excel_title = ["title","title2"]
          ));

          this.$Progress.finish();
        },

        async loadPermission(mid) {

            const response = await axios.get("api/permission/" + this.$gate.showMyID() + "/" + mid).then(
            res => {
              if (!res.data || res.data.length == 0) {
                return false;
              } else {
                return true;
              }
            })

            const getResponse = async () => {
              const a = await response;

              switch (mid) {
              case 50:
                this.can_access = a;
                if (!this.can_access) {
                  this.$router.push({ name: 'not-found' }) //redirect to not found
                }
                break;
              case 51:
                this.can_add = a;
                this.can_edit = a;
                break;
              case 52:
                this.can_delete = a;
              }
            };
            return getResponse()
        },

        loadProcItems() {
          axios.get('api/procurementitems/all').then(({ data }) => (this.procurementitems = data));
        },

        actionExcel() {
          //
        },

        actionPrint() {
          //
        },

        actionDelete() {
          swal.fire({
          title: 'Are you sure?',
          text: "Deleting will permanently remove the item and all its respective data. Do you really want to delete PPMP CY " + this.year + "?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete PPMP!'
          }).then((result)=>{
            if (result.value) {
              this.ppmp_form.delete('api/ppmps/delete/'+ this.year + '/' + this.officeid).then(()=>{
                swal.fire(
                  'Deleted!',
                  'PPMP has been deleted.',
                  'success'
                );
                Trigger.$emit('RefreshData');
              }).catch(()=>{
                swal.fire(
                  'Failed!',
                  'There was something wrong',
                  'warning'
                )
              });
            }
          })
        },

        addNewPpmp() {
        //   this.formPpmpyr.reset();
        //   this.formPpmpyr.clear();
          this.formPpmpyr.year = parseInt(this.ppmpdetails.year) + 1
          $('#addppmpmodal').modal('show');
        },

        editPpmp(pid, fid) {
          this.formPpmpyr.id = pid
          this.formPpmpyr.fundsource_id = fid
          $('#editppmpmodal').modal('show');
        },

        addppmpYear() {
            this.$Progress.start();
            this.formPpmpyr.post('api/ppmps')
              .then(()=>{
                Trigger.$emit('RefreshData');
                $('#addppmpmodal').modal('hide')
                toastr.fire({
                  icon: 'success',
                  title: 'PPMP year created successfully'
                })
                this.$Progress.finish();
              }).catch(()=>{
                toastr.fire({
                  icon: 'error',
                  title: 'PPMP year creation failed'
                });
                this.$Progress.fail();
              })
        },

        updateppmpYear() {
            this.$Progress.start();
            this.formPpmpyr.put('api/ppmps/' + this.formPpmpyr.id)
              .then(()=>{
                Trigger.$emit('RefreshData');
                $('#editppmpmodal').modal('hide')
                toastr.fire({
                  icon: 'success',
                  title: 'PPMP year updated successfully'
                })
                this.$Progress.finish();
              }).catch(()=>{
                toastr.fire({
                  icon: 'error',
                  title: 'PPMP year updating failed'
                });
                this.$Progress.fail();
              })
        },

        actionAdd() {
          const inputStep = 1;
          const inpulValue = parseInt(this.ppmpdetails.year) + 1;
          swal.fire({
            title: 'Create PPMP for Calendar Year:',
            html: `<input
                type="number"
                value="${inpulValue}"
                step="${inputStep}"
                class="swal2-input"
                id="sweettxt">`,
            inputAttributes: {
              autocapitalize: 'off',
              min: 2000,
              max: 3000,
              step: inputStep,
            },
            showCancelButton: true,
            confirmButtonText: 'Save',
            showLoaderOnConfirm: true,
            preConfirm: () => {

              this.ppmp_form.year = document.getElementById("sweettxt").value;

              if(!this.ppmpyears.some(data => data.year == document.getElementById("sweettxt").value))
              {
                  //save
                  this.$Progress.start();
                  this.ppmp_form.post('api/ppmps/' + document.getElementById("sweettxt").value)
                  .then(()=>{
                        //this.ppmpyears.push({"year":document.getElementById("sweettxt").value})
                        this.loadPpmpitemsYears();
                        this.year = document.getElementById("sweettxt").value;
                        this.loadPpmpitems(document.getElementById("sweettxt").value);
                        toastr.fire({
                          icon: 'success',
                          title: 'PPMP created successfully'
                        })
                        this.$Progress.finish();
                  }).catch(()=>{
                    toastr.fire({
                      icon: 'success',
                      title: 'PPMP created successfully'
                    })
                    this.$Progress.finish();
                  });
                  return "ok";
              }else{
                  swal.showValidationMessage(
                    `Request failed: PPMP for the given year already exist.`
                  )
              }
            },
            allowOutsideClick: () => !swal.isLoading()
          }).then((result) => {
            if (result.isConfirmed) {
              swal.fire({
                title: `${result.value.txt}'s avatar`,
                imageUrl: result.value.avatar_url
              })
            }
          })
        },

        deletePpmpItem(id) {
          swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, remove item from PPMP!'
          }).then((result)=>{
            if (result.value) {
              this.form.delete('api/ppmpitems/'+id).then(()=>{
                swal.fire(
                  'Deleted!',
                  'PPMP Item has been deleted.',
                  'success'
                );
                Trigger.$emit('RefreshItems');
              }).catch(()=>{
                swal.fire(
                  'Failed!',
                  'There was something wrong',
                  'warning'
                )
              });
            }
          })
        },

        actionAddItem() {
          this.selected_items_form.selected_items= [];
          $('#actionadditemmodal').modal('show');
        },

        addToFormSelectedItems() {

          this.$Progress.start();
            this.selected_items_form.post('api/ppmpitems')
            .then(()=>{
              Trigger.$emit('RefreshItems');
              $('#actionadditemmodal').modal('hide')
              toastr.fire({
                icon: 'success',
                title: 'Procurement items added'
              })
              this.$Progress.finish();
            }).catch(()=>{
              toastr.fire({
                icon: 'error',
                title: 'Procurement items adding failed'
              });
              this.$Progress.fail();
            })
        }

      },

      created() {
        Trigger.$on('searching',() => {
          let query = this.$parent.search;
          let emptyflag = false;

          if(query == ''){
            Trigger.$emit('RefreshData');
          }else{
            this.$Progress.start();
            axios.get("api/findPpmpitems?q=" + query)
            .then((data) => {
              this.ppmpitems = data.ppmpitems;

              if (data.data.total == 0){
                this.emptyflag = true;
              }

              this.$Progress.finish();
            })
            .catch(() => {
              this.$Progress.fail();
            })
          }

        });

        this.loadProcmodes();
        this.loadProcItems();
        this.loadOffice();
        this.loadPermission(50); //Can Access PPMP
        this.loadPermission(51); //Can Add/Revise PPMP
        this.loadPermission(52); //Can Delete PPMP



        Trigger.$on('RefreshData',() => {
          this.loadOffice();
        });

        Trigger.$on('RefreshItems',() => {
          this.loadPpmpitems(this.year);
        });
      },

  }
</script>
<style scoped>
div >>> p {
font-size: 15px;
}

div >>> strong {
font-size: 15px;
}

div >>> em {
font-size: 15px;
}

div.header >>> p {
line-height: 0.5;
font-size: 20px;
font-family: "Helvetica Narrow","Arial Narrow";
}

div.header >>> strong {
line-height: 0.5;
font-size: 20px;
font-family: "Helvetica Narrow","Arial Narrow";
}

div.header >>> em {
line-height: 0.5;
font-size: 20px;
font-family: "Helvetica Narrow","Arial Narrow";
}

.text-selection-none{
 user-select: none; /* supported by Chrome and Opera */
 -webkit-user-select: none; /* Safari */
 -khtml-user-select: none; /* Konqueror HTML */
 -moz-user-select: none; /* Firefox */
 -ms-user-select: none;
}

.responsive-table tbody td:last-of-type {
    text-align: center;
}

.card-body.p-0 .table tbody>tr>td:first-of-type, .card-body.p-0 .table tbody>tr>th:first-of-type, .card-body.p-0 .table thead>tr>td:first-of-type, .card-body.p-0 .table thead>tr>th:first-of-type {
    padding-left: 0.5rem;
}

@page {
  size: A4;
  margin: 17mm 17mm 17mm 17mm;
}

@media print {
    .page-number:before {
        content: counter(page);
    }
}

</style>
