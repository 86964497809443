<template>
  <div class="col-md-6">
    <!-- USERS LIST -->
    <div class="card">
      <div class="card-header">
        <h3 class="card-title">TAPI Birthday Celebrants of the Month</h3>

        <div class="card-tools">
          <span class="badge badge-priamry">{{ birthdays.length }} Employees</span>
          <button type="button" aria-label="collapse" class="btn btn-tool" data-card-widget="collapse">
            <i class="fas fa-minus"></i>
          </button>
          <!--
          <button type="button" class="btn btn-tool" data-card-widget="remove">
            <i class="fas fa-times"></i>
          </button>
          -->
        </div>
      </div>
      <!-- /.card-header -->
      <div class="card-body p-0">
        <ul class="users-list clearfix">
          <li  v-for="celebrant in birthdays"  v-bind:key="celebrant.id">
            <img height="66px" width="66px" alt="Avatar" :src="'img/profile/' + celebrant.photo">
            <a class="users-list-name" href="#">{{ celebrant.fname }} {{ celebrant.lname }}</a>
            <p class="users-list-date text-secondary">{{ celebrant.dob | birthdayformat }}</p>
          </li>
          <!--
          <li>
            <img src="dist/img/user8-128x128.jpg" alt="User Image">
            <a class="users-list-name" href="#">Norman</a>
            <span class="users-list-date">Yesterday</span>
          </li>
          <li>
            <img src="dist/img/user3-128x128.jpg" alt="User Image">
            <a class="users-list-name" href="#">Nadia</a>
            <span class="users-list-date">15 Jan</span>
          </li>
          -->
        </ul>
        <!-- /.users-list -->
      </div>
      <!-- /.card-body -->
      <!--<div class="card-footer text-center">
        <a href="javascript:">View All Users</a>
      </div>-->
      <!-- /.card-footer -->
    </div>
    <!--/.card -->
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment';

export default {
  name: 'UserBirthdayMonth',

  data() {
    return {
      birthdays : {},
    }
  },

  mounted() {
    const today = new Date();
    const curr_month = moment(String(today.getMonth() + 1)).format('MM');

    axios.get('api/users/birthdays/' + curr_month).then(({ data }) => (this.birthdays = data));
  }

}
</script>

<style>

</style>