<template>
    <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card card-secondary card-outline">
              <div class="card-header">
                <h3 class="card-title"><i class="fas fa-truck"></i> Suppliers</h3>

                <div class="card-tools">
                  <div>
                    <button class="btn btn-success btn-sm" @click="newModal"><i class="fa fa-plus"></i></button>
                  </div>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0 m-0">
                <table class="table responsive-table table-sm table-striped">
                  <thead>
                    <tr>
                      <th scope="col" class="pl-2">Name of Supplier</th>
                      <th scope="col">Contact Details</th>
                      <th scope="col">Business</th>
                      <th scope="col">Products</th>
                      <th scope="col">Status</th>
                      <th scope="col">Remarks</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="supplier in procsuppliers.data" :key="supplier.id">
                      <td scope="row" class="pl-2">{{ supplier.name }}</td>
                      <td data-title="Contact Details"><small>{{ supplier.address }}<br/>{{ supplier.tel }}<br/>{{ supplier.email }}</small></td>
                      <td data-title="Business">{{ supplier.bustypes }}</td>
                      <td data-title="Products"><small>{{ supplier.products }}</small></td>
                      <td data-title="Status">
                        <span v-if="(supplier.status == 1)" class="badge badge-success">Listed</span>
                        <span v-else-if="(supplier.status == 2)" class="badge badge-warning">Conditional</span>
                        <span v-else-if="(supplier.status == 3)" class="badge badge-danger">Delisted</span>
                        <span v-else class="badge badge-secondary">Closed</span>
                      </td>
                      <td data-title="Remarks">{{ supplier.remarks }}</td>

                      <td data-title="Options">
                        <div class="btn-group">
                          <button type="button" class="btn btn-default" @click="editModal(supplier)"><i class="fas fa-edit text-primary"></i></button>
                          <button type="button" class="btn btn-default" @click="actionDelete(supplier.id)"><i class="fas fa-times text-danger"></i></button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-show="procsuppliers.total==0">
                      <tr><td colspan="10">No suppliers found.</td></tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                <pagination :data="procsuppliers" @pagination-change-page="getResults" show-disabled :limit="2"></pagination>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="actionmodal" tabindex="-1" role="dialog" aria-labelledby="actionModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="actionModalLabel" v-if="editmode">Edit Supplier</h5>
                <h5 class="modal-title" id="actionModalLabel" v-else>Add New Supplier</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div class="modal-body">
                <div class="row">

                  <div class="col-sm-3">
                    <div class="form-group">
                    <label>Name</label>
                    <input type="text" class="form-control" v-model="form.name" :class="{ 'is-invalid': form.errors.has('name') }">
                    <has-error :form="form" field="name"></has-error>
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <div class="form-group">
                    <label>Alias</label>
                    <input type="text" class="form-control" v-model="form.alias" :class="{ 'is-invalid': form.errors.has('alias') }">
                    <has-error :form="form" field="alias"></has-error>
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <div class="form-group">
                    <label>Address</label>
                    <input type="text" class="form-control" v-model="form.address" :class="{ 'is-invalid': form.errors.has('address') }">
                    <has-error :form="form" field="address"></has-error>
                    </div>
                  </div>
                  <!-- <div class="col-sm-3">
                    <div class="form-group">
                    <label>Municipal</label>
                    <input type="text" class="form-control" v-model="form.mun_id" :class="{ 'is-invalid': form.errors.has('mun_id') }">
                    <has-error :form="form" field="mun_id"></has-error>
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <div class="form-group">
                    <label>Country</label>
                    <input type="text" class="form-control" v-model="form.cnt_id" :class="{ 'is-invalid': form.errors.has('cnt_id') }">
                    <has-error :form="form" field="cnt_id"></has-error>
                    </div>
                  </div> -->
                  <div class="col-sm-3">
                    <div class="form-group">
                    <label>Zip Code</label>
                    <input type="text" class="form-control" v-model="form.zipcode" :class="{ 'is-invalid': form.errors.has('zipcode') }">
                    <has-error :form="form" field="zipcode"></has-error>
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <div class="form-group">
                    <label>Tel No.</label>
                    <input type="text" class="form-control" v-model="form.tel" :class="{ 'is-invalid': form.errors.has('tel') }">
                    <has-error :form="form" field="tel"></has-error>
                    </div>
                  </div>

                  <div class="col-sm-3">
                    <div class="form-group">
                    <label>FAX</label>
                    <input type="text" class="form-control" v-model="form.fax" :class="{ 'is-invalid': form.errors.has('fax') }">
                    <has-error :form="form" field="fax"></has-error>
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <div class="form-group">
                    <label>Cel No.</label>
                    <input type="text" class="form-control" v-model="form.cel" :class="{ 'is-invalid': form.errors.has('cel') }">
                    <has-error :form="form" field="cel"></has-error>
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <div class="form-group">
                    <label>Website</label>
                    <input type="text" class="form-control" v-model="form.website" :class="{ 'is-invalid': form.errors.has('website') }">
                    <has-error :form="form" field="website"></has-error>
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <div class="form-group">
                    <label>Social Media</label>
                    <input type="text" class="form-control" v-model="form.sclmedia" :class="{ 'is-invalid': form.errors.has('sclmedia') }">
                    <has-error :form="form" field="sclmedia"></has-error>
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <div class="form-group">
                    <label>Email</label>
                    <input type="text" class="form-control" v-model="form.email" :class="{ 'is-invalid': form.errors.has('email') }">
                    <has-error :form="form" field="email"></has-error>
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <div class="form-group">
                    <label>Date Established</label>
                    <input type="date" id="prdate-date-input" class="form-control" v-model="form.date_est" :class="{ 'is-invalid': form.errors.has('date_est') }">
                    <has-error :form="form" field="date_est"></has-error>
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <div class="form-group">
                    <label>Org Form</label>
                    <select class="form-control" v-model="form.org_form" :class="{ 'is-invalid': form.errors.has('org_form') }">
                        <option value=""> - Select Org Form - </option>
                        <option value="1">Org1</option>
                        <option value="2">Org2</option>
                        <option value="3">Org3</option>
                        <option value="4">Org4</option>
                        <option value="5">Org5</option>
                    </select>
                    <has-error :form="form" field="org_form"></has-error>
                    </div>
                  </div>

                  <div class="col-sm-3">
                    <div class="form-group">
                    <label>Business Types</label>
                    <input type="text" class="form-control" v-model="form.bustypes" :class="{ 'is-invalid': form.errors.has('bustypes') }">
                    <has-error :form="form" field="bustypes"></has-error>
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <div class="form-group">
                    <label>Products</label>
                    <input type="text" class="form-control" v-model="form.products" :class="{ 'is-invalid': form.errors.has('products') }">
                    <has-error :form="form" field="products"></has-error>
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <div class="form-group">
                    <label>Number of Vehicles</label>
                    <input type="text" class="form-control" v-model="form.num_vehicles" :class="{ 'is-invalid': form.errors.has('num_vehicles') }">
                    <has-error :form="form" field="num_vehicles"></has-error>
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <div class="form-group">
                    <label>TIN</label>
                    <input type="text" class="form-control" v-model="form.tin_vat" :class="{ 'is-invalid': form.errors.has('tin_vat') }">
                    <has-error :form="form" field="tin_vat"></has-error>
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <div class="form-group">
                    <label>VAT?</label>
                    <input type="radio" id="yes" value="1" v-model="form.is_vat" /><label class="text-success ml-1" for="yes">Yes</label>
                    <input class="ml-3" type="radio" id="no" value="0" v-model="form.is_vat" /><label class="text-danger ml-1" for="no">No</label>
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <div class="form-group">
                    <label>Credit Terms</label>
                    <input type="text" class="form-control" v-model="form.credit_terms" :class="{ 'is-invalid': form.errors.has('credit_terms') }">
                    <has-error :form="form" field="credit_terms"></has-error>
                    </div>
                  </div>

                  <div class="col-sm-3">
                    <div class="form-group">
                    <label>Bank Acct. No</label>
                    <input type="text" class="form-control" v-model="form.acct_no" :class="{ 'is-invalid': form.errors.has('acct_no') }">
                    <has-error :form="form" field="acct_no"></has-error>
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <div class="form-group">
                    <label>Bank Acct Name</label>
                    <input type="text" class="form-control" v-model="form.acct_name" :class="{ 'is-invalid': form.errors.has('acct_name') }">
                    <has-error :form="form" field="acct_name"></has-error>
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <div class="form-group">
                    <label>Bank Name</label>
                    <input type="text" class="form-control" v-model="form.bank_name" :class="{ 'is-invalid': form.errors.has('bank_name') }">
                    <has-error :form="form" field="bank_name"></has-error>
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <div class="form-group">
                    <label>Bank Branch</label>
                    <input type="text" class="form-control" v-model="form.bank_branch" :class="{ 'is-invalid': form.errors.has('bank_branch') }">
                    <has-error :form="form" field="bank_branch"></has-error>
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <div class="form-group">
                    <label>Philgeps No</label>
                    <input type="text" class="form-control" v-model="form.philgeps_no" :class="{ 'is-invalid': form.errors.has('philgeps_no') }">
                    <has-error :form="form" field="philgeps_no"></has-error>
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <div class="form-group">
                    <label>Status</label>
                    <select class="form-control" v-model="form.status" :class="{ 'is-invalid': form.errors.has('status') }">
                        <option value=""> - Select Status - </option>
                        <option value="1">Listed</option>
                        <option value="2">Conditional</option>
                        <option value="3">Delisted</option>
                    </select>
                    <has-error :form="form" field="status"></has-error>
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <div class="form-group">
                    <label>Remarks</label>
                    <input type="text" class="form-control" v-model="form.remarks" :class="{ 'is-invalid': form.errors.has('remarks') }">
                    <has-error :form="form" field="remarks"></has-error>
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <div class="form-group">
                    <label>Is Bidder?</label>
                    <input type="radio" id="yes" value="1" v-model="form.is_bidder" /><label class="text-success ml-1" for="yes">Yes</label>
                    <input class="ml-3" type="radio" id="no" value="0" v-model="form.is_bidder" /><label class="text-danger ml-1" for="no">No</label>
                    </div>
                  </div>

                </div>
              </div>
              <div class="card-footer">
                <button v-if="editmode" class="btn btn-primary" data-container="body" type="button" @click="actionUpdate()"><i class="fa fa-save"></i> Update</button>
                <button v-else class="btn btn-primary" data-container="body" type="button" @click="actionSave()"><i class="fa fa-save"></i> Save</button>
                <button class="btn btn-primary" data-container="body" type="button" data-dismiss="modal" aria-label="Close"><i class="fa fa-times"></i> Close</button>
              </div>
            </div>
          </div>
        </div>




      </div>

</template>

<script>

    import Form from 'vform';
    import { VueEditor } from "vue2-editor";
    import moment from 'moment';

    export default {
        name: 'Procsuppliers',

        components: {
            VueEditor,
        },

        data() {
          return {
            timer: 30,
            editmode: false,
            loading: false,
            emptyflag: false,
            can_access: false,
            can_add: false,
            can_edit: false,
            can_delete: false,
            procsuppliers : {},
            form: new Form({
                id: null,
                alias: '',
                name: '',
                address: '',
                mun_id: null,
                cnt_id: null,
                zipcode: '',
                tel: '',
                fax: '',
                cel: '',
                website: '',
                sclmedia: '',
                email: '',
                date_est: '',
                org_form: null,
                bus_id: null,
                bustypes: '',
                products: '',
                num_vehicles: null,
                tin_vat: '',
                is_vat: null,
                credit_terms: null,
                acct_no: '',
                acct_name: '',
                bank_name: '',
                bank_branch: '',
                philgeps_no: '',
                status: null,
                remarks: '',
                is_bidder: null,
            }),
          }
        },

        props: [
          'todo'
        ],

        computed: {

          second() {
            return this.time / 1000;
          },

          isIdle() {
            const idlestat =  this.$store.state.idleVue.isIdle;
            if (idlestat == true) {
              $('#sessionmodal').modal('show');
              this.countDownTimer();
            } else {
              $('#sessionmodal').modal('hide');
            }
            return idlestat;
          },

        },

        methods: {

          countDownTimer() {
            let timerId = setInterval(() => {
              this.timer -= 1;
              if (!this.$store.state.idleVue.isIdle) clearInterval(timerId);

              if (this.timer < 1) {
                clearInterval(timerId);
                document.getElementById('logout-form').submit();
              }
            }, 1000);

            this.timer = 30;
          },


          newModal() {
            this.editmode = false;
            this.form.reset();
            this.form.clear();
            $('#actionmodal').modal('show');
          },

          editModal(payload) {
            this.editmode = true;
            this.form.reset();
            this.form.clear();
            $('#actionmodal').modal('show');
            this.form.fill(payload);
          },

          actionDelete(id) {
            swal.fire({
              title: 'Are you sure?',
              text: "You won't be able to revert this!",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes, delete supplier!'
              }).then((result)=>{
                if (result.value) {
                  this.form.delete('api/suppliers/'+id).then(()=>{
                    swal.fire(
                      'Deleted!',
                      'Supplier has been deleted.',
                      'success'
                    );
                    Trigger.$emit('RefreshData');
                  }).catch(()=>{
                    swal.fire(
                      'Failed!',
                      'There was something wrong',
                      'warning'
                    )
                  });
                }
            })
          },

          actionSave() {
            this.$Progress.start();
            this.form.post('api/suppliers').then(()=>{
              Trigger.$emit('RefreshData');
              $('#actionmodal').modal('hide')
              toastr.fire({
                icon: 'success',
                title: 'Supplier Saved'
              })
              this.$Progress.finish();
            }).catch(()=>{
              toastr.fire({
                icon: 'error',
                title: 'Supplier saving failed'
              });
              this.$Progress.fail();
            });
          },

          actionUpdate() {
            this.$Progress.start();
            this.form.put('api/suppliers/'+this.form.id).then(()=>{
              Trigger.$emit('RefreshData');
              $('#actionmodal').modal('hide')
              toastr.fire({
                icon: 'success',
                title: 'Supplier Updated'
              })
              this.$Progress.finish();
            }).catch(()=>{
              toastr.fire({
                icon: 'error',
                title: 'Supplier updating failed'
              });
              this.$Progress.fail();
            });
          },


          getResults(page = 1) {

            this.$Progress.start();

            let query = this.$parent.search;

            if(query == ''){
              axios.get('api/procsuppliers?page=' + page).then(response => { this.procsuppliers = response.data; });
            }else{
              axios.get('api/findProcsupplier?q=' + query + '&page=' + page).then(response => { this.procsuppliers = response.data; });
            }

            this.$Progress.finish();

            //console.log(query);


          },

          loadProcsuppliers() {
            this.$Progress.start();

            let query = this.$parent.search;
            let page = this.procsuppliers.current_page;

            if(query == ''){
              axios.get('api/procsuppliers?page=' + page).then(({ data }) => (this.procsuppliers = data));
            }else{
              axios.get('api/findProcsupplier?q=' + query + '&page=' + page).then(({ data }) => (this.procsuppliers = data));
            }

            this.$Progress.finish();
          },

          async loadPermission(mid) {
              const response = await axios.get("api/permission/" + this.$gate.showMyID() + "/" + mid).then(
              res => {
                if (!res.data || res.data.length == 0) {
                  return false;
                } else {
                  return true;
                }
              })

              const getResponse = async () => {
                const a = await response;

                switch (mid) {
                case 58:
                  this.can_access = a;
                  if (!this.can_access) {
                    this.$router.push({ name: 'not-found' }) //redirect to not found
                  }
                  break;
                  /*
                case 50:
                  this.can_edit = a;
                  break;
                case 51:
                  this.can_delete = a;
                  */
                }
              };
              return getResponse()
          },


        },

        created() {
          Trigger.$on('searching',() => {
            let query = this.$parent.search;
            let emptyflag = false;

            if(query == ''){
              Trigger.$emit('RefreshData');
            }else{
              this.$Progress.start();
              axios.get("api/findProcsupplier?q=" + query)
              .then((data) => {
                this.procsuppliers = data.data;

                if (data.data.total == 0){
                  this.emptyflag = true;
                }

                this.$Progress.finish();
              })
              .catch(() => {
                this.$Progress.fail();
              })
            }

          });

          this.loadProcsuppliers();
          this.loadPermission(58); //Can Manage Suppliers

          Trigger.$on('RefreshData',() => {
            this.loadProcsuppliers();
          });
        },

    }
</script>
<style scoped>
div >>> p {
  font-size: 15px;
}

div >>> strong {
  font-size: 15px;
}

div >>> em {
  font-size: 15px;
}

div.header >>> p {
  line-height: 0.5;
  font-size: 20px;
  font-family: "Helvetica Narrow","Arial Narrow";
}

div.header >>> strong {
  line-height: 0.5;
  font-size: 20px;
  font-family: "Helvetica Narrow","Arial Narrow";
}

div.header >>> em {
  line-height: 0.5;
  font-size: 20px;
  font-family: "Helvetica Narrow","Arial Narrow";
}

.text-selection-none{
   user-select: none; /* supported by Chrome and Opera */
   -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
   -moz-user-select: none; /* Firefox */
   -ms-user-select: none;
}
div.tblProject table {
  width: 100% !important;
}

div.tblProject table td, div.tblProject table th {
  border: 1px solid #6C757D !important;
  border-collapse: collapse !important;
  padding: 5px !important;
}

div.tblProject >>> table {
  width: 100% !important;
}

div.tblProject >>> table td, div.tblProject >>> table th {
  border: 1px solid #6C757D !important;
  border-collapse: collapse !important;
  padding: 5px !important;
}

div.tblProject >>> br {
  display: block; /* makes it have a width */
  content: ""; /* clears default height */
  margin-top: 15px; /* change this to whatever height you want it */
}
</style>
