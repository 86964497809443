<template>
    <div class="container-fluid">

        <div class="row">
 
          <div class="col-md-4">
            <div class="form-group">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text bg-info">PROGRAMS</span>
                </div>
                <select @change="filterTargetData" v-model="program_id" name="program_id" class="form-control" autocomplete="off" placeholder="SELECT PROGRAM">
                  <option selected value="">All Programs</option>
                  <option v-for="program in programs" :value="program.id" :key="program.id">{{ program.program_name }}</option>
                </select>
              </div>
            </div>
          </div>
            
          <div class="col-md-4">
            <div class="form-group">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text bg-info">MONTH</span>
                </div>
                <select @change="filterTargetData" v-model="month" name="month" class="form-control" autocomplete="off" placeholder="SELECT MONTH">
                    <option selected value="">All Months</option>
                    <option value="1">January</option>
                    <option value="2">February</option>
                    <option value="3">March</option>
                    <option value="4">April</option>
                    <option value="5">May</option>
                    <option value="6">June</option>
                    <option value="7">July</option>
                    <option value="8">August</option>
                    <option value="9">September</option>
                    <option value="10">October</option>
                    <option value="11">November</option>
                    <option value="12">December</option>
                </select>
              </div>
            </div>
          </div>

        </div>

        <div class="row">

          <div class="col-md-4">
            <div class="form-group">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text bg-info">STATUS</span>
                </div>
                <select @change="filterTargetData" v-model="status" name="status" class="form-control" autocomplete="off" placeholder="SELECT STATUS">
                  <option selected value="5">All Status</option>
                  <option value="0">Pending</option>
                    <option value="3">Submitted</option>
                    <option value="2">Revised</option>
                    <option value="4">Reviewed</option>
                    <option value="1">Approved</option>
                </select>
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text bg-info">YEAR</span>
                </div>
                <select @change="filterTargetData" v-model="year" name="year" class="form-control" placeholder="SELECT YEAR">
                  <option selected disabled value="">SELECT YEAR</option>
                  <option v-for="year in years" :value="year" :key="year">{{ year }}</option>
                </select>
              </div>
            </div>
          </div>

        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="card card-secondary card-outline">
              <div class="card-header">
                <h3 class="card-title"><i class="fas fa-bullseye"></i> Target Data</h3>
                <div class="card-tools">
                  <div class="form-group clearfix"> 
                    <button @click="resetFilter" data-toggle="tooltip" title="Reset Filter" class="btn btn-primary btn-sm">
                    <i class="fa fa-sync"></i>
                    </button>
                    <button @click="newTargetModal" data-toggle="tooltip" title="Add New" class="btn btn-success btn-sm">
                    <i class="fa fa-plus"></i>
                    </button>
                  </div>
                </div>
              </div>
              <!-- /.card-header -->

              <div class="card-body table-responsive p-0">
                <table class="table table-bordered table-hover">
                  <thead>
                    <tr>  
                      <th>Program Name</th>
                      <th>Month</th>
                      <th>Year</th>
                      <th>Status <i>(For Approval)</i></th>
                      <th>Options</th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-for="(target, index) in targets.data">  
                      <tr 
                        class="a-pointer" 
                        :class="{ 'active-class2': active_target === index }"
                        @click="enableSubRow(index)" 
                        :key="index" 
                        data-widget="expandable-table" aria-expanded="false">
                        
                        <td>
                          <i :class="(active_target === index) ? 'fas fa-angle-down' : 'fas fa-angle-right'"></i> 
                          {{ target.programs.program_name }}
                        </td>
                        <td>{{ target.month | monthwordformat }}</td>
                        <td>{{ target.year }}</td>
                        <td>
                          <span v-if="(target.status == '0')" class="badge bg-orange text-light">Pending</span> 
                          <span v-if="(target.status == '1')" class="badge badge-success">Approved</span>
                          <span v-if="(target.status == '2')" class="badge badge-info">Revised</span>
                          <span v-if="(target.status == '3')" class="badge badge-primary">Submitted</span>
                          <span v-if="(target.status == '4')" class="badge badge-secondary">Reviewed</span>
                          {{ target.updated_at | fulldate }}
                        </td>
                        <td>
                          <div class="btn-group btn-group-sm">
                            <button
                              @click="viewStatusHistoryModal(target.id)" 
                              type="button" 
                              class="btn btn bg-gradient-secondary text-light" 
                              data-toggle="tooltip"
                              title="View Status History">
                              <i class="fa fa-eye"></i>
                            </button>
 
                            <button v-show="can_approved_status && (target.status == 4)"
                              @click="editChangeStatusModal(target, 'approved')" 
                              type="button" 
                              class="btn btn bg-gradient-success text-light" 
                              data-toggle="tooltip" 
                              title="Approve">
                              Approve
                            </button> 

                            <!-- <button v-show="(can_approved_status || can_reviewed_status) && (target.status == 1 || target.status ==  3 || target.status == 4)" -->
                            
                            <!-- (can_approved_status && (target.status == 1 || target.status == 4))
                                         || (can_reviewed_status && (target.status == 3 || target.status == 4)) -->
                             
                            <button v-show="(can_approved_status) && (target.status == 1 || target.status == 4)
                                        || (can_reviewed_status && (target.status == 3 || target.status == 4))"
                              @click="editChangeStatusModal(target, 'revised')" 
                              type="button" 
                              class="btn btn bg-gradient-info text-light" 
                              data-toggle="tooltip" 
                              title="Revise">
                              Revise
                            </button>

                            <button v-show="(!can_reviewed_status) && (!can_approved_status) && (target.status == 0 || target.status == 2)
                                         || (can_reviewed_status) && (can_approved_status) && (target.status == 0 || target.status == 2)"
                              @click="editChangeStatusModal(target, 'submitted')" 
                              type="button" 
                              class="btn btn bg-gradient-primary text-light" 
                              :disabled="(target.status == 1)"
                              data-toggle="tooltip" 
                              title="Submit">
                              Submit
                            </button>

                            <button v-show="can_reviewed_status && (target.status == 3)"
                              @click="editChangeStatusModal(target, 'reviewed')" 
                              type="button" 
                              class="btn btn bg-gradient-secondary text-light" 
                              data-toggle="tooltip" 
                              title="Review">
                              Review
                            </button>
                          </div>
                        </td>
                      </tr>

                      <tr 
                        class="expandable-body" 
                        :key="target.index" 
                        :class="(active_target === index) ? '' : 'd-none'">
 
                        <td colspan="6" :class="{ 'active-class2': active_target === index }"> 
                          <div class="col-md-12">
                            <div 
                              :style="(active_target === index) ? '' : 'display: none;'" 
                              class="card card-primary card-outline">

                              <div class="card-header">
                                <h3 class="card-title">
                                  Indicators
                                </h3>

                                <div class="card-tools">
                                  <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                    <i class="fas fa-minus text-secondary"></i>
                                  </button>
                                </div>
                              </div>

                              <div class="card-body p-0" style="display: block;">
                                <ul class="nav nav-pills flex-column">
                                  <!-- <li 
                                    v-for="target_content in target.target_contents" 
                                    :key="target_content.id" 
                                    class="nav-item active">

                                    <a class="nav-link">
                                      <i class="fas fa-arrow-right"></i> 
                                      {{ target_content.indicators.indicator_name }}
                                    </a>
                                  </li> -->

                                  <li 
                                    v-for="indicator in target.programs.all_indicators" 
                                    :key="indicator.id" 
                                    class="nav-item active">

                                    <a @click="viewIndicatorDetailModal(indicator, target.programs.program_name, target.month, target.year)"
                                      class="nav-link a-pointer">
                                      <i class="fas fa-arrow-right"></i> 
                                      {{ indicator.indicator_name }}
                                    </a>
                                  </li>

                                  <!-- <li v-show="total_sub_indicators == 0" class="nav-item active">
                                    <a class="nav-link">
                                      <i class="fas fa-exclamation-circle text-warning"></i> 
                                      No sub indicators found.
                                    </a>
                                  </li> -->
                                </ul>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                  <tbody v-show="targets.total == 0">
                      <tr><td colspan="10">No target data found.</td></tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                <pagination :data="targets" @pagination-change-page="getResults" show-disabled :limit="10"></pagination>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>
 
        <!-- Target Modal -->
        <div class="modal fade" id="targetModal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="targetModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
          <div class="modal-content">
              <div class="modal-header">
                <h5 v-show="!editmode" class="modal-title" id="targetModalLabel">Create New Data</h5>
                <h5 v-show="editmode" class="modal-title" id="targetModalLabel">Update Data</h5> 
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <form @submit.prevent="editmode ? updateData() : createData()" @keydown="form.onKeydown($event)">

              <div v-show="!editmode" class="modal-body">
                <div class="row">
                  <div class="col-md-7">
                    <div class="card card-primary card-outline">
                      <div class="card-body">
 
                        <div class="form-group">
                          <div class="input-group">
                              <div class="input-group-prepend">
                                <span class="input-group-text bg-info">PROGRAM</span>
                              </div>
                              <selectize @input="getIndicators(form.program_id)" v-model="form.program_id" placeholder="SELECT PROGRAM" class="form-control" :class="{ 'is-invalid': form.errors.has('program_id') }">
                                <option selected disabled value="0">SELECT PROGRAM</option>
                                <option v-for="program in modify_programs" :key="program.id" v-bind:value="program.id" >{{ program.program_name }}</option>
                              </selectize>
                              <has-error :form="form" field="program_id"></has-error>
                          </div>
                        </div>
                         
                        <div class="form-group">
                          <div class="input-group">
                              <div class="input-group-prepend">
                                <span class="input-group-text bg-info">MONTH</span>
                              </div>
                              <select @change="getCount" v-model="form.month" name="month" class="form-control" autocomplete="off" placeholder="SELECT MONTH" :class="{ 'is-invalid': form.errors.has('month') }">
                                  <option selected value="">All Months</option>
                                  <option value="1">January</option>
                                  <option value="2">February</option>
                                  <option value="3">March</option>
                                  <option value="4">April</option>
                                  <option value="5">May</option>
                                  <option value="6">June</option>
                                  <option value="7">July</option>
                                  <option value="8">August</option>
                                  <option value="9">September</option>
                                  <option value="10">October</option>
                                  <option value="11">November</option>
                                  <option value="12">December</option>
                              </select>
                              <has-error :form="form" field="month"></has-error>
                          </div>
                        </div>

                        <div class="form-group">
                          <div class="input-group">
                              <div class="input-group-prepend">
                                <span class="input-group-text bg-info">YEAR</span>
                              </div>
                              <select @change="getCount" v-model="form.year" name="year" class="form-control" placeholder="SELECT YEAR" :class="{ 'is-invalid': form.errors.has('year') }">
                                <option selected disabled value="">SELECT YEAR</option>
                                <option v-for="year in years" :value="year" :key="year">{{ year }}</option>
                              </select>
                              <has-error :form="form" field="year"></has-error>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>

                  <div class="col-md-5">
                    <div class="card card-primary card-outline">
                      <div class="card-header">
                        <h3 class="card-title"><i class="fas fa-hand-point-right"></i> Indicators
                        <span class="badge" :class="(indicators.total == 0) ? 'bg-secondary' : 'bg-primary'">{{ indicators.total }}</span>
                        </h3>

                        <div class="card-tools">
                          <button type="button" class="btn btn-tool" data-card-widget="collapse">
                            <i class="fas fa-minus text-secondary"></i>
                          </button>
                        </div>
                      </div>

                      <div class="card-body p-0" style="display: block;">
                        <ul class="nav nav-pills flex-column">
                          <li v-for="(indicator, index) in indicators.data" :key="index" class="nav-item active">
                            <a @click="getSubIndicators(indicator)" class="a-pointer nav-link" :class="{ 'active-class': active_indicator === indicator.id }">
                              <i class="fas fa-arrow-right"></i> {{ indicator.indicator_name }}
                              <span
                                v-show="form.indicator_id == indicator.id && indicator.sub_indicators.length > 0 && form.month != '' && active_indicator_title != ''"
                                class="badge" :class="(count_indicators.data == 0) ? 'bg-secondary' : 'bg-warning'">
                                <!-- {{ count_indicators == '' ? '0' : count_indicators.data }} -->
                                <!-- {{ form.indicator_id == indicator.id ? count_indicators.data : 0 }} -->
                                {{ count_indicators.data }}
                              </span>
                            </a>
                          </li>

                          <li v-show="indicators.total == 0" class="nav-item active">
                            <a class="nav-link">
                              <i class="fas fa-exclamation-circle text-warning"></i> No indicators found.
                            </a>
                          </li>
                        </ul>
                      </div>

                      <!-- /.card-body -->
                    </div>
                    <!-- /.card -->
                  </div>
                </div>

                <hr/>
 
                <div class="container-fluid">
                  <div class="row">

                    <div class="col-md-12">
                      <div class="card card-primary card-outline">
                        <div class="card-header">
                          <h3 class="card-title">
                            <span>
                              <i class="fas fa-arrow-right text-info"></i> 
                              {{ active_indicator_title == '' ? 'Indicator Name' : active_indicator_title }}
                              <br>
                              <!-- with Sub Indicators -->
                              <template v-if="total_sub_indicators > 0">
                                <i class="fas fa-circle text-success"></i> Details
                              </template>
                            </span>
                          </h3>

                          <!-- TODO: Mark3 -->
                          <div v-show="active_indicator_title != ''" class="card-tools">
                            <!-- <template v-for="target_content in target_contents.data"> -->
                              <div v-show="target_status.status == 0 || target_status.status == 2" class="form-group clearfix">
                                <a v-if="total_sub_indicators > 0"
                                  @click="newTargetContentModal" 
                                  data-toggle="modal" 
                                  title="Add New Contents" 
                                  class="a-pointer btn btn-success btn-sm">
                                  <i class="fa fa-plus text-light"></i>
                                </a>
                                
                                <!-- <template v-else-if="target_contents.data == 0"> -->
                                  <a v-else-if="target_contents.data == 0"
                                    @click="newTargetValueModal" 
                                    data-toggle="modal" 
                                    title="Add Value" 
                                    class="a-pointer btn btn-success btn-sm">
                                    <i class="fa fa-plus text-light"></i>
                                  </a>
                                <!-- </template> -->
                              </div>
                            <!-- </template> -->
                          </div>
                        </div> 
 
                        <div class="card-body table-responsive p-0">
                          <table class="table table-striped no-wrap">
                            <thead>
                              <tr>  
                                <template v-if="active_indicator > 0 ">
                                  <th>Year</th>
                                  <th>Month</th>
                                </template>
                                  <th v-if="active_indicator > 0  && total_sub_indicators == 0">Value</th>

                                <th v-for="sub_indicator in sub_indicators.data" :key="sub_indicator.id">
                                  {{ sub_indicator.sub_indicator_name }}
                                </th>

                                <th v-if="active_indicator > 0  && form.month > 0">
                                  Options
                                </th>
                              </tr>
                            </thead>
                            <!-- zzz -->
                            <tbody>
                              <tr v-for="target_content in target_contents.data" :key="target_content.id">
                                <template v-if="target_content.targets != null && target_content.target_sub_contents.length > 0">
                                  <td>
                                      {{ target_content.targets.year }}
                                  </td>
                                  <td>
                                      {{ target_content.targets.month | monthwordformat }}
                                  </td>
                                </template>

                                <!-- && target_content.target_sub_contents.length > 0 && count_indicators.data > 0 -->
                                <template v-if="target_content.targets != null && target_content.target_sub_contents.length > 0 ">
                                  <td v-for="target_sub_content in target_content.target_sub_contents" :key="target_sub_content.id">
                                    {{ (target_sub_content.value == null) ? "-" : target_sub_content.value }}
                                  </td>
 
                                  <td v-if="form.month > 0">
                                    <button 
                                      @click="active_indicator > 0  && total_sub_indicators == 0 ? editTargetValue(target_content.target_sub_contents) : editTargetContent(target_content.target_sub_contents)" 
                                      type="button" 
                                      class="btn btn-outline-secondary btn-sm" 
                                      data-toggle="tooltip" 
                                      data-container="body" 
                                      :disabled="target_content.targets.status == 1 || target_content.targets.status == 3 || target_content.targets.status == 4"
                                      title="Edit">
                                      <i class="fa fa-edit"></i>
                                    </button>

                                    <button 
                                      @click="deleteSubContent(target_content.id)" 
                                      type="button" 
                                      class="btn btn-outline-secondary btn-sm" 
                                      data-toggle="tooltip" 
                                      data-container="body" 
                                      :disabled="target_content.targets.status == 1 || target_content.targets.status == 3 || target_content.targets.status == 4"
                                      title="Delete">
                                      <i class="fa fa-trash"></i>
                                    </button>
                                  </td>
                                </template>
                              </tr>
                            </tbody>
                            <!-- || count_indicators.data == 0 -->
                            <tbody v-if="active_indicator_title == '' || target_contents.data == 0">
                              <tr><td colspan="20">No details found.</td></tr>
                            </tbody>
  
                            <!-- <template v-for="target_content in target_contents.data">
                              <tbody :key="target_content.id">
                                <tr>
                                  <td colspan="10" v-if="target_content.targets != null && target_content.target_sub_contents.length == 0 || target_content.targets == null && target_content.target_sub_contents.length == 0">No sub indicators found.</td>
                                </tr>
                              </tbody>
                            </template> -->
                            
                          </table>
                        </div>

                        <div class="card-footer">
                            <pagination :data="target_contents" @pagination-change-page="getIndicatorDetailResults" show-disabled :limit="10"></pagination>
                        </div>
                      </div>              
                    </div>
 
                    <hr/>

                  </div>
                </div>
 
              </div>
 
              <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal"><i class="fa fa-times-circle"></i> CLOSE</button>
                <!-- <button v-show="editmode" type="submit" class="btn btn-default bg-primary"><i class="fa fa-save"></i> UPDATE</button>
                <button v-show="!editmode" type="submit" class="btn btn-default bg-success" disabled><i class="fa fa-save"></i> CREATE</button> -->
              </div>

            </form>

          </div>
          </div>
        </div>

        <!-- Target Content Modal -->
        <div class="modal" id="targetContentModal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="targetContentModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="targetContentModalLabel">{{ !editTargetContentMode ? "Add New" : "Update" }} Contents</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <form @submit.prevent="editTargetContentMode ? updateTargetContent() : createTargetContent()" @keydown="form.onKeydown($event)">

              <div class="modal-body">
                <div class="row">
                  <div class="col-md-12">
                    <div class="card card-primary card-outline">
                      <div class="card-body">

                        <div v-for="(sub_indicator, index) in sub_indicators.data" :key="index" class="form-group">
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span :class="sub_indicator.sub_indicator_name.length > 10 ? 'input-group-text bg-info text-wrap' : 'input-group-text bg-info'">
                                {{ sub_indicator.sub_indicator_name }}
                              </span>
                            </div>
                            <!-- <input v-if="sub_indicator.sub_indicator_name == 'Amount of Investment'"
                              v-model="form.sub_indicator_values[index]" 
                              @input="computeTotalAmount(form.sub_indicator_values[index])"
                              type="number" 
                              name="sub_indicator_values" 
                              autocomplete="off" 
                              :placeholder="sub_indicator.sub_indicator_name" 
                              class="form-control" :class="{ 'is-invalid': form.errors.has('sub_indicator_values') }"> -->
                            <template v-if="sub_indicator.sub_indicator_name.length > 10">
                                <textarea style="resize:none"
                                v-model="form.sub_indicator_values[index]" 
                                @keypress="(sub_indicator.sub_indicator_name == 'Amount of Investment') ? numbersOnly() : ''"
                                type="text" 
                                name="sub_indicator_values" 
                                :placeholder="sub_indicator.sub_indicator_name" 
                                :disabled="(sub_indicator.is_active == 0)"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('sub_indicator_values') }">
                                </textarea>
                                <has-error :form="form" field="sub_indicator_values"></has-error> 
                            </template>
                            <template v-else>
                                <input 
                                v-model="form.sub_indicator_values[index]" 
                                @keypress="(sub_indicator.sub_indicator_name == 'Amount of Investment') ? numbersOnly() : ''"
                                type="text" 
                                name="sub_indicator_values" 
                                :placeholder="sub_indicator.sub_indicator_name" 
                                :disabled="(sub_indicator.is_active == 0)"
                                class="form-control" :class="{ 'is-invalid': form.errors.has('sub_indicator_values') }">
                                <has-error :form="form" field="sub_indicator_values"></has-error> 
                            </template>
                          </div>
                              <i v-show="sub_indicator.is_active == 0 && sub_indicator.sub_indicator_name == 'Amount of Investment'">
                                * Enable "Amount of Investment" from Sub Indicators to update this field.
                              </i>
                        </div>
 
                        <!-- <div v-show="allow_attachment === 1" class="form-group">
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text bg-info">Attachment</span>
                            </div>
                            <input type="file" name="indicator_name" class="form-control" :class="{ 'is-invalid': form.errors.has('indicator_name') }">
                            <has-error :form="form" field="indicator_name"></has-error>
                          </div>
                        </div> -->

                      </div>
                    </div>
                  </div>
                </div>
                
              </div>

              <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal"><i class="fa fa-times-circle"></i> CLOSE</button>
                <button v-show="editTargetContentMode" type="submit" class="btn btn-primary"><i class="fa fa-save"></i> UPDATE</button>
                <button v-show="!editTargetContentMode" type="submit" class="btn btn-success"><i class="fa fa-save"></i> CREATE</button>
              </div>

              </form>

            </div>
          </div>
        </div>

        <!-- Target Value Modal -->
        <div class="modal" id="targetValueModal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="targetValueModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-md" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="targetValueModalLabel">{{ !editTargetValueMode ? "Add New" : "Update" }} Content</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <form @submit.prevent="editTargetValueMode ? updateTargetValue() : createTargetValue()" @keydown="form.onKeydown($event)">

              <div class="modal-body">
                <div class="row">
                  <div class="col-md-12">
                    <div class="card card-primary card-outline">
                      <div class="card-body">

                        <div class="form-group">
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text bg-info">Detail Value</span>
                            </div>
                            <input 
                              v-model="form.sub_indicator_value" 
                              @keypress="numbersOnly()"
                              type="text" 
                              name="sub_indicator_values" 
                              autocomplete="off" 
                              placeholder="Enter Value" 
                              class="form-control" :class="{ 'is-invalid': form.errors.has('sub_indicator_values') }">
                            <!-- <has-error :form="form" field="sub_indicator_values"></has-error> -->
                          </div>
                        </div>
 
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal"><i class="fa fa-times-circle"></i> CLOSE</button>
                <button v-show="editTargetValueMode" type="submit" class="btn btn-primary"><i class="fa fa-save"></i> UPDATE</button>
                <button v-show="!editTargetValueMode" type="submit" class="btn btn-success"><i class="fa fa-save"></i> CREATE</button>
              </div>

              </form>

            </div>
          </div>
        </div>

        <!-- Change Status Modal -->
        <div class="modal" id="changeStatusModal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="changeStatusModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-md" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="changeStatusModalLabel">Update Status</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <form @submit.prevent="updateChangeStatusModal()" @keydown="form_status.onKeydown($event)">

              <div class="modal-body">
                <div class="row">
                  <div class="col-md-12">
                    <div class="card card-primary card-outline">
                      <div class="card-body">
                        
                        <div class="form-group">
                          <div class="alert alert-primary alert-dismissible">
                            <h4>Alert!</h4>
                            <i class="fas fa-info-circle"></i>
                            Are you sure you want to 
                            <b v-if="(form_status.status == 'pending')"><span class="badge bg-orange text-light">Pending</span></b> 
                            <b v-if="(form_status.status == 'approved')"><span class="badge badge-success">Approve</span></b> 
                            <b v-if="(form_status.status == 'revised')"><span class="badge badge-info">Revise</span></b> 
                            <b v-if="(form_status.status == 'submitted')"><span class="badge badge-primary">Submit</span></b> 
                            <b v-if="(form_status.status == 'reviewed')"><span class="badge badge-secondary">Review</span></b> 
                            this?
                          </div>
                        </div>

                        <div class="form-group">
                          <div class="card">
                            <div class="card-header">
                              <h3 class="card-title">
                              <i class="fas fa-align-justify"></i> Details</h3>
                            </div>

                            <div class="card-body">
                              <dl class="row">
                                <dt class="col-sm-4">Program Name</dt>
                                <dd class="col-sm-8">{{ form_status.program_name }}</dd>

                                <dt class="col-sm-4">Month</dt>
                                <dd class="col-sm-8">{{ form_status.month | monthwordformat }}</dd>
                                
                                <dt class="col-sm-4">Year</dt>
                                <dd class="col-sm-8">{{ form_status.year }}</dd>

                                <dt class="col-sm-4">Status</dt>
                                <dd class="capitalize col-sm-8">
                                  <!-- {{ form_status.current_status }}  -->
                                  <span v-if="(form_status.current_status == 'pending')" class="badge bg-orange text-light">{{ form_status.current_status }}</span>
                                  <span v-if="(form_status.current_status == 'approved')" class="badge badge-success">{{ form_status.current_status }}</span>
                                  <span v-if="(form_status.current_status == 'revised')" class="badge badge-info">{{ form_status.current_status }}</span>
                                  <span v-if="(form_status.current_status == 'submitted')" class="badge badge-primary">{{ form_status.current_status }}</span>
                                  <span v-if="(form_status.current_status == 'reviewed')" class="badge badge-secondary">{{ form_status.current_status }}</span>
                                  <i class="fas fa-arrow-right"></i> 
                                  <!-- <b>{{ form_status.status }}</b> -->
                                  <span v-if="(form_status.status == 'pending')" class="badge bg-orange text-light">Pending</span>
                                  <span v-if="(form_status.status == 'approved')" class="badge badge-success">Approve</span>
                                  <span v-if="(form_status.status == 'revised')" class="badge badge-info">Revise</span>
                                  <span v-if="(form_status.status == 'submitted')" class="badge badge-primary">Submit</span>
                                  <span v-if="(form_status.status == 'reviewed')" class="badge badge-secondary">Review</span>
                                </dd>
                              </dl>
                            </div>
                          </div>
                        </div>
 
                        <div class="form-group">
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text bg-info">Reasons</span>
                            </div>
                            <textarea  
                              v-model="form_status.reasons" 
                              type="text" 
                              name="reasons" 
                              autocomplete="off" 
                              placeholder="(Optional)" 
                              class="reasons form-control" :class="{ 'is-invalid': form_status.errors.has('reasons') }">
                            <has-error :form="form_status" field="reasons"></has-error>
                            </textarea>
                          </div>
                        </div>
 
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal"><i class="fa fa-times-circle"></i> CLOSE</button>
                <button type="submit" class="btn btn-primary"><i class="fa fa-save"></i> UPDATE</button>
              </div>

              </form>

            </div>
          </div>
        </div>

        <!-- View Status History Modal -->
        <div class="modal" id="statusHistoryModal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="statusHistoryModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="statusHistoryModalLabel">Status History</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <form>

              <div class="modal-body">
                <div class="row">
                  
                  <div class="col-md-12">
                    <div class="card card-primary card-outline">
                      <!-- <div class="card-header bg-info">
                        <h4 class="card-title"><i class="fas fa-history"></i> Update Histories</h4>
                      </div> -->

                      <div class="card-body table-responsive p-0">
                        <table class="table table-hover table-striped">
                          <thead>
                            <tr>  
                              <th>Created At</th>
                              <th>Status</th>
                              <th>Reasons</th>
                              <th>Updated By</th>
                            </tr>
                          </thead>

                          <tbody>
                            <tr v-for="status_history in status_histories.data" :key="status_history.id">          
                              <td>{{ status_history.created_at | fulldate }}</td>
                              <td class="capitalize">
                                <span v-if="(status_history.status == 'pending')" class="badge bg-orange text-light">Pending</span>
                                <span v-if="(status_history.status == 'approved')" class="badge badge-success">Approved</span>
                                <span v-if="(status_history.status == 'revised')" class="badge badge-info">Revised</span>
                                <span v-if="(status_history.status == 'submitted')" class="badge badge-primary">Submitted</span>
                                <span v-if="(status_history.status == 'reviewed')" class="badge badge-secondary">Reviewed</span>
                              </td>
                              <td>{{ (status_history.reasons == null) ? "-" : status_history.reasons }}</td>
                              <td>{{ status_history.users.fname }} {{ status_history.users.mname }} {{ status_history.users.lname }}</td>
                            </tr>
                          </tbody>
                          <tbody v-if="status_histories.total == 0">
                              <tr><td colspan="10">No status histories found.</td></tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="card-footer">
                        <pagination :data="status_histories" @pagination-change-page="getStatusHistoryResults" show-disabled :limit="5"></pagination>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

              <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal"><i class="fa fa-times-circle"></i> CLOSE</button>
              </div>

              </form>

            </div>
          </div>
        </div>

        <!-- View Indicator Details Modal -->
        <div class="modal" id="indicatorDetailsModal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="indicatorDetailsModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="indicatorDetailsModalLabel">Indicator Details</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <form>

              <div class="modal-body">
                <div class="row">
 
                  <div class="col-md-6">
                    <div class="card card-primary card-outline">
                      <div class="card-body">
                        <strong><i class="fas fa-bars mr-1"></i> {{ this.program_name }}</strong>
                          <p class="text-muted">Program Name</p>
                        <hr>
                        <strong><i class="fas fa-hand-point-right mr-1"></i> {{ this.indicator_name }}</strong>
                          <p class="text-muted">Indicator Name</p>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="card card-primary card-outline">
                      <div class="card-body">
                        <strong><i class="fas fa-flag mr-1"></i> {{ form.month | monthwordformat }}</strong>
                          <p class="text-muted">Month</p>
                        <hr>
                        <strong><i class="fas fa-calendar mr-1"></i> {{ form.year }}</strong>
                          <p class="text-muted">Year</p>
                      </div>
                    </div>
                  </div>
  
                  <div class="col-md-12">
                    <div class="card card-primary card-outline">
                      <div class="card-body table-responsive p-0">
                        <table class="table table-hover table-striped no-wrap">
                          <thead>
                            <tr>  
                              <th v-if="sub_indicators.total == 0">
                                Value
                              </th>

                              <th v-else v-for="sub_indicator in sub_indicators.data" :key="sub_indicator.id">
                                {{ sub_indicator.sub_indicator_name }}
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                            <tr v-for="target_content in target_contents.data" :key="target_content.id"> 
                              <template v-for="target_sub_content in target_content.target_sub_contents">
                              <td :key="target_sub_content.id">
                                {{ (target_sub_content.value == null) ? "-" : target_sub_content.value }}
                              </td>
                              </template>
                            </tr>
                          </tbody>
                          <tbody v-if="target_contents.total == 0">
                              <tr><td colspan="12">No indicator details found.</td></tr>
                          </tbody>
                        </table>
                      </div>
                      <div class="card-footer">
                        <pagination :data="target_contents" @pagination-change-page="getIndicatorDetailResults" show-disabled :limit="10"></pagination>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

              <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal"><i class="fa fa-times-circle"></i> CLOSE</button>
              </div>

              </form>

            </div>
          </div>
        </div>
 
    </div>
</template>

<script>

    import Form from 'vform'; 

    export default {
        data() {
          return {
            timer: 30,
            editmode: false,
            editTargetValueMode: false,
            editTargetContentMode: false,
            emptyflag: false,
            can_approved_status: false,
            can_reviewed_status: false,
            can_add_edit_programs: false,
            programs: {},
            modify_programs: {},
            program_id: '',
            month: '',
            year: '',
            status: 5,
            get_month: '',
            get_year: '',
            indicator_name: '',
            program_name: '',
            targets: {}, 
            indicators: {},
            sub_indicators: {},
            
            active_target: '',
            active_indicator: '',
            active_indicator_title: '',
            total_sub_indicators: '',
            allow_attachment: '',
            with_amount: '',
            target_contents: {},
            
            count_indicators: '',
            // actual_amount: {},
            // get_actual_amount: '',
            total_amount: '',

            status_histories: {},
            target_status: '',

            money: {
              decimal: '.',
              thousands: ',',
              prefix: '',
              suffix: '',
              precision: 2,
              masked: true /* doesn't work with directive */
            },
             
            form: new Form({
              id: '',
              program_id: '',
              month: '',
              year: '',
              status: '',
              indicator_id: '',
              sub_indicator_values: [],
              target_content_id: '',
              target_sub_content_id: '',
              sub_indicator_value: '',
              sub_indicators: []
            }),
            form_status: new Form({
              id: '',
              target_id: '',
              status: '',
              reasons: '',
              month: '',
              year: '',

              program_name: '',
              current_status: ''
            })
          }
        },

        computed: {
  
          second() {
            return this.time / 1000;
          },

          isIdle() {
            const idlestat =  this.$store.state.idleVue.isIdle;
            if (idlestat == true) {
              $('#sessionmodal').modal('show');
              this.countDownTimer();
            } else {
              $('#sessionmodal').modal('hide');
            }

            return idlestat;

          },

          years() {
            const year = new Date().getFullYear()
            return Array.from({length: year - 1997}, (value, index) => 1998 + index)
          },
 
        },

        methods: {
 
          enableSubRow(index) {
            this.active_target = index;
          },

          countDownTimer() {
            let timerId = setInterval(() => {
              this.timer -= 1;
              if (!this.$store.state.idleVue.isIdle) clearInterval(timerId);

              if (this.timer < 1) {
                clearInterval(timerId);
                document.getElementById('logout-form').submit();
              }
            }, 1000);

            this.timer = 30;
          },

          // computeTotalAmount(value) {
          //   var displayValue = this.actual_amount.amount_of_investment || ""; //check if this.value is null
          //   displayValue = String(displayValue).replace(/,/g, ""); //replace ,

          //   if(value == '') { value = 0 };
 
          //   if(this.form.month == '')
          //   {
          //     return this.total_amount = 0.00;
          //   }
          //   else
          //   {
          //     return this.total_amount = parseInt(value) + parseInt(displayValue);
          //   }
            
          // },

          numbersOnly(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
              evt.preventDefault();;
            } else {
              return true;
            }
          },

          editTargetValue(target_sub_contents) {

            this.editTargetValueMode = true;

            this.form.target_sub_content_id = '';
            this.form.sub_indicator_value = '';
 
            this.form.target_sub_content_id = target_sub_contents[0].id;
            this.form.sub_indicator_value = target_sub_contents[0].value;

            $('#targetValueModal').modal('show'); 
          },
  
          editTargetContent(target_sub_contents) {

            this.editTargetContentMode = true;
            
            this.form.target_content_id = '';
            this.form.sub_indicator_values = [];

            this.form.target_content_id = target_sub_contents[0].target_content_id;
             
            target_sub_contents.forEach((value, index) => {
                this.form.sub_indicator_values.push(value.value);
                // console.log(value.value);
                // console.log(index);
            });
  
            $('#targetContentModal').modal('show'); 
          },

          deleteSubContent(id) { 
            swal.fire({

              title: 'Are you sure?',
              text: "You won't be able to revert this!",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes, delete content!'
              }).then((result) => {

                if (result.value) {
                  //send delete ajax tru api request to server
                  this.form.delete('api/targets/delete-sub-content/' + id).then(() => {

                    this.reloadTargetContents();
                     
                    swal.fire(
                      'Deleted!',
                      'Content has been deleted.',
                      'success'
                    );
                    Trigger.$emit('RefreshData');

                  }).catch(() => {

                    swal.fire(
                      'Failed!', 
                      'There was something wrong', 
                      'warning'
                    )
                  });
                }
            })
          },

          editModal(target) {
            this.editmode = true;
            this.form.reset();
            this.form.clear();
            this.form.fill(target);
 
            this.get_month = target.month;
            this.get_year = target.year;
            this.indicator_name = target.indicator_name;
            this.program_name = target.program_name;
 
            $('#targetModal').modal('show');
          },
  
          newTargetModal() {
            let id = 0;

            this.editmode = false;
            this.form.reset();
            this.form.clear();

            const today = new Date();
          
            const curr_year = today.getFullYear(); 

            let yr = ""; 

            if(this.form.year == '') {
              yr = curr_year; 
              this.form.year = curr_year; 
            } else {
              yr = this.form.year; 
            }
            
            // this.form.indicator_id = '';

            this.sub_indicators = {};
            this.target_contents = {};
            this.active_indicator_title = '';
            this.total_sub_indicators = '';
             
            axios.get('api/get-indicator-program-id/' + id + '/' + this.form.year).then(({ data }) => (this.indicators = data));

            $('#targetModal').modal('show');
          },

          newTargetContentModal() {
            this.editTargetContentMode = false;

            // let program_id = this.form.program_id;
            // let month = this.form.month;
            // let year = this.form.year;

            this.form.sub_indicator_values = [];
            
            // axios.get('api/get-actual-amount/' + program_id + '/' + month + '/' + year).then(({ data }) => (this.actual_amount = data));
             
            $('#targetContentModal').modal('show');
          },

          newTargetValueModal() {
            this.editTargetValueMode = false;
            this.form.sub_indicator_value = '';
            $('#targetValueModal').modal('show');
          },

          editChangeStatusModal(target, status) {
            this.form_status.reasons = '';
 
            this.form_status.month = target.month;
            this.form_status.year = target.year;
            this.form_status.program_name = target.programs.program_name;

            this.form_status.target_id = target.id;
            this.form_status.status = status;

            if(target.status == 0) { this.form_status.current_status = 'pending'; }
            if(target.status == 1) { this.form_status.current_status = 'approved'; }
            if(target.status == 2) { this.form_status.current_status = 'revised'; }
            if(target.status == 3) { this.form_status.current_status = 'submitted'; }
            if(target.status == 4) { this.form_status.current_status = 'reviewed'; }

            $('#changeStatusModal').modal('show');
          },

          updateChangeStatusModal() {
            this.$Progress.start();
             
            this.form_status.put('api/targets/change-status/' + this.form_status.target_id + '/' + this.form_status.status)
            .then(() => {

              this.form_status.resons = '';
              $('#changeStatusModal').modal('hide');
  
              this.filterTargetData();

              toastr.fire({
                icon: 'success',
                title: 'Status updated successfully'
              });

            this.$Progress.finish();

            }).catch(() => {

              this.form_status.resons = '';
              $('#changeStatusModal').modal('hide');

              this.filterTargetData();

              swal.fire(
                'Info!', 
                'Status already updated!', 
                'info'
              )
  
            this.$Progress.fail();
            });
          },

          viewStatusHistoryModal(target_id) {

            this.form_status.target_id = target_id;

            this.status_histories = {};

            axios.get('api/targets/get-status-histories-id/' + target_id).then(({ data }) => (this.status_histories = data));

            $('#statusHistoryModal').modal('show');
          },

          viewIndicatorDetailModal(indicator, program_name, month, year) {
 
            this.sub_indicators = {};
            this.target_contents = {};
 
            this.indicator_name = indicator.indicator_name;
            this.program_name = program_name;

            this.form.indicator_id = indicator.id;
            this.form.program_id = indicator.program_id;

            this.form.month = month;
            this.form.year = year;
 
            axios.get('api/get-sub-indicator-by-id/' + indicator.id).then(({ data }) => (this.sub_indicators = data));
 
            axios.get('api/get-target-contents-indicator-id/' + indicator.id + '/' + indicator.program_id + '/' + month + '/' + year).then(({ data }) => (this.target_contents = data));
  
            $('#indicatorDetailsModal').modal('show');
          },
  
          // TODO: Mark2
          getIndicators(id) {
            this.$Progress.start();

              this.form.indicator_id = '';

              this.indicators = {};

              this.active_indicator = '';
              this.active_indicator_title = '';
              this.total_sub_indicators = '';
              this.sub_indicators = {};
              this.target_contents = {};

              this.count_indicators = '';
              this.target_status = '';

              const month = this.form.month == '' ? 0 : this.form.month;
              const year = this.form.year;
              // axios.get('api/get-count/' + this.form.indicator_id + '/' + month + '/' + parseInt(id) + '/' + year).then(( data ) => (this.count_indicators = data));
              axios.get('api/targets/get-status/' + id + '/' + month + '/' + year).then(({ data }) => (this.target_status = data));

              axios.get('api/get-indicator-program-id/' + id + '/' + this.form.year).then(({ data }) => (this.indicators = data));
  
            this.$Progress.finish();
          },

          getCount(e) { 
            this.$Progress.start();
 
              const indicator_id = this.form.indicator_id == '' ? 0 : this.form.indicator_id;
              const month = this.form.month == '' ? 0 : this.form.month;
              const program_id = parseInt(this.form.program_id);
              const year = this.form.year;

              this.active_indicator = '';
              this.active_indicator_title = '';
              this.total_sub_indicators = '';
              this.sub_indicators = {};
              this.target_contents = {};

              axios.get('api/get-indicator-program-id/' + this.form.program_id + '/' + this.form.year).then(({ data }) => (this.indicators = data));
 
              axios.get('api/get-count/' + indicator_id + '/' + month + '/' + program_id + '/' + year).then(( data ) => (this.count_indicators = data));
            
            this.$Progress.finish();
          },

          getSubIndicators(indicator) {
            let id = indicator.id;

            this.$Progress.start();

            this.form.indicator_id = id;
            this.allow_attachment = indicator.allow_attachment;
            // this.with_amount = indicator.with_amount;
            
            // Highlight active indicator when clicked
            this.active_indicator = id;
            
            this.active_indicator_title = indicator.indicator_name;
            this.total_sub_indicators = indicator.sub_indicators.length;

            this.sub_indicators = {}
            this.target_contents = {};

            this.form.sub_indicator_values = [];
            this.target_status = '';
  
            // this.total_amount = '';

            const month = this.form.month == '' ? 0 : this.form.month;
            const program_id = parseInt(this.form.program_id);
            const year = this.form.year;

            axios.get('api/targets/get-status/' + program_id + '/' + month + '/' + year).then(({ data }) => (this.target_status = data));

            axios.get('api/get-count/' + id + '/' + month + '/' + program_id + '/' + year).then(( data ) => (this.count_indicators = data));
 
            axios.get('api/get-sub-indicator-by-id/' + id).then(({ data }) => (this.sub_indicators = data));
 
            axios.get('api/get-target-contents-indicator-id/' + id + '/' + program_id + '/' + month + '/' + year).then(({ data }) => (this.target_contents = data));
  
            this.$Progress.finish();
          },

          reloadTargetContents() {
            axios.get('api/get-target-contents-indicator-id/' + this.form.indicator_id + '/' + parseInt(this.form.program_id) + '/' + this.form.month + '/' + this.form.year).then(({ data }) => (this.target_contents = data));
            axios.get('api/get-count/' + this.form.indicator_id + '/' + this.form.month + '/' + parseInt(this.form.program_id) + '/' + this.form.year).then(( data ) => (this.count_indicators = data));
          },

          resetFilter() {
            this.program_id = '';
            this.status = 5;
            this.month = '';
            this.year = '';

            this.active_target = '';

            this.target_status = '';
 
            this.loadTargets();
          },
 
          getCurrentYear() {
            const today = new Date();
          
            const curr_year = today.getFullYear(); 

            let yr = ""; 

            if(this.year == '') {
              yr = curr_year; 
              this.year = curr_year; 
            } else {
              yr = this.year; 
            } 

            return yr
          },
 
          // TODO: Mark1
          filterTargetData() {
            let program_id = this.program_id
            let month = this.month
            let year = this.year
            let status = this.status

            this.active_target = "";

            program_id = (program_id == '') ? 0 : this.program_id
            month = (month == '') ? 0 : parseInt(this.month)
            year = (year == '') ? 0 : year
            status = (status == 5) ? 5 : status
 
            this.$Progress.start();
 
            axios.get('api/filter-targets/' + program_id + '/' + status + '/' + month + '/' + year).then(({ data }) => (this.targets = data));
 
            this.$Progress.finish();
          },
  
          createTargetValue() {
            this.$Progress.start();
            this.form.post('api/targets/create-value')
            .then(()=>{
              Trigger.$emit('RefreshData');

              this.form.sub_indicator_value = '';

              $('#targetValueModal').modal('hide')

              this.reloadTargetContents();
              this.filterTargetData();

              toastr.fire({
                icon: 'success',
                title: 'Target value created successfully'
              })
              this.$Progress.finish();
            }).catch(()=>{
              toastr.fire({
                icon: 'error',
                title: 'Target value creation failed'
              });
              this.$Progress.fail();
            })
          },

          updateTargetValue() {
            this.$Progress.start();
            this.form.put('api/targets/update-target-value/' + this.form.target_sub_content_id).then(()=>{
            Trigger.$emit('RefreshData');

            this.form.sub_indicator_value = '';
            $('#targetValueModal').modal('hide');

            this.reloadTargetContents();
            this.filterTargetData();

                toastr.fire({
                icon: 'success',
                title: 'Target value information updated successfully'
            })
            this.$Progress.finish();
            }).catch(()=>{
                toastr.fire({
                icon: 'error',
                title: 'Target value information updating failed'
            });
            this.$Progress.fail();
            });
          },

          createTargetContent() { 
            this.$Progress.start();
            this.form.post('api/targets/create-content')
            .then(()=>{
              Trigger.$emit('RefreshData');

              this.form.sub_indicator_values = [];
              this.total_amount = '';

              $('#targetContentModal').modal('hide');

              this.reloadTargetContents();
              this.filterTargetData();

              toastr.fire({
                icon: 'success',
                title: 'Target Data created successfully'
              })
              this.$Progress.finish();
            }).catch(()=>{
              toastr.fire({
                icon: 'error',
                title: 'Target Data creation failed'
              });
              this.$Progress.fail();
            })
          },

          updateTargetContent() {
            this.$Progress.start();
            this.form.put('api/targets/update-target-content/' + this.form.target_content_id).then(()=>{
            Trigger.$emit('RefreshData');

            this.form.sub_indicator_values = [];
            $('#targetContentModal').modal('hide');

            this.reloadTargetContents();
            this.filterTargetData();

                toastr.fire({
                icon: 'success',
                title: 'Target Data information updated successfully'
            })
            this.$Progress.finish();
            }).catch(()=>{
                toastr.fire({
                icon: 'error',
                title: 'Target Data information updating failed'
            });
            this.$Progress.fail();
            });
          },

          getResults(page = 1) {

            let query = this.$parent.search;

            if(query == ''){
              axios.get('api/targets?page=' + page).then(response => { this.targets = response.data; }); 
            }else{
              axios.get('api/find-target?q=' + query + '&page=' + page).then(response => { this.targets = response.data; }); 
            }
            
          },
 
          getIndicatorResults(page = 1) {

            let query = this.$parent.search;

            if(query == ''){
              axios.get('api/all-indicators?page=' + page).then(response => { this.indicators = response.data; }); 
            }else{
              axios.get('api/findIndicator?q=' + query + '&page=' + page).then(response => { this.indicators = response.data; }); 
            }
            
          },

          getSubIndicatorResults(page = 1) {

            let query = this.$parent.search;

            if(query == ''){
              axios.get('api/all-sub-indicators?page=' + page).then(response => { this.sub_indicators = response.data; }); 
            }else{
              axios.get('api/findIndicator?q=' + query + '&page=' + page).then(response => { this.indicators = response.data; }); 
            }
            
          },
 
          getStatusHistoryResults(page = 1) {
            axios.get('api/targets/get-status-histories-id/' + this.form_status.target_id + '?page=' + page).then(response => { this.status_histories = response.data; });  
          },

          getIndicatorDetailResults(page = 1) {
            let month = this.form.month;
            month = (month == '') ? 0 : parseInt(this.form.month);

            axios.get('api/get-target-contents-indicator-id/' + this.form.indicator_id + '/' + this.form.program_id + '/' + month + '/' + this.form.year + '?page=' + page).then(({ data }) => (this.target_contents = data));
          },
  
          loadTargets() {
            this.$Progress.start();
 
            let query = this.$parent.search;
            let page = this.targets.current_page;

            axios.get('api/all-programs').then(({ data }) => (this.programs = data));

            axios.get('api/modify-programs').then(({ data }) => (this.modify_programs = data));
 
            if(query == ''){
              axios.get('api/targets?page=' + page).then(({ data }) => (this.targets = data));
             
            }else{
              axios.get('api/find-target?q=' + query + '&page=' + page).then(({ data }) => (this.targets = data));
            
            }
 
            this.year = this.getCurrentYear();

            this.$Progress.finish();
          },

          loadTargetContents() {
            this.$Progress.start();
            axios.get('api/get-target-contents-id/' + this.form.id).then(({ data }) => (this.target_contents = data));
            this.$Progress.finish();
          },

          async loadPermission(mid) {
              const response = await axios.get("api/permission/" + this.$gate.showMyID() + "/" + mid).then( 
              res => {
                if (!res.data || res.data.length == 0) {
                  return false;
                } else {
                  return true;
                }
              })

              const getResponse = async () => {
                const a = await response;

                switch (mid) {
                case 36:
                  this.can_access = a;
                  if (!this.can_access) {
                    this.$router.push({ name: 'not-found' }) //redirect to not found page if !can access project monitoring
                  }
                  break;
                case 38:
                  this.can_approved_status = a;
                  break;
                case 39:
                  this.can_reviewed_status = a;
                  break;
                case 41:
                  this.can_add_edit_programs = a;
                  break;
                case 14:
                  this.can_delete = a;
                }
              };

              return getResponse()
          },

        },

        created() {
          Trigger.$on('searching',() => {
            let query = this.$parent.search;
            let emptyflag = false;

            if(query == ''){
              Trigger.$emit('RefreshData');
            }else{
              this.$Progress.start();
              axios.get("api/find-target?q=" + query)
              .then((data) => {
                this.targets = data.data;
 
                if (data.data.total == 0){
                  this.emptyflag = true;
                }

                this.$Progress.finish();
              })
              .catch(() => {
                this.$Progress.fail();
              })
            }
            
          });

          // this.year = this.getCurrentYear();

          this.loadPermission(36); // Can access target data
          this.loadPermission(38); // Can change status in target data
          this.loadPermission(39); // Can reviewed status in target data
          this.loadPermission(41); // Can add/edit all programs target data
 
          this.loadTargets();
 
          Trigger.$on('RefreshData',() => {
            this.loadTargets();
          });
        },

    }
</script>

<style lang="scss" scoped>
.a-pointer:hover {
    cursor: pointer;
}

.active-class {
  background-color: #1e90ff !important;
  color: #fffaf0 !important;
}

.active-class2 {
  background-color: #f2f3f4 !important;
  color: #0c0800 !important;
}

.modal {
  overflow-y:auto;
}

.reasons {
  resize: none;
}

.capitalize {
  text-transform: capitalize;
}

.text-wrap {
  overflow-wrap: break-word;
}

.table-responsive::-webkit-scrollbar {
    -webkit-appearance: none;
}

.table-responsive::-webkit-scrollbar:vertical {
    width: 12px;
}

.table-responsive::-webkit-scrollbar:horizontal {
    height: 12px;
}

.table-responsive::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, .5);
    border-radius: 10px;
    border: 2px solid #ffffff;
}

.table-responsive::-webkit-scrollbar-track {
    border-radius: 10px;  
    background-color: #ffffff; 
}

.no-wrap {
  white-space: nowrap;
}
</style>