<template>
    <doughnut-chart v-if="loaded" :chartdata="chartdata" :options="options"></doughnut-chart>
</template>

<script>
import DoughnutChart from './DoughnutChart'

export default {
    name: 'RegionalProjects',
    components: {
        DoughnutChart
    },

    data () {
        return {
            chartdata: null,
            options: null,
            loaded: false,
        }
    },

    props: {
        datajson: {},
    },

    mounted() {
        this.generateChartData();
    },

    methods: {

        generateColorPalette(length) {
            const palette = [];
            const lightness = 80; // Adjust lightness for pastel-like finish
            const saturation = 60; // Adjust saturation for pastel-like finish
            for (let i = 0; i < length; i++) {
                const hue = (i * 360 / length) % 360;
                palette.push(`hsl(${hue}, ${saturation}%, ${lightness}%)`);
            }
            return palette;
        },

        generateChartData() {
            const labels = [];
            const projects = [];

            this.datajson.features.forEach(feature => {
                if (feature.properties.projects > 0) {
                    labels.push('Region ' + feature.properties.name);
                    projects.push(feature.properties.projects);
                }
            });

            const numNonZeroProjects = labels.length;

            const backgroundColors = this.generateColorPalette(numNonZeroProjects);
            const borderColors = backgroundColors.map(color => color.replace('80%', '90%'));

            const hoverColors = backgroundColors.map(color => color.replace('80%', '70%')); // Adjust hover color lightness
            const hoverBorderColors = borderColors.map(color => color.replace('90%', '80%'));

            this.chartdata = {
                labels: labels,
                datasets: [
                    {
                        label: "# users",
                        data: projects,
                        backgroundColor: backgroundColors,
                        hoverBackgroundColor: hoverColors, // Override hover background color
                        borderColor: borderColors,
                        hoverBorderColor: hoverBorderColors,
                        borderWidth: 1
                    }
                ]
            };

            this.options = {
                legend: {
                    display: true
                },
                responsive: true,
                maintainAspectRatio: false,
                title: {
                    display: false,
                    text: "Projects Per Region"
                }
            };

            this.loaded = true;
        }
    }


}
</script>
