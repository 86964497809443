<template>
    <div class="container-fluid">
        <div class="row">
  
          <div class="col-md-4">
            <div class="form-group">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text bg-info">YEAR</span>
                </div>
                <select @change="getTargetData" v-model="form.year" name="year" class="form-control" placeholder="SELECT YEAR">
                  <option selected disabled value="">SELECT YEAR</option>
                  <option v-for="year in years" :value="year" :key="year">{{ year }}</option>
                </select>
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text bg-info">MONTH</span>
                </div>
                <select @change="getTargetData" v-model="form.month" name="month" class="form-control" placeholder="SELECT MONTH">
                    <option selected value="" disabled>Select Month</option>
                    <option value="1">January</option>
                    <option value="2">February</option>
                    <option value="3">March</option>
                    <option value="4">April</option>
                    <option value="5">May</option>
                    <option value="6">June</option>
                    <option value="7">July</option>
                    <option value="8">August</option>
                    <option value="9">September</option>
                    <option value="10">October</option>
                    <option value="11">November</option>
                    <option value="12">December</option>
                </select>
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text bg-info">PROGRAM</span>
                </div>
                <select @change="getTargetData" v-model="form.program_id" name="program_id" class="form-control" placeholder="SELECT PROGRAM">
                  <option selected value="">All Programs</option>
                  <option v-for="program in programs" :value="program.id" :key="program.id">{{ program.program_name }}</option>
                </select>
              </div>
            </div>
          </div>

        </div>

        <div class="row justify-content-center">
            <div class="col-md-12">
 
                <div class="card-header bg-primary pull-right">
                  <div class="card-tools">
                    <button v-print="'#printReport'" @click="print"  class="btn btn-success mr-2"><i class="fas fa-print"></i> Print</button>
                  </div>
                    
                </div>
     
                <div class="card">

                    <div class="card-body table-responsive p-0">

                      <div  id="printReport">
                        <table class="table table-borderless" style="font-size: 5px;">
                          <tbody>
                            <tr>
                              <td colspan="9" align="center">
                                <br/>
                                <h3>TECHNOLOGY APPLICATION AND PROMOTION INSTITUTE</h3>
                                <i>CY {{ this.form.year }} Accomplisment Report</i>
                              </td>
                            </tr>
                            <tr>
                              <td colspan="9">
                                <hr/>

                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table class="table table-bordered table-hover" style="font-size: 5px;">
                            <thead>
                            <tr>
                              <th class="card-header">
                                PERFORMANCE INDICATORS
                              </th>

                              <template style="text-align:center">
                                <th style="text-align:center" class="card-header">
                                  MONTH
                                </th>
                                <th style="text-align:center" class="card-header">
                                  TARGET
                                </th>
                                <th style="text-align:center" class="card-header">
                                  TOTAL
                                </th>
                                <th style="text-align:center" class="card-header">
                                  PERCENTAGE
                                </th>
                              </template>
                              
                            </tr>
                            </thead>
                            <tbody v-for="target in targets" :key="target.id">
                              <tr>
                                <template>
                                  <td colspan="15"><b>{{ target.programs.program_name }}</b></td>
                                </template>
                              </tr>
                              
                              <template v-for="(tar, index) in target.programs.all_indicators">

                                <tr :key="index">
                                  <td>{{ tar.indicator_name }}</td>
 
                                  <template v-if="tar.with_amount == 0 && tar.si_id == null">
                                    <td v-for="amt in tar.amount_per_months" :key="amt.id">
                                      <center>{{ (amt == "") ? 0 : amt }}</center>
                                    </td>

                                    <td><center>{{ tar.target_count }}</center></td>
                                     
                                    <td><center>{{ tar.amount_total }}</center></td>

                                    <td><center>{{ (tar.target_total != 0 && tar.target_count != 0) ? (tar.target_total /  tar.target_count) * 100 : 0 }}</center></td>
                                  </template>

                                  <template v-else>
                                    <td v-for="tpm in tar.target_per_months" :key="tpm.id">
                                      <center>{{ tpm }}</center>
                                    </td>
                                    <td><center>{{ tar.target_count }}</center></td>

                                    <td><center>{{ tar.target_total }}</center></td>

                                    <td><center>{{ (tar.target_total != 0 && tar.target_count != 0) ? (tar.target_total /  tar.target_count) * 100 : 0 }}</center></td>
                                  </template>
                                </tr>
                                
                                <template v-if="tar.with_amount == 1">
                                  <tr :key="tar.index">
                                    <td>Amount of Investment</td>
                                    <td v-for="(twa, ind) in tar.amount_per_months" :key="ind">
                                      <center>{{ (twa == "") ? 0 : twa | toCurrency }}</center>
                                    </td>
                                    <td></td>
                                    <td><center>{{ tar.amount_total | toCurrency }}</center></td>
                                    <td></td>
                                  </tr>
                                </template>

                              </template>
                            </tbody>
                            <tbody v-show="targets.length == 0">
                                <tr><td colspan="5">No reports found.</td></tr>
                            </tbody>
                        </table>
                       
                      </div>
                    </div>
                </div>

                <div class="modal fade" id="sessionmodal" tabindex="-1" role="dialog" aria-labelledby="sessionModalLabel" aria-hidden="true">
                  <div class="modal-dialog modal-dialog-centered modal-md" role="document">
                    <div class="modal-content">
                      <div class="modal-header bg-orange">
                        <h5 class="modal-title" id="sessionModalLabel"><i class="icon fas fa-exclamation-triangle"></i> Security Auto-logout</h5> 
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body">
                      <div class="row-aligncenter">
                        <div id="printPayslip2">
                          <div class="card-body">
                            You have left this browser idle for 10 minutes. Due to our data privacy policy, you will be 
                            automatically logged out in <b>{{ timer }}</b> seconds.
                          </div>
                        </div>
                      </div>
                      </div>
                      <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                      </div>
                    </div>
                  </div>
                </div>
                <span style="display:none;">{{ isIdle }}</span>
            </div>
        </div>
    </div>
</template>

<script>
    import Form from 'vform';
    import moment from 'moment';

    export default {
        data() {
          return {
            grandtotals: [],
            timer: 30,
            divisions : {},
            year: '',
            projects: [],
            targets: [],
            programs: {},
            count_per_months: [],
            hideempty: false,
            can_access: false,
            form: new Form({
              division: '',
              year: '',
              month: '',
              program_id: ''
            })
          }
        },

        methods: {

          print(){
              var style = document.createElement('style');
              style.innerHTML = "@page{size: landscape;margin: 5mm;}" +
                      " html{background-color: #FFFFFF;}" +
                      " body{border: solid 0px #FFFFFF;margin: 0;}";
              window.document.head.appendChild(style);
          },

          hideEmpty(e) {
                //this.$Progress.start();
                if(e.target.checked){
                     this.hideempty = true;
                } else {
                     this.hideempty = false;
                }
                //this.$Progress.finish();
            },

          countDownTimer() {
            let timerId = setInterval(() => {
              this.timer -= 1;
              if (!this.$store.state.idleVue.isIdle) clearInterval(timerId);

              if (this.timer < 1) {
                clearInterval(timerId);
                // Your logout function should be over here
                document.getElementById('logout-form').submit();
              }
            }, 1000);

            this.timer = 30;
          },
  
          getTargetData(){
            this.$Progress.start();

            let program_id = this.form.program_id;
            program_id = (program_id == '') ? 0 : this.form.program_id;

            // this.form.program_id = (this.form.program_id == '') ? 0: this.form.program_id;
 
            axios.get("api/targets/monthly-reports/" + this.form.year + '/' + this.form.month + '/' + program_id).then(({ data }) => (this.targets = data));
            
            this.$Progress.finish();
          },
 
          async loadPermission(mid) {
              const response = await axios.get("api/permission/" + this.$gate.showMyID() + "/" + mid).then( 
              res => {
                if (!res.data || res.data.length == 0) {
                  return false;
                } else {
                  return true;
                }
              })

              const getResponse = async () => {
                const a = await response;

                switch (mid) {
                case 5:
                  this.can_access = a;
                  if (!this.can_access) {
                    this.$router.push({ name: 'not-found' }) //redirect to not found
                  }
                }
              };
              return getResponse()
          },

        },

        computed: {

          years() {
            const year = new Date().getFullYear()
            return Array.from({length: year - 1997}, (value, index) => 1998 + index)
          },

          second() {
            return this.time / 1000;
          },
 
          isIdle() {
            const idlestat =  this.$store.state.idleVue.isIdle;
            if (idlestat == true) {
              $('#sessionmodal').modal('show');
              this.countDownTimer();
            } else {
              $('#sessionmodal').modal('hide');
            }

            return idlestat;

          },
 
        },

        created() {

          const today = new Date();
          const curr_year = today.getFullYear();
          const curr_month = moment(String(today.getMonth() + 1)).format('M');

          let yr = "";
          let mo = "";
          let program_id = this.form.program_id;

          if(this.form.year == '' || this.form.month == '') {
            yr = curr_year;
            mo = curr_month;
            this.form.year = curr_year;
            this.form.month = curr_month;
          } else {
            yr = this.form.year;
            mo = this.form.month;
          }

          program_id = (program_id == '') ? 0 : this.form.program_id;
  
          // this.loadPermission(5); //Can access payroll
          // axios.get("api/divisions").then(({ data }) => (this.divisions = data));
          // axios.get("api/projects/divisions/1/payrolls/" + yr + "/" + mo + "/1").then(({ data }) => (this.projects = data));
          // axios.get("api/payrolls/total/1/" + yr + "/" + mo + "/1").then(({ data }) => (this.grandtotals = data));
          axios.get('api/all-programs').then(({ data }) => (this.programs = data));
          axios.get("api/targets/monthly-reports/" + yr + '/' + mo + '/' + program_id).then(({ data }) => (this.targets = data));
          // axios.get("api/get-count-per-month/" + yr).then(({ data }) => (this.count_per_months = data));
        }
    }
</script>

