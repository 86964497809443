<template>
    <div>
        <div class="row">
            <div class="col-lg-3 col-12">
                <!-- small box -->
                <div class="small-box bg-info">
                    <div v-if="loading" class="overlay">
                        <i class="fas fa-3x fa-sync-alt"></i>
                    </div>
                    <div class="inner">
                    <h1>{{cases_total | toNumberComma}}</h1>
                    <br/>
                    </div>
                    <div class="icon">
                    <i class="fa fa-tint"></i>
                    </div>
                    <p class="small-box-footer"><b>DOH Recorded Covid-19 Cases</b></p>
                </div>
            </div>
            <!-- ./col -->
            <div class="col-lg-3 col-6">
                <!-- small box -->
                <div class="small-box bg-success">
                    <div v-if="loading" class="overlay">
                        <i class="fas fa-3x fa-sync-alt"></i>
                    </div>
                    <div class="inner">
                    <h1>{{cases_active | toNumberComma}}</h1>
                    <br/>
                    </div>
                    <div class="icon">
                    <i class="fa fa-plus"></i>
                    </div>
                    <p class="small-box-footer"><b>Active Cases</b></p>
                </div>
            </div>
            <!-- ./col -->
            <div class="col-lg-3 col-6">
                <!-- small box -->
                <div class="small-box bg-warning">
                    <div v-if="loading" class="overlay">
                        <i class="fas fa-3x fa-sync-alt"></i>
                    </div>
                    <div class="inner">
                    <h1>{{cases_recovered | toNumberComma}}</h1>
                    <br/>
                    </div>
                    <div class="icon">
                    <i class="fa fa-exclamation-triangle"></i>
                    </div>
                    <p class="small-box-footer"><b>Recovered</b></p>
                </div>
            </div>
            <!-- ./col -->
            <div class="col-lg-3 col-6">
                <!-- small box -->
                <div class="small-box bg-danger">
                    <div v-if="loading" class="overlay">
                        <i class="fas fa-3x fa-sync-alt"></i>
                    </div>
                    <div class="inner">
                    <h1>{{cases_died | toNumberComma}}</h1>
                    <br/>
                    </div>
                    <div class="icon">
                    <i class="fa fa-exclamation-circle"></i>
                    </div>
                    <p class="small-box-footer"><b>Died</b></p>
                </div>
            </div>
            <!-- ./col -->
        </div>
        <!--
        <div class="row">
            <div class="col-lg-12 col-12">
                <div class="card bg-gradient-default">
                    <div class="card-header border-0">
                        <h3 class="card-title mt-2">
                            <i class="fa fa-line-chart mr-1"></i>
                            Covid-19 Statistics
                        </h3>
                        <div class="card-tools">
                            <button title="Year" type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                                {{this.chart_year}}
                            </button>
                            <ul class="dropdown-menu" x-placement="bottom-start" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 48px, 0px);">
                                <li v-for="year in years" :value="year" :key="year" class="dropdown-item ddown" @click="loadData(year)">{{ year }}</li>
                            </ul>
                        </div>
                        
                    </div>
                    <div class="card-body">
                        <line-chart v-if="loaded" :chartdata="chartdata" :options="options"></line-chart>
                    </div>
                </div>
            </div>
        </div>
        -->
    </div>
</template>

<script>
import LineChart from './LineChart'
import axios from 'axios'

export default {
    name: 'Covid19Chart',
    components: {
        LineChart, 
    },


    data () {
        return {
        chartdata: null,
        options: null,
        loading: false,
        loaded: false,
        chart_year: '',
        chart_month: '',
        data: {},
        cases_total: '',
        cases_active: '',
        cases_recovered: '',
        cases_died: '',
        }
    },

    computed: {
        years() {
            const year = new Date().getFullYear()
            return Array.from({length: year - 2019}, (value, index) => 2020 + index)
        },
    },

    methods: {

        async loadData(yr) {
            this.chart_year = yr;
            this.loading = true;

            
            

        
        }


    },

    async mounted () {
        /*
        
        axios.get('api/dashboard/' + current_year + '/' + current_month).then(response => {
        this.months_consumption = response.data.dashboardConsumption;
        this.months_payments = response.data.dashboardPayments;
        this.months_pending = response.data.dashboardPending;
        this.months_overdue = response.data.dashboardOverdue;
        
        })
        */
        this.loading = true;
        //const current = new Date();
       // const current_year =  current.getFullYear();
        //this.loadData(current_year);

        //const current_month = moment(String(current)).format('MM');
        //this.chart_month = current_month;

        await axios.get('https://api.apify.com/v2/key-value-stores/lFItbkoNDXKeSWBBA/records/LATEST?disableRedirect=true',
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }, 
            transformRequest: (data, headers) => {
                delete headers.common['X-Requested-With'];
                return data;
            }
        }).then(response => {
            this.cases_total = response.data.infected;
            this.cases_active = response.data.activeCases;
            this.cases_recovered = response.data.recovered;
            this.cases_died = response.data.deceased;
        });

        /*
        await axios.get("https://api.apify.com/v2/datasets/sFSef5gfYg3soj8mb/items?format=json&clean=1",
        {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            }, 
            transformRequest: (data, headers) => {
                delete headers.common['X-Requested-With'];
                return data;
            }
        }).then(response => {
            this.data = response.data,
            this.loaded = true;

            var labelx = response.data.map(function(e) {
                return e.lastUpdatedAtApify;
            });

            var total_cases = response.data.map(function(e) {
                return e.infected;
            });

            var recovered_cases = response.data.map(function(e) {
                return e.recovered;
            });

            this.chartdata = {
                labels: labelx,
                datasets: [
                    {
                        label: 'Total Cases',
                        data: total_cases,
                        fill: true,
                        tension: 0.1,
                        borderColor: '#17A2B8',
                        backgroundColor: '#CDEBFF',
                        borderWidth: 1
                    },
                    
                    {
                        label: 'Recovered',
                        data: recovered_cases,
                        fill: false,
                        //tension: 0.1,
                        borderColor: '#24963E',
                        backgroundColor: '#D3F5F5',
                        borderWidth: 1
                    },
                    xxx
                    {
                        label: 'Recovered',
                        data: [
                            response.data.recovered,
                            ],
                        fill: false,
                        //tension: 0.1,
                        borderColor: '#E5AD06',
                        backgroundColor: '#FFF5DD',
                        borderWidth: 2
                    },
                    {
                        label: 'Deseased',
                        data: [
                            response.data.deceased,
                            ],
                        fill: false,
                        //tension: 0.1,
                        borderColor: '#DC3545',
                        backgroundColor: '#FFD8E1',
                        borderWidth: 2
                    },
                    xxx
                ]
            };
            this.options = {
                scales: {
                yAxes: [{
                    ticks: {
                    beginAtZero: true
                    },
                    gridLines: {
                    display: true,
                    }
                }],
                xAxes: [ {
                    gridLines: {
                    display: false
                    }
                }]
                },
                legend: {
                display: true
                },
                responsive: true,
                maintainAspectRatio: false
            };

            this.loading = false;

            }).catch(err => {
                console.log(err);
            });

            */


        this.loading = false;

    }
}
</script>