<template>
  <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card card-secondary card-outline">
            <div class="card-header">
              <h3 class="card-title"><i class="fas fa-edit"></i> Purchase Request for {{ this.office_name }}</h3>

              <div class="card-tools">
                <!--
                <span v-if="(this.ppmpdetails.status == 0)" class="badge badge-info"><i>Status:</i> <b>In-Preparation</b></span>
                <span v-else-if="(this.ppmpdetails.status == 1)" class="badge badge-warning"><i>Status:</i> <b>Revision</b></span>
                <span v-else-if="(this.ppmpdetails.status == 2)" class="badge badge-primary"><i>Status:</i> <b>Pending</b></span>
                <span v-else-if="(this.ppmpdetails.status == 3)" class="badge badge-success"><i>Status:</i> <b>Approved</b></span>
                <span v-if="ppmpdetails.ppmpitems" class="badge badge-secondary ml-1 mr-1"><i>No. of Items:</i> <b>{{ ppmpdetails.ppmpitems.length }}</b></span>
                <span v-if="ppmpdetails.ppmpitems" class="badge badge-secondary"><i>Total Amount:</i> <b>{{ this.total_expenses | toMoney }}</b></span>
                -->
              </div>
            </div>
            <!-- /.card-header -->
            <div class="row m-0 bg-secondary disabled color-palette">
                <div class="col-md-2">
                  <div class="btn-group mt-2 mb-2 d-flex justify-content-start">
                      <button type="button" v-show="can_add" class="btn btn-success" @click="newModal()"><i class="fa fa-plus"></i> CREATE NEW PR</button>
                  </div>
                </div>
                <div class="col-md-8 mt-2 mb-2">

                </div>
                <div class="col-md-2">
                  <div class="input-group input-group-md mt-2 mb-2 d-flex justify-content-end">
                    <div class="input-group-prepend">
                      <span class="input-group-text">CALENDAR YEAR</span>
                    </div>
                    <select name="type" @change="loadPrs(officeid, year)" v-model="year" class="form-control">
                        <option v-for="year in years" :value="year" :key="year">{{ year }}</option>
                    </select>
                  </div>
                </div>

            </div>

            <div class="card-body table-responsive p-0 m-0">
              <table class="table responsive-table table-sm table-striped">
                <thead>
                    <tr>
                    <th scope="col" class="pl-2">Date Created</th>
                    <th scope="col">PR No.</th>
                    <th scope="col">Description</th>
                    <th scope="col">Total Amount</th>
                    <th scope="col">Status</th>
                    <th scope="col">PR Start Date</th>
                    <th scope="col">Remarks</th>
                    <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="pr in prs" :key="pr.id">
                        <td data-title="Date">{{ pr.prdate }}</td>
                        <td data-title="PR No."><a style="cursor: pointer;" class="text-primary font-weight-bold" @click="editModal(pr)">{{ pr.prno }}</a></td>
                        <td data-title="Description"><small>{{ pr.desc }}</small></td>
                        <td data-title="Total Amount">{{ totalItem(pr.procprojectitems) | toMoney }}</td>
                        <td data-title="Status">
                          <span v-if="pr.status == 0" class="badge badge-info">In-Preparation</span>
                          <span v-else-if="pr.status == 1" class="badge badge-warning">Revision</span>
                          <span v-else-if="pr.status == 2" class="badge badge-primary">Pending</span>
                          <span v-else-if="pr.status == 3" class="badge badge-success">Approved</span>
                        </td>
                        <td data-title="Date Posted">{{ pr.postdate }}</td>
                        <td data-title="Remarks">{{ pr.remarks  }}</td>
                        <td data-title="" v-if="can_edit">
                          <div class="btn-group">
                            <button v-if="pr.status == 3" type="button" class="btn btn-default" @click="editModal(pr)" title="View"><i class="fas fa-eye text-primary"></i></button>
                            <button v-else type="button" class="btn btn-default" @click="editModal(pr)"><i class="fas fa-edit text-primary" title="Edit"></i></button>
                            <button v-if="pr.status != 3 && can_delete" type="button" class="btn btn-default" @click="deleteAction(pr.id)" title="Delete"><i class="fas fa-times text-danger"></i></button>
                            <button v-else type="button" class="btn btn-default" @click="deleteAction(pr.id)" title="Disabled: Status Approved" disabled><i class="fas fa-times text-danger"></i></button>
                          </div>
                        </td>
                        <td data-title="" v-else>
                          <button disabled type="button" class="btn btn-default"><i class="fas fa-edit text-primary"></i></button>
                          <button disabled type="button" class="btn btn-default"><i class="fas fa-times text-danger"></i></button>
                        </td>
                    </tr>
                </tbody>
                <tbody v-if="prs" v-show="prs.length==0">
                    <tr><td colspan="21">*** NO ITEMS TO DISPLAY ***</td></tr>
                </tbody>
              </table>
            </div>
            <!-- /.card-body <pagination :data="ppmpitems" @pagination-change-page="getResults" show-disabled :limit="2"></pagination> -->
            <div class="card-footer">
              <div class="card-tools d-flex justify-content-end">
                <!--
                <span v-if="(this.ppmpdetails.status == 0)" class="badge badge-info"><i>Status:</i> <b>In-Preparation</b></span>
                <span v-else-if="(this.ppmpdetails.status == 1)" class="badge badge-warning"><i>Status:</i> <b>Revision</b></span>
                <span v-else-if="(this.ppmpdetails.status == 2)" class="badge badge-primary"><i>Status:</i> <b>Pending</b></span>
                <span v-else-if="(this.ppmpdetails.status == 3)" class="badge badge-success"><i>Status:</i> <b>Approved</b></span>
                <span v-if="ppmpdetails.ppmpitems" class="badge badge-secondary ml-1 mr-1"><i>No. of Items:</i> <b>{{ ppmpdetails.ppmpitems.length }}</b></span>
                <span v-if="ppmpdetails.ppmpitems" class="badge badge-secondary"><i>Total Amount:</i> <b>{{ this.total_expenses | toMoney }}</b></span>
                -->
              </div>
            </div>
          </div>
          <!-- /.card -->
        </div>
      </div>

      <!-- Modal -->
      <div class="modal fade" id="actionmodal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="actionModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title text-dark" id="actionModalLabel">Purchase Request No. {{ form.prno }}
                <span v-if="form.status == 0" class="badge badge-info" style="padding-bottom:10px !important;">In-Preparation</span>
                <span v-else-if="form.status == 1" class="badge badge-warning" style="padding-bottom:10px !important;">Revision</span>
                <span v-else-if="form.status == 2" class="badge badge-primary" style="padding-bottom:10px !important;">Pending</span>
                <span v-else-if="form.status == 3" class="badge badge-success" style="padding-bottom:10px !important;">Approved</span>
              </h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div class="modal-body">
              <div class="row">

                <div class="col-sm-3">
                  <div class="form-group">
                    <label>PR No. (Managed by Procurement)</label>
                    <input type="text" class="form-control" v-model="form.prno" :disabled="!can_manage || form.status == 3" :class="{ 'is-invalid': form.errors.has('prno') }">
                    <has-error :form="form" field="prno"></has-error>
                  </div>
                  <div class="form-group">
                    <label>Date Created</label>
                    <input type="date" id="prdate-date-input" class="form-control" v-model="form.prdate" :disabled="!can_manage || form.status == 3" :class="{ 'is-invalid': form.errors.has('prdate') }">
                    <has-error :form="form" field="prdate"></has-error>
                  </div>
                  <div class="form-group">
                    <label>PR Start Date (Managed by Procurement)</label>
                    <input type="date" id="postdate-date-input" class="form-control" v-model="form.postdate" :disabled="!can_manage || form.status == 3" :class="{ 'is-invalid': form.errors.has('postdate') }">
                    <has-error :form="form" field="postdate"></has-error>
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="form-group">
                    <label>Description</label>
                    <input type="text" class="form-control" v-model="form.desc" :disabled="form.status == 3" :class="{ 'is-invalid': form.errors.has('desc') }">
                    <has-error :form="form" field="desc"></has-error>
                  </div>
                  <div class="form-group">
                    <label>Charging Fund</label>
                    <input type="hidden" id="postdate-date-input" class="form-control" v-model="form.postdate" :disabled="!can_manage || form.status == 3">
                    <select class="form-control" v-model="form.chargingfund" :disabled="form.status == 3" :class="{ 'is-invalid': form.errors.has('chargingfund') }">
                      <option value=""> - Select Class - </option>
                      <option value="Regular Procurement">Regular Procurement</option>
                      <option value="SDO">SDO</option>
                      <option value="Reimbursement">Reimbursement</option>
                      <option value="Cash Advance">Cash Advance</option>
                    </select>
                    <has-error :form="form" field="chargingfund"></has-error>
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="form-group">
                    <label>Purpose</label>
                    <textarea class="form-control" rows="4" data-gramm="false" wt-ignore-input="true" v-model="form.purpose" :disabled="form.status == 3" :class="{ 'is-invalid': form.errors.has('purpose') }"></textarea>
                    <has-error :form="form" field="purpose"></has-error>
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="form-group">
                    <label>Fund Source</label>
                    <select v-model="form.srcid" name="type" class="form-control" :disabled="form.status == 3" :class="{ 'is-invalid': form.errors.has('srcid') }">
                      <option v-for="(src,idx) in fundsources" :value="src.id" :key="idx">{{ src.alias }}</option>
                    </select>
                    <has-error :form="form" field="srcid"></has-error>
                  </div>
                  <div class="form-group">
                    <label>Allotment Class</label>
                    <select class="form-control" v-model="form.fundclass" :disabled="form.status == 3" :class="{ 'is-invalid': form.errors.has('fundclass') }">
                      <option value=""> - Select Class - </option>
                      <option value="MOOE">MOOE</option>
                      <option value="CO">CO</option>
                    </select>
                    <has-error :form="form" field="fundclass"></has-error>
                  </div>
                </div>

              </div>

              <div class="p-1">
                <div class="row">
                  <div class="col-md-12">
                    <div class="d-flex justify-content-end">
                      <button v-if="can_edit" class="btn btn-primary mr-1" :disabled="form.status == 3" data-container="body" type="button" @click="savePrDetails()"><i class="fa fa-save"></i> Save Details</button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row p-1">
                <table class="table table-striped m-0">
                  <thead class="bg-silver">
                    <tr>
                      <th>Item Description</th>
                      <th>Unit</th>
                      <th>Remaining Items</th>
                      <th>PR Quantity</th>
                      <th>Estimated Cost</th>
                      <th>Estimated Total Cost</th>
                      <th style="width: 130px;"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-show="pritems.length === 0"><td colspan="7" class="text-center">*** NO ITEMS FOUND ***</td></tr>
                    <tr v-for="itm in pritems" :key="itm.id">
                      <td v-if="itm.ppmpitems">
                        {{ itm.ppmpitems.description }}<br/><br/>
                        <span  v-if="edititemmode && itm.id == form_item.id" >
                          Specs:
                          <textarea rows="7" data-gramm="false" wt-ignore-input="true" name="content" v-model="form_item.item_details" class="form-control"></textarea>
                        </span>
                        <span v-else v-show="itm.item_details" style="white-space: pre-line">
                          <i>Specs:<br/>{{ itm.item_details }}</i>
                        </span>
                      </td>
                      <td v-else>?</td>
                      <td v-if="itm.ppmpitems">{{ itm.ppmpitems.uom_name }}</td><td v-else>?</td>
                      <td :class="{ 'text-danger': itm.ppmpitems.remaining == 0 }">{{ parseInt(itm.ppmpitems.remaining) }}</td>
                      <td v-if="edititemmode && itm.id == form_item.id">
                        <div class="btn-group">
                          <button type="button" @click="decrement()" class="btn btn-danger" :disabled="form_item.quantity <= 1"><i class="fa fa-minus"></i></button>
                          <input readonly type="number" maxlength="3" :class="{ 'is-invalid': form_item.quantity < 1 || form_item.quantity > (parseInt(itm.ppmpitems.remaining) + parseInt(itm.quantity)) }" @input="() => { if(value > parseInt(itm.ppmpitems.remaining) + parseInt(itm.quantity) || value < 0) { value = parseInt(itm.ppmpitems.remaining) + parseInt(itm.quantity) }}" min="1" :max="parseInt(itm.ppmpitems.remaining) + parseInt(itm.quantity)" class="form-control" v-model="form_item.quantity" />
                          <button type="button" @click="increment()" class="btn btn-success" :disabled="form_item.quantity >= (parseInt(itm.ppmpitems.remaining) + parseInt(itm.quantity))"><i class="fa fa-plus"></i></button>
                        </div>


                      </td>
                      <td v-else>{{ itm.quantity }}</td>
                      <td v-if="edititemmode && itm.id == form_item.id"><input type="number" step=".01" class="form-control" v-model="form_item.cost" /></td><td v-else>{{ itm.cost }}</td>
                      <td>{{ itm.quantity * itm.cost | toMoney }}</td>
                      <td>
                        <button title="Save" v-if="edititemmode && itm.id === form_item.id" @click="saveItem()" type="button" class="btn btn-default" :disabled="form_item.quantity < 1 || form_item.quantity > (parseInt(itm.ppmpitems.remaining) + parseInt(itm.quantity))"><i class="fa fa-save text-primary"></i></button>
                        <button title="Cancel" v-if="edititemmode && itm.id === form_item.id" @click="cancelItem()" type="button" class="btn btn-default"><i class="fa fa-ban"></i></button>
                        <button title="Edit" v-else @click="enableEdit(itm)" :disabled="form.status == 3" type="button" class="btn btn-default"><i class="fa fa-edit"></i></button>
                        <button title="Delete" @click="deleteItem(itm.id)" :disabled="form.status == 3" type="button" class="btn btn-default"><i class="fa fa-trash"></i></button>
                      </td>
                    </tr>
                    <tr><td colspan="5" class="text-right"><b>Total: </b></td><td colspan="2"><b>{{ getTotalAmount() | toMoney }}</b></td></tr>
                  </tbody>
                </table>
              </div>

              <div class="p-1">
                <div class="row">
                  <div class="col-md-12">
                    <div class="d-flex justify-content-end">
                      <button v-if="can_edit" class="btn btn-primary mr-1" data-container="body" type="button" @click="actionAddItem(form.id)" :disabled="form.status == 3"><i class="fa fa-plus"></i> Add Item(s)</button>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="card-footer">
              <div class="row">
                <div class="col-md-4">
                  <div class="d-flex justify-content-end">
                    <div class="input-group input-group-md d-flex justify-content-end" v-if="form.status === 3">
                      <div class="input-group-prepend">
                        <button class="btn btn-success" :disabled="!this.signatory" data-container="body" type="button" @click="printModal()"><i class="fa fa-print"></i> Print Preview</button>
                        <!-- <a class="btn btn-success" :href="'/print-pr?pr=' + form.id" target="_blank">Print PR {{ form.id }}</a> -->
                      </div>
                      <selectize @input="assignSignatory" class="form-control" v-model="signatory" placeholder="- Select Approved by -" :settings="settings">
                        <option v-for="sg in signatories" :key="sg.id" :value="sg.id">{{ sg.user.lname }}, {{ sg.user.fname }} {{ sg.user.mname | midname }}</option>
                      </selectize>
                    </div>
                    <div class="input-group input-group-md d-flex justify-content-end" v-else>
                      <span class="text-danger"><b>*PR must be approved to enable printing</b></span>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="d-flex justify-content-start">

                  </div>
                </div>
                <div class="col-md-4">
                  <div class="d-flex justify-content-end">
                    <button type="button" v-show="can_add && form.status == 1 || form.status == 0" class="btn btn-success mr-1" @click="changeStatus(2, 'Submitting finalizes PR and will disabled editing of data. Do you really want to submit PR for procurement review?')"><i class="fa fa-paper-plane"></i> Submit PR</button>
                    <button type="button" v-show="can_add && form.status == 2"  class="btn btn-warning mr-1" @click="changeStatus(1, 'This will change the status of the PR to Revise. Do you really want to proceed?')"><i class="fa fa-undo"></i> Revise PR</button>

                    <button class="btn btn-danger" data-container="body" type="button" data-dismiss="modal" aria-label="Close"><i class="fa fa-times"></i> Close</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" id="newactionmodal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="newactionModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
          <div class="modal-content">
            <div class="modal-header bg-warning">
              <h5 class="modal-title text-dark" id="newactionModalLabel">Create New Purchase Request</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div class="modal-body">
              <div class="row">

                <div class="col-sm-3">
                  <div class="form-group">
                    <label>Date Created</label>
                    <input type="date" id="prdate-date-input" class="form-control" v-model="form.prdate" :class="{ 'is-invalid': form.errors.has('prdate') }">
                    <has-error :form="form" field="prdate"></has-error>
                  </div>
                  <div class="form-group">
                    <label>Description</label>
                    <input type="text" class="form-control" v-model="form.desc" :class="{ 'is-invalid': form.errors.has('desc') }">
                    <has-error :form="form" field="desc"></has-error>
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="form-group">
                    <label>Charging Fund</label>
                    <input type="hidden" id="postdate-date-input" class="form-control" v-model="form.postdate" :disabled="!can_manage">
                    <select class="form-control" v-model="form.chargingfund" :class="{ 'is-invalid': form.errors.has('chargingfund') }">
                      <option value=""> - Select Class - </option>
                      <option value="Regular Procurement">Regular Procurement</option>
                      <option value="SDO">SDO</option>
                      <option value="Reimbursement">Reimbursement</option>
                      <option value="Cash Advance">Cash Advance</option>
                    </select>
                    <has-error :form="form" field="chargingfund"></has-error>
                  </div>
                  <div class="form-group">
                    <label>Remarks</label>
                    <input type="text" class="form-control" v-model="form.remarks" :class="{ 'is-invalid': form.errors.has('remarks') }">
                    <has-error :form="form" field="remarks"></has-error>
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="form-group">
                    <label>Purpose</label>
                    <textarea class="form-control" rows="4" data-gramm="false" wt-ignore-input="true" v-model="form.purpose" :class="{ 'is-invalid': form.errors.has('purpose') }" />
                    <has-error :form="form" field="purpose"></has-error>
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="form-group">
                    <label>Fund Source</label>
                    <select v-model="form.srcid" name="type" class="form-control" :class="{ 'is-invalid': form.errors.has('srcid') }">
                      <option value="">- Select Source -</option>
                      <option v-for="src in fundsources" :key="src.id" v-bind:value="src.id" >{{ src.alias }}</option>
                    </select>
                    <has-error :form="form" field="srcid"></has-error>
                  </div>
                  <div class="form-group">
                    <label>Allotment Class</label>
                    <select class="form-control" v-model="form.fundclass" :class="{ 'is-invalid': form.errors.has('fundclass') }">
                      <option value=""> - Select Class - </option>
                      <option value="MOOE">MOOE</option>
                      <option value="CO">CO</option>
                    </select>
                    <has-error :form="form" field="fundclass"></has-error>
                  </div>
                </div>

              </div>

            </div>
            <div class="card-footer">
              <div class="row">
                <div class="col-md-4">
                  <div class="d-flex justify-content-end">

                  </div>
                </div>
                <div class="col-md-5">
                  <div class="d-flex justify-content-start">

                  </div>
                </div>
                <div class="col-md-3">
                  <div class="d-flex justify-content-end">
                    <button v-if="can_edit" class="btn btn-primary mr-1" data-container="body" type="button" @click="saveNewPrDetails()"><i class="fa fa-save"></i> Save</button>
                    <button class="btn btn-danger" data-container="body" type="button" data-dismiss="modal" aria-label="Close"><i class="fa fa-times"></i> Close</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" id="printmodal" tabindex="-1" role="dialog" aria-labelledby="printModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
          <div class="modal-content">
            <div class="modal-header bg-success">
              <h5 class="modal-title text-dark" id="printModalLabel">Purchase Request (Print Preview)</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>

            <div class="modal-body">
              <div id="printAppendix60">
                <div class="card-body" style="font-family: Times New Roman;">

                  <table class="table table-borderless customtextsize">
                    <tr>
                      <td colspan="8" align="right">
                          <i style="font-size: 17px">Appendix 60</i>
                      </td>
                    </tr>
                  </table>
                  <br/>
                  <table class="table table-borderless customtextsize">
                    <tbody>
                      <tr>
                        <td colspan="2" align="center"><h2>PURCHASE REQUEST</h2></td>
                      </tr>
                      <tr>
                        <td>
                          <b>Entity Name : <u>Technology Application & Promotion Institute</u></b>
                        </td>
                        <td align="right">
                          <b>Fund Cluster : <u v-if="fundsources.length">{{ getSourceAliasById(form.srcid) }}</u></b>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <table class="table table-borderless customtextsize" style="border:2px solid #000000">
                    <tbody>
                      <tr>
                        <td style="border:2px solid #000000" colspan="2" align="left"><b>Office/Section:</b> {{ this.office_name }}</td>
                        <td style="border:2px solid #000000" colspan="2" align="left"><b>PR No.:</b>  ________________________<br/><b>Responsibility Center Code:</b> ________________________ </td>
                        <td style="border:2px solid #000000" colspan="2" align="left"><b>Date: ________________________</b></td>
                      </tr>
                    </tbody>
                  </table>
                  <table class="table table-borderless customtextsize" style="border:2px solid #000000">
                    <thead>

                    </thead>
                    <tbody>
                      <tr>
                        <td style="border:2px solid #000000;width: 130px;" align="center"><b>Stock/Property No.</b></td>
                        <td style="border:2px solid #000000" align="center"><b>Unit</b></td>
                        <td style="border:2px solid #000000;width: 400px;" align="center"><b>Item Description</b></td>
                        <td style="border:2px solid #000000" align="center"><b>Quantity</b></td>
                        <td style="border:2px solid #000000" align="center"><b>Unit Cost</b></td>
                        <td style="border:2px solid #000000" align="center"><b>Total Cost</b></td>
                      </tr>
                      <tr v-for="itm in pritems" :key="itm.id">
                        <td style="border:2px solid #000000" align="center"></td>
                        <td style="border:2px solid #000000" align="center">{{ itm.ppmpitems.uom_name }}</td>
                        <td style="border:2px solid #000000" align="left">
                          {{ itm.ppmpitems.description }}
                          <span v-show="itm.item_details" style="white-space: pre-line">
                            <br/><i>{{ itm.item_details }}</i>
                          </span>
                        </td>
                        <td style="border:2px solid #000000" align="center">{{ itm.quantity }}</td>
                        <td style="border:2px solid #000000" align="right">{{ itm.cost | toMoney }}</td>
                        <td style="border:2px solid #000000" align="right">{{ itm.quantity * itm.cost | toMoney }}</td>
                      </tr>
                      <tr class="lastchild">
                        <td colspan="6" style="border:2px solid #000000" align="center">*** NOTHING FOLLOWS ***</td>
                      </tr>
                      <tr class="footer">
                        <td style="border:2px solid #000000" align="center"></td>
                        <td style="border:2px solid #000000" align="center"></td>
                        <td style="border:2px solid #000000" align="center"></td>
                        <td style="border:2px solid #000000" align="center"></td>
                        <td style="border:2px solid #000000" align="right">TOTAL</td>
                        <td v-if="!this.pritems" style="border:2px solid #000000" align="right"></td><td v-else style="border:2px solid #000000" align="right">{{ getTotalAmount() | toMoney }}</td>
                      </tr>
                      <tr style="height: 100px !important">
                        <td colspan="6" style="border:2px solid #000000; vertical-align: top;" align="left">Purpose: {{ form.purpose }}</td>
                      </tr>
                    </tbody>
                    <tbody class="keep-together">
                      <tr>
                        <td style="border:2px solid #000000" align="left"></td>
                        <td colspan="2" style="border:2px solid #000000" align="left">Requested By:</td>
                        <td colspan="3" style="border:2px solid #000000" align="left">Approved By:</td>
                      </tr>
                      <tr>
                        <td style="border:2px solid #000000" align="left">Signature</td>
                        <td colspan="2" style="border:2px solid #000000" align="left"></td>
                        <td colspan="3" style="border:2px solid #000000" align="left"></td>
                      </tr>
                      <tr>
                        <td style="border:2px solid #000000" align="left">Printed Name</td>
                        <td colspan="2" style="border:2px solid #000000" align="center"><b>{{ this.$gate.showFname() }} {{ this.$gate.showMname() | midname }} {{ this.$gate.showLname() }}</b></td>
                        <td v-if="this.signatory_details" colspan="3" style="border:2px solid #000000" align="center"><b>{{ this.signatory_details.user.fname }} {{ this.signatory_details.user.mname | midname }} {{ this.signatory_details.user.lname }}</b></td>
                        <td v-else colspan="3" style="border:2px solid #000000" align="center"><b></b></td>
                      </tr>
                      <tr>
                        <td style="border:2px solid #000000" align="left">Designation</td>
                        <td colspan="2" style="border:2px solid #000000" align="center"><small>{{ this.$gate.showPosition() }}, {{ this.office_name }}</small></td>
                        <td v-if="this.signatory_details" colspan="3" style="border:2px solid #000000" align="center"><small>{{ this.signatory_details.user.position }}, {{ this.signatory_details.office.alias }}</small></td>
                        <td v-else colspan="3" style="border:2px solid #000000" align="center"></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div id="print-foot">(PROCMS REF#{{ form.prno }})&nbsp;</div>
              </div>

            </div>
            <div class="card-footer">
              <button v-print="'#printAppendix60'" @click="print" class="btn btn-primary" data-container="body" type="button"><i class="fa fa-print"></i> Print</button>
              <button class="btn btn-primary" data-container="body" type="button" data-dismiss="modal" aria-label="Close"><i class="fa fa-times"></i> Close</button>
            </div>
          </div>
        </div>
      </div>

      <div class="modal fade" id="actionadditemmodal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="actionadditemModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
          <div class="modal-content">
            <div class="modal-header bg-warning">
              <h5 class="modal-title" id="actionadditemModalLabel">Select Procurement Item from Approved PPMPs</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body" style="background-color:#FFFDD0">
              <div class="row m-0 bg-secondary disabled color-palette sticky-top">
                <div class="col-md-7">

                </div>
                <div class="col-md-3">
                  <div class="input-group input-group-sm mt-2 mb-2 d-flex justify-content-end">
                    <div class="input-group-prepend">
                      <span class="input-group-text">PPMP YEAR</span>
                    </div>
                    <select name="type" @change="loadPpmpProcItems(officeid, pr_items_year)" v-model="pr_items_year" class="form-control">
                        <option v-for="year in years" :value="year" :key="year">{{ year }}</option>
                    </select>
                  </div>
                </div>
                <div class="col-md-2">
                  <div class="input-group input-group-md mt-2 mb-2 d-flex justify-content-end">
                    <div class="input-group input-group-sm" style="width: 150px;">
                    <input type="text" name="table_search" class="form-control float-right" v-model="searchkey" placeholder="Filter" />
                    <div class="input-group-append">
                    <button v-if="!this.searchkey" type="submit" class="btn btn-default"><i class="fas fa-filter"></i></button>
                    <button v-else type="submit" @click="clearSearchkey()" class="btn btn-default"><i class="fas fa-undo"></i></button>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row m-0 table-responsive p-0" style="height: 300px;">
                <table class="table table-head-fixed text-nowrap">
              <thead>
                <tr style="background-color: #EEDC82">
                  <th style="background-color: #EEDC82"><i class="fa fa-check" aria-hidden="true"></i></th>
                  <th style="background-color: #EEDC82">DESCRIPTION</th>
                  <th style="background-color: #EEDC82">ITEM CODE</th>
                  <th style="background-color: #EEDC82">UNIT</th>
                  <th style="background-color: #EEDC82">PRICE</th>
                  <th style="background-color: #EEDC82">REMAINING</th>
                </tr>
              </thead>
              <tbody v-if="this.approvedppmpprocurementitems">
                <tr v-for="itms in tempApprovedPpmpProcurementitems" :key="itms.id">
                  <td><input v-show="itms.remaining != 0" v-bind:id="itms.id" type="checkbox" :value="itms" v-model="selected_items_form.selected_items" /></td>
                  <td :class="{ 'text-danger': itms.remaining == 0 }">{{ itms.description }}</td>
                  <td :class="{ 'text-danger': itms.remaining == 0 }">{{ itms.item_code }}</td>
                  <td :class="{ 'text-danger': itms.remaining == 0 }">{{ itms.uom_name }}</td>
                  <td :class="{ 'text-danger': itms.remaining == 0 }">{{ itms.unit_cost }}</td>
                  <!--<td :class="{ 'text-danger': itms.remaining == 0 }">{{ itms.tot_qty }}</td>-->
                  <!--<td :class="{ 'text-danger': itms.remaining == 0 }">{{ itms.used }}</td>-->
                  <td :class="{ 'text-danger': itms.remaining == 0 }">{{ itms.remaining }}</td>
                </tr>

              </tbody>
              <tbody v-else>
                <tr class="text-center">
                  <td colspan="7">*** NO ITEMS TO DISPLAY ***</td>
                </tr>
              </tbody>
              </table>
              </div>
            </div>
            <div class="card-footer" style="background-color:#FFFDD0">
              <button class="btn btn-primary" v-if="selected_items_form.selected_items.length !== 0" data-container="body" type="button" @click="addToFormSelectedItems()"><i class="fa fa-save"></i> Add Selected Items</button>
              <button class="btn btn-primary" v-else data-container="body" type="button" disabled><i class="fa fa-save"></i> Add Selected Items</button>
              <button class="btn btn-danger" data-container="body" type="button" data-dismiss="modal" aria-label="Close"><i class="fa fa-times"></i> Close</button>
            </div>
          </div>
        </div>
      </div>




    </div>

</template>

<script>

  import Form from 'vform';
  import { VueEditor } from "vue2-editor";
  import moment from 'moment';

  export default {
      name: 'PurchaseRequest',

      components: {
          VueEditor,
      },

      data() {
        return {
          timer: 30,
          searchkey: '',
          edititemmode: false,
          editmode: false,
          loading: false,
          emptyflag: false,
          can_access: false,
          can_add: false,
          can_edit: false,
          can_delete: false,
          can_manage: false,
          prs: {},
          pritems: {},
          pritemx: [],
          procurementitems: {},
          approvedppmpprocurementitems: {},
          fundsources: {},
          settings: {},



          year: new Date().getFullYear(),
          pr_items_year: new Date().getFullYear(),
          officeid: 0,
          userlevelid: 0,
          total_expenses: 0,
          role: {},
          office_name: '',
          signatories: {},
          signatory: 0,
          signatory_details: '',
          totalItemsAmount: 0.00,

          form_item: new Form({
            id: 0,
            quantity: 0,
            cost: 0.00,
            item_details: '',
          }),

          form: new Form({
            id: 0,
            prno: '',
            postdate: '',
            prdate: '',
            officeid: 0,
            desc: '',
            srcid: '',
            fundclass: '',
            fundsource: '',
            purpose: '',
            status: 0,
            remarks: '',
            chargingfund: '',
          }),

          selected_items_form: new Form({
            // ppmp_id: 0,
            purchaserequest_id: 0,
            selected_items: [],
          }),
          ppmp_form: new Form({
            year: 0,
            office_id: 0,
          }),
        }
      },

      props: [
        'todo'
      ],

      computed: {

        second() {
          return this.time / 1000;
        },

        tempApprovedPpmpProcurementitems() {
          let tempItems = this.approvedppmpprocurementitems.ppmpitems;
          // Process search input
          if (this.searchkey != '' && this.searchkey) {
            tempItems = tempItems.filter((item) => {
              return item.description.toUpperCase().includes(this.searchkey.toUpperCase())
            })
          }

          return tempItems;
        },

        isIdle() {
          const idlestat =  this.$store.state.idleVue.isIdle;
          if (idlestat == true) {
            $('#sessionmodal').modal('show');
            this.countDownTimer();
          } else {
            $('#sessionmodal').modal('hide');
          }
          return idlestat;
        },

        years() {
          const year = new Date().getFullYear()
          return Array.from({length: year - 2018}, (value, index) => 2019 + index)
        },

      },

      methods: {

        // print(){
        //       var style = document.createElement('style');
        //       style.innerHTML = "@page{size: A4 portrait !important;margin: 25mm !important;}" +
        //               " html{background-color: #FFFFFF;}" +
        //               " body{border: solid 0px #FFFFFF;margin: 0;}" +
        //               " tr:first-child {padding-top: 15mm;}";
        //       window.document.head.appendChild(style);
        //     },
        print() {
            var style = document.createElement('style');
            style.innerHTML = `
                @page {
                size: A4 portrait !important;
                margin: 15mm 10mm 15mm 10mm !important;
                }
                html, body {
                background-color: #FFFFFF;
                margin: 0;
                padding: 0;
                width: 100%;
                height: 100%;
                }
                body {
                font-size: 12px;
                line-height: 1.2;
                }
                table {
                width: 100%;
                border-collapse: collapse;
                page-break-inside: auto !important;
                }
                tr {
                page-break-inside: avoid !important;
                }
                /* Ensures last 4 rows move only when needed */
                .keep-together {
                page-break-inside: avoid !important;
                }
            `;

            window.document.head.appendChild(style);
        },

        getSourceAliasById(id) {
            const source = this.fundsources.find(src => src.id === id);
            return source ? source.alias : 'N/A';
        },

        increment() {
          this.form_item.quantity = parseInt(this.form_item.quantity) + 1;
        },

        decrement() {
          this.form_item.quantity = parseInt(this.form_item.quantity) - 1;
        },

        totalItem(item) {
          let sum = 0;
          for(let i = 0; i < item.length; i++){
            sum += (parseFloat(item[i].cost) * parseFloat(item[i].quantity));
          }

          return sum;
        },

        countDownTimer() {
          let timerId = setInterval(() => {
            this.timer -= 1;
            if (!this.$store.state.idleVue.isIdle) clearInterval(timerId);

            if (this.timer < 1) {
              clearInterval(timerId);
              document.getElementById('logout-form').submit();
            }
          }, 1000);

          this.timer = 30;
        },

        getTotalAmount() {

          return this.pritemx.reduce((accum, item) => {
                return accum + (parseFloat(item.cost) * parseFloat(item.quantity))
            }, 0.00)
        },

        clearSearchkey() {
          this.searchkey = '';
        },

        computeExpenses() {
            return this.ppmpdetails.ppmpitems.reduce((accum, item) => {
                return accum + parseFloat(item.tot_amount)
            }, 0.00)
          },

        newPpmpCy() {
          this.editmode = true;
          this.form.reset();
          this.form.clear();
          this.form.fill(payload);
          $('#actionmodal').modal('show');
        },

        updateItem() {
          this.$Progress.start();
          this.form.put('api/ppmpitems/'+this.form.id).then(()=>{
            Trigger.$emit('RefreshItems');
            $('#actionmodal').modal('hide')
            toastr.fire({
              icon: 'success',
              title: 'Item Saved'
            })
            this.$Progress.finish();
          }).catch(()=>{
            toastr.fire({
              icon: 'error',
              title: 'Item saving failed'
            });
            this.$Progress.fail();
          });
        },

        loadPpmpitemsYears() {

          if(!this.officeid) { //check if user is in role/signatory
            this.$router.push({ name: 'not-found' })
          } else {
            if(this.role.userlevelid !== 7) { //check if user is in PMO/End-User level
              this.$router.push({ name: 'not-found' })
            } else {
              axios.get('api/ppmps/years/' + this.officeid).then(({ data }) => (
                this.ppmpyears = data,
                this.year = data[0].year,
                this.loadPpmpitems(this.year)
              ));
            }
          }

        },

        loadFundSources() {
          axios.get('api/fundsources').then(({ data }) => (
            this.fundsources = data
          ));
        },

        changeStatus(status, txt) {
          swal.fire({
          title: 'Warning',
          text: txt,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes'
          }).then((result)=>{
            if (result.value) {
              axios.put('api/purchase-requests/status/'+ status + '/' + this.form.id).then(()=>{
                swal.fire(
                  'Confirmation',
                  'PR status has been updated.',
                  'success'
                );
                Trigger.$emit('RefreshData');
                $('#actionmodal').modal('hide');
              }).catch(()=>{
                swal.fire(
                  'Failed!',
                  'There was something wrong',
                  'warning'
                )
              });
            }
          })
        },


        loadPpmpitems(yr) {
          this.$Progress.start();

          axios.get('api/ppmps/' + this.officeid + '/' + yr).then(({ data }) => (
            this.ppmpitems = data.data[0].ppmpitems,
            this.ppmpdetails = data.data[0],
            // this.selected_items_form.ppmp_id = data.data[0].id,
            this.total_expenses = this.computeExpenses(data.data[0]),
            this.office_name = data.data[0].office.name
          ));

          this.$Progress.finish();
        },

        loadProcItems() {
          axios.get('api/procurementitems/all').then(({ data }) => (this.procurementitems = data));
        },

        actionExcel() {
          //
        },

        actionPrint() {
          //
        },

        actionDelete() {
          swal.fire({
          title: 'Are you sure?',
          text: "Deleting will permanently remove the item and all its respective data. Do you really want to delete PPMP CY " + this.year + "?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete PPMP!'
          }).then((result)=>{
            if (result.value) {
              this.ppmp_form.delete('api/ppmps/delete/'+ this.year).then(()=>{
                swal.fire(
                  'Deleted!',
                  'PPMP has been deleted.',
                  'success'
                );
                Trigger.$emit('RefreshData');
              }).catch(()=>{
                swal.fire(
                  'Failed!',
                  'There was something wrong',
                  'warning'
                )
              });
            }
          })
        },

        actionAdd() {
          const inputStep = 1;
          const inpulValue = parseInt(this.ppmpdetails.year) + 1;
          swal.fire({
            title: 'Create PPMP for Calendar Year:',
            html: `<input
                type="number"
                value="${inpulValue}"
                step="${inputStep}"
                class="swal2-input"
                id="sweettxt">`,
            inputAttributes: {
              autocapitalize: 'off',
              min: 2000,
              max: 3000,
              step: inputStep,
            },
            showCancelButton: true,
            confirmButtonText: 'Save',
            showLoaderOnConfirm: true,
            preConfirm: () => {

              this.ppmp_form.year = document.getElementById("sweettxt").value;

              if(!this.ppmpyears.some(data => data.year == document.getElementById("sweettxt").value)){
                  //save
                  this.$Progress.start();
                  this.ppmp_form.post('api/ppmps/' + document.getElementById("sweettxt").value)
                  .then(()=>{
                        this.ppmpyears.push({"year":document.getElementById("sweettxt").value})
                        this.year = document.getElementById("sweettxt").value;
                        this.loadPpmpitems(document.getElementById("sweettxt").value);
                        toastr.fire({
                          icon: 'success',
                          title: 'PPMP created successfully'
                        })
                        this.$Progress.finish();
                  }).catch(error => {
                    swal.showValidationMessage(
                      `Request failed: ${error}`
                    )
                  })
                  return "ok";
              }else{
                  swal.showValidationMessage(
                    `Request failed: PPMP for the given year already exist.`
                  )
              }
            },
            allowOutsideClick: () => !swal.isLoading()
          }).then((result) => {
            if (result.isConfirmed) {
              swal.fire({
                title: `${result.value.txt}'s avatar`,
                imageUrl: result.value.avatar_url
              })
            }
          })
        },

        deletePpmpItem(id) {
          swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, remove item from PPMP!'
          }).then((result)=>{
            if (result.value) {
              this.form.delete('api/ppmpitems/'+id).then(()=>{
                swal.fire(
                  'Deleted!',
                  'PPMP Item has been deleted.',
                  'success'
                );
                Trigger.$emit('RefreshData');
              }).catch(()=>{
                swal.fire(
                  'Failed!',
                  'There was something wrong',
                  'warning'
                )
              });
            }
          })
        },

        actionAddItem(pr_id) {
          this.loadPpmpProcItems(this.officeid, this.year)
          this.selected_items_form.purchaserequest_id = pr_id;
          this.selected_items_form.selected_items= [];
          $('#actionadditemmodal').modal('show');
        },

        addToFormSelectedItems() {

          this.$Progress.start();
            this.selected_items_form.post('api/procprojectitems')
            .then(()=>{
              this.loadPrItems(this.form.id)
              $('#actionadditemmodal').modal('hide')
              toastr.fire({
                icon: 'success',
                title: 'Procurement items added in PR'
              })
              this.$Progress.finish();
            }).catch(()=>{
              toastr.fire({
                icon: 'error',
                title: 'Procurement items adding failed'
              });
              this.$Progress.fail();
            })
        },


        assignSignatory() {
          this.signatory_details = this.signatories.find((sg) => sg.id == this.signatory);
        },


        editModal(payload) {
          this.editmode = true;
          this.form.reset();
          this.form.clear();
          this.form.fill(payload);
          this.loadPrItems(payload.id);
          $('#actionmodal').modal('show');
        },

        newModal() {
          this.editmode = false;
          this.form.reset();
          this.form.clear();
          this.form.officeid = this.officeid;
          this.form.prdate = moment(new Date()).format('YYYY-MM-DD');
          $('#newactionmodal').modal('show');
        },

        enableEdit(payload) {
          this.edititemmode = true;
          this.form_item.reset();
          this.form_item.clear();
          this.form_item.fill(payload);
        },

        cancelItem() {
          this.edititemmode = false;
          this.form_item.reset();
          this.form_item.clear();
        },

        deleteItem(id) {
          swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, remove item from PR!'
          }).then((result)=>{
            if (result.value) {
              this.form.delete('api/procprojectitems/'+id).then(()=>{
                swal.fire(
                  'Removed!',
                  'PR Item has been removed.',
                  'success'
                );
                this.loadPrItems(this.form.id)
              }).catch(()=>{
                swal.fire(
                  'Failed!',
                  'There was something wrong',
                  'warning'
                )
              });
            }
          })
        },

        saveItem() {
          this.$Progress.start();
          this.form_item.put('api/proc-project-items/'+this.form_item.id).then(()=>{
            Trigger.$emit('RefreshData');
            toastr.fire({
              icon: 'success',
              title: 'Item Updated'
            })
            this.loadPrItems(this.form.id)
            this.edititemmode = false;
            this.form_item.reset();
            this.form_item.clear();
            this.$Progress.finish();
          }).catch(()=>{
            toastr.fire({
              icon: 'error',
              title: 'Item updating failed'
            });
            this.$Progress.fail();
          });
        },

        savePrDetails() {
          this.$Progress.start();
          this.form.put('api/purchase-requests/'+this.form.id).then(()=>{
            Trigger.$emit('RefreshData');
            toastr.fire({
              icon: 'success',
              title: 'PR details Updated'
            })
            this.$Progress.finish();
          }).catch(()=>{
            toastr.fire({
              icon: 'error',
              title: 'PR details updating failed'
            });
            this.$Progress.fail();
          });
        },

        saveNewPrDetails() {
          this.$Progress.start();
          this.form.post('api/purchase-requests').then(()=>{
            Trigger.$emit('RefreshData');
            toastr.fire({
              icon: 'success',
              title: 'New PR Saved'
            })
            this.$Progress.finish();
            $('#newactionmodal').modal('hide')
          }).catch(()=>{
            toastr.fire({
              icon: 'error',
              title: 'New PR saving failed'
            });
            this.$Progress.fail();
          });
        },

        deleteAction(id) {
          swal.fire({
          title: 'Are you sure?',
          text: "Deleting will permanently remove the item and all its respective data. Do you really want to delete?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete PR!'
          }).then((result)=>{
            if (result.value) {
              this.ppmp_form.delete('api/purchase-requests/'+ id).then(()=>{
                swal.fire(
                  'Deleted!',
                  'PR has been deleted.',
                  'success'
                );
                Trigger.$emit('RefreshData');
              }).catch(()=>{
                swal.fire(
                  'Failed!',
                  'There was something wrong',
                  'warning'
                )
              });
            }
          })
        },

        printModal() {
          $('#printmodal').modal('show');
        },

        loadPrItems(prid) {
          axios.get('api/proc-project-items/pr/' + prid).then(({ data }) => (
            this.pritems = data,
            this.pritemx = data,
            this.getTotalAmount()
          ));
        },

        loadPrs(officeid, year) {
          axios.get('api/purchase-requests/office/' + officeid + '/' + year).then(({ data }) => (
            this.prs = data
          ));
        },

        loadOffice() {
          axios.get('api/procurement-role/user/' + this.$gate.showMyID()).then(({ data }) => (
            this.officeid = data.officeid,
            this.office_name = data.office.name,
            this.userlevelid = data.userlevelid,
            this.role = data,
            this.loadFundSources(),
            this.loadPrs(data.officeid, this.year),
            this.loadPpmpProcItems(data.officeid, this.year)
          ));
        },

        loadSignatories() {
          axios.get('api/procurement-role/level/17').then(({ data }) => (this.signatories = data ));
        },

        loadPpmpProcItems(officeid, year) {
          axios.get('api/ppmps/approved/' + officeid + '/' + year).then(({ data }) => (this.approvedppmpprocurementitems = data[0]));
        },

        async loadPermission(mid) {
          const response = await axios.get("api/permission/" + this.$gate.showMyID() + "/" + mid).then(
          res => {
            if (!res.data || res.data.length == 0) {
              return false;
            } else {
              return true;
            }
          })

          const getResponse = async () => {
            const a = await response;

            switch (mid) {
            case 54:
              this.can_access = a;
              if (!this.can_access) {
                this.$router.push({ name: 'not-found' }) //redirect to not found
              }
              break;
            case 55:
              this.can_add = a;
              this.can_edit = a;
              break;
            case 56:
              this.can_delete = a;
              break;
            case 57:
              this.can_manage = a;
            }
          };
          return getResponse()
        },



      },

      mounted() {
        //let pagedjs = document.createElement('script')
        //pagedjs.setAttribute('src', 'https://unpkg.com/pagedjs/dist/paged.polyfill.js')
        //document.head.appendChild(pagedjs)
      },

      created() {
        Trigger.$on('searching',() => {
          let query = this.$parent.search;
          let emptyflag = false;

          if(query == ''){
            Trigger.$emit('RefreshData');
          }else{
            this.$Progress.start();
            axios.get("api/findPpmpitems?q=" + query)
            .then((data) => {
              this.ppmpitems = data.ppmpitems;

              if (data.data.total == 0){
                this.emptyflag = true;
              }

              this.$Progress.finish();
            })
            .catch(() => {
              this.$Progress.fail();
            })
          }

        });

       // this.loadPpmpProcItems();
        this.loadSignatories();
        this.loadOffice();

        this.loadPermission(54); //Can Access PR
        this.loadPermission(55); //Can Add/Revise PR
        this.loadPermission(56); //Can Delete PR
        this.loadPermission(57); //Can Manange PR



        Trigger.$on('RefreshData',() => {
          this.loadOffice();
        });

        Trigger.$on('RefreshItems',() => {
          this.loadPrItems(this.year);
        });

      },

  }
</script>
<style scoped>
div >>> p {
font-size: 15px;
}

div >>> strong {
font-size: 15px;
}

div >>> em {
font-size: 15px;
}

div.header >>> p {
line-height: 0.5;
font-size: 20px;
font-family: "Helvetica Narrow","Arial Narrow";
}

div.header >>> strong {
line-height: 0.5;
font-size: 20px;
font-family: "Helvetica Narrow","Arial Narrow";
}

div.header >>> em {
line-height: 0.5;
font-size: 20px;
font-family: "Helvetica Narrow","Arial Narrow";
}

.text-selection-none{
 user-select: none; /* supported by Chrome and Opera */
 -webkit-user-select: none; /* Safari */
 -khtml-user-select: none; /* Konqueror HTML */
 -moz-user-select: none; /* Firefox */
 -ms-user-select: none;
}

.responsive-table tbody td:last-of-type {
    text-align: center;
}

.selectize-control {
    height: 0px !important;
}

table.customtextsize tr td {
  font-size: 17px;
  vertical-align: top !important;
}

b, strong {
    font-weight: bolder;
    font-size: 17px;
}

u {
    text-decoration: underline;
    font-size: 17px;
}

@page {
  size: A4 portrait !important;
  /*
  @bottom-left {
    content: 'Page ' counter(page) ' of ' counter(pages);
  }*/
}

#print-foot {
   display: block;
   position: fixed;
   bottom: 0pt;
   right: 20px;

  font-size: 80%;
}
/*
#print-foot:after {
	counter-increment: page;
    counter-reset: page;
    content: "Page " counter(page) " of " counter(pages);
}*/

@media print
{
  table { page-break-after:auto; }
  tr    { page-break-inside:avoid; page-break-after:auto }
  td    { page-break-inside:avoid; page-break-after:auto }
  thead { display:table-header-group }
  /*
  tbody:last-child {height:auto !important;}
  tfoot { display:table-footer-group; }
  */
}


</style>
