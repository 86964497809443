<template>
    <div class="">

          <!-- Covid19Chart-->


        <div class="row">

          <UserDivisionChart />
          <UserAppointmentChart />
          <UserGenderChart />
          <UserBirthdayMonth />
          <WebsiteMonitor />
        </div>

        <div class="row" v-for="stats in statistics"  v-bind:key="stats.usersCount">


          <!--
          <div class="col-md-2">
            <div class="info-box">
              <span class="info-box-icon bg-info"><i class="fas fa-users fa-2x"></i></span>
              <div class="info-box-content">
                <span class="info-box-number">{{ stats.usersCount }} Employees</span>
                <span class="info-box-text">{{ stats.usersInservice }} In-service</span>
                <span class="info-box-text">{{ stats.usersRetired }} Retired</span>
                <span class="info-box-text">{{ stats.usersEndo }} End of contract</span>
              </div>
            </div>
            <div v-show="can_cashier_dash" class="info-box">
              <span class="info-box-icon bg-green"><i class="fa fa-briefcase fa-2x"></i></span>
              <div class="info-box-content">
                <span class="info-box-number">{{ stats.banksCount }} Bank Accounts</span>
                <span class="info-box-text">{{ stats.banksSavings }} Savings Account</span>
                <span class="info-box-text">{{ stats.banksChecking }} Checking Account</span>
                <span class="info-box-text">&nbsp;</span>
              </div>
            </div>
          </div>
           /.col -->

          <!--
          <div class="col-md-2">
            <div v-show="can_cashier_dash" class="info-box">
              <span class="info-box-icon bg-red"><i class="fas fa-file-invoice-dollar fa-2x"></i></span>
              <div class="info-box-content">
                <span class="info-box-number">{{ stats.banksCount }} Loans</span>
                <span class="info-box-text">{{ stats.banksSavings }} Active</span>
                <span class="info-box-text">{{ stats.banksChecking }} Matured</span>
                <span class="info-box-text">&nbsp;</span>
              </div>
            </div>
            <div v-show="can_cashier_dash" class="info-box">
              <span class="info-box-icon bg-yellow"><i class="fas fa-file-invoice-dollar fa-2x"></i></span>
              <div class="info-box-content">
                <span class="info-box-number">{{ stats.paymentsCount }} Payments</span>
                <span class="info-box-text">&nbsp;</span>
                <span class="info-box-text">&nbsp;</span>
                <span class="info-box-text">&nbsp;</span>
              </div>
            </div>
          </div>
          /.col -->

        </div>



        <div class="modal fade" id="sessionmodal" tabindex="-1" role="dialog" aria-labelledby="sessionModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-md" role="document">
            <div class="modal-content">
              <div class="modal-header bg-orange">
                <h5 class="modal-title" id="sessionModalLabel"><i class="icon fas fa-exclamation-triangle"></i> Security Auto-logout</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
              <div class="row-aligncenter">
                <div id="printPayslip2">
                  <div class="card-body">
                    You have left this browser idle for 10 minutes. Due to our data privacy policy, you will be
                    automatically logged out in <b>{{ timer }}</b> seconds.
                  </div>
                </div>
              </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
        <span style="display:none;">{{ isIdle }}</span>



    </div>

</template>

<script>

    import Form from 'vform';
    import moment from 'moment';
    import UserDivisionChart from './charts/UserDivisionChart'
    import UserAppointmentChart from './charts/UserAppointmentChart'
    import UserGenderChart from './charts/UserGenderChart'
    import UserBirthdayMonth from './charts/UserBirthdayMonth'
    import WebsiteMonitor from './charts/WebsiteMonitor'
    import Covid19Chart from './charts/Covid19Chart.vue'

    export default {
      name: 'Dashboard',

      components: {
        UserDivisionChart, UserAppointmentChart, UserGenderChart, UserBirthdayMonth, WebsiteMonitor, Covid19Chart
      },

      data() {
        return {
            timer: 30,
            statistics : {},
            profile : {},
            can_cashier_dash: false,
            attendances: [{
                  in_am: '',
                  out_am: '',
                  in_pm: '',
                  out_pm: '',
              }],

            form: new Form({
              biometric_id: '',
              fin_am: '',
              fout_am: '',
              fin_pm: '',
              fout_pm: '',
            })
        }
      },

      methods: {

        countDownTimer() {
          let timerId = setInterval(() => {
            this.timer -= 1;
            if (!this.$store.state.idleVue.isIdle) clearInterval(timerId);

            if (this.timer < 1) {
              clearInterval(timerId);
              // Your logout function should be over here
              document.getElementById('logout-form').submit();
              //console.log("user logged out...")
            }
          }, 1000);

          this.timer = 30;
        },

         async loadPermission(mid) {
              const response = await axios.get("api/permission/" + this.$gate.showMyID() + "/" + mid).then(
              res => {
                if (!res.data || res.data.length == 0) {
                  return false;
                } else {
                  return true;
                }
              })

              const getResponse = async () => {
                const a = await response;

                switch (mid) {
                case 27:
                  this.can_cashier_dash = a;
                }
              };
              return getResponse()
          },

      },

      components: {

      },

      computed: {

        second() {
          return this.time / 1000;
        },

        isIdle() {
          const idlestat =  this.$store.state.idleVue.isIdle;
          if (idlestat == true) {
            $('#sessionmodal').modal('show');
            this.countDownTimer();
          } else {
            $('#sessionmodal').modal('hide');
          }

          return idlestat;

        },

        checktimerange() {
          const today = new Date();
          const curr_year = today.getFullYear();
          const curr_month = moment(String(today.getMonth() + 1)).format('MM');
          const curr_day = moment(String(today)).format('DD');
          const curr_hrs = moment(String(today)).format('HH');
          const curr_min = moment(String(today)).format('mm');
          const curr_sec = moment(String(today)).format('ss');
          const curr_date = curr_year + curr_month + curr_day;
          const curr_time = curr_year + curr_month + curr_day + curr_hrs + curr_min + curr_sec;
          var curr_range = "";
          //console.log(curr_year + curr_month + curr_day + curr_hrs + curr_min + curr_sec);
          //console.log(today);
          //console.log(curr_time);

          if ( parseInt(curr_time) >= parseInt(curr_date + '000000') && parseInt(curr_time) < parseInt(curr_date + '120000') ) {
            curr_range =  "AM";
          } else if ( parseInt(curr_time) >= parseInt(curr_date + '120000') && parseInt(curr_time) <= parseInt(curr_date + '130000') ) {
            curr_range = "NN";
          } else {
            curr_range = "PM";
          }

          return curr_range;
        },


      },

      created() {

        const today = new Date();
        const curr_year = today.getFullYear();
        const curr_month = moment(String(today.getMonth() + 1)).format('MM');
        const curr_day = moment(String(today)).format('DD');

        this.$Progress.start();
        axios.get("api/dashboard").then(({ data }) => (this.statistics = data));
        axios.get("api/users/" + this.$gate.showMyID() + "/attendances/" + curr_year + "/" + curr_month + "/"+ curr_day).then(({ data }) => (this.profile = data));
        axios.get("api/users/" + this.$gate.showMyID() + "/attendances/" + curr_year + "/" + curr_month + "/"+ curr_day).then(({ data }) => (this.attendances = data.attendances));
        this.loadPermission(27); //Can access cashier settings
        this.$Progress.finish();


      }
    }
</script>
