import { render, staticRenderFns } from "./Offices.vue?vue&type=template&id=b90c6612&scoped=true&"
import script from "./Offices.vue?vue&type=script&lang=js&"
export * from "./Offices.vue?vue&type=script&lang=js&"
import style0 from "./Offices.vue?vue&type=style&index=0&id=b90c6612&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b90c6612",
  null
  
)

export default component.exports