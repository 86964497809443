<template>
    <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card card-secondary card-outline">
              <div class="card-header bg-yellow">
                <h4 class="card-title"><i class="fas fa-file-alt"></i> Bank List</h4>

                <div class="card-tools">
                  <!--
                  <div class="input-group input-group-sm" style="width: 150px;">
                    <input type="text" name="table_search" class="form-control float-right" placeholder="Search">

                    <div class="input-group-append">
                      <button type="submit" class="btn btn-default"><i class="fas fa-search"></i></button>
                    </div>
                  </div>
                  -->

                  <div>
                    <button v-show="can_add" data-toggle="tooltip" data-container="body" title="Add New" class="btn btn-outline-secondary btn-sm" @click="newModal"><i class="fa fa-plus"></i></button>
                  </div>
                  
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">


                <b-table :items="banks.data" :fields="fields" striped responsive="sm" stacked="md">
                  <template #cell(_)="row">
                    <b-button variant="outline-default" size="sm" @click="row.toggleDetails" class="mr-2 btn btn-block">
                      <i v-if="row.detailsShowing" class='fa fa-chevron-up' />
                      <i v-else class='fa fa-chevron-down' />
                    </b-button>
                  </template>

                  <template #cell(account_no)="row">
                    <span v-if="row.item.account_status!='Active'" class="text-danger">{{ row.item.account_no }}</span>
                    <span v-else class="text-dark">{{ row.item.account_no }}</span>
                  </template>

                  <template #cell(bank_name)="row">
                    <span v-if="row.item.account_status!='Active'" class="text-danger">{{ row.item.bank_name }}</span>
                    <span v-else class="text-dark">{{ row.item.bank_name }}</span>
                  </template>

                  <template #cell(account_name)="row">
                    <span v-if="row.item.account_status!='Active'" class="text-danger">{{ row.item.account_name }}</span>
                    <span v-else class="text-dark">{{ row.item.account_name }}</span>
                  </template>

                  <template #row-details="row">
                    <b-card>
                      <b-row class="mb-2">
                        <b-col sm="3" class="text-sm-right"><b>Branch:</b></b-col>
                        <b-col>{{ row.item.bank_branch }}</b-col>
                      </b-row>

                      <b-row class="mb-2">
                        <b-col sm="3" class="text-sm-right"><b>Account Type:</b></b-col>
                        <b-col>{{ row.item.account_type }}</b-col>
                      </b-row>

                      <b-row class="mb-2">
                        <b-col sm="3" class="text-sm-right"><b>Status:</b></b-col>
                        <b-col v-if="row.item.account_status!='Active'">
                          <span  class="right badge badge-danger">{{ row.item.account_status }}</span>
                        </b-col>
                        <b-col v-else>
                          <span class="right badge badge-success">{{ row.item.account_status }}</span>
                        </b-col>
                      </b-row>

                      <b-button size="sm" variant="outline-default" class="btn btn-block" @click="row.toggleDetails"><i class="fa fa-chevron-up"></i> Hide Details</b-button>
                    </b-card>
                  </template>

                  <template #cell(options)="row">
                    <div class="btn-group btn-group-sm">
                      <button class="btn btn-outline-secondary" v-show="can_edit" vdata-toggle="tooltip" data-container="body" title="Edit" type="button" @click="editModal(row.item)" ><i class="fa fa-edit"></i></button>
                      <button class="btn btn-outline-secondary" v-show="can_delete" data-toggle="tooltip" data-container="body" title="Delete" type="button" @click="deleteBank(row.item.id)"><i class="fa fa-trash"></i></button>
                    </div>
                  </template>
                </b-table>
                <!--
                <table class="table table-hover table-striped">
                  <thead>
                    <tr>  
                      <th>Bank Name</th>
                      <th>Bank Branch</th>
                      <th>Account No</th>
                      <th>Account Name</th>
                      <th>Account Type</th>
                      <th>Account Status</th>
                      <th>Options</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="bnk in banks.data" :key="bnk.id">
                      <td>{{ bnk.bank_name }}</td>
                      <td>{{ bnk.bank_branch }}</td>
                      <td>{{ bnk.account_no }}</td>
                      <td>{{ bnk.account_name }}</td>
                      <td>{{ bnk.account_type }}</td>
                      <td>{{ bnk.account_status }}</td>
                      <td>
                        <div class="btn-group btn-group-sm">
                          <button class="btn btn-outline-secondary" v-show="can_edit" vdata-toggle="tooltip" data-container="body" title="Edit" type="button" @click="editModal(bnk)" ><i class="fa fa-edit"></i></button>
                          <button class="btn btn-outline-secondary" v-show="can_delete" data-toggle="tooltip" data-container="body" title="Delete" type="button" @click="deleteBank(bnk.id)"><i class="fa fa-trash"></i></button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-show="banks.total==0">
                      <tr><td colspan="10">No banks found.</td></tr>
                  </tbody>
                </table>
                -->
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                <pagination :data="banks" @pagination-change-page="getResults" show-disabled :limit="2"></pagination>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="bankmodal" tabindex="-1" role="dialog" aria-labelledby="bankModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 v-show="!editmode" class="modal-title" id="bankModalLabel">Create New Bank Information</h5>
                <h5 v-show="editmode" class="modal-title" id="bankModalLabel">Update Bank Information</h5> 
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <form @submit.prevent="editmode ? updateBank() : createBank()" @keydown="form.onKeydown($event)">

              <div class="modal-body">
              <div class="row">


                  <div class="col-md-6">
                      <!-- Your first column here -->
                      <div class="form-group">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">BANK NAME</span>
                          </div>
                          <input v-model="form.bank_name" type="text" name="bank_name" autocomplete="off" placeholder="BANK NAME.." class="form-control" :class="{ 'is-invalid': form.errors.has('bank_name') }">
                          <has-error :form="form" field="bank_name"></has-error>
                        </div>
                      </div>

                      <div class="form-group">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">ACCOUNT NUMBER</span>
                          </div>
                          <input v-model="form.account_no" type="text" name="account_no" autocomplete="off" placeholder="ACCOUNT NUMBER.." class="form-control" :class="{ 'is-invalid': form.errors.has('account_no') }">
                          <has-error :form="form" field="account_no"></has-error>
                        </div>
                      </div>

                      <div class="form-group">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">ACCOUNT TYPE</span>
                          </div>
                          <select v-model="form.account_type" name="type" class="form-control" placeholder="SELECT ACCOUNT TYPE.." :class="{ 'is-invalid': form.errors.has('account_type') }">
                            <option value=""></option>
                            <option value="Savings">Savings</option>
                            <option value="Checking">Checking</option>
                          </select>
                          <has-error :form="form" field="account_type"></has-error>
                        </div>
                      </div>

  
                  </div>




                  <div class="col-md-6">
                      <!-- Your second column here -->

                      <div class="form-group">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">BANK BRANCH</span>
                          </div>
                          <input v-model="form.bank_branch" type="text" name="bank_branch" autocomplete="off" placeholder="BANK BRANCH.." class="form-control" :class="{ 'is-invalid': form.errors.has('bank_branch') }">
                          <has-error :form="form" field="bank_branch"></has-error>
                        </div>
                      </div>

                      <div class="form-group">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">ACCOUNT NAME</span>
                          </div>
                          <input v-model="form.account_name" type="text" name="account_name" autocomplete="off" placeholder="ACCOUNT NAME.." class="form-control" :class="{ 'is-invalid': form.errors.has('account_name') }">
                          <has-error :form="form" field="account_name"></has-error>
                        </div>
                      </div>

                      <div class="form-group">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">ACCOUNT STATUS</span>
                          </div>
                          <select v-model="form.account_status" name="type" class="form-control" :class="{ 'is-invalid': form.errors.has('account_status') }">
                            <option selected disabled value="">SELECT ACCOUNT STATUS..</option>
                            <option value="Active">Active</option>
                            <option value="Inactive">Inactive</option>
                          </select>
                          <has-error :form="form" field="account_status"></has-error>
                        </div>
                      </div>

                  </div>


                  <!--
                  <div class="col-md-4">
                      //Your third column here
                  </div>
                  -->
     
          
              </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-outline-secondary" data-dismiss="modal"><i class="fa fa-times-circle"></i> CLOSE</button>
                <button v-show="editmode" type="submit" class="btn btn-outline-secondary"><i class="fa fa-save"></i> UPDATE</button>
                <button v-show="!editmode" type="submit" class="btn btn-outline-secondary"><i class="fa fa-save"></i> CREATE</button>
              </div>

              </form>

            </div>
          </div>
        </div>



      </div>

</template>

<script>

    import Form from 'vform';
    import Notifications from './Notifications'

    export default {

        components: {
          Notifications,
        },

        data() {
          return {
            timer: 30,
            editmode: false,
            emptyflag: false,
            can_access: false,
            can_add: false,
            can_edit: false,
            can_delete: false,
            banks : {},
            fields: ['_', 'account_no', 'bank_name', 'account_name', 'options'],
            form: new Form({
              id: '',
              bank_name: '',
              bank_branch: '',
              account_name: '',
              account_type: '',
              account_no: '',
              account_status: '',
            })
          }
        },

        computed: {
          
          second() {
            return this.time / 1000;
          },

          isIdle() {
            const idlestat =  this.$store.state.idleVue.isIdle;
            if (idlestat == true) {
              $('#sessionmodal').modal('show');
              this.countDownTimer();
            } else {
              $('#sessionmodal').modal('hide');
            }

            return idlestat;

          },

        },

        methods: {

          countDownTimer() {
            let timerId = setInterval(() => {
              this.timer -= 1;
              if (!this.$store.state.idleVue.isIdle) clearInterval(timerId);

              if (this.timer < 1) {
                clearInterval(timerId);
                document.getElementById('logout-form').submit();
              }
            }, 1000);

            this.timer = 30;
          },

          createBank() {
            this.$Progress.start();
            this.form.post('api/banks')
            .then(()=>{
              Trigger.$emit('RefreshData');
              $('#bankmodal').modal('hide')
              toastr.fire({
                icon: 'success',
                title: 'Bank information created successfully'
              })
              this.$Progress.finish();
            }).catch(()=>{
              toastr.fire({
                icon: 'error',
                title: 'Bank information creation failed'
              });
              this.$Progress.fail();
            })
          },

          updateBank() {
            this.$Progress.start();
            this.form.put('api/banks/'+this.form.id).then(()=>{
              Trigger.$emit('RefreshData');
              $('#bankmodal').modal('hide')
              toastr.fire({
                icon: 'success',
                title: 'Bank information updated successfully'
              })
              this.$Progress.finish();
            }).catch(()=>{
              toastr.fire({
                icon: 'error',
                title: 'Bank information updating failed'
              });
              this.$Progress.fail();
            });
          },

          deleteBank(id) {
            swal.fire({

              title: 'Are you sure?',
              text: "You won't be able to revert this!",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes, delete record!'
              }).then((result)=>{
                if (result.value) {
                  this.form.delete('api/banks/'+id).then(()=>{
                    swal.fire(
                      'Deleted!',
                      'Bank information has been deleted.',
                      'success'
                    );
                    Trigger.$emit('RefreshData');
                  }).catch(()=>{
                    swal.fire(
                      'Failed!', 
                      'There was something wrong', 
                      'warning'
                    )
                  });
                }
            })
          },

          editModal(bank) {
            this.editmode = true;
            this.form.reset();
            this.form.clear();
            $('#bankmodal').modal('show');
            this.form.fill(bank);
          },

          newModal() {
            this.editmode = false;
            this.form.reset();
            this.form.clear();
            $('#bankmodal').modal('show')
          },

          getResults(page = 1) {

            let query = this.$parent.search;

            if(query == ''){
              axios.get('api/banks?page=' + page).then(response => { this.banks = response.data; }); 
            }else{
              axios.get('api/findBank?q=' + query + '&page=' + page).then(response => { this.banks = response.data; }); 
            }

            console.log(query);

            
          },

          loadBanks() {
            this.$Progress.start();
            
            let query = this.$parent.search;
            let page = this.banks.current_page;
            
            if(query == ''){
              axios.get('api/banks?page=' + page).then(({ data }) => (this.banks = data));
            }else{
              axios.get('api/findBank?q=' + query + '&page=' + page).then(({ data }) => (this.banks = data));
            }

            this.$Progress.finish();
          },

          async loadPermission(mid) {
              const response = await axios.get("api/permission/" + this.$gate.showMyID() + "/" + mid).then( 
              res => {
                if (!res.data || res.data.length == 0) {
                  return false;
                } else {
                  return true;
                }
              })

              const getResponse = async () => {
                const a = await response;

                switch (mid) {
                case 19:
                  this.can_access = a;
                  if (!this.can_access) {
                    this.$router.push({ name: 'not-found' }) //redirect to not found
                  }
                  break;
                case 20:
                  this.can_add = a;
                  break;
                case 21:
                  this.can_edit = a;
                  break;
                case 22:
                  this.can_delete = a;
                }
              };
              return getResponse()
          },

          
        },

        created() {
          Trigger.$on('searching',() => {
            let query = this.$parent.search;
            let emptyflag = false;

            if(query == ''){
              Trigger.$emit('RefreshData');
            }else{
              this.$Progress.start();
              axios.get("api/findBank?q=" + query)
              .then((data) => {
                this.banks = data.data;
                
                if (data.data.total == 0){
                  this.emptyflag = true;
                }

                this.$Progress.finish();
              })
              .catch(() => {
                this.$Progress.fail();
              })
            }
            
          });

          //axios.get("api/banks").then(({ data }) => (this.banks = data));
          
          this.loadBanks();
          this.loadPermission(19); //Can access bank list
          this.loadPermission(20); //Can add new bank
          this.loadPermission(21); //Can edit bank details
          this.loadPermission(22); //Can delete bank
          
          Trigger.$on('RefreshData',() => {
            this.loadBanks();
          });
        },

    }
</script>
<style scoped>
.card-body.p-0 .table tbody>tr>td:first-of-type {
    padding-left: 0.5rem !important;
}

.card-body.p-0 .table thead>tr>th:first-of-type {
    padding-left: 0.5rem !important;
}
</style>
