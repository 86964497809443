<template>
    <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="card card-secondary card-outline" >
              <div class="card-header bg-yellow">
                <h3 class="card-title"><i class="fas fa-cogs"></i> Cashier Settings</h3>
              </div>
              <!-- /.card-header -->
              <form @submit.prevent="updateCashiersettings()" @keydown="form.onKeydown($event)">

              <div class="modal-body">
                <h2 class="mb-3">Signatories</h2>
              <div class="row">


                  <div class="col-md-6">
                      <!-- Your first column here 
                      <div class="form-group">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">FUND CLUSTER</span>
                          </div> 
                          <input v-model="form.fund_cluster" type="text" name="fund_cluster" :maxlength="9" autocomplete="off" placeholder="FUND CLUSTER.." class="form-control" :class="{ 'is-invalid': form.errors.has('fund_cluster') }">
                          <has-error :form="form" field="fund_cluster"></has-error>
                        </div>
                      </div>
                      -->

                      <div class="form-group">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">CHIEF ACCOUNTANT</span>
                          </div> 
                            <input v-model="form.chief_accountant" type="text" name="chief_accountant" :maxlength="40" autocomplete="off" placeholder="CHIEF ACCOUNTANT.." class="form-control" :class="{ 'is-invalid': form.errors.has('chief_accountant') }">
                            <has-error :form="form" field="chief_accountant"></has-error>
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">DESIGNATION</span>
                          </div>
                            <input v-model="form.chief_accountant_designation" type="text" name="chief_accountant_designation" :maxlength="40" autocomplete="off" placeholder="DESIGNATION / POSITION.." class="form-control" :class="{ 'is-invalid': form.errors.has('chief_accountant_designation') }">
                            <has-error :form="form" field="chief_accountant_designation"></has-error>
                        </div>
                      </div>

                      <br/>

                      <div class="form-group">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">DIVISION CHIEF</span>
                            </div>
                            <input v-model="form.division_chief" type="text" name="division_chief" :maxlength="40" autocomplete="off" placeholder="DIVISION CHIEF.." class="form-control" :class="{ 'is-invalid': form.errors.has('division_chief') }">
                            <has-error :form="form" field="division_chief"></has-error>
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">DESIGNATION</span>
                          </div>
                            <input v-model="form.division_chief_designation" type="text" name="division_chief_designation" :maxlength="40" autocomplete="off" placeholder="DESIGNATION / POSITION.." class="form-control" :class="{ 'is-invalid': form.errors.has('division_chief_designation') }">
                            <has-error :form="form" field="division_chief_designation"></has-error>
                        </div>
                      </div>

  
                  </div>




                  <div class="col-md-6">
                      <!-- Your second column here 

                      <div class="form-group">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">MDS SUB-ACCOUNT NO</span>
                          </div> 
                          <input v-model="form.mds_subacct" type="text" name="mds_subacct" :maxlength="15" autocomplete="off" placeholder="MDS SUB-ACCOUNT NO.." class="form-control" :class="{ 'is-invalid': form.errors.has('mds_subacct') }">
                          <has-error :form="form" field="mds_subacct"></has-error>
                        </div>
                      </div>
                      -->

                      <div class="form-group">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">HEAD CASHIER</span>
                          </div>
                            <input v-model="form.head_cashier" type="text" name="head_cashier" :maxlength="40" autocomplete="off" placeholder="HEAD CASHIER.." class="form-control" :class="{ 'is-invalid': form.errors.has('head_cashier') }">
                            <has-error :form="form" field="head_cashier"></has-error>
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">DESIGNATION</span>
                          </div>
                            <input v-model="form.head_cashier_designation" type="text" name="head_cashier_designation" :maxlength="40" autocomplete="off" placeholder="DESIGNATION / POSITION.." class="form-control" :class="{ 'is-invalid': form.errors.has('head_cashier_designation') }">
                            <has-error :form="form" field="head_cashier_designation"></has-error>
                        </div>
                      </div>

                      <br/>

                      <div class="form-group">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">DIRECTOR</span>
                          </div>
                            <input v-model="form.director" type="text" name="director" :maxlength="40" autocomplete="off" placeholder="DIRECTOR.." class="form-control" :class="{ 'is-invalid': form.errors.has('director') }">
                            <has-error :form="form" field="director"></has-error>
                        </div>
                      </div>
                      <div class="form-group">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">DESIGNATION</span>
                          </div>
                            <input v-model="form.director_designation" type="text" name="director_designation" :maxlength="40" autocomplete="off" placeholder="DESIGNATION / POSITION.." class="form-control" :class="{ 'is-invalid': form.errors.has('director_designation') }">
                            <has-error :form="form" field="director_designation"></has-error>
                        </div>
                      </div>


                  </div>
          
              </div>
              </div>
              
              <div class="modal-footer">
                <button type="submit" class="btn btn-default"><i class="fas fa-save"></i> SAVE</button>
              </div>

              </form>
                
            </div>
        </div>
        </div>

        


    </div>
</template>

<script>
    import Form from 'vform';
    import Notifications from './Notifications'

    export default {

        components: {
          Notifications,
        },

        data() {
          return {
            timer: 30,
            setting: {},
            form: new Form({
                id: '',
                fund_cluster: '',
                mds_subacct: '',
                chief_accountant: '',
                chief_accountant_designation: '',
                division_chief: '',
                division_chief_designation: '',
                head_cashier: '',
                head_cashier_designation: '',
                director: '',
                director_designation: '',

            })
          }
        },

        computed: {
          
          second() {
            return this.time / 1000;
          },

          isIdle() {
            const idlestat =  this.$store.state.idleVue.isIdle;
            if (idlestat == true) {
              $('#sessionmodal').modal('show');
              this.countDownTimer();
            } else {
              $('#sessionmodal').modal('hide');
            }

            return idlestat;

          },

        },

        methods: {

          countDownTimer() {
            let timerId = setInterval(() => {
              this.timer -= 1;
              if (!this.$store.state.idleVue.isIdle) clearInterval(timerId);

              if (this.timer < 1) {
                clearInterval(timerId);
                document.getElementById('logout-form').submit();
              }
            }, 1000);

            this.timer = 30;
          },

          loadSettings() {
            this.$Progress.start();
             axios.get('api/cashiersetting/1').then(({ data }) => (this.form.fill(data)));
            this.$Progress.finish();
          },

          updateCashiersettings() {
      
            this.$Progress.start();
            this.form.put('api/cashiersettings/' +this.form.id).then(()=>{
              Trigger.$emit('RefreshData');
              toastr.fire({
                icon: 'success',
                title: 'Cashier settings saved'
              })
              this.$Progress.finish();
            }).catch(()=>{
              toastr.fire({
                icon: 'error',
                title: 'Cashier settings saving failed'
              });
              this.$Progress.fail();
            });


          },

        },


        created() {

          this.loadSettings();

          Trigger.$on('RefreshData',() => {
            this.loadSettings();
          });

        },

    }
</script>
