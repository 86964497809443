<template>
    <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card card-secondary card-outline">
              <div class="card-header">
                <h3 class="card-title"><i class="fas fa-calculator"></i> Units of Measurement (UOM)</h3>

                <div class="card-tools">
                  <div>
                    <button v-show="can_add"  class="btn btn-success btn-sm" @click="newModal"><i class="fa fa-user-plus"></i></button>
                  </div>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0 m-0">
                <table class="table responsive-table table-sm table-striped">
                  <thead>
                    <tr>
                      <th scope="col" class="pl-2">Unit of Measurement</th>
                      <th scope="col">Abbreviation</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="unit in units.data" :key="unit.id">
                      <td scope="row" class="pl-2"><b>{{ unit.name }}</b></td>
                      <td data-title="Abbreviation">{{ unit.abbr }}</td>
                    </tr>
                  </tbody>
                  <tbody v-show="units.total==0">
                      <tr><td colspan="10">No units of measurement found.</td></tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                <pagination :data="units" @pagination-change-page="getResults" show-disabled :limit="2"></pagination>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="actionmodal" tabindex="-1" role="dialog" aria-labelledby="actionModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-md" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="actionModalLabel">Add New Action</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div class="modal-body">
                <div class="row">
                  <div class="col-sm-12">
                    <div class="form-group">
                    <label>Action</label>
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div class="form-group">
                    <label>Action By</label>
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div class="form-group">
                    <label>Evaluation Level</label>
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div class="form-group">
                    <label>Status</label>
                    </div>
                  </div>
                  <div class="col-sm-12">
                    <div class="form-group">
                    <label>Remarks</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <button :disabled="loading" class="btn btn-primary" data-container="body" type="button" @click="pushToDpmis()" ><i v-if="!loading" class="fa fa-save"></i><i v-else class="fas fa-spinner fa-pulse"></i> Submit</button>
              </div>
            </div>
          </div>
        </div>




      </div>

</template>

<script>

    import Form from 'vform';
    import { VueEditor } from "vue2-editor";
    import moment from 'moment';

    export default {
        name: 'UnitOfMeasurement',

        components: {
            VueEditor,
        },

        data() {
          return {
            timer: 30,
            editmode: false,
            loading: false,
            emptyflag: false,
            can_access: false,
            can_add: false,
            can_edit: false,
            can_delete: false,
            units : {},
            form: new Form({
              //
            }),
          }
        },

        props: [
          'todo'
        ],

        computed: {

          second() {
            return this.time / 1000;
          },

          isIdle() {
            const idlestat =  this.$store.state.idleVue.isIdle;
            if (idlestat == true) {
              $('#sessionmodal').modal('show');
              this.countDownTimer();
            } else {
              $('#sessionmodal').modal('hide');
            }
            return idlestat;
          },

        },

        methods: {

          countDownTimer() {
            let timerId = setInterval(() => {
              this.timer -= 1;
              if (!this.$store.state.idleVue.isIdle) clearInterval(timerId);

              if (this.timer < 1) {
                clearInterval(timerId);
                document.getElementById('logout-form').submit();
              }
            }, 1000);

            this.timer = 30;
          },


          viewModal(proposals) {
            this.editmode = true;
            this.form.reset();
            this.form.clear();
            $('#proposalmodal').modal('show');
            //this.getProjectmanager(proposals.project_id)
            this.form.fill(proposals);
          },

          newModal() {
            this.editmode = false;
            this.form.reset();
            this.form.clear();
            $('#proposalmodal').modal('show');
          },

          newActionModal(project_id) {
            this.form_action.reset();
            this.form_action.clear();
            $('#actionmodal').modal('show');
            this.form_action.created_by = 'John Nicholi Jamero';
            this.form_action.project_id = project_id;
          },


          getResults(page = 1) {

            this.$Progress.start();

            let query = this.$parent.search;

            if(query == ''){
              axios.get('api/units?page=' + page).then(response => { this.units = response.data; });
            }else{
              axios.get('api/findUnit?q=' + query + '&page=' + page).then(response => { this.units = response.data; });
            }

            this.$Progress.finish();

            //console.log(query);


          },

          loadUnits() {
            this.$Progress.start();

            let query = this.$parent.search;
            let page = this.units.current_page;

            if(query == ''){
              axios.get('api/units?page=' + page).then(({ data }) => (this.units = data));
            }else{
              axios.get('api/findUnit?q=' + query + '&page=' + page).then(({ data }) => (this.units = data));
            }

            this.$Progress.finish();
          },

          async loadPermission(mid) {
              const response = await axios.get("api/permission/" + this.$gate.showMyID() + "/" + mid).then(
              res => {
                if (!res.data || res.data.length == 0) {
                  return false;
                } else {
                  return true;
                }
              })

              const getResponse = async () => {
                const a = await response;

                switch (mid) {
                case 62:
                  this.can_access = a;
                  if (!this.can_access) {
                    this.$router.push({ name: 'not-found' }) //redirect to not found
                  }
                  break;
                  /*
                case 50:
                  this.can_edit = a;
                  break;
                case 51:
                  this.can_delete = a;
                  */
                }
              };
              return getResponse()
          },


        },

        created() {
          Trigger.$on('searching',() => {
            let query = this.$parent.search;
            let emptyflag = false;

            if(query == ''){
              Trigger.$emit('RefreshData');
            }else{
              this.$Progress.start();
              axios.get("api/findUnits?q=" + query)
              .then((data) => {
                this.offices = data.data;

                if (data.data.total == 0){
                  this.emptyflag = true;
                }

                this.$Progress.finish();
              })
              .catch(() => {
                this.$Progress.fail();
              })
            }

          });

          this.loadUnits();
          this.loadPermission(62); //Can Manage UOM

          Trigger.$on('RefreshData',() => {
            this.loadUnits();
          });
        },

    }
</script>
<style scoped>
div >>> p {
  font-size: 15px;
}

div >>> strong {
  font-size: 15px;
}

div >>> em {
  font-size: 15px;
}

div.header >>> p {
  line-height: 0.5;
  font-size: 20px;
  font-family: "Helvetica Narrow","Arial Narrow";
}

div.header >>> strong {
  line-height: 0.5;
  font-size: 20px;
  font-family: "Helvetica Narrow","Arial Narrow";
}

div.header >>> em {
  line-height: 0.5;
  font-size: 20px;
  font-family: "Helvetica Narrow","Arial Narrow";
}

.text-selection-none{
   user-select: none; /* supported by Chrome and Opera */
   -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
   -moz-user-select: none; /* Firefox */
   -ms-user-select: none;
}
div.tblProject table {
  width: 100% !important;
}

div.tblProject table td, div.tblProject table th {
  border: 1px solid #6C757D !important;
  border-collapse: collapse !important;
  padding: 5px !important;
}

div.tblProject >>> table {
  width: 100% !important;
}

div.tblProject >>> table td, div.tblProject >>> table th {
  border: 1px solid #6C757D !important;
  border-collapse: collapse !important;
  padding: 5px !important;
}

div.tblProject >>> br {
  display: block; /* makes it have a width */
  content: ""; /* clears default height */
  margin-top: 15px; /* change this to whatever height you want it */
}
</style>
