<template>
    <div class="">
        <div class="row justify-content-center">

            <div class="col-md-12">
            <!-- Widget: user widget style 1 -->
                <div class="card card-widget widget-user">
                  <!-- Add the bg color to the header using any of the bg-* classes -->
                  <div class="widget-user-header bg-yellow">
                    <h3 id="emp_fullname" v-if="profile.sname === 'NA'" class="mb-2">{{ profile.fname }} {{ profile.mname | midname }} {{ profile.lname }}</h3>
                    <h3 v-else class="widget-user-username mb-2">{{ profile.fname }} {{ profile.mname }} {{ profile.lname }} {{ profile.sname }}</h3>
                    <h5 id="emp_position" class="widget-user-desc">{{ profile.position }}</h5>
                    <h5 class="widget-user-desc">{{ profile.divisions.division_name }}</h5>
                  </div>
                  <div class="widget-user-image">
                    <img class="profile-user-img img-fluid img-circle elevation-2 img-circle bg-secondary" :src="getProfilePhoto()" alt="">
                  </div>
                  <div class="card-footer">
                    <div class="row justify-content-center">

                    <!--
                      <div class="col-sm-4 border-right">
                        <div class="description-block">
                            <div class="form-group row">

                            </div>

                          <span class="description-text">MATERIAL REQUISITION</span>
                        </div>
                      </div>
                      -->
                      <div class="col-sm-4">
                          <div class="description-block">
                              <div class="form-group row">
                                <div class="col-sm-10 ml-3">
                                <span style="font-weight: 700;float: left;" >EMPLOYEE</span><br/>
                                <selectize v-if="can_accessemp" @input="getAttendance" placeholder="- Select Employee -" v-model="employee_id" :settings="settings" class="form-control">
                                  <option v-for="user in users" :key="user.id" v-bind:value="user.id" >{{ user.lname }}, {{ user.fname }} {{ user.mname | midname }}</option>
                                </selectize>
                                <span v-else><hr/>{{ profile.lname }}, {{ profile.fname }}</span>

                                </div>
                              </div>
                          </div>
                      </div>

                      <div class="col-sm-4">
                          <div class="description-block">
                              <div class="form-group row">
                                <!--<label for="month" class="col-sm-2 col-form-label">MONTH</label>-->
                                <div class="col-sm-10">
                                    <span style="font-weight: 700;float: left;" >MONTH</span>
                                    <select @change="getAttendance" name="type" v-model="month" class="form-control">
                                        <option value="">- Select Month -</option>
                                        <option value="01">JANUARY</option>
                                        <option value="02">FEBRUARY</option>
                                        <option value="03">MARCH</option>
                                        <option value="04">APRIL</option>
                                        <option value="05">MAY</option>
                                        <option value="06">JUNE</option>
                                        <option value="07">JULY</option>
                                        <option value="08">AUGUST</option>
                                        <option value="09">SEPTEMBER</option>
                                        <option value="10">OCTOBER</option>
                                        <option value="11">NOVEMBER</option>
                                        <option value="12">DECEMBER</option>
                                    </select>
                                </div>
                              </div>
                          </div>
                      </div>

                      <div class="col-sm-4">
                        <div class="description-block">
                            <div class="form-group row">
                                <div class="col-sm-10">
                                  <span style="font-weight: 700;float: left;" >YEAR</span>
                                    <select @change="getAttendance" name="type" v-model="year" class="form-control">
                                        <option value="">- Select Year -</option>
                                        <option v-for="year in years" :value="year" :key="year">{{ year }}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                      </div>


                    </div>
                    <!-- /.row -->
                  </div>
                </div>
                <!-- /.widget-user -->
            </div>

            <div class="col-md-9">
              <div class="card table-responsive p-0" style="overflow: visible;">
                <div class="card-header p-2">
                    <div class="card-title">
                      <ul class="nav nav-pills">
                        <li class="nav-item">
                          <a v-if=" this.month === '01'" class="nav-link active" href="#attendances" data-toggle="tab">Time Sheet for January {{ this.year }}</a>
                          <a v-else-if=" this.month === '02'" class="nav-link active" href="#attendances" data-toggle="tab">Time Sheet for February {{ this.year }}</a>
                          <a v-else-if=" this.month === '03'" class="nav-link active" href="#attendances" data-toggle="tab">Time Sheet for March {{ this.year }}</a>
                          <a v-else-if=" this.month === '04'" class="nav-link active" href="#attendances" data-toggle="tab">Time Sheet for April {{ this.year }}</a>
                          <a v-else-if=" this.month === '05'" class="nav-link active" href="#attendances" data-toggle="tab">Time Sheet for May {{ this.year }}</a>
                          <a v-else-if=" this.month === '06'" class="nav-link active" href="#attendances" data-toggle="tab">Time Sheet for June {{ this.year }}</a>
                          <a v-else-if=" this.month === '07'" class="nav-link active" href="#attendances" data-toggle="tab">Time Sheet for July {{ this.year }}</a>
                          <a v-else-if=" this.month === '08'" class="nav-link active" href="#attendances" data-toggle="tab">Time Sheet for August {{ this.year }}</a>
                          <a v-else-if=" this.month === '09'" class="nav-link active" href="#attendances" data-toggle="tab">Time Sheet for September {{ this.year }}</a>
                          <a v-else-if=" this.month === '10'" class="nav-link active" href="#attendances" data-toggle="tab">Time Sheet for October {{ this.year }}</a>
                          <a v-else-if=" this.month === '11'" class="nav-link active" href="#attendances" data-toggle="tab">Time Sheet for November {{ this.year }}</a>
                          <a v-else-if=" this.month === '12'" class="nav-link active" href="#attendances" data-toggle="tab">Time Sheet for December {{ this.year }}</a>
                        </li>
                        <li class="nav-item"><a class="nav-link" href="#officialbusiness" data-toggle="tab">Official Business Form <span v-show="this.officialbusinesses.length != 0" class="badge badge-warning">{{ this.officialbusinesses.length }}</span></a></li>
                      </ul>
                    </div>
                </div><!-- /.card-header -->

                    <div class="tab-content">
                      <div class="tab-pane active" id="attendances">
                        <table class="table responsive-table">
                          <thead>
                            <tr class="bg-yellow">
                              <th scope="col" class="pl-2">DAY</th>
                              <th scope="col" class="pl-2">DATE</th>
                              <th scope="col">IN (AM)</th>
                              <th scope="col">OUT (AM)</th>
                              <th scope="col">IN (PM)</th>
                              <th scope="col">OUT (PM)</th>
                              <th scope="col">HRS</th>
                              <!-- <th class="card-header text-center">OT</th> -->
                              <th scope="col">L/U/A</th>
                              <th scope="col">FLAG CEM</th>
                              <th scope="col">NON-WORKING</th>
                              <th scope="col" class="text-right"><button  @click="dtrModal" class="btn btn-success btn-sm" data-toggle="tooltip" data-container="body" title="Print"><i class="fa fa-print"></i> PRINT</button></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(att, index) in this.attendances" :key="att.id">
                              <template v-if="isSaturday(att.year, att.month, att.day)">
                                <th scope="row" class="bg-secondary pl-2">{{ att.year + att.month + att.day | getday }}</th>
                                <td class="bg-secondary pl-2" data-title="DATE">{{ att.day }}</td>
                                <td class="bg-secondary" data-title="IN (AM)">{{ att.in_am  | timeformat }}</td>
                                <td class="bg-secondary" data-title="OUT (AM)">{{ att.out_am | timeformat }}</td>
                                <td class="bg-secondary" data-title="IN (PM)">{{ att.in_pm  | timeformat }}</td>
                                <td class="bg-secondary" data-title="OUT (PM)">{{ att.out_pm | timeformat }}</td>
                                <td class="bg-secondary" data-title="HRS">{{ subtotalRegular[index] }}</td>
                                <!--  <td class="text-center bg-secondary">{{ att.ot }}</td> -->
                                <td class="bg-secondary" data-title="L/U/A">{{ subtotalLate[index] }}</td>
                                <td class="bg-secondary" data-title="FLAG CEM">-</td>
                                <td class="bg-secondary" data-title="NON-WORKING">-</td>
                                <td class="bg-secondary" data-title=" ">-</td>
                              </template>
                              <template v-else-if="isSunday(att.year, att.month, att.day)">
                                <th scope="row" class="bg-secondary pl-2">{{ att.year + att.month + att.day | getday }}</th>
                                <td class="bg-secondary pl-2" data-title="DATE">{{ att.day }}</td>
                                <td class="bg-secondary" data-title="IN (AM)">{{ att.in_am  | timeformat }}</td>
                                <td class="bg-secondary" data-title="OUT (AM)">{{ att.out_am | timeformat }}</td>
                                <td class="bg-secondary" data-title="IN (PM)">{{ att.in_pm  | timeformat }}</td>
                                <td class="bg-secondary" data-title="OUT (PM)">{{ att.out_pm | timeformat }}</td>
                                <td class="bg-secondary" data-title="HRS">{{ subtotalRegular[index] }}</td>
                                <!--  <td class="text-center bg-secondary">{{ att.ot }}</td> -->
                                <td class="bg-secondary" data-title="L/U/A">{{ subtotalLate[index] }}</td>
                                <td class="bg-secondary" data-title="FLAG CEM">-</td>
                                <td class="bg-secondary" data-title="NON-WORKING">-</td>
                                <td class="bg-secondary" data-title=" ">-</td>
                              </template>
                              <template v-else>
                                <th scope="row" class="pl-2">{{ att.year + att.month + att.day | getday }}</th> <!--({{ att.daily_hours }}HRS)-->
                                <td class="pl-2" data-title="DATE">{{ att.day }}</td>
                                <td data-title="IN (AM)">{{ att.in_am  | timeformat }}</td>
                                <td data-title="OUT (AM)">{{ att.out_am | timeformat }}</td>
                                <td data-title="IN (PM)">{{ att.in_pm  | timeformat }}</td>
                                <td data-title="OUT (PM)">{{ att.out_pm | timeformat }}</td>
                                <!-- <td class="text-center">{{ att.ot }}</td> -->
                                <td data-title="HRS">{{ subtotalRegular[index] }}</td>
                                <td data-title="L/U/A">{{ subtotalLate[index] }}</td>
                                <td v-if="can_process" data-title="FLAG CEM">
                                    <div class="custom-control custom-switch">
                                      <input type="checkbox" v-model="att.is_flagcem" @click="setDayType(att.year, att.month, att.day, 'type_flagcem', $event);getAttendance()" class="custom-control-input" v-bind:id="att.id + '_FlagcemSwitch'">
                                      <label class="custom-control-label" v-bind:for="att.id + '_FlagcemSwitch'"></label>
                                    </div>
                                </td>
                                <td v-else data-title="FLAG CEM"><span v-if="att.is_flagcem === 'checked'"><i class="fa fa-flag text-green"></i></span><span v-else></span></td>
                                <td v-if="can_process" data-title="NON-WORKING">
                                    <div class="custom-control custom-switch">
                                      <input type="checkbox" v-model="att.is_nonwork" @click="setDayType(att.year, att.month, att.day, 'type_nonwork', $event);getAttendance()" class="custom-control-input" v-bind:id="att.id + '_NonworkSwitch'">
                                      <label class="custom-control-label" v-bind:for="att.id + '_NonworkSwitch'"></label>
                                    </div>
                                </td>
                                <td v-else data-title="NON-WORKING"><span v-if="att.is_nonwork === 'checked'"><i class="fa fa-check text-green"></i></span><span v-else></span></td>
                                <td>
                                  <div class="btn-group">
                                    <button type="button" class="btn btn-primary btn-sm dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                                      <i class="fas fa-bars"></i></button>
                                    <div class="dropdown-menu float-right" role="menu" x-placement="top-start" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(-5px, -133px, 0px);">
                                      <div v-show="can_process">
                                      <button class="dropdown-item" type="button" @click="overrideModal(att)">Override</button>
                                      <div class="dropdown-divider"></div>
                                      </div>
                                      <div v-if="profile.appointment == 'Permanent'">
                                      <button class="dropdown-item" type="button" @click="officialbusinessModal(att, 'Sick Leave')">Sick Leave</button>
                                      <!--
                                      <button class="dropdown-item" type="button" @click="officialbusinessModal(att, 'Vacation Leave');formatToDateTime()">Vacation Leave</button>
                                      <button class="dropdown-item" type="button" @click="officialbusinessModal(att, 'Privilege Leave');formatToDateTime()">Privilege Leave</button>
                                      <button class="dropdown-item" type="button" @click="officialbusinessModal(att, 'Forced Leave');formatToDateTime()">Forced Leave</button>
                                      -->
                                      <button class="dropdown-item" type="button" @click="officialbusinessModal(att, 'Vacation Leave')">Vacation Leave</button>
                                      <button class="dropdown-item" type="button" @click="officialbusinessModal(att, 'Privilege Leave')">Privilege Leave</button>
                                      <button class="dropdown-item" type="button" @click="officialbusinessModal(att, 'Forced Leave')">Forced Leave</button>
                                      <button class="dropdown-item" type="button" @click="officialbusinessModal(att, 'Emergency Leave')">Emergency Leave</button>
                                      <button class="dropdown-item" type="button" @click="officialbusinessModal(att, 'Maternity Leave')">Maternity Leave</button>
                                      <button class="dropdown-item" type="button" @click="officialbusinessModal(att, 'Paternity Leave')">Paternity Leave</button>
                                      <div class="dropdown-divider"></div>
                                      </div>
                                      <button class="dropdown-item" type="button" @click="officialbusinessModal(att, 'Special Order')">Special Order</button>
                                      <button class="dropdown-item" type="button" @click="officialbusinessModal(att, 'Travel Order')">Travel Order</button>
                                      <button class="dropdown-item" type="button" @click="officialbusinessModal(att, 'Pass Slip')">Pass Slip</button>
                                      <button class="dropdown-item" type="button" @click="officialbusinessModal(att, 'Work From Home')">Work From Home</button>
                                      <button class="dropdown-item" type="button" @click="officialbusinessModal(att, 'Offset')">Offset</button>
                                      <button class="dropdown-item" type="button" @click="officialbusinessModal(att, 'Flag Raising')">Flag Raising</button>
                                      <button class="dropdown-item" type="button" @click="officialbusinessModal(att, 'Flag Retreat')">Flag Retreat</button>
                                      <button class="dropdown-item" type="button" @click="officialbusinessModal(att, 'Justification')">Justification</button>
                                    </div>
                                  </div>
                                </td>
                              </template>
                            </tr>
                            <tr v-show="this.attendances.length != 0" class="bg-warning">
                                <td colspan="6" class="text-right font-weight-bold">TOTALS:</td>
                                <td class="font-weight-bold">{{ total_hrs }}</td>
                                <!-- <td class="text-center font-weight-bold">{{ total_ot }}</td> -->
                                <td class="font-weight-bold">{{ total_late }}</td>
                                <td colspan="3" class="text-center"></td>
                            </tr>
                            <tr v-show="this.attendances.length === 0">
                                <td colspan="12" class="text-center">No record available.</td>
                            </tr>
                          </tbody>
                        </table>
                        <br>
                      </div>

                      <div class="tab-pane" id="officialbusiness">
                        <table class="table responsive-table">
                          <thead>
                            <tr class="bg-yellow">
                              <th scope="col" class="pl-2">STATUS</th>
                              <th scope="col" class="pl-2">FORM TYPE</th>
                              <th scope="col">DATE</th>
                              <th scope="col">DAY</th>
                              <th scope="col">IN (AM)</th>
                              <th scope="col">OUT (AM)</th>
                              <th scope="col">IN (PM)</th>
                              <th scope="col">OUT (PM)</th>
                              <th scope="col" class="text-right">
                                <div class="btn-group">
                                    <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-expanded="false">
                                      Advance OB <i class="fas fa-bars"></i></button>
                                    <div class="dropdown-menu float-right" role="menu" x-placement="top-start" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(-5px, -133px, 0px);">
                                      <div v-if="profile.appointment == 'Permanent'">
                                        <button class="dropdown-item" type="button" @click="officialbusinessModalAdvance('Vacation Leave','Advance');formatToDateTime();">Vacation Leave</button>
                                        <button class="dropdown-item" type="button" @click="officialbusinessModalAdvance('Privilege Leave','Advance');formatToDateTime();">Privilege Leave</button>
                                        <button class="dropdown-item" type="button" @click="officialbusinessModalAdvance('Forced Leave','Advance');formatToDateTime();">Forced Leave</button>
                                        <button class="dropdown-item" type="button" @click="officialbusinessModalAdvance('Emergency Leave','Advance');formatToDateTime();">Emergency Leave</button>
                                        <button class="dropdown-item" type="button" @click="officialbusinessModalAdvance('Maternity Leave','Advance');formatToDateTime();">Maternity Leave</button>
                                        <button class="dropdown-item" type="button" @click="officialbusinessModalAdvance('Paternity Leave','Advance');formatToDateTime();">Paternity Leave</button>
                                        <div class="dropdown-divider"></div>
                                      </div>
                                      <button class="dropdown-item" type="button" @click="officialbusinessModalAdvance('Special Order','Advance');formatToDateTime();">Special Order</button>
                                      <button class="dropdown-item" type="button" @click="officialbusinessModalAdvance('Travel Order','Advance');formatToDateTime();">Travel Order</button>
                                      <button class="dropdown-item" type="button" @click="officialbusinessModalAdvance('Pass Slip','Advance');formatToDateTime();">Pass Slip</button>
                                      <button class="dropdown-item" type="button" @click="officialbusinessModalAdvance('Work From Home','Advance');formatToDateTime();">Work From Home</button>
                                      <button class="dropdown-item" type="button" @click="officialbusinessModalAdvance('Offset','Advance');formatToDateTime();">Offset</button>
                                    </div>
                                  </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="lst in this.officialbusinesses" :key="lst.id">
                              <th scope="row" class="pl-2"><b class="cursor" v-tooltip="{
                                                              content: '<b>OB Form:</b> ' + lst.type + '<br/><b>Description:</b> ' + lst.description,
                                                              placement: 'right',
                                                              classes: ['info'],
                                                              targetClasses: ['it-has-a-tooltip'],
                                                              offset: 10,
                                                              delay: {
                                                                show: 500,
                                                                hide: 300,
                                                              },
                                                            }"
                              >{{ lst.type }}</b></th>

                              <td data-title="STATUS" class="pl-2" v-if="lst.obstatus === 'Approved'"><span class="badge badge-success">{{ lst.obstatus }}</span></td>
                              <td data-title="STATUS" class="pl-2" v-if="lst.obstatus === 'Pending'"><span class="badge badge-primary">{{ lst.obstatus }}</span></td>
                              <td data-title="STATUS" class="pl-2" v-if="lst.obstatus === 'Denied'"><span class="badge badge-danger">{{ lst.obstatus }}</span></td>
                              <td data-title="STATUS" class="pl-2" v-if="lst.obstatus === 'Cancelled'"><span class="badge badge-warning">{{ lst.obstatus }}</span></td>

                              <td data-title="DATE">{{ lst.year }}-{{ lst.month }}-{{ lst.day }}</td>
                              <td data-title="DAY">{{ lst.year + lst.month + lst.day | getday }}</td>
                              <td data-title="IN (AM)">{{ lst.in_am | timeformat }}</td>
                              <td data-title="OUT (AM)">{{ lst.out_am | timeformat }}</td>
                              <td data-title="IN (PM)">{{ lst.in_pm | timeformat }}</td>
                              <td data-title="OUT (PM)">{{ lst.out_pm | timeformat }}</td>



                              <td data-title="Options" v-if="lst.obstatus === 'Approved'">
                                <button v-show="can_approve" type="button" class="btn btn-sm btn-secondary" disabled>Approve</button>
                                <button v-show="can_deny" type="button" class="btn btn-sm btn-secondary" disabled>Deny</button>
                                <button type="button" class="btn btn-sm btn-secondary" disabled>Cancel</button>
                              </td>

                              <td data-title="Options" v-if="lst.obstatus === 'Denied'">
                                <button v-show="can_approve" type="button" class="btn btn-sm btn-secondary" disabled>Approve</button>
                                <button v-show="can_deny" type="button" class="btn btn-sm btn-secondary" disabled>Deny</button>
                                <button type="button" class="btn btn-sm btn-secondary" disabled>Cancel</button>
                              </td>

                              <td data-title="Options" v-if="lst.obstatus === 'Cancelled'">
                                <button v-show="can_approve" type="button" class="btn btn-sm btn-secondary" disabled>Approve</button>
                                <button v-show="can_deny" type="button" class="btn btn-sm btn-secondary" disabled>Deny</button>
                                <button type="button" class="btn btn-sm btn-secondary" disabled>Cancel</button>
                              </td>

                              <td data-title="Options" v-if="lst.obstatus === 'Pending'">
                                  <button v-show="can_approve" v-if="(lst.year + lst.month + lst.day) > getDateToday" type="button" class="btn btn-sm btn-secondary" disabled>Approve</button>
                                  <button v-show="can_approve" v-else type="button" class="btn btn-sm btn-success" @click="approveOb(lst.id, lst.biometric_id, lst.year, lst.month, lst.day, lst.in_am, lst.out_am, lst.in_pm, lst.out_pm, $event)">Approve</button>
                                  <button v-show="can_deny" type="button" class="btn btn-sm btn-danger" @click="denyOb(lst.id, lst.year, lst.month, lst.day, $event)">Deny</button>
                                  <button type="button" class="btn btn-sm btn-warning" @click="cancelOb(lst.id, lst.year, lst.month, lst.day, $event)">Cancel</button>
                              </td>

                            </tr>
                            <br/><br/><br/><br/><br/><br/><br/><br/><br/>
                          </tbody>
                          <tbody v-show="this.officialbusinesses.length == 0">
                            <tr>
                              <td colspan="9" class="text-center">No record available.</td>
                            </tr>
                          </tbody>

                        </table>
                        <br>
                      </div>

                    </div>

              </div>
            </div>












            <div class="col-md-3">

                <div class="card">
                  <div class="card-header">
                    <h3 class="card-title">Summary</h3>
                    <div class="card-tools">
                      <div></div>
                    </div>
                  </div>

                  <div class="card-body p-0">
                    <table class="table table-bordered table-hover">
                      <tbody>
                        <tr>
                          <td class="align-left"><b>Current Monthly Salary:</b> {{ profile.salary | toCurrency }}</td>
                        </tr>
                        <tr>
                          <td class="align-left"><b>Appointment Status:</b> {{ profile.appointment }}</td>
                        </tr>
                        <tr>
                          <td class="align-left"><b>COR:</b> {{ profile.cor }}</td>
                        </tr>
                        <tr>
                          <td class="align-left"><b>TAPIEA MEMBER:</b> {{ profile.tapiea_membership }}</td>
                        </tr>
                        <tr>
                          <td class="align-left"><b>Calendar Days:</b> {{ this.no_days }} , {{ compute_daysofservice }}</td>
                        </tr>
                        <tr v-if="this.attendances.length != 0">
                          <td class="align-left">
                            <b>Daily Hours:</b> {{ this.attendances[0].daily_hours }}<br/><br/>
                            <div class="btn-group btn-group-sm">
                              <button v-if="this.attendances[0].daily_hours == 8" class="btn btn-danger" type="button"  @click="setDailyHour('2020', '02', 249, 8);getAttendance()">8 Hrs</button>
                              <button v-else class="btn btn-primary" type="button"  @click="setDailyHour('2020', '02', 249, 8);getAttendance()">8 Hrs</button>

                              <button v-if="this.attendances[0].daily_hours == 7" class="btn btn-danger" type="button"  @click="setDailyHour('2020', '02', 249, 7);getAttendance()">7 Hrs</button>
                              <button v-else class="btn btn-primary" type="button"  @click="setDailyHour('2020', '02', 249, 7);getAttendance()">7 Hrs</button>

                              <button v-if="this.attendances[0].daily_hours == 6" class="btn btn-danger" type="button"  @click="setDailyHour('2020', '02', 249, 6);getAttendance()">6 Hrs</button>
                              <button v-else class="btn btn-primary" type="button"  @click="setDailyHour('2020', '02', 249, 6);getAttendance()">6 Hrs</button>

                            </div>
                          </td>
                        </tr>
                        <!--
                        <tr>
                          <td class="align-left"><b>Daily Rate:</b> {{ compute_daily | toCurrency }}</td>
                        </tr>
                        <tr>
                          <td class="align-left"><b>Hourly Rate:</b> {{ compute_hourly | toCurrency }}</td>
                        </tr>
                        -->


                      </tbody>
                    </table>
                  </div>
                </div>

                <div v-if="profile.appointment === 'Contract of Service'">
                    <div class="card">
                    <div class="card-header">
                      <h3 class="card-title">First Cutoff (15 days)</h3>
                      <div class="card-tools">
                        <div></div>
                      </div>
                    </div>

                    <div class="card-body p-0">
                      <table class="table table-bordered table-hover">
                        <tbody>
                          <tr>
                            <td class="align-left"><b>Salary Earned:</b> <span id="sal_earned_1">{{ compute_earned_1 | toCurrency }}</span></td>
                          </tr>
                          <tr>
                            <td class="align-left"><b>Hours Worked:</b> {{ total_hrs_cutoff_1 }}</td>
                          </tr>
                          <!-- -->
                          <tr>
                            <td class="align-left"><b>L/U/A:</b> {{ total_late_cutoff_1 | fixdecimal }} HRS ( <span id="lua_1">{{ total_late_cutoff_1 * compute_hourly | toCurrency}}</span> )</td>
                          </tr>
                          <tr>
                            <td class="align-left"><b>Total Salary:</b> <span id="sal_total_1">{{ compute_salary_cutoff_1 | toCurrency  }}</span></td>
                          </tr>
                          <tr>
                            <td class="align-left"><b>EWT (2%):</b> <span id="ewt2_1">{{ compute_ewt2_cut1 | toCurrency  }}</span></td>
                          </tr>
                          <tr>
                            <td class="align-left"><b>EWT (5%):</b> <span id="ewt5_1">{{ compute_ewt5_cut1 | toCurrency  }}</span></td>
                          </tr>
                          <tr>
                            <td class="align-left"><b>Percentage Tax (3%):</b> <span id="ewt3_1">{{ compute_ewt3_cut1 | toCurrency  }}</span></td>
                          </tr>
                          <tr>
                            <td class="align-left"><b>HEALTHCARD C/O TAPIEA:</b> <span id="ewt3_1">{{ profile.tapiea_contribution | toCurrency  }}</span></td>
                          </tr>
                          <tr>
                            <td class="align-left"><b>Net Pay:</b> <span id="net_pay_1">{{ compute_netpay_cutoff_1 | toCurrency  }}</span></td>
                          </tr>
                          <tr>
                            <button v-show="can_process" v-if="firstcutoffprocess.total === 0" type="button" @click="processCosCut1" class="btn btn-success btn-lg btn-block">Process Payroll</button>
                            <button v-if="firstcutoffprocess.total > 0" type="button" @click="payslipModal(1)" class="btn btn-primary btn-lg btn-block">View Payslip</button>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div class="card">
                    <div class="card-header">
                      <h3 class="card-title">Second Cutoff ({{ this.no_days_2nd_cut }} days)</h3>
                      <div class="card-tools">
                        <div></div>
                      </div>
                    </div>

                    <div class="card-body p-0">
                      <table class="table table-bordered table-hover">
                        <tbody>
                          <tr>
                            <td class="align-left"><b>Salary Earned:</b> <span id="sal_earned_2">{{ compute_earned_2 | toCurrency }}</span></td>
                          </tr>

                          <tr>
                            <td class="align-left"><b>Hours Worked:</b> {{ total_hrs_cutoff_2 }}</td>
                          </tr>
                          <!-- -->
                          <tr>
                            <td class="align-left"><b>L/U/A:</b> {{ total_late_cutoff_2 | fixdecimal }} HRS ( <span id="lua_2">{{ total_late_cutoff_2 * compute_hourly | toCurrency}}</span> )</td>
                          </tr>
                          <tr>
                            <td class="align-left"><b>Total Salary:</b> <span id="sal_total_2">{{ compute_salary_cutoff_2 | toCurrency  }}</span></td>
                          </tr>
                          <tr>
                            <td class="align-left"><b>EWT (2%):</b> <span id="ewt2_2">{{ compute_ewt2_cut2 | toCurrency  }}</span></td>
                          </tr>
                          <tr>
                            <td class="align-left"><b>EWT (5%):</b> <span id="ewt5_2">{{ compute_ewt5_cut2 | toCurrency  }}</span></td>
                          </tr>
                          <tr>
                            <td class="align-left"><b>Percentage Tax (1%):</b> <span id="ewt3_2">{{ compute_ewt3_cut2 | toCurrency  }}</span></td>
                          </tr>
                          <tr>
                            <td class="align-left"><b>Net Pay:</b> <span id="net_pay_2">{{ compute_netpay_cutoff_2 | toCurrency  }}</span></td>
                          </tr>
                          <tr>
                            <button v-show="can_process" v-if="secondcutoffprocess.total === 0" type="button" @click="processCosCut2" class="btn btn-success btn-lg btn-block">Process Payroll</button>
                            <button v-if="secondcutoffprocess.total > 0" type="button" @click="payslipModal(2)" class="btn btn-primary btn-lg btn-block">View Payslip</button>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                  </div>
                </div>

                <div v-else style="display:none">
                  <div class="card">
                    <div class="card-header">
                      <h3 class="card-title">First Cutoff (15 days)</h3>
                      <div class="card-tools">
                        <div></div>
                      </div>
                    </div>

                    <div class="card-body p-0">
                      <table class="table table-bordered table-hover">
                        <tbody>
                          <tr>
                            <td class="align-left"><b>Salary Earned:</b> {{ compute_earned_1 | toCurrency }}</td>
                          </tr>
                          <!--
                          <tr>
                            <td class="align-left"><b>Hours Worked:</b> {{ total_hrs_cutoff_1 }}</td>
                          </tr>
                          -->
                          <tr>
                            <td class="align-left"><b>L/U/A:</b> {{ total_late_cutoff_1 | fixdecimal }} HRS ( {{ total_late_cutoff_1 * compute_hourly | toCurrency}} )</td>
                          </tr>
                          <tr>
                            <td class="align-left"><b>Total Salary:</b> {{ compute_salary_cutoff_1 | toCurrency  }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div class="card">
                    <div class="card-header">
                      <h3 class="card-title">Second Cutoff ({{ this.no_days_2nd_cut }} days)</h3>
                      <div class="card-tools">
                        <div></div>
                      </div>
                    </div>

                    <div class="card-body p-0">
                      <table class="table table-bordered table-hover">
                        <tbody>
                          <tr>
                            <td class="align-left"><b>Salary Earned:</b> {{ compute_earned_2 | toCurrency }}</td>
                          </tr>
                          <!--
                          <tr>
                            <td class="align-left"><b>Hours Worked:</b> {{ total_hrs_cutoff_2 }}</td>
                          </tr>
                          -->
                          <tr>
                            <td class="align-left"><b>L/U/A:</b> {{ total_late_cutoff_2 | fixdecimal }} HRS ( {{ total_late_cutoff_2 * compute_hourly | toCurrency}} )</td>
                          </tr>
                          <tr>
                            <td class="align-left"><b>Total Salary:</b> {{ compute_salary_cutoff_2 | toCurrency  }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                  </div>

                </div>



            </div>


        </div>



        <div class="modal fade" id="attmodal" tabindex="-1" role="dialog" aria-labelledby="attModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-md" role="document">
            <div class="modal-content">
              <div class="modal-header bg-orange">
                <h5 class="modal-title" id="attModalLabel"> Override Attendance for {{ form.month }}/{{ form.day }}/{{ form.year }}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <form @submit.prevent="updateAttendance()" @keydown="form.onKeydown($event)">

              <div class="modal-body">
              <div class="row-aligncenter">

                  <div class="col-lg-12 col-6">
                    <!-- small box -->
                    <div class="small-box bg-warning">
                      <div class="inner">
                        <h3>IN</h3>

                        <p><vue-timepicker hide-disabled-items close-on-complete :hour-range="[[0, 12]]" @change="formatToDateTime()" auto-scroll name="in_am" v-model="form.in_am" :class="{ 'is-invalid': form.errors.has('in_am') }" ></vue-timepicker></p>
                      </div>
                      <div class="icon">
                        <i class="fas">AM</i>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-12 col-6">
                    <!-- small box -->
                    <div class="small-box bg-warning">
                      <div class="inner">
                        <h3>OUT</h3>

                        <p><vue-timepicker hide-disabled-items close-on-complete :hour-range="[[12, 13]]" @change="formatToDateTime()" auto-scroll name="out_am" v-model="form.out_am" :class="{ 'is-invalid': form.errors.has('out_am') }" ></vue-timepicker></p>
                      </div>
                      <div class="icon">
                        <i class="fas">AM</i>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-12 col-6">
                    <!-- small box -->
                    <div class="small-box bg-orange">
                      <div class="inner">
                        <h3>IN</h3>

                        <p><vue-timepicker hide-disabled-items close-on-complete :hour-range="[[12, 13]]" @change="formatToDateTime()" auto-scroll name="in_pm" v-model="form.in_pm" :class="{ 'is-invalid': form.errors.has('in_pm') }" ></vue-timepicker></p>
                      </div>
                      <div class="icon">
                        <i class="fas">PM</i>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-12 col-6">
                    <!-- small box -->
                    <div class="small-box bg-orange">
                      <div class="inner">
                        <h3>OUT</h3>

                        <p><vue-timepicker hide-disabled-items close-on-complete :hour-range="[[13, 23]]" @change="formatToDateTime()" auto-scroll name="out_pm"  v-model="form.out_pm" :class="{ 'is-invalid': form.errors.has('out_pm') }" ></vue-timepicker></p>
                      </div>
                      <div class="icon">
                        <i class="fas">PM</i>
                      </div>
                    </div>
                  </div>

              </div>
              </div>

              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button type="submit" class="btn btn-success">Update</button>
              </div>

              </form>

            </div>
          </div>
        </div>


        <div class="modal fade" id="obmodal" tabindex="-1" role="dialog" aria-labelledby="obModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header bg-purple">
                <h5 class="modal-title" id="obModalLabel"> Create {{ form.ob }} for {{ form.month }}/{{ form.day }}/{{ form.year }}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <form @submit.prevent="createOb()" @keydown="form.onKeydown($event)">

              <div class="modal-body">
              <div class="row-aligncenter">

                <div class="row">

                <div class="col-lg-6 col-6">
                  <div class="form-group">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">YEAR</span>
                      </div>

                      <select v-if="form.obfile != 'Advance'" name="year" v-model="form.year" class="form-control" disabled>
                        <option value="">- Select Year -</option>
                        <option v-for="year in years" :value="year" :key="year">{{ year }}</option>
                      </select>

                      <select @click="formatToDateTime()" v-else name="year" v-model="form.year" class="form-control" :class="{ 'is-invalid': form.errors.has('year') }" >
                        <option value="">- Select Year -</option>
                        <option v-for="year in years" :value="year" :key="year">{{ year }}</option>
                      </select>
                      <has-error :form="form" field="year"></has-error>


                    </div>
                  </div>

                  <div class="form-group">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">MONTH</span>
                      </div>
                      <select v-if="form.obfile != 'Advance'" name="month" v-model="form.month" class="form-control" disabled>
                                        <option value="">- Select Month -</option>
                                        <option value="01">JANUARY</option>
                                        <option value="02">FEBRUARY</option>
                                        <option value="03">MARCH</option>
                                        <option value="04">APRIL</option>
                                        <option value="05">MAY</option>
                                        <option value="06">JUNE</option>
                                        <option value="07">JULY</option>
                                        <option value="08">AUGUST</option>
                                        <option value="09">SEPTEMBER</option>
                                        <option value="10">OCTOBER</option>
                                        <option value="11">NOVEMBER</option>
                                        <option value="12">DECEMBER</option>
                                    </select>

                      <select @click="formatToDateTime()" v-else name="month" v-model="form.month" class="form-control" :class="{ 'is-invalid': form.errors.has('month') }" >
                                        <option value="">- Select Month -</option>
                                        <option value="01">JANUARY</option>
                                        <option value="02">FEBRUARY</option>
                                        <option value="03">MARCH</option>
                                        <option value="04">APRIL</option>
                                        <option value="05">MAY</option>
                                        <option value="06">JUNE</option>
                                        <option value="07">JULY</option>
                                        <option value="08">AUGUST</option>
                                        <option value="09">SEPTEMBER</option>
                                        <option value="10">OCTOBER</option>
                                        <option value="11">NOVEMBER</option>
                                        <option value="12">DECEMBER</option>
                                    </select>
                                    <has-error :form="form" field="month"></has-error>
                    </div>
                  </div>

                  <div class="form-group">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text">DAY</span>
                      </div>
                      <select v-if="form.obfile != 'Advance'" name="day" v-model="form.day" class="form-control" disabled>
                                        <option value="">- Select Day -</option>
                                        <option value="01">01</option>
                                        <option value="02">02</option>
                                        <option value="03">03</option>
                                        <option value="04">04</option>
                                        <option value="05">05</option>
                                        <option value="06">06</option>
                                        <option value="07">07</option>
                                        <option value="08">08</option>
                                        <option value="09">09</option>
                                        <option value="10">10</option>
                                        <option value="11">11</option>
                                        <option value="12">12</option>
                                        <option value="13">13</option>
                                        <option value="14">14</option>
                                        <option value="15">15</option>
                                        <option value="16">16</option>
                                        <option value="17">17</option>
                                        <option value="18">18</option>
                                        <option value="19">19</option>
                                        <option value="20">20</option>
                                        <option value="21">21</option>
                                        <option value="22">22</option>
                                        <option value="23">23</option>
                                        <option value="24">24</option>
                                        <option value="25">25</option>
                                        <option value="26">26</option>
                                        <option value="27">27</option>
                                        <option value="28">28</option>
                                        <option value="29">29</option>
                                        <option value="30">30</option>
                                        <option value="31">31</option>
                                    </select>
                      <select @click="formatToDateTime()" v-else name="day" v-model="form.day" class="form-control" :class="{ 'is-invalid': form.errors.has('day') }" >
                                        <option value="">- Select Day -</option>
                                        <option value="01">01</option>
                                        <option value="02">02</option>
                                        <option value="03">03</option>
                                        <option value="04">04</option>
                                        <option value="05">05</option>
                                        <option value="06">06</option>
                                        <option value="07">07</option>
                                        <option value="08">08</option>
                                        <option value="09">09</option>
                                        <option value="10">10</option>
                                        <option value="11">11</option>
                                        <option value="12">12</option>
                                        <option value="13">13</option>
                                        <option value="14">14</option>
                                        <option value="15">15</option>
                                        <option value="16">16</option>
                                        <option value="17">17</option>
                                        <option value="18">18</option>
                                        <option value="19">19</option>
                                        <option value="20">20</option>
                                        <option value="21">21</option>
                                        <option value="22">22</option>
                                        <option value="23">23</option>
                                        <option value="24">24</option>
                                        <option value="25">25</option>
                                        <option value="26">26</option>
                                        <option value="27">27</option>
                                        <option value="28">28</option>
                                        <option value="29">29</option>
                                        <option value="30">30</option>
                                        <option value="31">31</option>
                                    </select>
                                    <has-error :form="form" field="day"></has-error>
                    </div>
                  </div>

                  <div class="form-group">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">REASON/DESCRIPTION</span>
                          </div>
                          <input v-model="form.description" type="text" name="description" autocomplete="off" placeholder="" class="form-control" :class="{ 'is-invalid': form.errors.has('description') }">
                          <has-error :form="form" field="description"></has-error>
                        </div>
                      </div>





                </div>
                <div class="col-lg-6 col-6">

                  <div class="col-lg-12 col-6">
                    <!-- small box -->
                    <div class="small-box bg-info">
                      <div class="inner">
                        <h3>IN</h3>
                        <p v-if="form.ob == 'Flag Retreat'"><vue-timepicker hide-disabled-items disabled name="ob_in_am" v-model="form.in_am" :class="{ 'is-invalid': form.errors.has('ob_in_am') }" ></vue-timepicker></p>
                        <p v-else-if="form.ob == 'Flag Raising'"><vue-timepicker hide-disabled-items close-on-complete :hour-range="[8]" :minute-range="[0]" name="ob_in_am" v-model="form.in_am" :class="{ 'is-invalid': form.errors.has('ob_in_am') }" ></vue-timepicker></p>
                        <p v-else><vue-timepicker advanced-keyboard hide-disabled-items close-on-complete :hour-range="[[0, 12]]" @change="formatToDateTime()" auto-scroll name="ob_in_am" v-model="form.in_am" :class="{ 'is-invalid': form.errors.has('ob_in_am') }" ></vue-timepicker></p>
                      </div>
                      <div class="icon">
                        <i class="fas">AM</i>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-12 col-6">
                    <!-- small box -->
                    <div class="small-box bg-info">
                      <div class="inner">
                        <h3>OUT</h3>

                        <p v-if="form.ob == 'Flag Retreat'"><vue-timepicker disabled name="ob_out_am" v-model="form.out_am" :class="{ 'is-invalid': form.errors.has('ob_out_am') }" ></vue-timepicker></p>
                        <p v-else-if="form.ob == 'Flag Raising'"><vue-timepicker disabled name="ob_out_am" v-model="form.out_am" :class="{ 'is-invalid': form.errors.has('ob_out_am') }" ></vue-timepicker></p>
                        <p v-else><vue-timepicker hide-disabled-items close-on-complete :hour-range="[[12, 13]]" @change="formatToDateTime()" auto-scroll name="ob_out_am" v-model="form.out_am" :class="{ 'is-invalid': form.errors.has('ob_out_am') }" ></vue-timepicker></p>
                      </div>
                      <div class="icon">
                        <i class="fas">AM</i>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-12 col-6">
                    <!-- small box -->
                    <div class="small-box bg-primary">
                      <div class="inner">
                        <h3>IN</h3>

                        <p v-if="form.ob == 'Flag Retreat' "><vue-timepicker disabled name="ob_in_pm" v-model="form.in_pm" :class="{ 'is-invalid': form.errors.has('ob_in_pm') }" ></vue-timepicker></p>
                        <p v-else-if="form.ob == 'Flag Raising' "><vue-timepicker disabled name="ob_in_pm" v-model="form.in_pm" :class="{ 'is-invalid': form.errors.has('ob_in_pm') }" ></vue-timepicker></p>
                        <p v-else><vue-timepicker hide-disabled-items close-on-complete :hour-range="[[12, 13]]" @change="formatToDateTime()" auto-scroll name="ob_in_pm" v-model="form.in_pm" :class="{ 'is-invalid': form.errors.has('ob_in_pm') }" ></vue-timepicker></p>
                      </div>
                      <div class="icon">
                        <i class="fas">PM</i>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-12 col-6">
                    <!-- small box -->
                    <div class="small-box bg-primary">
                      <div class="inner">
                        <h3>OUT</h3>

                        <p v-if="form.ob == 'Flag Raising'"><vue-timepicker disabled name="ob_out_pm"  v-model="form.out_pm" :class="{ 'is-invalid': form.errors.has('ob_out_pm') }" ></vue-timepicker></p>
                        <p v-else-if="form.ob == 'Flag Retreat'"><vue-timepicker hide-disabled-items close-on-complete :hour-range="[16,17,18]" name="ob_out_pm"  v-model="form.out_pm" :class="{ 'is-invalid': form.errors.has('ob_out_pm') }" ></vue-timepicker></p>
                        <p v-else><vue-timepicker hide-disabled-items close-on-complete :hour-range="[[13, 23]]" @change="formatToDateTime()" auto-scroll name="ob_out_pm"  v-model="form.out_pm" :class="{ 'is-invalid': form.errors.has('ob_out_pm') }" ></vue-timepicker></p>
                      </div>
                      <div class="icon">
                        <i class="fas">PM</i>
                      </div>
                    </div>
                  </div>



                </div>
              </div>
              </div>
              </div>

              <div class="modal-footer">
                <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                <button type="submit" class="btn btn-default">Submit</button>
              </div>

              </form>

            </div>
          </div>
        </div>


        <!-- PAYSLIP 1st -->
        <div class="modal fade" id="payslipmodal1" tabindex="-1" role="dialog" aria-labelledby="payslipModalLabel1" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header bg-orange">
                <h5 class="modal-title" id="payslipModalLabel1">First Cut-off E-Payslip</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>


              <div class="modal-body">
              <div class="row-aligncenter">
                <div id="printPayslip1">
                  <div class="card-body table-responsive p-0">
                    <table class="table table-bordered col-12">
                      <tbody>
                        <tr>
                          <td colspan="5" align="center"><h2><img src="img/tapi-logo.png" height="30px" /> &nbsp;TECHNOLOGY APPLICATION AND PROMOTION INSTITUTE</h2></td>
                        </tr>
                        <tr>
                          <td colspan="5" align="center"> <i>***** PAY SLIP *****<br/>for {{ payslip.month | monthwordformat }} 1-15, {{ payslip.year }}</i></td>
                        </tr>
                        <tr>
                          <td colspan="5">NAME : <b>{{ payslip.fullname }}</b></td>
                        </tr>
                        <tr>
                          <td colspan="5">DESIGNATION : <b>{{ payslip.position }}</b></td>
                        </tr>
                      </tbody>
                    </table>
                    <table class="table table-borderless" style="border:1px solid #dee2e6">
                      <thead>
                        <tr>
                          <th style="border:1px solid #dee2e6" class="card-header">
                            EARNINGS
                          </th>
                          <th style="border:1px solid #dee2e6" class="card-header">
                            AMOUNT
                          </th>
                          <th style="border:1px solid #dee2e6" class="card-header">
                            DEDUCTIONS
                          </th>
                          <th style="border:1px solid #dee2e6" class="card-header">
                            AMOUNT
                          </th>
                          <th style="border:1px solid #dee2e6" class="card-header">
                            NET AMOUNT
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td><b>MONTHLY SALARY</b></td>
                          <td>{{ payslip.salary_monthly | toCurrency }}</td>
                          <td>ABSENCES/LATES/UNDERTIME</td>
                          <td>{{ payslip.lua | toCurrency}}</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td></td>
                          <td></td>
                          <td>E. WITHHOLDING TAX (2%)</td>
                          <td>{{ payslip.ewt_2 | toCurrency  }}</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td></td>
                          <td></td>
                          <td>E. WITHHOLDING TAX (5%)</td>
                          <td>{{ payslip.ewt_5 | toCurrency  }}</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td></td>
                          <td></td>
                          <td>PERCENTAGE TAX (1%)</td>
                          <td>{{ payslip.ewt_3 | toCurrency  }}</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td></td>
                          <td></td>
                          <td>HEALTHCARD C/O TAPIEA</td>
                          <td>{{ payslip.tapiea_contrib | toCurrency  }}</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td></td>
                          <td style="border-top:2px solid black;border-bottom:2px solid black;"><b>{{ payslip.salary_earned | toCurrency }}</b></td>
                          <td></td>
                          <td>-</td>
                          <td style="border-top:2px solid black;border-bottom:2px solid black;"><b>{{ payslip.salary_net | toCurrency  }}</b></td>
                        </tr>
                        <tr>
                          <td colspan=5><br/></td>
                        </tr>
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td><b>NET PAY : </b></td>
                          <td><b>{{ payslip.salary_net | toCurrency  }}</b></td>
                        </tr>
                        <tr>
                          <td colspan="5"><br/></td>
                        </tr>
                      </tbody>
                    </table>
                    <table class="table table-borderless" style="border:1px solid #dee2e6">
                      <tr>
                        <td colspan="5" align="center"></td>
                      </tr>
                      <tr>
                        <td colspan="5" align="center"></td>
                      </tr>
                      <tr>
                        <td colspan="2" align="center" class="p-0">Certified Correct:</td>
                        <td colspan="1" align="center" class="p-0"></td>
                        <td colspan="2" align="center" class="p-0"><u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;LOURDES C. PALILEO&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u></td>
                      </tr>
                      <tr>
                        <td colspan="2" align="center" class="p-0"></td>
                        <td colspan="1" align="center" class="p-0"></td>
                        <td colspan="2" align="center" class="p-0">ADMINISTRATIVE OFFICER V<br/></td>
                      </tr>
                    </table>
                  </div>

                </div>

              </div>
              </div>

              <div class="modal-footer">
                <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                <button v-print="'#printPayslip1'" type="submit" class="btn btn-default">Print</button>
              </div>


            </div>
          </div>
        </div>

        <!-- PAYSLIP 2nd -->
        <div class="modal fade" id="payslipmodal2" tabindex="-1" role="dialog" aria-labelledby="payslipModalLabel2" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header bg-orange">
                <h5 class="modal-title" id="payslipModalLabel2">Second Cut-off E-Payslip</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>


              <div class="modal-body">
              <div class="row-aligncenter">
                <div id="printPayslip2">
                  <div class="card-body table-responsive p-0">
                    <table class="table table-bordered">
                      <tbody>
                        <tr>
                          <td colspan="5" align="center"><h2><img src="img/tapi-logo.png" height="30px" /> &nbsp;TECHNOLOGY APPLICATION AND PROMOTION INSTITUTE</h2></td>
                        </tr>
                        <tr>
                          <td colspan="5" align="center"> <i>***** PAY SLIP *****<br/>for {{ payslip.month | monthwordformat  }} 16-{{ this.no_days }}, {{ payslip.year }}</i></td>
                        </tr>
                        <tr>
                          <td colspan="5">NAME : <b>{{ payslip.fullname }}</b></td>
                        </tr>
                        <tr>
                          <td colspan="5">DESIGNATION : <b>{{ payslip.position }}</b></td>
                        </tr>
                      </tbody>
                    </table>
                    <table class="table table-borderless" style="border:1px solid #dee2e6">
                      <thead>
                        <tr>
                          <th style="border:1px solid #dee2e6" class="card-header">
                            EARNINGS
                          </th>
                          <th style="border:1px solid #dee2e6" class="card-header">
                            AMOUNT
                          </th>
                          <th style="border:1px solid #dee2e6" class="card-header">
                            DEDUCTIONS
                          </th>
                          <th style="border:1px solid #dee2e6" class="card-header">
                            AMOUNT
                          </th>
                          <th style="border:1px solid #dee2e6" class="card-header">
                            NET AMOUNT
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td><b>MONTHLY SALARY</b></td>
                          <td>{{ payslip.salary_monthly | toCurrency }}</td>
                          <td>ABSENCES/ LATES</td>
                          <td>{{ payslip.lua | toCurrency}}</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td></td>
                          <td></td>
                          <td>E. WITHHOLDING TAX (2%)</td>
                          <td>{{ payslip.ewt_2 | toCurrency  }}</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td></td>
                          <td></td>
                          <td>E. WITHHOLDING TAX (5%)</td>
                          <td>{{ payslip.ewt_5 | toCurrency  }}</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td></td>
                          <td></td>
                          <td>PERCENTAGE TAX (1%)</td>
                          <td>{{ payslip.ewt_3 | toCurrency  }}</td>
                          <td></td>
                        </tr>
                        <tr>
                          <td></td>
                          <td style="border-top:2px solid black;border-bottom:2px solid black;"><b>{{ payslip.salary_earned | toCurrency }}</b></td>
                          <td></td>
                          <td>-</td>
                          <td style="border-top:2px solid black;border-bottom:2px solid black;"><b>{{ payslip.salary_net | toCurrency  }}</b></td>
                        </tr>
                        <tr>
                          <td colspan=5><br/></td>
                        </tr>
                        <tr>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td><b>NET PAY : </b></td>
                          <td><b>{{ payslip.salary_net | toCurrency  }}</b></td>
                        </tr>
                        <tr>
                          <td colspan="5"><br/></td>
                        </tr>
                      </tbody>
                    </table>
                    <table class="table table-borderless" style="border:1px solid #dee2e6">
                      <tr>
                        <td colspan="5" align="center"></td>
                      </tr>
                      <tr>
                        <td colspan="5" align="center"></td>
                      </tr>
                      <tr>
                        <td colspan="2" align="center">Certified Correct:</td>
                        <td colspan="1" align="center"></td>
                        <td colspan="2" align="center"><u>&nbsp;MARY ANN S. NICDAO&nbsp;</u></td>
                      </tr>
                      <tr>
                        <td colspan="2" align="center"></td>
                        <td colspan="1" align="center"></td>
                        <td colspan="2" align="center">AO II - HR Section</td>
                      </tr>
                    </table>
                  </div>

                </div>

              </div>
              </div>

              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button v-print="'#printPayslip2'" type="submit" class="btn btn-success">Print</button>
              </div>


            </div>
          </div>
        </div>

        <div class="modal fade" id="dtrmodal" tabindex="-1" role="dialog" aria-labelledby="dtrModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
            <div class="modal-content">
              <div class="modal-header bg-orange">
                <h5 class="modal-title" id="dtrModalLabel"><i class="icon fas fa-clock"></i> Timesheet</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="row-aligncenter">
                  <div id="">
                    <div class="card-body container">
                      <div class="row">
                      <div class="col-sm">
                        <button type="button" v-print="'#printDtr1'" class="btn btn-primary btn-lg btn-block mb-2"><i class="fa fa-print"></i> Print Timesheet for {{ this.month | tomonthword  }} 1-15, {{ this.year }}</button>
                        <div id="printDtr1">
                          <table class="table table-bordered">
                              <tbody>
                                <tr><td colspan="8"><b>PAY ENDING:</b> {{ this.month | tomonthword  }} 1-15, {{ this.year }}</td></tr>
                                <tr><td colspan="8"><b>NAME:</b> {{ profile.fname }} {{ profile.mname | midname }} {{ profile.lname }}</td></tr>
                                <tr><td colspan="8"><b>POSITION:</b> {{ profile.position }}</td></tr>
                                <tr><td colspan="8"><b>OFFICE:</b> DOST-TAPI / {{ profile.divisions.division_name }}</td></tr>
                                <tr class="bg-yellow">
                                  <th scope="col" class="pl-2">DAY</th>
                                  <th scope="col" class="pl-2">DATE</th>
                                  <th scope="col">IN (AM)</th>
                                  <th scope="col">OUT (AM)</th>
                                  <th scope="col">IN (PM)</th>
                                  <th scope="col">OUT (PM)</th>
                                  <th scope="col">REMARKS</th>
                                  <!-- <th class="card-header text-center">OT</th> -->
                                  <!--
                                  <th scope="col">HRS</th>
                                  <th scope="col">L/U/A</th>
                                  -->
                                  <!--
                                  <th scope="col">FLAG CEM</th>
                                  <th scope="col">NON-WORKING</th>
                                  -->
                                </tr>
                              </tbody>
                              <tbody>
                                <tr v-for="(att, index) in this.attendances" :key="att.id">
                                  <template v-if="index <= 14">
                                  <template v-if="isSaturday(att.year, att.month, att.day)">
                                    <th scope="row" class="bg-secondary pl-2">{{ att.year + att.month + att.day | getday }}</th>
                                    <td class="bg-secondary pl-2" data-title="DATE">{{ att.day }}</td>
                                    <td class="bg-secondary" data-title="IN (AM)">{{ att.in_am  | timeformat }}</td>
                                    <td class="bg-secondary" data-title="OUT (AM)">{{ att.out_am | timeformat }}</td>
                                    <td class="bg-secondary" data-title="IN (PM)">{{ att.in_pm  | timeformat }}</td>
                                    <td class="bg-secondary" data-title="OUT (PM)">{{ att.out_pm | timeformat }}</td>
                                    <td class="bg-secondary" data-title="REMARKS"></td>
                                    <!--  <td class="text-center bg-secondary">{{ att.ot }}</td> -->
                                    <!--
                                    <td class="bg-secondary" data-title="HRS">{{ subtotalRegular[index] }}</td>
                                    <td class="bg-secondary" data-title="L/U/A">{{ subtotalLate[index] }}</td>
                                    -->
                                    <!--
                                    <td class="bg-secondary" data-title="FLAG CEM">-</td>
                                    <td class="bg-secondary" data-title="NON-WORKING">-</td>
                                    <td class="bg-secondary" data-title=" ">-</td>
                                    -->
                                  </template>
                                  <template v-else-if="isSunday(att.year, att.month, att.day)">
                                    <th scope="row" class="bg-secondary pl-2">{{ att.year + att.month + att.day | getday }}</th>
                                    <td class="bg-secondary pl-2" data-title="DATE">{{ att.day }}</td>
                                    <td class="bg-secondary" data-title="IN (AM)">{{ att.in_am  | timeformat }}</td>
                                    <td class="bg-secondary" data-title="OUT (AM)">{{ att.out_am | timeformat }}</td>
                                    <td class="bg-secondary" data-title="IN (PM)">{{ att.in_pm  | timeformat }}</td>
                                    <td class="bg-secondary" data-title="OUT (PM)">{{ att.out_pm | timeformat }}</td>
                                    <td class="bg-secondary" data-title="REMARKS"></td>
                                  </template>
                                  <template v-else>
                                    <th scope="row" class="pl-2">{{ att.year + att.month + att.day | getday }}</th>
                                    <td class="pl-2" data-title="DATE">{{ att.day }}</td>
                                    <td data-title="IN (AM)">{{ att.in_am  | timeformat }}</td>
                                    <td data-title="OUT (AM)">{{ att.out_am | timeformat }}</td>
                                    <td data-title="IN (PM)">{{ att.in_pm  | timeformat }}</td>
                                    <td data-title="OUT (PM)">{{ att.out_pm | timeformat }}</td>
                                    <td data-title="REMARKS"></td>
                                  </template>
                                  </template>
                                </tr>
                                <!--
                                <tr v-show="this.officialbusinesses.total != 0" class="bg-warning">
                                    <td colspan="6" class="text-right font-weight-bold">TOTALS:</td>
                                    <td class="font-weight-bold">{{ total_hrs }}</td>
                                    <td class="text-center font-weight-bold">{{ total_ot }}</td>
                                    <td class="font-weight-bold">{{ total_late }}</td>

                                    <td colspan="3" class="text-center"></td>

                                </tr>
                                -->
                                <tr v-show="this.attendances.length === 0">
                                    <td colspan="9" class="text-center">No record available.<br/></td>
                                </tr>
                                <tr><td colspan="9">I hereby certify that the above records are true and correct.</td></tr>
                                <tr>
                                  <td colspan="9" class="text-left">
                                    <div class="row">
                                      <div class="col-6 text-left">
                                        <br/>________________________<br/>Supervisor's Signature
                                      </div>
                                      <div class="col-6 text-right">
                                        <br/>_______________________<br/>Employee's Signature
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div class="col-sm">
                          <button type="button" v-print="'#printDtr2'" class="btn btn-primary btn-lg btn-block mb-2"><i class="fa fa-print"></i> Print Timesheet for {{ this.month | tomonthword  }} 16-{{ this.no_days }}, {{ this.year }}</button>
                          <div id="printDtr2">
                            <table class="table table-bordered">
                              <tbody>
                                <tr><td colspan="8"><b>PAY ENDING:</b> {{ this.month | tomonthword  }} 16-{{ this.no_days }}, {{ this.year }}</td></tr>
                                <tr><td colspan="8"><b>NAME:</b> {{ profile.fname }} {{ profile.mname | midname }} {{ profile.lname }}</td></tr>
                                <tr><td colspan="8"><b>POSITION:</b> {{ profile.position }}</td></tr>
                                <tr><td colspan="8"><b>OFFICE:</b> DOST-TAPI / {{ profile.divisions.division_name }}</td></tr>
                                <tr class="bg-yellow">
                                  <th scope="col" class="pl-2">DAY</th>
                                  <th scope="col" class="pl-2">DATE</th>
                                  <th scope="col">IN (AM)</th>
                                  <th scope="col">OUT (AM)</th>
                                  <th scope="col">IN (PM)</th>
                                  <th scope="col">OUT (PM)</th>
                                  <th scope="col">REMARKS</th>
                                  <!-- <th class="card-header text-center">OT</th> -->
                                  <!--
                                  <th scope="col">HRS</th>
                                  <th scope="col">L/U/A</th>
                                  -->
                                  <!--
                                  <th scope="col">FLAG CEM</th>
                                  <th scope="col">NON-WORKING</th>
                                  -->
                                </tr>
                              </tbody>
                              <tbody>
                                <tr v-for="(att, index) in this.attendances" :key="att.id">
                                  <template v-if="index >= 15">
                                  <template v-if="isSaturday(att.year, att.month, att.day)">
                                    <th scope="row" class="bg-secondary pl-2">{{ att.year + att.month + att.day | getday }}</th>
                                    <td class="bg-secondary pl-2" data-title="DATE">{{ att.day }}</td>
                                    <td class="bg-secondary" data-title="IN (AM)">{{ att.in_am  | timeformat }}</td>
                                    <td class="bg-secondary" data-title="OUT (AM)">{{ att.out_am | timeformat }}</td>
                                    <td class="bg-secondary" data-title="IN (PM)">{{ att.in_pm  | timeformat }}</td>
                                    <td class="bg-secondary" data-title="OUT (PM)">{{ att.out_pm | timeformat }}</td>
                                    <td class="bg-secondary" data-title="REMARKS"></td>
                                    <!--  <td class="text-center bg-secondary">{{ att.ot }}</td> -->
                                    <!--
                                    <td class="bg-secondary" data-title="HRS">{{ subtotalRegular[index] }}</td>
                                    <td class="bg-secondary" data-title="L/U/A">{{ subtotalLate[index] }}</td>
                                    -->
                                    <!--
                                    <td class="bg-secondary" data-title="FLAG CEM">-</td>
                                    <td class="bg-secondary" data-title="NON-WORKING">-</td>
                                    <td class="bg-secondary" data-title=" ">-</td>
                                    -->
                                  </template>
                                  <template v-else-if="isSunday(att.year, att.month, att.day)">
                                    <th scope="row" class="bg-secondary pl-2">{{ att.year + att.month + att.day | getday }}</th>
                                    <td class="bg-secondary pl-2" data-title="DATE">{{ att.day }}</td>
                                    <td class="bg-secondary" data-title="IN (AM)">{{ att.in_am  | timeformat }}</td>
                                    <td class="bg-secondary" data-title="OUT (AM)">{{ att.out_am | timeformat }}</td>
                                    <td class="bg-secondary" data-title="IN (PM)">{{ att.in_pm  | timeformat }}</td>
                                    <td class="bg-secondary" data-title="OUT (PM)">{{ att.out_pm | timeformat }}</td>
                                    <td class="bg-secondary" data-title="REMARKS"></td>
                                    <!--  <td class="text-center bg-secondary">{{ att.ot }}</td>
                                    <td class="bg-secondary" data-title="HRS">{{ subtotalRegular[index] }}</td>
                                    <td class="bg-secondary" data-title="L/U/A">{{ subtotalLate[index] }}</td> -->
                                    <!--
                                    <td class="bg-secondary" data-title="FLAG CEM">-</td>
                                    <td class="bg-secondary" data-title="NON-WORKING">-</td>
                                    <td class="bg-secondary" data-title=" ">-</td>
                                    -->
                                  </template>
                                  <template v-else>
                                    <th scope="row" class="pl-2">{{ att.year + att.month + att.day | getday }}</th>
                                    <td class="pl-2" data-title="DATE">{{ att.day }}</td>
                                    <td data-title="IN (AM)">{{ att.in_am  | timeformat }}</td>
                                    <td data-title="OUT (AM)">{{ att.out_am | timeformat }}</td>
                                    <td data-title="IN (PM)">{{ att.in_pm  | timeformat }}</td>
                                    <td data-title="OUT (PM)">{{ att.out_pm | timeformat }}</td>
                                    <td data-title="REMARKS"></td>
                                    <!-- <td class="text-center">{{ att.ot }}</td> -->
                                    <!--
                                    <td data-title="HRS">{{ subtotalRegular[index] }}</td>
                                    <td data-title="L/U/A">{{ subtotalLate[index] }}</td>
                                    -->
                                  </template>
                                  </template>
                                </tr>
                                <!--
                                <tr v-show="this.officialbusinesses.total != 0" class="bg-warning">
                                    <td colspan="6" class="text-right font-weight-bold">TOTALS:</td>
                                    <td class="font-weight-bold">{{ total_hrs }}</td>
                                    <td class="text-center font-weight-bold">{{ total_ot }}</td>
                                    <td class="font-weight-bold">{{ total_late }}</td>

                                    <td colspan="3" class="text-center"></td>

                                </tr>
                                -->
                                <tr v-show="this.attendances.length === 0">
                                    <td colspan="9" class="text-center">No record available.<br/></td>
                                </tr>
                                <tr><td colspan="9">I hereby certify that the above records are true and correct.</td></tr>
                                <tr>
                                  <td colspan="9" class="text-left">
                                    <div class="row">
                                      <div class="col-6 text-left">
                                        <br/>________________________<br/>Supervisor's Signature
                                      </div>
                                      <div class="col-6 text-right">
                                        <br/>_______________________<br/>Employee's Signature
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>

        <div class="modal fade" id="sessionmodal" tabindex="-1" role="dialog" aria-labelledby="sessionModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-md" role="document">
            <div class="modal-content">
              <div class="modal-header bg-orange">
                <h5 class="modal-title" id="sessionModalLabel"><i class="icon fas fa-exclamation-triangle"></i> Security Auto-logout</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
              <div class="row-aligncenter">
                <div id="printPayslip2">
                  <div class="card-body">
                    You have left this browser idle for 10 minutes. Due to our data privacy policy, you will be
                    automatically logged out in <b>{{ timer }}</b> seconds.
                  </div>
                </div>
              </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
        <span style="display:none;">{{ isIdle }}</span>

    </div>
</template>

<script>
    import Form from 'vform';
    import moment from 'moment';

    export default {

        data() {
          return {
            timer: 30,
            a_in: '',
            can_process: false,
            can_approve: false,
            can_deny: false,
            can_accessemp: false,
            settings: {},
            selected: 1,
            month: '',
            year: '',
            employee_id: '',
            no_days: 0,
            no_days_2nd_cut: 0,
            deduction_lua_cutoff_1: 0,
            deduction_lua_cutoff_2: 0,
            hrs_cut_1: 0,
            hrs_cut_2: 0,
            users: {},
            payslip: {},
            officialbusinesses: [{

            }],
            firstcutoffprocess : {},
            secondcutoffprocess : {},
            profile: {
              divisions: {},
            },
            attendances: [{
                  in_am: '',
                  out_am: '',
                  in_pm: '',
                  out_pm: '',
                  in_ot: '',
                  out_ot: '',
                  daily_hours: '',
                  is_flagcem: '',
                  is_nonwork: '',
                  hrs: 0,
                  ot: 0,
                  late: 0,
              }],

            form: new Form({
              id: '',
              is_flagcem: '',
              is_nonwork: '',
              in_am: { HH: '', mm: ''},
              out_am: { HH: '', mm: ''},
              in_pm: { HH: '', mm: ''},
              out_pm: { HH: '', mm: ''},
              fin_am: '',
              fout_am: '',
              fin_pm: '',
              fout_pm: '',
              year: '',
              month: '',
              day: '',
              ob: '',
              biometric_id: '',
              description: '',
              obfile: '',
            }),

            form_approve_ob: new Form({
              in_am: '',
              out_am: '',
              in_pm: '',
              out_pm: '',
            }),

            form_cos_cut1: new Form({
              cut1_cos_year: '',
              cut1_cos_month: '',
              cut1_cos_cutoff: '',
              cut1_cos_monthly: '',
              cut1_cos_fullname: '',
              cut1_cos_position: '',
              cut1_cos_project: '',
              cut1_cos_earned: '',
              cut1_cos_hours: '',
              cut1_cos_lua_hrs: '',
              cut1_cos_lua_php: '',
              cut1_cos_tapiea_contrib_php: '',
              cut1_cos_salary: '',
              cut1_cos_ewt2: '',
              cut1_cos_ewt5: '',
              cut1_cos_ewt3: '',
              cut1_cos_net: '',
              cut1_cos_employee_id: '',
            }),

            form_cos_cut2: new Form({
              cut2_cos_year: '',
              cut2_cos_month: '',
              cut2_cos_cutoff: '',
              cut2_cos_monthly: '',
              cut2_cos_fullname: '',
              cut2_cos_position: '',
              cut2_cos_project: '',
              cut2_cos_earned: '',
              cut2_cos_hours: '',
              cut2_cos_lua_hrs: '',
              cut2_cos_lua_php: '',
              cut2_cos_salary: '',
              cut2_cos_ewt2: '',
              cut2_cos_ewt5: '',
              cut2_cos_ewt3: '',
              cut2_cos_net: '',
              cut2_cos_employee_id: '',
            })

          }

        },


        methods: {

          countDownTimer() {
            let timerId = setInterval(() => {
              this.timer -= 1;
              if (!this.$store.state.idleVue.isIdle) clearInterval(timerId);

              if (this.timer < 1) {
                clearInterval(timerId);
                // Your logout function should be over here
                document.getElementById('logout-form').submit();
              }
            }, 1000);

            this.timer = 30;
          },

          async loadPermission(mid) {
              const response = await axios.get("api/permission/" + this.$gate.showMyID() + "/" + mid).then(
              res => {
                if (!res.data || res.data.length == 0) {
                  return false;
                } else {
                  return true;
                }
              })

              const getResponse = async () => {
                const a = await response;

                switch (mid) {
                case 6:
                  this.can_process = a;
                  break;
                case 8:
                  this.can_approve = a;
                  break;
                case 9:
                  this.can_deny = a;
                  break;
                case 1:
                  this.can_accessemp = a;
                }
              };
              return getResponse()
          },

          init_form_cos_cut: function(){
	          this.form_cos_cut1.cut1_cos_fullname = this.profile.lname + ', ' + this.profile.fname + ' ' + this.profile.mname.substring(0, 1) + '.';
            this.form_cos_cut1.cut1_cos_monthly = this.profile.salary;
            this.form_cos_cut1.cut1_cos_position = this.profile.position;
            this.form_cos_cut1.cut1_cos_project = this.profile.project;
            this.form_cos_cut1.cut1_cos_year = this.year;
            this.form_cos_cut1.cut1_cos_month = this.month;
            this.form_cos_cut1.cut1_cos_cutoff = '1';
            this.form_cos_cut1.cut1_cos_employee_id = this.employee_id;
            this.form_cos_cut1.cut1_cos_tapiea_contrib_php = this.profile.tapiea_contribution;

            this.form_cos_cut2.cut2_cos_fullname = this.profile.lname + ', ' + this.profile.fname + ' ' + this.profile.mname.substring(0, 1) + '.';
            this.form_cos_cut2.cut2_cos_monthly = this.profile.salary;
            this.form_cos_cut2.cut2_cos_position = this.profile.position;
            this.form_cos_cut2.cut2_cos_project = this.profile.project;
            this.form_cos_cut2.cut2_cos_year = this.year;
            this.form_cos_cut2.cut2_cos_month = this.month;
            this.form_cos_cut2.cut2_cos_cutoff = '2';
            this.form_cos_cut2.cut2_cos_employee_id = this.employee_id;
          },


          processCosCut1() {

            this.init_form_cos_cut();



            swal.fire({

              title: 'Are you sure?',
              text: "You won't be able to revert this!",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes, process 1st cutoff payroll!'
              }).then((result)=>{

                if (result.value) {
                  //send delete ajax tru api request to server
                  this.form_cos_cut1.post('api/payrolls/firstcutoff')
                  .then(()=>{

                    swal.fire(
                      'Processed!',
                      'First cutoff payroll has been processed.',
                      'success'
                    );

                    this.getAttendance();

                  }).catch(()=>{

                    swal.fire(
                      'Failed!',
                      'There was something wrong',
                      'warning'
                    )

                  });


                }
            })

          },

          processCosCut2() {
            this.init_form_cos_cut();
            swal.fire({

              title: 'Are you sure?',
              text: "You won't be able to revert this!",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes, process 2nd cutoff payroll!'
              }).then((result)=>{

                if (result.value) {
                  //send delete ajax tru api request to server
                  this.form_cos_cut2.post('api/payrolls/secondcutoff')
                  .then(()=>{

                    swal.fire(
                      'Processed!',
                      'Second cutoff payroll has been processed.',
                      'success'
                    );

                    this.getAttendance();

                  }).catch(()=>{

                    swal.fire(
                      'Failed!',
                      'There was something wrong',
                      'warning'
                    )

                  });
                }

            })
          },



          createOb() {
            this.$Progress.start();

             if (!this.form.in_am)
            {
              toastr.fire({
                icon: 'error',
                title: 'Please select time IN (AM) for OB'
              });
              this.$Progress.fail();
            }

            /*
            else if (this.form.out_am === "00:00")
            {
              toastr.fire({
                icon: 'error',
                title: 'Please select time OUT (AM) for OB'
              });
              this.$Progress.fail();
            }

            else if (this.form.in_pm === "00:00")
            {
              toastr.fire({
                icon: 'error',
                title: 'Please select time IN (PM) for OB'
              });
              this.$Progress.fail();
            }*/

            else if (!this.form.out_pm)
            {
              toastr.fire({
                icon: 'error',
                title: 'Please select time OUT (PM) for OB'
              });
              this.$Progress.fail();
            }

            else
            {

              //set f_form if has value
              /*
              if (this.form.in_am && this.form.in_am.length < 19) {
                this.form.in_am = this.form.year + '-' + this.form.month + '-' + this.form.day + ' ' + this.form.in_am.HH + ':' + this.form.in_am.mm + ':00';
              } else {
                this.form.in_am = '';
              }


              if (this.form.out_am && this.form.out_am.length < 19) {
                this.form.out_am = this.form.year + '-' + this.form.month + '-' + this.form.day + ' ' + this.form.out_am.HH + ':' + this.form.out_am.mm + ':00';
              } else {
                this.form.out_am = '';
              }

              if (this.form.out_am && this.form.in_pm.length < 19) {
                this.form.in_pm = this.form.year + '-' + this.form.month + '-' + this.form.day + ' ' + this.form.in_pm.HH + ':' + this.form.in_pm.mm + ':00';
              } else {
                this.form.in_pm = '';
              }

              if (this.form.out_pm && this.form.out_pm.length < 19) {
                this.form.fout_pm = this.form.year + '-' + this.form.month + '-' + this.form.day + ' ' + this.form.out_pm.HH + ':' + this.form.out_pm.mm + ':00';
              } else {
                this.form.out_pm = '';
              }
              */




              this.form.post('api/officialbusinesses').then(()=>{
                this.getAttendance();
                $('#obmodal').modal('hide')

                toastr.fire({
                  icon: 'success',
                  title: 'OB created successfully'
                })

                this.$Progress.finish();

              }).catch(()=>{

                toastr.fire({
                  icon: 'error',
                  title: 'OB creation failed'
                });

                this.$Progress.fail();

              });
            }


          },



          approveOb(oid, obiometric_id, oyear, omonth, oday, oin_am, oout_am, oin_pm, oout_pm, e) {

            //this.form_approve_ob.in_am = oyear + "-" + omonth + "-" + oday + " " + oin_am + ":00";
            //this.form_approve_ob.out_am = oyear + "-" + omonth + "-" + oday + " " + oout_am + ":00";
            //this.form_approve_ob.in_pm = oyear+ "-" + omonth + "-" + oday + " " + oin_pm + ":00";
            //this.form_approve_ob.out_pm = oyear + "-" + omonth + "-" + oday + " " + oout_pm + ":00";

            this.form_approve_ob.in_am = oin_am;
            this.form_approve_ob.out_am = oout_am;
            this.form_approve_ob.in_pm = oin_pm;
            this.form_approve_ob.out_pm = oout_pm;

            swal.fire({

              title: 'Are you sure to approve OB for ' + oyear + '-' + omonth + '-' + oday + '?',
              text: "You won't be able to revert this!",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes, approve this OB!'
              }).then((result)=>{

                if (result.value) {
                  //send delete ajax tru api request to server


                  this.form_approve_ob.put('api/attendances/' + obiometric_id + '/' +  oyear + '/' +  omonth + '/' +  oday)
                  .then(()=>{

                    this.form_approve_ob.put('api/officialbusinesses/' + oid + '/Approved');

                    swal.fire(
                      'Approved!',
                      'OB has been approved.',
                      'success'
                    );

                    //Trigger.$emit('RefreshData');
                    this.getAttendance();

                  }).catch(()=>{

                    swal.fire(
                      'Failed!',
                      'There was something wrong',
                      'warning'
                    )

                  });


                }



            })

          },

          denyOb(oid, oyear, omonth, oday, e) {

            swal.fire({

              title: 'Are you sure to deny OB for ' + oyear + '-' + omonth + '-' + oday + '?',
              text: "You won't be able to revert this!",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes, deny this OB!'
              }).then((result)=>{

                if (result.value) {

                  this.form_approve_ob.put('api/officialbusinesses/' + oid + '/Denied')
                  .then(()=>{

                    swal.fire(
                      'Denied!',
                      'OB has been denied.',
                      'success'
                    );
                    this.getAttendance();

                  }).catch(()=>{

                    swal.fire(
                      'Failed!',
                      'There was something wrong',
                      'warning'
                    )
                  });
                }
            })
          },

          cancelOb(oid, oyear, omonth, oday, e) {

            swal.fire({

              title: 'Are you sure to cancel OB for ' + oyear + '-' + omonth + '-' + oday + '?',
              text: "You won't be able to revert this!",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes, cancel this OB!'
              }).then((result)=>{

                if (result.value) {

                  this.form_approve_ob.put('api/officialbusinesses/' + oid + '/Cancelled')
                  .then(()=>{

                    swal.fire(
                      'Cancelled!',
                      'OB has been cancelled.',
                      'success'
                    );
                    this.getAttendance();

                  }).catch(()=>{

                    swal.fire(
                      'Failed!',
                      'There was something wrong',
                      'warning'
                    )
                  });
                }
            })
          },

          updateAttendance() {

            this.$Progress.start();
            //console.log(this.form.out_pm);

            if (this.form.in_am === "00:00")
            {
              //console.log("AM in should not equal to 12am");
              toastr.fire({
                icon: 'error',
                title: 'AM in should not equal to 12am'
              });
              this.$Progress.fail();
            }

            else if (this.form.out_am === "00:00")
            {
              toastr.fire({
                icon: 'error',
                title: 'AM out should not equal to 12am'
              });
              this.$Progress.fail();
            }

            else if (this.form.in_pm === "00:00")
            {

              toastr.fire({
                icon: 'error',
                title: 'PM in should not equal to 12am'
              });
              this.$Progress.fail();
            }

            else if (this.form.out_pm === "00:00")
            {
              toastr.fire({
                icon: 'error',
                title: 'PM out should not equal to 12am'
              });
              this.$Progress.fail();
            }



            else
            {


              /*
              if (this.form.in_am && this.form.in_am.length < 19) {
                this.form.in_am = this.form.year + "-" + this.form.month + "-" + this.form.day + " " + this.form.in_am + ":00";
              }

              if (this.form.out_am && this.form.out_am.length < 19) {
                this.form.out_am = this.form.year + "-" + this.form.month + "-" + this.form.day + " " + this.form.out_am + ":00";
              }

              if (this.form.in_pm && this.form.in_pm.length < 19) {
                this.form.in_pm = this.form.year + "-" + this.form.month + "-" + this.form.day + " " + this.form.in_pm + ":00";
              }

              if (this.form.out_pm && this.form.out_pm.length < 19) {
                this.form.out_pm = this.form.year + "-" + this.form.month + "-" + this.form.day + " " + this.form.out_pm + ":00";
              }*/


              this.form.put('api/attendances/' + this.form.id).then(()=>{
                this.getAttendance();
                $('#attmodal').modal('hide')

                toastr.fire({
                  icon: 'success',
                  title: 'Attendance updated successfully'
                })

                this.$Progress.finish();

              }).catch(()=>{

                toastr.fire({
                  icon: 'error',
                  title: 'Attendance updating failed'
                });

                this.$Progress.fail();

              });



            }



          },

          overrideModal(att) {

            $('#attmodal').modal('show');
            this.form.reset();
            this.form.clear();
            //this.form.fill(att);

            this.form.id = att.id;
            this.form.is_flagcem = att.is_flagcem;
            this.form.is_nonwork = att.is_nonwork;
            this.form.year = att.year;
            this.form.month = att.month;
            this.form.day = att.day;
            this.form.biometric_id = att.biometric_id;
            this.form.description = att.description;

            if(att.in_am) {
              this.form.in_am.HH = att.in_am.substring(11,13)
              this.form.in_am.mm = att.in_am.substring(14,16)
            } else {
              this.form.out_pm.HH = ''
              this.form.out_pm.mm = ''
            }

            if(att.out_am) {
              this.form.out_am.HH = att.out_am.substring(11,13)
              this.form.out_am.mm = att.out_am.substring(14,16)
            } else {
              this.form.out_pm.HH = ''
              this.form.out_pm.mm = ''
            }

            if(att.in_pm) {
              this.form.in_pm.HH = att.in_pm.substring(11,13)
              this.form.in_pm.mm = att.in_pm.substring(14,16)
            } else {
              this.form.out_pm.HH = ''
              this.form.out_pm.mm = ''
            }

            if(att.out_pm) {
              this.form.out_pm.HH = att.out_pm.substring(11,13)
              this.form.out_pm.mm = att.out_pm.substring(14,16)
            } else {
              this.form.out_pm.HH = ''
              this.form.out_pm.mm = ''
            }

            /*
            if (this.form.in_am == null) {
              this.form.in_am = "";
            }

            if (this.form.out_am == null) {
              this.form.out_am = "";
            }

            if (this.form.in_pm == null) {
              this.form.in_pm = "";
            }

            if (this.form.out_pm == null) {
              this.form.out_pm = "";
            } */


          },

          dtrModal(cutoff) {
            $('#dtrmodal').modal('show');
          },

          payslipModal(cutoff) {

            const today = new Date();
            const curr_year = today.getFullYear();
            const curr_month = moment(String(today.getMonth() + 1)).format('MM');

            let empid = "";
            let yr = "";
            let mo = "";

            if ( this.employee_id == '' ) {
              empid = this.$gate.showMyID();
            } else {
              empid = this.employee_id;
            }

            if(this.year == '' || this.month == '') {
              yr = curr_year;
              mo = curr_month;
              this.year = curr_year;
              this.month = curr_month;
              this.employee_id = this.$gate.showMyID();
            } else {
              yr = this.year;
              mo = this.month;
            }

            axios.get("api/attendances/payslip/" + empid + "/" + yr + "/" + mo + "/" + cutoff).then(({ data }) => (this.payslip = data));
            $('#payslipmodal' + cutoff).modal('show');

          },

          //payslipModal2() {
          //  axios.get("api/attendances/payslip/" + empid + "/" + yr + "/" + mo + "/2").then(({ data }) => (this.payslip = data));
          //  $('#payslipmodal2').modal('show');
          //},

          formatToDateTime() {
            this.form.fin_am = '';
            this.form.fout_am = '';
            this.form.fin_pm = '';
            this.form.fout_pm = '';

            if (this.form.in_am.HH && this.form.in_am.mm ) {
              this.form.fin_am = this.form.year + '-' + this.form.month + '-' + this.form.day + ' ' + this.form.in_am.HH + ':' + this.form.in_am.mm + ':00';
            } else {
              this.form.fin_am = '';
            }

            if (this.form.out_am.HH && this.form.out_am.mm) {
               this.form.fout_am = this.form.year + '-' + this.form.month + '-' + this.form.day + ' ' + this.form.out_am.HH + ':' + this.form.out_am.mm + ':00';
            } else {
              this.form.fout_am = '';
            }

            if (this.form.in_pm.HH && this.form.in_pm.mm) {
              this.form.fin_pm = this.form.year + '-' + this.form.month + '-' + this.form.day + ' ' + this.form.in_pm.HH + ':' + this.form.in_pm.mm + ':00';
            } else {
              this.form.fin_pm = '';
            }

            if (this.form.out_pm.HH && this.form.out_pm.mm) {
              this.form.fout_pm = this.form.year + '-' + this.form.month + '-' + this.form.day + ' ' + this.form.out_pm.HH + ':' + this.form.out_pm.mm + ':00';
            } else {
              this.form.fout_pm = '';
            }

          },

          officialbusinessModal(att, type) {

            $('#obmodal').modal('show');

            this.form.fin_am = "";
            this.form.fout_am = "";
            this.form.fin_pm = "";
            this.form.fout_pm = "";

            this.form.reset();
            this.form.clear();
            //console.log(att)
            //this.form.fill(att);

            this.form.id = att.id;
            this.form.is_flagcem = att.is_flagcem;
            this.form.is_nonwork = att.is_nonwork;
            this.form.year = att.year;
            this.form.month = att.month;
            this.form.day = att.day;
            this.form.ob = type;
            this.form.biometric_id = att.biometric_id;
            this.form.description = att.description;

            if(att.in_am) {
              this.form.in_am.HH = att.in_am.substring(11,13)
              this.form.in_am.mm = att.in_am.substring(14,16)
            } else {
              this.form.out_pm.HH = ''
              this.form.out_pm.mm = ''
            }

            if(att.out_am) {
              this.form.out_am.HH = att.out_am.substring(11,13)
              this.form.out_am.mm = att.out_am.substring(14,16)
            } else {
              this.form.out_pm.HH = ''
              this.form.out_pm.mm = ''
            }

            if(att.in_pm) {
              this.form.in_pm.HH = att.in_pm.substring(11,13)
              this.form.in_pm.mm = att.in_pm.substring(14,16)
            } else {
              this.form.out_pm.HH = ''
              this.form.out_pm.mm = ''
            }

            if(att.out_pm) {
              this.form.out_pm.HH = att.out_pm.substring(11,13)
              this.form.out_pm.mm = att.out_pm.substring(14,16)
            } else {
              this.form.out_pm.HH = ''
              this.form.out_pm.mm = ''
            }



            /*
            if (this.form.in_am == null) {
              this.form.in_am = "";
            } else {
              if (this.form.in_am.length == 19) {
                this.form.in_am = this.form.in_am.substring(11,  this.form.in_am.length - 3);
              }
            }

            if (this.form.out_am == null) {
               this.form.out_am = "";
            } else {
              if (this.form.out_am.length == 19) {
                this.form.out_am = this.form.out_am.substring(11,  this.form.out_am.length - 3);
              }
            }

            if (this.form.in_pm == null) {
              this.form.in_pm = "";
            } else {
              if (this.form.in_pm.length == 19) {
                this.form.in_pm = this.form.in_pm.substring(11,  this.form.in_pm.length - 3);
              }
            }

            if (this.form.out_pm == null) {
              this.form.out_pm = "";
            } else {
              if (this.form.out_pm.length == 19) {
                this.form.out_pm = this.form.out_pm.substring(11,  this.form.out_pm.length - 3);
              }
            }
            */


           this.formatToDateTime();

          },


          officialbusinessModalAdvance(type, obfile) {

            //asas
            const today = new Date();
            const curr_year = today.getFullYear();
            const curr_month = moment(String(today.getMonth() + 1)).format('MM');
            const curr_day = moment(String(today)).format('DD');
            //console.log(curr_year + curr_month + curr_day);
            //console.log(today);

            $('#obmodal').modal('show');
            this.form.reset();
            this.form.clear();
            this.form.year = curr_year
            this.form.month =  curr_month
            this.form.day =  curr_day
            this.form.in_am = "08:00"
            this.form.out_am = "12:00"
            this.form.in_pm = "12:00"
            this.form.out_pm = "17:00"

            this.form.biometric_id = this.profile.biometric_id;
            this.form.ob = type;
            this.form.obfile = obfile;
            //console.log(this.form.in_am);

          },

          setDayType(year, month, day, type, e) {
            const checked = e.target.checked;
            //console.log(year + month + day)
            //console.log(checked)

            this.$Progress.start();

            this.form.put('api/attendances/' + year + '/' + month + '/' + day + '/' + type + '/' + checked).then(()=>{

                    //Trigger.$emit('RefreshData');
                    this.getAttendance();
                    $('#usermodal').modal('hide')

                    toastr.fire({
                      icon: 'success',
                      title: 'Day type setting success'
                    })

                    this.$Progress.finish();

                  }).catch(()=>{

                    toastr.fire({
                      icon: 'error',
                      title: 'Day type setting failed'
                    });

                    this.$Progress.fail();

                  });

          },

          setDailyHour(year, month, biometric_id, hrs) {
            this.$Progress.start();
            this.form.put('api/attendances/dailyhrs/' + year + '/' + month + '/' + biometric_id + '/' + hrs).then(()=>{
                    //Trigger.$emit('RefreshData');
                    this.getAttendance();
                    toastr.fire({
                      icon: 'success',
                      title: 'Daily hour setting success'
                    })
                    this.$Progress.finish();
                  }).catch(()=>{
                    toastr.fire({
                      icon: 'error',
                      title: 'Daily hour setting failed'
                    });
                    this.$Progress.fail();
                  });
          },

          getAttendance(){
            this.$Progress.start();

            const today = new Date();
            const curr_year = today.getFullYear();
            const curr_month = moment(String(today.getMonth() + 1)).format('MM');

            let empid = "";
            let yr = "";
            let mo = "";

            if ( this.employee_id == '' ) {
              empid = this.$gate.showMyID();
            } else {
              empid = this.employee_id;
            }

            if(this.year == '' || this.month == '') {
              yr = curr_year;
              mo = curr_month;
              this.year = curr_year;
              this.month = curr_month;
              this.employee_id = this.$gate.showMyID();
            } else {
              yr = this.year;
              mo = this.month;
            }

            axios.get("api/users/" + empid + "/attendances/" + yr + "/" + mo).then(({ data }) => (this.profile = data));
            axios.get("api/users/" + empid + "/attendances/" + yr + "/" + mo).then(({ data }) => (this.attendances = data.attendances));
            axios.get("api/users/" + empid + "/officialbusinesses/" + yr + "/" + mo).then(({ data }) => (this.officialbusinesses = data.officialbusinesses));
            this.no_days = Number(moment( this.year + "-" + this.month, "YYYY-MM").daysInMonth());
            this.no_days_2nd_cut = Number(moment( this.year + "-" + this.month, "YYYY-MM").daysInMonth()) - 15;
            axios.get("api/users/" + empid + "/officialbusinesses/" + yr + "/" + mo).then(({ data }) => (this.officialbusinesses = data.officialbusinesses));
            axios.get("api/payrolls/processed/" + empid + "/" + yr + "/" + mo + "/1").then(({ data }) => (this.firstcutoffprocess = data));
            axios.get("api/payrolls/processed/" + empid + "/" + yr + "/" + mo + "/2").then(({ data }) => (this.secondcutoffprocess = data));
            //axios.get("api/attendances/payslip/" + empid + "/" + yr + "/" + mo + "/1").then(({ data }) => (this.payslip = data));


            this.$Progress.finish();
          },

          getProfilePhoto() {
            let photo = "img/profile/" + this.profile.photo;
            //console.log(photo);
            return photo;
          },

          isSaturday: function (yr, mo, da) {

              return moment(yr + mo + da).format('dddd') === 'Saturday';
              calculateTotal();
          },

          isSunday: function (yr, mo, da) {

              return moment(yr + mo + da).format('dddd') === 'Sunday';
              calculateTotal();
          },

        },

        computed: {

          second() {
            return this.time / 1000;
          },

          isIdle() {
            const idlestat =  this.$store.state.idleVue.isIdle;
            if (idlestat == true) {
              $('#sessionmodal').modal('show');
              this.countDownTimer();
            } else {
              $('#sessionmodal').modal('hide');
            }

            return idlestat;

          },

          employeeNames(){
            return this.users.map( e => e.lname);
          },

          compute_hourly: function(){
            let daily = 0;
            let hourly = 0;
            daily = this.profile.salary / 22;
            hourly = daily / 8;
            //hourly = this.compute_daily / 8;
            return hourly;
          },

          compute_daysofservice: function(){
            let daysofservice = 0;
            var a = moment(this.profile.contract_end,"YYYY-MM-DD");
            var b = moment(this.profile.contract_start,"YYYY-MM-DD");

            if (this.profile.contract_end == null || this.profile.contract_start == null) {
              daysofservice = 0;
            } else {
              daysofservice =  a.diff(b, 'days') + 1
            }

            //console.log(daysofservice)
            return daysofservice
          },

          compute_daily: function(){
            let daily = 0;
            daily = this.profile.salary / this.no_days;
            return daily;
          },

          compute_earned_1: function(){
            this.form_cos_cut1.cut1_cos_earned = this.compute_daily * 15;
            return this.compute_daily * 15;
          },

          compute_earned_2: function(){
            this.form_cos_cut2.cut2_cos_earned = this.compute_daily * this.no_days_2nd_cut;
            return this.compute_daily * this.no_days_2nd_cut;
          },

          years() {
            const year = new Date().getFullYear()
            return Array.from({length: year - 2018}, (value, index) => 2019 + index)
          },


          total_ot: function(){
            let total_ot = [];
            Object.entries(this.attendances).forEach(([key, val]) => {
                total_ot.push(val.ot) // the value of the current key.
            });
            return total_ot.reduce(function(total_ot, num){ return total_ot + num }, 0);
          },


          subtotalRegular() {
            let total_hrs = [];
            let tot_hrs = 0;

            let tot_reg = 0;
            let tot_late = 0;
            let tot_undertime = 0;
            let tot_tardiness = 0;
            let time_in = "";

            let max_in = "";
            let graceperiod_flagcem  = "";
            let graceperiod = "";
            let max_out_flagcem = "";
            let max_out = "";

            return this.attendances.map((att) => {

              if( att.in_am != null ) {
                if( att.out_pm != null ) {

                  const yr = att.in_am.substring(0, 4);
                  const mo = att.in_am.substring(5, 7);
                  const dy = att.in_am.substring(8, 10);

                  max_in               = yr  + "-" + mo + "-" + dy + " 07:00:00";
                  graceperiod_flagcem  = yr  + "-" + mo + "-" + dy + " 08:00:00";
                  graceperiod          = yr  + "-" + mo + "-" + dy + " 09:00:00";

                  if (att.daily_hours == 8) {
                    max_out_flagcem      = yr  + "-" + mo + "-" + dy + " 17:00:00";
                    max_out              = yr  + "-" + mo + "-" + dy + " 18:00:00";
                  } else if (att.daily_hours == 7) {
                    max_out_flagcem      = yr  + "-" + mo + "-" + dy + " 16:00:00";
                    max_out              = yr  + "-" + mo + "-" + dy + " 17:00:00";
                  } else if (att.daily_hours == 6) {
                    max_out_flagcem      = yr  + "-" + mo + "-" + dy + " 15:00:00";
                    max_out              = yr  + "-" + mo + "-" + dy + " 16:00:00";
                  }

                  // Check if in_am is earlier than max_in
                  if(att.in_am < max_in) {
                    time_in = max_in;
                    tot_reg = Number(moment(att.out_pm).seconds(0).diff(moment(time_in), 'minutes')) - 60 //minus 60 mins for lunch break

                    if(tot_reg > (att.daily_hours * 60)) {
                      tot_reg = att.daily_hours * 60;
                    } else {
                      tot_tardiness = (att.daily_hours * 60) - tot_reg;
                    }


                    //console.log("e|" + time_in + "|" + tot_tardiness + "|" + tot_reg)
                    return Math.floor(tot_reg / 60) +  ':' + tot_reg % 60;
                    total_hrs.push(tot_reg)
                  } else {


                    time_in = att.in_am;

                    // Check if the day is flag ceremony
                    if(att.is_flagcem == 'checked') {

                      //get late after 8am
                      tot_late = Number(moment(time_in).seconds(0).diff(moment(graceperiod_flagcem).seconds(0), 'minutes'));

                      //set late as 0 if difference is negative
                      if(tot_late < 0) { tot_late = 0; }

                      //get regular hours
                      if (att.out_pm > max_out_flagcem) {
                        tot_reg = (Number(moment(max_out_flagcem).seconds(0).diff(moment(time_in).seconds(0), 'minutes')) - 60) // - tot_late
                      } else {
                        tot_reg = (Number(moment(att.out_pm).seconds(0).diff(moment(time_in).seconds(0), 'minutes')) - 60) // - tot_late
                      }

                      if(tot_reg > att.daily_hours * 60) {
                        tot_reg = att.daily_hours * 60;
                      }
                      tot_tardiness = (att.daily_hours * 60) - tot_reg

                      //console.log("f|" + time_in + "|" + tot_tardiness + "|" + tot_reg)
                      return Math.floor(tot_reg / 60) +  ':' + tot_reg % 60;
                      total_hrs.push(tot_reg)

                    } else {

                      //get late after 9am
                      tot_late = Number(moment(time_in).seconds(0).diff(moment(graceperiod).seconds(0), 'minutes'));

                      //set late as 0 if difference is negative
                      if(tot_late < 0) { tot_late = 0; }

                      //get regular hours
                      if (att.out_pm > max_out) {
                        tot_reg = (Number(moment(max_out).seconds(0).diff(moment(time_in).seconds(0), 'minutes')) - 60) //- tot_late
                      } else {
                        tot_reg = (Number(moment(att.out_pm).seconds(0).diff(moment(time_in).seconds(0), 'minutes')) - 60) //- tot_late
                      }

                      if(tot_reg > att.daily_hours * 60) {
                        tot_reg = att.daily_hours * 60;
                      }
                      tot_tardiness = (att.daily_hours * 60) - tot_reg

                      //console.log("n|" + time_in + "|" + tot_tardiness + "|" + tot_reg)
                      return Math.floor(tot_reg / 60) +  ':' + tot_reg % 60;
                      total_hrs.push(tot_reg)

                    }
                  }

                //return this if no out_pm
                } else {
                  return Math.floor(0 / 60) +  ':' + 0 % 60;
                }

              //return this if no in_am
              } else {
                return Math.floor(0 / 60) +  ':' + 0 % 60;
              }





            });

            tot_hrs = total_hrs.reduce(function(total_hrs, num){ return total_hrs + num }, 0);
            //console.log(tot_hrs)

          },

          total_hrs_cutoff_1: function() {

            let total_hrs = [];
            let tot_hrs = 0;

            let tot_reg = 0;
            let tot_late = 0;
            let tot_undertime = 0;
            let tot_tardiness = 0;
            let time_in = "";

            let max_in = "";
            let graceperiod_flagcem  = "";
            let graceperiod = "";
            let max_out_flagcem = "";
            let max_out = "";

            Object.entries(this.attendances).forEach(([key, att]) => {

              if( att.in_am != null ) {
                if( att.out_pm != null ) {

                  const yr = att.in_am.substring(0, 4);
                  const mo = att.in_am.substring(5, 7);
                  const dy = att.in_am.substring(8, 10);

                  max_in               = yr  + "-" + mo + "-" + dy + " 07:00:00";
                  graceperiod_flagcem  = yr  + "-" + mo + "-" + dy + " 08:00:00";
                  graceperiod          = yr  + "-" + mo + "-" + dy + " 09:00:00";
                  if (att.daily_hours == 8) {
                    max_out_flagcem      = yr  + "-" + mo + "-" + dy + " 17:00:00";
                    max_out              = yr  + "-" + mo + "-" + dy + " 18:00:00";
                  } else if (att.daily_hours == 7) {
                    max_out_flagcem      = yr  + "-" + mo + "-" + dy + " 16:00:00";
                    max_out              = yr  + "-" + mo + "-" + dy + " 17:00:00";
                  } else if (att.daily_hours == 6) {
                    max_out_flagcem      = yr  + "-" + mo + "-" + dy + " 15:00:00";
                    max_out              = yr  + "-" + mo + "-" + dy + " 16:00:00";
                  }

                  // Check if in_am is earlier than max_in
                  if(att.in_am < max_in) {
                    time_in = max_in;
                    tot_reg = Number(moment(att.out_pm).seconds(0).diff(moment(time_in), 'minutes')) - 60
                    if(tot_reg > (att.daily_hours * 60)) {
                      tot_reg = att.daily_hours * 60;
                    }
                    tot_tardiness = (att.daily_hours * 60) - tot_reg;


                    if (key < 15) {
                      total_hrs.push(tot_reg)
                    }


                  } else {


                    time_in = att.in_am;

                    // Check if the day is flag ceremony
                    if(att.is_flagcem == 'checked') {

                      //get late after 8am
                      tot_late = Number(moment(time_in).seconds(0).diff(moment(graceperiod_flagcem).seconds(0), 'minutes'));

                      //set late as 0 if difference is negative
                      if(tot_late < 0) { tot_late = 0; }

                      //get regular hours
                      if (att.out_pm > max_out_flagcem) {
                        tot_reg = (Number(moment(max_out_flagcem).seconds(0).diff(moment(time_in).seconds(0), 'minutes')) - 60) // - tot_late
                      } else {
                        tot_reg = (Number(moment(att.out_pm).seconds(0).diff(moment(time_in).seconds(0), 'minutes')) - 60) // - tot_late
                      }

                      if(tot_reg > (att.daily_hours * 60)) {
                        tot_reg = att.daily_hours * 60;
                      }
                      tot_tardiness = (att.daily_hours * 60) - tot_reg;

                      if (key < 15) {
                        total_hrs.push(tot_reg)
                      }

                    } else {

                      //get late after 9am
                      tot_late = Number(moment(time_in).seconds(0).diff(moment(graceperiod).seconds(0), 'minutes'));

                      //set late as 0 if difference is negative
                      if(tot_late < 0) { tot_late = 0; }

                      //get regular hours
                      if (att.out_pm > max_out) {
                        tot_reg = (Number(moment(max_out).seconds(0).diff(moment(time_in).seconds(0), 'minutes')) - 60) //- tot_late
                      } else {
                        tot_reg = (Number(moment(att.out_pm).seconds(0).diff(moment(time_in).seconds(0), 'minutes')) - 60) //- tot_late
                      }

                      if(tot_reg > (att.daily_hours * 60)) {
                        tot_reg = att.daily_hours * 60;
                      }
                      tot_tardiness = (att.daily_hours * 60) - tot_reg;

                      if (key < 15) {
                        total_hrs.push(tot_reg)
                      }

                    }
                  }

                //return this if no out_pm
                } else {
                  total_hrs.push(0)
                }

              //return this if no in_am
              } else {
                total_hrs.push(0)
              }



            });

            tot_hrs = total_hrs.reduce(function(total_hrs, num){ return total_hrs + num }, 0);
            //return tot_hrs; //Math.floor(tot_hrs / 60) +  ':' + tot_hrs % 60;
            this.form_cos_cut1.cut1_cos_hours = tot_hrs / 60;
            return tot_hrs / 60;
          },

          total_hrs_cutoff_2: function() {

            let total_hrs = [];
            let tot_hrs = 0;

            let tot_reg = 0;
            let tot_late = 0;
            let tot_undertime = 0;
            let tot_tardiness = 0;
            let time_in = "";

            let max_in = "";
            let graceperiod_flagcem  = "";
            let graceperiod = "";
            let max_out_flagcem = "";
            let max_out = "";

            Object.entries(this.attendances).forEach(([key, att]) => {

              if( att.in_am != null ) {
                if( att.out_pm != null ) {

                  const yr = att.in_am.substring(0, 4);
                  const mo = att.in_am.substring(5, 7);
                  const dy = att.in_am.substring(8, 10);

                  max_in               = yr  + "-" + mo + "-" + dy + " 07:00:00";
                  graceperiod_flagcem  = yr  + "-" + mo + "-" + dy + " 08:00:00";
                  graceperiod          = yr  + "-" + mo + "-" + dy + " 09:00:00";
                  if (att.daily_hours == 8) {
                    max_out_flagcem      = yr  + "-" + mo + "-" + dy + " 17:00:00";
                    max_out              = yr  + "-" + mo + "-" + dy + " 18:00:00";
                  } else if (att.daily_hours == 7) {
                    max_out_flagcem      = yr  + "-" + mo + "-" + dy + " 16:00:00";
                    max_out              = yr  + "-" + mo + "-" + dy + " 17:00:00";
                  } else if (att.daily_hours == 6) {
                    max_out_flagcem      = yr  + "-" + mo + "-" + dy + " 15:00:00";
                    max_out              = yr  + "-" + mo + "-" + dy + " 16:00:00";
                  }

                  // Check if in_am is earlier than max_in
                  if(att.in_am < max_in) {
                    time_in = max_in;
                    tot_reg = Number(moment(att.out_pm).seconds(0).diff(moment(time_in), 'minutes')) - 60
                    if(tot_reg > (att.daily_hours * 60)) {
                      tot_reg = att.daily_hours * 60;
                    }
                    tot_tardiness = (att.daily_hours * 60) - tot_reg;

                    if (key > 14) {
                      total_hrs.push(tot_reg)
                    }


                  } else {


                    time_in = att.in_am;

                    // Check if the day is flag ceremony
                    if(att.is_flagcem == 'checked') {

                      //get late after 8am
                      tot_late = Number(moment(time_in).seconds(0).diff(moment(graceperiod_flagcem).seconds(0), 'minutes'));

                      //set late as 0 if difference is negative
                      if(tot_late < 0) { tot_late = 0; }

                      //get regular hours
                      if (att.out_pm > max_out_flagcem) {
                        tot_reg = (Number(moment(max_out_flagcem).seconds(0).diff(moment(time_in).seconds(0), 'minutes')) - 60) // - tot_late
                      } else {
                        tot_reg = (Number(moment(att.out_pm).seconds(0).diff(moment(time_in).seconds(0), 'minutes')) - 60) // - tot_late
                      }

                      if(tot_reg > (att.daily_hours * 60)) {
                        tot_reg = att.daily_hours * 60;
                      }
                      tot_tardiness = (att.daily_hours * 60) - tot_reg;

                      if (key > 14) {
                        total_hrs.push(tot_reg)
                      }

                    } else {

                      //get late after 9am
                      tot_late = Number(moment(time_in).seconds(0).diff(moment(graceperiod).seconds(0), 'minutes'));

                      //set late as 0 if difference is negative
                      if(tot_late < 0) { tot_late = 0; }

                      //get regular hours
                      if (att.out_pm > max_out) {
                        tot_reg = (Number(moment(max_out).seconds(0).diff(moment(time_in).seconds(0), 'minutes')) - 60) //- tot_late
                      } else {
                        tot_reg = (Number(moment(att.out_pm).seconds(0).diff(moment(time_in).seconds(0), 'minutes')) - 60) //- tot_late
                      }

                      if(tot_reg > (att.daily_hours * 60)) {
                        tot_reg = att.daily_hours * 60;
                      }
                      tot_tardiness = (att.daily_hours * 60) - tot_reg;

                      if (key > 14) {
                        total_hrs.push(tot_reg)
                      }

                    }
                  }

                //return this if no out_pm
                } else {
                  total_hrs.push(0)
                }

              //return this if no in_am
              } else {
                total_hrs.push(0)
              }



            });

            tot_hrs = total_hrs.reduce(function(total_hrs, num){ return total_hrs + num }, 0);
            //return tot_hrs; //Math.floor(tot_hrs / 60) +  ':' + tot_hrs % 60;
            this.form_cos_cut2.cut2_cos_hours = tot_hrs / 60;
            return tot_hrs / 60;
          },

          total_hrs: function() {

            let total_hrs = [];
            let tot_hrs = 0;

            let tot_reg = 0;
            let tot_late = 0;
            let tot_undertime = 0;
            let tot_tardiness = 0;
            let time_in = "";

            let max_in = "";
            let graceperiod_flagcem  = "";
            let graceperiod = "";
            let max_out_flagcem = "";
            let max_out = "";

            Object.entries(this.attendances).forEach(([key, att]) => {

              if( att.in_am != null ) {
                if( att.out_pm != null ) {

                  const yr = att.in_am.substring(0, 4);
                  const mo = att.in_am.substring(5, 7);
                  const dy = att.in_am.substring(8, 10);

                  max_in               = yr  + "-" + mo + "-" + dy + " 07:00:00";
                  graceperiod_flagcem  = yr  + "-" + mo + "-" + dy + " 08:00:00";
                  graceperiod          = yr  + "-" + mo + "-" + dy + " 09:00:00";
                  max_out_flagcem      = yr  + "-" + mo + "-" + dy + " 17:00:00";
                  max_out              = yr  + "-" + mo + "-" + dy + " 18:00:00";

                  // Check if in_am is earlier than max_in
                  if(att.in_am < max_in) {
                    time_in = max_in;
                    tot_reg = Number(moment(att.out_pm).seconds(0).diff(moment(time_in), 'minutes')) - 60

                    if(tot_reg > (att.daily_hours * 60)) {
                      tot_reg = att.daily_hours * 60;
                    }
                    tot_tardiness = (att.daily_hours * 60) - tot_reg;


                    total_hrs.push(tot_reg)

                  } else {


                    time_in = att.in_am;

                    // Check if the day is flag ceremony
                    if(att.is_flagcem == 'checked') {

                      //get late after 8am
                      tot_late = Number(moment(time_in).seconds(0).diff(moment(graceperiod_flagcem).seconds(0), 'minutes'));

                      //set late as 0 if difference is negative
                      if(tot_late < 0) { tot_late = 0; }

                      //get regular hours
                      if (att.out_pm > max_out_flagcem) {
                        tot_reg = (Number(moment(max_out_flagcem).seconds(0).diff(moment(time_in).seconds(0), 'minutes')) - 60) // - tot_late
                      } else {
                        tot_reg = (Number(moment(att.out_pm).seconds(0).diff(moment(time_in).seconds(0), 'minutes')) - 60) // - tot_late
                      }

                      if(tot_reg > att.daily_hours * 60) {
                        tot_reg = att.daily_hours * 60;
                      }
                      tot_tardiness = (att.daily_hours * 60) - tot_reg

                      total_hrs.push(tot_reg)

                    } else {

                      //get late after 9am
                      tot_late = Number(moment(time_in).seconds(0).diff(moment(graceperiod).seconds(0), 'minutes'));

                      //set late as 0 if difference is negative
                      if(tot_late < 0) { tot_late = 0; }

                      //get regular hours
                      if (att.out_pm > max_out) {
                        tot_reg = (Number(moment(max_out).seconds(0).diff(moment(time_in).seconds(0), 'minutes')) - 60) //- tot_late
                      } else {
                        tot_reg = (Number(moment(att.out_pm).seconds(0).diff(moment(time_in).seconds(0), 'minutes')) - 60) //- tot_late
                      }

                      if(tot_reg > att.daily_hours * 60) {
                        tot_reg = att.daily_hours * 60;
                      }
                      tot_tardiness = (att.daily_hours * 60) - tot_reg

                      total_hrs.push(tot_reg)

                    }
                  }

                //return this if no out_pm
                } else {
                  total_hrs.push(0)
                }

              //return this if no in_am
              } else {
                total_hrs.push(0)
              }



            });

            tot_hrs = total_hrs.reduce(function(total_hrs, num){ return total_hrs + num }, 0);
            return Math.floor(tot_hrs / 60) +  ':' + tot_hrs % 60;

          },




          subtotalLate() {

            let tot_reg = 0;
            let tot_late = 0;
            let tot_undertime = 0;
            let tot_tardiness = 0;
            let time_in = "";

            let yr = "";
            let mo = "";
            let dy = "";
            let max_in = "";
            let graceperiod_flagcem  = "";
            let graceperiod = "";
            let max_out_flagcem = "";
            let max_out = "";


            return this.attendances.map((att) => {
              const y = att.year;
              const m = att.month;
              const d = att.day;

              if (moment(y + m + d).format('dddd') != 'Saturday') {
                if (moment(y + m + d).format('dddd') != 'Sunday') {
                  if( att.is_nonwork != 'checked' ) {
                    if( att.in_am != null ) {
                      if( att.out_pm != null ) {

                      yr = att.in_am.substring(0, 4);
                      mo = att.in_am.substring(5, 7);
                      dy = att.in_am.substring(8, 10);

                      max_in               = yr  + "-" + mo + "-" + dy + " 07:00:00";
                      graceperiod_flagcem  = yr  + "-" + mo + "-" + dy + " 08:00:00";
                      graceperiod          = yr  + "-" + mo + "-" + dy + " 09:00:00";

                      if (att.daily_hours == 8) {
                        max_out_flagcem      = yr  + "-" + mo + "-" + dy + " 17:00:00";
                        max_out              = yr  + "-" + mo + "-" + dy + " 18:00:00";
                      } else if (att.daily_hours == 7) {
                        max_out_flagcem      = yr  + "-" + mo + "-" + dy + " 16:00:00";
                        max_out              = yr  + "-" + mo + "-" + dy + " 17:00:00";
                      } else if (att.daily_hours == 6) {
                        max_out_flagcem      = yr  + "-" + mo + "-" + dy + " 15:00:00";
                        max_out              = yr  + "-" + mo + "-" + dy + " 16:00:00";
                      }

                      // Check if in_am is earlier than max_in
                      if(att.in_am < max_in) {
                        time_in = max_in;
                        tot_reg = Number(moment(att.out_pm).seconds(0).diff(moment(time_in), 'minutes')) - 60

                        if(tot_reg > (att.daily_hours * 60)) {
                          tot_reg = att.daily_hours * 60;
                        }
                        tot_tardiness = (att.daily_hours * 60) - tot_reg;


                        //console.log("e|" + time_in + "|" + tot_tardiness + "|" + tot_reg)
                        return Math.floor(tot_tardiness / 60) +  ':' + tot_tardiness % 60;
                        } else {

                          time_in = att.in_am;

                          // Check if the day is flag ceremony
                          if(att.is_flagcem == 'checked') {

                            //get late after 8am
                            tot_late = Number(moment(time_in).seconds(0).diff(moment(graceperiod_flagcem).seconds(0), 'minutes'));

                            //set late as 0 if difference is negative
                            if(tot_late < 0) { tot_late = 0; }

                            //get regular hours
                            if (att.out_pm > max_out_flagcem) {
                              tot_reg = (Number(moment(max_out_flagcem).seconds(0).diff(moment(time_in).seconds(0), 'minutes')) - 60) // - tot_late
                            } else {
                              tot_reg = (Number(moment(att.out_pm).seconds(0).diff(moment(time_in).seconds(0), 'minutes')) - 60) // - tot_late
                            }

                            if(tot_reg > att.daily_hours * 60) {
                              tot_reg = att.daily_hours * 60;
                            }
                            tot_tardiness = (att.daily_hours * 60) - tot_reg

                            return Math.floor(tot_tardiness / 60) +  ':' + tot_tardiness % 60;

                          } else {

                            //get late after 9am
                            tot_late = Number(moment(time_in).seconds(0).diff(moment(graceperiod).seconds(0), 'minutes'));

                            //set late as 0 if difference is negative
                            if(tot_late < 0) { tot_late = 0; }

                            //get regular hours
                            if (att.out_pm > max_out) {
                              tot_reg = (Number(moment(max_out).seconds(0).diff(moment(time_in).seconds(0), 'minutes')) - 60) //- tot_late
                            } else {
                              tot_reg = (Number(moment(att.out_pm).seconds(0).diff(moment(time_in).seconds(0), 'minutes')) - 60) //- tot_late
                            }


                            if(tot_reg > att.daily_hours * 60) {
                              tot_reg = att.daily_hours * 60;
                            }
                            tot_tardiness = (att.daily_hours * 60) - tot_reg

                            return Math.floor(tot_tardiness / 60) +  ':' + tot_tardiness % 60;
                          }
                        }

                        //return this if no out_pm
                        } else {
                          return Math.floor(480 / 60) +  ':' + 0 % 60;
                        }

                      //return this if no in_am
                      } else {
                        return Math.floor(480 / 60) +  ':' + 0 % 60;
                      }
                    //return 0 lates if holiday
                    } else {
                      return Math.floor(0 / 60) +  ':' + 0 % 60;
                    }
                } else {
                  return Math.floor(0 / 60) +  ':' + 0 % 60;
                }

              } else {
                return Math.floor(0 / 60) +  ':' + 0 % 60;
              }




            });

          },


          total_late: function(){
            let total_hrs = [];
            let tot_hrs = 0;

            let tot_reg = 0;
            let tot_late = 0;
            let tot_undertime = 0;
            let tot_tardiness = 0;
            let time_in = "";

            let yr = "";
            let mo = "";
            let dy = "";
            let max_in = "";
            let graceperiod_flagcem  = "";
            let graceperiod = "";
            let max_out_flagcem = "";
            let max_out = "";

            Object.entries(this.attendances).forEach(([key, att]) => {

              const y = att.year;
              const m = att.month;
              const d = att.day;

              if (moment(y + m + d).format('dddd') != 'Saturday') {
                if (moment(y + m + d).format('dddd') != 'Sunday') {
                  if( att.is_nonwork != 'checked' ) {
                    if( att.in_am != null ) {
                      if( att.out_pm != null ) {

                        yr = att.in_am.substring(0, 4);
                        mo = att.in_am.substring(5, 7);
                        dy = att.in_am.substring(8, 10);

                        max_in               = yr  + "-" + mo + "-" + dy + " 07:00:00";
                        graceperiod_flagcem  = yr  + "-" + mo + "-" + dy + " 08:00:00";
                        graceperiod          = yr  + "-" + mo + "-" + dy + " 09:00:00";
                        if (att.daily_hours == 8) {
                          max_out_flagcem      = yr  + "-" + mo + "-" + dy + " 17:00:00";
                          max_out              = yr  + "-" + mo + "-" + dy + " 18:00:00";
                        } else if (att.daily_hours == 7) {
                          max_out_flagcem      = yr  + "-" + mo + "-" + dy + " 16:00:00";
                          max_out              = yr  + "-" + mo + "-" + dy + " 17:00:00";
                        } else if (att.daily_hours == 6) {
                          max_out_flagcem      = yr  + "-" + mo + "-" + dy + " 15:00:00";
                          max_out              = yr  + "-" + mo + "-" + dy + " 16:00:00";
                        }

                        // Check if in_am is earlier than max_in
                        if(att.in_am < max_in) {
                          time_in = max_in;
                          tot_reg = Number(moment(att.out_pm).seconds(0).diff(moment(time_in), 'minutes')) - 60

                          if(tot_reg > (att.daily_hours * 60)) {
                            tot_reg = att.daily_hours * 60;
                          }
                          tot_tardiness = (att.daily_hours * 60) - tot_reg;

                          total_hrs.push(tot_tardiness)

                        } else {


                          time_in = att.in_am;

                          // Check if the day is flag ceremony
                          if(att.is_flagcem == 'checked') {

                            //get late after 8am
                            tot_late = Number(moment(time_in).seconds(0).diff(moment(graceperiod_flagcem).seconds(0), 'minutes'));

                            //set late as 0 if difference is negative
                            if(tot_late < 0) { tot_late = 0; }

                            //get regular hours
                            if (att.out_pm > max_out_flagcem) {
                              tot_reg = (Number(moment(max_out_flagcem).seconds(0).diff(moment(time_in).seconds(0), 'minutes')) - 60) // - tot_late
                            } else {
                              tot_reg = (Number(moment(att.out_pm).seconds(0).diff(moment(time_in).seconds(0), 'minutes')) - 60) // - tot_late
                            }

                            if(tot_reg > att.daily_hours * 60) {
                              tot_reg = att.daily_hours * 60;
                            }
                            tot_tardiness = (att.daily_hours * 60) - tot_reg


                            total_hrs.push(tot_tardiness)

                          } else {

                            //get late after 9am
                            tot_late = Number(moment(time_in).seconds(0).diff(moment(graceperiod).seconds(0), 'minutes'));

                            //set late as 0 if difference is negative
                            if(tot_late < 0) { tot_late = 0; }

                            //get regular hours
                            if (att.out_pm > max_out) {
                              tot_reg = (Number(moment(max_out).seconds(0).diff(moment(time_in).seconds(0), 'minutes')) - 60) //- tot_late
                            } else {
                              tot_reg = (Number(moment(att.out_pm).seconds(0).diff(moment(time_in).seconds(0), 'minutes')) - 60) //- tot_late
                            }



                            if(tot_reg > att.daily_hours * 60) {
                              tot_reg = att.daily_hours * 60;
                            }
                            tot_tardiness = (att.daily_hours * 60) - tot_reg

                            total_hrs.push(tot_tardiness)

                          }
                        }

                      //return this if no out_pm
                      } else {
                        total_hrs.push(480)
                      }

                    //return this if no in_am
                    } else {
                      total_hrs.push(480)
                    }
                  //return 0 lates if holiday
                  } else {
                    total_hrs.push(0)
                  }

                } else {
                  total_hrs.push(0)
                }

              } else {
                total_hrs.push(0)
              }





            });

            tot_hrs = total_hrs.reduce(function(total_hrs, num){ return total_hrs + num }, 0);
            return Math.floor(tot_hrs / 60) +  ':' + tot_hrs % 60;
            //return tot_hrs / 60;

          },


          total_late_cutoff_1: function(){
            let total_hrs = [];
            let tot_hrs = 0;

            let tot_reg = 0;
            let tot_late = 0;
            let tot_undertime = 0;
            let tot_tardiness = 0;
            let time_in = "";

            let yr = "";
            let mo = "";
            let dy = "";
            let max_in = "";
            let graceperiod_flagcem  = "";
            let graceperiod = "";
            let max_out_flagcem = "";
            let max_out = "";

            Object.entries(this.attendances).forEach(([key, att]) => {

              const y = att.year;
              const m = att.month;
              const d = att.day;

              if (moment(y + m + d).format('dddd') != 'Saturday') {
                if (moment(y + m + d).format('dddd') != 'Sunday') {
                  if( att.is_nonwork != 'checked' ) {
                    if( att.in_am != null ) {
                      if( att.out_pm != null ) {

                        yr = att.in_am.substring(0, 4);
                        mo = att.in_am.substring(5, 7);
                        dy = att.in_am.substring(8, 10);

                        max_in               = yr  + "-" + mo + "-" + dy + " 07:00:00";
                        graceperiod_flagcem  = yr  + "-" + mo + "-" + dy + " 08:00:00";
                        graceperiod          = yr  + "-" + mo + "-" + dy + " 09:00:00";
                        if (att.daily_hours == 8) {
                          max_out_flagcem      = yr  + "-" + mo + "-" + dy + " 17:00:00";
                          max_out              = yr  + "-" + mo + "-" + dy + " 18:00:00";
                        } else if (att.daily_hours == 7) {
                          max_out_flagcem      = yr  + "-" + mo + "-" + dy + " 16:00:00";
                          max_out              = yr  + "-" + mo + "-" + dy + " 17:00:00";
                        } else if (att.daily_hours == 6) {
                          max_out_flagcem      = yr  + "-" + mo + "-" + dy + " 15:00:00";
                          max_out              = yr  + "-" + mo + "-" + dy + " 16:00:00";
                        }

                        // Check if in_am is earlier than max_in
                        if(att.in_am < max_in) {
                          time_in = max_in;
                          tot_reg = Number(moment(att.out_pm).seconds(0).diff(moment(time_in), 'minutes')) - 60

                          if(tot_reg > (att.daily_hours * 60)) {
                            tot_reg = att.daily_hours * 60;
                          }
                          tot_tardiness = (att.daily_hours * 60) - tot_reg;

                          if (key < 15) {
                            total_hrs.push(tot_tardiness)
                          }

                        } else {


                          time_in = att.in_am;

                          // Check if the day is flag ceremony
                          if(att.is_flagcem == 'checked') {

                            //get late after 8am
                            tot_late = Number(moment(time_in).seconds(0).diff(moment(graceperiod_flagcem).seconds(0), 'minutes'));

                            //set late as 0 if difference is negative
                            if(tot_late < 0) { tot_late = 0; }

                            //get regular hours
                            if (att.out_pm > max_out_flagcem) {
                              tot_reg = (Number(moment(max_out_flagcem).seconds(0).diff(moment(time_in).seconds(0), 'minutes')) - 60) // - tot_late
                            } else {
                              tot_reg = (Number(moment(att.out_pm).seconds(0).diff(moment(time_in).seconds(0), 'minutes')) - 60) // - tot_late
                            }

                            if(tot_reg > att.daily_hours * 60) {
                              tot_reg = att.daily_hours * 60;
                            }
                            tot_tardiness = (att.daily_hours * 60) - tot_reg

                            if (key < 16) {
                              total_hrs.push(tot_tardiness)
                            }

                          } else {

                            //get late after 9am
                            tot_late = Number(moment(time_in).seconds(0).diff(moment(graceperiod).seconds(0), 'minutes'));

                            //set late as 0 if difference is negative
                            if(tot_late < 0) { tot_late = 0; }

                            //get regular hours
                            if (att.out_pm > max_out) {
                              tot_reg = (Number(moment(max_out).seconds(0).diff(moment(time_in).seconds(0), 'minutes')) - 60) //- tot_late
                            } else {
                              tot_reg = (Number(moment(att.out_pm).seconds(0).diff(moment(time_in).seconds(0), 'minutes')) - 60) //- tot_late
                            }

                            if(tot_reg > att.daily_hours * 60) {
                              tot_reg = att.daily_hours * 60;
                            }
                            tot_tardiness = (att.daily_hours * 60) - tot_reg

                            if (key < 16) {
                              total_hrs.push(tot_tardiness)
                            }

                          }
                        }

                      //return this if no out_pm
                      } else {
                        if (key < 15) {
                          total_hrs.push(480)
                        }
                      }

                    //return this if no in_am
                    } else {
                      if (key < 15) {
                        total_hrs.push(480)
                      }
                    }
                  //return 0 lates if holiday
                  } else {
                    total_hrs.push(0)
                  }

                } else {
                  total_hrs.push(0)
                }

              } else {
                total_hrs.push(0)
              }





            });

            tot_hrs = total_hrs.reduce(function(total_hrs, num){ return total_hrs + num }, 0);
            //return Math.floor(tot_hrs / 60) +  ':' + tot_hrs % 60;
            this.deduction_lua_cutoff_1 = tot_hrs / 60;
            this.form_cos_cut1.cut1_cos_lua_hrs = tot_hrs / 60;
            this.form_cos_cut1.cut1_cos_lua_php = (tot_hrs / 60) * this.compute_hourly;
            return tot_hrs / 60;

          },



          total_late_cutoff_2: function(){
            let total_hrs = [];
            let tot_hrs = 0;

            let tot_reg = 0;
            let tot_late = 0;
            let tot_undertime = 0;
            let tot_tardiness = 0;
            let time_in = "";

            let yr = "";
            let mo = "";
            let dy = "";
            let max_in = "";
            let graceperiod_flagcem  = "";
            let graceperiod = "";
            let max_out_flagcem = "";
            let max_out = "";

            Object.entries(this.attendances).forEach(([key, att]) => {

              const y = att.year;
              const m = att.month;
              const d = att.day;

              if (moment(y + m + d).format('dddd') != 'Saturday') {
                if (moment(y + m + d).format('dddd') != 'Sunday') {
                  if( att.is_nonwork != 'checked' ) {
                    if( att.in_am != null ) {
                      if( att.out_pm != null ) {

                        yr = att.in_am.substring(0, 4);
                        mo = att.in_am.substring(5, 7);
                        dy = att.in_am.substring(8, 10);

                        max_in               = yr  + "-" + mo + "-" + dy + " 07:00:00";
                        graceperiod_flagcem  = yr  + "-" + mo + "-" + dy + " 08:00:00";
                        graceperiod          = yr  + "-" + mo + "-" + dy + " 09:00:00";
                        if (att.daily_hours == 8) {
                          max_out_flagcem      = yr  + "-" + mo + "-" + dy + " 17:00:00";
                          max_out              = yr  + "-" + mo + "-" + dy + " 18:00:00";
                        } else if (att.daily_hours == 7) {
                          max_out_flagcem      = yr  + "-" + mo + "-" + dy + " 16:00:00";
                          max_out              = yr  + "-" + mo + "-" + dy + " 17:00:00";
                        } else if (att.daily_hours == 6) {
                          max_out_flagcem      = yr  + "-" + mo + "-" + dy + " 15:00:00";
                          max_out              = yr  + "-" + mo + "-" + dy + " 16:00:00";
                        }

                        // Check if in_am is earlier than max_in
                        if(att.in_am < max_in) {
                          time_in = max_in;
                          tot_reg = Number(moment(att.out_pm).seconds(0).diff(moment(time_in), 'minutes')) - 60

                          if(tot_reg > (att.daily_hours * 60)) {
                            tot_reg = att.daily_hours * 60;
                          }
                          tot_tardiness = (att.daily_hours * 60) - tot_reg;


                          if (key > 15) {
                            total_hrs.push(tot_tardiness)
                          }

                        } else {


                          time_in = att.in_am;

                          // Check if the day is flag ceremony
                          if(att.is_flagcem == 'checked') {

                            //get late after 8am
                            tot_late = Number(moment(time_in).seconds(0).diff(moment(graceperiod_flagcem).seconds(0), 'minutes'));

                            //set late as 0 if difference is negative
                            if(tot_late < 0) { tot_late = 0; }

                            //get regular hours
                            if (att.out_pm > max_out_flagcem) {
                              tot_reg = (Number(moment(max_out_flagcem).seconds(0).diff(moment(time_in).seconds(0), 'minutes')) - 60) // - tot_late
                            } else {
                              tot_reg = (Number(moment(att.out_pm).seconds(0).diff(moment(time_in).seconds(0), 'minutes')) - 60) // - tot_late
                            }

                            if(tot_reg > att.daily_hours * 60) {
                              tot_reg = att.daily_hours * 60;
                            }
                            tot_tardiness = (att.daily_hours * 60) - tot_reg

                            if (key > 15) {
                              total_hrs.push(tot_tardiness)
                            }

                          } else {

                            //get late after 9am
                            tot_late = Number(moment(time_in).seconds(0).diff(moment(graceperiod).seconds(0), 'minutes'));

                            //set late as 0 if difference is negative
                            if(tot_late < 0) { tot_late = 0; }

                            //get regular hours
                            if (att.out_pm > max_out) {
                              tot_reg = (Number(moment(max_out).seconds(0).diff(moment(time_in).seconds(0), 'minutes')) - 60) //- tot_late
                            } else {
                              tot_reg = (Number(moment(att.out_pm).seconds(0).diff(moment(time_in).seconds(0), 'minutes')) - 60) //- tot_late
                            }

                            if(tot_reg > att.daily_hours * 60) {
                              tot_reg = att.daily_hours * 60;
                            }
                            tot_tardiness = (att.daily_hours * 60) - tot_reg

                            if (key > 15) {
                              total_hrs.push(tot_tardiness)
                            }

                          }
                        }

                      //return this if no out_pm
                      } else {
                        if (key > 15) {
                          total_hrs.push(480)
                        }
                      }

                    //return this if no in_am
                    } else {
                      if (key > 15) {
                        total_hrs.push(480)
                      }
                    }
                  //return 0 lates if holiday
                  } else {
                    total_hrs.push(0)
                  }

                } else {
                  total_hrs.push(0)
                }

              } else {
                total_hrs.push(0)
              }





            });

            tot_hrs = total_hrs.reduce(function(total_hrs, num){ return total_hrs + num }, 0);
            //return Math.floor(tot_hrs / 60) +  ':' + tot_hrs % 60;
            this.deduction_lua_cutoff_2 = tot_hrs / 60;
            //console.log(tot_hrs);
            this.form_cos_cut2.cut2_cos_lua_hrs = tot_hrs / 60;
            this.form_cos_cut2.cut2_cos_lua_php = (tot_hrs / 60) * this.compute_hourly;
            return tot_hrs / 60;

          },




          compute_salary_cutoff_1: function(){
            let salary = 0;

            if (this.total_hrs_cutoff_1 == 0) {
              salary = 0;
            } else {
              salary = this.compute_earned_1 - (this.deduction_lua_cutoff_1 * this.compute_hourly);
            }
            this.form_cos_cut1.cut1_cos_salary = salary;
            return salary;
          },

          compute_salary_cutoff_2: function(){
            let salary = 0;

            if (this.total_hrs_cutoff_2 == 0) {
              salary = 0;
            } else {
              salary = this.compute_earned_2 - (this.deduction_lua_cutoff_2 * this.compute_hourly);
            }
            this.form_cos_cut2.cut2_cos_salary = salary;
            return salary;
          },

          compute_ewt2_cut1: function() {

            let ewt2 = 0;

            if (this.profile.cor == 'YES') {
              if ((this.compute_daily * this.compute_daysofservice) <= 250000) {
                ewt2 = 0;
              } else {
                let sal = 0;

                if ((this.compute_salary_cutoff_1 - 10417) < 0) {
                  sal = 0;
                } else {
                  sal = this.compute_salary_cutoff_1 - 10417;
                }

                ewt2 = (sal) * 2.0 / 100.0;
                //console.log('EWT2:' + ewt2);
              }
            } else {
              ewt2 = 0;
            }
            this.form_cos_cut1.cut1_cos_ewt2 = ewt2;
            return ewt2;
          },

          compute_ewt5_cut1: function() {
            let ewt5 = 0;

            if (this.profile.cor == 'NO') {
              let sal = 0;

              //if ((this.compute_salary_cutoff_1 - 10417) < 0) {
              if (this.compute_salary_cutoff_1 < 0) {
                sal = 0;
              } else {
                //sal = this.compute_salary_cutoff_1 - 10417;
                sal = this.compute_salary_cutoff_1;
              }
              ewt5 = (sal) * 5.0 / 100.0;
            } else {
              ewt5 = 0;
            }
            this.form_cos_cut1.cut1_cos_ewt5 = ewt5;
            return ewt5;
          },


          compute_ewt3_cut1: function() {
            let ewt3 = 0;

            if (this.profile.cor == 'NO') {
              let sal = 0;

              //if ((this.compute_salary_cutoff_1 - 10417) < 0) {
              if (this.compute_salary_cutoff_1 < 0) {
                sal = 0;
              } else {
                //sal = this.compute_salary_cutoff_1 - 10417;
                sal = this.compute_salary_cutoff_1;
              }
              ewt3 = (sal) * 3.0 / 100.0;
            } else {
              ewt3 = 0;
            }
            this.form_cos_cut1.cut1_cos_ewt3 = ewt3;

            return ewt3;
          },

          compute_netpay_cutoff_1: function(){
            let netpay_cutoff_1 = 0;

            netpay_cutoff_1 = this.compute_salary_cutoff_1 - this.compute_ewt2_cut1 - this.compute_ewt5_cut1 - this.compute_ewt3_cut1 - this.profile.tapiea_contribution;
            this.form_cos_cut1.cut1_cos_net = netpay_cutoff_1;

            if (netpay_cutoff_1 < 1200) {
              return 0;
            } else {
              return netpay_cutoff_1;
            }

          },


          compute_ewt2_cut2: function() {

            let ewt2 = 0;

            if (this.profile.cor == 'YES') {
              //console.log(this.compute_daily * this.compute_daysofservice)
              if ((this.compute_daily * this.compute_daysofservice) <= 250000) {
                ewt2 = 0;
              } else {
                let sal = 0;

                if ((this.compute_salary_cutoff_2 - 10417) < 0) {
                  sal = 0;
                } else {
                  sal = this.compute_salary_cutoff_2 - 10417;
                }

                ewt2 = (sal) * 2.0 / 100.0;
              }
            } else {
              ewt2 = 0;
            }
            this.form_cos_cut2.cut2_cos_ewt2 = ewt2;

            return ewt2;
          },

          compute_ewt5_cut2: function() {
            let ewt5 = 0;

            if (this.profile.cor == 'NO') {
              let sal = 0;

              //if ((this.compute_salary_cutoff_2 - 10417) < 0) {
              if (this.compute_salary_cutoff_2 < 0) {
                sal = 0;
              } else {
                //sal = this.compute_salary_cutoff_2 - 10417;
                sal = this.compute_salary_cutoff_2;
              }

              ewt5 = (sal) * 5.0 / 100.0;

            } else {
              ewt5 = 0;
            }
            this.form_cos_cut2.cut2_cos_ewt5 = ewt5;

            return ewt5;
          },


          compute_ewt3_cut2: function() {
            let ewt3 = 0;

            if (this.profile.cor == 'NO') {
              let sal = 0;

              //if ((this.compute_salary_cutoff_2 - 10417) < 0) {
              if (this.compute_salary_cutoff_2 < 0) {
                sal = 0;
              } else {
                //sal = this.compute_salary_cutoff_2 - 10417;
                sal = this.compute_salary_cutoff_2;
              }

              ewt3 = (sal) * 1.0 / 100.0;
            } else {
              ewt3 = 0;
            }
            this.form_cos_cut2.cut2_cos_ewt3 = ewt3;

            return ewt3;
          },

          compute_netpay_cutoff_2: function(){
            let netpay_cutoff_2 = 0;

            netpay_cutoff_2 = this.compute_salary_cutoff_2 - this.compute_ewt2_cut2 - this.compute_ewt5_cut2 - this.compute_ewt3_cut2;
            this.form_cos_cut2.cut2_cos_net = netpay_cutoff_2;

            return netpay_cutoff_2;
          },



          initialize_form_cos_cut: function(){
	          this.form_cos_cut1.cut1_cos_fullname = this.profile.lname + ', ' + this.profile.fname + ' ' + this.profile.mname.substring(0, 1) + '.';
            this.form_cos_cut1.cut1_cos_monthly = this.profile.salary;
            this.form_cos_cut1.cut1_cos_position = this.profile.position;
            this.form_cos_cut1.cut1_cos_project = this.profile.project;
            this.form_cos_cut1.cut1_cos_year = this.year;
            this.form_cos_cut1.cut1_cos_month = this.month;
            this.form_cos_cut1.cut1_cos_cutoff = '1';
            this.form_cos_cut1.cut1_cos_employee_id = this.employee_id;
            this.form_cos_cut1.cut1_cos_tapiea_contrib_php = this.profile.tapiea_contribution;

            this.form_cos_cut2.cut2_cos_fullname = this.profile.lname + ', ' + this.profile.fname + ' ' + this.profile.mname.substring(0, 1) + '.';
            this.form_cos_cut2.cut2_cos_monthly = this.profile.salary;
            this.form_cos_cut2.cut2_cos_position = this.profile.position;
            this.form_cos_cut2.cut2_cos_project = this.profile.project;
            this.form_cos_cut2.cut2_cos_year = this.year;
            this.form_cos_cut2.cut2_cos_month = this.month;
            this.form_cos_cut2.cut2_cos_cutoff = '2';
            this.form_cos_cut2.cut2_cos_employee_id = this.employee_id;
          },


          getDateToday: function(){
            const today = new Date();
            const curr_year = today.getFullYear();
            const curr_month = moment(String(today.getMonth() + 1)).format('MM');
            const curr_day = moment(String(today)).format('DD');
            //console.log(curr_year + curr_month + curr_day);
            //console.log(today);
            return curr_year + curr_month + curr_day;
          },




        },



        created() {
          this.$Progress.start();




          const today = new Date();

          const curr_year = today.getFullYear();
          const curr_month = moment(String(today.getMonth() + 1)).format('MM');

          let empid = "";
          let yr = "";
          let mo = "";

          if ( this.employee_id == '' ) {
            empid = this.$gate.showMyID();
          } else {
            empid = this.employee_id;
          }


          if(this.year == '' || this.month == '') {
              yr = curr_year;
              mo = curr_month;
              this.year = curr_year;
              this.month = curr_month;
              this.employee_id = this.$gate.showMyID();
            } else {
              yr = this.year;
              mo = this.month;
            }

          this.loadPermission(6); //Can process payroll
          this.loadPermission(8); //Can approve OB
          this.loadPermission(9); //Can deny OB
          this.loadPermission(1); //Can access employees

          axios.get("api/users/" + empid + "/attendances/" + yr + "/" + mo).then(({ data }) => (this.profile = data));
          axios.get("api/users/" + empid + "/attendances/" + yr + "/" + mo).then(({ data }) => (this.attendances = data.attendances));

          this.form.biometric_id = this.profile.biometric_id;
          axios.get("api/users/" + empid + "/officialbusinesses/" + yr + "/" + mo).then(({ data }) => (this.officialbusinesses = data.officialbusinesses));
          axios.get("api/users/allusers").then(({ data }) => (this.users = data));
          axios.get("api/payrolls/processed/" + empid + "/" + yr + "/" + mo + "/1").then(({ data }) => (this.firstcutoffprocess = data));
          axios.get("api/payrolls/processed/" + empid + "/" + yr + "/" + mo + "/2").then(({ data }) => (this.secondcutoffprocess = data));
          //axios.get("api/attendances/payslip/" + empid + "/" + yr + "/" + mo + "/1").then(({ data }) => (this.payslip = data));



          this.no_days = Number(moment( yr + "-" + mo, "YYYY-MM").daysInMonth());
          this.no_days_2nd_cut = Number(moment( yr + "-" + mo, "YYYY-MM").daysInMonth()) - 15;

          this.$Progress.finish();

          Trigger.$on('RefreshData',() => {

            axios.get("api/users/" + empid + "/attendances/" + yr + "/" + mo).then(({ data }) => (this.profile = data));
            axios.get("api/users/" + empid + "/attendances/" + yr + "/" + mo).then(({ data }) => (this.attendances = data.attendances));

            this.form.biometric_id = this.profile.biometric_id;
            axios.get("api/users/" + empid + "/officialbusinesses/" + yr + "/" + mo).then(({ data }) => (this.officialbusinesses = data.officialbusinesses));
            //axios.get("api/attendances/payslip/" + empid + "/" + yr + "/" + mo + "/1").then(({ data }) => (this.payslip = data));

          });


          this.form_cos_cut2.cut2_cos_employee_id = this.employee_id;

        }

    }
</script>
