<template>
    <div class="">

        <div class="row mb-2">
            <div class="col-sm-12">
                <h2>Project Roles Permissions</h2>
            </div>
        </div>

        <section class="content connectedSortable ui-sortable">
                <div class="row">
                    <div class="col-md-6">
                        <div class="card">
                            <div class="card-header ui-sortable-handle" style="cursor: move;">
                                <h3 class="card-title">
                                <i class="fa fa-list-ol mr-1"></i>
                                Manage project roles permissions and assign users
                                </h3>
                                <div class="card-tools">
                                </div>
                            </div>

                            <div class="card-body">
                                <div class="drag-list" v-for="role in projectroles" :key="role.id">
                                    <span class="text ml-2">[@{{role.rolename}}] </span>
                                    <span class="text ml-2"><b>{{role.rolelabel}}</b></span>
                                    <div class="tools">
                                        <i @click="editModal(role)" class="fa fa-edit"></i>
                                        <i class="fa fa-trash"></i>
                                    </div>
                                    <span class="text float-right mr-5"><i>{{role.description}}</i></span>
                                </div>
                            </div>

                            <div class="card-footer clearfix">
                                <button type="button" class="btn btn-primary float-right"><i class="fas fa-plus"></i> Add Role</button>
                            </div>
                        </div>
                    </div>
                </div>
        </section>

        <div class="modal fade" id="rolemodal" tabindex="-1" role="dialog" aria-labelledby="roleModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 v-show="!editmode" class="modal-title" id="roleModalLabel">Create New Project Role</h5>
                <h5 v-show="editmode" class="modal-title" id="roleModalLabel">Update Project Role</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <form @submit.prevent="editmode ? updateRole() : createRole()" @keydown="form.onKeydown($event)">

              <div class="modal-body">
              <div class="row">

                  <div class="col-md-6">
                      <!-- Your first column here -->
                      <div class="form-group">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">Role Name</span>
                          </div>
                          <input v-model="form.rolename" type="text" name="rolename" autocomplete="off" placeholder="Role Name.." class="form-control" :class="{ 'is-invalid': form.errors.has('rolename') }">
                          <has-error :form="form" field="rolename"></has-error>
                        </div>
                      </div>

                      <div class="form-group">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">Description</span>
                          </div>
                          <input v-model="form.description" type="text" name="description" autocomplete="off" placeholder="Description.." class="form-control" :class="{ 'is-invalid': form.errors.has('description') }">
                          <has-error :form="form" field="description"></has-error>
                        </div>
                      </div>

                      <div class="form-group">
                        <span style="font-weight: 700;float: left;" >Role Members</span><br/>
                        <v-selectize :options="users"
                            @search="text = $event"
                            v-model="form.users"
                            :create-item="maybeCreate()"
                            multiple
                            placeholder="Select role members.."
                            keyBy="id"
                            label="lname"
                            :keys="['id', 'lname']">
                            <template slot="item" slot-scope="{item}">{{ item.lname + ', ' + item.fname }}</template>
                            <template slot="option" slot-scope="{option}">
                                <label>{{ option.lname + ', ' + option.fname }}</label>
                                <span>{{ option.username }}</span>
                            </template>
                        </v-selectize>
                        <!--
                        <pre class="language-json"><code>{{ form.users  }}</code></pre>
                        <pre class="language-json"><code>{{ actions  }}</code></pre>
                        -->
                      </div>

                      <div class="form-group">
                        <span style="font-weight: 700;float: left;" >Action Approvers</span><br/>
                        <v-selectize :options="users"
                            @search="text = $event"
                            v-model="form.approvers"
                            :create-item="maybeCreate()"
                            multiple
                            placeholder="Select role members.."
                            keyBy="id"
                            label="lname"
                            :keys="['id', 'lname']">
                            <template slot="item" slot-scope="{item}">{{ item.lname + ', ' + item.fname }}</template>
                            <template slot="option" slot-scope="{option}">
                                <label>{{ option.lname + ', ' + option.fname }}</label>
                                <span>{{ option.username }}</span>
                            </template>
                        </v-selectize>
                      </div>

                  </div>

                  <div class="col-md-6">
                    <!--Your second column here-->
                    <div class="alert alert-warning">
                        <h5><i class="icon fas fa-exclamation-triangle"></i> Activity Permissions</h5>
                        Check only those actions permitted for this role.
                    </div>
                    <div class="drag-listx" v-for="pa in projectactions" :key="pa.id">
                        <span class="text ml-2"><label style="margin-bottom: 0.3rem !important;" :for="'chk' + pa.id"><b>{{pa.action}}</b></label></span>
                        <div class="tools float-right">
                            <input type="checkbox" :id="'chk' + pa.id" :value="pa.id" v-model="actions">
                        </div>
                    </div>
                  </div>

              </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-default" data-dismiss="modal"><i class="fa fa-times-circle"></i> CLOSE</button>
                <button v-show="editmode" type="submit" class="btn btn-default"><i class="fa fa-save"></i> UPDATE</button>
                <button v-show="!editmode" type="submit" class="btn btn-default"><i class="fa fa-save"></i> CREATE</button>
              </div>

              </form>

            </div>
          </div>
        </div>

    </div>

</template>

<script>

    import Form from 'vform';
    import draggable from 'vuedraggable'

    export default {

        components: {
            draggable
        },

        data() {
          return {
            value: [

            ],

            timer: 30,
            editmode: false,
            emptyflag: false,
            can_access: false,
            can_add: false,
            can_edit: false,
            can_delete: false,
            projectroles : [],
            projectactions: [],
            settings: {},
            users: [],
            actions: [],
            form: new Form({
              id: '',
              rolename: '',
              description: '',
              users: '',
              approvers: '',
              actions: '',
            })
          }
        },

        computed: {

          second() {
            return this.time / 1000;
          },

          isIdle() {
            const idlestat =  this.$store.state.idleVue.isIdle;
            if (idlestat == true) {
              $('#sessionmodal').modal('show');
              this.countDownTimer();
            } else {
              $('#sessionmodal').modal('hide');
            }

            return idlestat;

          },

        },

        methods: {

            countDownTimer() {
                let timerId = setInterval(() => {
                this.timer -= 1;
                if (!this.$store.state.idleVue.isIdle) clearInterval(timerId);

                if (this.timer < 1) {
                    clearInterval(timerId);
                    document.getElementById('logout-form').submit();
                }
                }, 1000);
                this.timer = 30;
            },

            editModal(role) {
                this.editmode = true;
                this.form.reset();
                this.form.clear();
                $('#rolemodal').modal('show');
                this.form.fill(role);
                if(!role.actions) {
                    this.actions = [];
                } else {
                    this.actions = role.actions;
                }
            },

            addTag (newTag) {
                const tag = {
                    name: newTag,
                    code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
                }
                this.options.push(tag)
                this.value.push(tag)
            },

            maybeCreate () {
                return false
            },

            updateRole() {
                this.$Progress.start();
                this.form.actions = this.actions;
                this.form.put('api/projectroles/' + this.form.id).then(()=>{
                    $('#rolemodal').modal('hide')
                    Trigger.$emit('RefreshData');
                    toastr.fire({
                        icon: 'success',
                        title: 'Role info updated'
                    })
                    this.$Progress.finish();
                }).catch(()=>{
                    toastr.fire({
                    icon: 'error',
                    title: 'Role updating failed'
                });
                this.$Progress.fail();});
            },

            loadData() {
                this.$Progress.start();
                axios.get("api/users/allinservice").then(({ data }) => (this.users = data));
                axios.get('api/projectroles').then(({ data }) => ( this.projectroles = data ));
                axios.get('api/projectactions').then(({ data }) => ( this.projectactions = data ));
                this.$Progress.finish();
            }

        },

        created() {

            this.loadData();

            Trigger.$on('RefreshData',() => {
                this.loadData();
            });
        },

    }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
.selectize-input > input {
    width: auto !important;
}
</style>

<style scoped>

.selectize-input > input {
    width: 200px !important;
}
/*image*/
.img-thumbnail{
  height: 164px;
  overflow: hidden;
  margin: auto;

}

.drag-list {
    border-radius: 2px;
    background-color: #f8f9fa;
    border-left: 2px solid #e9ecef;
    color: #495057;
    margin-bottom: 2px;
    padding: 10px;
    display: inline-block;
    font-weight: 600;
    margin-left: 5px;
    width: 100%;
}

.drag-listx {
    border-radius: 2px;
    background-color: #f8f9fa;
    border-left: 2px solid #e9ecef;
    color: #495057;
    margin-bottom: 2px;
    padding: 4px;
    display: inline-block;
    font-weight: 600;
    margin-left: 5px;
    width: 100%;
}

.drag-list:hover .tools {
    display: block;
    color: #dc3545;
}

.tools {
    color: #7a8999;
    display: block;
    float: right;
    cursor: pointer;
}

.list-move{
  transition: .5s;
}
.handle {
    cursor: move;
}

@import 'https://adminlte.io/themes/v3/plugins/bs-stepper/css/bs-stepper.min.css';
</style>
