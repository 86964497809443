<template>
    <li ref="element1" class="nav-item dropdown">
        <a class="nav-link" data-toggle="dropdown" href="#" aria-expanded="false">
            <i  class="fa fa-bell" aria-hidden="true"></i>
            <span v-show="this.unread!=0" v-if="notifications.data" class="badge badge-danger navbar-badge" style="position: relative;font-size: 7px !important;top: -5px;right: 8px;">{{ unreadNotifications.length }}</span>
        </a>
        <div ref="element2" class="dropdown-menu dropdown-menu-lg dropdown-menu-right" onclick="event.stopPropagation()" style="left: inherit; right: 0px;width: 300px;padding: 0px;">
            <div v-for="(notification, index) in notifications.data"  v-bind:key="index">
                <a style="text-decoration:none;" role="button" href="#" @click.prevent="goToLocation(notification.url)">
                    <span class="dropdown-item"  :style="[!notification.notificationstate[0] ? {'background-color': '#E6F2FF'} : {}]">
                        <div class="media">
                            <img :src="'img/profile/' + notification.user.photo" alt="User Avatar" class="img-size-50 mr-3 img-circle">
                            <div class="media-body">
                                <h3 class="dropdown-item-title">
                                    {{ notification.user.fname | capitalize }} {{ notification.user.lname | capitalize }}
                                    <span class="float-right text-sm text-primary">
                                        <i v-show="!notification.notificationstate[0]" class="fa fa-circle"  @click="readNotification(notification.id, index)" />
                                    </span>
                                </h3>
                                <p class="text-sm">{{ notification.details }}</p>
                                <p class="text-sm text-muted"><i class="fa fa-clock mr-1"></i>
                                    <timeago :datetime="notification.created_at"></timeago>
                                </p>
                            </div>
                        </div>
                    </span>
                </a>
                <div class="dropdown-divider"></div>
            </div>
            <a v-if="notifications.data && notifications.data.length == 0" href="#" v-show="notifications.data" class="dropdown-item dropdown-footer text-center">No New Notifications</a>
            <a v-else v-show="notifications.data" class="dropdown-item dropdown-footer text-center" href="#" role="button" @click="goToAlerts">See All Notifications</a>
        </div>
    </li>
</template>

<script>

import axios from 'axios'
import Form from 'vform';

export default {
    name: 'Notifications',

    data () {
        return {
            unread: 0,
            notifications: {},
            access_1: false,
            access_10: false,
            access_12: false,
            access_15: false,
            access_16: false,
            access_17: false,
            access_18: false,
            access_19: false,
            access_23: false,
            access_27: false,
            access_28: false,
            access_29: false,
            access_31: false,
            access_44: false,
            access_48: false,
            form: new Form({
              notification_id: '',
              user_id: '',
            })
        }
    },

    computed: {
        unreadNotifications() {
            this.unread = this.notifications.data.filter(notification => !notification.notificationstate[0]);
            return this.notifications.data.filter(notification => !notification.notificationstate[0]);
        }
    },

    methods: {

        goToLocation(url) {
            console.log(url)
            const questionMarkIndex = url.indexOf('?');
            const parsedUrl = questionMarkIndex !== -1 ? url.substring(0, questionMarkIndex) : url;

            if (window.location.pathname == parsedUrl) {
                this.$router.push(url);
                location.reload();
            } else {
                this.$refs.element1.classList.remove('show');
                this.$refs.element2.classList.remove('show');
                this.$router.push(url);
            }
        },

        goToAlerts() {
            if (window.location.pathname == '/alerts') {
                location.reload();
            } else {
                this.$refs.element1.classList.remove('show');
                this.$refs.element2.classList.remove('show');
                this.$router.push('/alerts');
            }
            this.$refs.element1.classList.remove('show');
            this.$refs.element2.classList.remove('show');
            this.$router.push('/alerts');
        },

        toggleToast(val) {
            toastr.fire({
                icon: 'info',
                html: '<div style="text-align:left;margin-left:10px;"><span style="font-weight:bold;">' + val.user.fname + ' ' + val.user.lname + '</span><br/>' + val.details + '</div>',
                position: 'bottom-start',
                timer: 10000,
                timerProgressBar: true,
                onOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            })
        },

        async subscribeChannel(channel_name) {
            Echo.private(channel_name).listen(".notification-sent", (payload) => {
                console.log(payload);

                switch (payload.notification.module_id) {
                case 0: //  If no module selected, as
                    if (this.$gate.showMyID() === payload.notification.user_to) {  this.notifications.data.unshift(payload.notification); this.toggleToast(payload.notification); } break;
                case 1: //  HR - Can access employees
                    if (this.access_1) {  this.notifications.data.unshift(payload.notification); this.toggleToast(payload.notification); } break;
                case 10: // HR - Can access TAPIEA
                    if (this.access_10) {  this.notifications.data.unshift(payload.notification); this.toggleToast(payload.notification); } break;
                case 12: // HR - Can access loans
                    if (this.access_12) {  this.notifications.data.unshift(payload.notification); this.toggleToast(payload.notification); } break;
                case 15: // MIS - Can access API tokens
                    if (this.access_15) {  this.notifications.data.unshift(payload.notification); this.toggleToast(payload.notification); } break;
                case 16: // MIS - Can access user roles
                    if (this.access_16) {  this.notifications.data.unshift(payload.notification); this.toggleToast(payload.notification); } break;
                case 17: // MIS - Can access support ticket
                    if (this.access_17) {  this.notifications.data.unshift(payload.notification); this.toggleToast(payload.notification); } break;
                case 19: // CASHIER - Can access bank list
                    if (this.access_19) {  this.notifications.data.unshift(payload.notification); this.toggleToast(payload.notification); } break;
                case 23: // CASHIER - Can access LDDAP-ADA
                    if (this.access_23) {  this.notifications.data.unshift(payload.notification); this.toggleToast(payload.notification); } break;
                case 27: // CASHIER - Can access cashier settings
                    if (this.access_27) {  this.notifications.data.unshift(payload.notification); this.toggleToast(payload.notification); } break;
                case 28: // EXECOM - Can access execom deliberation
                    if (this.access_28) {  this.notifications.data.unshift(payload.notification); this.toggleToast(payload.notification); } break;
                case 29: // EXECOM - Can access project proposal
                    if (this.access_29) {  this.notifications.data.unshift(payload.notification); this.toggleToast(payload.notification); } break;
                case 31: // EXECOM - Execom secretariat
                    if (this.access_31) {  this.notifications.data.unshift(payload.notification); this.toggleToast(payload.notification); } break;
                case 44: // CERTIFICATES - Can access e-Certificates
                    if (this.access_44) {  this.notifications.data.unshift(payload.notification); this.toggleToast(payload.notification); } break;
                case 48: // CERTIFICATES - Can access e-Cert Settings
                    if (this.access_48) {  this.notifications.data.unshift(payload.notification); this.toggleToast(payload.notification); } break;
                }

            });
        },

        unsubscribeChannel(channel_name) {
            Echo.leave(channel_name);
        },

        readNotification(nid, index) {
            this.form.notification_id = nid;
            this.form.user_id = this.$gate.showMyID();

            this.form.post('api/notificationstates').then(()=>{
              this.notifications.data[index].notificationstate.push({id: 1, user_id: this.$gate.showMyID(), notification_id: nid, isRead: 1})
            }).catch(()=>{
              console.log('read failed!')
            });
        },

        async loadPermission() {
            await axios.get("api/permissions/" + this.$gate.showMyID()).then(result => {
                //console.log(res.data);
                if (result.data || result.data.length != 0) {
                    for (const key in result.data) {
                        if(result.data[key].module_id == 1) { this.access_1 = true; }
                        else if(result.data[key].module_id == 10) { this.access_10 = true; }
                        else if(result.data[key].module_id == 12) { this.access_12 = true; }
                        else if(result.data[key].module_id == 15) { this.access_15 = true; }
                        else if(result.data[key].module_id == 16) { this.access_16 = true; }
                        else if(result.data[key].module_id == 17) { this.access_17 = true; }
                        else if(result.data[key].module_id == 19) { this.access_19 = true; }
                        else if(result.data[key].module_id == 23) { this.access_23 = true; }
                        else if(result.data[key].module_id == 27) { this.access_27 = true; }
                        else if(result.data[key].module_id == 28) { this.access_28 = true; }
                        else if(result.data[key].module_id == 29) { this.access_29 = true; }
                        else if(result.data[key].module_id == 31) { this.access_31 = true; }
                        else if(result.data[key].module_id == 44) { this.access_44 = true; }
                        else if(result.data[key].module_id == 48) { this.access_48 = true; }
                    }
                } else {
                    return "No module view accesses/permissions";
                }
            })
        },
    },

    async created () {
        this.loadPermission();
        this.subscribeChannel('notification-channel');
        await axios.get("api/notifications").then(result => {
            this.notifications = result.data;
        }).catch(err => {
            console.log(err);
        });
    },

    beforeDestroy() {
       this.unsubscribeChannel('notification-channel'); //this.disconnectPusher();
    },


}
</script>
<style scoped>
.dropdown-item:hover, .dropdown-item:focus {
    background-color: #f8f9fa !important;
}
</style>
