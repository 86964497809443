<template>
    <div class="container-fluid">

        <div class="row">
          <div class="col-md-12">
            <div class="card card-secondary card-outline">
              <div class="card-header">
                <h4 class="card-title"><i class="fas fa-user-cog"></i> Program Managers</h4>

                <div class="card-tools">
                  <div>
                    <button data-toggle="tooltip" data-container="body" title="Add New Program Manager" class="btn btn-outline-secondary btn-sm bg-green" @click="newModal"><i class="fa fa-plus"></i></button>
                  </div>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <table class="table table-bordered table-hover">
                  <thead>
                    <tr>  
                      <th>Program Manager Name</th>
                      <th>Access All Programs</th>
                      <th>Status</th>
                      <th>Options</th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-for="(program_manager, index) in program_managers.data">
                      <tr class="div-pointer" 
                        :class="{ 'active-class': active_program_manager === index }"
                        @click="enableSubRow(program_manager, index)" 
                        :key="index" 
                        data-widget="expandable-table" aria-expanded="false">

                        <td>
                          <i :class="(active_program_manager === index) ? 'fas fa-angle-down' : 'fas fa-angle-right'"></i>
                          {{ program_manager.users.fname }} {{ program_manager.users.lname }}
                        </td>
                        <td>
                          <span v-if="(program_manager.access_all_programs == '1')" class="badge badge-primary">Yes</span>
                          <span v-else class="badge badge-secondary">No</span>
                        </td>
                        <td>
                          <span v-if="(program_manager.status == '1')" class="badge badge-success">Active</span>
                          <span v-else class="badge badge-danger">Inactive</span>
                        </td>
                        <td>
                          <div class="btn-group btn-group-sm">
                            <button class="btn btn-outline-secondary" data-toggle="tooltip" data-container="body" title="Edit" type="button" @click="editModal(program_manager)"><i class="fa fa-edit"></i></button>
                            <button class="btn btn-outline-secondary" data-toggle="tooltip" data-container="body" title="Delete" type="button" @click="deleteProgramManager(program_manager.id)"><i class="fa fa-trash"></i></button>
                          </div>
                        </td>
                      </tr>

                      <tr 
                        class="expandable-body" 
                        :key="program_manager.index" 
                        :class="(active_program_manager === index) ? '' : 'd-none'">
 
                        <td colspan="6" :class="{ 'active-class': active_program_manager === index }"> 
                          <div class="col-md-12">
                            <div 
                              :style="(active_program_manager === index) ? '' : 'display: none;'" 
                              class="card card-primary card-outline">

                              <div class="card-header">
                                <h3 class="card-title">
                                  Program Access
                                </h3>

                                <div class="card-tools">
                                  <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                    <i class="fas fa-minus text-secondary"></i>
                                  </button>
                                </div>
                              </div>

                              <div class="card-body p-0" style="display: block;">
                                <ul class="nav nav-pills flex-column">
                                  <li v-if="access_all_programs == 1" class="nav-item active">
                                    <a class="nav-link">
                                      <i class="fas fa-check-circle text-success"></i> 
                                      All programs has access.
                                    </a>
                                  </li>
                                  
                                  <li v-else
                                    v-for="program_access in program_accesses.data" :key="program_access.id" 
                                    class="nav-item active">
                                    <a class="nav-link">
                                      <i class="fas fa-arrow-right"></i> 
                                      {{ program_access.program_name }}
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                  <tbody v-show="program_managers.total==0">
                      <tr><td colspan="10">No program manager found.</td></tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                <pagination :data="program_managers" @pagination-change-page="getResults" show-disabled :limit="10"></pagination>
              </div>
            </div>
            <!-- /.card -->
 
          </div>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="programManagerModal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="programManagerModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="programManagerModalLabel">{{ !editmode ? "Create New" : "Update" }} Program Manager</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <form @submit.prevent="editmode ? updateProgramManager() : createProgramManager()" @keydown="form.onKeydown($event)">

              <div class="modal-body">
                <div class="row">

                  <div class="col-md-12">
                    <div class="card card-primary card-outline">

                      <div class="card-body">

                        <div class="form-group">
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text bg-info">USER</span>
                            </div>
                            <selectize 
                              v-if="!editmode" 
                              placeholder="SELECT USER" 
                              v-model="form.user_id" 
                              name="user_id" 
                              class="form-control" 
                              :class="{ 'is-invalid': form.errors.has('user_id') }">
                              <option v-for="user in users" :value="user.id" :key="user.id">{{ user.lname }}, {{ user.fname }}</option>
                            </selectize>
                            <has-error :form="form" field="user_id"></has-error>

                            <input v-if="editmode" type="text" name="user_id" class="form-control" v-model="form.user_id" hidden>
                            <input v-if="editmode" type="text" name="user_id" class="form-control" :value="full_name" disabled>
                          </div>
                        </div>
      
                        <div class="form-group">
                          <div class="input-group">
                            <div class="custom-control custom-switch">
                              <input
                                @click="form.program_id = ''"
                                v-model="form.access_all_programs"
                                name="access_all_programs"
                                type="checkbox" 
                                class="custom-control-input" 
                                id="customSwitch2"
                                value="0">
                              <label class="div-pointer custom-control-label" for="customSwitch2">Access all programs?</label>
                            </div>
                          </div>
                        </div>
 
                        <div class="form-group">
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text bg-info">PROGRAM</span>
                            </div>
                            <selectize 
                              v-if="form.access_all_programs == 0"
                              placeholder="SELECT PROGRAM"
                              v-model="form.program_id" 
                              name="program_id" 
                              class="form-control"
                              :class="{ 'is-invalid': form.errors.has('program_id') }">
                              <option v-for="program in programs" :value="program.id" :key="program.id">{{ program.program_name }}</option>
                            </selectize>
                            <has-error :form="form" field="program_id"></has-error>

                            <input 
                              v-if="form.access_all_programs == 1" 
                              type="text" 
                              name="program_id" 
                              class="form-control" 
                              v-model="form.program_id" hidden>
                            <a v-if="form.access_all_programs == 1" class="form-control nav-link">
                              <i class="fas fa-check-circle text-success"></i> 
                              All programs has access.
                            </a>
                          </div>
                        </div>
                         
                        <div class="form-group">
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text bg-info">STATUS</span>
                            </div>
                            <selectize 
                              placeholder="SELECT STATUS"
                              v-model="form.status" 
                              name="status" 
                              class="form-control" 
                              :class="{ 'is-invalid': form.errors.has('status') }">
                              <option :value="1">Active</option>
                              <option :value="0">Inactive</option>
                            </selectize>
                            <has-error :form="form" field="status"></has-error>
                          </div>
                        </div>
 
                      </div>

                    </div>
                  </div> 

                  <template v-if="access_all_programs == 0">
                  <div v-if="editmode" class="col-md-12">
                    <div class="card card-primary card-outline">
                      <div class="card-body table-responsive p-0">
                        <table class="table table-hover table-striped">
                          <thead>
                            <tr>  
                              <th>Program Access</th>
                              <th>Options</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="program_access in program_accesses.data" :key="program_access.id">          
                              <td>{{ program_access.program_name }}</td>
                              <td>
                                <div class="btn-group btn-group-sm"> 
                                  <button
                                    class="btn btn-outline-secondary" 
                                    data-toggle="tooltip" 
                                    data-container="body" 
                                    title="Delete" 
                                    type="button">
                                    <i class="fa fa-trash"></i>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                          <!-- <tbody>
                            <tr>
                              <td colspan="10">All programs has access.</td>
                            </tr>
                          </tbody> -->
                        </table>
                      </div> 
                    </div>
                  </div>
                  </template>
 
                </div>
              </div>

              <div class="modal-footer">
                <button type="button" class="btn btn-default bg-danger" data-dismiss="modal"><i class="fa fa-times-circle"></i> CLOSE</button>
                <button v-show="editmode" type="submit" class="btn btn-default bg-primary"><i class="fa fa-save"></i> UPDATE</button>
                <button v-show="!editmode" type="submit" class="btn btn-default bg-success"><i class="fa fa-save"></i> CREATE</button>
              </div>

              </form>

            </div>
          </div>
        </div>
 
    </div>

</template>

<script>

    import Form from 'vform';

    export default {
        data() {
          return {
            timer: 30,
            editmode: false,
            emptyflag: false,
            program_managers: {},
            program_accesses: {},
            users: {}, 
            programs: {}, 
            active_program_manager: '',
            active_user_id: '',
            access_all_programs: '',
            user_id: '',
            program_id: '',
            full_name: '', 
            form: new Form({
              id: '',
              user_id: '',
              access_all_programs: false,
              program_id: '',
              status: 1
            })
          }
        },
 
        computed: {
          
          second() {
            return this.time / 1000;
          },

          isIdle() {
            const idlestat =  this.$store.state.idleVue.isIdle;
            if (idlestat == true) {
              $('#sessionmodal').modal('show');
              this.countDownTimer();
            } else {
              $('#sessionmodal').modal('hide');
            }

            return idlestat;
          },

          isDisabled() {
            return !this.terms;
          }

        },

        methods: {
 
          enableSubRow(program_manager, index) {
            this.active_program_manager = index;
            this.active_user_id = program_manager.user_id;
            this.access_all_programs = program_manager.access_all_programs;

            this.program_accesses = {};

            this.$Progress.start();
              axios.get('api/get-program-access-id/' + this.active_user_id).then(({ data }) => (this.program_accesses = data));
            this.$Progress.finish();
          },

          loadProgramAccess() {
            this.program_accesses = {};
            this.active_program_manager = '';
            this.access_all_programs = '';

            // this.access_all_programs = 1;
            // axios.get('api/get-program-access-id/' + this.active_user_id).then(({ data }) => (this.program_accesses = data));
          },
   
          countDownTimer() {
            let timerId = setInterval(() => {
              this.timer -= 1;
              if (!this.$store.state.idleVue.isIdle) clearInterval(timerId);

              if (this.timer < 1) {
                clearInterval(timerId);
                document.getElementById('logout-form').submit();
              }
            }, 1000);

            this.timer = 30;
          },

          createProgramManager() {
            this.$Progress.start();
            this.form.post('api/program-managers')
            .then(() => {
              Trigger.$emit('RefreshData');
              $('#programManagerModal').modal('hide')
              toastr.fire({
                icon: 'success',
                title: 'Program Manager created successfully'
              })
              this.$Progress.finish();
            }).catch(() => {
              toastr.fire({
                icon: 'error',
                title: 'Program Manager creation failed'
              });
              this.$Progress.fail();
            })
          },
 
          updateProgramManager() {
            this.$Progress.start();
            this.form.put('api/program-managers/'+ this.form.id).then(()=>{
              Trigger.$emit('RefreshData');
              $('#programManagerModal').modal('hide')
              toastr.fire({
                icon: 'success',
                title: 'Program Manager information updated successfully'
              })
              this.$Progress.finish();
            }).catch(()=>{
              toastr.fire({
                icon: 'error',
                title: 'Program Manager information updating failed'
              });
              this.$Progress.fail();
            });
          },

          deleteProgramManager(id) {
            swal.fire({

              title: 'Are you sure?',
              text: "You won't be able to revert this!",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes, delete program manager!'
              }).then((result) => {

                if (result.value) {
                  //send delete ajax tru api request to server
                  this.form.delete('api/program-managers/' + id).then(() => {

                    swal.fire(
                      'Deleted!',
                      'Program Manager has been deleted.',
                      'success'
                    );
                    Trigger.$emit('RefreshData');

                  }).catch(() => {

                    swal.fire(
                      'Failed!', 
                      'There was something wrong', 
                      'warning'
                    )
                  });
                }
              })
          },
 
          newModal() {
            this.editmode = false;
 
            this.form.reset();
            this.form.clear();
            this.form.status = '';
            $('#programManagerModal').modal('show')

            axios.get('api/all-programs').then(({ data }) => (this.programs = data));
            axios.get('api/users/getAllUsers').then(({ data }) => (this.users = data));
          },

          editModal(program_manager) {
            this.editmode = true;

            this.full_name = program_manager.users.lname + ', ' + program_manager.users.fname;
  
            this.$Progress.start();
              axios.get('api/all-programs').then(({ data }) => (this.programs = data));
            this.$Progress.finish();
  
            this.form.reset();
            this.form.clear();
 
            $('#programManagerModal').modal('show');
            this.form.fill(program_manager);
          },
 
          getResults(page = 1) {
            let query = this.$parent.search;

            if(query == ''){
              axios.get('api/program-managers?page=' + page).then(response => { this.program_managers = response.data; }); 
            }else{
              axios.get('api/findProgramManager?q=' + query + '&page=' + page).then(response => { this.program_managers = response.data; }); 
            }
          },

          loadProgramManagers() {
            this.$Progress.start();
            
            let query = this.$parent.search;
            let page = this.program_managers.current_page;
 
            if(query == ''){
              axios.get('api/program-managers?page=' + page).then(({ data }) => (this.program_managers = data));
            }else{
              axios.get('api/findProgramManager?q=' + query + '&page=' + page).then(({ data }) => (this.program_managers = data));
            }

            this.$Progress.finish();
          },
 
        },

        created() {
          Trigger.$on('searching',() => {
            let query = this.$parent.search;
            let emptyflag = false;

            if(query == ''){
              Trigger.$emit('RefreshData');
            }else{
              this.$Progress.start();
              axios.get("api/findProgramManager?q=" + query)
              .then((data) => {
                this.program_managers = data.data;
                
                if (data.data.total == 0){
                  this.emptyflag = true;
                }

                this.$Progress.finish();
              })
              .catch(() => {
                this.$Progress.fail();
              })
            }
            
          });
 
          this.loadProgramManagers();
          
          Trigger.$on('RefreshData',() => {
            this.loadProgramManagers();
            this.loadProgramAccess();
          });
        },

    }
</script>

<style lang="scss" scoped>
.div-pointer:hover {
    cursor: pointer;
}

.active-class {
  background-color: #f2f3f4 !important;
  color: #0c0800 !important;
}
</style>
