<template>
    <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card card-secondary card-outline">
              <div class="card-header bg-yellow">
                <h4 class="card-title"><i class="fas fa-file-alt"></i> QR Code-Based e-Certificates</h4>

                <div class="card-tools">
                  <!--
                  <div class="input-group input-group-sm" style="width: 150px;">
                    <input type="text" name="table_search" class="form-control float-right" placeholder="Search">

                    <div class="input-group-append">
                      <button type="submit" class="btn btn-default"><i class="fas fa-search"></i></button>
                    </div>
                  </div>
                  -->

                  <div>
                    <button v-show="can_add" data-toggle="tooltip" data-container="body" title="Add New" class="btn btn-outline-secondary btn-sm" @click="newModal"><i class="fa fa-plus"></i></button>
                  </div>
                  
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">


                <b-table :items="certificates.data" :fields="fields" striped responsive="sm" stacked="md">
                  <template #cell(_)="row">
                    <b-button variant="outline-default" size="sm" @click="row.toggleDetails" onclick="this.blur()" class="mr-2 btn btn-block">
                      <i v-if="row.detailsShowing" class='fa fa-chevron-up' />
                      <i v-else class='fa fa-chevron-down' />
                    </b-button>
                  </template>

                   <template #cell(qr_code)="row">
                    <qr-code :text='"http://dtoms.tapidost.net/download-certificate?cn=" + row.item.control_no' :size="30" error-level="L" color="#000000"></qr-code>
                  </template>

                  <template #cell(control_no)="row">
                    <span v-if="row.item.status!='Active'" class="text-danger">{{ row.item.control_no }}</span>
                    <span v-else class="text-dark">{{ row.item.control_no }}</span>
                  </template>

                  <template #cell(certificate_type)="row">
                    <span v-if="row.item.status!='Active'" class="text-danger">{{ row.item.certificate_type }}</span>
                    <span v-else class="text-dark">{{ row.item.certificate_type }}</span>
                  </template>

                  <template #cell(name)="row">
                    <span v-if="row.item.status!='Active'" class="text-danger">{{ row.item.name }}</span>
                    <span v-else class="text-dark">{{ row.item.name }}</span>
                  </template>

                  <template #cell(status)="row">
                    <span v-if="row.item.status!='Active'" class="right badge badge-danger">{{ row.item.status }}</span>
                    <span v-else class="right badge badge-success">{{ row.item.status }}</span>
                  </template>

                  <template #row-details="row">
                    <b-card>
                      <!--
                      <b-row class="mb-2">
                        <b-col sm="3" class="text-sm-right"><b>Program:</b></b-col>
                        <b-col>{{ row.item.program }}</b-col>
                      </b-row>

                      <b-row class="mb-2">
                        <b-col sm="3" class="text-sm-right"><b>Venue:</b></b-col>
                        <b-col>{{ row.item.venue }}</b-col>
                      </b-row>

                      <b-row class="mb-2">
                        <b-col sm="3" class="text-sm-right"><b>Contents/Body:</b></b-col>
                        <b-col><span v-html="row.item.content"></span></b-col>
                      </b-row>
                      -->

                      <div class="row justify-content-center mt-2">
                        <div class="col-md-9">
                            <div class="card card-default" style="width:853pt;height:595pt;">
                                
                                <div class="card-header">
                                    <h3 class="card-title">
                                        <i class="fas fa-certificate"></i> Certificate Preview 
                                        <span v-if="row.item.status!='Active'" class="right badge badge-danger">{{ row.item.status }}</span>
                                        <span v-else class="right badge badge-success">{{ row.item.status }}</span>
                                    </h3>
                                </div>
                                
                                <div class="card-body text-selection-none" v-bind:style="{'background-image': 'url(storage/certificate_files/' + row.item.background + ')'}" style="font-size: 15px !important;width:853pt;background-size: cover;">
                                    
                                    <div class="row">
                                      <div class="col-12 text-center mt-3">
                                        <!--
                                        <img :src='"img/" + row.item.logo1' height="50px" />
                                        <img :src='"img/" + row.item.logo2' height="50px" />
                                        -->
                                      </div>
                                      <div class="col-12 mt-4 text-center header">
                                        <!--
                                        <span v-html="row.item.header"></span>
                                        -->
                                      </div>
                                      <div class="col-12 mb-2 text-center type">
                                        <!--
                                          <i>Award this</i><br/>
                                          <span style="font-size:58px;font-weight:bold;">CERTIFICATE</span><br/>
                                          <span style="font-size:40px;font-weight:bold;">OF {{ row.item.certificate_type }}</span><br/>
                                          <i>to</i>
                                        -->
                                      </div>
                                      <div class="col-12 text-center name" style="margin-top: 300px !important;">
                                          <p style="font-size:50px;">{{ row.item.name }}</p>
                                      </div>
                                      <!--
                                      <div class="col-12 text-center body">
                                        <span v-html="row.item.content"></span>
                                      </div>
                                      -->
                                    </div>
                                 
                                    <table class="table table-borderless" style="position:absolute; bottom:40px;width: 100%;left: 0px;right:0;">
                                        <tr style="background-color:transparent;">
                                            <td style="font-size: 13px !important;vertical-align:top !important;text-align:center;background-color:transparent;">
                                              <!--
                                              <img :src='"img/signatories/" + row.item.signature1' height="60px" /><br/><b style="font-size: 15px !important;">{{ row.item.signatory1 }}</b><br/>{{ row.item.designation1 }}<br/>{{ row.item.committee1 }}
                                              -->
                                            </td>
                                            <td style="font-size: 13px !important;vertical-align:top !important;text-align:center;background-color:transparent;">{{ row.item.signature2 }}<br/><b style="font-size: 15px !important;">{{ row.item.signatory2 }}</b><br/>{{ row.item.designation2 }}<br/>{{ row.item.committee2 }}</td>
                                            <td style="float:right;background-color:transparent;">
                                              <div class="container" style="display: flex; float: right;">
                                                <div class="fixed" style="width: 105px;">
                                                    <qr-code :text='"http://dtoms.tapidost.net/download-certificate?cn=" + row.item.control_no' :size="85" error-level="L" color="#000000"></qr-code>
                                                </div>
                                                <div class="flex-item" style="flex-grow: 1;font-size: 13px !important;">
                                                    Certificate No.<br/>
                                                    {{ row.item.control_no }}<br/><br/>
                                                    Issued:<br/>
                                                    {{ row.item.issue_date }}
                                                </div>
                                            </div>
                                            </td>
                                        </tr>
                                    </table>

                                </div>
                            </div>
                        </div>
                    </div>

                      <b-button size="sm" variant="outline-default" class="btn btn-block" @click="row.toggleDetails"><i class="fa fa-chevron-up"></i> Hide Details</b-button>
                    </b-card>
                  </template>

                  <template #cell(options)="row">
                    <div class="btn-group btn-group-sm">
                      <button class="btn btn-outline-secondary" v-show="can_edit" vdata-toggle="tooltip" data-container="body" title="Edit" type="button" @click="editModal(row.item)" ><i class="fa fa-edit"></i></button>
                      <button class="btn btn-outline-secondary" v-show="can_delete" data-toggle="tooltip" data-container="body" title="Delete" type="button" @click="deleteCertificate(row.item.id)"><i class="fa fa-trash"></i></button>
                    </div>
                  </template>
                </b-table>
                
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                <pagination :data="certificates" @pagination-change-page="getResults" show-disabled :limit="2"></pagination>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="certificatemodal" tabindex="-1" role="dialog" aria-labelledby="certificateModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 v-show="!editmode" class="modal-title" id="certificateModalLabel">Create New Certificate</h5>
                <h5 v-show="editmode" class="modal-title" id="certificateModalLabel">Update Certificate Information</h5> 
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <form @submit.prevent="editmode ? updateCertificate() : createCertificate()" @keydown="form.onKeydown($event)">

              <div class="modal-body">
              <div class="row">


                  <div class="col-md-5">
                      <!-- Your first column here -->
                      <div class="form-group">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">CONTROL NUMBER</span>
                          </div>
                          <input v-model="form.control_no" type="text" name="control_no" autocomplete="off" placeholder="CONTROL NO.." class="form-control" :class="{ 'is-invalid': form.errors.has('control_no') }">
                          <has-error :form="form" field="control_no"></has-error>
                        </div>
                      </div>

                      <div class="form-group">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">DATE ISSUED</span>
                          </div>
                          <input v-model="form.issue_date" type="date" name="issue_date" id="issuedate-date-input" class="form-control" :class="{ 'is-invalid': form.errors.has('issue_date') }">
                          <has-error :form="form" field="issue_date"></has-error>
                        </div>
                      </div>

                      <div class="form-group">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">CERTIFICATE TYPE</span>
                          </div>
                          <select v-model="form.certificate_type" name="type" class="form-control" placeholder="SELECT CERTIFICATE TYPE.." :class="{ 'is-invalid': form.errors.has('certificate_type') }">
                            <option selected disabled value="">SELECT TYPE..</option>
                            <option value="ACCREDITATION">CERTIFICATE OF ACCREDITATION</option>
                            <option value="APPEARANCE">CERTIFICATE OF APPEARANCE</option>
                            <option value="APPRECIATION">CERTIFICATE OF APPRECIATION</option>
                            <option value="ATTENDANCE">CERTIFICATE OF ATTENDANCE</option>
                            <option value="CONFIRMATION">CERTIFICATE OF CONFIRMATION</option>
                            <option value="PARTICIPATION">CERTIFICATE OF PARTICIPATION</option>
                            <option value="RECOGNITION">CERTIFICATE OF RECOGNITION</option>
                            <option value="TAX/DUTY EXEMPTION">CERTIFICATE OF TAX/DUTY EXEMPTION</option>
                          </select>
                          <has-error :form="form" field="certificate_type"></has-error>
                        </div>
                      </div>

                      <div class="form-group">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">CLIENT NAME</span>
                          </div>
                          <input v-model="form.name" type="text" name="name" autocomplete="off" placeholder="CLIENT NAME.." class="form-control" :class="{ 'is-invalid': form.errors.has('name') }">
                          <has-error :form="form" field="name"></has-error>
                        </div>
                      </div>

                      <div class="form-group">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">PROGRAM</span>
                          </div>
                          <input v-model="form.program" type="text" name="program" autocomplete="off" placeholder="PROGRAM.." class="form-control" :class="{ 'is-invalid': form.errors.has('program') }">
                          <has-error :form="form" field="program"></has-error>
                        </div>
                      </div>

                      <div class="form-group">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">VENUE</span>
                          </div>
                          <input v-model="form.venue" type="text" name="venue" autocomplete="off" placeholder="VENUE.." class="form-control" :class="{ 'is-invalid': form.errors.has('venue') }">
                          <has-error :form="form" field="venue"></has-error>
                        </div>
                      </div>

                      <div class="form-group">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">STATUS</span>
                          </div>
                          <select v-model="form.status" name="type" class="form-control" :class="{ 'is-invalid': form.errors.has('status') }">
                            <option selected disabled value="">SELECT STATUS..</option>
                            <option value="Active">Active</option>
                            <option value="Expired">Expired</option>
                          </select>
                          <has-error :form="form" field="status"></has-error>
                        </div>
                      </div>

                      <div class="form-group">
                        Selected Background Image:
                        <input v-model="form.background" type="hidden" name="background" autocomplete="off" placeholder="BACKGROUND IMAGE.." class="form-control" :class="{ 'is-invalid': form.errors.has('background') }">
                        <img v-if="!form.background" src="http://via.placeholder.com/350x248?text=3508x2480" class="img-thumbnail" width="100%" />
                        <img v-else :src='"storage/certificate_files/" + form.background' class="img-thumbnail" width="100%" />
                        <has-error :form="form" field="background"></has-error>
                      </div>

    
                  </div>


                  <!-- 2nd Column -->
                  <div class="col-md-7">
                    <div class="row">
                        <div class="col-md-12 p-0">
                          <certificate-file-uploader @update-table="loadCertificateBackgrounds()"></certificate-file-uploader>
                        </div>
                    </div>

                    <div class="row mt-3">
                      <div class="col-md-12">
                        <div class="card card-outline">
                          <div class="card-header">
                            <h3 class="card-title">Select Background</h3>
                            <div class="card-tools">
                              
                            </div>
                          </div>

                          <div class="card-body p-0">
                            <!--
                            <div class="mailbox-controls">
                              <button type="button" class="btn btn-default btn-sm checkbox-toggle"><i class="far fa-square"></i></button>
                              <div class="btn-group">
                                <button type="button" class="btn btn-default btn-sm"><i class="far fa-trash-alt"></i></button>
                                <button type="button" class="btn btn-default btn-sm"><i class="fas fa-reply"></i></button>
                                <button type="button" class="btn btn-default btn-sm"><i class="fas fa-share"></i></button>
                              </div>

                              <button type="button" class="btn btn-default btn-sm"><i class="fas fa-sync-alt"></i></button>
                              <div class="float-right">1-50/200
                                <div class="btn-group">
                                  <button type="button" class="btn btn-default btn-sm"><i class="fas fa-chevron-left"></i></button>
                                  <button type="button" class="btn btn-default btn-sm"><i class="fas fa-chevron-right"></i></button>
                                </div>
                              </div>
                            </div>
                            -->
                            <div v-if="certbgs.total!=0" class="p-3">
                              <div class="row mt-1">
                                <div class="cc-selector-2 img-thumbnail ml-3 mb-3" v-for="bgs in certbgs.data" :key="bgs.id">
                                    <input :id="bgs.id" type="radio" v-model="form.background" name="creditcard" :value="bgs.filepath" />
                                    <label class="drinkcard-cc visa" :style="'background-image:url(storage/certificate_files/' + bgs.filepath + ');'" :for="bgs.id"
                                    v-tooltip="{
                                                                content: '<b>File Name:</b> ' + bgs.filename + '<br/><b>Type:</b> ' + bgs.filetype + '<br/><b>Size:</b> ' + bgs.filesize + '<br/><b>Uploaded:</b> ' + bgs.created_at,
                                                                placement: 'top',
                                                                classes: ['info'],
                                                                targetClasses: ['it-has-a-tooltip'],
                                                                offset: 10,
                                                                delay: {
                                                                  show: 500,
                                                                  hide: 300,
                                                                },
                                                              }"
                                                              ></label>
                                </div>
                              </div>
                            </div>
                            <div v-else class="d-flex justify-content-center mb-5 mt-5">
                              <div>No images found.</div>
                            </div>
                          </div>

                          <div class="card-footer p-0">
                            <div class="mailbox-controls">
                              <!--
                              <button type="button" class="btn btn-default btn-sm checkbox-toggle"><i class="far fa-square"></i></button>
                              <div class="btn-group">
                                <button type="button" class="btn btn-default btn-sm"><i class="far fa-trash-alt"></i></button>
                                <button type="button" class="btn btn-default btn-sm"><i class="fas fa-reply"></i></button>
                                <button type="button" class="btn btn-default btn-sm"><i class="fas fa-share"></i></button>
                              </div>
                              <button type="button" class="btn btn-default btn-sm"><i class="fas fa-sync-alt"></i></button>
                              -->
                              <div class="float-right"><span v-show="certbgs.total!=0">{{ certbgs.from }}-{{ certbgs.to }}/{{ certbgs.total }}</span>
                                <div class="btn-group">
                                  <button :disabled="!certbgs.prev_page_url" type="button" class="btn btn-default btn-sm mb-1" @click.prevent="prevPage(certbgs.prev_page_url)"><i class="fas fa-chevron-left"></i></button>
                                  <button :disabled="!certbgs.next_page_url" type="button" class="btn btn-default btn-sm mb-1" @click.prevent="nextPage(certbgs.next_page_url)"><i class="fas fa-chevron-right"></i></button>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
   
     
          
              </div>
              

              


              <!--
              <div class="row mt-5">
                  <div class="col-md-12">
                        <label>HEADER:</label>
                        <vue-editor name="header" v-model="form.header" :class="{ 'is-invalid': form.errors.has('header') }"></vue-editor>
                        <has-error :form="form" field="header"></has-error>
                  </div>
              </div>
              -->

              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-outline-secondary" data-dismiss="modal"><i class="fa fa-times-circle"></i> CLOSE</button>
                <button v-show="editmode" type="submit" class="btn btn-outline-secondary"><i class="fa fa-save"></i> UPDATE</button>
                <button v-show="!editmode" type="submit" class="btn btn-outline-secondary"><i class="fa fa-save"></i> CREATE</button>
              </div>

              </form>

            </div>
          </div>
        </div>

      </div>

</template>

<script>

    import Form from 'vform';
    import { VueEditor } from "vue2-editor";
    import CertificateFileUploader from './uploaders/CertificateFileUploader';
    import moment from 'moment';
    
    export default {
        name: 'Certificates',

        components: {
            VueEditor, CertificateFileUploader,
        },

        data() {
          return {
            timer: 30,
            editmode: false,
            emptyflag: false,
            can_access: false,
            can_add: false,
            can_edit: false,
            can_delete: false,
            certificates : {},
            certbgs: {},
            fields: ['_', 'qr_code', 'control_no', 'issue_date', 'certificate_type', 'name', 'status', 'options'],
            form: new Form({
              id: '',
              header: '',
              control_no: '',
              certificate_type: '',
              name: '',
              program: '',
              details: '',
              content: '',
              issue_date: '',
              venue: '',
              status: '',
              background: '',
              logo1: '',
              logo2: '',
              signatory1: '',
              signature1: '',
              designation1: '',
              committee1: '',
              signatory2: '',
              signature2: '',
              designation2: '',
              committee2: '',
            })
          }
        },

        computed: {
          
          second() {
            return this.time / 1000;
          },

          isIdle() {
            const idlestat =  this.$store.state.idleVue.isIdle;
            if (idlestat == true) {
              $('#sessionmodal').modal('show');
              this.countDownTimer();
            } else {
              $('#sessionmodal').modal('hide');
            }

            return idlestat;

          },

        },

        methods: {

          copyQr () {
            //
          },

          loadCertificateBackgrounds() {
                this.$Progress.start();
                axios.get('api/certificatefiles').then(({ data }) => (this.certbgs = data));
                this.$Progress.finish();
            },

          countDownTimer() {
            let timerId = setInterval(() => {
              this.timer -= 1;
              if (!this.$store.state.idleVue.isIdle) clearInterval(timerId);

              if (this.timer < 1) {
                clearInterval(timerId);
                document.getElementById('logout-form').submit();
              }
            }, 1000);

            this.timer = 30;
          },

          createCertificate() {
            this.$Progress.start();
            this.form.post('api/certificates')
            .then(()=>{
              Trigger.$emit('RefreshData');
              $('#certificatemodal').modal('hide')
              toastr.fire({
                icon: 'success',
                title: 'Certificate information created successfully'
              })
              this.$Progress.finish();
            }).catch(()=>{
              toastr.fire({
                icon: 'error',
                title: 'Certificate information creation failed'
              });
              this.$Progress.fail();
            })
          },

          updateCertificate() {
            this.$Progress.start();
            this.form.put('api/certificates/'+this.form.id).then(()=>{
              Trigger.$emit('RefreshData');
              $('#certificatemodal').modal('hide')
              toastr.fire({
                icon: 'success',
                title: 'Certificate information updated successfully'
              })
              this.loadCertificateBackgrounds();
              this.$Progress.finish();
            }).catch(()=>{
              toastr.fire({
                icon: 'error',
                title: 'Certificate information updating failed'
              });
              this.$Progress.fail();
            });
          },

          deleteCertificate(id) {
            swal.fire({

              title: 'Are you sure?',
              text: "You won't be able to revert this!",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes, delete record!'
              }).then((result)=>{
                if (result.value) {
                  this.form.delete('api/certificates/'+id).then(()=>{
                    swal.fire(
                      'Deleted!',
                      'Certificate information has been deleted.',
                      'success'
                    );
                    Trigger.$emit('RefreshData');
                  }).catch(()=>{
                    swal.fire(
                      'Failed!', 
                      'There was something wrong', 
                      'warning'
                    )
                  });
                }
            })
          },

          editModal(certificate) {
            this.editmode = true;
            this.form.reset();
            this.form.clear();
            this.loadCertificateBackgrounds();
            $('#certificatemodal').modal('show');
            this.form.fill(certificate);
          },

          zeroPad(num, places) {
            var zero = places - num.toString().length + 1;
            return Array(+(zero > 0 && zero)).join("0") + num;
          },

          async generateControlNo() {
            var code;
            const current = new Date();
            const current_year =  current.getFullYear();

            await axios.get('api/certificates/lastid').then(response => {
              if (!response.data) {
                this.new_control_no = 'TAPI-' + current_year + '-0001';
                this.form.control_no = 'TAPI-' + current_year + '-0001';
              } else {
                code = response.data.control_no.slice(response.data.control_no.length - 4);
                code = parseInt(code) + 1
                this.new_control_no = 'TAPI-' + current_year + '-' + this.zeroPad(code, 4);
                this.form.control_no = 'TAPI-' + current_year + '-' + this.zeroPad(code, 4);
              }
            });
          },

          newModal() {
            this.editmode = false;
            this.form.reset();
            this.form.clear();
            this.generateControlNo();
            this.loadCertificateBackgrounds();
            $('#certificatemodal').modal('show')
          },

          getResults(page = 1) {

            let query = this.$parent.search;

            if(query == ''){
              axios.get('api/certificates?page=' + page).then(response => { this.certificates = response.data; }); 
            }else{
              axios.get('api/findCertificate?q=' + query + '&page=' + page).then(response => { this.certificate = response.data; }); 
            }

            console.log(query);

            
          },

          nextPage(page) {
            this.$Progress.start();
            axios.get(page).then(({ data }) => (this.certbgs = data));
            this.$Progress.finish();
          },

          prevPage(page) {
            this.$Progress.start();
            axios.get(page).then(({ data }) => (this.certbgs = data));
            this.$Progress.finish();
          },

          loadCertificates() {
            this.$Progress.start();
            
            let query = this.$parent.search;
            let page = this.certificates.current_page;
            
            if(query == ''){
              axios.get('api/certificates?page=' + page).then(({ data }) => (this.certificates = data));
            }else{
              axios.get('api/findCertificate?q=' + query + '&page=' + page).then(({ data }) => (this.certificates = data));
            }

            this.$Progress.finish();
          },

          async loadPermission(mid) {
              const response = await axios.get("api/permission/" + this.$gate.showMyID() + "/" + mid).then( 
              res => {
                if (!res.data || res.data.length == 0) {
                  return false;
                } else {
                  return true;
                }
              })

              const getResponse = async () => {
                const a = await response;

                switch (mid) {
                case 44:
                  this.can_access = a;
                  if (!this.can_access) {
                    this.$router.push({ name: 'not-found' }) //redirect to not found
                  }
                  break;
                case 45:
                  this.can_add = a;
                  break;
                case 46:
                  this.can_edit = a;
                  break;
                case 47:
                  this.can_delete = a;
                }
              };
              return getResponse()
          },
        },

        mounted() {
          //
        },

        created() {
          
          Trigger.$on('searching',() => {
            let query = this.$parent.search;
            let emptyflag = false;

            if(query == ''){
              Trigger.$emit('RefreshData');
            }else{
              this.$Progress.start();
              axios.get("api/findCertificate?q=" + query)
              .then((data) => {
                this.certificates = data.data;
                
                if (data.data.total == 0){
                  this.emptyflag = true;
                }

                this.$Progress.finish();
              })
              .catch(() => {
                this.$Progress.fail();
              })
            }
            
          });
          
          this.loadCertificates();
          this.loadPermission(44); //Can access Certificates list
          this.loadPermission(45); //Can add new Certificate
          this.loadPermission(46); //Can edit Certificate details
          this.loadPermission(47); //Can delete Certificate
          this.loadPermission(48); //Can access Certificates settings
          
          Trigger.$on('RefreshData',() => {
            this.loadCertificates();
          });
        },


    }
</script>
<style scoped>
div >>> p {
  font-size: 15px;
  margin: 0 !important;
}

div >>> strong {
  font-size: 15px;
}

div >>> em {
  font-size: 15px;
}

.text-selection-none{
   user-select: none; /* supported by Chrome and Opera */
   -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
   -moz-user-select: none; /* Firefox */
   -ms-user-select: none;
}

div.header {
    line-height: 0.5;
    font-size: 15px;
    font-family: "Open Sans", sans-serif;
    color:#404A66;
}

div.type {
    line-height: 0.2;
    color: #404A66;
    font-family: 'Montserrat', sans-serif;
    color:#404A66;
}

p.ql-align-center {
    margin: 0 !important;
}

div.name {
    line-height: 0.5;
    font-size: 70px;
    /*font-family: 'Savoye LET', sans-serif;*/
    font-family: 'Montserrat', sans-serif;
    color: #404A66;
    text-decoration: underline;
    text-decoration-thickness: 0.1rem;
    text-underline-offset: 0.4rem;
}

div.body {
    line-height: 0.5;
    font-size: 15px;
    font-family: "Open Sans", sans-serif;
    color:#404A66;
}

.cc-selector-2 input{
    position:absolute;
    z-index:999;
}


.cc-selector-2 input:active +.drinkcard-cc, .cc-selector input:active +.drinkcard-cc{opacity: .9;}
.cc-selector-2 input:checked +.drinkcard-cc, .cc-selector input:checked +.drinkcard-cc{
    -webkit-filter: none;
       -moz-filter: none;
            filter: none;
}
.drinkcard-cc{
    cursor:pointer;
    background-size:cover;
    background-repeat:no-repeat;
    display:inline-block;
    width:100px;height:70px;
    -webkit-transition: all 100ms ease-in;
       -moz-transition: all 100ms ease-in;
            transition: all 100ms ease-in;
    -webkit-filter: brightness(1.1) grayscale(1) opacity(.7);
       -moz-filter: brightness(1.1) grayscale(1) opacity(.7);
            filter: brightness(1.1) grayscale(1) opacity(.7);
}
.drinkcard-cc:hover{
    background-size:cover;
    background-repeat:no-repeat;
    -webkit-filter: brightness(1.1) grayscale(.5) opacity(.9);
       -moz-filter: brightness(1.1) grayscale(.5) opacity(.9);
            filter: brightness(1.1) grayscale(.5) opacity(.9);
}

/* Extras */
.cc-selector-2 input{ margin: 5px 0 0 12px; }
.cc-selector-2 label{ margin-left: 0px; margin-bottom: 0px; }
span.cc{ color:#6d84b4 }

</style>