<template>
    <div class="">

        <div class="row mb-2">
            <div class="col-sm-12">
                <h2>Project Industry</h2>
            </div>
        </div>

        <section class="content">
                <div class="row">
                    <div class="col-md-4">
                        <div class="card">
                            <div class="card-header">
                                <h3 class="card-title">
                                <i class="fa fa-list-ol mr-1"></i>
                                Manage project industries
                                </h3>
                                <div class="card-tools">
                                    <button type="button" @click.prevent="newModal" class="btn btn-tool" title="Add new industry"><i class="fas fa-plus"></i></button>
                                </div>
                            </div>

                            <div class="card-body">
                                <div class="drag-list" v-for="ind in projectindustries" :key="ind.id">
                                    <span class="text ml-2">{{ind.industryname}}</span>
                                    <div class="tools">
                                        <i @click.prevent="editModal(ind)" class="fa fa-edit"></i>
                                        <i @click.prevent="deleteIndustry(ind.id)" class="fa fa-trash"></i>
                                    </div>
                                </div>
                            </div>

                            <div class="card-footer clearfix">
                                <button type="button" @click.prevent="newModal" class="btn btn-primary float-right"><i class="fas fa-plus"></i> Add item</button>
                            </div>
                        </div>
                    </div>
                </div>
        </section>

        <!-- Modal : Add/Edit Project -->
        <div class="modal fade" id="industrymodal" tabindex="-1" role="dialog" aria-labelledby="industryModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-md" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 v-show="!editmode" class="modal-title" id="industryModalLabel">Create New Project Industry</h5>
                <h5 v-show="editmode" class="modal-title" id="industryModalLabel">Update Project Industry</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <form @submit.prevent="editmode ? updateIndustry() : createIndustry()" @keydown="form.onKeydown($event)">
              <div class="modal-body">
                <div class="row">

                    <div class="col-md-12">
                        <!-- Your first column here -->
                        <div class="form-group">
                            <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text">PROJECT INDUSTRY</span>
                            </div>
                            <input v-model="form.industryname" type="text" name="industryname" autocomplete="off" placeholder="INDUSTRY NAME.." class="form-control" :class="{ 'is-invalid': form.errors.has('industryname') }">
                            <has-error :form="form" field="industryname"></has-error>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-default" data-dismiss="modal"><i class="fa fa-times-circle"></i> CLOSE</button>
                <button v-show="editmode" type="submit" class="btn btn-default"><i class="fa fa-save"></i> UPDATE</button>
                <button v-show="!editmode" type="submit" class="btn btn-default"><i class="fa fa-save"></i> CREATE</button>
              </div>

              </form>

            </div>
          </div>
        </div>

    </div>

</template>

<script>

    import Form from 'vform';
    import draggable from 'vuedraggable'

    export default {

        components: {
            draggable
        },

        data() {
          return {
            timer: 30,
            editmode: false,
            emptyflag: false,
            can_access: false,
            can_add: false,
            can_edit: false,
            can_delete: false,
            projectindustries : [],
            form: new Form({
                id: '',
                industryname: '',
            })
          }
        },

        computed: {

          second() {
            return this.time / 1000;
          },

          isIdle() {
            const idlestat =  this.$store.state.idleVue.isIdle;
            if (idlestat == true) {
              $('#sessionmodal').modal('show');
              this.countDownTimer();
            } else {
              $('#sessionmodal').modal('hide');
            }

            return idlestat;

          },

        },

        methods: {

            countDownTimer() {
                let timerId = setInterval(() => {
                this.timer -= 1;
                if (!this.$store.state.idleVue.isIdle) clearInterval(timerId);

                if (this.timer < 1) {
                    clearInterval(timerId);
                    document.getElementById('logout-form').submit();
                }
                }, 1000);
                this.timer = 30;
            },

            editModal(ind) {
                this.editmode = true;
                this.form.reset();
                this.form.clear();
                $('#industrymodal').modal('show');
                this.form.fill(ind);
            },

            newModal() {
                this.editmode = false;
                this.form.reset();
                this.form.clear();
                $('#industrymodal').modal('show')
            },

            createIndustry() {
              this.$Progress.start();
              this.form.post('api/projectindustries')
              .then(()=>{
                Trigger.$emit('RefreshData');
                $('#industrymodal').modal('hide')
                toastr.fire({
                  icon: 'success',
                  title: 'Project industry created successfully'
                })
                this.$Progress.finish();
              }).catch(()=>{
                toastr.fire({
                  icon: 'error',
                  title: 'Industry information creation failed'
                });
                this.$Progress.fail();
              })
            },

            updateIndustry() {
              this.$Progress.start();
              this.form.put('api/projectindustries/'+this.form.id).then(()=>{
                Trigger.$emit('RefreshData');
                $('#industrymodal').modal('hide')
                toastr.fire({
                  icon: 'success',
                  title: 'Industry information updated successfully'
                })
                this.$Progress.finish();
              }).catch(()=>{
                toastr.fire({
                  icon: 'error',
                  title: 'Industry information updating failed'
                });
                this.$Progress.fail();
              });
            },

            deleteIndustry(id) {
              swal.fire({

                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete industry!'
                }).then((result)=>{
                  if (result.value) {
                    this.form.delete('api/projectindustries/'+id).then(()=>{
                      swal.fire(
                        'Deleted!',
                        'Industry has been deleted.',
                        'success'
                      );
                      Trigger.$emit('RefreshData');
                    }).catch(error => {
                        if (error.response.status === 422) {
                        swal.fire(
                            'Failed!',
                            error.response.data.message,
                            'warning'
                        );
                        } else {
                        swal.fire(
                            'Failed!',
                            'There was something wrong:' + error,
                            'warning'
                        );
                        }
                    });
                  }
              })
            },

            loadIndustries() {
                this.$Progress.start();
                axios.get('api/projectindustries').then(({ data }) => ( this.projectindustries = data ));
                this.$Progress.finish();
            },

        },


        created() {

            this.loadIndustries();

            Trigger.$on('RefreshData',() => {
                this.loadIndustries();
            });
        },

    }
</script>

<style scoped>
/*image*/
.img-thumbnail{
  height: 164px;
  overflow: hidden;
  margin: auto;

}

.drag-list {
    border-radius: 2px;
    background-color: #f8f9fa;
    border-left: 2px solid #e9ecef;
    color: #495057;
    margin-bottom: 2px;
    padding: 10px;
    display: inline-block;
    font-weight: 600;
    margin-left: 5px;
    width: 100%;
}

.drag-list:hover .tools {
    display: block;
    color: #dc3545;
}

.tools {
    color: #7a8999;
    display: block;
    float: right;
    cursor: pointer;
}

.list-move{
  transition: .5s;
}
.handle {
    cursor: move;
}

@import 'https://adminlte.io/themes/v3/plugins/bs-stepper/css/bs-stepper.min.css';
</style>
