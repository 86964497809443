<template>
    <div class="container-fluid">

        <div class="row mb-2 ml-2">
            <div class="col-sm-12">
                <h2>Notifications</h2>
            </div>
        </div>

        <section class="content">
            <div class="container-fluid">
                <div class="card">
                    <div class="card-header">
                        <h3 class="card-title">
                        <i class="fa fa-list-ol mr-1"></i>
                        All notifications
                        </h3>
                        <div class="card-tools">
                        </div>
                    </div>

                    <div class="card-body">
                        <div class="row notifitem" v-for="(notification, index) in notifications.data"  v-bind:key="index">
                            <router-link style="text-decoration:none;width: 100%;" :to="notification.url">
                            <span class="dropdown-item"  :style="[!notification.notificationstate[0] ? {'background-color': '#E6F2FF'} : {}]">
                                <div class="media">
                                    <img :src="'img/profile/' + notification.user.photo" alt="User Avatar" class="img-size-50 mr-3 img-circle">
                                    <div class="media-body">
                                        <h3 class="dropdown-item-title">
                                            {{ notification.user.fname | capitalize }} {{ notification.user.lname | capitalize }}
                                            <span class="float-right text-sm text-primary">
                                                <i v-show="!notification.notificationstate[0]" class="fa fa-circle"  @click.prevent="readNotification(notification.id, index)"> Mark as Read</i>
                                            </span>
                                        </h3>
                                        <p class="text-sm">{{ notification.details }}</p>
                                        <p class="text-sm text-muted"><i class="fa fa-clock mr-1"></i>
                                            <timeago :datetime="notification.created_at"></timeago>
                                        </p>
                                    </div>
                                </div>
                            </span>
                            </router-link>
                            <a v-if="notifications.data && notifications.data.length == 0" href="#" v-show="notifications.data" class="dropdown-item dropdown-footer text-center">No New Notifications</a>
                        </div>
                    </div>

                    <div class="card-footer clearfix">
                        <pagination :data="notifications" @pagination-change-page="getResults" show-disabled :limit="2"></pagination>
                    </div>
                </div>






            </div>
        </section>

    </div>

</template>

<script>

    import Form from 'vform';
    import draggable from 'vuedraggable'

    export default {

        components: {
            draggable
        },

        data() {
          return {
            timer: 30,
            editmode: false,
            emptyflag: false,
            can_access: false,
            can_add: false,
            can_edit: false,
            can_delete: false,
            notifications: {},
            form: new Form({
              notification_id: '',
              user_id: '',
            })
          }
        },

        computed: {

          second() {
            return this.time / 1000;
          },

          isIdle() {
            const idlestat =  this.$store.state.idleVue.isIdle;
            if (idlestat == true) {
              $('#sessionmodal').modal('show');
              this.countDownTimer();
            } else {
              $('#sessionmodal').modal('hide');
            }

            return idlestat;

          },

        },

        methods: {

            countDownTimer() {
                let timerId = setInterval(() => {
                this.timer -= 1;
                if (!this.$store.state.idleVue.isIdle) clearInterval(timerId);

                if (this.timer < 1) {
                    clearInterval(timerId);
                    document.getElementById('logout-form').submit();
                }
                }, 1000);
                this.timer = 30;
            },

            readNotification(nid, index) {
                this.form.notification_id = nid;
                this.form.user_id = this.$gate.showMyID();

                this.form.post('api/notificationstates').then(()=>{
                    this.notifications.data[index].notificationstate.push({id: 1, user_id: this.$gate.showMyID(), notification_id: nid, isRead: 1})
                }).catch(()=>{
                    console.log('read failed!')
                });
            },

            getResults(page = 1) {
                axios.get('api/notifications?page=' + page).then(response => { this.notifications = response.data; });
            },

        },

        async created () {
        //this.loadPermission();
        //this.subscribeChannel('notification-channel');
        await axios.get("api/notifications").then(result => {
            this.notifications = result.data;
        }).catch(err => {
            console.log(err);
        });
    },

    }
</script>

<style scoped>

.notifitem {
    border-bottom: 1px solid #DEDEDE;
}
.card-body {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}
</style>
