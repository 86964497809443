<template>
    <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card card-secondary card-outline">
              <div class="card-header">
                <h3 class="card-title"><i class="fas fa-building"></i> Offices</h3>

                <div class="card-tools">
                  <div>
                    <button class="btn btn-success btn-sm" @click="newModal"><i class="fa fa-plus"></i></button>
                  </div>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0 m-0">
                <table class="table responsive-table table-sm table-striped">
                  <thead>
                    <tr>
                      <th scope="col" class="pl-2">Short Name</th>
                      <th scope="col">Name of Office</th>
                      <th scope="col">Type</th>
                      <th scope="col">Status</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="office in offices" :key="office.id">
                      <td v-if="office.parent_id == 0" scope="row" class="pl-2"><b>{{ office.alias }}</b></td>
                      <td v-else scope="row" class="pl-2"><b>→ </b>{{ office.alias }}</td>
                      <td data-title="Name of Office">{{ office.name }}</td>

                      <td data-title="Type">
                        <span v-if="(office.type == 1)">Regional</span>
                        <span v-else-if="(office.type == 2)">Executive Office</span>
                        <span v-else-if="(office.type == 3)">Service</span>
                        <span v-else-if="(office.type == 4)">Division</span>
                        <span v-else-if="(office.type == 5)">Section</span>
                        <span v-else-if="(office.type == 6)">Unit</span>
                        <span v-else-if="(office.type == 7)">Project</span>
                        <span v-else-if="(office.type == 8)">Committee/Cluster</span>
                        <span v-else>Non Office</span>
                      </td>

                      <td data-title="Status">
                        <span v-if="(office.status == 1)" class="badge badge-success">PMO</span>
                        <span v-else-if="(office.status == 2)" class="badge badge-info">Enduser</span>
                        <span v-else class="badge badge-danger">Removed</span>
                      </td>

                      <td data-title="Options">
                        <div class="btn-group">
                          <button type="button" class="btn btn-default" @click="editModal(office)"><i class="fas fa-edit text-primary"></i></button>
                          <button type="button" class="btn btn-default" @click="actionDelete(office.id)"><i class="fas fa-times text-danger"></i></button>
                        </div>
                      </td>


                    </tr>
                  </tbody>
                  <tbody v-show="offices.total==0">
                      <tr><td colspan="10">No offices found.</td></tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
            </div>
            <!-- /.card -->
          </div>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="actionmodal" tabindex="-1" role="dialog" aria-labelledby="actionaddModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-md" role="document">
            <div class="modal-content">
                <div class="modal-header">
                <h5 class="modal-title" id="actionaddModalLabel" v-if="editmode">Edit Office</h5>
                <h5 class="modal-title" id="actionaddModalLabel" v-else>Add New Office</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <div class="modal-body">
                    <div class="form-group">
                        <label for="exampleInputEmail1">Office Name</label>
                        <input type="text" class="form-control" v-model="form.name" :class="{ 'is-invalid': form.errors.has('name') }">
                        <has-error :form="form" field="name"></has-error>
                    </div>
                    <div class="form-group">
                        <label for="exampleInputEmail1">Office Alias</label>
                        <input type="text" class="form-control" v-model="form.alias" :class="{ 'is-invalid': form.errors.has('alias') }">
                        <has-error :form="form" field="alias"></has-error>
                    </div>

                    <div class="form-group">
                        <label for="exampleInputEmail1">Parent Office</label>
                        <selectize placeholder="- Select Office -" v-model="form.parent_id" :settings="settings" class="form-control" :class="{ 'is-invalid': form.errors.has('parent_id') }">
                            <option value="0">No Parent Office</option>
                            <option v-for="office in offices" :key="office.id" v-bind:value="office.id" >{{ office.alias }}</option>
                        </selectize>
                        <has-error :form="form" field="alias"></has-error>
                    </div>
                    <div class="form-group">
                        <label for="exampleInputEmail1">Level of Type</label>
                        <select class="form-control" v-model="form.type" :class="{ 'is-invalid': form.errors.has('type') }">
                            <option value=""> - Select Status - </option>
                            <option value="1">Regional</option>
                            <option value="2">Executive Office</option>
                            <option value="3">Service</option>
                            <option value="4">Division</option>
                            <option value="5">Section</option>
                            <option value="6">Unit</option>
                            <option value="7">Project</option>
                            <option value="8">Committee/Cluster</option>
                        </select>
                        <has-error :form="form" field="type"></has-error>
                    </div>
                    <div class="form-group">
                        <label for="exampleInputEmail1">Level of Access</label>
                        <select class="form-control" v-model="form.status" :class="{ 'is-invalid': form.errors.has('status') }">
                            <option value=""> - Select Status - </option>
                            <option value="1">PMO</option>
                            <option value="2">Enduser</option>
                            <option value="3">Removed</option>
                        </select>
                        <has-error :form="form" field="status"></has-error>
                    </div>
                </div>
                <div class="card-footer">
                <button v-if="editmode" class="btn btn-primary" data-container="body" type="button" @click="actionUpdate()"><i class="fa fa-save"></i> Update</button>
                <button v-else class="btn btn-primary" data-container="body" type="button" @click="actionSave()"><i class="fa fa-save"></i> Save</button>
                <button class="btn btn-primary" data-container="body" type="button" data-dismiss="modal" aria-label="Close"><i class="fa fa-times"></i> Close</button>
                </div>
            </div>
            </div>
        </div>


    </div>
</template>

<script>

    import Form from 'vform';
    import { VueEditor } from "vue2-editor";
    import moment from 'moment';

    export default {
        name: 'Offices',

        components: {
            VueEditor,
        },

        data() {
          return {
            settings: {},
            timer: 30,
            editmode: false,
            loading: false,
            emptyflag: false,
            can_access: false,
            can_add: false,
            can_edit: false,
            can_delete: false,
            offices : {},
            form: new Form({
                id: null,
                alias: '',
                name: '',
                parent_id: null,
                type: null,
                status: null
            }),
          }
        },

        props: [
          'todo'
        ],

        computed: {

          second() {
            return this.time / 1000;
          },

          isIdle() {
            const idlestat =  this.$store.state.idleVue.isIdle;
            if (idlestat == true) {
              $('#sessionmodal').modal('show');
              this.countDownTimer();
            } else {
              $('#sessionmodal').modal('hide');
            }
            return idlestat;
          },

        },

        methods: {

          countDownTimer() {
            let timerId = setInterval(() => {
              this.timer -= 1;
              if (!this.$store.state.idleVue.isIdle) clearInterval(timerId);

              if (this.timer < 1) {
                clearInterval(timerId);
                document.getElementById('logout-form').submit();
              }
            }, 1000);

            this.timer = 30;
          },


          newModal() {
            this.editmode = false;
            this.form.reset();
            this.form.clear();
            $('#actionmodal').modal('show');
          },

          editModal(payload) {
            this.editmode = true;
            this.form.reset();
            this.form.clear();
            $('#actionmodal').modal('show');
            this.form.fill(payload);
          },

          actionDelete(id) {
            swal.fire({
              title: 'Are you sure?',
              text: "You won't be able to revert this!",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes, delete office!'
              }).then((result)=>{
                if (result.value) {
                  this.form.delete('api/offices/'+id).then(()=>{
                    swal.fire(
                      'Deleted!',
                      'Office has been deleted.',
                      'success'
                    );
                    Trigger.$emit('RefreshData');
                  }).catch(()=>{
                    swal.fire(
                      'Failed!',
                      'There was something wrong',
                      'warning'
                    )
                  });
                }
            })
          },

          actionSave() {
            this.$Progress.start();
            this.form.post('api/offices').then(()=>{
              Trigger.$emit('RefreshData');
              $('#actionmodal').modal('hide')
              toastr.fire({
                icon: 'success',
                title: 'Item Saved'
              })
              this.$Progress.finish();
            }).catch(()=>{
              toastr.fire({
                icon: 'error',
                title: 'Item saving failed'
              });
              this.$Progress.fail();
            });
          },

          actionUpdate() {
            this.$Progress.start();
            this.form.put('api/offices/'+this.form.id).then(()=>{
              Trigger.$emit('RefreshData');
              $('#actionmodal').modal('hide')
              toastr.fire({
                icon: 'success',
                title: 'Item Updated'
              })
              this.$Progress.finish();
            }).catch(()=>{
              toastr.fire({
                icon: 'error',
                title: 'Item updating failed'
              });
              this.$Progress.fail();
            });
          },

          getResults(page = 1) {

            this.$Progress.start();

            let query = this.$parent.search;

            if(query == ''){
              axios.get('api/offices?page=' + page).then(response => { this.offices = response.data; });
            }else{
              axios.get('api/findOffice?q=' + query + '&page=' + page).then(response => { this.offices = response.data; });
            }

            this.$Progress.finish();
          },

          loadOffices() {
            this.$Progress.start();

            let query = this.$parent.search;
            let page = this.offices.current_page;

            if(query == ''){
              axios.get('api/offices?page=' + page).then(({ data }) => (this.offices = data));
            }else{
              axios.get('api/findOffice?q=' + query + '&page=' + page).then(({ data }) => (this.offices = data));
            }

            this.$Progress.finish();
          },

          async loadPermission(mid) {
              const response = await axios.get("api/permission/" + this.$gate.showMyID() + "/" + mid).then(
              res => {
                if (!res.data || res.data.length == 0) {
                  return false;
                } else {
                  return true;
                }
              })

              const getResponse = async () => {
                const a = await response;

                switch (mid) {
                case 59:
                  this.can_access = a;
                  if (!this.can_access) {
                    this.$router.push({ name: 'not-found' }) //redirect to not found
                  }
                  break;
                  /*
                case 50:
                  this.can_edit = a;
                  break;
                case 51:
                  this.can_delete = a;
                  */
                }
              };
              return getResponse()
          },


        },

        created() {
          Trigger.$on('searching',() => {
            let query = this.$parent.search;
            let emptyflag = false;

            if(query == ''){
              Trigger.$emit('RefreshData');
            }else{
              this.$Progress.start();
              axios.get("api/findOffice?q=" + query)
              .then((data) => {
                this.offices = data.data;

                if (data.data.total == 0){
                  this.emptyflag = true;
                }

                this.$Progress.finish();
              })
              .catch(() => {
                this.$Progress.fail();
              })
            }

          });

          this.loadOffices();
          this.loadPermission(59); //Can Manage Offices

          Trigger.$on('RefreshData',() => {
            this.loadOffices();
          });
        },

    }
</script>
<style scoped>
div >>> p {
  font-size: 15px;
}

div >>> strong {
  font-size: 15px;
}

div >>> em {
  font-size: 15px;
}

div.header >>> p {
  line-height: 0.5;
  font-size: 20px;
  font-family: "Helvetica Narrow","Arial Narrow";
}

div.header >>> strong {
  line-height: 0.5;
  font-size: 20px;
  font-family: "Helvetica Narrow","Arial Narrow";
}

div.header >>> em {
  line-height: 0.5;
  font-size: 20px;
  font-family: "Helvetica Narrow","Arial Narrow";
}

.text-selection-none{
   user-select: none; /* supported by Chrome and Opera */
   -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
   -moz-user-select: none; /* Firefox */
   -ms-user-select: none;
}
div.tblProject table {
  width: 100% !important;
}

div.tblProject table td, div.tblProject table th {
  border: 1px solid #6C757D !important;
  border-collapse: collapse !important;
  padding: 5px !important;
}

div.tblProject >>> table {
  width: 100% !important;
}

div.tblProject >>> table td, div.tblProject >>> table th {
  border: 1px solid #6C757D !important;
  border-collapse: collapse !important;
  padding: 5px !important;
}

div.tblProject >>> br {
  display: block; /* makes it have a width */
  content: ""; /* clears default height */
  margin-top: 15px; /* change this to whatever height you want it */
}
</style>
