<template>
    <div class="container-fluid">
        <div class="row">
 
          <div class="col-md-4">
            <div class="form-group">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text bg-info">PROGRAMS</span>
                </div>
                <select @change="filterIndicatorData" v-model="program_id" name="program_id" class="form-control" autocomplete="off" placeholder="SELECT PROGRAM">
                  <option selected value="">All Programs</option>
                  <option v-for="program in programs" :value="program.id" :key="program.id">{{ program.program_name }}</option>
                </select>
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text bg-info">YEAR</span>
                </div>
                <select @change="filterIndicatorData" v-model="year" name="year" class="form-control" placeholder="SELECT YEAR">
                  <option selected disabled value="">SELECT YEAR</option>
                  <option v-for="year in years" :value="year" :key="year">{{ year }}</option>
                </select>
              </div>
            </div>
          </div>

        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="card card-secondary card-outline">
              <div class="card-header">
                <h4 class="card-title"><i class="fas fa-hand-point-right"></i> Indicators</h4>

                <div class="card-tools">
                  <div class="form-group clearfix"> 
                    <button 
                      data-toggle="tooltip" 
                      data-container="body"
                      title="Reset Filter" 
                      class="btn btn-outline-secondary btn-sm bg-blue"
                      @click="resetFilter">
                      <i class="fa fa-sync"></i>
                    </button>

                    <button 
                      data-toggle="tooltip" 
                      data-container="body" 
                      title="Add New Indicator" 
                      class="btn btn-outline-secondary btn-sm bg-green" 
                      @click="newModal">
                      <i class="fa fa-plus"></i>
                    </button>
                  </div>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <table class="table table-bordered table-hover">
                  <thead>
                    <tr>  
                      <th>Indicator Name</th>
                      <th>Program Under</th>
                      <th>Year</th>
                      <th>With Amount of Investment</th>
                      <th>Status</th>
                      <th>Options</th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-for="(indicator, index) in indicators.data">
                      <tr class="div-pointer" 
                        :class="{ 'active-class': active_indicator === indicator.id }"
                        @click="enableSubRow(indicator)" 
                        :key="index" 
                        data-widget="expandable-table" aria-expanded="false">

                        <td>
                          <i :class="(active_indicator === indicator.id) ? 'fas fa-angle-down' : 'fas fa-angle-right'"></i>
                          {{ indicator.indicator_name }}
                        </td>
                        <td>{{ indicator.programs.program_name }}</td>
                        <td>{{ indicator.year }}</td>
                        <td>
                          <span v-if="(indicator.with_amount == '1')" class="badge badge-primary">Yes</span>
                          <span v-else class="badge badge-secondary">No</span>
                        </td>
                        <td>
                          <span v-if="(indicator.is_active == '1')" class="badge badge-success">Active</span>
                          <span v-else class="badge badge-danger">Inactive</span>
                        </td>
                        <td>
                          <div class="btn-group btn-group-sm">
                            <button class="btn btn-outline-secondary" data-toggle="tooltip" data-container="body" title="Edit" type="button" @click="editModal(indicator)"><i class="fa fa-edit"></i></button>
                            <button class="btn btn-outline-secondary" data-toggle="tooltip" data-container="body" title="Delete" type="button" @click="deleteIndicator(indicator.id)"><i class="fa fa-trash"></i></button>
                          </div>
                        </td>
                      </tr>

                      <tr 
                        class="expandable-body" 
                        :key="indicator.index" 
                        :class="(active_indicator === indicator.id) ? '' : 'd-none'">

                        <!-- <td colspan="12"> -->
                        <td colspan="6" :class="{ 'active-class': active_indicator === indicator.id }"> 
                          <div class="col-md-12">
                            <div 
                              :style="(active_indicator === indicator.id) ? '' : 'display: none;'" 
                              class="card card-primary card-outline">

                              <div class="card-header">
                                <h3 class="card-title">
                                  Details
                                </h3>

                                <div class="card-tools">
                                  <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                    <i class="fas fa-minus text-secondary"></i>
                                  </button>
                                </div>
                              </div>

                              <div class="card-body p-0" style="display: block;">
                                <ul class="nav nav-pills flex-column">
                                  <li v-if="total_sub_indicators == 0" class="nav-item active">
                                    <a class="nav-link">
                                      <i class="fas fa-exclamation-circle text-warning"></i> 
                                      No details found.
                                    </a>
                                  </li>

                                  <li v-else
                                    v-for="sub_indicator in indicator.sub_indicators" 
                                    :key="sub_indicator.id" 
                                    class="nav-item active">
                                    <a class="nav-link">
                                      <i class="fas fa-arrow-right"></i> 
                                      {{ sub_indicator.sub_indicator_name }}
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                  <tbody v-show="indicators.total==0">
                      <tr><td colspan="10">No indicators found.</td></tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                <pagination :data="indicators" @pagination-change-page="getResults" show-disabled :limit="10"></pagination>
              </div>
            </div>
            <!-- /.card -->
 
          </div>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="indicatorModal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="indicatorModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 v-show="!editmode" class="modal-title" id="indicatorModalLabel">Create New Indicator</h5>
                <h5 v-show="editmode" class="modal-title" id="indicatorModalLabel">Update Indicator</h5> 
                <button @click="resetFilterTwo" type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <form @submit.prevent="editmode ? updateIndicator() : createIndicator()" @keydown="form.onKeydown($event)">

              <div class="modal-body">
                <div class="row">

                  <div class="col-md-12">
                    <div class="card card-primary card-outline">

                      <div class="card-body">
 
                        <div class="form-group">
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text bg-info">PROGRAM</span>
                            </div>
                            <select v-if="!editmode" placeholder="SELECT PROGRAM" v-model="form.program_id" name="program_id" class="form-control" :class="{ 'is-invalid': form.errors.has('program_id') }">
                              <option selected value="" disabled>SELECT PROGRAM</option>
                              <option v-for="program in programs" :value="program.id" :key="program.id">{{ program.program_name }}</option>
                            </select>
                            <has-error :form="form" field="program_id"></has-error>

                            <input v-if="editmode" type="text" name="program_id" class="form-control" v-model="form.program_id" hidden>
                            <input v-if="editmode" type="text" name="program_id" class="form-control" :value="program_name" disabled>
                          </div>
                        </div>
 
                        <div class="form-group">
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text bg-info">INDICATOR NAME</span>
                            </div>
                            <input v-model="form.indicator_name" type="text" name="indicator_name" autocomplete="off" placeholder="INDICATOR NAME" class="form-control" :class="{ 'is-invalid': form.errors.has('indicator_name') }">
                            <has-error :form="form" field="indicator_name"></has-error>
                          </div>
                        </div>
 
                        <div class="form-group">
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text bg-info">YEAR</span>
                            </div>
                            <select v-if="!editmode" placeholder="SELECT YEAR" v-model="form.year" name="year" class="form-control" :class="{ 'is-invalid': form.errors.has('year') }">
                              <option selected value="" disabled>SELECT YEAR</option>
                              <option v-for="year in years" :value="year" :key="year">{{ year }}</option>
                            </select>
                            <has-error :form="form" field="year"></has-error>

                            <input v-if="editmode" type="text" name="year" class="form-control" :value="form.year" disabled>
                          </div>
                        </div>
   
                        <div class="form-group">
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text bg-info">STATUS</span>
                            </div>
                            <select v-model="form.is_active" name="is_active" class="form-control" :class="{ 'is-invalid': form.errors.has('is_active') }">
                              <option selected disabled value="">SELECT STATUS</option>
                              <option value="1">Active</option>
                              <option value="0">Inactive</option>
                            </select>
                            <has-error :form="form" field="is_active"></has-error>
                          </div>
                        </div>

                        <div class="form-group">
                          <div class="input-group">
                            <div class="custom-control custom-switch">
                              <input 
                                v-model="form.allow_attachment" 
                                name="allow_attachment" 
                                type="checkbox" 
                                class="custom-control-input" 
                                id="customSwitch1" 
                                value="1">
                              <label class="div-pointer custom-control-label" for="customSwitch1">Allow attachment on this Indicator.</label>
                            </div>
                          </div>
                        </div>

                      </div>

                    </div>
                  </div> 
 
                  <div class="col-md-12"> 
 
                    <div class="card card-primary card-outline">
                      
                      <div class="card-header">
                        <h4 class="card-title">
                          <div class="form-group">
                            <div class="input-group">
                              <div class="custom-control custom-switch">
                                <input
                                  v-model="form.with_sub_indicators"
                                  name="with_sub_indicators"
                                  type="checkbox" 
                                  class="custom-control-input" 
                                  id="customSwitch3"
                                  value="0">
                                <label 
                                  class="div-pointer custom-control-label" 
                                  for="customSwitch3">
                                  With Details?
                                </label>
                              </div>
                            </div>
                          </div>
                        </h4>

                        <div class="card-tools">
                          <div>
                            <button 
                              :disabled="!form.with_sub_indicators" 
                              v-if="form.sub_indicators.length < 10" 
                              @click="addNewSubIndicator()" 
                              data-toggle="tooltip" 
                              title="Add More Details" 
                              type="button" 
                              class="btn btn-outline-secondary btn-sm bg-green">
                              <i class="fa fa-plus"></i>
                            </button>
                            <button v-else disabled data-toggle="tooltip" title="Add New Detail" type="button" class="btn btn-success btn-sm">
                              <i class="fa fa-plus"></i>
                            </button>
                          </div>
                        </div>
                      </div>

                      <div class="card-body">

                        <a v-show="form.sub_indicators.length == 0" class="nav-link">
                          <i class="fas fa-exclamation-circle text-warning"></i> 
                          No Details found.
                        </a>

                        <div class="form-group" v-for="(sub_indicator, index) in form.sub_indicators" :key="index">
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text bg-info">DETAIL NAME</span>
                              <button v-show="form.sub_indicators.length > 1" type="button" class="btn btn-success btn-flat" disabled>{{ index + 1 }}</button>
                              <!-- <button type="button" class="btn btn-secondary btn-flat">{{ sub_indicator.id }}</button> -->
                            </div>

                            <input 
                              :disabled="!form.with_sub_indicators" 
                              v-model="sub_indicator.sub_indicator_name" 
                              type="text" 
                              name="sub_indicator_name" 
                              autocomplete="off" 
                              placeholder="DETAIL NAME" 
                              class="form-control" 
                              :class="{ 'is-invalid': form.errors.has('sub_indicator_name') }">
                            <span v-show="form.sub_indicators.length > 1" class="input-group-append">
                              <button 
                                :disabled="!form.with_sub_indicators" 
                                @click="removeSubIndicator(sub_indicator.sub_indicator_name, sub_indicator.id, index)" 
                                type="button" 
                                class="btn btn-danger btn-flat">
                                <i class="fa fa-trash"></i>
                              </button>
                            </span>
                              
                            <has-error :form="form" field="sub_indicator_name"></has-error>
                          </div>
                        </div>

                        <hr>

                        <div class="form-group">
                          <div class="input-group">
                            <div class="custom-control custom-switch">
                              <input
                                :disabled="!form.with_sub_indicators" 
                                @click="editmode == false ? '' : withAmountInvestment(form.id)"
                                v-model="form.with_amount" 
                                name="with_amount" 
                                type="checkbox" 
                                class="custom-control-input" 
                                id="customSwitch2" 
                                value="1">
                              <label 
                                title="This will be include in Indicator as Detail when encoding in Target Data."
                                data-toggle="tooltip" 
                                class="div-pointer custom-control-label" 
                                for="customSwitch2">
                                With Amount of Investment (Detail)
                              </label>
                            </div>
                          </div>
                          <!-- <h6><i>* This will be display automatically in Target Data when encoding.</i></h6> -->
                        </div>

                      </div>
                    </div>

                  </div>

                  <div v-if="editmode" class="col-md-6">
                    <div class="card card-primary card-outline">

                      <div class="card-header">
                        <h4 class="card-title">
                          <div class="form-group">
                            <div class="input-group">
                              <b><i class="fas fa-bullseye mr-1"></i> Targets (January to June) - {{ form.year }}</b>
                            </div>
                          </div>
                        </h4>
                      </div>

                      <div class="card-body">
                        <div class="form-group" v-for="(indicator_target, index) in form.indicator_targets.slice(0, 6)" :key="index">
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text bg-info">{{ indicator_target.target_month | monthwordformat }}</span>
                            </div>
                            <input 
                              v-model="indicator_target.target_number" 
                              @keypress="numbersOnly"
                              type="text" 
                              name="target_number" 
                              autocomplete="off" 
                              placeholder="TARGET NUMBER" 
                              class="form-control" 
                              :class="{ 'is-invalid': form.errors.has('target_number') }">
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>

                  <div v-if="editmode" class="col-md-6">
                    <div class="card card-primary card-outline">

                      <div class="card-header">
                        <h4 class="card-title">
                          <div class="form-group">
                            <div class="input-group">
                              <b><i class="fas fa-bullseye mr-1"></i> Targets (July to December) - {{ form.year }}</b>
                            </div>
                          </div>
                        </h4>
                      </div>

                      <div class="card-body">
                        <div class="form-group" v-for="(indicator_target, index) in form.indicator_targets.slice(6, 12)" :key="index">
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text bg-info">{{ indicator_target.target_month | monthwordformat }}</span>
                            </div>
                            <input 
                              v-model="indicator_target.target_number" 
                              @keypress="numbersOnly"
                              type="text" 
                              name="target_number" 
                              autocomplete="off" 
                              placeholder="TARGET NUMBER" 
                              class="form-control" 
                              :class="{ 'is-invalid': form.errors.has('target_number') }">
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
    
                </div>
              </div>

              <div class="modal-footer">
                <button @click="resetFilterTwo" type="button" class="btn btn-danger" data-dismiss="modal"><i class="fa fa-times-circle"></i> CLOSE</button>
                <button v-show="editmode" type="submit" class="btn btn-primary"><i class="fa fa-save"></i> UPDATE</button>
                <button v-show="!editmode" type="submit" class="btn btn-success"><i class="fa fa-save"></i> CREATE</button>
              </div>

              </form>

            </div>
          </div>
        </div>
 
    </div>

</template>

<script>

    import Form from 'vform';

    export default {
        data() {
          return {
            timer: 30,
            editmode: false,
            emptyflag: false,
            indicators: {},
            programs: {}, 
            active_indicator: '',
            total_sub_indicators: '',
            program_id: '',
            program_name: '',
            year: '',
            form: new Form({
              id: '',
              program_id: '',
              indicator_name: '',
              year: '',
              allow_attachment: false,
              with_amount: false,
              is_active: '',
              sub_indicators: [{
                  sub_indicator_name: ''
              }],
              with_sub_indicators: false,
              indicator_targets: [{
                  target_number: ''
              }],
            })
          }
        },
 
        computed: {
          
          second() {
            return this.time / 1000;
          },

          isIdle() {
            const idlestat =  this.$store.state.idleVue.isIdle;
            if (idlestat == true) {
              $('#sessionmodal').modal('show');
              this.countDownTimer();
            } else {
              $('#sessionmodal').modal('hide');
            }

            return idlestat;
          },

          isDisabled() {
            return !this.terms;
          },

          years() {
            const year = new Date().getFullYear()
            return Array.from({length: year - 1997}, (value, index) => 1998 + index)
          },

        },

        methods: {

          enableSubRow(indicator) {

            this.active_indicator = indicator.id;
            this.total_sub_indicators = indicator.sub_indicators.length;

            // if(this.active_indicator == '')
            // {
            //   this.active_indicator = indicator.id;
            //   this.total_sub_indicators = indicator.sub_indicators.length;
            // }
            // else
            // {
            //   this.active_indicator = '';
            //   this.total_sub_indicators = '';
            // }
            
          },

          resetFilter() {
            this.program_id = '';
            this.active_indicator = '';
            this.year = '';
 
            this.loadIndicators();
          },

          resetFilterTwo() {
            this.active_indicator = '';

            this.filterIndicatorData();
          },
 
          addNewSubIndicator() {
              this.form.sub_indicators.push({
                  sub_indicator_name: ''
              })
          },

          removeSubIndicator_(name, id, index) {
            console.log(id);
          },
 
          removeSubIndicator(name, id, index) {
            if(name == '' || id == undefined)
            {
              this.form.sub_indicators.splice(index, 1);
            }
            else
            {
              swal.fire({

              title: 'Are you sure?',
              text: "You won't be able to revert this!",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes, delete sub indicator!'
              }).then((result) => {

                if (result.value) {
                  //send delete ajax tru api request to server
                  this.form.delete('api/indicators/delete-sub-indicator/'+id).then(() => {

                    this.form.sub_indicators.splice(index, 1);

                    swal.fire(
                      'Deleted!',
                      'Sub Indicator has been deleted.',
                      'success'
                    );
                    Trigger.$emit('RefreshData');

                  }).catch(() => {

                    swal.fire(
                      'Failed!', 
                      'There was something wrong', 
                      'warning'
                    )
                  });
                }
              })
            }
          },

          countDownTimer() {
            let timerId = setInterval(() => {
              this.timer -= 1;
              if (!this.$store.state.idleVue.isIdle) clearInterval(timerId);

              if (this.timer < 1) {
                clearInterval(timerId);
                document.getElementById('logout-form').submit();
              }
            }, 1000);

            this.timer = 30;
          },

          createIndicator() {
            this.$Progress.start();
            this.form.post('api/indicators')
            .then(() => {
              Trigger.$emit('RefreshData');
              $('#indicatorModal').modal('hide')
              toastr.fire({
                icon: 'success',
                title: 'Indicator created successfully'
              })
              this.$Progress.finish();
            }).catch(() => {
              toastr.fire({
                icon: 'error',
                title: 'Indicator creation failed'
              });
              this.$Progress.fail();
            })
          },

          deleteIndicator(id) {
            swal.fire({

              title: 'Are you sure?',
              text: "You won't be able to revert this!",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes, delete indicator!'
              }).then((result) => {

                if (result.value) {
                  //send delete ajax tru api request to server
                  this.form.delete('api/indicators/'+id).then(() => {

                    swal.fire(
                      'Deleted!',
                      'Indicator has been deleted.',
                      'success'
                    );
                    Trigger.$emit('RefreshData');

                  }).catch(() => {

                    swal.fire(
                      'Failed!', 
                      'There was something wrong', 
                      'warning'
                    )
                  });
                }
              })
          },

          updateIndicator() {
            this.$Progress.start();
            this.form.put('api/indicators/'+ this.form.id).then(()=>{
              Trigger.$emit('RefreshData');
              // $('#indicatorModal').modal('hide')

              this.active_indicator = '';

              toastr.fire({
                icon: 'success',
                title: 'Indicator information updated successfully'
              })
              this.$Progress.finish();
            }).catch(()=>{
              toastr.fire({
                icon: 'error',
                title: 'Indicator information updating failed'
              });
              this.$Progress.fail();
            });
          },

          editModal(indicator) {
            this.editmode = true;

            this.program_name = indicator.programs.program_name;
  
            this.form.reset();
            this.form.clear();
 
            $('#indicatorModal').modal('show');
            this.form.fill(indicator);
          },

          newModal() {
            this.editmode = false;
            this.form.reset();
            this.form.clear();
            
            const today = new Date();
            const curr_year = today.getFullYear(); 

            if(this.form.year == '') {
              this.form.year = curr_year; 
            } else {
              this.form.year; 
            }

            $('#indicatorModal').modal('show')
          },

          withAmountInvestment(indicator_id) {
            this.$Progress.start();

            this.form.post('api/with-amount-investment/' + indicator_id).then(()=>{
              toastr.fire({
                icon: 'success',
                title: 'With Amount of Investment setting changed'
              });
 
              this.$Progress.finish();
              Trigger.$emit('RefreshData');

            }).catch(()=>{
              toastr.fire({
                icon: 'error',
                title: 'With Amount of Investment changing failed'
              });

              this.$Progress.fail();

            });
          },
 
          getResults(page = 1) {
            let query = this.$parent.search;

            if(query == ''){
              axios.get('api/indicators?page=' + page).then(response => { this.indicators = response.data; }); 
            }else{
              axios.get('api/findIndicator?q=' + query + '&page=' + page).then(response => { this.indicators = response.data; }); 
            }
          },

          loadIndicators() {
            this.$Progress.start();
            
            let query = this.$parent.search;
            let page = this.indicators.current_page;

            // fetch data programs for dropdown
            axios.get('api/all-programs').then(({ data }) => (this.programs = data));
            
            if(query == ''){
              axios.get('api/indicators?page=' + page).then(({ data }) => (this.indicators = data));
            }else{
              axios.get('api/findIndicator?q=' + query + '&page=' + page).then(({ data }) => (this.indicators = data));
            }

            this.$Progress.finish();
          },

          filterIndicatorData() {
            this.active_indicator = '';
            this.total_sub_indicators = '';
            
            let program_id = this.program_id
            let year = this.year

            program_id = (program_id == '') ? 0 : parseInt(this.program_id)
            year = (year == '') ? 0 : year
           
            this.$Progress.start();
 
            axios.get('api/filter-indicators/' + program_id + '/' + year).then(({ data }) => (this.indicators = data));
 
            this.$Progress.finish();
          },

          numbersOnly(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
              evt.preventDefault();;
            } else {
              return true;
            }
          },
 
        },

        created() {
          Trigger.$on('searching',() => {
            let query = this.$parent.search;
            let emptyflag = false;

            if(query == ''){
              Trigger.$emit('RefreshData');
            }else{
              this.$Progress.start();
              axios.get("api/findIndicator?q=" + query)
              .then((data) => {
                this.indicators = data.data;

                this.active_indicator = '';
                
                if (data.data.total == 0){
                  this.emptyflag = true;
                }

                this.$Progress.finish();
              })
              .catch(() => {
                this.$Progress.fail();
              })
            }
            
          });
 
          this.loadIndicators();
          
          Trigger.$on('RefreshData',() => {
            // this.loadIndicators();
            
            let program_id = this.program_id
            let year = this.year

            program_id = (program_id == '') ? 0 : parseInt(program_id)
            year = (year == '') ? 0 : year
            
            axios.get('api/filter-indicators/' + program_id + '/' + year).then(({ data }) => (this.indicators = data));
          });
        },

    }
</script>

<style lang="scss" scoped>
.div-pointer:hover {
    cursor: pointer;
}

.active-class {
  background-color: #f2f3f4 !important;
  color: #0c0800 !important;
}
</style>
