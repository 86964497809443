<template>
    <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card card-secondary card-outline">
              <div class="card-header">
                <h4 class="card-title"><i class="fas fa-tasks"></i> Program List</h4>

                <div class="card-tools">
                  <div>
                    <button data-toggle="tooltip" data-container="body" title="Add New Program" class="btn btn-success btn-sm" @click="newModal"><i class="fa fa-plus"></i></button>
                  </div>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <table class="table table-hover table-striped">
                  <thead>
                    <tr>  
                      <th>Program Name</th>
                      <th>Division</th>
                      <th>Status</th>
                      <th>Options</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="program in programs.data" :key="program.id">
                      <td>{{ program.program_name }}</td>
                      <td> {{ program.project.project_name }} </td>
                      <td>
                        <span v-if="(program.is_active == '1')" class="badge badge-success">Active</span>
                        <span v-else class="badge badge-danger">Inactive</span>
                      </td>
                      <td>
                        <div class="btn-group btn-group-sm">
                          <button class="btn btn-outline-secondary" data-toggle="tooltip" data-container="body" title="Edit" type="button" @click="editModal(program)" ><i class="fa fa-edit"></i></button>
                          <button class="btn btn-outline-secondary" data-toggle="tooltip" data-container="body" title="Delete" type="button" @click="deleteProgram(program.id)"><i class="fa fa-trash"></i></button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-show="programs.total==0">
                      <tr><td colspan="10">No programs found.</td></tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                <pagination :data="programs" @pagination-change-page="getResults" show-disabled :limit="10"></pagination>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="programModal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="programModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 v-show="!editmode" class="modal-title" id="programModalLabel">Create New Program</h5>
                <h5 v-show="editmode" class="modal-title" id="programModalLabel">Update Program</h5> 
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <form @submit.prevent="editmode ? updateProgram() : createProgram()" @keydown="form.onKeydown($event)">

              <div class="modal-body">
                <div class="row">
                  
                  <div class="col-md-12">
                    <div class="card card-primary card-outline">
                      <div class="card-body">
                        <!-- Your first column here -->
                        <div class="form-group">
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text bg-info">SELECT PROJECT</span>
                            </div>
                            <select placeholder="SELECT PROJECT" v-model="form.project_id" name="project_id" class="form-control" :class="{ 'is-invalid': form.errors.has('project_id') }">
                              <option selected value="" disabled>PROJECT NAME</option>
                              <option v-for="project in projects" :key="project.id" v-bind:value="project.id" >{{ project.project_name }}</option>
                            </select>
                            <has-error :form="form" field="project_id"></has-error>
                          </div>
                        </div>

                        <div class="form-group">
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text bg-info">PROGRAM NAME</span>
                            </div>
                            <input v-model="form.program_name" type="text" name="program_name" autocomplete="off" placeholder="PROGRAM NAME" class="form-control" :class="{ 'is-invalid': form.errors.has('program_name') }">
                            <has-error :form="form" field="program_name"></has-error>
                          </div>
                        </div>

                        <div class="form-group">
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text bg-info">STATUS</span>
                            </div>
                            <select v-model="form.is_active" name="type" class="form-control" :class="{ 'is-invalid': form.errors.has('is_active') }">
                              <option selected disabled value="">SELECT STATUS</option>
                              <option value="1">Active</option>
                              <option value="0">Inactive</option>
                            </select>
                            <has-error :form="form" field="is_active"></has-error>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12">
                    <div class="card">
                      <div class="card-header bg-info">
                        <h4 class="card-title"><i class="fas fa-unlock-alt"></i> User's that can access this program.</h4>
                      </div>

                      <div class="card-body">
                        <div class="form-group">
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text bg-info">Users</span>
                            </div>
                              <multi-select 
                                v-model="form.selected_users"
                                :options="user_lists"
                                :multiple="true"
                                @search-change="multiSelectUsers"
                                class="form-control"
                                placeholder="Select Users"
                                :custom-label="customLabel"
                                track-by="username">
                              </multi-select>
                          </div>
                        </div> 
                      </div> 
                    </div>
                  </div>

                </div>
              </div>

              <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal"><i class="fa fa-times-circle"></i> CLOSE</button>
                <button v-show="editmode" type="submit" class="btn btn-primary"><i class="fa fa-save"></i> UPDATE</button>
                <button v-show="!editmode" type="submit" class="btn btn-success"><i class="fa fa-save"></i> CREATE</button>
              </div>

              </form>

            </div>
          </div>
        </div>

    </div>

</template>

<script>

    import Form from 'vform';

    export default {
        data() {
          return {
            timer: 30,
            editmode: false,
            emptyflag: false,
            programs : {},
            program_accesses : {},
            projects : {},
            users : {},
            form: new Form({
              id: '',
              program_name: '',
              project_id: '',
              is_active: '',
              selected_users: [],
            }),
            user_lists: [],
          }
        },

        computed: {
          
          second() {
            return this.time / 1000;
          },

          isIdle() {
            const idlestat =  this.$store.state.idleVue.isIdle;
            if (idlestat == true) {
              $('#sessionmodal').modal('show');
              this.countDownTimer();
            } else {
              $('#sessionmodal').modal('hide');
            }

            return idlestat;

          },

        },

        methods: {

          countDownTimer() {
            let timerId = setInterval(() => {
              this.timer -= 1;
              if (!this.$store.state.idleVue.isIdle) clearInterval(timerId);

              if (this.timer < 1) {
                clearInterval(timerId);
                document.getElementById('logout-form').submit();
              }
            }, 1000);

            this.timer = 30;
          },
  
          customLabel(users) {
            return users.lname + ', ' + users.fname + ' ' + users.mname;
          },
 
          multiSelectUsers() {
            this.user_lists = this.users;
          },

          createProgram() {
            this.$Progress.start();
            this.form.post('api/programs')
            .then(() => {
              Trigger.$emit('RefreshData');
              $('#programModal').modal('hide')
              toastr.fire({
                icon: 'success',
                title: 'Program created successfully'
              })
              this.$Progress.finish();
            }).catch(() => {
              toastr.fire({
                icon: 'error',
                title: 'Program creation failed'
              });
              this.$Progress.fail();
            })
          },

          deleteProgram(id) {
            swal.fire({

              title: 'Are you sure?',
              text: "You won't be able to revert this!",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes, delete program!'
              }).then((result) => {

                if (result.value) {
                  //send delete ajax tru api request to server
                  this.form.delete('api/programs/'+id).then(() => {

                    swal.fire(
                      'Deleted!',
                      'Program has been deleted.',
                      'success'
                    );
                    Trigger.$emit('RefreshData');

                  }).catch(() => {

                    swal.fire(
                      'Failed!', 
                      'There was something wrong', 
                      'warning'
                    )
                  });
                }
              })
          },

          updateProgram() {
            this.$Progress.start();
            this.form.put('api/programs/'+ this.form.id).then(()=>{
              Trigger.$emit('RefreshData');
              $('#programModal').modal('hide')
              toastr.fire({
                icon: 'success',
                title: 'Program information updated successfully'
              })
              this.$Progress.finish();
            }).catch(()=>{
              toastr.fire({
                icon: 'error',
                title: 'Program information updating failed'
              });
              this.$Progress.fail();
            });
          },

          editModal(program) {
            // fetch data projects for dropdown
            // axios.get("api/projects").then(({ data }) => (this.projects = data));

            this.editmode = true;
            this.form.reset();
            this.form.clear();
            $('#programModal').modal('show');
            this.form.fill(program);
            
            this.multiSelectUsers();
            this.form.selected_users = program.program_accesses;
          },

          newModal() {
            // fetch data projects for dropdown
            // axios.get("api/projects").then(({ data }) => (this.projects = data));

            this.editmode = false;
            this.form.reset();
            this.form.clear();
            this.multiSelectUsers();
            $('#programModal').modal('show')
          },
  
          getResults(page = 1) {
            let query = this.$parent.search;

            if(query == ''){
              axios.get('api/programs?page=' + page).then(response => { this.programs = response.data; }); 
            }else{
              axios.get('api/findProgram?q=' + query + '&page=' + page).then(response => { this.programs = response.data; }); 
            }

            console.log(query);
          },

          loadPrograms() {
            this.$Progress.start();
            
            let query = this.$parent.search;
            let page = this.programs.current_page;
            
            if(query == ''){
              axios.get('api/programs?page=' + page).then(({ data }) => (this.programs = data));
            }else{
              axios.get('api/findProgram?q=' + query + '&page=' + page).then(({ data }) => (this.programs = data));
            }

            this.$Progress.finish();
          },
 
        },

        created() {
          Trigger.$on('searching',() => {
            let query = this.$parent.search;
            let emptyflag = false;

            if(query == ''){
              Trigger.$emit('RefreshData');
            }else{
              this.$Progress.start();
              axios.get("api/findProgram?q=" + query)
              .then((data) => {
                this.programs = data.data;
                
                if (data.data.total == 0){
                  this.emptyflag = true;
                }

                this.$Progress.finish();
              })
              .catch(() => {
                this.$Progress.fail();
              })
            }
            
          });
          
          // fetch data projects for dropdown
          axios.get("api/projects").then(({ data }) => (this.projects = data));
          axios.get("api/users/getAllUsers").then(({ data }) => (this.users = data));
  
          this.loadPrograms();
          
          Trigger.$on('RefreshData',() => {
            this.loadPrograms();
          });
        },

    }
</script>