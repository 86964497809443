<template>
    <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card card-secondary card-outline">
              <div class="card-header bg-yellow">
                <h4 class="card-title"><i class="fas fa-file-alt"></i> Official Business Forms</h4>
                <div class="card-tools">
                  <div class="row">
                    <div class="col-md-6">
                        <select @change="loadResults" name="type" v-model="year" class="form-control">
                            <option value="">- Select Year -</option>
                            <option v-for="year in years" :value="year" :key="year">{{ year }}</option>
                        </select>
                    </div>
                    <div class="col-md-6">
                        <select @change="loadResults" name="type" v-model="month" class="form-control">
                            <option value="">- Select Month -</option>
                            <option value="01">JANUARY</option>
                            <option value="02">FEBRUARY</option>
                            <option value="03">MARCH</option>
                            <option value="04">APRIL</option>
                            <option value="05">MAY</option>
                            <option value="06">JUNE</option>
                            <option value="07">JULY</option>
                            <option value="08">AUGUST</option>
                            <option value="09">SEPTEMBER</option>
                            <option value="10">OCTOBER</option>
                            <option value="11">NOVEMBER</option>
                            <option value="12">DECEMBER</option>
                        </select>
                    </div>
                  </div>
                </div>

              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <table class="table responsive-table">
                  <thead>
                    <tr>
                      <th scope="col" class="pl-2">EMPLOYEE</th>
                      <th scope="col" class="pl-2">STATUS</th>
                      <th scope="col">FORM TYPE</th>
                      <th scope="col">DATE</th>
                      <th scope="col">DAY</th>
                      <th scope="col">IN (AM)</th>
                      <th scope="col">OUT (AM)</th>
                      <th scope="col">IN (PM)</th>
                      <th scope="col">OUT (PM)</th>
                      <th scope="col" class="text-right">OPTIONS</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="ob in officialbusinesses.data" :key="ob.id">
                      <th scope="row" class="pl-2">{{ ob.lname }}, {{ ob.fname }}</th>
                      <td data-title="STATUS" class="pl-2">
                          <span v-if="(ob.obstatus == 'Approved')" class="badge badge-success">{{ ob.obstatus }}</span>
                          <span v-else-if="(ob.obstatus == 'Pending')" class="badge badge-warning">{{ ob.obstatus }}</span>
                          <span v-else-if="(ob.obstatus == 'Denied')" class="badge badge-danger">{{ ob.obstatus }}</span>
                          <span v-else class="badge badge-secondary">{{ ob.obstatus }}</span>
                      </td>
                      <td data-title="FORM TYPE"><b class="cursor" v-tooltip="{
                                                                content: '<b>OB Form:</b> ' + ob.type + '<br/><b>Description:</b> ' + ob.description,
                                                                placement: 'right',
                                                                classes: ['info'],
                                                                targetClasses: ['it-has-a-tooltip'],
                                                                offset: 10,
                                                                delay: {
                                                                  show: 500,
                                                                  hide: 300,
                                                                },
                                                              }"
                      >{{ ob.type }}</b></td>
                      <td data-title="DATE">{{ ob.year }}-{{ ob.month }}-{{ ob.day }}</td>
                      <td data-title="DAY">{{ ob.year + ob.month + ob.day | getday }}</td>
                      <td data-title="IN (AM)">{{ ob.in_am | timeformat }}</td>
                      <td data-title="IN (PM)">{{ ob.out_am | timeformat }}</td>
                      <td data-title="IN (PM)">{{ ob.in_pm | timeformat }}</td>
                      <td data-title="OUT (PM)">{{ ob.out_pm | timeformat }}</td>
                      <td v-if="ob.obstatus === 'Approved'">
                          <button v-show="can_approve" type="button" class="btn btn-sm btn-secondary" disabled>Approve</button>
                          <button v-show="can_deny" type="button" class="btn btn-sm btn-secondary" disabled>Deny</button>
                      </td>
                      <td v-if="ob.obstatus === 'Denied'">
                          <button v-show="can_approve" type="button" class="btn btn-sm btn-secondary" disabled>Approve</button>
                          <button v-show="can_deny" type="button" class="btn btn-sm btn-secondary" disabled>Deny</button>
                      </td>
                      <td v-if="ob.obstatus === 'Cancelled'">
                          <button v-show="can_approve" type="button" class="btn btn-sm btn-secondary" disabled>Approve</button>
                          <button v-show="can_deny" type="button" class="btn btn-sm btn-secondary" disabled>Deny</button>
                      </td>
                      <td v-if="ob.obstatus === 'Pending'">
                          <button v-show="can_approve" type="button" class="btn btn-sm btn-success" @click="approveOb(ob.id, ob.fname, ob.lname, ob.biometric_id, ob.year, ob.month, ob.day, ob.in_am, ob.out_am, ob.in_pm, ob.out_pm, $event)">Approve</button>
                          <button v-show="can_deny" type="button" class="btn btn-sm btn-danger" @click="denyOb(ob.id, ob.fname, ob.lname, ob.year, ob.month, ob.day, ob.in_am, ob.out_am, ob.in_pm, ob.out_pm, $event)">Deny</button>
                      </td>

                    </tr>
                  </tbody>
                  <tbody v-show="officialbusinesses.total==0">
                      <tr><td colspan="10">No records found.</td></tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                <pagination :data="officialbusinesses" @pagination-change-page="getResults" show-disabled :limit="10"></pagination>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>



      </div>

</template>

<script>

    import Form from 'vform';
    import moment from 'moment';

    export default {
        data() {
          return {
            timer: 30,
            editmode: false,
            emptyflag: false,
            month: '',
            year: '',
            can_access: false,
            can_approve: false,
            can_deny: false,
            officialbusinesses : {},
            form: new Form({
              in_am: '',
              out_am: '',
              in_pm: '',
              out_pm: '',
            })
          }
        },

        computed: {
          // TODO  test
          second() {
            return this.time / 1000;
          },

          isIdle() {
            const idlestat =  this.$store.state.idleVue.isIdle;
            if (idlestat == true) {
              $('#sessionmodal').modal('show');
              this.countDownTimer();
            } else {
              $('#sessionmodal').modal('hide');
            }

            return idlestat;

          },

          years() {
            const year = new Date().getFullYear()
            return Array.from({length: year - 2018}, (value, index) => 2019 + index)
          },

          getDateToday: function(){
            const today = new Date();
            const curr_year = today.getFullYear();
            const curr_month = moment(String(today.getMonth() + 1)).format('MM');
            const curr_day = moment(String(today)).format('DD');
            //console.log(curr_year + curr_month + curr_day);
            //console.log(today);
            return curr_year + curr_month + curr_day;
          },

        },

        methods: {

          countDownTimer() {
            let timerId = setInterval(() => {
              this.timer -= 1;
              if (!this.$store.state.idleVue.isIdle) clearInterval(timerId);

              if (this.timer < 1) {
                clearInterval(timerId);
                document.getElementById('logout-form').submit();
              }
            }, 1000);

            this.timer = 30;
          },


          getResults(page = 1) {
            const today = new Date();

            const curr_year = today.getFullYear();
            const curr_month = moment(String(today.getMonth() + 1)).format('MM');

            let empid = "";
            let yr = "";
            let mo = "";

            if(this.year == '' || this.month == '') {
              yr = curr_year;
              mo = curr_month;
              this.year = curr_year;
              this.month = curr_month;
            } else {
              yr = this.year;
              mo = this.month;
            }

            this.$Progress.start();
            axios.get("api/officialbusinesses/" + yr + "/" + mo + '?page=' + page).then(({ data }) => (this.officialbusinesses = data));
            this.$Progress.finish();
          },

          loadResults() {
            const today = new Date();

            const curr_year = today.getFullYear();
            const curr_month = moment(String(today.getMonth() + 1)).format('MM');

            let empid = "";
            let yr = "";
            let mo = "";

            if(this.year == '' || this.month == '') {
              yr = curr_year;
              mo = curr_month;
              this.year = curr_year;
              this.month = curr_month;
            } else {
              yr = this.year;
              mo = this.month;
            }

            this.$Progress.start();
            let page = this.officialbusinesses.current_page;
            axios.get("api/officialbusinesses/" + yr + "/" + mo + '?page=' + page).then(({ data }) => (this.officialbusinesses = data));
            this.$Progress.finish();
          },

          approveOb(oid, ofname, olname, obiometric_id, oyear, omonth, oday, oinam, ooutam, oinpm, ooutpm) {

            this.form.in_am = oinam;
            this.form.out_am = ooutam;
            this.form.in_pm = oinpm;
            this.form.out_pm = ooutpm;

            swal.fire({

              title: 'Are you sure to approve ' + ofname + ' ' + olname + '`s OB? <br/><br/>Date : ' + oyear + '-' + omonth + '-' + oday +
                     '<br/><br/>IN AM&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: ' + this.$options.filters.timeformat(oinam) +
                     '<br/>OUT AM&nbsp;&nbsp;: ' + this.$options.filters.timeformat(ooutam) +
                     '<br/>IN PM&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: ' + this.$options.filters.timeformat(oinpm) +
                     '<br/>OUT PM&nbsp;&nbsp;: ' + this.$options.filters.timeformat(ooutpm),
              text: "You won't be able to revert this!",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes, approve this OB!'
              }).then((result)=>{

                if (result.value) {
                  //send delete ajax tru api request to server


                  this.form.put('api/attendances/' + obiometric_id + '/' +  oyear + '/' +  omonth + '/' +  oday)
                  .then(()=>{

                    this.form.put('api/officialbusinesses/' + oid + '/Approved');

                    swal.fire(
                      'Approved!',
                      'OB has been approved.',
                      'success'
                    );

                    //Trigger.$emit('RefreshData');
                    this.loadResults();

                  }).catch(()=>{

                    swal.fire(
                      'Failed!',
                      'There was something wrong',
                      'warning'
                    )

                  });


                }



            })

          },

          denyOb(oid, ofname, olname, oyear, omonth, oday, oinam, ooutam, oinpm, ooutpm) {

            swal.fire({

              title: 'Are you sure to deny ' + ofname + ' ' + olname + '`s OB? <br/><br/>Date : ' + oyear + '-' + omonth + '-' + oday +
                     '<br/><br/>IN AM&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: ' + this.$options.filters.timeformat(oinam) +
                     '<br/>OUT AM&nbsp;&nbsp;: ' + this.$options.filters.timeformat(ooutam) +
                     '<br/>IN PM&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;: ' + this.$options.filters.timeformat(oinpm) +
                     '<br/>OUT PM&nbsp;&nbsp;: ' + this.$options.filters.timeformat(ooutpm),
              text: "You won't be able to revert this!",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes, deny this OB!'
              }).then((result)=>{

                if (result.value) {

                  this.form.put('api/officialbusinesses/' + oid + '/Denied')
                  .then(()=>{

                    swal.fire(
                      'Denied!',
                      'OB has been denied.',
                      'success'
                    );
                    this.loadResults();

                  }).catch(()=>{

                    swal.fire(
                      'Failed!',
                      'There was something wrong',
                      'warning'
                    )
                  });
                }
            })
          },

          async loadPermission(mid) {
              const response = await axios.get("api/permission/" + this.$gate.showMyID() + "/" + mid).then(
              res => {
                if (!res.data || res.data.length == 0) {
                  return false;
                } else {
                  return true;
                }
              })

              const getResponse = async () => {
                const a = await response;

                switch (mid) {
                case 7:
                  this.can_access = a;
                  if (!this.can_access) {
                    this.$router.push({ name: 'not-found' }) //redirect to not found
                  }
                  break;
                case 8:
                  this.can_approve = a;
                  break;
                case 9:
                  this.can_deny = a;
                }
              };
              return getResponse()
          },


        },

        created() {


          this.loadResults();
          this.loadPermission(7); //Can access OB requests
          this.loadPermission(8); //Can approve OB
          this.loadPermission(9); //Can deny OB

          Trigger.$on('RefreshData',() => {
            this.loadResults();
          });
        },

    }
</script>

<style lang="scss">

.cursor {
  cursor: pointer;
}

.tooltip {
  display: block !important;
  z-index: 990000;
  //width:100%;

  .tooltip-inner {
    color: rgba(#004499, .9);
    border-radius: 3px;
    padding: 5px 5px 5px;
    text-align: left;
    /*width:500px;*/
  }

  .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 0px;
    /*border-color: black;*/
    z-index: 1;
  }

  &[x-placement^="top"] {
    margin-bottom: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 0 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      bottom: 0px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="bottom"] {
    margin-top: 5px;

    .tooltip-arrow {
      border-width: 0 5px 5px 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      top: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="right"] {
    margin-left: 0px;

    .tooltip-arrow {
      border-width: 5px 5px 5px 0;
      border-left-color: transparent !important;
      border-top-color: transparent !important;
      border-bottom-color: transparent !important;
      left: 5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[x-placement^="left"] {
    margin-right: 5px;

    .tooltip-arrow {
      border-width: 5px 0 5px 5px;
      border-top-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      right: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[aria-hidden='true'] {
    visibility: hidden;
    opacity: 0;
    transition: opacity .15s, visibility .15s;
  }

  &[aria-hidden='false'] {
    visibility: visible;
    opacity: 1;
    transition: opacity .15s;
    .tooltip-inner {
      padding: 5px 5px 5px;
      //color: rgba(#004499, .9);
      color: #FFF;
      box-shadow: 0 5px 30px rgba(black, .1);
      background: #000;
      text-align: left;
      //width: max-content !important;
      //width:100%;
    }
    .popover-inner{
        width: max-content !important;
    }
    .tooltip-arrow {
      background: transparent;
      border-color: #000;
      left: 10px;
      bottom: -5px;
    }
    .mention-item:hover {
      background: #FFF !important;
    }
  }
}

</style>
