<template>
  <div class="col-md-4">
    <!-- USERS LIST -->
    <div class="card">
      <div class="card-header">
        <h3 class="card-title">Employees by gender</h3>

        <div class="card-tools">
          <!--<span class="badge badge-warning">Employees</span>
          <button type="button" class="btn btn-tool" data-card-widget="collapse">
            <i class="fas fa-minus"></i>
          </button>-->
          <!--
          <button type="button" class="btn btn-tool" data-card-widget="remove">
            <i class="fas fa-times"></i>
          </button>
          -->
        </div>
      </div>
      <!-- /.card-header -->
      <div class="card-body p-0 mb-4">
        <bar-chart v-if="loaded" :chartdata="chartdata" :options="options"></bar-chart>
      </div>
      <!-- /.card-body -->
      <!--<div class="card-footer text-center">
        <a href="javascript:">View All Users</a>
      </div>-->
      <!-- /.card-footer -->
    </div>
    <!--/.card -->
  </div>
</template>

<script>
import BarChart from './BarChart'
import axios from 'axios'

export default {
  name: 'UserGenderChart',
  components: {
    BarChart
  },

  data () {
      return {
        chartdata: null,
        options: null,
        loaded: false,
        data: {},
      }
  },

  async mounted () {
      this.loaded = false;
      await axios.get("api/dashboardhr").then(res => {
        //console.log(res);
        this.data = res.data,
        this.loaded = true;
        this.chartdata = {
          labels: ["Female", "Male"],
          datasets: [
            {
              // label: "Bar Chart",
              data: [res.data.userFemale, res.data.userMale],
              /*
              backgroundColor: [
                "rgba(255, 99, 132, 1)",
                "rgba(54, 162, 235, 1)",
                "rgb(239, 185, 63)",
                "rgb(12, 122, 51)",
                "rgb(242, 29, 29)"
              ],
              borderColor: [
                "rgba(255, 99, 132, 0.2)",
                "rgba(54, 162, 235, 0.2)",
                "rgb(239, 185, 63)",
                "rgb(12, 122, 51)",
                "rgb(242, 29, 29)"
              ],
              */
              backgroundColor: [
              'rgba(255, 99, 132, 0.2)',
              'rgba(54, 162, 235, 0.2)'
              ],
              borderColor: [
                'rgba(255,99,132,1)',
                'rgba(54, 162, 235, 1)'
              ],
              borderWidth: 1
            }
          ]
        };
        this.options = {
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true
              },
              gridLines: {
                display: true
              }
            }],
            xAxes: [{
              gridLines: {
                display: false
              }
            }]
          },
          legend: {
            display: false
          },
          responsive: true,
          maintainAspectRatio: false
        };
        
      })
      .catch(err => {
        console.log(err);
      });

    }

}
</script>