<template>
    <div class="container-fluid">
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
                <div class="input-group">
                    <div class="input-group-prepend">
                    <span class="input-group-text bg-info">PROGRAMS</span>
                    </div>
                    <select @change="filterActualAmounts" v-model="program_id" name="program_id" class="form-control" autocomplete="off" placeholder="SELECT PROGRAM">
                      <option selected value="">All Programs</option>
                      <option v-for="program in programs" :value="program.id" :key="program.id">{{ program.program_name }}</option>
                    </select>
                </div>
            </div>
          </div>
            
          <div class="col-md-4">
            <div class="form-group">
                <div class="input-group">
                    <div class="input-group-prepend">
                    <span class="input-group-text bg-info">MONTH</span>
                    </div>
                    <select @change="filterActualAmounts" v-model="month" name="month" class="form-control" autocomplete="off" placeholder="SELECT MONTH">
                        <option selected value="">All Months</option>
                        <option value="1">January</option>
                        <option value="2">February</option>
                        <option value="3">March</option>
                        <option value="4">April</option>
                        <option value="5">May</option>
                        <option value="6">June</option>
                        <option value="7">July</option>
                        <option value="8">August</option>
                        <option value="9">September</option>
                        <option value="10">October</option>
                        <option value="11">November</option>
                        <option value="12">December</option>
                    </select>
                </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group">
                <div class="input-group">
                    <div class="input-group-prepend">
                    <span class="input-group-text bg-info">YEAR</span>
                    </div>
                    <select @change="filterActualAmounts" v-model="year" name="year" class="form-control" placeholder="SELECT YEAR">
                      <option selected disabled value="">SELECT YEAR</option>
                      <option v-for="year in years" :value="year" :key="year">{{ year }}</option>
                    </select>
                </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="card card-secondary card-outline">
              <div class="card-header">
                <h4 class="card-title"><i class="fas fa-money-bill-wave"></i> Actual Amounts</h4>
                <div class="card-tools">
                  <div class="form-group clearfix"> 
                    <button @click="clearFilter" data-toggle="tooltip" title="Clear Filter" class="btn btn-primary btn-sm">
                      <i class="fa fa-redo-alt"></i>
                    </button>
                    <button @click="newModal" data-toggle="tooltip" title="Generate Actual Amounts" class="btn btn-outline-secondary btn-sm bg-green">
                      <i class="fa fa-plus"></i>
                    </button>
                  </div>
                </div>
              </div>
              <!-- /.card-header -->

              <div class="card-body table-responsive p-0">
                <table class="table table-hover table-striped">
                  <thead>
                    <tr>
                      <th>Program</th>  
                      <th>Amount of Investment</th>
                      <th>Month</th>
                      <th>Year</th>
                      <th>Options</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="act_amt in actual_amounts.data" :key="act_amt.id">          
                      <td>{{ act_amt.programs.program_name }}</td>
                      <td>
                        <span v-if="act_amt.amount_of_investment == '0.00'" class="badge badge-secondary">
                          <b>{{ act_amt.amount_of_investment }}</b>
                        </span>  
                        <span v-else class="badge badge-warning">
                          <b>{{ act_amt.amount_of_investment }}</b>
                        </span>  
                      </td>
                      <!-- <td>{{ act_amt.remarks }}</td> -->
                      <td>{{ act_amt.month | monthwordformat }}</td>
                      <td>{{ act_amt.year }}</td>
                      <td>
                        <div class="btn-group btn-group-sm">
                          <button class="btn btn-outline-secondary" data-toggle="tooltip" data-container="body" title="Edit" type="button" @click="editModal(act_amt)"><i class="fa fa-edit"></i></button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-show="actual_amounts.total==0">
                      <tr><td colspan="10">No actual amounts found.</td></tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                <pagination :data="actual_amounts" @pagination-change-page="getResults" show-disabled :limit="10"></pagination>
              </div>
            </div>
            <!-- /.card -->
 
          </div>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="actualAmountModal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="actualAmountModal" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 v-show="!editmode" class="modal-title" id="actualAmountModalLabel">Generate Actual Amounts</h5>
                <h5 v-show="editmode" class="modal-title" id="actualAmountModalLabel">Update Data</h5> 
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <form @submit.prevent="editmode ? updateActualAmount() : createActualAmount()" @keydown="form.onKeydown($event)">

              <div class="modal-body">
                <div class="row">
                  
                  <div class="col-md-12">
                    <div class="card card-primary card-outline">
                      <!-- <div class="card-header">
                        <h3 class="card-title">Details</h3>
                      </div> -->
                      <!-- /.card-header -->
                      <div class="card-body">
                        <div v-show="editmode">
                          <strong><i class="fas fa-tasks mr-1"></i> Program Name</strong>
                          <p class="text-muted">{{ program_name }}</p>
                          <hr>

                          <strong><i class="fas fa-calendar-alt mr-1"></i> Month</strong>
                          <p class="text-muted">{{ display_month | monthwordformat }}</p>
                          <hr>

                          <strong><i class="fas fa-calendar mr-1"></i> Year</strong>
                          <p class="text-muted">{{ display_year }}</p>
                          <hr>
                        </div>

                        <div v-show="editmode" class="form-group">
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text bg-info">AMOUNT OF INVESTMENT</span>
                            </div>
                            <input v-model="form.amount_of_investment" v-money="money" type="text" name="amount_of_investment" autocomplete="off" placeholder="AMOUNT OF INVESTMENT" class="form-control" :class="{ 'is-invalid': form.errors.has('amount_of_investment') }">
                            <has-error :form="form" field="amount_of_investment"></has-error>
                          </div>
                        </div>

                        <div v-show="editmode" class="form-group">
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text bg-info">REMARKS</span>
                            </div>
                            <input v-model="form.remarks" type="text" name="remarks" autocomplete="off" placeholder="REMARKS" class="form-control" :class="{ 'is-invalid': form.errors.has('remarks') }">
                            <has-error :form="form" field="remarks"></has-error>
                          </div>
                        </div>

                        <div v-show="!editmode" class="form-group">
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text bg-info">SELECT PROGRAM</span>
                            </div>
                            <selectize placeholder="SELECT PROGRAM" v-model="form.program_id" name="program_id" class="form-control" :class="{ 'is-invalid': form.errors.has('program_id') }">
                              <option v-for="program in programs" :key="program.id" v-bind:value="program.id" >{{ program.program_name }}</option>
                            </selectize>
                            <has-error :form="form" field="program_id"></has-error>
                          </div>
                        </div>

                        <div v-show="!editmode" class="form-group">
                          <div class="input-group">
                            <div class="input-group-prepend">
                              <span class="input-group-text bg-info">YEAR</span>
                            </div>
                            <select v-model="form.year" name="year" class="form-control" placeholder="SELECT YEAR" :class="{ 'is-invalid': form.errors.has('year') }">
                              <option selected disabled value="">SELECT YEAR</option>
                              <option v-for="year in years" :value="year" :key="year">{{ year }}</option>
                            </select>
                            <has-error :form="form" field="year"></has-error>
                          </div>
                        </div>
                        <!-- <hr> -->
                      </div>
                      <!-- /.card-body -->
                    </div>
                  </div>

                  <div v-show="editmode" class="col-md-12">
                    <div class="card card-primary card-outline">
                      <!-- <div class="card-header bg-info">
                        <h4 class="card-title"><i class="fas fa-history"></i> Update Histories</h4>
                      </div> -->

                      <div class="card-body table-responsive p-0">
                        <table class="table table-hover table-striped">
                          <thead>
                            <tr>  
                              <th>Created At</th>
                              <th>Action Taken</th>
                              <th>Remarks</th>
                              <th>Updated By</th>
                            </tr>
                          </thead>
                          <!-- TODO: Create api to get specific data using id from editModal -->
                          <tbody>
                            <tr v-for="act_amt_history in act_amt_histories.data" :key="act_amt_history.id">          
                              <td>{{ act_amt_history.created_at | fulldate }}</td>
                              <td>{{ act_amt_history.action_taken }}</td>
                              <td>{{ act_amt_history.remarks }}</td>
                              <td>{{ act_amt_history.users.fname }} {{ act_amt_history.users.lname }}</td>
                            </tr>
                          </tbody>
                          <tbody v-if="act_amt_histories.total == 0">
                              <tr><td colspan="10">No actual amount histories found.</td></tr>
                          </tbody>
                        </table>
                      </div>
                      <!-- <div class="card-footer">
                        <pagination :data="act_amt_histories" @pagination-change-page="getAmountHistoryResults" show-disabled :limit="10"></pagination>
                      </div> -->
                    </div>
                  </div>

                </div>
              </div>

              <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal"><i class="fa fa-times-circle"></i> CLOSE</button>
                <button v-show="editmode" type="submit" class="btn btn-primary"><i class="fa fa-save"></i> UPDATE</button>
                <button v-show="!editmode" type="submit" class="btn btn-success"><i class="fa fa-save"></i> CREATE</button>
              </div>

              </form>

            </div>
          </div>
        </div>
 
    </div>
</template>

<script>

    import Form from 'vform';

    export default {
        data() {
          return {
            timer: 30,
            editmode: false,
            emptyflag: false,
            program_id: '',
            program_name: '',
            month: '',
            year: '',
            display_month: '',
            display_year: '',
            actual_amounts : {},
            act_amt_histories: {},
            programs : {},
            money: {
              decimal: '.',
              thousands: ',',
              prefix: '',
              suffix: '',
              precision: 2,
              masked: true /* doesn't work with directive */
            },
            form: new Form({
              id: '',
              program_id: '',
              month: '',
              year: '',
              amount_of_investment: '',
              remarks: ''
            })
          }
        },

        computed: {
          
          second() {
            return this.time / 1000;
          },

          isIdle() {
            const idlestat =  this.$store.state.idleVue.isIdle;
            if (idlestat == true) {
              $('#sessionmodal').modal('show');
              this.countDownTimer();
            } else {
              $('#sessionmodal').modal('hide');
            }

            return idlestat;

          },

          years() {
            const year = new Date().getFullYear()
            return Array.from({length: year - 1997}, (value, index) => 1998 + index)
          }

        },

        methods: {

          clearFilter() {
            this.program_id = '';
            this.month = '';
            this.year = '';

            this.loadActualAmounts();
          },

          countDownTimer() {
            let timerId = setInterval(() => {
              this.timer -= 1;
              if (!this.$store.state.idleVue.isIdle) clearInterval(timerId);

              if (this.timer < 1) {
                clearInterval(timerId);
                document.getElementById('logout-form').submit();
              }
            }, 1000);

            this.timer = 30;
          },

          createActualAmount() {
            this.$Progress.start();
            this.form.post('api/actual-amounts')
            .then(() => {
              Trigger.$emit('RefreshData');
              $('#actualAmountModal').modal('hide')
              toastr.fire({
                icon: 'success',
                title: 'Actual Amount created successfully'
              })
              this.$Progress.finish();
            }).catch(() => {
              toastr.fire({
                icon: 'error',
                title: 'Actual Amount creation failed'
              });
              this.$Progress.fail();
            })
          },

          deleteActualAmount(id) {
            swal.fire({

              title: 'Are you sure?',
              text: "You won't be able to revert this!",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes, delete amount!'
              }).then((result) => {

                if (result.value) {
                  //send delete ajax tru api request to server
                  this.form.delete('api/actual-amounts/'+id).then(() => {

                    swal.fire(
                      'Deleted!',
                      'Actual Amount has been deleted.',
                      'success'
                    );
                    Trigger.$emit('RefreshData');

                  }).catch(() => {

                    swal.fire(
                      'Failed!', 
                      'There was something wrong', 
                      'warning'
                    )
                  });
                }
              })
          },

          updateActualAmount() {
            this.$Progress.start();
            this.form.put('api/actual-amounts/'+ this.form.id).then(()=>{
              Trigger.$emit('RefreshData');
              $('#actualAmountModal').modal('hide')
              toastr.fire({
                icon: 'success',
                title: 'Actual Amount information updated successfully'
              })
              this.$Progress.finish();
            }).catch(()=>{
              toastr.fire({
                icon: 'error',
                title: 'Actual Amount information updating failed'
              });
              this.$Progress.fail();
            });
          },

          editModal(act_amt) { 
            this.editmode = true;
            this.form.reset();
            this.form.clear();
            this.form.fill(act_amt);

            this.program_name = act_amt.programs.program_name;
            this.display_month = act_amt.month;
            this.display_year = act_amt.year;

            axios.get('api/get-actual-amount-histories-id/' + act_amt.id).then(({ data }) => (this.act_amt_histories = data));
 
            $('#actualAmountModal').modal('show');
          },

          newModal() {
            this.editmode = false;
            this.form.reset();
            this.form.clear();

            const today = new Date();
          
            const curr_year = today.getFullYear();

            let yr = "";

            if(this.form.year == '') {
              yr = curr_year;
              this.form.year = curr_year;
            } else {
              yr = this.form.year;
            }

            $('#actualAmountModal').modal('show')
          },
 
          getResults(page = 1) {
            let query = this.$parent.search;

            if(query == ''){
              axios.get('api/actual-amounts?page=' + page).then(response => { this.actual_amounts = response.data; }); 
            }else{
              axios.get('api/find-actual-amount?q=' + query + '&page=' + page).then(response => { this.actual_amounts = response.data; }); 
            }
          },
 
          filterActualAmounts() {
            let program_id = this.program_id
            let month = this.month
            let year = this.year

            program_id = (program_id == '') ? 0 : this.program_id
            month = (month == '') ? 0 : parseInt(this.month)
            year = (year == '') ? 0 : year
 
            this.$Progress.start();
 
            axios.get('api/filter-actual-amounts/' + program_id + '/' + month + '/' + year).then(({ data }) => (this.actual_amounts = data));

            this.$Progress.finish();
          },

          loadActualAmounts() {
            this.$Progress.start();
            
            let query = this.$parent.search;
            let page = this.actual_amounts.current_page;

            // fetch data programs for dropdown
            axios.get('api/all-programs').then(({ data }) => (this.programs = data));
            
            if(query == ''){
              axios.get('api/actual-amounts?page=' + page).then(({ data }) => (this.actual_amounts = data));
            }else{
              axios.get('api/find-actual-amount?q=' + query + '&page=' + page).then(({ data }) => (this.actual_amounts = data));
            }

            this.$Progress.finish();
          },
 
        },

        created() {
          Trigger.$on('searching',() => {
            let query = this.$parent.search;
            let emptyflag = false;

            if(query == ''){
              Trigger.$emit('RefreshData');
            }else{
              this.$Progress.start();
              axios.get("api/find-actual-amount?q=" + query)
              .then((data) => {
                this.indicators = data.data;
                
                if (data.data.total == 0){
                  this.emptyflag = true;
                }

                this.$Progress.finish();
              })
              .catch(() => {
                this.$Progress.fail();
              })
            }
            
          });
          
          const today = new Date();
          
          const curr_year = today.getFullYear();

          let yr = "";

          if(this.year == '') {
            yr = curr_year;
            this.year = curr_year;
          } else {
            yr = this.year;
          }

          this.loadActualAmounts();
          
          Trigger.$on('RefreshData',() => {
            this.loadActualAmounts();
          });
        },

    }
</script>
