<template>
    <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card card-secondary card-outline">
              <div class="card-header bg-yellow">
                <h3 class="card-title"><i class="fas fa-file-invoice-dollar"></i> LIST OF DUE AND DEMANDABLE ACCOUNTS PAYABLE - ADVICE TO DEBIT ACCOUNTS</h3>

                <div class="card-tools">
                  <div>
                    <button v-show="can_add" data-toggle="tooltip" data-container="body" title="Add New" class="btn btn-outline-secondary btn-sm" @click="newModal"><i class="fa fa-plus"></i></button>
                  </div>

                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <table class="table responsive-table table-striped">
                  <thead>
                    <tr>
                      <th scope="col" class="pl-2">LDDAP-ADA No</th>
                      <th scope="col">Payment Date</th>
                      <th scope="col">Creditor</th>
                      <th scope="col">Fund Cluster</th>
                      <th scope="col">MDS Sub-account</th>
                      <th scope="col">Check No</th>
                      <th scope="col" class="controls">Options</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="pmt in payments.data" :key="pmt.id">
                      <th scope="row" class="pl-2">{{ pmt.lddapada_no }}</th>
                      <td data-title="Payment Date">{{ pmt.payment_date }}</td>
                      <td data-title="Payment Date">
                        <span v-if="pmt.creditors.length==1">{{ pmt.creditors[0].acct_name }}</span>
                        <span v-else class="cursor text-info font-weight-bold" v-tooltip="{
                            content: '<b>Multiple Creditors</b>',
                            placement: 'top',
                            classes: ['info'],
                            targetClasses: ['it-has-a-tooltip'],
                            offset: 5,
                            delay: {
                              show: 100,
                              hide: 300,
                            },
                          }">{{ pmt.creditors | toFlatString }}
                      </span>
                      </td>
                      <td data-title="Fund Cluster">{{ pmt.fund_cluster }}</td>
                      <td data-title="MDS Sub-account">{{ pmt.mds_subacct }}</td>
                      <td data-title="Check No">{{ pmt.check_no }}</td><!-- {{ //pmt.creditors[0].acct_name }} -->
                      <td data-title="Options">
                        <div>
                          <button @click="viewModal(pmt.id)" type="button" class="btn btn-outline-secondary btn-sm" data-toggle="tooltip" data-container="body" title="View"><i class="fas fa-folder-open"></i> OPEN</button>
                          <button v-show="can_edit" @click="editModal(pmt)" type="button" class="btn btn-outline-secondary btn-sm" data-toggle="tooltip" data-container="body" title="Edit"><i class="fa fa-edit"></i> EDIT</button>
                          <button v-show="can_delete" @click="deletePayment(pmt.id)" type="button" class="btn btn-outline-secondary btn-sm" data-toggle="tooltip" data-container="body" title="Delete"><i class="fa fa-trash-alt"></i> DELETE</button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-show="payments.total==0">
                      <tr><td colspan="10">No payments found.</td></tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                <pagination :data="payments" @pagination-change-page="getResults" show-disabled :limit="10"></pagination>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="paymentmodal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="paymentModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 v-show="!editmode" class="modal-title" id="paymentModalLabel">LDDAP-ADA Data Entry</h5>
                <h5 v-show="editmode" class="modal-title" id="paymentModalLabel">Update LDDAP-ADA Details</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <form @submit.prevent="editmode ? updatePayment() : createPayment()" @keydown="form.onKeydown($event)">

              <div class="modal-body">

                <div class="row">

                  <div class="col-md-4">
                    <!-- Your first column here
                    <div class="form-group">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text bg-default font-weight-bold">CONTROL NO</span>
                        </div>
                        -->
                        <input tabindex="1" v-model="form.control_no" type="hidden" name="control_no" autocomplete="off" placeholder="CONTROL NO.." class="form-control" :class="{ 'is-invalid': form.errors.has('control_no') }">
                        <!--
                        <has-error :form="form" field="control_no"></has-error>
                      </div>
                    </div>
                    -->
                    <div class="form-group">
                      <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text bg-default text-danger font-weight-bold">MDS SUB-ACCOUNT NO</span>
                          </div>
                          <select tabindex="4" v-model="form.mds_subacct" name="type" class="form-control" :class="{ 'is-invalid': form.errors.has('mds_subacct') }">
                            <option value="2182-9000-80">2182-9000-80</option>
                            <option value="2182-9006-40">2182-9006-40</option>
                          </select>
                          <has-error :form="form" field="mds_subacct"></has-error>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <!-- Your second column here -->
                    <div class="form-group">
                      <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text bg-default text-danger font-weight-bold">FUND CLUSTER</span>
                          </div>
                          <select tabindex="2" v-model="form.fund_cluster" @click="setFundCluster()" name="type" class="form-control" :class="{ 'is-invalid': form.errors.has('fund_cluster') }">
                            <option value="01-101101">01-101101</option>
                            <option value="01-101101-99">01-101101-99</option>
                            <option value="07-308601">07-308601</option>
                            <option value="07-308601-99">07-308601-99</option>
                          </select>
                          <has-error :form="form" field="fund_cluster"></has-error>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text bg-default text-danger font-weight-bold">CHECK NO</span>
                        </div>
                        <input tabindex="5" v-model="form.check_no" type="text" name="check_no" :maxlength="10" autocomplete="off" placeholder="CHECK NO.." class="form-control" :class="{ 'is-invalid': form.errors.has('check_no') }" >
                        <has-error :form="form" field="check_no"></has-error>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="form-group">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text bg-default text-danger font-weight-bold">LDDAP-ADA NO</span>
                        </div>
                        <input tabindex="3" v-model="form.lddapada_no" type="text" name="lddapada_no" :maxlength="24" autocomplete="off" placeholder="LDDAP-ADA NO.." class="form-control" :class="{ 'is-invalid': form.errors.has('lddapada_no') }">
                        <has-error :form="form" field="lddapada_no"></has-error>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text bg-default text-danger font-weight-bold">PAYMENT DATE</span>
                        </div>
                        <input tabindex="6" v-model="form.payment_date" type="date" name="payment_date" id="dob-date-input" class="form-control" :class="{ 'is-invalid': form.errors.has('payment_date') }">
                        <has-error :form="form" field="payment_date"></has-error>
                      </div>
                    </div>
                  </div>


                </div>

                <hr/>

                <div class="row">
                  <div class="col-md-12">

                        <button tabindex="-1" type="button"  class="btn btn-success mt-5 mb-5" @click="addNewCreditor()">
                            <i class="fa fa-plus"></i> Add New Creditor ({{ form.creditors.length }})
                        </button>

                        <div class="card mb-3" v-for="(creditor, index) in form.creditors" :key="index">
                            <div class="card-header bg-success">
                              <h5 class="card-title">Creditor {{ index + 1}}</h5>
                              <div class="card-tools">
                                <button v-show="form.creditors.length > 1" type="button" class="btn btn-tool" @click="removeCreditor(index, creditor.id)">
                                  <i class="fas fa-times"></i>
                                </button>
                              </div>
                            </div>
                            <div class="card-body">

                                <div class="form-group">
                                  <div class="input-group">
                                    <div class="input-group-prepend" style="height:32px">
                                      <span class="input-group-text bg-default text-danger font-weight-bold">SELECT ACCOUNT</span>
                                    </div>
                                    <selectize tabindex="7" v-model="creditor.acct" @input="fillAcctDetails(index, creditor.acct)" placeholder="- SELECT ACCOUNT -" :settings="settings" class="form-control" :class="{ 'is-invalid': form.errors.has('acct') }">
                                      <option v-for="bank in banks" :key="bank.id" v-bind:value="bank.account_name + ' [' + bank.account_type + ' No. ' + bank.account_no + ' | ' + bank.bank_name + ' - ' + bank.bank_branch + ']'">{{ bank.account_name }}  [{{ bank.account_type }} No. {{ bank.account_no }} / {{ bank.bank_branch }}]</option>
                                    </selectize>
                                    <has-error :form="form" field="acct"></has-error>
                                  </div>
                                </div>
                                <div class="form-group">
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text font-weight-bold">ACCOUNT NAME</span>
                                    </div>
                                    <input tabindex="-1" v-model="creditor.acct_name" type="text" name="acct_name" autocomplete="off" placeholder="ACCOUNT NAME.." class="form-control" :class="{ 'is-invalid': form.errors.has('acct_name') }" readonly>
                                    <has-error :form="form" field="acct_name"></has-error>
                                  </div>
                                </div>
                                <div class="form-group">
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text font-weight-bold">ACCOUNT DETAILS</span>
                                    </div>
                                    <input tabindex="-1" v-model="creditor.acct_details" type="text" name="acct_details" autocomplete="off" placeholder="ACCOUNT DETAILS.." class="form-control" :class="{ 'is-invalid': form.errors.has('acct_details') }" readonly>
                                    <has-error :form="form" field="acct_details"></has-error>
                                  </div>
                                </div>
                                <div class="form-group">
                                  <div class="input-group">
                                      <div class="input-group-prepend">
                                        <span class="input-group-text bg-default text-danger font-weight-bold">YEAR A/Ps</span>
                                      </div>
                                      <select tabindex="8" v-model="creditor.status" name="type" class="form-control" :class="{ 'is-invalid': form.errors.has('status') }">
                                        <option value="Current">Current Year</option>
                                        <option value="Prior">Prior Year</option>
                                      </select>
                                      <has-error :form="form" field="status"></has-error>
                                  </div>
                                </div>
                                <div class="form-group">
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text bg-default text-danger font-weight-bold">NATURE</span>
                                    </div>
                                    <input tabindex="9" v-model="creditor.nature" type="text" name="nature" autocomplete="off" placeholder="NATURE.." class="form-control" :class="{ 'is-invalid': form.errors.has('nature') }">
                                    <has-error :form="form" field="nature"></has-error>
                                  </div>
                                </div>
                                <div class="form-group">
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text bg-default text-danger font-weight-bold">GROSS AMOUNT</span>
                                    </div>
                                    <input tabindex="10" v-model="creditor.gross" @change="getNet(index)" v-money="money" type="text" name="gross" class="form-control" :class="{ 'is-invalid': form.errors.has('gross') }">
                                    <has-error :form="form" field="gross"></has-error>
                                  </div>
                                </div>
                                <div class="form-group">
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text bg-default text-danger font-weight-bold">W/TAX</span>
                                    </div>
                                    <input tabindex="11" v-model="creditor.tax" @change="getNet(index)" v-money="money" type="text" name="tax" class="form-control" :class="{ 'is-invalid': form.errors.has('tax') }">
                                    <has-error :form="form" field="tax"></has-error>
                                  </div>
                                </div>
                                <div class="form-group">
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text font-weight-bold">NET AMOUNT</span>
                                    </div>
                                    <input tabindex="-1" v-model="creditor.net" type="text" name="net" class="form-control" :class="{ 'is-invalid': form.errors.has('net') }" readonly>
                                    <has-error :form="form" field="net"></has-error>
                                  </div>
                                </div>
                                <div class="form-group">
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text bg-default text-danger font-weight-bold">ALLOTMENT</span>
                                    </div>
                                    <input tabindex="12" v-model="creditor.allotment" type="text" name="allotment" autocomplete="off" placeholder="ALLOTMENT.." class="form-control" :class="{ 'is-invalid': form.errors.has('allotment') }">
                                    <has-error :form="form" field="allotment"></has-error>
                                  </div>
                                </div>
                                <div class="form-group">
                                  <div class="input-group">
                                      <div class="input-group-prepend">
                                        <span class="input-group-text bg-default text-danger font-weight-bold">OBJECT CLASS</span>
                                      </div>
                                      <select tabindex="13" v-model="creditor.class" name="class" class="form-control" :class="{ 'is-invalid': form.errors.has('class') }">
                                        <option value="PS">PS</option>
                                        <option value="MOOE">MOOE</option>
                                        <option value="CO">CO</option>
                                        <option value="FE">FE</option>
                                      </select>
                                      <has-error :form="form" field="class"></has-error>
                                  </div>
                                </div>
                                <div class="form-group">
                                  <div class="input-group">
                                      <div class="input-group-prepend">
                                        <span class="input-group-text bg-default text-danger font-weight-bold">MODE OF PAYMENT</span>
                                      </div>
                                      <select tabindex="14" v-model="creditor.type" name="type" class="form-control" :class="{ 'is-invalid': form.errors.has('type') }">
                                        <option value="MDS Trust">MDS Trust</option>
                                        <option value="MDS Trust - IC">MDS Trust - IC</option>
                                        <option value="General Fund">General Fund</option>
                                        <option value="General Fund - IC">General Fund - IC</option>
                                      </select>
                                      <has-error :form="form" field="type"></has-error>
                                  </div>
                                </div>
                                <div class="form-group">
                                  <div class="input-group">
                                    <div class="input-group-prepend" style="height:32px">
                                      <span class="input-group-text bg-default text-danger font-weight-bold">FUND SOURCE</span>
                                    </div>
                                    <selectize tabindex="15" v-model="creditor.project" placeholder="- SELECT PROJECT -" :settings="settings" class="form-control" :class="{ 'is-invalid': form.errors.has('project') }">
                                      <option v-for="project in projects" :key="project.id" v-bind:value="project.project_name">{{ project.project_name }}</option>
                                    </selectize>
                                    <has-error :form="form" field="project"></has-error>
                                  </div>
                                </div>
                                <div class="form-group">
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text bg-default text-danger font-weight-bold">DATE RECEIVED BY ACCTG</span>
                                    </div>
                                    <input tabindex="16" v-model="creditor.received_acctg" type="date" name="received_acctg" id="dob-date-input" class="form-control" :class="{ 'is-invalid': form.errors.has('received_acctg') }">
                                    <has-error :form="form" field="received_acctg"></has-error>
                                  </div>
                                </div>
                                <div class="form-group">
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text bg-default font-weight-bold">REMARKS</span>
                                    </div>
                                    <input tabindex="17" v-model="creditor.remarks" type="text" name="remarks" autocomplete="off" placeholder="REMARKS.." class="form-control" :class="{ 'is-invalid': form.errors.has('remarks') }">
                                    <has-error :form="form" field="remarks"></has-error>
                                  </div>
                                </div>

                                <button tabindex="-1" v-if="creditor.creditorinfos.length < 4" type="button" class="btn btn-info mt-5 mb-5" @click="addNewCreditorinfo(index)"><i class="fa fa-plus"></i> Add New Creditor Information ({{ creditor.creditorinfos.length }})</button>
                                <button v-else disabled class="btn btn-secondary mt-5 mb-5"><i class="fa fa-plus"></i> Add New Creditor Information ({{ creditor.creditorinfos.length }})</button>
                                <div class="row">
                                  <div class="col-md-3" v-for="(creditorinfo, idx) in creditor.creditorinfos" :key="idx">
                                    <div class="card bg-info">
                                      <div class="card-header">
                                        <h3 class="card-title">Creditor Info {{ idx + 1}}</h3>
                                        <div class="card-tools">
                                          <button tabindex="-1" v-show="creditor.creditorinfos.length > 1" type="button" class="btn btn-tool" @click="removeCreditorinfo(index, idx, creditorinfo.id)"><i class="fas fa-times"></i>
                                          </button>
                                        </div>
                                      </div>
                                      <div class="card-body">
                                        <div class="form-group">
                                          <div class="input-group">
                                            <div class="input-group-prepend">
                                              <span class="input-group-text text-danger bg-default font-weight-bold">ORS</span>
                                            </div>
                                            <input tabindex="18" type="text" class="form-control" placeholder="ORS NUMBER" v-model="creditorinfo.ors_no">
                                          </div>
                                        </div>
                                        <div class="form-group">
                                          <div class="input-group">
                                            <div class="input-group-prepend">
                                              <span class="input-group-text bg-default font-weight-bold">DV #</span>
                                            </div>
                                            <input tabindex="19" type="text" class="form-control" placeholder="DV NUMBER" v-model="creditorinfo.dv_no">
                                          </div>
                                        </div>

                                        <div class="form-group">
                                          <div class="input-group">
                                            <div class="input-group-prepend">
                                              <span class="input-group-text bg-default font-weight-bold">UACS Obj Code</span>
                                            </div>
                                            <input tabindex="20" type="text" class="form-control" placeholder="UACS" v-model="creditorinfo.uacs">
                                          </div>
                                        </div>
                                        <div class="form-group">
                                          <div class="input-group">
                                            <div class="input-group-prepend">
                                              <span class="input-group-text bg-default font-weight-bold">RC Code</span>
                                            </div>
                                            <input tabindex="21" type="text" class="form-control" placeholder="RC Code" v-model="creditorinfo.responsibility_center">
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                            </div>
                        </div>

                  </div>
                  <hr/>
                </div>




                <div class="row">
                  <div class="col-md-4">
                    <div class="form-group">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text bg-deafault text-danger font-weight-bold">HEAD ACCOUNTANT</span>
                        </div>
                        <input tabindex="22" v-model="form.head_accountant" value="test" type="text" :maxlength="40" name="head_accountant" autocomplete="off" placeholder="HEAD ACCOUNTANT.." class="form-control" :class="{ 'is-invalid': form.errors.has('head_accountant') }">
                        <has-error :form="form" field="head_accountant"></has-error>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text bg-deafault text-danger font-weight-bold">DESIGNATION</span>
                        </div>
                        <input tabindex="23" v-model="form.chief_accountant_designation" value="test" type="text" :maxlength="40" name="chief_accountant_designation" autocomplete="off" placeholder="DESIGNATION.." class="form-control" :class="{ 'is-invalid': form.errors.has('chief_accountant_designation') }">
                        <has-error :form="form" field="chief_accountant_designation"></has-error>
                      </div>
                    </div>
                    <br/>
                    <div class="form-group">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text bg-default text-danger font-weight-bold">DIRECTOR/OIC</span>
                        </div>
                        <input tabindex="28" v-model="form.director" type="text" :maxlength="40" name="director" autocomplete="off" placeholder="DIRECTOR.." class="form-control" :class="{ 'is-invalid': form.errors.has('director') }">
                        <has-error :form="form" field="director"></has-error>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text bg-default text-danger font-weight-bold">DESIGNATION</span>
                        </div>
                        <input tabindex="29" v-model="form.director_designation" type="text" :maxlength="40" name="director_designation" autocomplete="off" placeholder="DESIGNATION.." class="form-control" :class="{ 'is-invalid': form.errors.has('director_designation') }">
                        <has-error :form="form" field="director_designation"></has-error>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="form-group">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text bg-default text-danger font-weight-bold">DIVISION CHIEF</span>
                        </div>
                        <input tabindex="24" v-model="form.division_chief" type="text" :maxlength="40" name="division_chief" autocomplete="off" placeholder="DIVISION CHIEF.." class="form-control" :class="{ 'is-invalid': form.errors.has('division_chief') }">
                        <has-error :form="form" field="division_chief"></has-error>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text bg-default text-danger font-weight-bold">DESIGNATION</span>
                        </div>
                        <input tabindex="25" v-model="form.division_chief_designation" type="text" :maxlength="40" name="division_chief_designation" autocomplete="off" placeholder="DESIGNATION.." class="form-control" :class="{ 'is-invalid': form.errors.has('division_chief_designation') }">
                        <has-error :form="form" field="division_chief_designation"></has-error>
                      </div>
                    </div>
                  </div>
                  <br/>
                  <div class="col-md-4">
                    <div class="form-group">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text bg-default text-danger font-weight-bold">HEAD CASHIER</span>
                        </div>
                        <input tabindex="26" v-model="form.head_cashier" type="text" :maxlength="40" name="head_cashier" autocomplete="off" placeholder="HEAD CASHIER.." class="form-control" :class="{ 'is-invalid': form.errors.has('head_cashier') }">
                        <has-error :form="form" field="head_cashier"></has-error>
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text bg-default text-danger font-weight-bold">DESIGNATION</span>
                        </div>
                        <input tabindex="27" v-model="form.head_cashier_designation" type="text" :maxlength="40" name="head_cashier_designation" autocomplete="off" placeholder="DESIGNATION.." class="form-control" :class="{ 'is-invalid': form.errors.has('head_cashier_designation') }">
                        <has-error :form="form" field="head_cashier_designation"></has-error>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div class="modal-footer">
                <span class="pull-left text-danger font-weight-bold">Note: Fill out all red* colored labels to enable save button.</span>
                <button tabindex="-1" type="button" class="btn btn-default" data-dismiss="modal"><i class="fa fa-times-circle"></i> CLOSE</button>
                <button tabindex="30" :disabled="validateFormCreditors" v-show="editmode" type="submit" class="btn btn-default"><i class="fa fa-save"></i> UPDATE</button>
                <button tabindex="31" :disabled="validateFormCreditors" v-show="!editmode" type="submit" class="btn btn-default"><i class="fa fa-save"></i> SAVE</button>
              </div>

            </form>

          </div>
        </div>
      </div>

      <!-- Print Modal -->
      <div class="modal fade" id="printmodal" tabindex="-1" role="dialog" aria-labelledby="printModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
            <div class="modal-content">
              <div class="modal-header bg-orange">
                <h5 class="modal-title" id="printModalLabel">LDDAP-ADA Control No. {{ form.control_no }}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>


              <div class="modal-body">
              <div class="row-aligncenter">

                <div class="card card-primary card-tabs">
                  <div class="card-header p-0 pt-1">
                    <ul class="nav nav-tabs" id="custom-tabs-one-tab" role="tablist">
                      <li class="nav-item">
                        <a class="nav-link active" id="custom-tabs-one-home-tab" data-toggle="pill" href="#custom-tabs-one-home" role="tab" aria-controls="custom-tabs-one-home" aria-selected="true">Appendix 42</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link" id="custom-tabs-one-profile-tab" data-toggle="pill" href="#custom-tabs-one-profile" role="tab" aria-controls="custom-tabs-one-profile" aria-selected="false">Appendix 53</a>
                      </li>
                    </ul>
                  </div>
                  <div class="card-body">
                    <div class="tab-content" id="custom-tabs-one-tabContent">
                      <div class="tab-pane fade active show" id="custom-tabs-one-home" role="tabpanel" aria-labelledby="custom-tabs-one-home-tab">
                        <div id="printPayment">
                          <div class="card-body" style="font-family: Times New Roman;">

                            <table class="table table-borderless">
                              <tr>
                                <td colspan="8" align="right">
                                    <i>Appendix 42</i><br/><br/>
                                </td>
                              </tr>
                            </table>
                            <br/><br/>
                            <table class="table table-borderless">
                              <tbody>
                                <tr>
                                  <td colspan="2" align="center"><h2>LIST OF DUE AND DEMANDABLE ACCOUNTS PAYABLE - ADVICE TO DEBIT ACCOUNTS (LDDAP-ADA)</h2></td>
                                </tr>
                                <tr>
                                  <td>
                                    <b>
                                      Department : <u>Department of Science and Technology</u><br/>
                                      Entity Name : <u>Technology Application & Promotion Institute</u><br/>
                                      Operating Unit :
                                    </b>
                                  </td>
                                  <td align="right">
                                    <b>
                                    LDDAP-ADA No. <u>{{ payment.lddapada_no }}</u> <br/>
                                    Date : <u>{{ payment.payment_date | dobformat }}</u> <br/>
                                    Fund Cluster : <u>{{ payment.fund_cluster }}</u>
                                    </b>
                                  </td>
                                </tr>
                                <tr>
                                  <td colspan="2" align="center"><b>MDS-GSB BRANCH/MDS SUB ACCOUNT NO.: <u>{{ payment.mds_subacct }}</u></b></td>
                                </tr>
                              </tbody>
                            </table>


                            <table class="table table-borderless" style="border:2px solid #000000">
                              <thead>
                                <tr>
                                  <td style="border:2px solid #000000" colspan="8" align="center"><b>I. LIST OF DUE AND DEMANDABLE ACCOUNTS PAYABLE (LDDAP)</b></td>
                                </tr>
                                <tr>
                                  <td style="border:2px solid #000000" colspan="2" align="center"><b>CREDITOR</b></td>
                                  <td style="border:2px solid #000000" rowspan="2" align="center"><b>Obligation Request<br/>and Status No.</b></td>
                                  <td style="border:2px solid #000000" rowspan="2" align="center"><b>ALLOT<br/>MENT<br/>CLASS<br/>per<br/>(UACS)</b></td>
                                  <td style="border:2px solid #000000" colspan="3" align="center"><b>In Pesos</b></td>
                                  <td style="border:2px solid #000000" rowspan="2" align="center"><b>REMARKS</b></td>
                                </tr>
                                <tr>
                                  <td align="center" style="border:2px solid #000000">
                                    <b>NAME</b>
                                  </td>
                                  <td align="center" style="border:2px solid #000000">
                                    <b>PREFERRED SERVICING<br/>BANKS/SAVINGS/CURRENT<br/>ACCOUNT NO.</b>
                                  </td>
                                  <td align="center" style="border:2px solid #000000">
                                    <b>GROSS AMOUNT</b>
                                  </td>
                                  <td align="center" style="border:2px solid #000000">
                                    <b>W/TAX</b>
                                  </td>
                                  <td align="center" style="border:2px solid #000000">
                                    <b>NET AMOUNT</b>
                                  </td>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td style="border-right:2px solid #000000"><b>I. Current Year A/Ps</b></td>
                                  <td style="border-right:2px solid #000000"></td>
                                  <td style="border-right:2px solid #000000"></td>
                                  <td style="border-right:2px solid #000000"></td>
                                  <td style="border-right:2px solid #000000"></td>
                                  <td style="border-right:2px solid #000000"></td>
                                  <td style="border-right:2px solid #000000"></td>
                                  <td style="border-right:2px solid #000000"></td>
                                </tr>
                              </tbody>
                              <tbody>
                                <tr v-for="creditr in payment.creditors" :key="creditr.id">
                                  <td v-if="creditr.status=='Current'" style="border-right:2px solid #000000">{{ creditr.acct_name }}</td>
                                  <td v-if="creditr.status=='Current'" style="border-right:2px solid #000000"><p :inner-html.prop="creditr.acct_details | convertnewline"></p></td>
                                  <td v-if="creditr.status=='Current'" style="border-right:2px solid #000000">
                                    <ul class="list-unstyled" v-for="cinfo in creditr.creditorinfos" :key="cinfo.id">
                                      <li>{{ cinfo.ors_no }}</li>
                                    </ul>
                                  </td>
                                  <td align="center" v-if="creditr.status=='Current'" style="border-right:2px solid #000000">
                                    <ul class="list-unstyled" >
                                      <li>{{ creditr.allotment }}</li>
                                    </ul>
                                  </td>
                                  <td align="center" v-if="creditr.status=='Current'" style="border-right:2px solid #000000">{{ creditr.gross }}</td>
                                  <td align="center" v-if="creditr.status=='Current'" style="border-right:2px solid #000000">{{ creditr.tax }}</td>
                                  <td align="center" v-if="creditr.status=='Current'" style="border-right:2px solid #000000">{{ creditr.net }}</td>
                                  <td align="center" v-if="creditr.status=='Current'" style="border-right:2px solid #000000">{{ creditr.remarks }}</td>
                                </tr>
                              </tbody>

                              <tbody>
                                <tr>
                                  <td style="border-right:2px solid #000000">Sub-total</td>
                                  <td style="border-right:2px solid #000000"></td>
                                  <td style="border-right:2px solid #000000"></td>
                                  <td style="border-right:2px solid #000000"></td>
                                  <td align="center" style="border-bottom: 3px double #000000;border-top:4px solid #000000;border-left:2px solid #000000;">{{ compute_total_gross_current(payment.creditors) | toMoney }}</td>
                                  <td align="center" style="border-bottom: 3px double #000000;border-top:4px solid #000000;border-left:2px solid #000000;">{{ compute_total_tax_current(payment.creditors) | toMoney }}</td>
                                  <td align="center" style="border-bottom: 3px double #000000;border-top:4px solid #000000;border-left:2px solid #000000;">{{ compute_total_net_current(payment.creditors) | toMoney }}</td>
                                  <td style="border:2px solid #000000"></td>
                                </tr>
                                <tr>
                                  <td style="border-right:2px solid #000000"><b>II. Prior Year A/Ps</b></td>
                                  <td style="border-right:2px solid #000000"></td>
                                  <td style="border-right:2px solid #000000"></td>
                                  <td style="border-right:2px solid #000000"></td>
                                  <td style="border-right:2px solid #000000"></td>
                                  <td style="border-right:2px solid #000000"></td>
                                  <td style="border-right:2px solid #000000"></td>
                                  <td style="border-right:2px solid #000000"></td>
                                </tr>
                              </tbody>
                              <tbody>
                                <tr v-for="creditr in payment.creditors" :key="creditr.id">
                                  <td v-if="creditr.status=='Prior'" style="border-right:2px solid #000000">{{ creditr.acct_name }}</td>
                                  <td v-if="creditr.status=='Prior'" style="border-right:2px solid #000000">{{ creditr.acct_details }}</td>
                                  <td v-if="creditr.status=='Prior'" style="border-right:2px solid #000000">
                                    <ul class="list-unstyled" v-for="cinfo in creditr.creditorinfos" :key="cinfo.id">
                                      <li>{{ cinfo.ors_no }}</li>
                                    </ul>
                                  </td>
                                  <td align="center" v-if="creditr.status=='Prior'" style="border-right:2px solid #000000">
                                    <ul class="list-unstyled" v-for="cinfo in creditr.creditorinfos" :key="cinfo.id">
                                      <li>{{ cinfo.uacs }}</li>
                                    </ul>
                                  </td>
                                  <td align="center" v-if="creditr.status=='Prior'" style="border-right:2px solid #000000">{{ creditr.gross }}</td>
                                  <td align="center" v-if="creditr.status=='Prior'" style="border-right:2px solid #000000">{{ creditr.tax }}</td>
                                  <td align="center" v-if="creditr.status=='Prior'" style="border-right:2px solid #000000">{{ creditr.net }}</td>
                                  <td align="center" v-if="creditr.status=='Prior'" style="border-right:2px solid #000000">{{ creditr.remarks }}</td>
                                </tr>
                              </tbody>
                              <tbody>
                                <tr>
                                  <td style="border-right:2px solid #000000">Sub-total</td>
                                  <td style="border-right:2px solid #000000"></td>
                                  <td style="border-right:2px solid #000000"></td>
                                  <td style="border-right:2px solid #000000"></td>
                                  <td align="center" style="border-top:4px solid #000000;border-bottom:2px solid #000000;border-left:2px solid #000000;">{{ compute_total_gross_prior(payment.creditors) | toMoney }}</td>
                                  <td align="center" style="border-top:4px solid #000000;border-bottom:2px solid #000000;border-left:2px solid #000000;">{{ compute_total_tax_prior(payment.creditors) | toMoney }}</td>
                                  <td align="center" style="border-top:4px solid #000000;border-bottom:2px solid #000000;border-left:2px solid #000000;">{{ compute_total_net_prior(payment.creditors) | toMoney }}</td>
                                  <td style="border:2px solid #000000"></td>
                                </tr>
                                <tr style="border:2px solid #000000">
                                  <td style="border:2px solid #000000"><b>TOTAL</b></td>
                                  <td style="border:2px solid #000000"></td>
                                  <td style="border:2px solid #000000"></td>
                                  <td style="border:2px solid #000000">{{  }}</td>
                                  <td align="center" style="border-top: 3px double #000000;">{{ compute_total_gross(payment.creditors) | toMoney }}</td>
                                  <td align="center" style="border-top: 3px double #000000;">{{ compute_total_tax(payment.creditors) | toMoney }}</td>
                                  <td align="center" style="border-top: 3px double #000000;"><b style="font-size: 15px;">{{ compute_total_net(payment.creditors) | toMoney }}</b></td>
                                  <td style="border:2px solid #000000"></td>
                                </tr>
                              </tbody>
                            </table>
                            <table class="table table-borderless" style="border:2px solid #000000">
                              <tr>
                                <td colspan="8" align="center"></td>
                              </tr>
                              <tr>
                                <td colspan="4" align="left">
                                  <div style="width:370px;">
                                  &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;I hereby warrant that the above List of Due and Demandable A/Ps was
                                  prepared in accordance with existing budgeting, accounting and auditing rules and
                                  regualtions.
                                  </div>
                                </td>
                                <td colspan="4" align="left">
                                  <div style="width:370px;">
                                  &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;I hereby assume full responsibility for the veracity and accuracy of the
                                  listed claims, and the authenticity of the supporting documents as submitted
                                  by the claimants.
                                  </div>
                                </td>
                              </tr>
                              <tr>
                                <td colspan="4" align="left">Certified Correct:</td>
                                <td colspan="4" align="left">Approved:</td>
                              </tr>
                              <tr>
                                <td colspan="4" align="center">
                                  <u>&nbsp;<b>{{ this.payment.head_accountant }}</b>&nbsp;</u><br/>
                                  {{ this.payment.chief_accountant_designation }}<br/>
                                  (Signature over Printed Name)<br/>
                                  Head of Accounting<br/>
                                  Division/Unit
                                </td>
                                <td colspan="4" align="center">
                                  <u>&nbsp;<b>{{ this.payment.division_chief }}</b>&nbsp;</u><br/>
                                  {{ this.payment.division_chief_designation }}<br/>
                                  (Signature over Printed Name)<br/>
                                  Head of Agency or Authorized Official
                                </td>
                              </tr>
                              <tr>
                                <td style="border:2px solid #000000" colspan="8" align="center"><b>II. ADVICE TO DEBIT ACCOUNT (ADA)</b></td>
                              </tr>
                              <tr>
                                <td colspan="4" align="left">
                                  <b>To: MDS-GSB of the Agency</b><br/>
                                  Please debit MDS Sub-Account Number: <b>{{ this.payment.mds_subacct }}</b><br/>
                                  Please credit the accounts of the above listed creditors to cover payment of accounts payable<br/><br/>
                                </td>
                                <td colspan="4" align="right" style="vertical-align: top !important;">
                                  <span class="font-weight-bold" v-html="getAccountNumberOnly(this.payment.creditors)"></span>
                                </td>
                              </tr>
                              <tr>
                                <td colspan="1" align="left"><div style="width: 120px;"><b data-v-04c873d0="">TOTAL AMOUNT:</b></div></td>
                                <td colspan="3" align="center"><b><u style="font-size: 15px;">{{ compute_total_net(payment.creditors) | toWordsPesos | allcaps }} {{ compute_total_net(payment.creditors) | toWordsCentavos | allcaps }}</u></b><br/>(In Words)</td>
                                <td colspan="4" align="right"><b style="font-size: 16px;margin-right:30px;">&#8369; {{ compute_total_net(payment.creditors) | toMoney }}</b></td>
                              </tr>
                              <tr>
                                <td colspan="8" align="center"><br/><br/><b>Agency Authorized Signatories</b><br/><br/></td>
                              </tr>
                              <tr>
                                <td colspan="4" align="center">1. <b><u>{{ this.payment.head_cashier }}</u></b><br/>{{ this.payment.head_cashier_designation }}</td>
                                <td colspan="4" align="center">2. <b><u>{{ this.payment.director }}</u></b><br/>{{ this.payment.director_designation }}</td>
                              </tr>
                            </table>

                            <table class="table table-borderless">
                              <tr>
                                <td colspan="8" align="right">
                                  Check Number: <b><u>{{ this.payment.check_no }}</u></b><br/>
                                  Date: <b><u>{{ this.payment.payment_date | dobformat}}</u></b>
                                </td>
                              </tr>
                            </table>


                          </div>

                        </div>
                        <div class="modal-footer">
                          <button type="button" class="btn btn-default" data-dismiss="modal"><i class="fa fa-times-circle"></i> CLOSE</button>
                          <button v-print="'#printPayment'" type="submit" class="btn btn-default"><i class="fa fa-print"></i> PRINT</button>
                        </div>
                      </div>
                      <div class="tab-pane fade" id="custom-tabs-one-profile" role="tabpanel" aria-labelledby="custom-tabs-one-profile-tab">
                        <div id="printAppendix53">
                          <div class="card-body" style="font-family: Times New Roman;">

                            <table class="table table-borderless">
                              <tr>
                                <td colspan="8" align="right">
                                    <i>Appendix 53</i><br/><br/>
                                </td>
                              </tr>
                            </table>
                            <br/><br/>
                            <table class="table table-borderless">
                              <tbody>
                                <tr>
                                  <td colspan="2" align="center"><h2>SUMMARY OF LDDAP-ADAs ISSUED AND INVALIDATED ADA ENTRIES</h2></td>
                                </tr>
                                <tr>
                                  <td>
                                    <b>
                                      Department : <u>Department of Science and Technology</u><br/>
                                      Entity Name : <u>Technology Application & Promotion Institute</u><br/>
                                      Operating Unit :
                                    </b>
                                  </td>
                                  <td align="right">
                                    <b>
                                    Fund Cluster : <u>{{ payment.fund_cluster }}</u><br/>
                                    SLIIE No.: <u>{{ payment.lddapada_no }}</u> <br/>
                                    Date : <u>{{ payment.payment_date | dobformat }}</u>
                                    </b>
                                  </td>
                                </tr>
                                <tr>
                                  <td colspan="2" align="left">
                                    <b>
                                      To: The Bank Manager<br/>
                                      &nbsp;&nbsp;&nbsp;&nbsp;(Bank Branch)<br/>
                                      &nbsp;&nbsp;&nbsp;&nbsp;(Address)
                                    </b>
                                  </td>
                                </tr>
                              </tbody>
                            </table>


                            <table class="table table-borderless" style="border:2px solid #000000">
                              <thead>
                                <tr>
                                  <td style="border:2px solid #000000" rowspan="3" align="center"><b>LDDAP-ADA No.</b></td>
                                  <td style="border:2px solid #000000" rowspan="3" align="center"><b>Date of Issue</b></td>
                                  <td style="border:2px solid #000000" colspan="5" align="center"><b>Amount</b></td>
                                  <td style="border:2px solid #000000" rowspan="1" colspan="2" align="center"><b>For GSB Use Only</b></td>
                                </tr>
                                <tr>
                                  <td align="center" style="border:2px solid #000000" rowspan="2">Total</td>
                                  <td align="center" style="border:2px solid #000000" rowspan="1" colspan="4">Allotment/Object Class</td>
                                  <td align="center" style="border:2px solid #000000" rowspan="2" colspan="2">Remarks</td>
                                </tr>
                                <tr>
                                  <td align="center" style="border:2px solid #000000" rowspan="1">PS</td>
                                  <td align="center" style="border:2px solid #000000" rowspan="1">MOOE</td>
                                  <td align="center" style="border:2px solid #000000" rowspan="1">CO</td>
                                  <td align="center" style="border:2px solid #000000" rowspan="1">FE</td>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td style="border-right:2px solid #000000">{{ payment.lddapada_no }}</td>
                                  <td style="border-right:2px solid #000000">{{ payment.payment_date | customdate }}</td>
                                  <td align="center" style="border-right:2px solid #000000">{{ compute_total_net(payment.creditors) | toMoney }}</td>
                                  <td align="center" style="border-right:2px solid #000000">{{ compute_total_ps(payment.creditors) | toMoney }}</td>
                                  <td align="center" style="border-right:2px solid #000000">{{ compute_total_mooe(payment.creditors) | toMoney }}</td>
                                  <td align="center" style="border-right:2px solid #000000">{{ compute_total_co(payment.creditors) | toMoney }}</td>
                                  <td align="center" style="border-right:2px solid #000000">{{ compute_total_fe(payment.creditors) | toMoney }}</td>
                                  <td style="border-right:2px solid #000000" colspan="2"></td>
                                </tr>
                                <tr>
                                  <td style="border-right:2px solid #000000"></td>
                                  <td style="border-right:2px solid #000000"></td>
                                  <td style="border-right:2px solid #000000"></td>
                                  <td style="border-right:2px solid #000000"></td>
                                  <td style="border-right:2px solid #000000"></td>
                                  <td style="border-right:2px solid #000000"></td>
                                  <td style="border-right:2px solid #000000"></td>
                                  <td style="border-right:2px solid #000000" colspan="2"></td>
                                </tr>
                                <tr>
                                  <td style="border-right:2px solid #000000"></td>
                                  <td style="border-right:2px solid #000000"></td>
                                  <td style="border-right:2px solid #000000"></td>
                                  <td style="border-right:2px solid #000000"></td>
                                  <td style="border-right:2px solid #000000"></td>
                                  <td style="border-right:2px solid #000000"></td>
                                  <td style="border-right:2px solid #000000"></td>
                                  <td style="border-right:2px solid #000000" colspan="2"></td>
                                </tr>
                              </tbody>
                              <tbody>
                                <tr>
                                  <td style="border-right:2px solid #000000"></td>
                                  <td style="border-right:2px solid #000000"></td>
                                  <td align="center" style="border:2px solid #000000">{{ compute_total_net(payment.creditors) | toMoney }}</td>
                                  <td style="border-right:2px solid #000000"></td>
                                  <td style="border-right:2px solid #000000"></td>
                                  <td style="border-right:2px solid #000000"></td>
                                  <td style="border-right:2px solid #000000"></td>
                                  <td style="border-right:2px solid #000000" colspan="2"></td>
                                </tr>
                                <tr style="border:2px solid #000000">
                                  <td style="border:2px solid #000000" rowspan="2">No. of pcs of LDDAP-ADA: <u><b><input value="1" style="border: 0;
                                            width: 40px;
                                            outline: 0;
                                            background: transparent;
                                            border-bottom: 1px solid black;"
                                    type="number" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" name="sheet_no" maxlength="2" autocomplete="off" /></b></u></td>
                                  <td style="border:2px solid #000000" rowspan="1">Total Amount:</td>
                                  <td style="border:2px solid #000000" rowspan="1" colspan="7"><b style="font-size: 15px;">{{ compute_total_net(payment.creditors) | toMoney }}</b></td>
                                </tr>
                                <tr style="border:2px solid #000000">
                                  <td style="border:2px solid #000000" rowspan="1">Amount in words:</td>
                                  <td style="border:2px solid #000000" rowspan="1" colspan="7"><b style="font-size: 15px;">{{ compute_total_net(payment.creditors) | toWordsPesos | allcaps }} {{ compute_total_net(payment.creditors) | toWordsCentavos | allcaps }}</b></td>
                                </tr>
                                <tr>
                                  <td style="border-bottom:2px solid #000000;background-color:#000000;" colspan="9" align="center"></td>
                                </tr>
                                <tr>
                                  <td style="border:2px solid #000000" rowspan="3" align="center"><b>LDDAP-ADA No.</b></td>
                                  <td style="border:2px solid #000000" rowspan="3" align="center"><b>Amount</b></td>
                                  <td style="border:2px solid #000000" rowspan="3" align="center"><b>Date Issued</b></td>
                                  <td style="border:2px solid #000000" rowspan="1" colspan="6" align="center">OF WHICH INVALIDATED ENTRIES OF PREVIOUSLY ISSUED LDDAP-ADAs</td>
                                </tr>
                                <tr>
                                  <td style="border:2px solid #000000" rowspan="1" colspan="6" align="center"><b>Allotment/Object Class</b></td>
                                </tr>
                                <tr>
                                  <td style="border:2px solid #000000" rowspan="1" align="center">PS</td>
                                  <td style="border:2px solid #000000" rowspan="1" align="center">MOOE</td>
                                  <td style="border:2px solid #000000" rowspan="1" align="center">CO</td>
                                  <td style="border:2px solid #000000" rowspan="1" align="center">FE</td>
                                  <td style="border:2px solid #000000" rowspan="1" align="center">TOTAL</td>
                                  <td style="border:2px solid #000000" rowspan="1" align="center">Remarks</td>
                                </tr>
                                <tr>
                                  <td style="border:2px solid #000000" align="center"></td>
                                  <td style="border:2px solid #000000" align="center"></td>
                                  <td style="border:2px solid #000000" align="center"></td>
                                  <td style="border:2px solid #000000" align="center"></td>
                                  <td style="border:2px solid #000000" align="center"></td>
                                  <td style="border:2px solid #000000" align="center"></td>
                                  <td style="border:2px solid #000000" align="center"></td>
                                  <td style="border:2px solid #000000" align="center"></td>
                                  <td style="border:2px solid #000000" align="center"></td>
                                </tr>
                              </tbody>
                            </table>
                            <table class="table table-borderless" style="border:2px solid #000000">
                              <tr>
                                <td colspan="9" align="center"></td>
                              </tr>

                              <tr>
                                <td colspan="5" align="left">Certified Correct by:</td>
                                <td colspan="4" align="left">Approved by:</td>
                              </tr>
                              <tr>
                                <td colspan="5" align="center">
                                  <u>&nbsp;<b>{{ this.payment.head_accountant }}</b>&nbsp;</u><br/>
                                  {{ this.payment.chief_accountant_designation }}<br/>
                                  Head of Cash/Treasury Unit/Authorized Official
                                </td>
                                <td colspan="4" align="center">
                                  <u>&nbsp;<b>{{ this.payment.director }}</b>&nbsp;</u><br/>
                                  {{ this.payment.director_designation }}<br/>
                                  Head of Agency or Authorized Official
                                </td>
                              </tr>
                              <tr>
                                <td style="border-bottom:2px solid #000000" colspan="9" align="center"></td>
                              </tr>
                              <tr>
                                <td colspan="5" align="left">
                                  TRANSMITTAL INFORMATION<br/>
                                  &nbsp;Delivered by:<br/><br/>
                                </td>
                                <td colspan="4" align="center">
                                  <span>Received by:</span><br/>
                                </td>
                              </tr>
                              <tr>
                                <td colspan="5" align="center">
                                  <u><b>{{ this.payment.head_cashier }}</b></u><br/>
                                  {{ this.payment.head_cashier_designation }}<br/>
                                </td>
                                <td colspan="4" align="center">
                                  <span>&nbsp;</span><br/>
                                </td>
                              </tr>
                            </table>

                          </div>

                        </div>
                        <div class="modal-footer">
                          <button type="button" class="btn btn-default" data-dismiss="modal"><i class="fa fa-times-circle"></i> CLOSE</button>
                          <button v-print="'#printAppendix53'" type="submit" class="btn btn-default" @click="print"><i class="fa fa-print"></i> PRINT</button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- /.card -->
                </div>



              </div>
              </div>

              <!--
              <div class="modal-footer">
                <button type="button" class="btn btn-default" data-dismiss="modal"><i class="fa fa-times-circle"></i> CLOSE</button>
                <button v-print="'#printPayment'" type="submit" class="btn btn-default"><i class="fa fa-print"></i> PRINT</button>
              </div>
              -->


            </div>
          </div>
        </div>



    </div>

</template>

<script>

    import Form from 'vform';
    import moment from 'moment';
import notfound from './notfound.vue';

    export default {
  components: { notfound },
        data() {
          return {
            timer: 30,
            editmode: false,
            emptyflag: false,
            can_access: false,
            can_add: false,
            can_edit: false,
            can_delete: false,
            payment : {},
            payments : {},
            new_control_no : {},
            settings: {},
            banks: {},
            projects: {},
            money: {
              decimal: '.',
              thousands: ',',
              prefix: '',
              suffix: '',
              precision: 2,
              masked: true /* doesn't work with directive */
            },
            form: new Form({
              id: '',
              control_no: '',
              lddapada_no: '',
              fund_cluster: '',
              mds_subacct: '',
              head_accountant: '',
              division_chief: '',
              head_cashier: '',
              director: '',
              chief_accountant_designation: '',
              division_chief_designation: '',
              head_cashier_designation: '',
              director_designation: '',
              check_no: '',
              payment_date: '',
              creditors: [
                {
                    acct: '',
                    acct_name: '',
                    acct_details: '',
                    status: 'Current',
                    nature: '',
                    gross: 0,
                    tax: 0,
                    net: 0,
                    allotment: '',
                    class: '',
                    type: '',
                    project: '',
                    received_acctg: '',
                    remarks: '',
                    creditorinfos: [
                      {
                      ors_no: '',
                      dv_no: '',
                      responsibility_center: '',
                      }
                    ] // <-
                }
            ],

            })
          }
        },

        computed: {

          second() {
            return this.time / 1000;
          },

          isIdle() {
            const idlestat =  this.$store.state.idleVue.isIdle;
            if (idlestat == true) {
              $('#sessionmodal').modal('show');
              this.countDownTimer();
            } else {
              $('#sessionmodal').modal('hide');
            }

            return idlestat;

          },

          validateFormCreditors() {

            var disabled = false;
            var len = this.form.creditors.length

            for (let i = 0; i < len; i++) {
              if (this.form.creditors[i].acct == '') {
                return true;
              }

              /*else if (this.form.creditors[i].nature == '') {
                return true;
              } else if (this.form.creditors[i].gross == '0.00') {
                return true;
              } else if (this.form.creditors[i].tax == '0.00') {
                return true;
              }*/
            }

            return false;

          },

        },

        methods: {

            print(){
                var style = document.createElement('style');
                style.innerHTML = "@page{size: A4 portrait !important;margin: 25mm !important;}" +
                        " html{background-color: #FFFFFF;}" +
                        " body{border: solid 0px #FFFFFF;margin: 0;}";
                window.document.head.appendChild(style);
            },

          compute_total_ps(class_ps) {
            if (!class_ps) {
              //
            } else {
              return class_ps.reduce((tot, val) => {
                var sum;
                if(val.class == 'PS'){ sum = tot + parseFloat(val.net.replace(/,/g, '')); } else { sum = tot + 0; }
                return sum;
              }, 0);
            }
          },

          compute_total_mooe(class_mooe) {
            if (!class_mooe) {
              //
            } else {
              return class_mooe.reduce((tot, val) => {
                var sum;
                if(val.class == 'MOOE'){ sum = tot + parseFloat(val.net.replace(/,/g, '')); } else { sum = tot + 0; }
                return sum;
              }, 0);
            }
          },

          compute_total_co(class_co) {
            if (!class_co) {
              //
            } else {
              return class_co.reduce((tot, val) => {
                var sum;
                if(val.class == 'CO'){ sum = tot + parseFloat(val.net.replace(/,/g, '')); } else { sum = tot + 0; }
                return sum;
              }, 0);
            }
          },

          compute_total_fe(class_fe) {
            if (!class_fe) {
              //
            } else {
              return class_fe.reduce((tot, val) => {
                var sum;
                if(val.class == 'FE'){ sum = tot + parseFloat(val.net.replace(/,/g, '')); } else { sum = tot + 0; }
                return sum;
              }, 0);
            }
          },

          compute_total_gross_current(grossparam) {
            if (!grossparam) {
              //
            } else {
              return grossparam.reduce((tot, val) => {
                var sum;
                if(val.status == 'Current'){ sum = tot + parseFloat(val.gross.replace(/,/g, '')); } else { sum = tot + 0; }
                return sum;
              }, 0);
            }
          },

          compute_total_gross_prior(grossparam) {
            if (!grossparam) {
              //
            } else {
              return grossparam.reduce((tot, val) => {
                var sum;
                if(val.status == 'Prior'){ sum = tot + parseFloat(val.gross.replace(/,/g, '')); } else { sum = tot + 0; }
                return sum;
              }, 0);
            }
          },

          compute_total_gross(grossparam) {
            if (!grossparam) {
              //
            } else {
              return grossparam.reduce((tot, val) => {
                return tot + parseFloat(val.gross.replace(/,/g, ''));
              }, 0);
            }
          },

          compute_total_tax_current(taxparam) {
            if (!taxparam) {
              //
            } else {
              return taxparam.reduce((tot, val) => {
                var sum;
                if(val.status == 'Current'){ sum = tot + parseFloat(val.tax.replace(/,/g, '')); } else { sum = tot + 0; }
                return sum;
              }, 0);
            }
          },

          compute_total_tax_prior(taxparam) {
            if (!taxparam) {
              //
            } else {
              return taxparam.reduce((tot, val) => {
                var sum;
                if(val.status == 'Prior'){ sum = tot + parseFloat(val.tax.replace(/,/g, '')); } else { sum = tot + 0; }
                return sum;
              }, 0);
            }
          },

          compute_total_tax(taxparam) {
            if (!taxparam) {
              //
            } else {
              return taxparam.reduce((tot, val) => {
                return tot + parseFloat(val.tax.replace(/,/g, ''));
              }, 0);
            }
          },

          compute_total_net_current(netparam) {
            if (!netparam) {
              //
            } else {
              return netparam.reduce((tot, val) => {
                var sum;
                if(val.status == 'Current'){ sum = tot + parseFloat(val.net.replace(/,/g, '')); } else { sum = tot + 0; }
                return sum;
              }, 0);
            }
          },

          compute_total_net_prior(netparam) {
            if (!netparam) {
              //
            } else {
              return netparam.reduce((tot, val) => {
                var sum;
                if(val.status == 'Prior'){ sum = tot + parseFloat(val.net.replace(/,/g, '')); } else { sum = tot + 0; }
                return sum;
              }, 0);
            }
          },

          compute_total_net(netparam) {
            if (!netparam) {
              //
            } else {
              return netparam.reduce((tot, val) => {
                return tot + parseFloat(val.net.replace(/,/g, ''));
              }, 0);
            }
          },

          getNet(index) {
            var gross;
            var tax;
            var net = 0;

            //console.log(parseFloat(this.gross) + parseFloat(this.tax))
            if (this.form.creditors[index].gross == null) {
              gross = 0;
            } else {
              gross = this.form.creditors[index].gross;
            }

            if (this.form.creditors[index].tax == null) {
              tax = 0;
            } else {
              tax = this.form.creditors[index].tax;
            }

            net = this.convertToFloat(gross) - this.convertToFloat(tax);
            //console.log(net);

            this.form.creditors[index].net = net.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');

          },

          convertToFloat(x) {
            if(!x) {
              return 0.00
            } else {

              return parseFloat(x.replace(/\$|,/g, ''));
            }
          },

          addNewCreditor() {
              this.form.creditors.push({
                  id: null,
                  acct_name: '',
                  acct_details: '',
                  status: 'Current',
                  nature: '',
                  gross: 0,
                  tax: 0,
                  net: 0,
                  remarks: '',
                  creditorinfos: [{
                    id: null,
                    ors_no: '',
                    dv_no: '',
                    responsibility_center: '',
                  }] // <-
              })
          },
          removeCreditor(id, credid) {
            console.log(credid)
            this.form.get('api/creditors/'+ credid).then(()=>
            {
              swal.fire({
              title: 'Are you sure?',
              text: "All information attached in this creditor will be also deleted",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes, delete this creditor!'
              }).then((result)=>{
                if (result.value) {
                  this.form.delete('api/creditors/'+credid).then(()=>{
                    swal.fire(
                      'Deleted!',
                      'Creditor and its info has been deleted.',
                      'success'
                    );
                    this.form.creditors.splice(id, 1);
                  }).catch(()=>{
                    this.form.creditors.splice(id, 1);
                  });
                }
              });
            }).catch(()=>{
              this.form.creditors.splice(id, 1);
            });
          },

          addNewCreditorinfo(id) {
              // passing the id of the creditor
              this.form.creditors[id].creditorinfos.push({
                  id: null,
                  ors_no: '',
                  dv_no: '',
                  responsibility_center: '',
              })
          },
          removeCreditorinfo(index, id, infoid) {
            this.form.get('api/creditorinfos/'+ infoid).then(()=>
            {
              swal.fire({
              title: 'Are you sure?',
              text: "You won't be able to revert this!",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes, delete this info!'
              }).then((result)=>{
                if (result.value) {
                  this.form.delete('api/creditorinfos/'+infoid).then(()=>{
                    swal.fire(
                      'Deleted!',
                      'Creditor info has been deleted.',
                      'success'
                    );
                    this.form.creditors[index].creditorinfos.splice(id, 1);
                  }).catch(()=>{
                    this.form.creditors[index].creditorinfos.splice(id, 1);
                  });
                }
              });
            }).catch(()=>{
              this.form.creditors[index].creditorinfos.splice(id, 1);
            });
          },

          fillAcctDetails(index, details) {
            this.form.creditors[index].acct_name = details.replace(/ *\[[^\]]*\] */g, "");
            this.form.creditors[index].acct_details = details.split('[').pop().split(']')[0].replace("Savings", "S/A").replace("Checking", "C/A");
          },


          getAccountNumberOnly(value) {
            let str = [];
            // push account anmes to str array
            if(value) {
              for(var val in value) {
                if (value.hasOwnProperty(val)) {
                  str.push(value[val].acct_details.split('|')[0].replace("Savings", "S/A").replace("Checking", "C/A"));
                }
              }
            }
            let uniqueChars = str.filter((c, index) => {
              return str.indexOf(c) === index;
            });
            return uniqueChars.join('<br/>');
          },

          countDownTimer() {
            let timerId = setInterval(() => {
              this.timer -= 1;
              if (!this.$store.state.idleVue.isIdle) clearInterval(timerId);

              if (this.timer < 1) {
                clearInterval(timerId);
                document.getElementById('logout-form').submit();
              }
            }, 1000);

            this.timer = 30;
          },

          editModal(pmt) {
            this.editmode = true;
            this.form.reset();
            this.form.clear();
            this.form.fill(pmt);
            $('#paymentmodal').modal('show');
          },

          async generateControlNo() {
            var code;
            const current = new Date();
            const current_year =  current.getFullYear();
            const current_month = moment(String(current)).format('MM');

            await axios.get('api/payments/lastid').then(response => {
              if (!response.data) {
                this.new_control_no = current_year + current_month + '-0001';
                this.form.control_no = current_year + current_month + '-0001';
              } else {
                code = response.data.control_no.slice(response.data.control_no.length - 4);
                code = parseInt(code) + 1
                this.new_control_no = current_year + current_month + '-' + this.zeroPad(code, 4);
                this.form.control_no = current_year + current_month + '-' +this.zeroPad(code, 4);
              }
            });
          },

          setFundCluster() {
            this.generateLddapNo(this.form.fund_cluster.replace('-',''));
          },

          async generateLddapNo(fc) {

            var code;
            const current = new Date();
            const current_year =  current.getFullYear();
            const current_yy =  moment(String(current)).format('YY');
            const current_month = moment(String(current)).format('MM');

            await axios.get('api/payments/lastlddap/' + fc).then(response => {
              if (!response.data) {

                this.form.lddapada_no = fc + '-' + current_month + '-0001-' + current_year;

                if (fc.length != 8) {
                  this.form.check_no = '99' + current_yy + current_month + '0001';
                } else {
                  this.form.check_no = current_year + '000001';
                }
              } else {
                code = response.data.lddapada_no.slice(response.data.lddapada_no.length - 9);
                code = code.slice(0, 4);
                code = parseInt(code) + 1;
                this.form.lddapada_no = fc + '-' + current_month + '-' + this.zeroPad(code, 4) + '-' + current_year;
                if (fc.length != 8) {
                  this.form.check_no = '99' + current_yy + current_month + this.zeroPad(code, 4);
                } else {
                  this.form.check_no = current_year + this.zeroPad(code, 6);
                }

              }
            });
          },

          /*
          async generateCheckNo() {

            var code;
            const current = new Date();
            const current_year =  current.getFullYear();
            const current_month = moment(String(current)).format('MM');

            await axios.get('api/payments/lastcheckno').then(response => {
              if (!response.data) {
                this.form.check_no = current_year + '000001';
              } else {
                code = response.data.check_no.slice(response.data.check_no.length - 6);
                code = parseInt(code) + 1;
                this.form.check_no = current_year + this.zeroPad(code, 6);
              }
            });
          },
          */

          zeroPad(num, places) {
            var zero = places - num.toString().length + 1;
            return Array(+(zero > 0 && zero)).join("0") + num;
          },

          newModal() {

            this.editmode = false;
            this.form.reset();
            this.form.clear();
            this.form.fund_cluster = this.settings.fund_cluster;
            this.form.mds_subacct = this.settings.mds_subacct;
            this.form.head_accountant = this.settings.chief_accountant;
            this.form.division_chief = this.settings.division_chief;
            this.form.head_cashier = this.settings.head_cashier;
            this.form.director = this.settings.director;
            this.form.chief_accountant_designation = this.settings.chief_accountant_designation;
            this.form.division_chief_designation = this.settings.division_chief_designation;
            this.form.head_cashier_designation = this.settings.head_cashier_designation;
            this.form.director_designation = this.settings.director_designation;
            this.generateControlNo();
            this.generateLddapNo(this.form.fund_cluster.replace('-',''));
            $('#paymentmodal').modal('show');
          },



          viewModal(id) {
            //this.payment.clear;
            this.form.reset();
            this.form.clear();

            axios.get('api/payments/' + id + '/creditors').then(({ data }) => (
              this.payment = data,
              //console.log(data),
              this.form.fill(data)
              //this.form.lddapada_no = this.payment.lddapada_no,
              //this.form.payment_date = this.payment.payment_date,
              //this.form.fund_cluster = this.payment.fund_cluster
            ));


            $('#printmodal').modal('show');
          },

          createPayment() {
            this.$Progress.start();
            this.form.post('api/payments')
            .then(()=>{
              Trigger.$emit('RefreshData');
              $('#paymentmodal').modal('hide')
              toastr.fire({
                icon: 'success',
                title: 'LDDAP-ADA created successfully'
              })
              this.$Progress.finish();
            }).catch(()=>{
              toastr.fire({
                icon: 'error',
                title: 'LDDAP-ADA creation failed'
              });
              this.$Progress.fail();
            })
          },

          updatePayment() {
            this.$Progress.start();
            this.form.put('api/payments/' + this.form.id)
            .then(()=>{
              Trigger.$emit('RefreshData');
              $('#paymentmodal').modal('hide')
              toastr.fire({
                icon: 'success',
                title: 'LDDAP-ADA updated successfully'
              })
              this.$Progress.finish();
            }).catch(()=>{
              toastr.fire({
                icon: 'error',
                title: 'LDDAP-ADA updating failed'
              });
              this.$Progress.fail();
            })
          },

          deletePayment(id) {
            swal.fire({

              title: 'Are you sure?',
              text: "You won't be able to revert this!",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes, delete this record!'
              }).then((result)=>{
                if (result.value) {
                  this.form.delete('api/payments/'+id).then(()=>{
                    swal.fire(
                      'Deleted!',
                      'LDDAP-ADA has been deleted.',
                      'success'
                    );
                    Trigger.$emit('RefreshData');
                  }).catch(()=>{
                    swal.fire(
                      'Failed!',
                      'There was something wrong',
                      'warning'
                    )
                  });
                }
            })
          },

          deleteCreditorInfo(id) {
            swal.fire({

              title: 'Are you sure?',
              text: "You won't be able to revert this!",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes, delete this info!'
              }).then((result)=>{
                if (result.value) {
                  this.form.delete('api/creditorinfos/'+id).then(()=>{
                    swal.fire(
                      'Deleted!',
                      'Creditor info has been deleted.',
                      'success'
                    );
                    //Trigger.$emit('RefreshData');
                  }); /*.catch(()=>{
                    swal.fire(
                      'Failed!',
                      'There was something wrong',
                      'warning'
                    )
                  });*/
                }
            })
          },

          getResults(page = 1) {

            let query = this.$parent.search;

            if(query == ''){
              axios.get('api/payments?page=' + page).then(response => { this.payments = response.data; });
            }else{
              axios.get('api/findPayment?q=' + query + '&page=' + page).then(response => { this.payments = response.data; });
            }

          },

          loadPayments() {
            this.$Progress.start();

            let query = this.$parent.search;
            let page = this.payments.current_page;

            if(query == ''){
              axios.get('api/payments?page=' + page).then(({ data }) => (this.payments = data));
              axios.get('api/banks/allbanks').then(({ data }) => (this.banks = data));
              axios.get('api/projects').then(({ data }) => (this.projects = data));
              axios.get('api/cashiersetting/1').then(({ data }) => (this.settings = data));
              axios.get('api/payments/lastid').then(({ data }) => (this.lastdata = data));
              //console.log(this.settings.chief_accountant);
              this.form.head_accountant = this.settings.chief_accountant;
            }else{
              axios.get('api/findPayment?q=' + query + '&page=' + page).then(({ data }) => (this.payments = data));
              axios.get('api/banks/allbanks').then(({ data }) => (this.banks = data));
              axios.get('api/projects').then(({ data }) => (this.projects = data));
              axios.get('api/cashiersetting/1').then(({ data }) => (this.settings = data));
              axios.get('api/payments/lastid').then(({ data }) => (this.lastdata = data));
            }

            this.$Progress.finish();
          },

          async loadPermission(mid) {
              const response = await axios.get("api/permission/" + this.$gate.showMyID() + "/" + mid).then(
              res => {
                if (!res.data || res.data.length == 0) {
                  return false;
                } else {
                  return true;
                }
              })

              const getResponse = async () => {
                const a = await response;

                switch (mid) {
                case 23:
                  this.can_access = a;
                  if (!this.can_access) {
                    this.$router.push({ name: 'not-found' }) //redirect to not found
                  }
                  break;
                case 24:
                  this.can_add = a;
                  break;
                case 25:
                  this.can_edit = a;
                  break;
                case 26:
                  this.can_delete = a;
                }
              };
              return getResponse()
          },


        },

        created() {
          Trigger.$on('searching',() => {
            let query = this.$parent.search;
            let emptyflag = false;

            if(query == ''){
              Trigger.$emit('RefreshData');
            }else{
              this.$Progress.start();
              axios.get("api/findPayment?q=" + query)
              .then((data) => {
                this.payments = data.data;

                if (data.data.total == 0){
                  this.emptyflag = true;
                }

                this.$Progress.finish();
              })
              .catch(() => {
                this.$Progress.fail();
              })
            }

          });

          this.loadPayments();
          this.loadPermission(23); //Can access LDDAP-ADA
          this.loadPermission(24); //Can add new LDDAP-ADA
          this.loadPermission(25); //Can edit LDDAP-ADA
          this.loadPermission(26); //Can delete LDDAP-ADA

          Trigger.$on('RefreshData',() => {
            this.loadPayments();
          });
        },

    }
</script>
<style scoped>
.card-body.p-0 .table tbody>tr>td:first-of-type {
  padding-left: 0.5rem !important;
}
.controls {
  text-align: center;
}
h2 {
  font-size: 18px;
}
</style>
