
<style scoped>
.table th, .table td {
    padding: 0.30rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6;
}

</style>

<template>
    <div class="">

        <div class="row justify-content-center">

            <div class="col-md-12">
            <!-- Widget: user widget style 1 -->
                <div class="card card-widget widget-user">
                  <!-- Add the bg color to the header using any of the bg-* classes -->
                  <div class="widget-user-header bg-yellow">
                    <h3 v-if="pds.sname === 'NA'" class="mb-2">{{ pds.fname }} {{ pds.mname | midname }} {{ pds.lname }}</h3>
                    <h3 v-else class="widget-user-username mb-2">{{ pds.fname }} {{ pds.mname }} {{ pds.lname }} {{ pds.sname }}</h3>
                    <h5 class="widget-user-desc">{{ pds.position }}</h5>
                    <h5 class="widget-user-desc">{{ pds.divisions.division_name }}</h5>
                  </div>
                  <div class="widget-user-image">
                    <img class="profile-user-img img-fluid img-circle elevation-2 img-circle bg-secondary" :src="getProfilePhoto()" alt="">
                  </div>
                  <div class="card-footer">
                    <div class="row">
                      <div class="col-sm-4 border-right">
                        <div class="description-block">
                          <h5 class="description-header"><!--0--></h5>
                          <span class="description-text"><!--MATERIAL REQUISITION--></span>
                        </div>
                        <!-- /.description-block -->
                      </div>
                      <!-- /.col -->
                      <div class="col-sm-4 border-right">
                        <div class="description-block">
                          <h5 class="description-header"><!--0--></h5>
                          <span class="description-text"><!--SUPPORT TICKETS--></span>
                        </div>
                        <!-- /.description-block -->
                      </div>
                      <!-- /.col -->
                      <div class="col-sm-4">
                        <div class="description-block">
                          <h5 class="description-header"><!--0--></h5>
                          <span class="description-text"><!--TRAININGS--></span>
                        </div>
                        <!-- /.description-block -->
                      </div>
                      <!-- /.col -->
                    </div>
                    <!-- /.row -->
                  </div>
                </div>
                <!-- /.widget-user -->
            </div>

            <div class="col-md-3">
                <div class="card card-primary">

                  <div class="card-body">
                    <strong><i class="fas fa-id-card mr-1"></i> Employee ID</strong>
                    <p class="text-muted">{{ pds.employee_code }}</p>
                    <hr>
                    <strong><i class="fas fa-fingerprint mr-1"></i> Biometric ID</strong>
                    <p class="text-muted">{{ pds.biometric_id }}</p>
                    <hr>
                    <strong><i class="fas fa-user-tie mr-1"></i> Username</strong>
                    <p class="text-muted">{{ pds.username }}</p>
                  </div>
                  <!-- /.card-body -->
                </div>
            </div>

            <div class="col-md-3">
                <div class="card card-primary">

                  <div class="card-body">
                    <strong><i class="fas fa-envelope mr-1"></i> Email</strong>
                    <p class="text-muted">{{ pds.email }}</p>
                    <hr>
                    <strong><i class="fas fa-certificate mr-1"></i> Eligibility</strong>
                    <p class="text-muted">{{ pds.eligibility }}</p>
                    <hr>
                    <strong><i class="fas fa-user-tag mr-1"></i> Plantilla Item No.</strong>
                    <p v-if="pds.plantilla == null" class="text-muted">NA</p>
                    <p v-else class="text-muted">{{ pds.plantilla }}</p>
                  </div>
                  <!-- /.card-body -->
                </div>
            </div>

            <div class="col-md-3">
                <div class="card card-primary">

                  <div class="card-body">
                    <strong><i class="fas fa-hand-holding-usd mr-1"></i> Salary Grade / Monthly Salary</strong>
                    <p class="text-muted">SG {{ form.sg | sgformat }} ({{ pds.salary | toCurrency }})</p>
                    <hr>
                    <strong><i class="fas fa-user-lock mr-1"></i> Appointment Status</strong>
                    <p class="text-muted">{{ pds.appointment }}</p>
                    <hr>
                    <strong><i class="fas fa-briefcase mr-1"></i> Appointment Nature</strong>
                    <p class="text-muted">{{ pds.nature }}</p>
                  </div>
                  <!-- /.card-body -->
                </div>
            </div>

            <div class="col-md-3">
                <div class="card card-primary">

                  <div class="card-body">

                    <strong><i class="fas fa-calendar mr-1"></i> Date of Original Appointment</strong>
                    <p class="text-muted">{{ pds.hired | customdate }}</p>
                    <hr>
                    <strong><i class="fas fa-medal mr-1"></i> Last Promotion</strong>
                    <p class="text-muted">{{ pds.promotion | customdate }}</p>
                    <hr>
                    <strong><i class="fas fa-money-check-alt mr-1"></i> Employee Status</strong>
                    <p class="text-muted">{{ pds.acct_status }}</p>
                  </div>
                  <!-- /.card-body -->
                </div>
            </div>
    </div>

    <div class="row justify-content-center">
        <div class="col-md-12">
            <div class="card table-responsive p-0">
              <div class="card-header p-2">
                <div class="card-title">
                  <ul class="nav nav-pills">
                    <li class="nav-item"><a class="nav-link active" href="#personal" data-toggle="tab">Personal Info</a></li>
                    <li class="nav-item"><a class="nav-link" href="#family" data-toggle="tab">Family</a></li>
                    <li class="nav-item"><a class="nav-link" href="#education" data-toggle="tab">Education</a></li>
                    <li class="nav-item"><a class="nav-link" href="#examinations" data-toggle="tab">Examinations</a></li>
                    <li class="nav-item"><a class="nav-link" href="#experience" data-toggle="tab">Work Experience</a></li>
                    <li class="nav-item"><a class="nav-link" href="#voluntary" data-toggle="tab">Voluntary Work</a></li>
                    <li class="nav-item"><a class="nav-link" href="#trainings" data-toggle="tab">Trainings</a></li>
                    <!-- <li class="nav-item"><a class="nav-link" href="#timeline" data-toggle="tab">Timeline</a></li> -->
                    <li class="nav-item"><a class="nav-link" href="#others" data-toggle="tab">Other Info</a></li>
                    <li class="nav-item"><a class="nav-link" href="#references" data-toggle="tab">References</a></li>
                    <li class="nav-item"><a class="nav-link" href="#settings" data-toggle="tab">Settings</a></li>
                  </ul>
                </div>
                <div class="card-tools">
                  <div>
                    <!--
                    <a href="/pds" target="_blank" class="btn btn-secondary mr-2"><i class="fas fa-print"></i> Print</a>
                    <button type="button" @click="pdsModal" class="btn btn-primary btn-lg btn-block">View PDS</button>
                    -->
                  </div>
                </div>

              </div><!-- /.card-header -->
              <div class="card-body">
                <div class="tab-content">

                  <!-- /.tab-pane (Personal Information) -->
                  <div class="tab-pane active" id="personal">
                    <form class="form-horizontal">


                      <div class="row">


                        <table class="table table-bordered table-hover ml-4 mr-4">
                          <tbody>
                            <tr>
                              <th colspan="3" class="card-header">
                                  GENERAL INFORMATION <button type="button" @click="generalModal" class="btn btn-link float-right">EDIT <i class="fa fa-edit"></i></button>
                              </th>
                            </tr>
                            <tr>
                              <td><strong>Date of Birth: </strong> {{ pds.dob | customdate }}</td>
                              <td><strong>Height (m): </strong>{{ pds.height }}</td>
                              <td><strong>GSIS ID No: </strong>{{ pds.gsis }}</td>
                            </tr>
                            <tr>
                              <td><strong>Place of Birth: </strong> {{ pds.pob }}</td>
                              <td><strong>Weight (kg): </strong>{{ pds.weight }}</td>
                              <td><strong>Pag-ibig ID No: </strong>{{ pds.pagibig }}</td>
                            </tr>
                            <tr>
                              <td><strong>Gender: </strong> {{ pds.gender }}</td>
                              <td><strong>Blood Type: </strong>{{ pds.blood_type }}</td>
                              <td><strong>Philhealth No: </strong>{{ pds.philhealth }}</td>
                            </tr>
                            <tr>
                              <td><strong>Civil Status: </strong> {{ pds.civil_status }}</td>
                              <td><strong>Telephone No: </strong>{{ pds.telephone_no }}</td>
                              <td><strong>SSS No: </strong>{{ pds.sss }}</td>
                            </tr>
                            <tr>
                              <td><strong>Citizenship: </strong> {{ pds.citizenship }}</td>
                              <td><strong>Mobile No: </strong>{{ pds.mobile_no }}</td>
                              <td><strong>TIN No: </strong>{{ pds.tin }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>


                      <div class="row pl-4 pr-4">

                        <table class="table table-bordered table-hover">
                          <tbody>
                            <tr>
                              <th class="card-header">
                                RESIDENTIAL ADDRESS
                              </th>
                              <th class="card-header">
                                PERMANENT ADDRESS  <button type="button" @click="addressModal" class="btn btn-link float-right">EDIT <i class="fa fa-edit"></i></button>
                              </th>
                            </tr>
                            <tr>
                              <td><strong>House / Block / Lot No: </strong>{{ pds.res_lot }}</td>
                              <td><strong>House / Block / Lot No: </strong>{{ pds.perm_lot }}</td>
                            </tr>
                            <tr>
                              <td><strong>Street: </strong>{{ pds.res_street }}</td>
                              <td><strong>Street: </strong>{{ pds.perm_street }}</td>
                            </tr>
                            <tr>
                              <td><strong>Subdivision / Village: </strong>{{ pds.res_subdivision }}</td>
                              <td><strong>Subdivision / Village: </strong>{{ pds.perm_subdivision }}</td>
                            </tr>
                            <tr>
                              <td><strong>Barangay: </strong>{{ pds.res_barangay }}</td>
                              <td><strong>Barangay: </strong>{{ pds.perm_barangay }}</td>
                            </tr>
                            <tr>
                              <td><strong>City / Municipality: </strong>{{ pds.res_city }}</td>
                              <td><strong>City / Municipality: </strong>{{ pds.perm_city }}</td>
                            </tr>
                            <tr>
                              <td><strong>Province: </strong>{{ pds.res_province }}</td>
                              <td><strong>Province: </strong>{{ pds.perm_province }}</td>
                            </tr>
                            <tr>
                              <td><strong>ZIP Code: </strong>{{ pds.res_zip }}</td>
                              <td><strong>ZIP Code: </strong>{{ pds.perm_zip }}</td>
                            </tr>
                          </tbody>
                        </table>




                      </div>



                    </form>
                  </div>
                  <!-- /.tab-pane -->

                  <!-- /.tab-pane (Family Background) -->
                  <div class="tab-pane" id="family">
                    <form class="form-horizontal">
                      <div class="row pl-4 pr-4">

                        <table class="table table-bordered table-hover col-md-6">
                          <tbody>
                            <tr>
                              <th class="card-header">
                                FATHER'S NAME
                              </th>
                            </tr>
                            <tr>
                              <td><strong>Surname: </strong>{{ pds.father_lastname }}</td>
                            </tr>
                            <tr>
                              <td><strong>First Name: </strong>{{ pds.father_firstname }}</td>
                            </tr>
                            <tr>
                              <td><strong>Middle Name: </strong>{{ pds.father_middlename }}</td>
                            </tr>
                            <tr>
                              <td><strong>Name Extension (Jr., Sr): </strong>{{ pds.father_extension }}</td>
                            </tr>
                          </tbody>
                        </table>

                        <table class="table table-bordered table-hover col-md-6">
                          <tbody>
                            <tr>
                              <th class="card-header">
                                MOTHER'S MAIDEN NAME  <button type="button" @click="familyModal" class="btn btn-link float-right">EDIT <i class="fa fa-edit"></i></button>
                              </th>
                            </tr>
                            <tr>
                              <td><strong>Surname: </strong>{{ pds.mother_lastname }}</td>
                            </tr>
                            <tr>
                              <td><strong>First Name: </strong>{{ pds.mother_firstname }}</td>
                            </tr>
                            <tr>
                              <td><strong>Middle Name: </strong>{{ pds.mother_middlename }}</td>
                            </tr>
                            <tr>
                              <td>&nbsp;</td>
                            </tr>
                          </tbody>
                        </table>


                      </div>

                      <div class="row pl-4 pr-4">

                        <table class="table table-bordered table-hover col-md-6">
                          <tbody>
                            <tr>
                              <th colspan="3" class="card-header">
                                SPOUSE'S NAME
                              </th>
                            </tr>
                            <tr>
                              <td><strong>Surname: </strong>{{ pds.spouse_lastname }}</td>
                              <td><strong>Occupation: </strong>{{ pds.spouse_occupation }}</td>
                            </tr>
                            <tr>
                              <td><strong>First Name: </strong>{{ pds.spouse_firstname }}</td>
                              <td><strong>Employer/Business Name: </strong>{{ pds.spouse_employer }}</td>
                            </tr>
                            <tr>
                              <td><strong>Middle Name: </strong>{{ pds.spouse_middlename }}</td>
                              <td><strong>Business Address: </strong>{{ pds.spouse_business_address }}</td>
                            </tr>
                            <tr>
                              <td><strong>Name Extension (Jr., Sr): </strong>{{ pds.spouse_extension }}</td>
                              <td><strong>Telephone No: </strong>{{ pds.spouse_telephone_no }}</td>
                            </tr>
                          </tbody>

                        </table>



                        <table class="table table-bordered table-hover col-md-6">
                          <tbody>
                            <tr>
                              <th class="card-header">
                                NAME OF CHILDREN
                              </th>
                              <th class="card-header">
                                DATE OF BIRTH
                              </th>
                              <td align="center">
                                <button type="button" @click="createChildrenModal" class="btn btn-link">ADD <i class="fa fa-plus"></i></button>
                              </td>
                            </tr>
                            <tr v-for="child in form.children" :key="child.id">
                              <td>{{ child.child_name }}</td>
                              <td>{{ child.child_dob }}</td>
                              <td align="center">
                                <button class="btn btn-link" type="button" @click="editChildrenModal(child)"><i class="fa fa-edit"></i></button>
                                <button class="btn btn-link" type="button" @click="deleteChildren(child.id)"><i class="fa fa-trash"></i></button>
                              </td>
                            </tr>
                          </tbody>
                          <tbody v-show="form.children.length == 0">
                              <td>NA</td>
                              <td>NA</td>
                              <td>NA</td>
                          </tbody>

                        </table>

                      </div>

                    </form>
                  </div>
                  <!-- /.tab-pane -->

                  <!-- /.tab-pane (Educational Background) -->
                  <div class="tab-pane" id="education">
                    <form class="form-horizontal">
                      <div class="row pl-4 pr-4">
                        <table class="table table-bordered table-hover">
                          <tbody>
                            <tr>
                              <th rowspan="2" class="card-header">
                                LEVEL
                              </th>
                              <th rowspan="2" class="card-header">
                                NAME OF SCHOOL<br>(Write in full)
                              </th>
                              <th rowspan="2"  class="card-header">
                                BASIC EDUCATION / DEGREE / COURSE<br>(Write in full)
                              </th>
                              <th colspan="2" class="card-header">
                                PERIOD OF ATTENDANCE
                              </th>

                              <th rowspan="2"  class="card-header">
                                HIGHEST LEVEL / UNITS EARNED<br>(if not graduated)
                              </th>
                              <th rowspan="2"  class="card-header">
                                YEAR<br>GRADUATED
                              </th>
                              <th rowspan="2"  class="card-header">
                                SCHOLARSHIP / ACADEMIC HONORS RECEIVED
                              </th>
                              <td rowspan="2"  align="center">
                                <button type="button" @click="educationModal" class="btn btn-link"><i class="fa fa-plus"></i></button>
                              </td>
                            </tr>
                            <tr>
                              <th class="card-header">FROM</th>
                              <th class="card-header">TO</th>
                            </tr>
                            <tr v-for="educ in form.educates" :key="educ.id">
                              <td>{{ educ.educ_level }}</td>
                              <td>{{ educ.educ_school }}</td>
                              <td>{{ educ.educ_degree }}</td>
                              <td>{{ educ.educ_from }}</td>
                              <td>{{ educ.educ_to }}</td>
                              <td>{{ educ.educ_earned }}</td>
                              <td>{{ educ.educ_gradyear }}</td>
                              <td>{{ educ.educ_honors }}</td>
                              <td align="center">
                                <button class="btn btn-link" type="button" @click="editEducation(educ)"><i class="fa fa-edit"></i></button>
                                <button class="btn btn-link" type="button" @click="deleteEducation(educ)"><i class="fa fa-trash"></i></button>
                              </td>
                            </tr>
                          </tbody>
                          <tbody v-show="form.educates.length == 0">
                              <td>NA</td>
                              <td>NA</td>
                              <td>NA</td>
                              <td>NA</td>
                              <td>NA</td>
                              <td>NA</td>
                              <td>NA</td>
                              <td>NA</td>
                              <td>NA</td>
                          </tbody>

                        </table>
                      </div>
                    </form>
                  </div>
                  <!-- /.tab-pane -->

                  <!-- /.tab-pane (Examinations) -->
                  <div class="tab-pane" id="examinations">
                    <form class="form-horizontal">
                      <div class="row pl-4 pr-4">
                        <table class="table table-bordered table-hover">
                          <tbody>
                            <tr>
                              <th rowspan="2" class="card-header">
                                CAREER SERVICE / RA 1080 (BOARD / BAR) UNDER<br>
                                SPECIAL LAWS / CES / CSEE BARANGAY ELIGIBILITY / DRIVER'S LICENSE
                              </th>
                              <th rowspan="2" class="card-header">
                                RATING<br>(If Applicable)
                              </th>
                              <th rowspan="2"  class="card-header">
                                DATE OF <br>
                                EXAMINATION /<br>
                                CONFERMENT
                              </th>
                              <th rowspan="2"  class="card-header">
                                PLACE OF EXAMINATION / CONFERMENT
                              </th>

                              <th colspan="2" class="card-header">
                                LICENSE (if applicable)
                              </th>

                              <td rowspan="2"  align="center">
                                <button type="button" @click="examinationModal" class="btn btn-link"><i class="fa fa-plus"></i></button>
                              </td>
                            </tr>
                            <tr>
                              <th class="card-header">NUMBER</th>
                              <th class="card-header">Date of Validity</th>
                            </tr>
                            <tr v-for="exam in form.examinations" :key="exam.id">
                              <td>{{ exam.exam_name }}</td>
                              <td>{{ exam.exam_rating }}</td>
                              <td>{{ exam.exam_conferment | customdate }}</td>
                              <td>{{ exam.exam_place }}</td>
                              <td>{{ exam.exam_license }}</td>
                              <td>{{ exam.exam_validity }}</td>
                              <td align="center">
                                <button class="btn btn-link" type="button" @click="editExaminiation(exam)"><i class="fa fa-edit"></i></button>
                                <button class="btn btn-link" type="button" @click="deleteExamination(exam)"><i class="fa fa-trash"></i></button>
                              </td>
                            </tr>
                          </tbody>
                          <tbody v-show="form.examinations.length == 0">
                              <td>NA</td>
                              <td>NA</td>
                              <td>NA</td>
                              <td>NA</td>
                              <td>NA</td>
                              <td>NA</td>
                              <td>NA</td>
                          </tbody>

                        </table>
                      </div>
                    </form>
                  </div>
                  <!-- /.tab-pane -->

                  <!-- /.tab-pane (Work Experience) -->
                  <div class="tab-pane" id="experience">
                    <form class="form-horizontal">
                      <div class="row pl-4 pr-4">
                        <table class="table table-bordered table-hover">
                          <tbody>
                            <tr>
                              <th colspan="2" class="card-header">
                                INCLUSIVE DATES
                              </th>
                              <th rowspan="2" class="card-header">
                                POSITION TITLE<br>(Write in full / Do not abbreviate)
                              </th>
                              <th rowspan="2"  class="card-header">
                                DEPARTMENT / AGENCY / OFFICE / COMPANY<br>(Write in full / Do not abbreviate)
                              </th>
                              <th rowspan="2" class="card-header">
                                MONTHLY SALARY
                              </th>

                              <th rowspan="2"  class="card-header">
                                SALARY / JOB / PAY GRADE<br>(if applicable) & STEP<br>(Format "00-0") / INCREMENT
                              </th>
                              <th rowspan="2"  class="card-header">
                                STATUS OF<br>APPOINTMENT
                              </th>
                              <th rowspan="2"  class="card-header">
                                GOV'T<br>SERVICE<br>(Y/N)
                              </th>
                              <td rowspan="2"  align="center">
                                <button type="button" @click="experienceModal" class="btn btn-link"><i class="fa fa-plus"></i></button>
                              </td>
                            </tr>
                            <tr>
                              <th class="card-header">FROM</th>
                              <th class="card-header">TO</th>
                            </tr>
                            <tr v-for="exp in form.experiences" :key="exp.id">
                              <td>{{ exp.exp_from }}</td>
                              <td>{{ exp.exp_to }}</td>
                              <td>{{ exp.exp_title }}</td>
                              <td>{{ exp.exp_company }}</td>
                              <td>{{ exp.exp_salary }}</td>
                              <td>{{ exp.exp_grade }}</td>
                              <td>{{ exp.exp_appointment }}</td>
                              <td>{{ exp.exp_govt }}</td>
                              <td align="center">
                                <button class="btn btn-link" type="button" @click="editExperience(exp)"><i class="fa fa-edit"></i></button>
                                <button class="btn btn-link" type="button" @click="deleteExperience(exp)"><i class="fa fa-trash"></i></button>
                              </td>
                            </tr>
                          </tbody>
                          <tbody v-show="form.experiences.length == 0">
                              <td>NA</td>
                              <td>NA</td>
                              <td>NA</td>
                              <td>NA</td>
                              <td>NA</td>
                              <td>NA</td>
                              <td>NA</td>
                              <td>NA</td>
                              <td>NA</td>
                          </tbody>

                        </table>
                      </div>
                    </form>
                  </div>
                  <!-- /.tab-pane -->

                  <!-- /.tab-pane (Voluntary Work) -->
                  <div class="tab-pane" id="voluntary">
                    <form class="form-horizontal">
                      <div class="row pl-4 pr-4">
                        <table class="table table-bordered table-hover">
                          <tbody>
                            <tr>
                              <th rowspan="2" class="card-header">
                                NAME & ADDRESS OF ORGANIZATION<br>
                                (Write in full)
                              </th>
                              <th colspan="2" class="card-header">
                                INCLUSIVE DATES
                              </th>
                              <th rowspan="2"  class="card-header">
                                NUMBER OF HOURS
                              </th>
                              <th rowspan="2"  class="card-header">
                                POSITION / NATURE OF WORK
                              </th>

                              <td rowspan="2"  align="center">
                                <button type="button" @click="voluntaryModal" class="btn btn-link"><i class="fa fa-plus"></i></button>
                              </td>
                            </tr>
                            <tr>
                              <th class="card-header">From</th>
                              <th class="card-header">To</th>
                            </tr>
                            <tr v-for="voluntary in form.voluntaries" :key="voluntary.id">
                              <td>{{ voluntary.vol_name }}</td>
                              <td>{{ voluntary.vol_from }}</td>
                              <td>{{ voluntary.vol_to }}</td>
                              <td>{{ voluntary.vol_hrs }}</td>
                              <td>{{ voluntary.vol_position }}</td>
                              <td align="center">
                                <button class="btn btn-link" type="button" @click="editVoluntary(voluntary)"><i class="fa fa-edit"></i></button>
                                <button class="btn btn-link" type="button" @click="deleteVoluntary(voluntary)"><i class="fa fa-trash"></i></button>
                              </td>
                            </tr>
                          </tbody>
                          <tbody v-show="form.voluntaries.length == 0">
                              <td>NA</td>
                              <td>NA</td>
                              <td>NA</td>
                              <td>NA</td>
                              <td>NA</td>
                              <td>NA</td>
                          </tbody>

                        </table>
                      </div>
                    </form>
                  </div>
                  <!-- /.tab-pane -->

                  <!-- /.tab-pane (Trainings) -->
                  <div class="tab-pane" id="trainings">
                    <form class="form-horizontal">
                      <div class="row pl-4 pr-4">
                        <table class="table table-bordered table-hover">
                          <tbody>
                            <tr>
                              <th rowspan="2" class="card-header">
                                TITLE OF LEARNING AND DEVELOPMENT INTERVENTIONS / TRAINING PROGRAMS<br>
                              </th>
                              <th colspan="2" class="card-header">
                                INCLUSIVE DATES
                              </th>
                              <th rowspan="2"  class="card-header">
                                NUMBER OF HOURS
                              </th>
                              <th rowspan="2"  class="card-header">
                                Type of LD (Managerial / Supervisory / Technical / etc)
                              </th>
                              <th rowspan="2"  class="card-header">
                                CONDUCTED / SPONSORED BY<br>
                              </th>

                              <td rowspan="2"  align="center">
                                <button type="button" @click="trainingModal" class="btn btn-link"><i class="fa fa-plus"></i></button>
                              </td>
                            </tr>
                            <tr>
                              <th class="card-header">From</th>
                              <th class="card-header">To</th>
                            </tr>
                            <tr v-for="trn in form.trainings" :key="trn.id">
                              <td>{{ trn.train_title }}</td>
                              <td>{{ trn.train_from }}</td>
                              <td>{{ trn.train_to }}</td>
                              <td>{{ trn.train_hrs }}</td>
                              <td>{{ trn.train_type }}</td>
                              <td>{{ trn.train_sponsor }}</td>
                              <td align="center">
                                <button class="btn btn-link" type="button" @click="editTraining(trn)"><i class="fa fa-edit"></i></button>
                                <button class="btn btn-link" type="button" @click="deleteTraining(trn)"><i class="fa fa-trash"></i></button>
                              </td>
                            </tr>
                          </tbody>
                          <tbody v-show="form.trainings.length == 0">
                              <td>NA</td>
                              <td>NA</td>
                              <td>NA</td>
                              <td>NA</td>
                              <td>NA</td>
                              <td>NA</td>
                          </tbody>

                        </table>
                      </div>
                    </form>
                  </div>
                  <!-- /.tab-pane -->

                  <!-- /.tab-pane (Others) -->
                  <div class="tab-pane" id="others">
                    <form class="form-horizontal">
                      <div class="row pl-4 pr-4">
                        <table class="table table-bordered table-hover">
                          <tbody>
                            <tr>
                              <th class="card-header">
                                SPECIAL SKILLS and HOBBIES
                              </th>
                              <th class="card-header">
                                NON-ACADEMIC DISTINCTIONS / RECOGNITION<br>(Write in full)
                              </th>
                              <th class="card-header">
                                MEMBERSHIP IN ASSOCIATION / ORGANIZATION<br>(Write in full)
                              </th>

                              <td align="center">
                                <button type="button" @click="otherModal" class="btn btn-link"><i class="fa fa-plus"></i></button>
                              </td>
                            </tr>
                            <tr v-for="skl in form.skills" :key="skl.id">
                              <td>{{ skl.skill_title }}</td>
                              <td>{{ skl.skill_recognition }}</td>
                              <td>{{ skl.skill_membership }}</td>
                              <td align="center">
                                <button class="btn btn-link" type="button" @click="editSkill(skl)"><i class="fa fa-edit"></i></button>
                                <button class="btn btn-link" type="button" @click="deleteSkill(skl)"><i class="fa fa-trash"></i></button>
                              </td>
                            </tr>
                          </tbody>
                          <tbody v-show="form.skills.length == 0">
                              <td>NA</td>
                              <td>NA</td>
                              <td>NA</td>
                              <td>NA</td>
                          </tbody>

                        </table>
                      </div>
                    </form>
                  </div>
                  <!-- /.tab-pane -->

                  <!-- /.tab-pane (References) -->
                  <div class="tab-pane" id="references">
                    <form class="form-horizontal">
                      <div class="row pl-4 pr-4">
                        <table class="table table-bordered table-hover">
                          <tbody>
                            <tr>
                              <th class="card-header">
                                REFERENCE NAME
                              </th>
                              <th class="card-header">
                                ADDRESS
                              </th>
                              <th class="card-header">
                                TEL. NO.
                              </th>

                              <td align="center">
                                <button type="button" @click="referenceModal" class="btn btn-link"><i class="fa fa-plus"></i></button>
                              </td>
                            </tr>
                            <tr v-for="refr in form.references" :key="refr.id">
                              <td>{{ refr.ref_name }}</td>
                              <td>{{ refr.ref_address }}</td>
                              <td>{{ refr.ref_tel }}</td>
                              <td align="center">
                                <button class="btn btn-link" type="button" @click="editReference(refr)"><i class="fa fa-edit"></i></button>
                                <button class="btn btn-link" type="button" @click="deleteReference(refr)"><i class="fa fa-trash"></i></button>
                              </td>
                            </tr>
                          </tbody>
                          <tbody v-show="form.references.length == 0">
                              <td>NA</td>
                              <td>NA</td>
                              <td>NA</td>
                              <td>NA</td>
                          </tbody>

                        </table>
                      </div>
                    </form>
                  </div>
                  <!-- /.tab-pane -->




                  <!-- /.tab-pane (Account Settings) -->
                  <div class="tab-pane" id="settings">
                    <form class="form-horizontal">

                      <div class="form-group row">
                        <label for="photo" class="col-sm-2 col-form-label">Display Picture</label>
                        <div class="col-sm-10" >
                          <div class="custom-file">
                            <input type="file" @change="updateProfile" class="custom-file-input" name="photo" id="photo">
                            <label class="custom-file-label" for="photo">Choose file</label>
                          </div>
                        </div>
                      </div>


                      <div class="form-group row">
                        <label for="password" class="col-sm-2 col-form-label">Change Password</label>
                        <div class="col-sm-10">
                          <input type="password" v-model="form.password" class="form-control" id="password" placeholder="Change Password (leave empty if not changing)" :class="{ 'is-invalid': form.errors.has('password') }">
                          <has-error :form="form" field="password"></has-error>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label for="confirm_password" class="col-sm-2 col-form-label">Confirm Password</label>
                        <div class="col-sm-10">
                          <input type="password" v-model="form.confirm_password" class="form-control" id="confirm_password" placeholder="Confirm Password" :class="{ 'is-invalid': form.errors.has('confirm_password') }">
                          <has-error :form="form" field="confirm_password"></has-error>
                        </div>
                      </div>


                      <div class="form-group row">
                        <div class="offset-sm-2 col-sm-10">
                          <button @click.prevent="updateInfo" type="submit" class="btn btn-success">Update</button>
                        </div>
                      </div>
                    </form>
                  </div>
                  <!-- /.tab-pane -->

                  <div class="modal fade" id="sessionmodal" tabindex="-1" role="dialog" aria-labelledby="sessionModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-md" role="document">
                      <div class="modal-content">
                        <div class="modal-header bg-orange">
                          <h5 class="modal-title" id="sessionModalLabel"><i class="icon fas fa-exclamation-triangle"></i> Security Auto-logout</h5>
                          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div class="modal-body">
                        <div class="row-aligncenter">
                          <div id="printPayslip2">
                            <div class="card-body">
                              You have left this browser idle for 10 minutes. Due to our data privacy policy, you will be
                              automatically logged out in <b>{{ timer }}</b> seconds.
                            </div>
                          </div>
                        </div>
                        </div>
                        <div class="modal-footer">
                          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                      </div>
                    </div>
                  </div>




                  <span style="display:none;">{{ isIdle }}</span>



                </div>

                <!-- /.tab-content -->
              </div><!-- /.card-body -->
            </div>
            </div>
        </div>


            <!-- MODAL PDS -->
                <div class="row" id="pdsmodal1" tabindex="-1" role="dialog" aria-labelledby="pdsModalLabel1" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
                      <div class="modal-content table-responsive p-0">
                        <div class="modal-header bg-orange">
                          <h5 class="modal-title" id="pdsModalLabel1">Electronic Personal Data Sheet (e-PDS)</h5>
                          <!--
                          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                          </button>
                          -->
                        </div>


                        <div class="modal-body">
                        <div class="row-aligncenter">
                          <div id="printPds">
                            <div class="card-body">
                              <br/>
                              <table class="table table-borderless">
                                <tr>
                                  <td colspan="5" class="border border-bottom-0 border-dark"><small class="float-left font-italic font-weight-bold">CS Form No. 212<br/>Revised 2017</small></td>
                                </tr>
                                 <tr>
                                  <td colspan="5" class="border border-top-0 border-bottom-0 border-dark">&nbsp;</td>
                                </tr>
                                <tr>
                                  <td colspan="5" class="border border-top-0 border-bottom-0 border-dark"><h1 class="page-header text-center font-weight-bold">PERSONAL DATA SHEET</h1></td>
                                </tr>
                                 <tr>
                                  <td colspan="5" class="border border-top-0 border-bottom-0 border-dark">&nbsp;</td>
                                </tr>
                                <tr>
                                  <td colspan="5" class="border border-top-0 border-bottom-0 border-dark"><small class="font-italic font-weight-bold">WARNING: Any misrepresentation made in the Personal Data Sheet and the Work Experience Sheet shall cause the filing of administrative/criminal case/s against the person concerned.<br/>READ THE ATTACHED GUIDE TO FILLING OUT THE PERSONAL DATA SHEED (PDS) BEFORE ACCOMPLISHING THE PDS FORM.</small></td>
                                </tr>
                                <tr>
                                  <td colspan="3" class="border border-top-0 border-bottom-0 border-dark"><small>Print legibly. Tick appropriate boxes (&squ;) and use separate sheet if necessary. Indicate N/A if not applicable.</small><small class="font-weight-bold"> NOT ABBREVIATE.</small></td>
                                  <td class="border border-dark bg-secondary">1. CS ID No</td>
                                  <td class="border border-left-0 border-dark" align="right"><small class="float-left" style="font-size:8px;line-height: -1;">(Do not fill up. For CSC use only)</small>&nbsp;</td>
                                </tr>
                                <tr>
                                  <td colspan="5" class="border border-top-0 border-bottom-0 border-dark">&nbsp;</td>
                                </tr>
                                <tr>
                                  <td colspan="5" class="border border-dark bg-secondary font-italic font-weight-bold">&nbsp;&nbsp;&nbsp;&nbsp;I. PERSONAL INFORMATION</td>
                                </tr>
                                <tr>
                                  <td class="border-left border-dark">2. SURNAME</td>
                                  <td class="border border-top-0 border-dark" colspan="4">{{ form.lname }}</td>
                                </tr>
                                <tr>
                                  <td class="border-left border-dark">&nbsp;&nbsp;&nbsp;&nbsp;FIRSTNAME</td>
                                  <td class="border border-top-0 border-dark" colspan="3">{{ form.fname }}</td>
                                  <td class="border-right border-bottom border-dark" align="right"><small class="float-left" style="font-size:8px;line-height: 1;">NAME EXTENSION (JR., SR) </small>  {{ form.sname }}&nbsp;&nbsp;&nbsp;</td>
                                </tr>
                                <tr>
                                  <td class="border-left border-bottom border-dark">&nbsp;&nbsp;&nbsp;&nbsp;MIDDLENAME</td>
                                  <td class="border border-top-0 border-dark" colspan="4">{{ form.mname }}</td>
                                </tr>
                                <tr>
                                  <td class="border-right border-left border-dark">3. DATE OF BIRTH</td>
                                  <td class="border-right border-left border-dark">{{ form.dob | dobformat }}</td>
                                  <td class="border border-top-0 border-bottom-0 border-dark">16. CITIZENSHIP</td>
                                  <td class="border border-top-0 border-bottom-0 border-dark" colspan="2">&#9745; Filipino    &#9744; Dual Citizenship</td>
                                </tr>
                                <tr>
                                  <td class="border border-top-0 border-dark">&nbsp;&nbsp;&nbsp;&nbsp;(mm/dd/yyyy)</td>
                                  <td class="border border-top-0 border-dark"></td>
                                  <td class="border border-top-0 border-bottom-0 border-dark"></td>
                                  <td class="border border-top-0 border-bottom-0 border-dark" colspan="2">&nbsp;&nbsp;&nbsp;&nbsp;&#9745; by birth  &squ; by naturalization</td>
                                </tr>
                                <tr>
                                  <td class="border border-top-0 border-dark">4. PLACE OF BIRTH</td>
                                  <td class="border border-top-0 border-dark">{{ form.pob }}</td>
                                  <td class="border border-top-0 border-bottom-0 border-dark">If holder of dual citizenship,</td>
                                  <td class="border border-top-0 border-dark" colspan="2">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Pls. indicate country:</td>
                                </tr>
                                <tr>
                                  <td class="border border-top-0 border-dark">5. SEX</td>
                                  <td v-if="form.gender === 'Male'" class="border border-top-0 border-dark">&#9745; Male &squ; Female</td>
                                  <td v-else class="border border-top-0 border-dark">&squ; Male &#9745; Female</td>
                                  <td class="border border-top-0 border-dark">please indicate the details.</td>
                                  <td class="border border-top-0 border-dark" colspan="2"></td>
                                </tr>
                                <tr>
                                  <td class="border border-top-0 border-bottom-0 border-dark">6. CIVIL STATUS</td>
                                  <td v-if="form.civil_status === 'Single'" class="border border-top-0 border-bottom-0 border-dark">&#9745; Single &squ; Married</td>
                                  <td v-if="form.civil_status === 'Married'" class="border border-top-0 border-bottom-0 border-dark">&squ; Single &#9745; Married</td>
                                  <td v-if="form.civil_status === 'Widowed' || form.civil_status === 'Separated'" class="border border-top-0 border-bottom-0 border-dark">&squ; Single &squ; Married</td>
                                  <td class="border border-top-0 border-bottom-0 border-dark">17. RESIDENTIAL ADDRESS</td>
                                  <td class="border border-top-0 border-bottom-0 border-right-0 border-dark"><b>{{ form.res_lot }}</b></td>
                                  <td class="border border-top-0 border-left-0 border-bottom-0 border-dark"><b>{{ form.res_street }}</b></td>
                                </tr>
                                <tr>
                                  <td class="border border-top-0 border-bottom-0 border-dark"></td>
                                  <td v-if="form.civil_status === 'Widowed'" class="border border-top-0 border-bottom-0 border-dark">&#9745; Widowed &squ; Separated</td>
                                  <td v-if="form.civil_status === 'Separated'" class="border border-top-0 border-bottom-0 border-dark">&squ; Widowed &#9745; Separated</td>
                                  <td v-if="form.civil_status === 'Single' || form.civil_status === 'Married'" class="border border-top-0 border-bottom-0 border-dark">&squ; Widowed &squ; Separated</td>
                                  <td class="border border-top-0 border-bottom-0 border-dark"></td>
                                  <td class="border border-top-0 border-right-0 border-dark"><small class="font-italic">House/Block/Lot No.</small></td>
                                  <td class="border border-top-0 border-left-0 border-dark"><small class="font-italic">Street</small></td>
                                </tr>
                                <tr>
                                  <td class="border border-top-0 border-bottom-0 border-dark"></td>
                                  <td class="border border-top-0 border-bottom-0 border-dark">&squ; Other/s:</td>
                                  <td class="border border-top-0 border-bottom-0 border-dark"></td>
                                  <td class="border border-top-0 border-right-0 border-bottom-0 border-dark"><b>{{ form.res_subdivision }}</b></td>
                                  <td class="border border-top-0 border-left-0 border-bottom-0 border-dark"><b>{{ form.res_barangay }}</b></td>
                                </tr>
                                <tr>
                                  <td class="border border-top-0 border-dark"></td>
                                  <td class="border border-top-0 border-dark"></td>
                                  <td class="border border-top-0 border-right-0 border-bottom-0 border-dark"></td>
                                  <td class="border border-top-0 border-right-0 border-dark"><small class="font-italic">Subdivision/Village</small></td>
                                  <td class="border border-top-0 border-left-0 border-dark"><small class="font-italic">Barangay</small></td>
                                </tr>
                                <tr>
                                  <td class="border border-top-0 border-bottom-0 border-dark">7. HEIGHT (m)</td>
                                  <td class="border border-top-0 border-bottom-0 border-dark">{{ form.height }}</td>
                                  <td class="border border-top-0 border-bottom-0 border-dark"></td>
                                  <td class="border border-top-0 border-bottom-0 border-right-0 border-dark"><b>{{ form.res_city }}</b></td>
                                  <td class="border border-top-0 border-left-0 border-bottom-0 border-dark"><b>{{ form.res_province }}</b></td>
                                </tr>
                                <tr>
                                  <td class="border border-top-0 border-bottom-0 border-dark"></td>
                                  <td class="border border-top-0 border-bottom-0 border-dark"></td>
                                  <td class="border border-top-0 border-bottom-0 border-dark"></td>
                                  <td class="border border-top-0 border-right-0 border-dark"><small class="font-italic">City/Municipality</small></td>
                                  <td class="border border-top-0 border-left-0 border-dark"><small class="font-italic">Province</small></td>
                                </tr>
                                <tr>
                                  <td class="border border-top-0 border-dark"></td>
                                  <td class="border border-top-0 border-dark"></td>
                                  <td class="border border-top-0 border-dark">ZIP CODE</td>
                                  <td colspan="2" class="border border-top-0 border-dark">{{ form.res_zip }}</td>
                                </tr>
                                 <tr>
                                  <td class="border border-top-0 border-bottom-0 border-dark">8. WEIGHT (kg)</td>
                                  <td class="border border-top-0 border-bottom-0 border-dark">{{ form.weight }}</td>
                                  <td class="border border-top-0 border-bottom-0 border-dark">18. PERMANENT ADDRESS</td>
                                  <td class="border border-top-0 border-bottom-0 border-right-0 border-dark"><b>{{ form.perm_lot }}</b></td>
                                  <td class="border border-top-0 border-left-0 border-bottom-0 border-dark"><b>{{ form.perm_street }}</b></td>
                                </tr>
                                <tr>
                                  <td class="border border-top-0 border-dark"></td>
                                  <td class="border border-top-0 border-dark"></td>
                                  <td class="border border-top-0 border-bottom-0 border-dark"></td>
                                  <td class="border border-top-0 border-right-0 border-dark"><small class="font-italic">House/Block/Lot No.</small></td>
                                  <td class="border border-top-0 border-left-0 border-dark"><small class="font-italic">Street</small></td>
                                </tr>
                                <tr>
                                  <td class="border border-top-0 border-bottom-0 border-dark">9. BLOOD TYPE</td>
                                  <td class="border border-top-0 border-bottom-0 border-dark">{{ form.blood_type }}</td>
                                  <td class="border border-top-0 border-bottom-0 border-dark"></td>
                                  <td class="border border-top-0 border-right-0 border-bottom-0 border-dark"><b>{{ form.perm_subdivision }}</b></td>
                                  <td class="border border-top-0 border-left-0 border-bottom-0 border-dark"><b>{{ form.perm_barangay }}</b></td>
                                </tr>
                                <tr>
                                  <td class="border border-top-0 border-dark"></td>
                                  <td class="border border-top-0 border-dark"></td>
                                  <td class="border border-top-0 border-bottom-0 border-dark"></td>
                                  <td class="border border-top-0 border-right-0 border-dark"><small class="font-italic">Subdivision/Village</small></td>
                                  <td class="border border-top-0 border-left-0 border-dark"><small class="font-italic">Barangay</small></td>
                                </tr>
                                <tr>
                                  <td class="border border-top-0 border-bottom-0 border-dark">10. GSIS ID NO.</td>
                                  <td class="border border-top-0 border-bottom-0 border-dark">{{ form.gsis }}</td>
                                  <td class="border border-top-0 border-bottom-0 border-dark"></td>
                                  <td class="border border-top-0 border-bottom-0 border-right-0 border-dark"><b>{{ form.perm_city }}</b></td>
                                  <td class="border border-top-0 border-left-0 border-bottom-0 border-dark"><b>{{ form.perm_province }}</b></td>
                                </tr>
                                <tr>
                                  <td class="border border-top-0 border-dark"></td>
                                  <td class="border border-top-0 border-dark"></td>
                                  <td class="border border-top-0 border-bottom-0 border-dark"></td>
                                  <td class="border border-top-0 border-right-0 border-dark"><small class="font-italic">City/Municipality</small></td>
                                  <td class="border border-top-0 border-left-0 border-dark"><small class="font-italic">Province</small></td>
                                </tr>
                                <tr>
                                  <td class="border border-top-0 border-dark">11. PAG-IBIG ID NO.</td>
                                  <td class="border border-top-0 border-dark">{{ form.pagibig }}</td>
                                  <td class="border border-top-0 border-bottom-0 border-dark"></td>
                                  <td colspan="2" class="border border-top-0 border-bottom-0 border-dark"></td>
                                </tr>
                                <tr>
                                  <td class="border border-top-0 border-dark">12. PHILHEALTH NO.</td>
                                  <td class="border border-top-0 border-dark">{{ form.philhealth }}</td>
                                  <td class="border border-top-0 border-dark">ZIP CODE</td>
                                  <td colspan="2" class="border border-top-0 border-dark">{{ form.perm_zip }}</td>
                                </tr>
                                <tr>
                                  <td class="border border-top-0 border-dark">13. SSS NO.</td>
                                  <td class="border border-top-0 border-dark">{{ form.sss }}</td>
                                  <td class="border border-top-0 border-dark">19. TELEPHONE NO.</td>
                                  <td colspan="2" class="border border-top-0 border-dark">{{ form.telephone_no }}</td>
                                </tr>
                                <tr>
                                  <td class="border border-top-0 border-dark">14. TIN NO.</td>
                                  <td class="border border-top-0 border-dark">{{ form.tin }}</td>
                                  <td class="border border-top-0 border-dark">20. MOBILE NO.</td>
                                  <td colspan="2" class="border border-top-0 border-dark">{{ form.mobile_no }}</td>
                                </tr>
                                <tr>
                                  <td class="border border-top-0 border-dark">15. AGENCY EMPLOYEE NO.</td>
                                  <td class="border border-top-0 border-dark">{{ form.employee_code }}</td>
                                  <td class="border border-top-0 border-dark">21. E-MAIL ADDRESS (if any)</td>
                                  <td colspan="2" class="border border-top-0 border-dark">{{ form.email }}</td>
                                </tr>
                                <tr>
                                  <td colspan="5" class="border border-dark bg-secondary font-italic font-weight-bold">&nbsp;&nbsp;&nbsp;&nbsp;II. FAMILY BACKGROUND</td>
                                </tr>
                              </table>
                              <table style="width:100%; border: 0;" cellspacing="0" cellpadding="0">
                                <tr>
                                  <td>
                                  <table  class="table table-borderless" style="margin-bottom: 0 !important;">
                                    <tr>
                                      <td class="border border-top-0 border-dark">22. SPOUSE'S SURNAME</td>
                                      <td colspan="2" class="border border-top-0 border-dark">{{ form.spouse_lastname }}</td>
                                    </tr>
                                    <tr>
                                      <td class="border border-top-0 border-dark">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;FIRST NAME</td>
                                      <td class="border border-top-0 border-dark">{{ form.spouse_firstname }}</td>
                                      <td class="border-right border-bottom border-dark" align="right"><small class="float-left" style="font-size:8px;line-height: 1;">NAME EXTENSION (JR., SR) </small>  {{ form.spouse_extension }}&nbsp;&nbsp;&nbsp;</td>
                                    </tr>
                                    <tr>
                                      <td class="border border-top-0 border-dark">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;MIDDLE NAME</td>
                                      <td colspan="2" class="border border-top-0 border-dark">{{ form.spouse_middlename }}</td>
                                    </tr>
                                    <tr>
                                      <td class="border border-top-0 border-dark">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;OCCUPATION</td>
                                      <td colspan="2" class="border border-top-0 border-dark">{{ form.spouse_occupation }}</td>
                                    </tr>
                                    <tr>
                                      <td class="border border-top-0 border-dark">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;EMPLOYER/BUSINESS NAME</td>
                                      <td colspan="2" class="border border-top-0 border-dark">{{ form.spouse_employer }}</td>
                                    </tr>
                                    <tr>
                                      <td class="border border-top-0 border-dark">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;BUSINESS ADDRESS</td>
                                      <td colspan="2" class="border border-top-0 border-dark">{{ form.spouse_business_address }}</td>
                                    </tr>
                                    <tr>
                                      <td class="border border-top-0 border-dark">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;TELEPHONE NO.</td>
                                      <td colspan="2" class="border border-top-0 border-dark">{{ form.spouse_telephone_no }}</td>
                                    </tr>
                                    <tr>
                                      <td class="border border-top-0 border-dark">22. FATHER'S SURNAME</td>
                                      <td colspan="2" class="border border-top-0 border-dark">{{ form.father_lastname }}</td>
                                    </tr>
                                    <tr>
                                      <td class="border border-top-0 border-dark">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;FIRST NAME</td>
                                      <td class="border border-top-0 border-dark">{{ form.father_firstname }}</td>
                                      <td class="border-right border-bottom border-dark" align="right"><small class="float-left" style="font-size:8px;line-height: 1;">NAME EXTENSION (JR., SR) </small>  {{ form.father_extension }}&nbsp;&nbsp;&nbsp;</td>
                                    </tr>
                                    <tr>
                                      <td class="border border-top-0 border-dark">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;MIDDLE NAME</td>
                                      <td colspan="2" class="border border-top-0 border-dark">{{ form.father_middlename }}</td>
                                    </tr>
                                    <tr>
                                      <td class="border border-top-0 border-dark">25. MOTHER'S MAIDEN NAME</td>
                                      <td colspan="2" class="border border-top-0 border-dark"></td>
                                    </tr>
                                    <tr>
                                      <td class="border border-top-0 border-dark">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;SURNAME</td>
                                      <td colspan="2" class="border border-top-0 border-dark">{{ form.mother_lastname }}</td>
                                    </tr>
                                    <tr>
                                      <td class="border border-top-0 border-dark">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;FIRST NAME</td>
                                      <td colspan="2" class="border border-top-0 border-dark">{{ form.mother_firstname }}</td>
                                    </tr>
                                    <tr>
                                      <td class="border border-top-0 border-dark">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;MIDDLE NAME</td>
                                      <td colspan="2" class="border border-top-0 border-dark">{{ form.mother_middlename }}</td>
                                    </tr>
                                  </table>
                                  </td>
                                  <td>
                                  <table class="table table-borderless" style="margin-bottom: 0 !important;">
                                    <tr>
                                      <td class="border border-top-0 border-left-0 border-dark">23. NAME of CHILDREN (Write full name and list all)</td>
                                      <td class="border border-top-0 border-dark">DATE OF BIRTH (mm/dd/yyyy)</td>
                                    </tr>
                                    <!--
                                    <tr v-for="child in children" :key="child.id">
                                      <td class="border border-top-0 border-left-0 border-dark">{{ child.child_name }}</td>
                                      <td class="border border-top-0 border-dark">{{ child.child_dob | dobformat }}</td>
                                    </tr>
                                    -->
                                    <tr>
                                      <td class="border border-top-0 border-left-0 border-dark">&nbsp;</td>
                                      <td class="border border-top-0 border-dark">&nbsp;</td>
                                    </tr>
                                    <tr>
                                      <td class="border border-top-0 border-left-0 border-dark">&nbsp;</td>
                                      <td class="border border-top-0 border-dark">&nbsp;</td>
                                    </tr>
                                    <tr>
                                      <td class="border border-top-0 border-left-0 border-dark">&nbsp;</td>
                                      <td class="border border-top-0 border-dark">&nbsp;</td>
                                    </tr>
                                    <tr>
                                      <td class="border border-top-0 border-left-0 border-dark">&nbsp;</td>
                                      <td class="border border-top-0 border-dark">&nbsp;</td>
                                    </tr>
                                    <tr>
                                      <td class="border border-top-0 border-left-0 border-dark">&nbsp;</td>
                                      <td class="border border-top-0 border-dark">&nbsp;</td>
                                    </tr>
                                    <tr>
                                      <td class="border border-top-0 border-left-0 border-dark">&nbsp;</td>
                                      <td class="border border-top-0 border-dark">&nbsp;</td>
                                    </tr>
                                    <tr>
                                      <td class="border border-top-0 border-left-0 border-dark">&nbsp;</td>
                                      <td class="border border-top-0 border-dark">&nbsp;</td>
                                    </tr>
                                    <tr>
                                      <td class="border border-top-0 border-left-0 border-dark">&nbsp;</td>
                                      <td class="border border-top-0 border-dark">&nbsp;</td>
                                    </tr>
                                    <tr>
                                      <td class="border border-top-0 border-left-0 border-dark">&nbsp;</td>
                                      <td class="border border-top-0 border-dark">&nbsp;</td>
                                    </tr>
                                    <tr>
                                      <td class="border border-top-0 border-left-0 border-dark">&nbsp;</td>
                                      <td class="border border-top-0 border-dark">&nbsp;</td>
                                    </tr>
                                    <tr>
                                      <td class="border border-top-0 border-left-0 border-dark">&nbsp;</td>
                                      <td class="border border-top-0 border-dark">&nbsp;</td>
                                    </tr>
                                    <tr>
                                      <td class="border border-top-0 border-left-0 border-dark">&nbsp;</td>
                                      <td class="border border-top-0 border-dark">&nbsp;</td>
                                    </tr>
                                    <tr>
                                      <td class="border border-top-0 border-left-0 border-dark">&nbsp;</td>
                                      <td class="border border-top-0 border-dark">&nbsp;</td>
                                    </tr>
                                  </table>
                                  </td>
                                </tr>
                              </table>
                              <table class="table table-borderless" style="width: 100%;">
                                <tr>
                                  <td colspan="8" class="border border-dark bg-secondary font-italic font-weight-bold">&nbsp;&nbsp;&nbsp;&nbsp;III. EDUCATIONAL BACKGROUND</td>
                                </tr>
                                <tr class="align-top" >
                                  <td class="border border-bottom-0 border-dark">26. LEVEL</td>
                                  <td class="border border-bottom-0 border-dark">NAME OF SCHOOL<br/>(Write in full)</td>
                                  <td class="border border-bottom-0 border-dark">BASIC EDUCATION/DEGREE/COURSE<br/>(Write in full)</td>
                                  <td class="border border-dark" colspan="2">PERIOD OF ATTENDANCE</td>
                                  <td class="border border-bottom-0 border-dark">HIGHEST<br/>LEVEL/UNITS<br/>EARNED (if not</td>
                                  <td class="border border-bottom-0 border-dark">YEAR<br/>GRADUATED</td>
                                  <td class="border border-bottom-0 border-dark">SCHOLARSHIP/ACADEMIC<br/>HONORS RECEIVED</td>
                                </tr>
                                <tr>
                                  <td class="border border-top-0 border-dark"></td>
                                  <td class="border border-top-0 border-dark"></td>
                                  <td class="border border-top-0 border-dark"></td>
                                  <td class="border border-top-0 border-dark">From</td>
                                  <td class="border border-top-0 border-dark">To</td>
                                  <td class="border border-top-0 border-dark">graduated)</td>
                                  <td class="border border-top-0 border-dark"></td>
                                  <td class="border border-top-0 border-dark"></td>
                                </tr>
                                <tr>
                                  <td class="border border-top-0 border-dark">ELEMENTARY</td>
                                  <td class="border border-top-0 border-dark"></td>
                                  <td class="border border-top-0 border-dark"></td>
                                  <td class="border border-top-0 border-dark"></td>
                                  <td class="border border-top-0 border-dark"></td>
                                  <td class="border border-top-0 border-dark"></td>
                                  <td class="border border-top-0 border-dark"></td>
                                  <td class="border border-top-0 border-dark"></td>
                                </tr>
                                <tr>
                                  <td class="border border-top-0 border-dark">SECONDARY</td>
                                  <td class="border border-top-0 border-dark"></td>
                                  <td class="border border-top-0 border-dark"></td>
                                  <td class="border border-top-0 border-dark"></td>
                                  <td class="border border-top-0 border-dark"></td>
                                  <td class="border border-top-0 border-dark"></td>
                                  <td class="border border-top-0 border-dark"></td>
                                  <td class="border border-top-0 border-dark"></td>
                                </tr>
                                <tr>
                                  <td class="border border-top-0 border-dark">VOCATIONAL / TRADE COURSE</td>
                                  <td class="border border-top-0 border-dark"></td>
                                  <td class="border border-top-0 border-dark"></td>
                                  <td class="border border-top-0 border-dark"></td>
                                  <td class="border border-top-0 border-dark"></td>
                                  <td class="border border-top-0 border-dark"></td>
                                  <td class="border border-top-0 border-dark"></td>
                                  <td class="border border-top-0 border-dark"></td>
                                </tr>
                                <tr>
                                  <td class="border border-top-0 border-dark">COLLEGE</td>
                                  <td class="border border-top-0 border-dark"></td>
                                  <td class="border border-top-0 border-dark"></td>
                                  <td class="border border-top-0 border-dark"></td>
                                  <td class="border border-top-0 border-dark"></td>
                                  <td class="border border-top-0 border-dark"></td>
                                  <td class="border border-top-0 border-dark"></td>
                                  <td class="border border-top-0 border-dark"></td>
                                </tr>
                                <tr>
                                  <td class="border border-top-0 border-dark">GRADUATE STUDIES</td>
                                  <td class="border border-top-0 border-dark"></td>
                                  <td class="border border-top-0 border-dark"></td>
                                  <td class="border border-top-0 border-dark"></td>
                                  <td class="border border-top-0 border-dark"></td>
                                  <td class="border border-top-0 border-dark"></td>
                                  <td class="border border-top-0 border-dark"></td>
                                  <td class="border border-top-0 border-dark"></td>
                                </tr>
                                <tr>
                                  <td colspan="8"  class="border border-top-0 border-dark"><i>(Continue on separate sheet if necessary)</i></td>
                                </tr>
                                <tr>
                                  <td class="border border-top-0 border-dark"><i><b>SIGNATURE</b></i></td>
                                  <td colspan="2" class="border border-top-0 border-dark"></td>
                                  <td colspan="2" class="border border-top-0 border-dark"><i><b>DATE</b></i></td>
                                  <td colspan="3" class="border border-top-0 border-dark"></td>

                                </tr>
                              </table>


                            </div>
                          </div>
                        </div>
                        </div>

                        <div class="modal-footer">
                          <!-- button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button -->
                          <button v-print="'#printPds'" type="submit" class="btn btn-default"><i class="fa fa-print"></i> PRINT</button>
                        </div>


                      </div>
                    </div>
                  </div>


                  <!-- Modal (General Information) -->
                  <div class="modal fade" id="generalmodal" tabindex="-1" role="dialog" aria-labelledby="generalModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 class="modal-title" id="generalModalLabel">Update General Information</h5>
                          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>

                        <form @submit.prevent="updateMyProfile()" @keydown="form.onKeydown($event)">

                        <div class="modal-body">
                        <div class="row">


                            <div class="col-md-6">
                                <!-- Your first column here -->
                                <div class="form-group">
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text">PLACE OF BIRTH</span>
                                    </div>
                                    <input v-model="form.pob" type="text" name="pob" autocomplete="off" placeholder="PLACE OF BIRTH.." class="form-control" :class="{ 'is-invalid': form.errors.has('pob') }">
                                    <has-error :form="form" field="pob"></has-error>
                                  </div>
                                </div>

                                <div class="form-group">
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text">GENDER</span>
                                    </div>
                                    <input v-model="form.gender" type="text" name="gender" autocomplete="off" placeholder="GENDER.." class="form-control" :class="{ 'is-invalid': form.errors.has('gender') }">
                                    <has-error :form="form" field="gender"></has-error>
                                  </div>
                                </div>

                                <div class="form-group">
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text">CIVIL STATUS</span>
                                    </div>
                                    <select v-model="form.civil_status" name="type" class="form-control" :class="{ 'is-invalid': form.errors.has('civil_status') }">
                                      <option value="">- Select Civil Status -</option>
                                      <option value="Single">Single</option>
                                      <option value="Married">Married</option>
                                    </select>
                                    <has-error :form="form" field="civil_status"></has-error>
                                  </div>
                                </div>

                                <div class="form-group">
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text">CITIZENSHIP</span>
                                    </div>
                                    <input v-model="form.citizenship" type="text" name="citizenship" autocomplete="off" placeholder="CITIZENSHIP.." class="form-control" :class="{ 'is-invalid': form.errors.has('citizenship') }">
                                    <has-error :form="form" field="citizenship"></has-error>
                                  </div>
                                </div>

                                <div class="form-group">
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text">HEIGHT (m)</span>
                                    </div>
                                    <input v-model="form.height" type="text" name="height" autocomplete="off" placeholder="HEIGHT.." class="form-control" :class="{ 'is-invalid': form.errors.has('height') }">
                                    <has-error :form="form" field="height"></has-error>
                                  </div>
                                </div>

                                <div class="form-group">
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text">WEIGHT (kg)</span>
                                    </div>
                                    <input v-model="form.weight" type="text" name="weight" autocomplete="off" placeholder="WEIGHT.." class="form-control" :class="{ 'is-invalid': form.errors.has('weight') }">
                                    <has-error :form="form" field="weight"></has-error>
                                  </div>
                                </div>

                                <div class="form-group">
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text">BLOOD TYPE</span>
                                    </div>
                                    <input v-model="form.blood_type" type="text" name="blood_type" autocomplete="off" placeholder="BLOOD TYPE.." class="form-control" :class="{ 'is-invalid': form.errors.has('blood_type') }">
                                    <has-error :form="form" field="blood_type"></has-error>
                                  </div>
                                </div>

                            </div>


                            <div class="col-md-6">
                                <!-- Your second column here -->

                                <div class="form-group">
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text">TELEPHONE NO.</span>
                                    </div>
                                    <input v-model="form.telephone_no" type="text" name="telephone_no" autocomplete="off" placeholder="TELEPHONE NO.." class="form-control" :class="{ 'is-invalid': form.errors.has('telephone_no') }">
                                    <has-error :form="form" field="telephone_no"></has-error>
                                  </div>
                                </div>

                                <div class="form-group">
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text">MOBILE NO.</span>
                                    </div>
                                    <input v-model="form.mobile_no" type="text" name="mobile_no" autocomplete="off" placeholder="MOBILE NO.." class="form-control" :class="{ 'is-invalid': form.errors.has('mobile_no') }">
                                    <has-error :form="form" field="mobile_no"></has-error>
                                  </div>
                                </div>

                                <div class="form-group">
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text">GSIS ID NO.</span>
                                    </div>
                                    <input v-model="form.gsis" type="text" name="gsis" autocomplete="off" placeholder="GSIS ID NO.." class="form-control" :class="{ 'is-invalid': form.errors.has('gsis') }">
                                    <has-error :form="form" field="gsis"></has-error>
                                  </div>
                                </div>

                                <div class="form-group">
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text">PAG-IBIG ID NO.</span>
                                    </div>
                                    <input v-model="form.pagibig" type="text" name="pagibig" autocomplete="off" placeholder="PAG-IBIG ID NO.." class="form-control" :class="{ 'is-invalid': form.errors.has('pagibig') }">
                                    <has-error :form="form" field="pagibig"></has-error>
                                  </div>
                                </div>

                                <div class="form-group">
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text">PHILHEALTH NO.</span>
                                    </div>
                                    <input v-model="form.philhealth" type="text" name="philhealth" autocomplete="off" placeholder="PHILHEALTH NO.." class="form-control" :class="{ 'is-invalid': form.errors.has('philhealth') }">
                                    <has-error :form="form" field="philhealth"></has-error>
                                  </div>
                                </div>

                                <div class="form-group">
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text">SSS NO.</span>
                                    </div>
                                    <input v-model="form.sss" type="text" name="sss" autocomplete="off" placeholder="SSS NO.." class="form-control" :class="{ 'is-invalid': form.errors.has('sss') }">
                                    <has-error :form="form" field="sss"></has-error>
                                  </div>
                                </div>

                                <div class="form-group">
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text">TIN NO.</span>
                                    </div>
                                    <input v-model="form.tin" type="text" name="tin" autocomplete="off" placeholder="TIN NO.." class="form-control" :class="{ 'is-invalid': form.errors.has('tin') }">
                                    <has-error :form="form" field="tin"></has-error>
                                  </div>
                                </div>

                            </div>

                        </div>
                        </div>
                        <div class="modal-footer">
                          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                          <button type="submit" class="btn btn-primary">Save</button>
                        </div>

                        </form>

                      </div>
                    </div>
                  </div>

                  <!-- Modal (Address) -->
                  <div class="modal fade" id="addressmodal" tabindex="-1" role="dialog" aria-labelledby="addressModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 class="modal-title" id="addressModalLabel">Update Address</h5>
                          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <form @submit.prevent="updateMyProfile()" @keydown="form.onKeydown($event)">

                        <div class="modal-body">
                        <div class="row">

                            <div class="col-md-6">
                                <!-- Your first column here -->
                                <b>RESIDENTIAL ADDRESS</b><br/><br/>
                                <div class="form-group">
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text">House / Block / Lot No:</span>
                                    </div>
                                    <input v-model="form.res_lot" type="text" name="res_lot" autocomplete="off" placeholder="House / Block / Lot No.." class="form-control" :class="{ 'is-invalid': form.errors.has('res_lot') }">
                                    <has-error :form="form" field="res_lot"></has-error>
                                  </div>
                                </div>

                                <div class="form-group">
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text">Street</span>
                                    </div>
                                    <input v-model="form.res_street" type="text" name="res_street" autocomplete="off" placeholder="Street.." class="form-control" :class="{ 'is-invalid': form.errors.has('res_street') }">
                                    <has-error :form="form" field="res_street"></has-error>
                                  </div>
                                </div>

                                <div class="form-group">
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text">Subdivision / Village</span>
                                    </div>
                                    <input v-model="form.res_subdivision" type="text" name="res_subdivision" autocomplete="off" placeholder="Subdivision / Village.." class="form-control" :class="{ 'is-invalid': form.errors.has('res_subdivision') }">
                                    <has-error :form="form" field="res_subdivision"></has-error>
                                  </div>
                                </div>

                                <div class="form-group">
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text">Barangay</span>
                                    </div>
                                    <input v-model="form.res_barangay" type="text" name="res_barangay" autocomplete="off" placeholder="Barangay.." class="form-control" :class="{ 'is-invalid': form.errors.has('res_barangay') }">
                                    <has-error :form="form" field="res_barangay"></has-error>
                                  </div>
                                </div>

                                <div class="form-group">
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text">City / Municipality</span>
                                    </div>
                                    <input v-model="form.res_city" type="text" name="res_city" autocomplete="off" placeholder="City / Municipality.." class="form-control" :class="{ 'is-invalid': form.errors.has('res_city') }">
                                    <has-error :form="form" field="res_city"></has-error>
                                  </div>
                                </div>

                                <div class="form-group">
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text">Province</span>
                                    </div>
                                    <input v-model="form.res_province" type="text" name="res_province" autocomplete="off" placeholder="Province.." class="form-control" :class="{ 'is-invalid': form.errors.has('res_province') }">
                                    <has-error :form="form" field="res_province"></has-error>
                                  </div>
                                </div>

                                <div class="form-group">
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text">ZIP Code</span>
                                    </div>
                                    <input v-model="form.res_zip" type="text" name="res_zip" autocomplete="off" placeholder="ZIP Code.." class="form-control" :class="{ 'is-invalid': form.errors.has('res_zip') }">
                                    <has-error :form="form" field="res_zip"></has-error>
                                  </div>
                                </div>

                            </div>


                            <div class="col-md-6">
                                <!-- Your second column here -->
                                <b>PERMANENT ADDRESS</b><br/><br/>
                                <div class="form-group">
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text">House / Block / Lot No:</span>
                                    </div>
                                    <input v-model="form.perm_lot" type="text" name="perm_lot" autocomplete="off" placeholder="House / Block / Lot No.." class="form-control" :class="{ 'is-invalid': form.errors.has('perm_lot') }">
                                    <has-error :form="form" field="perm_lot"></has-error>
                                  </div>
                                </div>

                                <div class="form-group">
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text">Street</span>
                                    </div>
                                    <input v-model="form.perm_street" type="text" name="perm_street" autocomplete="off" placeholder="Street.." class="form-control" :class="{ 'is-invalid': form.errors.has('perm_street') }">
                                    <has-error :form="form" field="perm_street"></has-error>
                                  </div>
                                </div>

                                <div class="form-group">
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text">Subdivision / Village</span>
                                    </div>
                                    <input v-model="form.perm_subdivision" type="text" name="perm_subdivision" autocomplete="off" placeholder="Subdivision / Village.." class="form-control" :class="{ 'is-invalid': form.errors.has('perm_subdivision') }">
                                    <has-error :form="form" field="perm_subdivision"></has-error>
                                  </div>
                                </div>

                                <div class="form-group">
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text">Barangay</span>
                                    </div>
                                    <input v-model="form.perm_barangay" type="text" name="perm_barangay" autocomplete="off" placeholder="Barangay.." class="form-control" :class="{ 'is-invalid': form.errors.has('perm_barangay') }">
                                    <has-error :form="form" field="perm_barangay"></has-error>
                                  </div>
                                </div>

                                <div class="form-group">
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text">City / Municipality</span>
                                    </div>
                                    <input v-model="form.perm_city" type="text" name="perm_city" autocomplete="off" placeholder="City / Municipality.." class="form-control" :class="{ 'is-invalid': form.errors.has('perm_city') }">
                                    <has-error :form="form" field="perm_city"></has-error>
                                  </div>
                                </div>

                                <div class="form-group">
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text">Province</span>
                                    </div>
                                    <input v-model="form.perm_province" type="text" name="perm_province" autocomplete="off" placeholder="Province.." class="form-control" :class="{ 'is-invalid': form.errors.has('perm_province') }">
                                    <has-error :form="form" field="perm_province"></has-error>
                                  </div>
                                </div>

                                <div class="form-group">
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text">ZIP Code</span>
                                    </div>
                                    <input v-model="form.perm_zip" type="text" name="perm_zip" autocomplete="off" placeholder="ZIP Code.." class="form-control" :class="{ 'is-invalid': form.errors.has('perm_zip') }">
                                    <has-error :form="form" field="perm_zip"></has-error>
                                  </div>
                                </div>

                            </div>

                        </div>
                        </div>
                        <div class="modal-footer">
                          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                          <button type="submit" class="btn btn-primary">Save</button>
                        </div>

                        </form>

                      </div>
                    </div>
                  </div>

                  <!-- Modal (Family) -->
                  <div class="modal fade" id="familymodal" tabindex="-1" role="dialog" aria-labelledby="familyModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                      <div class="modal-content">
                        <div class="modal-header">
                          <!--<h5 v-show="!editmode" class="modal-title" id="generalModalLabel">Create New Bank Information</h5>
                          <h5 v-show="editmode" class="modal-title" id="bankModalLabel">Update Bank Information</h5>
                          -->
                          <h5 class="modal-title" id="familyModalLabel">Update Family Information</h5>
                          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>

                        <!--
                        <form @submit.prevent="editmode ? updateGeneral() : createBank()" @keydown="form.onKeydown($event)">
                          -->
                        <form @submit.prevent="updateMyProfile()" @keydown="form.onKeydown($event)">

                        <div class="modal-body">
                        <div class="row">


                            <div class="col-md-6">
                                <!-- Your first column here -->
                                <b>FATHER'S NAME</b><br/><br/>
                                <div class="form-group">
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text">Surname</span>
                                    </div>
                                    <input v-model="form.father_lastname" type="text" name="father_lastname" autocomplete="off" placeholder="Surname.." class="form-control" :class="{ 'is-invalid': form.errors.has('father_lastname') }">
                                    <has-error :form="form" field="father_lastname"></has-error>
                                  </div>
                                </div>

                                <div class="form-group">
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text">First Name</span>
                                    </div>
                                    <input v-model="form.father_firstname" type="text" name="father_firstname" autocomplete="off" placeholder="First Name.." class="form-control" :class="{ 'is-invalid': form.errors.has('father_firstname') }">
                                    <has-error :form="form" field="father_firstname"></has-error>
                                  </div>
                                </div>

                                <div class="form-group">
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text">Middle Name</span>
                                    </div>
                                    <input v-model="form.father_middlename" type="text" name="father_middlename" autocomplete="off" placeholder="Middle Name.." class="form-control" :class="{ 'is-invalid': form.errors.has('father_middlename') }">
                                    <has-error :form="form" field="father_middlename"></has-error>
                                  </div>
                                </div>

                                <div class="form-group">
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text">Name Extension (Jr., Sr)</span>
                                    </div>
                                    <input v-model="form.father_extension" type="text" name="father_extension" autocomplete="off" placeholder="Name Extension (Jr., Sr).." class="form-control" :class="{ 'is-invalid': form.errors.has('father_extension') }">
                                    <has-error :form="form" field="father_extension"></has-error>
                                  </div>
                                </div>

                                <br/><br/><b>SPOUSE'S NAME</b><br/><br/>
                                <div class="form-group">
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text">Surname</span>
                                    </div>
                                    <input v-model="form.spouse_lastname" type="text" name="spouse_lastname" autocomplete="off" placeholder="Surname.." class="form-control" :class="{ 'is-invalid': form.errors.has('spouse_lastname') }">
                                    <has-error :form="form" field="spouse_lastname"></has-error>
                                  </div>
                                </div>

                                <div class="form-group">
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text">First Name</span>
                                    </div>
                                    <input v-model="form.spouse_firstname" type="text" name="spouse_firstname" autocomplete="off" placeholder="First Name.." class="form-control" :class="{ 'is-invalid': form.errors.has('spouse_firstname') }">
                                    <has-error :form="form" field="spouse_firstname"></has-error>
                                  </div>
                                </div>

                                <div class="form-group">
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text">Middle Name</span>
                                    </div>
                                    <input v-model="form.spouse_middlename" type="text" name="spouse_middlename" autocomplete="off" placeholder="Middle Name.." class="form-control" :class="{ 'is-invalid': form.errors.has('spouse_middlename') }">
                                    <has-error :form="form" field="spouse_middlename"></has-error>
                                  </div>
                                </div>

                                <div class="form-group">
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text">Name Extension (Jr., Sr)</span>
                                    </div>
                                    <input v-model="form.spouse_extension" type="text" name="spouse_extension" autocomplete="off" placeholder="Name Extension (Jr., Sr).." class="form-control" :class="{ 'is-invalid': form.errors.has('spouse_extension') }">
                                    <has-error :form="form" field="spouse_extension"></has-error>
                                  </div>
                                </div>

                            </div>


                            <div class="col-md-6">
                                <!-- Your second column here -->
                                <b>MOTHER'S MAIDEN NAME</b><br/><br/>
                                <div class="form-group">
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text">Surname</span>
                                    </div>
                                    <input v-model="form.mother_lastname" type="text" name="mother_lastname" autocomplete="off" placeholder="Surname.." class="form-control" :class="{ 'is-invalid': form.errors.has('mother_lastname') }">
                                    <has-error :form="form" field="mother_lastname"></has-error>
                                  </div>
                                </div>

                                <div class="form-group">
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text">First Name</span>
                                    </div>
                                    <input v-model="form.mother_firstname" type="text" name="mother_firstname" autocomplete="off" placeholder="First Name.." class="form-control" :class="{ 'is-invalid': form.errors.has('mother_firstname') }">
                                    <has-error :form="form" field="mother_firstname"></has-error>
                                  </div>
                                </div>

                                <div class="form-group">
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text">Middle Name</span>
                                    </div>
                                    <input v-model="form.mother_middlename" type="text" name="mother_middlename" autocomplete="off" placeholder="Middle Name.." class="form-control" :class="{ 'is-invalid': form.errors.has('mother_middlename') }">
                                    <has-error :form="form" field="mother_middlename"></has-error>
                                  </div>
                                </div>

                                <div class="form-group">
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      &nbsp;
                                    </div>
                                  </div>
                                </div>

                                <br/><br/><b><br/></b><br/><br/>
                                <div class="form-group">
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text">Occupation</span>
                                    </div>
                                    <input v-model="form.spouse_occupation" type="text" name="spouse_occupation" autocomplete="off" placeholder="Occupation.." class="form-control" :class="{ 'is-invalid': form.errors.has('spouse_occupation') }">
                                    <has-error :form="form" field="spouse_occupation"></has-error>
                                  </div>
                                </div>

                                <div class="form-group">
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text">Employer/Business Name</span>
                                    </div>
                                    <input v-model="form.spouse_employer" type="text" name="spouse_employer" autocomplete="off" placeholder="Employer/Business Name.." class="form-control" :class="{ 'is-invalid': form.errors.has('spouse_employer') }">
                                    <has-error :form="form" field="spouse_employer"></has-error>
                                  </div>
                                </div>

                                <div class="form-group">
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text">Business Address</span>
                                    </div>
                                    <input v-model="form.spouse_business_address" type="text" name="spouse_business_address" autocomplete="off" placeholder="Business Address.." class="form-control" :class="{ 'is-invalid': form.errors.has('spouse_business_address') }">
                                    <has-error :form="form" field="spouse_business_address"></has-error>
                                  </div>
                                </div>

                                <div class="form-group">
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text">Telephone No</span>
                                    </div>
                                    <input v-model="form.spouse_telephone_no" type="text" name="spouse_telephone_no" autocomplete="off" placeholder="Telephone No.." class="form-control" :class="{ 'is-invalid': form.errors.has('spouse_telephone_no') }">
                                    <has-error :form="form" field="spouse_telephone_no"></has-error>
                                  </div>
                                </div>


                            </div>



                        </div>
                        </div>
                        <div class="modal-footer">
                          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                          <!--
                          <button v-show="editmode" type="submit" class="btn btn-success">Update</button>
                          <button v-show="!editmode" type="submit" class="btn btn-primary">Create</button>
                          -->
                          <button type="submit" class="btn btn-primary">Save</button>
                        </div>

                        </form>

                      </div>
                    </div>
                  </div>

                  <!-- Modal (Children) -->
                  <div class="modal fade" id="childrenmodal" tabindex="-1" role="dialog" aria-labelledby="childrenModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5 v-show="!editmode" class="modal-title" id="childrenModalLabel">Create New Child</h5>
                          <h5 v-show="editmode" class="modal-title" id="childrenModalLabel">Update Child Information</h5>
                          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>

                        <form @submit.prevent="editmode ? updateChildren() : createChildren()" @keydown="form.onKeydown($event)">

                        <div class="modal-body">
                        <div class="row">


                            <div class="col-md-6">
                                <!-- Your first column here -->
                                <div class="form-group">
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text">NAME</span>
                                    </div>
                                    <input v-model="form_child.child_name" type="text" name="child_name" autocomplete="off" placeholder="NAME.." class="form-control" :class="{ 'is-invalid': form_child.errors.has('child_name') }">
                                    <has-error :form="form_child" field="child_name"></has-error>
                                  </div>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <!-- Your second column here -->
                                <div class="form-group">
                                  <div class="input-group">
                                    <div class="input-group-prepend">
                                      <span class="input-group-text">DATE OF BIRTH</span>
                                    </div>
                                    <input v-model="form_child.child_dob" type="text" name="child_dob" autocomplete="off" placeholder="DATE OF BIRTH.." class="form-control" :class="{ 'is-invalid': form_child.errors.has('child_dob') }">
                                    <has-error :form="form_child" field="child_dob"></has-error>
                                  </div>
                                </div>
                            </div>



                        </div>
                        </div>
                        <div class="modal-footer">
                          <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>

                          <button v-show="editmode" type="submit" class="btn btn-success">Update</button>
                          <button v-show="!editmode" type="submit" class="btn btn-primary">Create</button>

                        </div>

                        </form>

                      </div>
                    </div>
                  </div>

        </div>
</template>

<script>
    import Form from 'vform';

    export default {

        data()  {
          return {
            timer: 30,
            editmode: false,
            children : { },
            pds: {
              divisions : [],
              children : [],
              educates : [],
              examinations : [],
              experiences : [],
              voluntaries : [],
              trainings : [],
              skills : [],
              references : [],
            },
            form: new Form({
              id: '',
              fname: '',
              mname: '',
              lname: '',
              sname: '',
              position: '',
              role: '',
              password: '',
              photo: '',

              employee_code: '',
              biometric_id: '',
              username: '',
              email: '',
              eligibility: '',
              plantilla: '',
              sg: '',
              appointment: '',
              nature: '',
              hired: '',
              promotion: '',
              acct_status: '',
              salary: '',

              gender: '',
              dob: '',
              pob: '',
              civil_status: '',
              citizenship: '',
              height: '',
              weight: '',
              blood_type: '',
              telephone_no: '',
              mobile_no: '',
              gsis: '',
              pagibig: '',
              sss: '',
              philhealth: '',
              tin: '',

              res_lot: '',
              res_street: '',
              res_subdivision: '',
              res_barangay: '',
              res_city: '',
              res_province: '',
              res_zip: '',

              perm_lot: '',
              perm_street: '',
              perm_subdivision: '',
              perm_barangay: '',
              perm_city: '',
              perm_province: '',
              perm_zip: '',

              spouse_lastname: '',
              spouse_firstname: '',
              spouse_middlename: '',
              spouse_extension: '',
              spouse_occupation: '',
              spouse_employer: '',
              spouse_business_address: '',
              spouse_telephone_no: '',

              father_lastname: '',
              father_firstname: '',
              father_middlename: '',
              father_extension: '',

              mother_lastname: '',
              mother_firstname: '',
              mother_middlename: '',

              divisions : [],
              children : [],
              educates : [],
              examinations : [],
              experiences : [],
              voluntaries : [],
              trainings : [],
              skills : [],
              references : [],
            }),
            form_child: new Form({
              id: '',
              user_id: '',
              child_name: '',
              child_dob: '',
            })
          }
        },

        mounted() {
            //console.log('Component mounted.')

        },

        methods: {

          countDownTimer() {
            let timerId = setInterval(() => {
              this.timer -= 1;
              if (!this.$store.state.idleVue.isIdle) clearInterval(timerId);

              if (this.timer < 1) {
                clearInterval(timerId);
                // Your logout function should be over here
                document.getElementById('logout-form').submit();
              }
            }, 1000);

            this.timer = 30;
          },

          getProfilePhoto() {
            let photo = (this.form.photo.length > 200) ? this.form.photo : "img/profile/" + this.form.photo;
            //console.log(photo);
            return photo;
          },

          pdsModal() {
            $('#pdsmodal1').modal('show');
          },
          generalModal() {
            $('#generalmodal').modal('show');
          },
          addressModal() {
            $('#addressmodal').modal('show');
          },
          familyModal() {
            $('#familymodal').modal('show');
          },
          createChildrenModal() {
            this.editmode = false;
            this.form_child.reset();
            this.form_child.clear();
            this.form_child.user_id = this.$gate.showMyID();
            $('#childrenmodal').modal('show');
          },
          editChildrenModal(child) {
            this.editmode = true;
            this.form_child.fill(child);
            $('#childrenmodal').modal('show');
          },
          educationModal() {
            $('#educationmodal').modal('show');
          },
          examinationModal() {
            $('#examinationmodal').modal('show');
          },
          experienceModal() {
            $('#experiencemodal').modal('show');
          },
          voluntaryModal() {
            $('#voluntarymodal').modal('show');
          },
          trainingModal() {
            $('#trainingmodal').modal('show');
          },
          otherModal() {
            $('#othermodal').modal('show');
          },
          referenceModal() {
            //this.form.reset();
            //this.form.clear();
            //this.loadProfile();
            $('#referencemodal').modal('show');
          },

          createChildren() {
            this.$Progress.start();
            this.form_child.post('api/children')
            .then(()=>{
              Trigger.$emit('RefreshData');
              $('#childrenmodal').modal('hide')
              toastr.fire({
                icon: 'success',
                title: 'Family info created successfully'
              })
              this.$Progress.finish();
            }).catch(()=>{
              toastr.fire({
                icon: 'error',
                title: 'Family info creation failed'
              });
              this.$Progress.fail();
            })
          },

          updateChildren() {
            this.$Progress.start();
            this.form_child.put('api/children/'+this.form_child.id).then(()=>{
              Trigger.$emit('RefreshData');
              $('#childrenmodal').modal('hide')
              toastr.fire({
                icon: 'success',
                title: 'Family info updated successfully'
              })
              this.$Progress.finish();
            }).catch(()=>{
              toastr.fire({
                icon: 'error',
                title: 'Family info updating failed'
              });
              this.$Progress.fail();
            });
          },

          deleteChildren(id) {
            swal.fire({
              title: 'Are you sure?',
              text: "You won't be able to revert this!",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes, delete family record!'
              }).then((result)=>{
                if (result.value) {
                  this.form.delete('api/children/'+id).then(()=>{
                    swal.fire(
                      'Deleted!',
                      'Family record has been deleted.',
                      'success'
                    );
                    Trigger.$emit('RefreshData');
                  }).catch(()=>{
                    swal.fire(
                      'Failed!',
                      'There was something wrong',
                      'warning'
                    )
                  });
                }
            })
          },

          updateMyProfile() {
            this.$Progress.start();
            this.form.put('api/profile/update/').then(()=>{
              $('#generalmodal').modal('hide')
              $('#addressmodal').modal('hide')
              $('#familymodal').modal('hide')
              toastr.fire({
                icon: 'success',
                title: 'Profile Info Saved'
              })
              this.$Progress.finish();
              Trigger.$emit('RefreshData');
              this.loadProfile();
            }).catch(()=>{
              toastr.fire({
                icon: 'error',
                title: 'Profile Info Saving failed'
              });
              this.$Progress.fail();
            })
          },

          updateInfo() {
            this.$Progress.start();
            this.form.put('api/profile/')
            .then(()=>{
              Trigger.$emit('RefreshData');
              this.$Progress.finish();
            })
            .catch(()=>{
              this.$Progress.fail();
            });
          },

          updateProfile(e) {
            let file = e.target.files[0];
            console.log(file);
            let reader = new FileReader();

            if(file['size'] < 2111775){
                reader.onloadend = (file) => {
                  this.form.photo = reader.result;
                  //console.log('RESULT', reader.result)
                }
                reader.readAsDataURL(file);
            }else{
                swal.fire(
                  'Failed!',
                  'Image is too large (minimum of 2MB)',
                  'error'
                )
            }
          },

          loadProfile() {
            axios.get("api/users/pds/" + this.$gate.showMyID()).then(({ data }) => (this.form.fill(data)));
            axios.get("api/users/pds/" + this.$gate.showMyID()).then(({ data }) => (this.pds = data));
            axios.get("api/users/pds/" + this.$gate.showMyID()).then(({ data }) => (this.children = data.children));
          },


        },

        computed: {

          second() {
            return this.time / 1000;
          },

          isIdle() {
            const idlestat =  this.$store.state.idleVue.isIdle;
            if (idlestat == true) {
              $('#sessionmodal').modal('show');
              this.countDownTimer();
            } else {
              $('#sessionmodal').modal('hide');
            }

            return idlestat;

          },

        },

        created() {
          this.$Progress.start();
          axios.get("api/users/pds/" + this.$gate.showMyID()).then(({ data }) => (this.form.fill(data)));
          axios.get("api/users/pds/" + this.$gate.showMyID()).then(({ data }) => (this.pds = data));
          axios.get("api/users/pds/" + this.$gate.showMyID()).then(({ data }) => (this.children = data.children));

          this.$Progress.finish();

          Trigger.$on('RefreshData',() => {
            this.loadProfile();
          });
        }
    }
</script>
