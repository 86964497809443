<template>
    <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card card-secondary card-outline">
              <div class="card-header">
                <h3 class="card-title"><i class="fas fa-book"></i> Project Procurement Mgmt Plan (PPMP) - Administration</h3>
  
                <div class="card-tools">
                  <div>
                    <button v-show="can_add"  class="btn btn-success btn-sm" @click="newModal"><i class="fa fa-user-plus"></i></button>
                  </div>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="row m-0 bg-secondary disabled color-palette">
                <div class="col-md-4">

                </div>
                <div class="col-md-6 mt-2 mb-2">
                  
                </div>
                <div class="col-md-2">
                  <div class="input-group input-group-md mt-2 mb-2 d-flex justify-content-end">
                    <div class="input-group-prepend">
                      <span class="input-group-text">PPMP YR</span>
                    </div>
                    <select @change="loadPpmps" name="type" v-model="year" class="form-control">
                        <option v-for="year in years" :value="year" :key="year">{{ year }}</option>
                    </select>
                  </div>
                </div>
              </div>
  
              <div class="card-body table-responsive p-0 m-0">
                <table class="table responsive-table table-sm table-striped">
                  <thead>
                    <tr>
                      <th scope="col" class="pl-2">Office/Division/Section</th>
                      <th scope="col">Status</th>
                      <th scope="col">Date</th>
                      <th scope="col">Remarks</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="ofc in ppmps.data" :key="ofc.id">
                      <td v-if="ofc.parent_id == 0" scope="row" class="pl-2"><a style="cursor: pointer;" class="text-primary font-weight-bold" v-if="ofc.ppmps && ofc.ppmps.status !== 0" @click="showModal(ofc.ppmps, ofc.name, ofc.ppmps.status)">{{ ofc.name }}</a><span v-else>{{ ofc.name }}</span></td>
                      <td v-else scope="row" class="pl-2"><b>→ </b><a style="cursor: pointer;" class="text-primary font-weight-bold" v-if="ofc.ppmps && ofc.ppmps.status !== 0" @click="showModal(ofc.ppmps, ofc.name, ofc.ppmps.status)">{{ ofc.name }}</a><span v-else>{{ ofc.name }}</span></td>
  
                      <td v-if="ofc.ppmps" data-title="Status">
                        <span v-if="(ofc.ppmps.status == 0)" class="badge badge-info">In-Preparation</span>
                        <span v-else-if="(ofc.ppmps.status == 1)" class="badge badge-warning">Revision</span>
                        <span v-else-if="(ofc.ppmps.status == 2)" class="badge badge-primary">Pending</span>
                        <span v-else-if="(ofc.ppmps.status == 3)" class="badge badge-success">Approved</span>
                        <span v-else class="badge badge-secondary">No Submission</span>
                      </td>
                      <td v-else data-title="Status"><span class="badge badge-secondary">No Submission</span></td>
  
                      <td v-if="ofc.ppmps" data-title="Date">{{ ofc.ppmps.date }}</td>
                      <td v-else data-title="Date">-</td>
  
                      <td v-if="ofc.ppmps" data-title="Remarks">{{ ofc.ppmps.remarks }}</td>
                      <td v-else data-title="Remarks"></td>
  
                      <td data-title="Options">
                          <button v-if="ofc.ppmps" v-show="ofc.ppmps.status === 2 || ofc.ppmps.status === 3" class="btn btn-sm btn-primary"  type="button" @click="changeStatus(ofc.ppmps.id, 1, 'This will change the status of the PPMP to Revise. Do you really want to proceed?', 1)"><i class="fa fa-undo"></i> REVISE</button>
                          <button v-if="ofc.ppmps" v-show="ofc.ppmps.status === 2" class="btn btn-sm btn-success"  type="button" @click="changeStatus(ofc.ppmps.id, 3, 'This will change the status of the PPMP to Approve. Do you really want to proceed?', 0)"><i class="fa fa-check"></i> APPROVE</button>
                      </td>
                      
  
                    </tr>
                  </tbody>
                  <tbody v-show="ppmps.total==0">
                      <tr><td colspan="10">No procurement items categories found.</td></tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->

            </div>
            <!-- /.card -->
          </div>
        </div>
  
        <!-- Modal -->
        <div class="modal fade" id="ppmpitemsmodal" tabindex="-1" role="dialog" aria-labelledby="PpmpitemsModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <div class="col-md-6">
                  <div class="d-flex justify-content-start">
                    <h5 class="modal-title" id="PpmpitemsModalLabel">Office: {{ this.office  }}</h5> 
                    <span v-if="ppmpitemx.ppmpitems" class="badge badge-secondary ml-3 mr-3"><i>No. of Items:</i><b> {{ ppmpitemx.ppmpitems.length }}</b></span>
                    <span v-if="ppmpitemx.ppmpitems" class="badge badge-secondary mr-3"><i>Total Amount:</i><b> {{ this.total_expenses | toMoney }}</b></span>
                    <span v-if="(this.status == 0)" class="badge badge-info"><i>Status:</i> <b>In-Preparation</b></span>
                    <span v-else-if="(this.status == 1)" class="badge badge-warning"><i>Status:</i> <b>Revision</b></span>
                    <span v-else-if="(this.status == 2)" class="badge badge-primary"><i>Status:</i> <b>Pending</b></span>
                    <span v-else-if="(this.status == 3)" class="badge badge-success"><i>Status:</i> <b>Approved</b></span>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="d-flex justify-content-end">
                    <download-excel class="btn btn-success btn-sm ml-3" data-container="body" :data="ppmpitemx.ppmpitems" :fields="excel_fields" :name="file_name" :header="excel_header"><i class="fa fa-file-excel"></i> Export to Excel</download-excel>
                    <button style="margin-left:10px!important;" type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                  </div>
                </div>
                
               
              </div>
  
              <div class="modal-body">
                <div class="card-body table-responsive p-0 m-0">
                    <table class="table responsive-table table-sm table-striped">
                    <thead>
                        <tr>
                        <th scope="col" class="pl-2">Date</th>
                        <th scope="col">Code</th>
                        <th scope="col">Category</th>
                        <th scope="col">Description</th>
                        <th scope="col">UOM</th>
                        <th scope="col">Cost</th>
                        <th scope="col">Jan</th>
                        <th scope="col">Feb</th>
                        <th scope="col">Mar</th>
                        <th scope="col">Apr</th>
                        <th scope="col">May</th>
                        <th scope="col">Jun</th>
                        <th scope="col">Jul</th>
                        <th scope="col">Aug</th>
                        <th scope="col">Sep</th>
                        <th scope="col">Oct</th>
                        <th scope="col">Nov</th>
                        <th scope="col">Dec</th>
                        <th scope="col">Qty</th>
                        <th scope="col">Amt</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="itms in ppmpitemx.ppmpitems" :key="itms.id">
                            <td data-title="Date">{{ itms.date }}</td>
                            <td data-title="Code">{{ itms.item_code }}</td>
                            <td data-title="Category"><small>{{ itms.name }}</small></td>
                            <td data-title="Description"><small>{{ itms.description }}</small></td>
                            <td data-title="UOM"><small>{{ itms.uom_name }}</small></td>
                            <td data-title="Cost">{{ itms.unit_cost | toMoney  }}</td>
                            <td data-title="Jan" v-if="itms.jan !== 0">{{ itms.jan }}</td><td data-title="Jan" v-else>-</td>
                            <td data-title="Feb" v-if="itms.feb !== 0">{{ itms.feb }}</td><td data-title="Jan" v-else>-</td>
                            <td data-title="Mar" v-if="itms.mar !== 0">{{ itms.mar }}</td><td data-title="Jan" v-else>-</td>
                            <td data-title="Apr" v-if="itms.apr !== 0">{{ itms.apr }}</td><td data-title="Jan" v-else>-</td>
                            <td data-title="May" v-if="itms.may !== 0">{{ itms.may }}</td><td data-title="Jan" v-else>-</td>
                            <td data-title="Jun" v-if="itms.jun !== 0">{{ itms.jun }}</td><td data-title="Jan" v-else>-</td>
                            <td data-title="Jul" v-if="itms.jul !== 0">{{ itms.jul }}</td><td data-title="Jan" v-else>-</td>
                            <td data-title="Aug" v-if="itms.aug !== 0">{{ itms.aug }}</td><td data-title="Jan" v-else>-</td>
                            <td data-title="Sep" v-if="itms.sep !== 0">{{ itms.sep }}</td><td data-title="Jan" v-else>-</td>
                            <td data-title="Oct" v-if="itms.oct !== 0">{{ itms.oct }}</td><td data-title="Jan" v-else>-</td>
                            <td data-title="Nov" v-if="itms.nov !== 0">{{ itms.nov }}</td><td data-title="Jan" v-else>-</td>
                            <td data-title="Dec" v-if="itms.dec !== 0">{{ itms.dec }}</td><td data-title="Jan" v-else>-</td>
                            <td data-title="Qty">{{ itms.tot_qty }}</td>
                            <td data-title="Amt"><b>{{ itms.tot_amount | toMoney  }}</b></td>
                        </tr>
                    </tbody>
                    <tbody v-if="ppmpitemx.ppmpitems" v-show="ppmpitemx.ppmpitems.length==0">
                        <tr><td colspan="20">No procurement items found.</td></tr>
                    </tbody>
                    </table>
                </div>
              </div>
              <div class="card-footer">
                <!--<button :disabled="loading" class="btn btn-primary" data-container="body" type="button" ><i v-if="!loading" class="fa fa-save"></i><i v-else class="fas fa-spinner fa-pulse"></i> Submit</button>-->
                <span v-if="ppmpitemx.ppmpitems"><i>No. of Items:</i><b> {{ ppmpitemx.ppmpitems.length }}</b></span> | <i>Total Amount:</i><b> {{ this.total_expenses | toMoney }}</b>
              </div>
            </div>
          </div>
        </div>
  
  
        
  
      </div>
  
  </template>
  
  <script>
  
    import Form from 'vform';
    import { VueEditor } from "vue2-editor";
    import moment from 'moment';
    
    export default {
        name: 'PpmpAdmin',
  
        components: {
            VueEditor,
        },
  
        data() {
          return {
            excel_header: 'PPMP',
            file_name: 'PPMP_.xls',
            excel_fields: {
                'Category': 'name',
                'Extent/Size of Contract Scope/Packages': 'description',
                'Unit': 'uom_name',
                'JAN': 'jan',
                'FEB': 'feb',
                'MAR': 'mar',
                'APR': 'apr',
                'MAY': 'may',
                'JUN': 'jun',
                'JUL': 'jul',
                'AUG': 'aug',
                'SEP': 'sep',
                'OCT': 'oct',
                'NOV': 'nov',
                'DEC': 'dec',
                'Total Order': 'tot_qty',
                'Unit Price': 'unit_cost',
                'Estimated Budget': 'tot_amount',
            },
            timer: 30,
            editmode: false,
            loading: false,
            emptyflag: false,
            can_access: false,
            can_add: false,
            can_edit: false,
            can_delete: false,
            office: '',
            status:'',
            ppmpitemx: {},
            ppmps : {},
            total_expenses: 0,
            year: new Date().getFullYear(),
            form: new Form({
              //
            }),
          }
        },
  
        props: [
          'todo'
        ],
  
        computed: {
          
          second() {
            return this.time / 1000;
          },
  
          isIdle() {
            const idlestat =  this.$store.state.idleVue.isIdle;
            if (idlestat == true) {
              $('#sessionmodal').modal('show');
              this.countDownTimer();
            } else {
              $('#sessionmodal').modal('hide');
            }
            return idlestat;
          },
  
          years() {
            const year = new Date().getFullYear()
            return Array.from({length: year - 2018}, (value, index) => 2019 + index + 1)
          }, 
        
  
        },
  
        methods: {
  
          countDownTimer() {
            let timerId = setInterval(() => {
              this.timer -= 1;
              if (!this.$store.state.idleVue.isIdle) clearInterval(timerId);
  
              if (this.timer < 1) {
                clearInterval(timerId);
                document.getElementById('logout-form').submit();
              }
            }, 1000);
  
            this.timer = 30;
          },
  
          showModal(payload, officename, status) {
            //console.log(payload)
            this.ppmpitemx = payload;
            this.office = officename;
            this.file_name = 'PPMP - ' + officename + '.xls';
            this.excel_header = ["PROJECT PROCUREMENT MANAGEMENT PLAN", "Procurement Unit: " + officename, "Calendar Year: " + this.year];
            //console.log(status)
            this.status = status;

            this.total_expenses = this.computeExpenses(payload)
            $('#ppmpitemsmodal').modal('show');
          },

          computeExpenses() {
            /*
            let total_ot = [];
            Object.entries(this.office.data.ppmps.ppmpitems).forEach(([key, val]) => {
                total_ot.push(val.tot_amount) // the value of the current key.
            });*/

            return this.ppmpitemx.ppmpitems.reduce((accum, item) => {
                return accum + parseFloat(item.tot_amount)
            }, 0.00)

          },
  
          newModal() {
            this.editmode = false;
            this.form.reset();
            this.form.clear();
            $('#proposalmodal').modal('show');
          },
  
          newActionModal(project_id) {
            this.form_action.reset();
            this.form_action.clear();
            $('#actionmodal').modal('show');
            this.form_action.created_by = 'John Nicholi Jamero';
            this.form_action.project_id = project_id;
          },
  
  
          getResults(page = 1) {
  
            this.$Progress.start();
  
            let query = this.$parent.search;
  
            if(query == ''){
              axios.get('api/ppmps/offices/' + this.year + '?page=' + page).then(response => { this.ppmps = response.data; }); 
            }else{
              axios.get('api/findPpmps?q=' + query + '&page=' + page).then(response => { this.ppmps = response.data; }); 
            }
  
            this.$Progress.finish();
  
            //console.log(query);
  
            
          },

          changeStatus(id, status, txt, version) {
            swal.fire({
            title: 'Warning',
            text: txt,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
            }).then((result)=>{
              if (result.value) {
                axios.put('api/ppmps/status/'+ status + '/' + version + '/' + id).then(()=>{
                  swal.fire(
                    'Confirmation',
                    'PPMP status has been updated.',
                    'success'
                  );
                  Trigger.$emit('RefreshData');
                  }).catch(()=>{
                    swal.fire(
                      'Failed!', 
                      'There was something wrong', 
                      'warning'
                    )
                  });
              }
            })
          },
  
          loadPpmps() {
            this.$Progress.start();
            
            let query = this.$parent.search;
            let page = this.ppmps.current_page;
            
            if(query == ''){
              axios.get('api/ppmps/offices/' + this.year + '?page=' + page).then(({ data }) => (this.ppmps = data));
            }else{
              axios.get('api/findPpmps?q=' + query + '&page=' + page).then(({ data }) => (this.ppmps = data));
            }
  
            this.$Progress.finish();
          },
  
          async loadPermission(mid) {
              const response = await axios.get("api/permission/" + this.$gate.showMyID() + "/" + mid).then( 
              res => {
                if (!res.data || res.data.length == 0) {
                  return false;
                } else {
                  return true;
                }
              })
  
              const getResponse = async () => {
                const a = await response;
  
                switch (mid) {
                case 53:
                  this.can_access = a;
                  if (!this.can_access) {
                    this.$router.push({ name: 'not-found' }) //redirect to not found
                  }
                  break;
                  /*
                case 50:
                  this.can_edit = a;
                  break;
                case 51:
                  this.can_delete = a;
                  */
                }
              };
              return getResponse()
          },
  
          
        },
  
        created() {
          Trigger.$on('searching',() => {
            let query = this.$parent.search;
            let emptyflag = false;
  
            if(query == ''){
              Trigger.$emit('RefreshData');
            }else{
              this.$Progress.start();
              axios.get("api/findPpmps?q=" + query)
              .then((data) => {
                this.ppmps = data.data;
                
                if (data.data.total == 0){
                  this.emptyflag = true;
                }
  
                this.$Progress.finish();
              })
              .catch(() => {
                this.$Progress.fail();
              })
            }
            
          });
          
          this.loadPpmps();
          this.loadPermission(53); //Can Manage PPMP
          
          Trigger.$on('RefreshData',() => {
            this.loadPpmps();
          });
        },
  
    }
  </script>
  <style scoped>
  div >>> p {
  font-size: 15px;
  }
  
  div >>> strong {
  font-size: 15px;
  }
  
  div >>> em {
  font-size: 15px;
  }
  
  div.header >>> p {
  line-height: 0.5;
  font-size: 20px;
  font-family: "Helvetica Narrow","Arial Narrow";
  }
  
  div.header >>> strong {
  line-height: 0.5;
  font-size: 20px;
  font-family: "Helvetica Narrow","Arial Narrow";
  }
  
  div.header >>> em {
  line-height: 0.5;
  font-size: 20px;
  font-family: "Helvetica Narrow","Arial Narrow";
  }
  
  .text-selection-none{
   user-select: none; /* supported by Chrome and Opera */
   -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
   -moz-user-select: none; /* Firefox */
   -ms-user-select: none;
  }
  div.tblProject table {
  width: 100% !important;
  }
  
  div.tblProject table td, div.tblProject table th {
  border: 1px solid #6C757D !important;
  border-collapse: collapse !important;
  padding: 5px !important;
  }
  
  div.tblProject >>> table {
  width: 100% !important;
  }
  
  div.tblProject >>> table td, div.tblProject >>> table th {
  border: 1px solid #6C757D !important;
  border-collapse: collapse !important;
  padding: 5px !important;
  }
  
  div.tblProject >>> br {
  display: block; /* makes it have a width */
  content: ""; /* clears default height */
  margin-top: 15px; /* change this to whatever height you want it */
  }

  .modal-xl {
    max-width: 95%;
  }
  </style>
  