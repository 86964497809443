export default class Gate {

    constructor(user) {
        this.user = user;
    }

    isHR() {
        return this.user.role === 'HR';
    }

    isUSER() {
        return this.user.role === 'STANDARD USER';
    }

    isMIS() {
        //console.log(this.user.role);
        return this.user.role === 'MIS';
    }

    isCASHIER() {
        return this.user.role === 'CASHIER';
    }

    isExecomSecretariat() {
        return this.user.role === 'EXECOM SECRETARIAT';
    }

    isProjectManager() {
        return this.user.role === 'PROJECT MANAGER';
    }

    showMyID() {
        return this.user.id;
    }

    showFullName() {
        return this.user.fname + ' ' + this.user.lname;
    }

    showFname() {
        return this.user.fname;
    }

    showMname() {
        return this.user.mname;
    }

    showLname() {
        return this.user.lname;
    }

    showPosition() {
        return this.user.position;
    }

    showPhoto() {
        return this.user.photo;
    }

}
