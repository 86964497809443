<template>
    <div>

        <!-- Action map Modal -->
        <div class="modal fade" id="actionmapmodal" tabindex="-1" role="dialog" aria-labelledby="actionmapModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="actionmapModalLabel">Action Map</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="callout callout-success">
                            <h5>Start</h5>
                            <p>Created a Project.</p>
                        </div>
                        <div v-for="pa in projectactions" :key="pa.id">
                            <div class="callout callout-warning">
                                <h5>#{{pa.order}} - {{pa.action}}</h5>
                                <p>{{pa.description}}</p>

                            </div>
                            <div class="text-center mb-2">
                                <i class="fa fa-arrow-down" aria-hidden="true"></i>
                            </div>
                        </div>
                        <div class="callout callout-success">
                            <h5>End</h5>
                            <p>End of timeline.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Comment or Post History -->
        <div class="modal fade" id="historymodal" tabindex="-1" role="dialog" aria-labelledby="historyModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="historyModalLabel"><i class="fas fa-history"></i> Edit History</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body" v-if="history">
                        <div v-for="his in history" :key="his.id">
                            <strong>Changed last {{ his.created_at | fulldate }}</strong><p class="text-muted">{{ his.comment }}</p><hr>
                        </div>
                    </div>
                    <div class="modal-body" v-show="history.length == 0">
                        <div>
                            <p class="text-muted">No edit history yet.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal : Add Inquiry -->
        <div class="modal fade" id="inquirymodal" tabindex="-1" role="dialog" aria-labelledby="inquiryModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-md" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="inquiryModalLabel">Add New Inquiry</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <form @submit.prevent="createInquiry()" @keydown="formInquiry.onKeydown($event)">

                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-12">
                                <!-- Your first column here -->
                                <div class="form-group">
                                    <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">INQUIRER</span>
                                    </div>
                                    <input v-model="formInquiry.inquirer" type="text" name="inquirer" autocomplete="off" placeholder="INQUIRER.." class="form-control" :class="{ 'is-invalid': formInquiry.errors.has('inquirer') }">
                                    <has-error :form="formInquiry" field="inquirer"></has-error>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">PHONE</span>
                                    </div>
                                    <input v-model="formInquiry.phone" type="number" name="phone" autocomplete="off" placeholder="PHONE.." class="form-control" :class="{ 'is-invalid': formInquiry.errors.has('phone') }">
                                    <has-error :form="formInquiry" field="phone"></has-error>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">EMAIL/CONTACTS</span>
                                    </div>
                                    <input v-model="formInquiry.email" type="text" name="email" autocomplete="off" placeholder="EMAIL.." class="form-control" :class="{ 'is-invalid': formInquiry.errors.has('email') }">
                                    <has-error :form="formInquiry" field="email"></has-error>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">INQUIRY</span>
                                    </div>
                                    <textarea v-model="formInquiry.details" class="form-control" rows="4" style="resize:none" placeholder="INQUIRY.." :class="{ 'is-invalid': formInquiry.errors.has('details') }"></textarea>
                                    <has-error :form="formInquiry" field="details"></has-error>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-default" data-dismiss="modal"><i class="fa fa-times-circle"></i> CLOSE</button>
                        <button type="submit" class="btn btn-default"><i class="fa fa-save"></i> SAVE</button>
                    </div>
                    </form>
                </div>
            </div>
        </div>

        <!-- Modal : Add/Edit Project -->
        <div class="modal fade" id="execommodal" tabindex="-1" role="dialog" aria-labelledby="execomModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-md" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="execomModalLabel">Update Project Information</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <form @submit.prevent="updateExecom()" @keydown="formExecom.onKeydown($event)">

                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-12">
                                <!-- Your first column here -->
                                <div class="form-group">
                                    <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">PROJECT TITLE</span>
                                    </div>
                                    <input v-model="formExecom.proposal" type="text" name="proposal" autocomplete="off" placeholder="PROPOSAL TITLE.." class="form-control" :class="{ 'is-invalid': formExecom.errors.has('proposal') }">
                                    <has-error :form="formExecom" field="proposal"></has-error>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">INDUSTRY/SECTOR</span>
                                    </div>
                                    <select v-model="formExecom.projectindustry_id" name="type" class="form-control" :class="{ 'is-invalid': formExecom.errors.has('projectindustry_id') }">
                                            <option value="">- Select Industry -</option>
                                            <option v-for="ind in projectindustries" :key="ind.id" :value="ind.id" >{{ ind.industryname }}</option>
                                    </select>
                                    <has-error :form="formExecom" field="projectindustry_id"></has-error>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">PROGRAM</span>
                                    </div>
                                    <select v-model="formExecom.program_id" name="type" class="form-control" :class="{ 'is-invalid': form.errors.has('program_id') }">
                                            <option value="">- Select Program -</option>
                                            <option v-for="pg in programs" :key="pg.id" v-bind:value="pg.id" >{{ pg.program_name }}</option>
                                    </select>
                                    <has-error :form="formExecom" field="program_id"></has-error>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">AMOUNT REQUESTED</span>
                                    </div>
                                    <money v-model="formExecom.amt_requested" :precision="2" class="form-control" :class="{ 'is-invalid': formExecom.errors.has('amt_requested') }"></money>
                                    <has-error :form="formExecom" field="amt_requested"></has-error>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">PROPONENT NAME</span>
                                    </div>
                                    <input v-model="formExecom.proponent" type="text" name="proponent" autocomplete="off" placeholder="PROPONENT NAME.." class="form-control" :class="{ 'is-invalid': formExecom.errors.has('proponent') }">
                                    <has-error :form="formExecom" field="proponent"></has-error>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">AGE</span>
                                    </div>
                                    <input type="number" v-model="formExecom.age" class="form-control" :class="{ 'is-invalid': formExecom.errors.has('age') }" />
                                    <has-error :form="formExecom" field="age"></has-error>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">SEX</span>
                                    </div>
                                    <div class="form-check form-check-inline ml-3">
                                        <input type="radio" id="sexMale" value="MALE" v-model="formExecom.sex" class="form-check-input" />
                                        <label for="sexMale" class="form-check-label">MALE</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input type="radio" id="sexFemale" value="FEMALE" v-model="formExecom.sex" class="form-check-input" />
                                        <label for="sexFemale" class="form-check-label">FEMALE</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input type="radio" id="sexOthers" value="OTHERS" v-model="formExecom.sex" class="form-check-input" />
                                        <label for="sexOthers" class="form-check-label">OTHERS</label>
                                    </div>
                                    <has-error :form="formExecom" field="sex"></has-error>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">COMPANY</span>
                                    </div>
                                    <input v-model="formExecom.company" type="text" name="company" autocomplete="off" placeholder="COMPANY NAME.." class="form-control" :class="{ 'is-invalid': formExecom.errors.has('company') }">
                                    <has-error :form="formExecom" field="company"></has-error>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">EMAIL/CONTACTS</span>
                                    </div>
                                    <input v-model="formExecom.email" type="text" name="email" autocomplete="off" placeholder="EMAIL.." class="form-control" :class="{ 'is-invalid': formExecom.errors.has('email') }">
                                    <has-error :form="formExecom" field="email"></has-error>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">REGION</span>
                                    </div>
                                    <select v-model="formExecom.region" name="type" class="form-control" :class="{ 'is-invalid': formExecom.errors.has('region') }">
                                        <option value="">- Select Region -</option>
                                        <option value="BARMM">BARMM</option>
                                        <option value="CAR">CAR</option>
                                        <option value="NCR">NCR</option>
                                        <option value="I">Region I</option>
                                        <option value="II">Region II</option>
                                        <option value="III">Region III</option>
                                        <option value="IV-A">Region IV-A</option>
                                        <option value="IV-B">Region IV-B</option>
                                        <option value="V">Region V</option>
                                        <option value="VI">Region VI</option>
                                        <option value="VII">Region VII</option>
                                        <option value="VIII">Region VIII</option>
                                        <option value="IX">Region IX</option>
                                        <option value="X">Region X</option>
                                        <option value="XI">Region XI</option>
                                        <option value="XII">Region XII</option>
                                        <option value="XIII">Region XIII</option>
                                        <option value="INT">INTERNATIONAL</option>
                                        <option value="OTHERS">OTHERS</option>
                                    </select>
                                    <has-error :form="formExecom" field="region"></has-error>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">ADDRESS</span>
                                    </div>
                                    <input v-model="formExecom.address" type="text" name="address" autocomplete="off" placeholder="ADDRESS.." class="form-control" :class="{ 'is-invalid': formExecom.errors.has('address') }">
                                    <has-error :form="formExecom" field="address"></has-error>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">DATE RECEIVED</span>
                                    </div>
                                    <input v-model="formExecom.date_received" type="date" name="date_received" autocomplete="off" placeholder="DATE RECEIVED.." class="form-control" :class="{ 'is-invalid': formExecom.errors.has('date_received') }">
                                    <has-error :form="formExecom" field="date_received"></has-error>
                                    </div>
                                </div>
                                <div class="form-group" v-if="accessflag">
                                    <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">DATE CREATED</span>
                                    </div>
                                    <input v-model="formExecom.created_at" type="date" name="created_at" autocomplete="off" placeholder="DATE CREATED.." class="form-control" :class="{ 'is-invalid': formExecom.errors.has('created_at') }">
                                    <has-error :form="formExecom" field="created_at"></has-error>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">REMARKS</span>
                                    </div>
                                    <textarea  v-model="formExecom.remarks" rows="4" name="remarks" autocomplete="off" placeholder="REMARKS.." class="form-control" :class="{ 'is-invalid': formExecom.errors.has('remarks') }" />
                                    <has-error :form="formExecom" field="remarks"></has-error>
                                    </div>
                                </div>
                                <!-- <div class="form-group">
                                    <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">STATUS</span>
                                    </div>
                                    <select v-model="formExecom.status" name="type" class="form-control" :class="{ 'is-invalid': formExecom.errors.has('status') }">
                                        <option value="">- Select Status -</option>
                                        <option value="In-progress">In-progress</option>
                                        <option value="Withdrawn">Withdrawn</option>
                                        <option value="Disapproved">Disapproved</option>
                                        <option value="Complete">Complete</option>
                                    </select>
                                    <has-error :form="formExecom" field="status"></has-error>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-default" data-dismiss="modal"><i class="fa fa-times-circle"></i> CLOSE</button>
                        <button type="submit" class="btn btn-default"><i class="fa fa-save"></i> UPDATE</button>
                    </div>
                    </form>
                </div>
            </div>
        </div>

        <!-- Modal : Add/Edit Post -->
        <div class="modal fade" id="postmodal" tabindex="-1" role="dialog" aria-labelledby="postModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 v-show="!editmode" class="modal-title" id="postModalLabel">Create Action</h5>
                        <h5 v-show="editmode" class="modal-title" id="postModalLabel">Update Action</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <form @submit.prevent="editmode ? updatePost() : createPost()" @keydown="form.onKeydown($event)">
                    <div class="modal-body">
                        <div class="row">
                            <div v-if="!myprojectrole" class="col-md-12">
                                <div class="user-block mb-3">
                                    <img class="img-circle img-bordered-sm" :src='"img/profile/" + this.$gate.showPhoto()' alt="user image">
                                    <span class="username"><b>{{ this.$gate.showFullName() | capitalizewords }}</b></span>
                                    <span class="description">Roles: None</span>
                                </div>
                                <div class="clearfix">
                                    <div class="alert alert-danger alert-dismissible">
                                        <h5><i class="icon fas fa-info"></i> Access Denied</h5>
                                        Please contact administrator to assign you a role and permissions.
                                    </div>
                                </div>
                            </div>
                            <div v-else class="col-md-12">
                                <!-- Poster profile -->
                                <div class="user-block mb-3">
                                    <img class="img-circle img-bordered-sm" :src='"img/profile/" + this.$gate.showPhoto()' alt="user image">
                                    <span class="username"><b>{{ this.$gate.showFullName() | capitalizewords }}</b></span>
                                    <span class="description">Roles: {{ myprojectrole.join(', ') }}</span>
                                </div>

                                 <!--Action Dropdown-->
                                <div class="clearfix" v-if="!editmode">
                                    <v-selectize key-by="id" label="action" :create-item="false" :options="myprojectactions" v-model="vselected" placeholder="Choose project action.." disableSearch>
                                        <template v-slot:option="{ option }" class="test">
                                            <div class="text-base">
                                                <i class="fa fa-share-alt"></i>
                                                <b class="ml-1">{{ option.action }}</b>
                                                <small class="ml-1">(#{{ option.order }})</small>
                                            </div>
                                            <small class="ml-1 text-muted"><i>Group: {{ option.projectactiongroup.group | capitalizewords }}</i></small>
                                            <!--
                                            <div class="text-xs">
                                                <span>{{ option.language }}</span>
                                                <span class="ml-2"><b>{{ option.watchers }}</b> watchers</span>
                                                <span class="ml-2"><b>{{ option.forks }}</b> forks</span>
                                            </div>
                                            -->
                                        </template>

                                    </v-selectize>
                                    <has-error :form="form" field="form.projectaction_id"></has-error>
                                </div>
                                <div v-else>
                                    <div class="form-group" v-if="accessflag">
                                        <div class="input-group">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text">DATE CREATED</span>
                                        </div>
                                        <input v-model="formComment.created_at" type="text" name="created_at" autocomplete="off" placeholder="DATE CREATED.." class="form-control" :class="{ 'is-invalid': formComment.errors.has('created_at') }">
                                        <has-error :form="formComment" field="created_at"></has-error>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="info-box">
                                            <div class="info-box-content" style="background: #e2e2e2;">
                                                <div class="user-block">
                                                    <span class="username mb-2 ml-0">Old Action Body <small>Saved in edit history</small></span>
                                                    <span class="description ml-0">{{ formComment.to_body }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <!--Tag people-->
                                <div class="form-group">
                                    <Mentionable :keys="['@', '#']" :items="items" offset="6" insert-space @open="onOpen" @apply="onApply()">
                                        <div class="textarea-container">
                                            <textarea v-if="!editmode" autofocus ref="postbody" v-focus v-model="form.body" class="form-control" rows="4" style="resize:none" :placeholder='"What`s on your mind, " + this.$gate.showFname() + "?"'></textarea>
                                            <textarea v-else autofocus ref="postbody" v-focus v-model="formComment.comment" class="form-control" rows="4" style="resize:none" :placeholder='"What`s on your mind, " + this.$gate.showFname() + "?"'></textarea>
                                        </div>
                                        <template #no-result>
                                            <div class="dim">
                                                No result
                                            </div>
                                        </template>
                                        <template #item-@="{ item }">
                                            <div class="issue">
                                                <span class="number">
                                                    @{{ item.username }}
                                                </span>
                                                <span class="dim">
                                                    {{ item.lname }}, {{ item.fname }}
                                                </span>
                                            </div>
                                        </template>
                                        <template #item-#="{ item }">
                                            <div class="issue">
                                                <span class="number">
                                                    #{{ item.rolename }}
                                                </span>
                                                <span class="dim">
                                                    {{ item.rolelabel }}
                                                </span>
                                            </div>
                                        </template>
                                    </Mentionable>
                                    <has-error :form="form" field="body"></has-error>
                                </div>

                                <div v-if="!editmode" v-show="form.usertags || form.roletags" class="alert alert-warning alert-dismissible">
                                    <h5><i class="icon fas fa-exclamation-triangle"></i>Tagged @users or #roles will be notified.</h5>
                                    <span class="badge bg-primary mr-1" v-for="ut in form.usertags">{{ut}}</span><span class="badge bg-info mr-1" v-for="rt in form.roletags">{{rt}}</span>
                                </div>
                                <div v-else v-show="formComment.usertags || formComment.roletags" class="alert alert-warning alert-dismissible">
                                    <h5><i class="icon fas fa-exclamation-triangle"></i>Tagged @users or #roles will be notified.</h5>
                                    <span class="badge bg-primary mr-1" v-for="ut in formComment.usertags">{{ut}}</span><span class="badge bg-info mr-1" v-for="rt in formComment.roletags">{{rt}}</span>
                                </div>

                                <button type="button" class="btn btn-block btn-default mb-1" data-toggle="collapse" data-target="#demo" v-if="!editmode">
                                    <b class="mr-2">Add attachments to your post</b>
                                    <i class="mr-2 fa fa-file-image text-secondary"></i>
                                    <i class="mr-2 fa fa-file-word text-primary"></i>
                                    <i class="mr-2 fa fa-file-excel text-success"></i>
                                    <i class="mr-2 fa fa-file-powerpoint text-orange"></i>
                                    <i class="mr-2 fa fa-file-pdf text-danger"></i>
                                </button>

                                <!--File Upload Drag-->
                                <div id="demo" class="collapse mb-5" v-if="!editmode">
                                    <div class="col-md-12 p-0">
                                    <div class="">
                                        <div class="p-0">
                                        <div class="uploader" @dragenter="OnDragEnter" @dragleave="OnDragLeave" @dragover.prevent @drop="onDrop" :class="{ dragging: isDragging }">
                                            <div v-show="!documents.length">
                                                <i class="fa fa-cloud-upload"></i>
                                                <h3>Drag your files here</h3>
                                                <div class="mb-2">OR</div>
                                                <div class="file-input btn btn-outline-secondary">
                                                    <label for="file">Select file</label>
                                                    <input type="file" id="file" @change="onInputChange" multiple>
                                                </div>
                                            </div>
                                            <div class="container-fluid">
                                                <div class="row" v-show="documents.length">
                                                    <div class="col-md-6 p-0" v-for="(document, index) in documents" :key="document.id">
                                                        <div class="info-box">
                                                            <div class="info-box-content">
                                                                <button type="button" @click="onRemove(index)" class="btn float-right"><i class="fas fa-times"></i></button>
                                                                <small class="info-box-number float-left">{{ files[index].name }}<br/><span class="text-muted"><i>Size: {{ getFileSize(files[index].size) }}</i></span></small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                </div>

                                <button :disabled="!formComment.comment" v-show="editmode" type="submit" class="btn btn-block btn-primary btn-lg mt-4">Update</button>
                                <button :disabled="!form.body||!vselected" v-show="!editmode" type="submit" class="btn btn-block btn-primary btn-lg mt-4">Post</button>
                            </div>
                        </div>
                    </div>
                    </form>
                </div>
            </div>
        </div>

        <!-- Comment Modal -->
        <div class="modal fade" id="commentmodal" tabindex="-1" role="dialog" aria-labelledby="commentModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 v-show="!editmode" class="modal-title" id="commentModalLabel">Comment</h5>
                        <h5 v-show="editmode" class="modal-title" id="commentModalLabel">Update Comment</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <form @submit.prevent="editmode ? updateComment() : createComment()" @keydown="form.onKeydown($event)">
                    <div class="modal-body">
                        <div class="row" v-if="!editmode">
                            <div class="col-md-12">
                                <div class="info-box">
                                    <div class="info-box-content" style="background: #e2e2e2;">
                                        <div class="user-block">
                                            <img :src='"img/profile/" + formComment.to_photo' alt="user image" class="img-circle img-bordered-sm">
                                            <span class="username mb-2">Replying to {{ formComment.to_name | capitalizewords}}'s post:</span>
                                            <span class="description">{{ formComment.to_body }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row" v-else>
                            <div class="col-md-12">
                                <div class="info-box">
                                    <div class="info-box-content" style="background: #e2e2e2;">
                                        <div class="user-block">
                                            <span class="username mb-2 ml-0">Old Comment <small>Saved in edit history</small></span>
                                            <span class="description ml-0">{{ formComment.to_body }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div v-if="!myprojectrole" class="col-md-12">
                                <div class="user-block mb-3">
                                    <img class="img-circle img-bordered-sm" :src='"img/profile/" + this.$gate.showPhoto()' alt="user image">
                                    <span class="username"><b>{{ this.$gate.showFullName() | capitalizewords }}</b></span>
                                    <span class="description">Roles: None</span>
                                </div>
                                <div class="clearfix">
                                    <div class="alert alert-danger alert-dismissible">
                                        <h5><i class="icon fas fa-info"></i> Access Denied</h5>
                                        Please contact administrator to assign you a role and permissions.
                                    </div>
                                </div>
                            </div>
                            <div v-else class="col-md-12">
                                <!-- Your first column here -->

                                <div class="user-block mb-3">
                                    <img class="img-circle img-bordered-sm" :src='"img/profile/" + this.$gate.showPhoto()' alt="user image">
                                    <span class="username"><b>{{ this.$gate.showFullName() | capitalizewords }}</b></span>
                                    <span class="description">Roles: {{ myprojectrole.join(', ') }}</span>
                                </div>

                                <!--Tag people-->
                                <div class="form-group">
                                    <Mentionable :keys="['@', '#']" :items="items" offset="6" insert-space @open="onOpen" @apply="onApply()">
                                        <div class="textarea-container">
                                            <textarea ref="commentbody" v-model="formComment.comment" class="form-control" rows="4" style="resize:none" placeholder="Write a comment..." v-focus></textarea>
                                        </div>
                                        <template #no-result>
                                            <div class="dim">
                                                No result
                                            </div>
                                        </template>
                                        <template #item-@="{ item }">
                                            <div class="issue">
                                                <span class="number">
                                                    @{{ item.username }}
                                                </span>
                                                <span class="dim">
                                                    {{ item.lname }}, {{ item.fname }}
                                                </span>
                                            </div>
                                        </template>
                                        <template #item-#="{ item }">
                                            <div class="issue">
                                                <span class="number">
                                                    #{{ item.rolename }}
                                                </span>
                                                <span class="dim">
                                                    {{ item.rolelabel }}
                                                </span>
                                            </div>
                                        </template>
                                    </Mentionable>
                                    <has-error :form="form" field="body"></has-error>
                                </div>

                                <div v-show="formComment.usertags || formComment.roletags" class="alert alert-warning alert-dismissible">
                                    <h5><i class="icon fas fa-exclamation-triangle"></i>Tagged @users or #roles will be notified.</h5>
                                    <span class="badge bg-primary mr-1" v-for="ut in formComment.usertags">{{ut}}</span><span class="badge bg-info mr-1" v-for="rt in formComment.roletags">{{rt}}</span>
                                </div>

                                <button :disabled="!formComment.comment" v-show="editmode" type="submit" class="btn btn-block btn-primary btn-lg mt-4">Update</button>
                                <button :disabled="!formComment.comment" v-show="!editmode" type="submit" class="btn btn-block btn-primary btn-lg mt-4">Post</button>
                            </div>
                        </div>
                    </div>
                    </form>
                </div>
            </div>
        </div>

        <section class="content">
            <div class="mb-2">
                <div class="row">
                    <div class="col-md-4">
                        <button class="btn btn-info" @click="$router.push('projects')"><i class="fa fa-arrow-circle-left"></i> BACK</button>
                    </div>
                </div>
            </div>
            <div>
                <div class="row">
                    <div class="col-md-4">
                        <div class="card card-widget widget-user-2 shadow-sm">
                            <div class="widget-user-header p-3" :class="{'bg-success': execom.status == 'Complete','bg-primary': execom.status == 'In-progress','bg-danger': execom.status == 'Disapproved','bg-danger': execom.status == 'Withdrawn'}">
                                <h4>Project Title: {{ execom.proposal }}</h4>
                            </div>
                            <div class="card-body p-0">
                                <ul class="nav flex-column">
                                    <li><p class="p-2 m-0"><b>Date Created</b> <span class="float-right">{{ execom.created_at | customdate }}</span></p></li>
                                    <li><p class="p-2 m-0"><b>Date Received</b> <span class="float-right">{{ execom.date_received | customdate }}</span></p></li>
                                    <li v-if="execom.projectindustry"><p class="p-2 m-0"><b>Industry/Sector</b> <span class="float-right">{{ execom.projectindustry.industryname }}</span></p></li>
                                    <li v-if="execom.program"><p class="p-2 m-0"><b>Program</b> <span class="float-right">{{ execom.program.program_name }}</span></p></li>
                                    <li><p class="p-2 m-0"><b>Amount Requested</b> <span class="float-right">{{ execom.amt_requested | toMoney }}</span></p></li>
                                    <li><p class="p-2 m-0"><b>Email</b> <span class="float-right">{{ execom.email }}</span></p></li>
                                    <li><p class="p-2 m-0"><b>Region</b> <span class="float-right">{{ execom.region }}</span></p></li>
                                    <li><p class="p-2 m-0"><b>Proponent</b> <span class="float-right">{{ execom.proponent }}</span></p></li>
                                    <li><p class="p-2 m-0"><b>Age</b> <span class="float-right">{{ execom.age }}</span></p></li>
                                    <li><p class="p-2 m-0"><b>Sex</b> <span class="float-right">{{ execom.sex }}</span></p></li>
                                    <li><p class="p-2 m-0"><b>Company</b> <span class="float-right">{{ execom.company }}</span></p></li>
                                    <li><p class="p-2 m-0"><b>Address</b> <span class="float-right">{{ execom.address }}</span></p></li>
                                    <li><p class="p-2 m-0"><b>Remarks</b> <span class="float-right">{{ execom.remarks }}</span></p></li>
                                    <li><p class="p-2 m-0"><b>Status</b> <span class="float-right"><span class="badge" :class="{'badge-success': execom.status == 'Complete','badge-primary': execom.status == 'In-progress','badge-danger': execom.status == 'Disapproved','badge-danger': execom.status == 'Withdrawn'}">{{ execom.status }}</span></span></p></li>
                                    <li v-if="execom.user && execom.user.id === this.$gate.showMyID() || accessflag"><button type="button" class="btn btn-default btn-block" @click.prevent="editProjectModal(execom)"><i class="fa fa-edit"></i> Edit</button></li>
                                    <li v-if="accessflag"><button type="button" class="btn btn-danger btn-block" @click.prevent="deleteProject(execom.id)"><i class="fa fa-trash"></i> Delete</button></li>
                                </ul>
                            </div>
                        </div>
                        <div class="card card-info card-outline direct-chat-primary shadow-none sticky-top">
                            <div class="card-header">
                                <h3 class="card-title">Timeline Summary</h3>
                                <div class="card-tools">
                                    <button type="button" class="btn btn-tool" title="Click to show Action Map" @click="openActionmap">
                                        <i class="fas fa-info-circle"></i>
                                    </button>
                                </div>
                            </div>
                            <div class="card-body">
                                <template v-if="execom.projectposts && execom.projectposts.length !== 0">
                                    <div class="progress progress-sm">
                                        <div class="progress-bar bg-green progress-bar-striped"
                                            role="progressbar"
                                            :aria-valuenow="(execom.projectposts[0].projectaction.percentage)"
                                            aria-valuemin="0"
                                            aria-valuemax="100"
                                            :style="{ width: (execom.projectposts[0].projectaction.percentage) + '%' }">
                                        </div>
                                    </div>
                                    <small>{{ execom.projectposts[0].projectaction.percentage }}% Complete</small>
                                </template>
                                <ul class="timeline">
                                    <li>
                                        <span class="ml-5"><b>ENCODING / SYSTEM FILING</b></span>
                                        <p class="ml-5 mt-2">
                                            <ul class="list-unstyled text-muted">
                                                <li><i class="fas fa-clock"></i> {{ execom.created_at | shortdaytime }} - <a href="#post0">Created a Project</a></li>
                                            </ul>
                                        </p>
                                    </li>
                                    <li v-for="(grp, idx) in projectactiongroups">
                                        <span class="ml-5"><b>{{ grp.group }}</b></span>
                                        <p class="ml-5 mt-2">
                                            <ul class="list-unstyled text-muted">
                                                <li v-for="(act, index) in grp.projectpostsactions">
                                                    <i class="fas fa-clock"></i> {{ act.created_at | shortdaytime }} -
                                                    <a :href='"#post" + act.id'>{{ act.projectaction_title }}</a>
                                                    <!-- <span v-if="index != 0">({{ act.created_at | moment("from", grp.projectpostsactions[index - 1].created_at, true) }})</span>
                                                    <span v-else><span v-if="idx != 0">({{ act.created_at | moment("from",  projectactiongroups[idx - 1].projectpostsactions[projectactiongroups[idx - 1].projectpostsactions.length - 1].created_at, true) }})</span></span> -->
                                                    <!-- Time spent logic -->
                                                    <span v-if="idx === 0 && index === 0">
                                                        ({{ act.created_at | moment("from", execom.created_at, true) }})
                                                    </span>
                                                    <span v-else-if="index !== 0">
                                                        ({{ act.created_at | moment("from", grp.projectpostsactions[index - 1].created_at, true) }})
                                                    </span>
                                                    <span v-else>
                                                        <span v-if="idx !== 0">
                                                            ({{ act.created_at | moment("from", projectactiongroups[idx - 1].projectpostsactions[projectactiongroups[idx - 1].projectpostsactions.length - 1].created_at, true) }})
                                                        </span>
                                                    </span>
                                                </li>
                                            </ul>
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="card card-warning card-outline direct-chat-primary shadow-none">
                            <div class="card-header">
                                <h3 class="card-title">Project Inquiries</h3>
                                <div class="card-tools">
                                    <button type="button" class="btn btn-success btn-xs float-right" @click.prevent="newInquiryModal"><i class="fas fa-plus"></i> Add</button>
                                </div>
                            </div>
                            <div class="card-body p-0">
                                <ul class="products-list product-list-in-card pl-2 pr-2">
                                    <li class="item" v-for="inq in execom.projectinquiries" :key="inq.id">
                                        <div>
                                            <span class="product-title">
                                                <span v-if="!inq.status" class="badge badge-warning">OPEN</span><span v-else class="badge badge-secondary">CLOSED</span>  <a href="#" role="button" data-toggle="collapse" :data-target="'#demo' + inq.id">{{ inq.inquirer }}</a><span class="time float-right"><i class="fa fa-clock"></i> {{ inq.created_at | shortdaytime }}</span>
                                            </span>
                                            <span class="product-description">{{ inq.details }}</span>
                                        </div>
                                        <div :id="'demo' + inq.id" class="mb-0 mt-2 attachment-block collapse">
                                            <i class="fas fa-phone"></i> {{ inq.phone }} <i class="fas fa-envelope ml-2"></i> {{ inq.email }}<button v-if="!inq.status" type="button" class="btn btn-success btn-xs float-right" @click.prevent="closeInquiry(inq.id)">Mark as closed</button><button v-else type="button" class="btn btn-warning btn-xs float-right" @click.prevent="openInquiry(inq.id)">Mark as open</button>
                                            <br><br>
                                            <b>Inquiry Details:</b><br>
                                            {{ inq.details }}
                                            <br><br>
                                            <b>Submitted By:</b> {{ inq.user.fname | capitalizewords }} {{ inq.user.lname | capitalizewords }}
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-8 pl-0 pr-0">

                        <div class="">
                            <div class="col-12">
                                <div class="post-block">
                                    <img class="img-circle img-bordered-sm" :src='"img/profile/" + this.$gate.showPhoto()' alt="user image">
                                    <span class="postinput"><input class="form-control form-control-lg mb-3" type="text" @click="newModal" placeholder="Click here to post new action..."></span>
                                </div>
                            </div>
                        </div>


                        <div class="timeline">
                            <div class="mr-0" v-for="post in execom.projectposts" :key="post.id">
                                <i v-if="post.projectpostfiles.length != 0" class="fas fa-paperclip bg-maroon"></i>
                                <i v-else class="fas fa-pen bg-blue"></i>
                                <div class="timeline-item" :id='"post" + post.id'>
                                    <span class="time"><i class="fas fa-clock"></i> {{ post.created_at  | fulldate }}</span>
                                    <h3 class="timeline-header">Activity: {{ post.projectaction_title }}</h3>

                                    <div class="timeline-body">

                                        <div class="post">
                                            <div class="user-block">
                                                <img v-if="post.user.photo" class="img-circle img-bordered-sm" :src='"img/profile/" + post.user.photo' alt="user image">
                                                <span class="username">
                                                    <a href="#" @click.prevent="newCommentModal(execom.id, post.id, 0, currentuserid, '@' + post.user.username, post.user.fname + ' ' + post.user.lname, post.body, post.user.photo)">{{ post.user.fname | capitalizewords }} {{ post.user.lname | capitalizewords }}</a>
                                                    <div class="dropdown float-right btn-tool mt-1">
                                                        <button class="btn btn-link dropdown-toggle pt-0" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            <i class="fas fa-ellipsis-h"></i>
                                                        </button>
                                                        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                                            <a class="dropdown-item" href="#" role="button" @click.prevent="editPostModal(post.id, post.body, post.created_at)"  v-show="post.user.id === currentuserid || accessflag"><i class="fas fa-edit"></i> Edit Post</a>
                                                            <a class="dropdown-item" href="#" role="button" @click.prevent="openHistoryModal(post.projectposthistories)"><i class="fas fa-history"></i> Edit History</a>
                                                            <a class="dropdown-item text-danger" href="#" role="button" @click.prevent="deletePost(post.id)"  v-show="accessflag"><i class="fas fa-trash text-danger"></i> Delete Post</a>
                                                        </div>
                                                    </div>
                                                </span>
                                                <span class="description">{{ post.user.position }}</span>
                                            </div>

                                                <!-- <template v-for="part of post.body.split(/([@#https://]\w+)/g)">
                                                    <span v-if="part[0] == '@'" ><mark><b>{{part}}</b></mark></span>
                                                    <span v-else-if="part[0] == '#'" ><mark><b>{{part}}</b></mark></span>
                                                    <span v-else-if="part[0] == 'https://'" ><mark><b>{{part}}zzz</b></mark></span>
                                                    <template v-else>{{part}}</template>
                                                </template> -->

                                            <span v-for="(part, index) in formatPostBody(post.body)" :key="index">
                                                <template v-if="part.startsWith('@')">
                                                    <mark><b>{{ part }}</b></mark>
                                                </template>
                                                <template v-else-if="part.startsWith('#')">
                                                    <mark><b>{{ part }}</b></mark>
                                                </template>
                                                <template v-else-if="isURL(part)">
                                                    <a :href="part" target="_blank" class="text-blue-500 underline">{{ part }}</a>
                                                </template>
                                                <template v-else-if="part === '\n'">
                                                    <span v-html="part"></span> <!-- Render the <br> tag -->
                                                </template>
                                                <template v-else>
                                                    <span v-html="part"></span>
                                                </template>
                                            </span>

                                            <div class="mt-3">


                                                    <ul class="mailbox-attachments clearfix">
                                                        <li v-for="fle in post.projectpostfiles" :key="fle.id">
                                                            <div v-if="fle.filetype==='jpg' || fle.filetype==='jpeg' || fle.filetype==='png' || fle.filetype==='bmp' || fle.filetype==='gif'">
                                                                <span class="mailbox-attachment-icon has-img fill imahe"><img @click="imageModal(fle.projectpost_id, fle.filepath)" :src='"storage/post_files/" + fle.projectpost_id + "/" + fle.filepath' alt="Attachment"></span>
                                                                <div class="mailbox-attachment-info">
                                                                    <a href="#" role="button" @click="imageModal(fle.projectpost_id, fle.filepath)" :title="fle.filename" class="mailbox-attachment-name"><i class="fas fa-camera"></i> {{ fle.filename | stringlimit(19) }}..</a>
                                                                    <span class="mailbox-attachment-size clearfix mt-1">
                                                                    <span>{{ fle.filesize }}</span>
                                                                    <button class="btn btn-default btn-sm float-right" data-toggle="tooltip" data-container="body" title="Download" type="button" @click="downloadFile(fle.id, fle.filename)"><i class="fas fa-cloud-download-alt"></i></button>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div v-else>
                                                                <span class="mailbox-attachment-icon imahe">
                                                                    <a v-if="fle.filetype==='pdf'" :href='"http://dtoms.tapidost.net/storage/post_files/" + post.id + "/" + fle.filepath' target="_blank" class="mailbox-attachment-name">
                                                                        <i class="fa fa-file-pdf fa-10x text-danger"></i>
                                                                    </a>
                                                                    <!--<a v-else :href='"https://view.officeapps.live.com/op/view.aspx?src=http://dtoms.tapidost.net/storage/post_files/" + post.id + "/" + fle.filepath' target="_blank" class="mailbox-attachment-name">-->
                                                                    <a v-else :href='"https://docs.google.com/gview?url=http://dtoms.tapidost.net/storage/post_files/" + post.id + "/" + fle.filepath' target="_blank" class="mailbox-attachment-name">
                                                                        <i v-if="fle.filetype==='xls' || fle.filetype==='xlsx'" class="fa fa-file-excel fa-10x text-success"></i>
                                                                        <i v-else-if="fle.filetype==='doc' || fle.filetype==='docx'" class="fa fa-file-word fa-10x text-primary"></i>
                                                                        <i v-else-if="fle.filetype==='ppt' || fle.filetype==='pptx'" class="fa fa-file-powerpoint fa-10x text-orange"></i>
                                                                        <i v-else class="fas fa-paperclip fa-10x"></i>
                                                                    </a>
                                                                </span>
                                                                <div class="mailbox-attachment-info">
                                                                    <!--<a v-if="fle.filetype==='pdf'" :href='"http://dtoms.tapidost.net/storage/post_files/" + post.id + "/" + fle.filepath' target="_blank" :title="fle.filename" class="mailbox-attachment-name">-->
                                                                    <a v-if="fle.filetype==='pdf'" :href='"http://dtoms.tapidost.net/storage/post_files/" + post.id + "/" + fle.filepath' target="_blank" :title="fle.filename" class="mailbox-attachment-name">
                                                                        <i class="fas fa-paperclip"></i> {{ fle.filename  | stringlimit(19) }}..
                                                                    </a>
                                                                    <a v-else :href='"https://docs.google.com/gview?url=http://dtoms.tapidost.net/storage/post_files/" + post.id + "/" + fle.filepath' target="_blank" :title="fle.filename" class="mailbox-attachment-name"><i class="fas fa-paperclip"></i> {{ fle.filename  | stringlimit(19) }}..</a>
                                                                    <span class="mailbox-attachment-size clearfix mt-1">
                                                                    <span>{{ fle.filesize }}</span>
                                                                        <button class="btn btn-default btn-sm float-right" data-toggle="tooltip" data-container="body" title="Download" type="button" @click="downloadFile(fle.id, fle.filename)"><i class="fas fa-cloud-download-alt"></i></button>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>

                                            </div>
                                            <div class="row ml-1 mt-3">
                                                <span class="image-container handle" v-for="apr in post.projectpostapprovals" :key="apr.id"  v-tooltip="{
                                                                content: apr.user.fname + ' ' + apr.user.lname,
                                                                placement: 'top',
                                                                classes: ['info'],
                                                                targetClasses: ['it-has-a-tooltip'],
                                                                offset: -10,
                                                                delay: {
                                                                  show: 500,
                                                                  hide: 300,
                                                                },
                                                              }">
                                                    <img class="direct-chat-img img-bordered-sm mr-1" :src='"img/profile/" + apr.user.photo' alt="Approved this action post">
                                                    <div class="ixon">
                                                        <i class="fa fa-thumbs-up fa-1x text-white"></i>
                                                    </div>
                                                </span>
                                            </div>
                                            <p class="clearfix mb-0 mt-2" style="border-top: 1px solid #DEDEDE;margin-bottom: 4px;padding-top: 5px;">
                                                <!-- <a href="#" class="link-black text-sm mr-2"><i class="fas fa-share mr-1"></i> Share</a>
                                                <a href="#" class="link-black text-sm"><i class="fa fa-thumbs-up mr-1"></i> Like</a> -->

                                                <template v-if="isUserAnApprover(post.projectaction_id) && isUserExistingInApprovals(post.projectpostapprovals, currentuserid)">
                                                    <span v-if="post.projectpostapprovals.length === 1" class="text-secondary text-sm"><i class="fa fa-thumbs-up mr-1"></i> You approved</span>
                                                    <span v-else-if="post.projectpostapprovals.length > 1" class="text-secondary text-sm"><i class="fa fa-thumbs-up mr-1"></i> You and {{ post.projectpostapprovals.length - 1 }}
                                                        <span class="text-secondary text-sm" v-if="post.projectpostapprovals.length - 1 === 1"> other </span>
                                                        <span class="text-secondary text-sm" v-else> others </span>
                                                        approved
                                                    </span>
                                                </template>

                                                <template v-else-if="isUserAnApprover(post.projectaction_id) && !isUserExistingInApprovals(post.projectpostapprovals, currentuserid)">
                                                    <a v-if="isUserAnApprover(post.projectaction_id) && !isUserExistingInApprovals(post.projectpostapprovals, currentuserid)" href="#" role="button" @click.prevent="approvePost(post.id, post.user.id, post.body)" class="text-primary text-sm"><i class="fa fa-thumbs-up mr-1"></i> Approve</a>
                                                    <span v-if="post.projectpostapprovals.length === 1" class="text-secondary text-sm"> &#128900; {{ post.projectpostapprovals[0].user.fname + ' ' + post.projectpostapprovals[0].user.lname | capitalizewords }} approved</span>
                                                    <span v-else-if="post.projectpostapprovals.length > 1" class="text-secondary text-sm"> &#128900; {{ post.projectpostapprovals[0].user.fname + ' ' + post.projectpostapprovals[0].user.lname | capitalizewords }} and {{ post.projectpostapprovals.length - 1 }}
                                                        <span class="text-secondary text-sm" v-if="post.projectpostapprovals.length - 1 === 1"> other </span>
                                                        <span class="text-secondary text-sm" v-else> others </span>
                                                        approved
                                                    </span>
                                                </template>

                                                <template v-else-if="!isUserAnApprover(post.projectaction_id) && !isUserExistingInApprovals(post.projectpostapprovals, currentuserid)">
                                                    <span v-if="!post.projectpostapprovals.length" class="text-secondary text-sm"><i class="fa fa-thumbs-up mr-1"></i> Approved (0)</span>
                                                    <span v-else-if="post.projectpostapprovals.length === 1" class="text-secondary text-sm"><i class="fa fa-thumbs-up mr-1"></i> {{ post.projectpostapprovals[0].user.fname + ' ' + post.projectpostapprovals[0].user.lname | capitalizewords }} approved</span>
                                                    <span v-else-if="post.projectpostapprovals.length > 1" class="text-secondary text-sm"><i class="fa fa-thumbs-up mr-1"></i> {{ post.projectpostapprovals[0].user.fname + ' ' + post.projectpostapprovals[0].user.lname | capitalizewords }} and {{ post.projectpostapprovals.length - 1 }}
                                                        <span class="text-secondary text-sm" v-if="post.projectpostapprovals.length - 1 === 1"> other </span>
                                                        <span class="text-secondary text-sm" v-else> others </span>
                                                        approved
                                                    </span>
                                                </template>

                                                <span class="float-right mb-3"><span class="text-secondary text-sm"><i class="fa fa-comments mr-1"></i> Comments ({{ post.projectpostcomments_count }})</span></span>
                                            </p>
                                            <div class="post-block">
                                                <img class="img-circle img-bordered-sm" :src='"img/profile/" + currentuserphoto' alt="user image">
                                                <span class="postinput"><input class="form-control form-control-lg mb-3" type="text" @click="newCommentModal(execom.id, post.id, 0, currentuserid, '@' + post.user.username, post.user.fname + ' ' + post.user.lname, post.body, post.user.photo)" placeholder="Write a comment..."></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="timeline-footer">
                                        <div class="col-12">
                                            <div class="direct-chat-msg" v-for="cmt in post.projectpostcomments" :key="cmt.id">
                                                <img class="direct-chat-img img-bordered-sm" :src='"img/profile/" + cmt.user.photo' alt="message user image">
                                                <div class="direct-chat-text" :id='"comment" + cmt.id'>
                                                    <div class="dropdown float-right">
                                                        <button class="btn btn-link dropdown-toggle pt-0" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            <i class="fas fa-ellipsis-h"></i>
                                                        </button>
                                                        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                                            <a class="dropdown-item" href="#" role="button" @click.prevent="editCommentModal(cmt.id, execom.id, post.id, 0, currentuserid, '', '', cmt.comment)" v-show="cmt.user.id === currentuserid"><i class="fas fa-edit"></i> Edit Comment</a>
                                                            <a class="dropdown-item" href="#" role="button" @click.prevent="openHistoryModal(cmt.projectposthistories)"><i class="fas fa-history"></i> Edit History</a>
                                                        </div>
                                                    </div>
                                                    <span class="direct-chat-name float-left">{{ cmt.user.fname | capitalizewords }} {{ cmt.user.lname | capitalizewords }}</span><br/>
                                                    <p class="mb-0 pt-1">
                                                        <template v-for="part of cmt.comment.split(/([@#]\w+)/g)">
                                                            <span v-if="part[0] == '@'" ><mark><b>{{part}}</b></mark></span>
                                                            <span v-else-if="part[0] == '#'" ><mark><b>{{part}}</b></mark></span>
                                                            <template v-else>{{part}}</template>
                                                        </template>
                                                    </p>
                                                </div>
                                                <div class="direct-chat-infos clearfix aligntobox mt-1">
                                                    <a href="#" class="text-secondary text-sm  mr-3"><i class="fas fa-clock"></i> {{ cmt.created_at | fulldate }}</a>
                                                    <a href="#" @click.prevent="newCommentModal(execom.id, post.id, cmt.id, currentuserid, '@' + cmt.user.username, cmt.user.fname + ' ' + cmt.user.lname, cmt.comment, cmt.user.photo)" role="button" class="text-primary text-sm"><i class="fa fa-reply"></i> Reply</a>
                                                </div>
                                                <div v-if="cmt.commentchildren" v-for="cmtch in cmt.commentchildren" :key="cmtch.id">
                                                    <div class="direct-chat-msg ml-5">
                                                        <img class="direct-chat-img img-bordered-sm" :src='"img/profile/" + cmtch.user.photo' alt="message user image">
                                                        <div class="direct-chat-text" :id='"comment" + cmtch.id'>
                                                            <div class="dropdown float-right">
                                                                <button class="btn btn-link dropdown-toggle pt-0" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                    <i class="fas fa-ellipsis-h"></i>
                                                                </button>
                                                                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                                                    <a class="dropdown-item" href="#" role="button" @click.prevent="editCommentModal(cmtch.id, execom.id, post.id, 0, currentuserid, '', '', cmtch.comment)" v-show="cmtch.user.id === currentuserid"><i class="fas fa-edit"></i> Edit Comment</a>
                                                                    <a class="dropdown-item" href="#" role="button" @click.prevent="openHistoryModal(cmtch.projectposthistories)"><i class="fas fa-history"></i> Edit History</a>
                                                                </div>
                                                            </div>
                                                            <span class="direct-chat-name float-left">{{ cmtch.user.fname | capitalizewords }} {{ cmtch.user.lname | capitalizewords }}</span><br/>
                                                            <p class="mb-0 pt-1">
                                                                <template v-for="part of cmtch.comment.split(/([@#]\w+)/g)">
                                                                    <span v-if="part[0] == '@'" ><mark><b>{{part}}</b></mark></span>
                                                                    <span v-else-if="part[0] == '#'" ><mark><b>{{part}}</b></mark></span>
                                                                    <template v-else>{{part}}</template>
                                                                </template>
                                                            </p>
                                                        </div>
                                                        <div class="direct-chat-infos clearfix aligntobox mt-1">
                                                            <a href="#" class="text-secondary text-sm  mr-3"><i class="fas fa-clock"></i> {{ cmtch.created_at | fulldate }}</a>
                                                            <a href="#" @click.prevent="newCommentModal(execom.id, post.id, cmt.id, currentuserid, '@' + cmtch.user.username, cmtch.user.fname + ' ' + cmtch.user.lname, cmtch.comment, cmtch.user.photo)" role="button" aria-pressed="true" class="text-primary text-sm"><i class="fa fa-reply"></i> Reply</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="mr-0">
                                <i class="fas fa-folder bg-green"></i>
                                <div class="timeline-item" id="post0">
                                    <span class="time"><i class="fas fa-clock"></i> {{ execom.created_at | fulldate }}</span>
                                    <h3 class="timeline-header">Activity: Created a Project</h3>
                                    <div class="timeline-body">
                                        <div class="post">
                                            <div class="user-block" v-if="execom.user">
                                                <img class="img-circle img-bordered-sm" :src='"img/profile/" + execom.user.photo' alt="user image">
                                                <span class="username"><a href="#" @click.prevent="newCommentModal(execom.id, 0, 0, currentuserid, '@' + execom.user.username, execom.user.fname + ' ' + execom.user.lname, 'Created a project: ' + execom.proposal, execom.user.photo)">{{ execom.user.fname | capitalizewords }} {{ execom.user.lname | capitalizewords }}</a></span>
                                                <span class="description">{{ execom.user.position }}</span>
                                            </div>
                                            <div class="clearfix">
                                                <p><cite>Project Title:</cite> <b>{{ execom.proposal }}</b></p>
                                            </div>
                                            <p class="clearfix mb-0 mt-2" style="border-top: 1px solid #DEDEDE;margin-bottom: 4px;padding-top: 5px;">
                                                <!-- <a href="#" class="link-black text-sm mr-2"><i class="fas fa-share mr-1"></i> Share</a>
                                                <a href="#" class="link-black text-sm"><i class="fa fa-thumbs-up mr-1"></i> Like</a> -->
                                                <span class="float-right mb-3"><span class="text-secondary text-sm"><i class="fa fa-comments mr-1"></i> Comments ({{ execom.projectpostcomments_count }})</span></span>
                                            </p>
                                            <div class="post-block">
                                                <img class="img-circle img-bordered-sm" :src='"img/profile/" + currentuserphoto' alt="user image">
                                                <span class="postinput"><input class="form-control form-control-lg mb-3" type="text" @click="newCommentModal(execom.id, 0, 0, currentuserid, '@' + execom.user.username, execom.user.fname + ' ' + execom.user.lname, 'Created a project: ' + execom.proposal, execom.user.photo)" placeholder="Write a comment..."></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="timeline-footer">
                                        <!-- <a href="#" class="btn btn-sm bg-primary">See comments</a> -->
                                        <div class="col-12">
                                            <div v-for="cmt in execom.projectpostcomments" :key="cmt.id">
                                                <div class="direct-chat-msg">
                                                    <img class="direct-chat-img img-bordered-sm" :src='"img/profile/" + cmt.user.photo' alt="message user image">
                                                    <div class="direct-chat-text" :id='"comment" + cmt.id'>
                                                        <div class="dropdown float-right">
                                                            <button class="btn btn-link dropdown-toggle pt-0" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <i class="fas fa-ellipsis-h"></i>
                                                            </button>
                                                            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                                                <a class="dropdown-item" href="#" role="button" @click.prevent="editCommentModal(cmt.id, execom.id, 0, 0, currentuserid, '', '', cmt.comment)" v-show="cmt.user.id === currentuserid"><i class="fas fa-edit"></i> Edit Comment</a>
                                                                <a class="dropdown-item" href="#" role="button" @click.prevent="openHistoryModal(cmt.projectposthistories)"><i class="fas fa-history"></i> Edit History</a>
                                                            </div>
                                                        </div>
                                                        <span class="direct-chat-name float-left">{{ cmt.user.fname | capitalizewords }} {{ cmt.user.lname | capitalizewords }}</span><br/>
                                                        <p class="mb-0 pt-1">
                                                            <template v-for="part of cmt.comment.split(/([@#]\w+)/g)">
                                                                <span v-if="part[0] == '@'" ><mark><b>{{part}}</b></mark></span>
                                                                <span v-else-if="part[0] == '#'" ><mark><b>{{part}}</b></mark></span>
                                                                <template v-else>{{part}}</template>
                                                            </template>
                                                        </p>
                                                    </div>
                                                    <div class="direct-chat-infos clearfix aligntobox mt-1">
                                                        <a href="#" class="text-secondary text-sm  mr-3"><i class="fas fa-clock"></i> {{ cmt.created_at | fulldate }}</a>
                                                        <a href="#" @click.prevent="newCommentModal(execom.id, 0, cmt.id, currentuserid, '@' + cmt.user.username, cmt.user.fname + ' ' + cmt.user.lname, cmt.comment, cmt.user.photo)" role="button" aria-pressed="true" class="text-primary text-sm"><i class="fa fa-reply"></i> Reply</a>
                                                    </div>
                                                </div>
                                                <div v-for="cmtch in cmt.commentchildren" :key="cmtch.id">
                                                    <div class="direct-chat-msg ml-5">
                                                        <img class="direct-chat-img img-bordered-sm" :src='"img/profile/" + cmtch.user.photo' alt="message user image">
                                                        <div class="direct-chat-text" :id='"comment" + cmtch.id'>
                                                            <div class="dropdown float-right">
                                                                <button class="btn btn-link dropdown-toggle pt-0" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                    <i class="fas fa-ellipsis-h"></i>
                                                                </button>
                                                                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                                                    <a class="dropdown-item" href="#" role="button" @click.prevent="editCommentModal(cmtch.id, execom.id, 0, 0, currentuserid, '', '', cmtch.comment)" v-show="cmtch.user.id === currentuserid"><i class="fas fa-edit"></i> Edit Comment</a>
                                                                    <a class="dropdown-item" href="#" role="button" @click.prevent="openHistoryModal(cmtch.projectposthistories)"><i class="fas fa-history"></i> Edit History</a>
                                                                </div>
                                                            </div>
                                                            <span class="direct-chat-name float-left">{{ cmtch.user.fname | capitalizewords }} {{ cmtch.user.lname | capitalizewords }}</span><br/>
                                                            <p class="mb-0 pt-1">
                                                            <template v-for="part of cmtch.comment.split(/([@#]\w+)/g)">
                                                                <span v-if="part[0] == '@'" ><mark><b>{{part}}</b></mark></span>
                                                                <span v-else-if="part[0] == '#'" ><mark><b>{{part}}</b></mark></span>
                                                                <template v-else>{{part}}</template>
                                                            </template>
                                                        </p>
                                                        </div>
                                                        <div class="direct-chat-infos clearfix aligntobox mt-1">
                                                            <a href="#" class="text-secondary text-sm  mr-3"><i class="fas fa-clock"></i> {{ cmtch.created_at | fulldate }}</a>
                                                            <a href="#" @click.prevent="newCommentModal(execom.id, 0, cmt.id, currentuserid, '@' + cmtch.user.username, cmtch.user.fname + ' ' + cmtch.user.lname, cmtch.comment, cmtch.user.photo)" role="button" aria-pressed="true" class="text-primary text-sm"><i class="fa fa-reply"></i> Reply</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div>
                                <i class="fas fa-clock bg-gray"></i>
                            </div>
                        </div>

                        <div class="modal fade" id="imagemodal" tabindex="-1" role="dialog" aria-labelledby="imageModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="imageModalLabel">Image Viewer</h5>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                        </button>
                                    </div>
                                    <div class="modal-body d-flex justify-content-center">
                                        <img class="img-fluid" :src='"storage/post_files/" + imagepath' />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </section>
    </div>

</template>

<script>
    import { controllers } from 'chart.js';
    import Form from 'vform';
    import { Mentionable } from 'vue-mention'


    export default {

        components: {
            Mentionable,
        },

        data() {
          return {
            id: this.$route.query.id,
            timer: 30,
            editmode: false,
            emptyflag: false,
            can_access: false,
            can_add: false,
            can_edit: false,
            can_delete: false,
            currentuserid: this.$gate.showMyID(),
            currentuserphoto: this.$gate.showPhoto(),
            history: {},
            execom : {},
            accessflag: 0,
            imagepath: null,
            projectactiongroups: [],
            myprojectrole: [],
            myprojectactions: [],
            myapproveractions: [],
            projectactions : [],
            vselected: {},
            projectindustries: {},
            programs: {},
            currentuser: this.$gate.showPhoto(),
            isDragging: false,
            dragCount: 0,
            files: [],
            documents: [],

            form: new Form({
                id: null,
                body: '',
                usertags: null,
                roletags: null,
            }),

            formComment: new Form({
                id: '',
                to_name: '',
                to_body: '',
                to_photo: '',
                comment: '',
                execom_id: 0,
                projectpost_id: 0,
                parent_id: 0,
                user_id: 0,
                usertags: null,
                roletags: null,
                execom_id: null,
                created_at: '',
            }),

            formExecom: new Form({
              id: '',
              proposal: '',
              projectindustry_id: '',
              program_id: '',
              proponent: '',
              company:'',
              email:'',
              region:'',
              address:'',
              age:'',
              sex:'',
              date_received:'',
              created_at:'',
              amt_requested:'',
              remarks: '',
              user_id: '',
              status: '',
            }),

            formInquiry: new Form({
                execom_id: '',
                inquirer: '',
                phone: '',
                email: '',
                details: '',
                user_id: null,
                execom_id: null,
            }),

            content: `What's on your mind?`,
            customToolbar: [
                ["bold", "italic", "underline"],
                [{ list: "ordered" }, { list: "bullet" }],
            ],

            users: [],
            items: [],
            //mentionUsers: [],
            //usertags: null,
            //roletags: null,
            //comment: '',
          }
        },

        watch: {
            vselected(newValue) {
                // console.log(newValue)
                this.form.id = newValue.id
            }

        },

        computed: {


            second() {
                return this.time / 1000;
            },

            isIdle() {
                const idlestat =  this.$store.state.idleVue.isIdle;
                if (idlestat == true) {
                $('#sessionmodal').modal('show');
                this.countDownTimer();
                } else {
                $('#sessionmodal').modal('hide');
                }
                return idlestat;
            },

            currentRouteName() {
                    return this.$route.name;
            },

        },

        methods: {

            formatPostBody(body) {
                if (!body) return []; // Prevent errors on null/undefined

                // First, replace multiple consecutive newlines with <br> repeated for the number of newlines.
                // This replaces all newlines (\n) with multiple <br> tags
                body = body.replace(/\n+/g, (match) => {
                return '<br>'.repeat(match.length); // For example, 3 newlines become <br><br><br>
                });

                // Split by words, mentions, hashtags, URLs, and spaces
                return body
                .split(/([@#]\w+|https?:\/\/\S+|\s+)/g) // Split by words, URLs, spaces, and line breaks
                .map(part => {
                    if (this.isURL(part)) {
                    return `<a href="${part}" target="_blank" class="text-blue-500 underline">${part}</a>`; // Make URL clickable
                    }
                    if (part.startsWith('@')) {
                    return `<mark><b>${part}</b></mark>`; // Highlight mentions
                    }
                    if (part.startsWith('#')) {
                    return `<mark><b>${part}</b></mark>`; // Highlight hashtags
                    }
                    return part; // Normal text
                })
                .filter(Boolean); // Remove empty strings
            },

            isURL(text) {
                return /^https?:\/\//.test(text); // Simple URL check
            },

            isUserExistingInApprovals(obj, value) {
                for (const record of obj) {
                    if (record.user_id === value) {
                        return true;
                    }
                }
                return false;
            },

            isUserAnApprover(num) {
                return this.myapproveractions.includes(num);
            },

            identifyCommentMentions() {
                this.formComment.usertags = this.formComment.comment.match(/@\w+/g);
                this.formComment.roletags = this.formComment.comment.match(/#\w+/g);
            },

            identifyPostMentions() {
                this.form.usertags = this.form.body.match(/@\w+/g);
                this.form.roletags = this.form.body.match(/#\w+/g);
            },

            postComment() {
                const frm = new FormData();
                let utags = this.comment.match(/@\w+/g);
                let rtags = this.comment.match(/#\w+/g);
                frm.append("comment", this.comment)
                frm.append("usertags", utags)
                frm.append("roletags", rtags)
                console.log(utags)
                console.log(rtags)
            },

            deleteProject(id) {
                swal.fire({
                    title: 'Are you sure?',
                    text: "Please be aware that this will delete all data related to this project. This is not reversible.",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete project!'
                    }).then((result)=>{
                    if (result.value) {
                        axios.delete('api/execoms/'+id).then(()=>{
                        swal.fire(
                            'Deleted!',
                            'Project has been deleted.',
                            'success'
                        );
                        //Trigger.$emit('RefreshData');
                        this.$router.push('/projects');
                        }).catch(()=>{
                        swal.fire(
                            'Failed!',
                            'There was something wrong',
                            'warning'
                        )
                        });
                    }
                })
            },

            deletePost(id){
                swal.fire({
                    title: 'Are you sure?',
                    text: "Please be aware that this will delete all data related to this post. This is not reversible.",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete post!'
                    }).then((result)=>{
                    if (result.value) {
                        axios.delete('api/projectposts/'+id).then(()=>{
                        swal.fire(
                            'Deleted!',
                            'Post has been deleted.',
                            'success'
                        );
                        Trigger.$emit('RefreshData');
                        }).catch(()=>{
                        swal.fire(
                            'Failed!',
                            'There was something wrong',
                            'warning'
                        )
                        });
                    }
                })
            },

            openActionmap() {
                $('#actionmapmodal').modal('show')
            },

            onOpen (key) {
                this.items = key === '@' ? this.users : this.roles
            },

            onApply (item, key, replacedWith) {
                //console.log(item, `has been replaced with ${replacedWith}`)
                this.$refs.commentbody.focus();
            },

            countDownTimer() {
                let timerId = setInterval(() => {
                this.timer -= 1;
                if (!this.$store.state.idleVue.isIdle) clearInterval(timerId);

                if (this.timer < 1) {
                    clearInterval(timerId);
                    document.getElementById('logout-form').submit();
                }
                }, 1000);
                this.timer = 30;
            },

            downloadFile(id, title) {
                axios({
                url: 'api/download-post-file/' + id,
                method: 'GET',
                responseType: 'blob', // important
                }).then((response) => {
                if (!window.navigator.msSaveOrOpenBlob){
                    // BLOB NAVIGATOR
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', title);
                    document.body.appendChild(link);
                    link.click();
                    toastr.fire({
                        icon: 'success',
                        title: 'File downloaded'
                        });
                } else {
                    // BLOB FOR EXPLORER 11
                    const url = window.navigator.msSaveOrOpenBlob(new Blob([response.data]), title);
                }
                });
            },

            imageModal(id, path) {
                this.imagepath = id + '/' + path;
                $('#imagemodal').modal('show')
            },

            editModal(post) {
                this.editmode = true;
                this.form.reset();
                this.form.clear();
                $('#postmodal').modal('show');
                this.form.fill(post);
            },

            newModal() {
                this.editmode = false;
                this.form.reset();
                this.form.clear();
                this.vselected = null;
                $('#postmodal').modal('show')
                this.$refs.postbody.focus();
            },

            newInquiryModal() {
                this.formInquiry.reset();
                this.formInquiry.clear();
                this.formInquiry.execom_id = this.$route.query.id;
                this.formInquiry.user_id = this.$gate.showMyID();
                $('#inquirymodal').modal('show')
            },

            newCommentModal(execom_id, post_id, parent_id, user_id, body, to_name, to_body, to_photo) {
                this.editmode = false;
                //console.log( to_name, to_body, to_photo)

                this.formComment.reset();
                this.formComment.clear();
                $('#commentmodal').modal('show')
                this.formComment.to_name = to_name;
                this.formComment.to_body = to_body;
                this.formComment.to_photo = to_photo;
                this.formComment.comment = body + ' ';
                this.formComment.execom_id = execom_id;
                this.formComment.projectpost_id = post_id;
                this.formComment.parent_id = parent_id;
                this.formComment.user_id = user_id;

                this.$refs.commentbody.focus();
            },

            editCommentModal(id, execom_id, post_id, parent_id, user_id, body, to_name, to_body, to_photo) {
                this.editmode = true;
                this.formComment.reset();
                this.formComment.clear();
                $('#commentmodal').modal('show')
                this.formComment.id = id;
                this.formComment.to_name = to_name;
                this.formComment.to_body = to_body;
                this.formComment.to_photo = to_photo;
                this.formComment.comment = to_body;
                this.formComment.execom_id = execom_id;
                this.formComment.projectpost_id = post_id;
                this.formComment.parent_id = parent_id;
                this.formComment.user_id = user_id;

                this.$refs.commentbody.focus();
            },

            editPostModal(id, to_body, created_at) {
                this.editmode = true;
                this.formComment.reset();
                this.formComment.clear();
                $('#postmodal').modal('show')
                this.formComment.id = id;
                this.formComment.to_body = to_body;
                this.formComment.comment = to_body;
                this.formComment.created_at = created_at;
                this.$refs.commentbody.focus();
            },

            openHistoryModal(history) {
                this.history = history;
                $('#historymodal').modal('show')
            },

            OnDragEnter(e) {
              e.preventDefault();

              this.dragCount++;
              this.isDragging = true;
              return false;
            },

            OnDragLeave(e) {
                e.preventDefault();
                this.dragCount--;
                if (this.dragCount <= 0)
                    this.isDragging = false;
            },

            onInputChange(e) {
                const files = e.target.files;
                Array.from(files).forEach(file => this.addDocument(file));
            },

            onDrop(e) {
                e.preventDefault();
                e.stopPropagation();
                this.isDragging = false;
                const files = e.dataTransfer.files;
                Array.from(files).forEach(file => this.addDocument(file));
            },

            onRemove(index) {
                //console.log(index);
                this.documents.splice(index, 1);
                this.files.splice(index, 1);
            },

            addDocument(file) {
                if (!file.type.match('image/jpeg|image/png|image/bmp|image/gif|application/vnd.ms-excel|application/msword|application/vnd.ms-powerpoint|application/pdf|application/vnd.openxmlformats-officedocument.wordprocessingml.document|application/vnd.openxmlformats-officedocument.spreadsheetml.sheet|application/vnd.openxmlformats-officedocument.presentationml.presentation')) {
                    toastr.fire({
                            icon: 'error',
                            title: `${file.name} is not supported`
                        });
                    return;
                }
                this.files.push(file);
                const img = new Image(),
                    reader = new FileReader();
                reader.onload = (e) => this.documents.push(e.target.result);
                reader.readAsDataURL(file);
                //console.log(this.execom.id);
            },

            getFileSize(size) {
                const fSExt = ['Bytes', 'KB', 'MB', 'GB'];
                let i = 0;

                while(size > 900) {
                    size /= 1024;
                    i++;
                }
                return `${(Math.round(size * 100) / 100)} ${fSExt[i]}`;
            },

            approvePost(projectpost_id, poster_id, body) {
                this.$Progress.start();

                const formData = new FormData();
                formData.append("execom_id", this.$route.query.id,)
                formData.append("projectpost_id", projectpost_id,)
                formData.append("projectposter_id", poster_id,)
                formData.append("projectpost_body", body,)

                swal.fire({
                    title: 'Are you sure to Approve?',
                    html: "Action approval <strong>cannot be undone</strong>. Once you approve, you won't be able to revert.<br/><br/><strong>• Please review the action post carefully before approving.</strong><br/><strong>• Ensure all information is accurate and final.</strong>",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Confirm & Approve'
                    }).then((result)=>{
                    if (result.value) {
                        axios.post('api/projectpostapprovals', formData).then(()=>{
                            Trigger.$emit('RefreshData');
                            toastr.fire({
                                icon: 'success',
                                title: 'You approved a post'
                            })
                            this.$Progress.finish();
                        }).catch(()=>{
                            toastr.fire({
                                icon: 'error',
                                title: 'Post approval failed'
                            });
                            this.$Progress.fail();
                        });
                    }
                })

                // axios.post('api/projectpostapprovals', formData).then(() => {
                //     Trigger.$emit('RefreshData');
                //     toastr.fire({
                //         icon: 'success',
                //         title: 'You approved a post'
                //     })
                //     this.$Progress.finish();
                // }).catch(()=>{
                //     toastr.fire({
                //         icon: 'error',
                //         title: 'Post approval failed'
                //     });
                //     this.$Progress.fail();
                // })
            },

            createInquiry() {
                this.$Progress.start();
                this.formInquiry.post('api/projectinquiries').then(() => {
                    Trigger.$emit('RefreshData');
                    $('#inquirymodal').modal('hide');
                    toastr.fire({
                        icon: 'success',
                        title: 'Inquiry posted successfully'
                    })
                    this.$Progress.finish();
                }).catch(()=>{
                    toastr.fire({
                        icon: 'error',
                        title: 'Inquiry posting failed'
                    });
                    this.$Progress.fail();
                })
            },

            closeInquiry(id) {
                this.$Progress.start();
                axios.put('api/projectinquiries/close/'+id).then(() => {
                    Trigger.$emit('RefreshData');
                    toastr.fire({
                        icon: 'success',
                        title: 'Inquiry marked as closed'
                    })
                    this.$Progress.finish();
                }).catch(()=>{
                    toastr.fire({
                        icon: 'error',
                        title: 'Inquiry closing failed'
                    });
                    this.$Progress.fail();
                })
            },

            openInquiry(id) {
                this.$Progress.start();
                axios.put('api/projectinquiries/open/'+id).then(() => {
                    Trigger.$emit('RefreshData');
                    toastr.fire({
                        icon: 'success',
                        title: 'Inquiry marked as open'
                    })
                    this.$Progress.finish();
                }).catch(()=>{
                    toastr.fire({
                        icon: 'error',
                        title: 'Inquiry opening failed'
                    });
                    this.$Progress.fail();
                })
            },


            createPost() {
                this.$Progress.start();

                const formData = new FormData();

                //console.log(formData)

                this.files.forEach(file => {
                    formData.append('documents[]', file, file.name);
                });

                if (Array.isArray(this.form.usertags) && this.form.usertags.length > 0) {
                    this.form.usertags.forEach((username, index) => {
                        const cleanedUsername = username.replace('@', '');
                        formData.append(`tagged_users[${index}]`, cleanedUsername);
                    });
                }

                if (Array.isArray(this.form.roletags) && this.form.roletags.length > 0) {
                    this.form.roletags.forEach((rolename, index) => {
                        const cleanedRole = rolename.replace('#', '');
                        formData.append(`tagged_roles[${index}]`, cleanedRole);
                    });
                }


                formData.append("execom_id", this.$route.query.id,)
                formData.append("projectactiongroup_id", this.vselected.projectactiongroup_id,)
                formData.append("projectaction_id", this.vselected.id,)
                formData.append("projectaction_title", this.vselected.action)
                formData.append("body", this.form.body)

                // console.log(formData)

                swal.fire({
                    title: 'Are you sure?',
                    html: "This action <strong>cannot be undone</strong>. Once you post, you won't be able to revert this content.<br/><br/><strong>• Please review your post carefully before submitting.</strong><br/><strong>• Ensure all information is accurate and final.</strong>",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Confirm & Post'
                    }).then((result)=>{
                    if (result.value) {
                        axios.post('api/projectposts', formData).then(()=>{
                            Trigger.$emit('RefreshData');
                            $('#postmodal').modal('hide');
                            toastr.fire({
                                icon: 'success',
                                title: 'Action posted successfully'
                            })
                            this.$Progress.finish();
                        }).catch(()=>{
                            toastr.fire({
                                icon: 'error',
                                title: 'Action posting failed'
                            });
                            this.$Progress.fail();
                        });
                    }
                })

                // axios.post('api/projectposts', formData).then(() => {
                //     Trigger.$emit('RefreshData');
                //     $('#postmodal').modal('hide');
                //     toastr.fire({
                //         icon: 'success',
                //         title: 'Action posted successfully'
                //     })
                //     //this.documents = [];
                //     //this.files = [];
                //     this.$Progress.finish();
                // }).catch(()=>{
                //     toastr.fire({
                //         icon: 'error',
                //         title: 'Action posting failed'
                //     });
                //     this.$Progress.fail();
                // })
            },

            updatePost() {
                this.$Progress.start();

                this.formComment.execom_id = this.$route.query.id;

                axios.put('api/projectposts/'+this.formComment.id, this.formComment).then(() => {
                    Trigger.$emit('RefreshData');
                    $('#postmodal').modal('hide');
                    toastr.fire({
                        icon: 'success',
                        title: 'Action post updated successfully'
                    })
                    this.$Progress.finish();
                }).catch(()=>{
                    toastr.fire({
                        icon: 'error',
                        title: 'Action post updating failed'
                    });
                    this.$Progress.fail();
                })
            },

            createComment() {
                this.$Progress.start();
                axios.post('api/projectpostcomments', this.formComment).then(() => {
                    Trigger.$emit('RefreshData');
                    $('#commentmodal').modal('hide');
                    toastr.fire({
                        icon: 'success',
                        title: 'Comment posted successfully'
                    })
                    this.$Progress.finish();
                }).catch(()=>{
                    toastr.fire({
                        icon: 'error',
                        title: 'Comment posting failed'
                    });
                    this.$Progress.fail();
                })
            },

            updateComment() {
                this.$Progress.start();
                axios.put('api/projectpostcomments/'+this.formComment.id, this.formComment).then(() => {
                    Trigger.$emit('RefreshData');
                    $('#commentmodal').modal('hide');
                    toastr.fire({
                        icon: 'success',
                        title: 'Comment updated successfully'
                    })
                    this.$Progress.finish();
                }).catch(()=>{
                    toastr.fire({
                        icon: 'error',
                        title: 'Comment updating failed'
                    });
                    this.$Progress.fail();
                })
            },

            editProjectModal(execom) {
                this.editmode = true;
                this.formExecom.reset();
                this.formExecom.clear();
                $('#execommodal').modal('show');
                this.formExecom.fill(execom);
            },

            updateExecom() {
              this.$Progress.start();
              this.formExecom.put('api/execoms/'+this.formExecom.id).then(()=>{
                Trigger.$emit('RefreshData');
                $('#execommodal').modal('hide')
                toastr.fire({
                  icon: 'success',
                  title: 'Project information updated successfully'
                })
                this.$Progress.finish();
              }).catch(()=>{
                toastr.fire({
                  icon: 'error',
                  title: 'Project information updating failed'
                });
                this.$Progress.fail();
              });
            },

            async loadData() {
                this.$Progress.start();
                this.loading = true;

                await axios.get("api/execoms/" + this.id).then((data) => {
                    this.execom = data.data;
                    if (data.data.total == 0)
                    {
                        this.emptyflag = true;
                    }
                })

                await axios.get("api/projectactiongroups/posts/" + this.id).then((data) => { this.projectactiongroups = data.data; })
                await axios.get('api/projectactions/user/' + this.$gate.showMyID() + '/' + this.id).then(({ data }) => ( this.myprojectactions = data ));
                await axios.get('api/projectactions/approver/' + this.$gate.showMyID() + '/' + this.id).then(({ data }) => ( this.myapproveractions = data ));
                await axios.get('api/projectroles/user/' + this.$gate.showMyID()).then(({ data }) => ( this.myprojectrole = data ));

                const urlhash = window.location.hash;
                if (urlhash) {
                    this.scrollTo(urlhash);
                }

                //this.loadIssues(searchText = null)

                //if(!searchText)
                //    this.items = await fetchIssues(searchText)

                this.loading = false;
                this.$Progress.finish();
            },

            scrollTo(element) {
                //const el = document.getElementById(element);
                //el.scrollIntoView({ behavior: "smooth" });
                //document.getElementById(element).style.background = "#e8eafa";
                window.location.href = element;
            },

            isPermitted(moduleid) {
                axios.get('api/permission/' + this.$gate.showMyID() + '/' + moduleid)
                .then(data => {
                    // here you can access the data
                    this.accessflag = data.data.isChecked;
                });
            },

        },

        created() {
            axios.get('api/projectactions').then(({ data }) => ( this.projectactions = data ));
            axios.get("api/projectindustries").then(({ data }) => (this.projectindustries = data));
            axios.get("api/programs").then(({ data }) => (this.programs = data));
            axios.get("api/users/allinservice").then(({ data }) => (this.users = data));
            axios.get('api/projectroles').then(({ data }) => ( this.roles = data ));
            this.isPermitted(70);

            this.loadData();
            Trigger.$on('RefreshData',() => {
                this.loadData();
            });
        },

        mounted() {
            this.$watch('formComment.comment', function(newVal, oldVal) {
                this.identifyCommentMentions()
                //this.results.push('Old value:'+oldVal+', new value: '+newVal)
            })

            this.$watch('form.body', function(newVal, oldVal) {
                this.identifyPostMentions()
            })
        },

        directives: {
            focus: {
                // directive definition
                inserted: function (el) {
                el.focus()
                }
            }
        }


    }
</script>

<style scoped>
html{scroll-behavior:smooth !important;}
@media all and (max-width:768px) {
  .sticky-top {
    position: static !important; /* Override Bootstrap's sticky positioning */
  }
}
.btn-xs {
    padding: 0.125rem 0.25rem !important;
    font-size: .75rem !important;
    line-height: 1.5 !important;
    border-radius: 0.15rem !important;
}
ul.timeline > li {
    margin: 10px 0px 0px 0px !important;
    padding-left: 0px !important;
}
ul.timeline > li:before {
    left: 0px !important;
}
ul.timeline:before {
    left: 8px !important;
    width: 4px !important;
}
.dropdown-toggle::after {
        display: none !important;
}
.dropdown-toggle {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    padding: 0;
    text-align: center;
    background-color: transparent;
    transition: background-color 0.3s ease;
}

.dropdown-toggle:hover {
    background-color: #F4F6F9;
}
.image-container {
    position: relative;
    max-width: 100%;
    height: 45px;
}

.ixon {
    position: absolute;
    top: 20px;
    right: 0px;
    background: #007BFF;
    padding: 5px;
    height: 25px;
    width: 25px;
    z-index: 999;
    border-radius: 25px;
}

.handle {
    cursor: pointer;
}

.tooltip-inner {
    color: #FFFFFF;
    border-radius: 16px;
    padding: 5px 5px 5px;
    text-align: left;
    background-color: #000000;
}

mark {
  border-radius: 7px;
}
.timeline-body {
    padding-bottom: 0 !important;
}
.timeline-footer {
    padding-top: 0 !important;
}

.mailbox-attachment-name {
    font-size: 10px !important;
}
.mailbox-attachment-info {
    position: absolute;
    bottom: -2px;
    width: 100%;
    opacity: 0.9;
    padding: 5px;
}

.mailbox-attachment-icon {
    background-color: #dcdcdc;
}

.mailbox-attachments li {
    position:relative;
    height: 170px !important;
    width: 175px !important;
}
.fill {
    display: flex;
    justify-content: center;
    /*
    align-items: center;
    overflow: hidden*/
}
.fill img {
    /*
    flex-shrink: 0;
    min-width: 100%;
    min-height: 100%*/
    width: 175px;
  height: 170px;
  object-fit: cover;
}
.wrapper {
    max-width: 1000px;
    width: fit-content;
}

.tooltip .tooltip-inner {
    background: transparent !important;
    color: pink  !important;
    background-color: transparent !important;
}
.tooltip-inner {
    /*max-width: 500px;*/
    color: pink  !important;
    text-align: center;
    background-color: transparent !important;
}

.issue {
  padding: 4px 6px !important;
  border-radius: 4px !important;
  cursor: pointer !important;
}

.mention-selected .issue {
  background: rgb(139, 212, 255) !important;
}

.issue .number {
  font-family: monospace !important;
}

.dim {
  color: #666 !important;
  background-color: transparent !important;
}

.mention-item {
  padding: 4px 10px !important;
  border-radius: 4px !important;
}

.btn-circle {
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 6px 0;
  font-size: 12px;
  line-height: 1.428571429;
  border-radius: 15px;
}
.btn-circle.btn-lg {
  width: 50px;
  height: 50px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33;
  border-radius: 25px;
}
.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  font-size: 24px;
  line-height: 1.33;
  border-radius: 35px;
}

.textarea-container {
  position: relative;
}
.textarea-container textarea {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
.textarea-container button {
  position: absolute;
  bottom: 10px;
  right: 10px;
}
.card-comments {
    background: rgba(248, 249, 250, .4) !important;
    border-bottom: 1px solid #e9ecef;
    border-top: 1px solid #e9ecef;
}

.sticky-top {
  align-self: flex-start;
}
/*image*/
.img-thumbnail{
  height: 164px;
  overflow: hidden;
  margin: auto;

}

.user-block {
    float: none !important;
}

ul.timeline {
    list-style-type: none;
    position: relative;
}
ul.timeline:before {
    content: ' ';
    background: #d4d9df;
    display: inline-block;
    position: absolute;
    left: 29px;
    width: 2px;
    height: 100%;
    z-index: 400;
}
ul.timeline > li {
    margin: 20px 0;
    padding-left: 20px;
}
ul.timeline > li:before {
    content: ' ';
    background: white;
    display: inline-block;
    position: absolute;
    border-radius: 50%;
    border: 3px solid #22c0e8;
    left: 20px;
    width: 20px;
    height: 20px;
    z-index: 400;
}

.timestamp {
    color: #999;
    float: right;
    font-size: 12px;
}

.user-block .username {
    font-weight: 650 !important;
    font-size: 18px !important;
}

.post a {
    text-decoration: none !important;
}

.post-block .postinput {
    display: block;
    margin-left: 46px;
}

.post-block img {
    float: left;
    height: 40px;
    width: 40px;
}

.btn-link {
    padding-bottom: 0px !important;
}

.aligntobox {
    margin-left: 48px;
}

.imahe {
    cursor: pointer;
}

.img-sm {
    height: 1.875rem !important;
    width: 1.875rem !important;
}

.postinputx {
    display: block;
    margin-left: 30px;
}

div.timeline-item:target {
    scroll-behavior: smooth;
	-webkit-animation: target-fade 0.5s;
	-moz-animation: target-fade 0.5s;
	-o-animation: target-fade 0.5s;
	animation: target-fade 0.5s;
    background-color: #e8eafa;
}

div.direct-chat-text:target {
    scroll-behavior: smooth;
	-webkit-animation: target-fade 0.5s;
	-moz-animation: target-fade 0.5s;
	-o-animation: target-fade 0.5s;
	animation: target-fade 0.5s;
    background-color: #e8eafa;
}

/**
 * Keyframe animation definition
 *
 * 1. Insert a color of your choice here
 */
@-webkit-keyframes target-fade {
	from { background-color: #6574cd; } /* [1] */
	to { background-color: #e8eafa; }
}
@-moz-keyframes target-fade {
	from { background-color: #6574cd; } /* [1] */
	/* to { background-color: transparent; } */
    to { background-color: #e8eafa; }
}
@-o-keyframes target-fade {
	from { background-color: #6574cd; } /* [1] */
	/* to { background-color: transparent; } */
    to { background-color: #e8eafa; }
}
@keyframes target-fade {
	from { background-color: #6574cd; } /* [1] */
	to { background-color: transparent; }
}


</style>

<style lang="scss" scoped>
.info-box {
    padding: 0 !important;
}
.uploader {
    width: 100%;
    color: #000000;
    background: #F4F6FC;
    padding: 30px 15px 15px 5px;
    text-align: center;
    border-radius: 10px;
    border: 3px dashed #C0C0C0;
    font-size: 20px;
    position: relative;
    &.dragging {
        background: #fff;
        color: #2196F3;
        border: 3px dashed #2196F3;
        .file-input label {
            /*background: #2196F3;*/
            color: #2196F3;
        }
    }
    i {
        font-size: 10px;
    }
    .info-box {
        text-align: left;
        color: #000;
        margin-left: 10px;
        min-height: 55px;
        .info-box-icon {
            width: 45px;
        }
    }
    .info-box-number {
    width: 160px;
    overflow: hidden;
    }
    .file-input {
        width: 160px;
        margin: auto;
        height: 30px;
        position: relative;
        label,
        input {
        /*
            background: #DEDEDE;
            color: #2196F3;
            */
            width: 100%;
            position: absolute;
            left: 0;
            top: 5;
            /*
            padding: 10px;
            border-radius: 4px;
            margin-top: 7px;
            */
            cursor: pointer;
        }
        input {
            opacity: 0;
            z-index: -2;
        }
    }
    .images-preview {
        display: flex;
        flex-wrap: wrap;
        margin-top: 20px;
        .img-wrapper {
            width: 100%;
            display: flex;
            flex-direction: column;
            margin: 10px;
            height: 40px;
            justify-content: space-between;
            background: #fff;
            box-shadow: 5px 5px 20px #3e3737;
            img {
                max-height: 105px;
            }
        }
        .details {
            font-size: 12px;
            background: #fff;
            color: #000;
            display: flex;
            flex-direction: column;
            align-items: self-start;
            padding: 3px 6px;
            .name {
                overflow: hidden;
                height: 18px;
            }
        }
    }
    .upload-control {
        position: absolute;
        width: 100%;
        background: #F4F6FC;
        top: 0;
        left: 0;
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;
        padding: 10px;
        padding-bottom: 4px;
        text-align: right;
        button, label {
            background: #2196F3;
            border: 2px solid #03A9F4;
            border-radius: 3px;
            color: #fff;
            font-size: 15px;
            cursor: pointer;
        }
        label {
            padding: 2px 5px;
            margin-right: 10px;
        }
    }
}
</style>
