<template>
    <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card card-secondary card-outline">
              <div class="card-header">
                <h3 class="card-title"><i class="fas fa-book"></i> Annual Procurement Plan (APP)</h3>

                <div class="card-tools" v-if="ppmpitems.length != 0">
                    <span class="badge badge-secondary ml-1 mr-1"><i>No. of Items:</i> <b>{{ ppmpitems.length }}</b></span>
                    <span class="badge badge-secondary"><i>Total Amount:</i> <b>{{ this.total_expenses | toMoney }}</b></span>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="row m-0 bg-secondary disabled color-palette">
                  <div class="col-md-4">
                    <div class="btn-group mt-2 mb-2 d-flex justify-content-start" v-if="ppmpitems.length != 0">
                        <download-excel class="btn btn-default" data-container="body" :data="ppmpitems" :fields="excel_fields" :name="file_name" :header="excel_header" :title="excel_title" :footer="excel_footer"><i class="fa fa-file-excel"></i> EXPORT TO EXCEL</download-excel>
                        <button type="button" class="btn btn-default" @click="printModal()"><i class="fa fa-print"></i> PRINT</button>
                    </div>
                  </div>
                  <div class="col-md-6 mt-2 mb-2">

                  </div>
                  <div class="col-md-2">
                    <div class="input-group input-group-md mt-2 mb-2 d-flex justify-content-end">
                        <div class="input-group-prepend">
                        <span class="input-group-text">APP YR</span>
                        </div>
                        <select @change="loadPpmpitems(year)" name="type" v-model="year" class="form-control">
                            <option v-for="year in years" :value="year" :key="year">{{ year }}</option>
                        </select>
                    </div>
                  </div>

              </div>

              <div class="card-body table-responsive p-0 m-0">
                <table class="table responsive-table table-sm table-striped">
                  <thead>
                      <tr>
                      <th class="text-left" scope="col">Code</th>
                      <th class="text-left" scope="col">Procurement Project</th>
                      <th class="text-left" scope="col">PMO/End-User</th>
                      <th class="text-left" scope="col">Is this an Early Procurement Activity? (Yes/No)</th>
                      <th class="text-left" scope="col">Mode of Procurement</th>
                      <th class="text-left" scope="col">Advertisment/Posting of IB/REI</th>
                      <th class="text-left" scope="col">Submission/Opening of Bids</th>
                      <th class="text-left" scope="col">Notice of Award</th>
                      <th class="text-left" scope="col">Contract Signing</th>
                      <th class="text-left" scope="col">Source of Funds</th>
                      <th class="text-left" scope="col">Total</th>
                      <th class="text-left" scope="col">MOOE</th>
                      <th class="text-left" scope="col">CO</th>
                      <th class="text-left" scope="col">Remarks (brief description of Project)</th>
                      <th class="text-center" scope="col"></th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr v-for="itms in ppmpitems" :key="itms.id">
                          <td class="text-left border-left" data-title="Code">{{ itms.procurementitem.item_code }}</td>
                          <td class="text-left border-left" data-title="Procurement Project"><small>{{ itms.procurementitem.description }}</small></td>
                          <td class="text-left border-left" data-title="PMO/End-User"><small>{{ itms.ppmp_id }}{{ itms.ppmp.office.alias }}</small></td>
                          <td class="text-left border-left" data-title="Is this an Early Procurement Activity? (Yes/No)">{{ itms.early_procurement }}</td>
                          <td class="text-left border-left" data-title="Mode of Procurement"><small>{{ itms.procmode.alias }}</small></td>
                          <td class="text-left border-left" data-title="Advertisment/Posting of IB/REI"><small>{{ itms.date_ibrei }}</small></td>
                          <td class="text-left border-left" data-title="Submission/Opening of Bids"><small>{{ itms.date_bidopening }}</small></td>
                          <td class="text-left border-left" data-title="Notice of Award"><small>{{ itms.date_noa }}</small></td>
                          <td class="text-left border-left" data-title="Contract Signing"><small>{{ itms.date_signing }}</small></td>
                          <td class="text-left border-left" data-title="Source of Funds">{{ itms.fundsource.alias }}</td>
                          <td class="text-left border-left" data-title="Total">{{ itms.unit_cost * itms.tot_qty | toMoney  }}</td>
                          <td class="text-left border-left" data-title="MOOE" v-if="itms.operating_program == 'MOOE'">{{ itms.unit_cost * itms.tot_qty | toMoney  }}</td>
                          <td class="text-left border-left" data-title="MOOE" v-else>0.00</td>
                          <td class="text-left border-left" data-title="CO" v-if="itms.operating_program == 'CO'">{{ itms.unit_cost * itms.tot_qty | toMoney  }}</td>
                          <td class="text-left border-left" data-title="CO" v-else>0.00</td>
                          <td class="text-left border-left" data-title="Remarks (brief description of Project)"></td>
                          <td class="text-left border-left" data-title="">
                            <div class="btn-group">
                              <button :disabled="ppmpdetails.status == 3" type="button" class="btn btn-default" @click="editModal(itms)"><i class="fas fa-edit text-primary"></i></button>
                              <!--button :disabled="ppmpdetails.status == 3" type="button" class="btn btn-default" @click="deletePpmpItem(itms.id)"><i class="fas fa-times text-danger"></i></button-->
                            </div>
                          </td>
                      </tr>
                  </tbody>
                  <tbody v-if="ppmpitems" v-show="ppmpitems.length==0">
                      <tr><td colspan="21">No procurement items found.</td></tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body <pagination :data="ppmpitems" @pagination-change-page="getResults" show-disabled :limit="2"></pagination> -->
              <div class="card-footer">
                <div class="card-tools d-flex justify-content-end">

                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="actionmodal" tabindex="-1" role="dialog" aria-labelledby="actionModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="actionModalLabel">Item Details</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div class="modal-body">
                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>Mode of Procurement</label>
                      <select v-model="form.mod_code" name="type" class="form-control">
                        <option v-for="(src,idx) in procmodes" :value="src.code" :key="idx">{{ src.alias }}</option>
                      </select>
                    </div>
                    <div class="form-group">
                      <label>Source of Fund</label>
                      <select v-model="form.src_id" name="type" class="form-control">
                        <option v-for="(src,idx) in fundsources" :value="src.id" :key="idx">{{ src.alias }}</option>
                      </select>
                    </div>
                    <div class="form-group">
                      <label>Is this an Early Procurement Activity?</label>
                      <select v-model="form.early_procurement" name="type" class="form-control">
                        <option value="NO">NO</option>
                        <option value="YES">YES</option>
                      </select>
                    </div>
                    <div class="form-group">
                      <label>Operating Program</label>
                      <select v-model="form.operating_program" name="type" class="form-control">
                        <option value="MOOE">MOOE</option>
                        <option value="CO">CO</option>
                      </select>
                    </div>

                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>Advertisment/Posting of IB/REI</label>
                      <input type="date" class="form-control" placeholder="yyyy-mm-dd" v-model="form.date_ibrei">
                    </div>
                    <div class="form-group">
                      <label>Submission/Opening of Bids</label>
                      <input type="date" class="form-control" placeholder="yyyy-mm-dd" v-model="form.date_bidopening">
                    </div>
                    <div class="form-group">
                      <label>Notice of Award</label>
                      <input type="date" class="form-control" placeholder="yyyy-mm-dd" v-model="form.date_noa">
                    </div>
                    <div class="form-group">
                      <label>Contract Signing</label>
                      <input type="date" class="form-control" placeholder="yyyy-mm-dd" v-model="form.date_signing">
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <button class="btn btn-primary" data-container="body" type="button" @click="updateItem()"><i class="fa fa-save"></i> Save</button>
                <button class="btn btn-primary" data-container="body" type="button" data-dismiss="modal" aria-label="Close"><i class="fa fa-times"></i> Close</button>
              </div>
            </div>
          </div>
        </div>

        <!-- Print Modal -->
        <div class="modal fade" id="printmodal" tabindex="-1" role="dialog" aria-labelledby="printModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
            <div class="modal-content">
              <div class="modal-header bg-success">
                <button v-print="'#printAppendix60'" @click="print" class="btn btn-primary mr-2" data-container="body" type="button"><i class="fa fa-print"></i> Print</button>
                <button class="btn btn-primary mr-2" data-container="body" type="button" data-dismiss="modal" aria-label="Close"><i class="fa fa-times"></i> Close</button>

                <select v-model="app_type" name="type" class="form-control mr-2" style="width:300px">
                    <option value="2">Annual Procurement Plan</option>
                    <option value="1">Indicative APP-Non-CSE</option>
                    <option value="3">Supplemental APP</option>
                    <!-- option value="4">APP for Common-Use Supplies and Equipment</option -->
                </select>

                <div v-if="app_type == 3"><input type="date" class="form-control" placeholder="yyyy-mm-dd" v-model="as_of"></div>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div class="modal-body p-0 m-0">
                <div id="printAppendix60">
                  <div class="card-body" style="font-family: Times New Roman;">
                    <table class="table table-borderless customtextsize" style="border:2px solid transparent;">
                        <tr>
                          <td colspan="17" align="center">
                            <b v-if="app_type == 1">Technology Application and Promotion Institute (TAPI) Indicative Annual Procurement Plan form FY {{ year }} - Non-CSE</b>
                            <b v-if="app_type == 2">Technology Application and Promotion Institute (TAPI) Annual Procurement Plan form FY {{ year }}</b>
                            <b v-if="app_type == 3">
                                Technology Application and Promotion Institute (TAPI)<br/>
                                FY - {{ year }} Annual Procurement Plan - Non-CSE - Indicative (Supplemental)<br/>
                                as of {{ as_of | customdate2 }}
                            </b>
                          </td>
                        </tr>
                        <!-- <tr>
                          <td colspan="17" align="left">END USER/UNIT: <b>{{ this.office_name }}</b><br/><b><i>Charged to GAA {{ this.year }}</i></b><br/><i>Projects, Programs and Activities (PAPs)</i></td>
                        </tr> -->
                    </table>
                    <table class="table table-borderless customtextsize" style="border:2px solid #000000;">
                      <thead>
                        <tr>
                          <td rowspan="2" style="border:2px solid #000000;padding:2px;width:50px;" align="center"><b><small>Code</small><br/><small>(PAP)</small></b></td>
                          <td rowspan="2" style="border:2px solid #000000;padding:2px;" align="center"><b><small>Procurement Program/Project</small></b></td>
                          <td rowspan="2" style="border:2px solid #000000;padding:2px;" align="center"><b><small>PMO/ End-User</small></b></td>
                          <td rowspan="2" style="border:2px solid #000000;padding:2px;" align="center" v-if="app_type == 1 || app_type == 2"><b><small>Is this an Early Procurement Activity? (Yes/No)</small></b></td>
                          <td rowspan="2" style="border:2px solid #000000;padding:2px;width:110px;" align="center"><b><small>Mode of Procurement</small></b></td>
                          <td rowspan="1" colspan="4" style="border:2px solid #000000;padding:2px;" align="center"><b><small>Schedule for Each Procurement Activity</small></b></td>
                          <td rowspan="2" style="border:2px solid #000000;padding:2px;width:130px;" align="center"><b><small>Source of Funds</small></b></td>
                          <td rowspan="1" colspan="3" style="border:2px solid #000000;padding:2px;" align="center"><b><small>Estimated Budget (PhP)</small></b></td>
                          <td rowspan="2" style="border:2px solid #000000;padding:2px;" align="center"><b><small>Remarks (brief description of Program/Activity/ Project)</small></b></td>
                        </tr>
                        <tr>
                          <td style="border:2px solid #000000;padding:2px;width:90px;" align="center"><b><small>Advertisement/ Posting of IB/REI</small></b></td>
                          <td style="border:2px solid #000000;padding:2px;width:90px;" align="center"><b><small>Submission/ Opening of Bids</small></b></td>
                          <td style="border:2px solid #000000;padding:2px;width:90px;" align="center"><b><small>Notice of Award</small></b></td>
                          <td style="border:2px solid #000000;padding:2px;width:90px;" align="center"><b><small>Contract<br/>Signing</small></b></td>
                          <td style="border:2px solid #000000;padding:2px;width:90px;" align="center"><b><small>Total</small></b></td>
                          <td style="border:2px solid #000000;padding:2px;width:90px;" align="center"><b><small>MOOE</small></b></td>
                          <td style="border:2px solid #000000;padding:2px;width:90px;" align="center"><b><small>CO</small></b></td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="itms in ppmpitems" :key="itms.id">
                          <td style="border:2px solid #000000;padding:2px;"><small>{{ itms.procurementitem.item_code }}</small></td>
                          <td style="border:2px solid #000000;padding:2px;"><small>{{ itms.procurementitem.description }}</small></td>
                          <td style="border:2px solid #000000;padding:2px;" class="text-center"><small>{{ itms.ppmp.office.alias }}</small></td>
                          <td style="border:2px solid #000000;padding:2px;" class="text-center" v-if="app_type == 1 || app_type == 2"><small>{{ itms.early_procurement }}</small></td>
                          <td style="border:2px solid #000000;padding:2px;" class="text-left"><small>{{ itms.procmode.alias }}</small></td>
                          <td style="border:2px solid #000000;padding:2px;" class="text-center"><small>{{ itms.date_ibrei }}</small></td>
                          <td style="border:2px solid #000000;padding:2px;" class="text-center"><small>{{ itms.date_bidopening }}</small></td>
                          <td style="border:2px solid #000000;padding:2px;" class="text-center"><small>{{ itms.date_noa }}</small></td>
                          <td style="border:2px solid #000000;padding:2px;" class="text-center"><small>{{ itms.date_signing }}</small></td>
                          <td style="border:2px solid #000000;padding:2px;" class="text-center"><small>{{ itms.fundsource.alias }}</small></td>
                          <td style="border:2px solid #000000;padding:2px;" class="text-right"><small>{{ itms.unit_cost * itms.tot_qty | toMoney  }}</small></td>
                          <td style="border:2px solid #000000;padding:2px;" class="text-right" v-if="itms.operating_program == 'MOOE'"><small>{{ itms.unit_cost * itms.tot_qty | toMoney  }}</small></td>
                          <td style="border:2px solid #000000;padding:2px;" class="text-right" v-else><small>0.00</small></td>
                          <td style="border:2px solid #000000;padding:2px;" class="text-right" v-if="itms.operating_program == 'CO'"><small>{{ itms.unit_cost * itms.tot_qty | toMoney  }}</small></td>
                          <td style="border:2px solid #000000;padding:2px;" class="text-right" v-else><small>0.00</small></td>
                          <td style="border:2px solid #000000;padding:2px;"><small>{{ itms.remarks }}</small></td>
                        </tr>
                        <tr class="footer">
                          <td style="padding:2px;" align="right" colspan="17" ><br/><b><u>GRAND TOTAL:</u> {{ this.total_expenses | toMoney }}</b><br/><br/></td>
                        </tr>
                        </tbody>
                    </table>
                    <table v-if="app_type == 1 || app_type == 2">
                        <tbody>
                        <tr>
                          <td colspan="3" style="padding:2px;" align="left"><small>Prepared and Consolidated by:</small><br/><br/></td>
                          <td colspan="6" style="padding:2px;" align="left"><small>Concurred by BAC Members:</small><br/><br/></td>
                          <td colspan="3" style="padding:2px;" align="left"><small>Recommending Approval:</small><br/><br/></td>
                          <td colspan="2" style="padding:2px;" align="left"><small>Approved by:</small><br/><br/></td>
                        </tr>
                        <tr>
                          <td colspan="3" style="padding:2px;" align="left"><b><u><small>{{ this.$gate.showFname() }} {{ this.$gate.showMname() | midname }} {{ this.$gate.showLname() }}</small></u></b></td>
                          <td colspan="3" style="padding:2px;" align="left">
                            <select class="custom-select" placeholder="- Select BAC Member -">
                                <option value='0'>SELECT BAC MEMBER ▼ </option>
                                <option v-for="sg in bac_members" :key="sg.id" :value="sg.id">{{ sg.user.lname }}, {{ sg.user.fname }} {{ sg.user.mname | midname }}</option>
                            </select>
                          </td>
                          <td colspan="3" style="padding:2px;" align="left">
                            <select class="custom-select" placeholder="- Select BAC Member -">
                                <option value='0'>SELECT BAC MEMBER ▼ </option>
                                <option v-for="sg in bac_members" :key="sg.id" :value="sg.id">{{ sg.user.lname }}, {{ sg.user.fname }} {{ sg.user.mname | midname }}</option>
                            </select>
                          </td>
                          <td colspan="3" style="padding:2px;" align="left">
                            <select class="custom-select" placeholder="- Select BAC Chairperson -">
                                <option value='0'>SELECT BAC CHAIRPERSON ▼ </option>
                                <option v-for="sg in bac_chair" :key="sg.id" :value="sg.id">{{ sg.user.lname }}, {{ sg.user.fname }} {{ sg.user.mname | midname }}</option>
                            </select>
                          </td>
                          <td colspan="2" style="padding:2px;" align="left">
                            <select class="custom-select" placeholder="- Select Director / OIC -">
                                <option value='0'>SELECT DIRECTOR / OIC ▼ </option>
                                <option v-for="sg in bac_director" :key="sg.id" :value="sg.id">{{ sg.user.lname }}, {{ sg.user.fname }} {{ sg.user.mname | midname }}</option>
                            </select>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="3" style="padding:2px;" align="left"><small>Head, BAC Secretariat</small><br/></td>
                          <td colspan="3" style="padding:2px;" align="left"><small>BAC Member</small><br/></td>
                          <td colspan="3" style="padding:2px;" align="left"><small>BAC Member</small><br/></td>
                          <td colspan="3" style="padding:2px;" align="left"><small>BAC Chairperson</small><br/></td>
                          <td colspan="2" style="padding:2px;" align="left"><small>Director, TAPI</small><br/></td>
                        </tr>
                        <tr>
                          <td colspan="14" style="padding:2px;" align="left"><small></small><br/></td>
                        </tr>
                        <tr>
                          <td colspan="3" style="padding:2px;" align="left"></td>
                          <td colspan="3" style="padding:2px;" align="left">
                            <select class="custom-select" placeholder="- Select BAC Member -">
                                <option value='0'>SELECT BAC MEMBER ▼ </option>
                                <option v-for="sg in bac_members" :key="sg.id" :value="sg.id">{{ sg.user.lname }}, {{ sg.user.fname }} {{ sg.user.mname | midname }}</option>
                            </select>
                          </td>
                          <td colspan="3" style="padding:2px;" align="left">
                            <select class="custom-select" placeholder="- Select BAC Member -">
                                <option value='0'>SELECT BAC MEMBER ▼ </option>
                                <option v-for="sg in bac_members" :key="sg.id" :value="sg.id">{{ sg.user.lname }}, {{ sg.user.fname }} {{ sg.user.mname | midname }}</option>
                            </select>
                          </td>
                          <td colspan="3" style="padding:2px;" align="left"></td>
                          <td colspan="2" style="padding:2px;" align="left"></td>
                        </tr>
                        <tr>
                          <td colspan="3" style="padding:2px;" align="left"><small></small><br/></td>
                          <td colspan="3" style="padding:2px;" align="left"><small>BAC Member</small><br/></td>
                          <td colspan="3" style="padding:2px;" align="left"><small>BAC Member</small><br/></td>
                          <td colspan="3" style="padding:2px;" align="left"><small></small><br/></td>
                          <td colspan="2" style="padding:2px;" align="left"><small></small><br/></td>
                        </tr>
                      </tbody>
                    </table>
                    <table v-if="app_type == 3">
                        <tbody>
                        <tr>
                          <td colspan="4" style="padding: 2px;width:260px;" align="left"><small>Prepared and Consolidated by:</small><br/><br/></td>
                          <td colspan="4" style="padding:2px;" align="left"><small>Recommended by:</small><br/><br/></td>
                          <td colspan="5" style="padding:2px;" align="left"><small>Approved by:</small><br/><br/></td>
                        </tr>
                        <tr>
                          <td colspan="4" style="padding:2px;" align="left">
                            <b><u><small>{{ this.$gate.showFname() }} {{ this.$gate.showMname() | midname }} {{ this.$gate.showLname() }}</small></u></b>
                          </td>
                          <td colspan="4" style="padding:2px;" align="left">
                            <select class="custom-select" placeholder="- Select BAC Member -">
                                <option value='0'>SELECT BAC MEMBER ▼ </option>
                                <option v-for="sg in bac_members" :key="sg.id" :value="sg.id">{{ sg.user.lname }}, {{ sg.user.fname }} {{ sg.user.mname | midname }}</option>
                            </select>
                          </td>
                          <td colspan="5" style="padding:2px;" align="left">
                            <select class="custom-select" placeholder="- Select Director / OIC -">
                                <option value='0'>SELECT DIRECTOR / OIC ▼ </option>
                                <option v-for="sg in bac_director" :key="sg.id" :value="sg.id">{{ sg.user.lname }}, {{ sg.user.fname }} {{ sg.user.mname | midname }}</option>
                            </select>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="4" style="padding:2px;" align="left"><small>Head, BAC Secretariat</small><br/></td>
                          <td colspan="4" style="padding:2px;" align="left"><small>Chairperson, BAC</small><br/></td>
                          <td colspan="5" style="padding:2px;" align="left"><small>Director, TAPI</small><br/></td>
                        </tr>
                      </tbody>
                    </table>

                  </div>
                </div>

              </div>
              <div class="card-footer">
                <button v-print="'#printAppendix60'" @click="print" class="btn btn-primary" data-container="body" type="button"><i class="fa fa-print"></i> Print</button>
                <button class="btn btn-primary" data-container="body" type="button" data-dismiss="modal" aria-label="Close"><i class="fa fa-times"></i> Close</button>
              </div>
            </div>
          </div>
        </div>

      </div>

  </template>

  <script>

    import Form from 'vform';
    import { VueEditor } from "vue2-editor";
    import moment from 'moment';

    export default {
        name: 'App',

        components: {
            VueEditor,
        },

        data() {
          return {
            app_type: 2,
            as_of: new Date(),
            excel_header: 'PROJECT PROCUREMENT MANAGEMENT PLAN (PPMP)',
            excel_title: 'TTILE',
            excel_footer: 'FOOTER',
            file_name: 'PPMP_.xls',
            excel_fields: {
                'Code': 'procurementitem.item_code',
                'Procurement Project': 'procurementitem.description',
                'PMO/End-User': 'ppmp.office.alias',
                'Is this an Early Procurement Activity? (Yes/No)': 'early_procurement',
                'Mode of Procurement': 'procmode.alias',
                'Advertisment/Posting of IB/REI': 'date_ibrei',
                'Submission/Opening of Bids': 'date_bidopening',
                'Notice of Award': 'date_noa',
                'Contract Signing': 'date_signing',
                'Source of Funds': 'fundsource.alias',
                'Total': {
                  field: 'tot_amount',
                  callback: (value) => {
                      return this.$options.filters.toMoney(value);
                  }
                },
                'MOOE': 'operating_program',
                'CO': 'operating_program',
                'Remarks (brief description of Project)': 'remarks'
            },
            timer: 30,
            searchkey: '',
            editmode: false,
            loading: false,
            emptyflag: false,
            can_access: false,
            bac_chair: {},
            bac_director: {},
            bac_members: {},
            procurementitems: {},
            ppmpdetails : {},
            ppmpitems : [],
            ppmpyears : {},
            fundsources: {},
            procmodes: {},
            year: new Date().getFullYear(),
            officeid: 0,
            userlevelid: 0,
            total_expenses: 0,
            role: {},
            office_name: '',
            form: new Form({
              id: 0,
              description: '',
              item_code: '',
              name: '',
              uom_name: '',
              unit_cost: '',
              mod_code: '',
              date_start: '',
              date_end: '',
              specs: '',
              src_id: 0,
              early_procurement: '',
              date_ibrei: '',
              date_bidopening: '',
              date_noa: '',
              date_signing: '',
              operating_program: '',
              jan: 0,
              feb: 0,
              mar: 0,
              apr: 0,
              may: 0,
              jun: 0,
              jul: 0,
              aug: 0,
              sep: 0,
              oct: 0,
              nov: 0,
              dec: 0,
            }),
            selected_items_form: new Form({
              ppmp_id: 0,
              selected_items: [],
            }),
            ppmp_form: new Form({
              year: 0,
              office_id: 0,
            }),
          }
        },

        props: [
          'todo'
        ],

        computed: {

          second() {
            return this.time / 1000;
          },

          filteredProcurementitems() {
            let tempItems = this.procurementitems;
            // Process search input
            if (this.searchkey != '' && this.searchkey) {
              tempItems = tempItems.filter((item) => {
                return item.description.toUpperCase().includes(this.searchkey.toUpperCase())
              })
            }

            return tempItems;
          },

          isIdle() {
            const idlestat =  this.$store.state.idleVue.isIdle;
            if (idlestat == true) {
              $('#sessionmodal').modal('show');
              this.countDownTimer();
            } else {
              $('#sessionmodal').modal('hide');
            }
            return idlestat;
          },

          years() {
            const year = new Date().getFullYear()
            return Array.from({length: year - 2018}, (value, index) => 2019 + index + 1)
          },

        },

        methods: {

            print(){
              var style = document.createElement('style');
              style.innerHTML = "@page{size: A4 landscape !important;margin: 25mm !important;}" +
                      " html{background-color: #FFFFFF;}" +
                      " body{border: solid 0px #FFFFFF;margin: 0;}";
              window.document.head.appendChild(style);
            },

            actionExcel() {
                console.log(this.ppmpitems.data, this.excel_fields);
                // Ensure the data and fields are as expected before exporting
            },

          countDownTimer() {
            let timerId = setInterval(() => {
              this.timer -= 1;
              if (!this.$store.state.idleVue.isIdle) clearInterval(timerId);

              if (this.timer < 1) {
                clearInterval(timerId);
                document.getElementById('logout-form').submit();
              }
            }, 1000);

            this.timer = 30;
          },

          clearSearchkey() {
            this.searchkey = '';
          },

          computeExpenses() {
              return this.ppmpitems.reduce((accum, item) => {
                  return accum + parseFloat(item.tot_amount)
              }, 0.00)
            },

          newPpmpCy() {
            this.editmode = true;
            this.form.reset();
            this.form.clear();
            this.form.fill(payload);
            $('#actionmodal').modal('show');
          },

          printModal() {
            $('#printmodal').modal('show');
          },

          editModal(payload) {
            this.editmode = true;
            this.form.reset();
            this.form.clear();
            this.form.fill(payload);
            $('#actionmodal').modal('show');
          },

          updateItem() {
            this.$Progress.start();
            this.form.put('api/ppmpitems/'+this.form.id).then(()=>{
              Trigger.$emit('RefreshItems');
              $('#actionmodal').modal('hide')
              toastr.fire({
                icon: 'success',
                title: 'Item Saved'
              })
              this.$Progress.finish();
            }).catch(()=>{
              toastr.fire({
                icon: 'error',
                title: 'Item saving failed'
              });
              this.$Progress.fail();
            });
          },

          loadOffice() {
            axios.get('api/procurement-role/user/' + this.$gate.showMyID()).then(({ data }) => (
              this.officeid = data.officeid,
              this.ppmp_form.office_id = data.officeid,
              this.userlevelid = data.userlevelid,
              this.role = data,
              //this.loadFundSources(),
              this.loadPpmpitemsYears()
            ));
          },

          loadPpmpitemsYears() {

            this.loadPpmpitems(this.year)
            /*
            if(!this.officeid) { //check if user is in role/signatory
              this.$router.push({ name: 'not-found' })
            } else {
              if(this.role.userlevelid !== 7) { //check if user is in PMO/End-User level
                this.$router.push({ name: 'not-found' })
              } else {
                axios.get('api/ppmps/years/' + this.officeid).then(({ data }) => (
                  this.ppmpyears = data,
                  this.year = data[0].year,

                ));
              }
            }

            */

          },

          loadFundSources() {
            axios.get('api/fundsources').then(({ data }) => (
              this.fundsources = data
            ));
          },

          loadPpmpitems(yr) {
            this.$Progress.start();

            axios.get('api/ppmpsitems/' + yr).then(({ data }) => (
              this.ppmpitems = data.data,
              this.total_expenses = this.computeExpenses(data.data[0]),
              /*
              this.ppmpdetails = data.data[0],
              this.selected_items_form.ppmp_id = data.data[0].id,

              this.office_name = data.data[0].office.name,
              */
              this.file_name = 'Annual Procurement Plan (APP) - ' + yr + '.xls',
              this.excel_header = ["Annual Procurement Plan (APP) - " + yr],
              this.excel_footer = ["&nbsp;",'GRAND TOTAL:'+ this.$options.filters.toMoney(this.computeExpenses(data.data[0]))],
              this.excel_title = ["Annual Procurement Plan (APP)"]

            ));

            this.$Progress.finish();
          },

          async loadPermission(mid) {

              const response = await axios.get("api/permission/" + this.$gate.showMyID() + "/" + mid).then(
              res => {
                if (!res.data || res.data.length == 0) {
                  return false;
                } else {
                  return true;
                }
              })

              const getResponse = async () => {
                const a = await response;

                switch (mid) {
                case 69:
                  this.can_access = a;
                  if (!this.can_access) {
                    this.$router.push({ name: 'not-found' }) //redirect to not found
                  }
                  break;
                }
              };
              return getResponse()
          },

          loadProcmodes() {
            axios.get('api/procmodes').then(({ data }) => (this.procmodes = data));
          },

          loadSignatories() {
            axios.get('api/procurement-role/level/11').then(({ data }) => (this.bac_chair = data));
            axios.get('api/procurement-role/level/19').then(({ data }) => (this.bac_director = data));
            axios.get('api/procurement-role/level/13').then(({ data }) => (this.bac_members = data));
          },

          actionExcel() {
            //
          },

          actionPrint() {
            //
          },

          actionDelete() {
            swal.fire({
            title: 'Are you sure?',
            text: "Deleting will permanently remove the item and all its respective data. Do you really want to delete PPMP CY " + this.year + "?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete PPMP!'
            }).then((result)=>{
              if (result.value) {
                this.ppmp_form.delete('api/ppmps/delete/'+ this.year + '/' + this.officeid).then(()=>{
                  swal.fire(
                    'Deleted!',
                    'PPMP has been deleted.',
                    'success'
                  );
                  Trigger.$emit('RefreshData');
                }).catch(()=>{
                  swal.fire(
                    'Failed!',
                    'There was something wrong',
                    'warning'
                  )
                });
              }
            })
          },

          actionAdd() {
            const inputStep = 1;
            const inpulValue = parseInt(this.ppmpdetails.year) + 1;
            swal.fire({
              title: 'Create PPMP for Calendar Year:',
              html: `<input
                  type="number"
                  value="${inpulValue}"
                  step="${inputStep}"
                  class="swal2-input"
                  id="sweettxt">`,
              inputAttributes: {
                autocapitalize: 'off',
                min: 2000,
                max: 3000,
                step: inputStep,
              },
              showCancelButton: true,
              confirmButtonText: 'Save',
              showLoaderOnConfirm: true,
              preConfirm: () => {

                this.ppmp_form.year = document.getElementById("sweettxt").value;

                if(!this.ppmpyears.some(data => data.year == document.getElementById("sweettxt").value))
                {
                    //save
                    this.$Progress.start();
                    this.ppmp_form.post('api/ppmps/' + document.getElementById("sweettxt").value)
                    .then(()=>{
                          //this.ppmpyears.push({"year":document.getElementById("sweettxt").value})
                          this.loadPpmpitemsYears();
                          this.year = document.getElementById("sweettxt").value;
                          this.loadPpmpitems(document.getElementById("sweettxt").value);
                          toastr.fire({
                            icon: 'success',
                            title: 'PPMP created successfully'
                          })
                          this.$Progress.finish();
                    }).catch(()=>{
                      toastr.fire({
                        icon: 'success',
                        title: 'PPMP created successfully'
                      })
                      this.$Progress.finish();
                    });
                    return "ok";
                }else{
                    swal.showValidationMessage(
                      `Request failed: PPMP for the given year already exist.`
                    )
                }
              },
              allowOutsideClick: () => !swal.isLoading()
            }).then((result) => {
              if (result.isConfirmed) {
                swal.fire({
                  title: `${result.value.txt}'s avatar`,
                  imageUrl: result.value.avatar_url
                })
              }
            })
          },

        },


        created() {
          Trigger.$on('searching',() => {
            let query = this.$parent.search;
            let emptyflag = false;

            if(query == ''){
              Trigger.$emit('RefreshData');
            }else{
              this.$Progress.start();
              axios.get("api/findPpmpitems?q=" + query)
              .then((data) => {
                this.ppmpitems = data.ppmpitems;

                if (data.data.total == 0){
                  this.emptyflag = true;
                }

                this.$Progress.finish();
              })
              .catch(() => {
                this.$Progress.fail();
              })
            }

          });

          this.loadProcmodes();
          this.loadFundSources();
          //this.loadProcItems();
          this.loadOffice();
          this.loadPermission(69); //Can Manage APP
          this.loadSignatories();



          Trigger.$on('RefreshData',() => {
            this.loadOffice();
          });

          Trigger.$on('RefreshItems',() => {
            this.loadPpmpitems(this.year);
          });
        },

    }
  </script>
  <style scoped>
  div >>> p {
  font-size: 15px;
  }

  div >>> strong {
  font-size: 15px;
  }

  div >>> em {
  font-size: 15px;
  }

  div.header >>> p {
  line-height: 0.5;
  font-size: 20px;
  font-family: "Helvetica Narrow","Arial Narrow";
  }

  div.header >>> strong {
  line-height: 0.5;
  font-size: 20px;
  font-family: "Helvetica Narrow","Arial Narrow";
  }

  div.header >>> em {
  line-height: 0.5;
  font-size: 20px;
  font-family: "Helvetica Narrow","Arial Narrow";
  }

  .text-selection-none{
   user-select: none; /* supported by Chrome and Opera */
   -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
   -moz-user-select: none; /* Firefox */
   -ms-user-select: none;
  }

  .responsive-table tbody td:last-of-type {
      text-align: center;
  }

  .card-body.p-0 .table tbody>tr>td:first-of-type, .card-body.p-0 .table tbody>tr>th:first-of-type, .card-body.p-0 .table thead>tr>td:first-of-type, .card-body.p-0 .table thead>tr>th:first-of-type {
      padding-left: 0.5rem;
  }

  @page {
    size: A4;
    margin: 17mm 17mm 17mm 17mm;
  }

  @media print {
      .page-number:before {
          content: counter(page);
      }
  }

  .custom-select{
    border: 1px solid transparent !important;
    padding: 0 !important;
    font-size: 10px !important;
    color: #000000 !important;
    height: 20px !important;
    width: 200px !important;
    appearance: none;
    text-decoration: underline !important;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding: 10px;
    background: transparent;
    box-shadow: inset 0 1px 2px #FFFFFF;
}

.custom-select::after {
  content: '▼'; /* You can use a custom chevron or arrow here */
  font-size: 12px;
  color: #999; /* Color of the chevron */
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.option-text {
  text-decoration: underline;
}

  </style>
