<template>
    <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card card-secondary card-outline">
              <div class="card-header">
                <h3 class="card-title"><i class="fas fa-file-invoice-dollar"></i> Source of Funds</h3>

                <div class="card-tools">
                  <div>
                    <button class="btn btn-success btn-sm" @click="newModal"><i class="fa fa-plus"></i></button>
                  </div>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0 m-0">
                <table class="table responsive-table table-sm table-striped">
                  <thead>
                    <tr>
                      <th scope="col" class="pl-2">Short Name</th>
                      <th scope="col">Title of Project/Source</th>
                      <th scope="col">Funding Instrument</th>
                      <th scope="col">Fund</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="source in fundsources" :key="source.id">
                      <td scope="row" class="pl-2">{{ source.alias }}</td>
                      <td data-title="Title of Project/Source">{{ source.title }}</td>

                      <td data-title="Funding Instrument">
                        <span v-if="(source.cluster == 1)">Regular</span>
                        <span v-else-if="(source.type == 2)">Foreign Project</span>
                        <span v-else-if="(source.type == 3)">SA-Local</span>
                        <span v-else-if="(source.type == 4)">SA-Foreign</span>
                        <span v-else-if="(source.type == 5)">Internal Income</span>
                        <span v-else-if="(source.type == 6)">Business Income</span>
                        <span v-else>Trust</span>
                      </td>

                      <td data-title="Fund Cluster">{{ source.fund }}</td>

                      <td data-title="Options">
                        <div class="btn-group">
                          <button type="button" @click="editModal(source)" class="btn btn-default"><i class="fas fa-edit text-primary"></i></button>
                          <button type="button" @click="actionDelete(source.id)" class="btn btn-default"><i class="fas fa-times text-danger"></i></button>
                        </div>
                      </td>


                    </tr>
                  </tbody>
                  <tbody v-show="!fundsources">
                      <tr><td colspan="10">No fund sources found.</td></tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->

            </div>
            <!-- /.card -->
          </div>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="actionmodal" tabindex="-1" role="dialog" aria-labelledby="actionaddModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-md" role="document">
            <div class="modal-content">
                <div class="modal-header">
                <h5 class="modal-title" id="actionaddModalLabel" v-if="editmode">Edit Fund Source</h5>
                <h5 class="modal-title" id="actionaddModalLabel" v-else>Add New Fund Source</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <div class="modal-body">
                    <div class="form-group">
                        <label for="exampleInputEmail1">Title</label>
                        <input type="text" class="form-control" v-model="form.title" :class="{ 'is-invalid': form.errors.has('title') }">
                        <has-error :form="form" field="title"></has-error>
                    </div>
                    <div class="form-group">
                        <label for="exampleInputEmail1">Alias</label>
                        <input type="text" class="form-control" v-model="form.alias" :class="{ 'is-invalid': form.errors.has('alias') }">
                        <has-error :form="form" field="alias"></has-error>
                    </div>

                    <div class="form-group">
                        <label for="exampleInputEmail1">Funding Instrument</label>
                        <select class="form-control" v-model="form.cluster" :class="{ 'is-invalid': form.errors.has('cluster') }">
                            <option value=""> - Select Status - </option>
                            <option value="1">Regular</option>
                            <option value="2">Foreign Project</option>
                            <option value="3">SA-Local</option>
                            <option value="4">SA-Foreign</option>
                            <option value="5">Internal Income</option>
                            <option value="6">Business Income</option>
                        </select>
                        <has-error :form="form" field="cluster"></has-error>
                    </div>
                    <div class="form-group">
                        <label for="exampleInputEmail1">Fund</label>
                        <input type="text" class="form-control" v-model="form.fund" :class="{ 'is-invalid': form.errors.has('fund') }">
                        <has-error :form="form" field="fund"></has-error>
                    </div>
                </div>
                <div class="card-footer">
                <button v-if="editmode" class="btn btn-primary" data-container="body" type="button" @click="actionUpdate()"><i class="fa fa-save"></i> Update</button>
                <button v-else class="btn btn-primary" data-container="body" type="button" @click="actionSave()"><i class="fa fa-save"></i> Save</button>
                <button class="btn btn-primary" data-container="body" type="button" data-dismiss="modal" aria-label="Close"><i class="fa fa-times"></i> Close</button>
                </div>
            </div>
            </div>
        </div>


      </div>

</template>

<script>

    import Form from 'vform';
    import { VueEditor } from "vue2-editor";
    import moment from 'moment';

    export default {
        name: 'FundSource',

        components: {
            VueEditor,
        },

        data() {
          return {
            timer: 30,
            editmode: false,
            loading: false,
            emptyflag: false,
            can_access: false,
            can_add: false,
            can_edit: false,
            can_delete: false,
            fundsources : [],
            form: new Form({
                id: null,
                title: '',
                alias: '',
                cluster: null,
                fund: ''
            }),
          }
        },

        props: [
          'todo'
        ],

        computed: {

          second() {
            return this.time / 1000;
          },

          isIdle() {
            const idlestat =  this.$store.state.idleVue.isIdle;
            if (idlestat == true) {
              $('#sessionmodal').modal('show');
              this.countDownTimer();
            } else {
              $('#sessionmodal').modal('hide');
            }
            return idlestat;
          },

        },

        methods: {

          countDownTimer() {
            let timerId = setInterval(() => {
              this.timer -= 1;
              if (!this.$store.state.idleVue.isIdle) clearInterval(timerId);

              if (this.timer < 1) {
                clearInterval(timerId);
                document.getElementById('logout-form').submit();
              }
            }, 1000);

            this.timer = 30;
          },


          newModal() {
            this.editmode = false;
            this.form.reset();
            this.form.clear();
            $('#actionmodal').modal('show');
          },

          editModal(payload) {
            this.editmode = true;
            this.form.reset();
            this.form.clear();
            $('#actionmodal').modal('show');
            this.form.fill(payload);
          },

          actionDelete(id) {
            swal.fire({
              title: 'Are you sure?',
              text: "You won't be able to revert this!",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes, delete fund source!'
              }).then((result)=>{
                if (result.value) {
                  this.form.delete('api/fundsources/'+id).then(()=>{
                    swal.fire(
                      'Deleted!',
                      'Fund source has been deleted.',
                      'success'
                    );
                    Trigger.$emit('RefreshData');
                  }).catch(()=>{
                    swal.fire(
                      'Failed!',
                      'There was something wrong',
                      'warning'
                    )
                  });
                }
            })
          },

          actionSave() {
            this.$Progress.start();
            this.form.post('api/fundsources').then(()=>{
              Trigger.$emit('RefreshData');
              $('#actionmodal').modal('hide')
              toastr.fire({
                icon: 'success',
                title: 'Fund Source Saved'
              })
              this.$Progress.finish();
            }).catch(()=>{
              toastr.fire({
                icon: 'error',
                title: 'Fund Source saving failed'
              });
              this.$Progress.fail();
            });
          },

          actionUpdate() {
            this.$Progress.start();
            this.form.put('api/fundsources/'+this.form.id).then(()=>{
              Trigger.$emit('RefreshData');
              $('#actionmodal').modal('hide')
              toastr.fire({
                icon: 'success',
                title: 'Fund Source Updated'
              })
              this.$Progress.finish();
            }).catch(()=>{
              toastr.fire({
                icon: 'error',
                title: 'Fund Source updating failed'
              });
              this.$Progress.fail();
            });
          },


          loadFundsources() {
            this.$Progress.start();
            axios.get('api/fundsources').then(({ data }) => (this.fundsources = data));
            this.$Progress.finish();
          },

          async loadPermission(mid) {
              const response = await axios.get("api/permission/" + this.$gate.showMyID() + "/" + mid).then(
              res => {
                if (!res.data || res.data.length == 0) {
                  return false;
                } else {
                  return true;
                }
              })

              const getResponse = async () => {
                const a = await response;

                switch (mid) {
                case 63:
                  this.can_access = a;
                  if (!this.can_access) {
                    this.$router.push({ name: 'not-found' }) //redirect to not found
                  }
                  break;
                  /*
                case 50:
                  this.can_edit = a;
                  break;
                case 51:
                  this.can_delete = a;
                  */
                }
              };
              return getResponse()
          },


        },

        created() {
          Trigger.$on('searching',() => {
            let query = this.$parent.search;
            let emptyflag = false;

            if(query == ''){
              Trigger.$emit('RefreshData');
            }else{
              this.$Progress.start();
              axios.get("api/findOffice?q=" + query)
              .then((data) => {
                this.fundsources = data;

                if (data.total == 0){
                  this.emptyflag = true;
                }

                this.$Progress.finish();
              })
              .catch(() => {
                this.$Progress.fail();
              })
            }

          });

          this.loadFundsources();
          this.loadPermission(63); //Can Manage Fund Source

          Trigger.$on('RefreshData',() => {
            this.loadFundsources();
          });
        },

    }
</script>
<style scoped>
div >>> p {
  font-size: 15px;
}

div >>> strong {
  font-size: 15px;
}

div >>> em {
  font-size: 15px;
}

div.header >>> p {
  line-height: 0.5;
  font-size: 20px;
  font-family: "Helvetica Narrow","Arial Narrow";
}

div.header >>> strong {
  line-height: 0.5;
  font-size: 20px;
  font-family: "Helvetica Narrow","Arial Narrow";
}

div.header >>> em {
  line-height: 0.5;
  font-size: 20px;
  font-family: "Helvetica Narrow","Arial Narrow";
}

.text-selection-none{
   user-select: none; /* supported by Chrome and Opera */
   -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
   -moz-user-select: none; /* Firefox */
   -ms-user-select: none;
}
div.tblProject table {
  width: 100% !important;
}

div.tblProject table td, div.tblProject table th {
  border: 1px solid #6C757D !important;
  border-collapse: collapse !important;
  padding: 5px !important;
}

div.tblProject >>> table {
  width: 100% !important;
}

div.tblProject >>> table td, div.tblProject >>> table th {
  border: 1px solid #6C757D !important;
  border-collapse: collapse !important;
  padding: 5px !important;
}

div.tblProject >>> br {
  display: block; /* makes it have a width */
  content: ""; /* clears default height */
  margin-top: 15px; /* change this to whatever height you want it */
}
</style>
