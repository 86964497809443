<template>
    <div class="container-fluid">
        <div class="row">
  
          <div class="col-md-4">
            <div class="form-group">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text bg-info">YEAR</span>
                </div>
                <select @change="getTargetData" v-model="form.year" name="year" class="form-control" placeholder="SELECT YEAR">
                  <option selected disabled value="">SELECT YEAR</option>
                  <option v-for="year in years" :value="year" :key="year">{{ year }}</option>
                </select>
              </div>
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text bg-info">PROGRAM</span>
                </div>
                <select @change="getTargetData" v-model="form.program_id" name="program_id" class="form-control" placeholder="SELECT PROGRAM">
                  <option selected value="">All Programs</option>
                  <option v-for="program in programs" :value="program.id" :key="program.id">{{ program.program_name }}</option>
                </select>
              </div>
            </div>
          </div>

        </div>

        <div class="row justify-content-center">
            <div class="col-md-12">


     
                  <div class="card-header bg-primary pull-right">

  
                        <!-- Year
                        <select @change="getPayroll" v-model="form.year" name="year" class="">
                        <option v-for="year in years" :value="year" :key="year">{{ year }}</option>
                        </select> -->

                        <!-- &nbsp;&nbsp;Month
                        <select @change="getPayroll" v-model="form.month" name="month" class="">
                            <option value="01">JANUARY</option>
                            <option value="02">FEBRUARY</option>
                            <option value="03">MARCH</option>
                            <option value="04">APRIL</option>
                            <option value="05">MAY</option>
                            <option value="06">JUNE</option>
                            <option value="07">JULY</option>
                            <option value="08">AUGUST</option>
                            <option value="09">SEPTEMBER</option>
                            <option value="10">OCTOBER</option>
                            <option value="11">NOVEMBER</option>
                            <option value="12">DECEMBER</option>
                        </select> -->
                        <!-- &nbsp;&nbsp;Cutoff
                        <select @change="getPayroll" v-model="form.cutoff" name="cutoff" class="">
                                        <option value="1">1st Cutt-off</option>
                                        <option value="2">2nd Cutt-off</option>
                                    </select>
                        &nbsp;&nbsp;Source of Fund
                        <select @click="getPayroll" v-model="form.division" name="division" @change="onDivisionChange($event)">
                                    <option v-for="division in divisions" :value="division.id" :key="division.id">{{ division.division_name }}</option>
                                  </select> -->
                      <div class="card-tools">
                        <button v-print="'#printReport'" @click="print"  class="btn btn-success mr-2"><i class="fas fa-print"></i> Print</button>
                      </div>
                      <!--
                      <div class="card-tools">
                        <div class="form-check" style="padding-right: 15px">
                          <input class="form-check-input" type="checkbox" @change="hideEmpty" v-model="hideempty" :checked="hideempty == true ? true: false" />
                          <label class="form-check-label">Hide empty projects</label>
                        </div>
                      </div>
                      -->
                      
                  </div>
     


                <div class="card">

                    <div class="card-body table-responsive p-0">

                      <div  id="printReport">
                        <table class="table table-borderless" style="font-size: 5px;">
                          <tbody>
                            <tr>
                              <td colspan="9" align="center">
                                <br/>
                                <h3>TECHNOLOGY APPLICATION AND PROMOTION INSTITUTE</h3>
                                <i v-if="selected === 'Finance and Administration Division'">CY {{ this.form.year }} Accomplisment Report</i>
                                <i v-else>Source of Fund: {{ selected }}</i>
                              </td>
                            </tr>
                            <tr>
                              <td colspan="9">
                                <hr/>
                                <!-- We hereby acknowledge to have received the sums therein specified opposite our respective names for our services rendered:<br/><br/> -->
                                <!-- <b><span v-if=" this.form.cutoff == '1'"> 1-15</span> <span v-else-if=" this.form.cutoff == '2'"> 16-31</span> {{ this.form.month | monthwordformat }} {{ this.form.year }}</b> -->
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table class="table table-bordered table-hover" style="font-size: 5px;">
                            <thead>
                            <tr>
                              <th class="card-header">
                                PERFORMANCE INDICATORS
                              </th>

                              <template style="text-align:center">
                                <th style="text-align:center" class="card-header">
                                  JAN
                                </th>
                                <th style="text-align:center" class="card-header">
                                  FEB
                                </th>
                                <th style="text-align:center" class="card-header">
                                  MAR
                                </th>
                                <th style="text-align:center" class="card-header">
                                  APR
                                </th>
                                <th style="text-align:center" class="card-header">
                                  MAY
                                </th>
                                <th style="text-align:center" class="card-header">
                                  JUN
                                </th>
                                <th style="text-align:center" class="card-header">
                                  JUL
                                </th>
                                <th style="text-align:center" class="card-header">
                                  AUG
                                </th>
                                <th style="text-align:center" class="card-header">
                                  SEP
                                </th>
                                <th style="text-align:center" class="card-header">
                                  OCT
                                </th>
                                <th style="text-align:center" class="card-header">
                                  NOV
                                </th>
                                <th style="text-align:center" class="card-header">
                                  DEC
                                </th>
                                <th style="text-align:center" class="card-header">
                                  TARGET
                                </th>
                                <th style="text-align:center" class="card-header">
                                  TOTAL
                                </th>
                                <th style="text-align:center" class="card-header">
                                  PERCENTAGE
                                </th>
                              </template>
                              
                            </tr>
                            </thead>
                            <tbody v-for="target in targets" :key="target.id">
                              <tr>
                                <template>
                                  <td colspan="15"><b>{{ target.programs.program_name }}</b></td>
                                </template>
                              </tr>
                              
                              <template v-for="(tar, index) in target.programs.all_indicators">

                                <tr :key="index">
                                  <td>{{ tar.indicator_name }}</td>
 
                                  <template v-if="tar.with_amount == 0 && tar.si_id == null">
                                    <td v-for="amt in tar.amount_per_months" :key="amt.id">
                                      <center>{{ (amt == "") ? 0 : amt }}</center>
                                    </td>

                                    <td><center>{{ tar.target_count }}</center></td>
                                     
                                    <td><center>{{ tar.amount_total }}</center></td>

                                    <td>
                                      <center>
                                      <template v-if="tar.target_total != 0 && tar.target_count != 0">
                                        {{ (tar.target_total > tar.target_count) ? 0 : ((tar.target_total /  tar.target_count) * 100).toFixed(2) }}
                                      </template>
                                      <template v-else>
                                        {{ 0 }}
                                      </template>
                                      </center>
                                    </td>
                                  </template>

                                  <template v-else>
                                    <td v-for="tpm in tar.target_per_months" :key="tpm.id">
                                      <center>{{ tpm }}</center>
                                    </td>
                                    <td><center>{{ tar.target_count }}</center></td>

                                    <td><center>{{ tar.target_total }}</center></td>

                                    <!-- <td><center>{{ (tar.target_total != 0 && tar.target_count != 0) ? ((tar.target_total /  tar.target_count) * 100).toFixed(2) : 0 }}</center></td> -->
                                    <td>
                                      <center>
                                      <template v-if="tar.target_total != 0 && tar.target_count != 0">
                                        {{ (tar.target_total > tar.target_count) ? 0 : ((tar.target_total /  tar.target_count) * 100).toFixed(2) }}
                                      </template>
                                      <template v-else>
                                        {{ 0 }}
                                      </template>
                                      </center>
                                    </td>
                                  </template>
                                </tr>
                                
                                <template v-if="tar.with_amount == 1">
                                  <tr :key="tar.index">
                                    <td>Amount of Investment</td>
                                    <td v-for="(twa, ind) in tar.amount_per_months" :key="ind">
                                      <center>{{ (twa == "") ? 0 : twa | toCurrency }}</center>
                                    </td>
                                    <td></td>
                                    <td><center>{{ tar.amount_total | toCurrency }}</center></td>
                                    <td></td>
                                  </tr>
                                </template>

                              </template>
                            </tbody>
                        </table>
                        <br/>
                      
                        <!-- <div class="row" style="margin:0;">
                          <div class="col-4">
                            <div class="text-center mb-5">
                              Consolidated by:
                            </div>
                            <div class="text-center mb-5">
                              <u><b> Ramon D. Navarrete Jr. </b></u><br/>
                              Planning Officer II
                            </div>
                            <div class="text-center mb-5">
                              Reviewed by:
                            </div>
                            <div class="text-center mb-5">
                              <u><b> Janice W. Cornel </b></u><br/>
                              Planning Officer III
                            </div>
                          </div>
                          <div class="col-4">
                            <div class="text-center mb-5">
                              Recommending approval:
                            </div>
                            <div class="text-center mb-5">
                              <u><b> Romeo M. Javate </b></u><br/>
                              Division Chief, IBOD
                            </div>
                            <div class="text-center mb-5">
                              <u><b> Joycel M. Aguilar </b></u><br/>
                              Division Chief, FAD
                            </div>
                          </div>
                          <div class="col-4">
                            <div class="text-center mb-5">
                              Approved by:
                            </div>
                            <div class="text-center mb-5">
                              <u><b> Atty. Marion Ivy D. Decena </b></u><br/>
                              Director
                            </div>
                          </div>
                        </div> -->

                      </div>
                    </div>
                </div>

                <div class="modal fade" id="sessionmodal" tabindex="-1" role="dialog" aria-labelledby="sessionModalLabel" aria-hidden="true">
                  <div class="modal-dialog modal-dialog-centered modal-md" role="document">
                    <div class="modal-content">
                      <div class="modal-header bg-orange">
                        <h5 class="modal-title" id="sessionModalLabel"><i class="icon fas fa-exclamation-triangle"></i> Security Auto-logout</h5> 
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body">
                      <div class="row-aligncenter">
                        <div id="printPayslip2">
                          <div class="card-body">
                            You have left this browser idle for 10 minutes. Due to our data privacy policy, you will be 
                            automatically logged out in <b>{{ timer }}</b> seconds.
                          </div>
                        </div>
                      </div>
                      </div>
                      <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                      </div>
                    </div>
                  </div>
                </div>
                <span style="display:none;">{{ isIdle }}</span>
                {{ getelementval }}

            </div>
        </div>
    </div>
</template>

<script>
    import Form from 'vform';
    import moment from 'moment';

    export default {
        data() {
          return {
            grandtotals: [],
            timer: 30,
            selected: 'Finance and Administration Division',
            divisions : {},
            year: '',
            projects: [],
            targets: [],
            programs: {},
            count_per_months: [],
            hideempty: false,
            can_access: false,
            form: new Form({
              division: '',
              year: '',
              month: '',
              program_id: ''
            })
          }
        },

        methods: {

          print(){
              var style = document.createElement('style');
              style.innerHTML = "@page{size: landscape;margin: 5mm;}" +
                      " html{background-color: #FFFFFF;}" +
                      " body{border: solid 0px #FFFFFF;margin: 0;}";
              window.document.head.appendChild(style);
          },

          hideEmpty(e) {
                //this.$Progress.start();
                if(e.target.checked){
                     this.hideempty = true;
                } else {
                     this.hideempty = false;
                }
                //this.$Progress.finish();
            },

          countDownTimer() {
            let timerId = setInterval(() => {
              this.timer -= 1;
              if (!this.$store.state.idleVue.isIdle) clearInterval(timerId);

              if (this.timer < 1) {
                clearInterval(timerId);
                // Your logout function should be over here
                document.getElementById('logout-form').submit();
              }
            }, 1000);

            this.timer = 30;
          },

          onDivisionChange(event) {
            var optionText = event.target.options[event.target.options.selectedIndex].text;
            this.selected = optionText;
          },

          getPayroll(){
            this.$Progress.start();
            axios.get("api/projects/divisions/" + this.form.division + "/payrolls/" + this.form.year + "/" + this.form.month + "/" + this.form.cutoff).then(({ data }) => (this.projects = data));
            axios.get("api/payrolls/total/" + this.form.division + "/" + this.form.year + "/" + this.form.month + "/" + this.form.cutoff).then(({ data }) => (this.grandtotals = data));
            this.$Progress.finish();
          },

          getTargetData(){
            this.$Progress.start();

            let program_id = this.form.program_id;
            program_id = (program_id == '') ? 0 : this.form.program_id;

            axios.get("api/targets/annual-reports/" + this.form.year + '/' + program_id).then(({ data }) => (this.targets = data));
            // axios.get("api/get-count-per-month/" + this.form.year).then(({ data }) => (this.count_per_months = data));
            this.$Progress.finish();
          },

          total_salary_monthly: function(payrolls) {
            return payrolls.reduce((acc, pay) => {
              return acc + parseFloat(pay.salary_monthly);
            }, 0);
          },

          grand_total_salary_monthly: function() {

          },

          total_salary_earned: function(payrolls) {
            return payrolls.reduce((acc, pay) => {
              return acc + parseFloat(pay.salary_earned);
            }, 0);
          },

          total_lua: function(payrolls) {
            return payrolls.reduce((acc, pay) => {
              return acc + parseFloat(pay.lua);
            }, 0);
          }, 

          total_salary_total: function(payrolls) {
            return payrolls.reduce((acc, pay) => {
              return acc + parseFloat(pay.salary_total);
            }, 0);
          }, 

          total_ewt_2: function(payrolls) {
            return payrolls.reduce((acc, pay) => {
              return acc + parseFloat(pay.ewt_2);
            }, 0);
          }, 

          total_ewt_5: function(payrolls) {
            return payrolls.reduce((acc, pay) => {
              return acc + parseFloat(pay.ewt_5);
            }, 0);
          }, 

          total_ewt_3: function(payrolls) {
            return payrolls.reduce((acc, pay) => {
              return acc + parseFloat(pay.ewt_3);
            }, 0);
          }, 

          total_salary_net: function(payrolls) {
            console.log(payrolls);
            return payrolls.reduce((acc, pay) => {
              return acc + parseFloat(pay.salary_net);
            }, 0);
          },

          async loadPermission(mid) {
              const response = await axios.get("api/permission/" + this.$gate.showMyID() + "/" + mid).then( 
              res => {
                if (!res.data || res.data.length == 0) {
                  return false;
                } else {
                  return true;
                }
              })

              const getResponse = async () => {
                const a = await response;

                switch (mid) {
                case 5:
                  this.can_access = a;
                  if (!this.can_access) {
                    this.$router.push({ name: 'not-found' }) //redirect to not found
                  }
                }
              };
              return getResponse()
          },

        },

        computed: {

          years() {
            const year = new Date().getFullYear()
            return Array.from({length: year - 1997}, (value, index) => 1998 + index)
          },

          second() {
            return this.time / 1000;
          },

          getelementval() {
            //console.log(document.getElementById('xxx'));
            //console.log($('#xxx').val());
          },

          isIdle() {
            const idlestat =  this.$store.state.idleVue.isIdle;
            if (idlestat == true) {
              $('#sessionmodal').modal('show');
              this.countDownTimer();
            } else {
              $('#sessionmodal').modal('hide');
            }

            return idlestat;

          },
 
        },

        created() {

          const today = new Date();
          
          const curr_year = today.getFullYear();
          const curr_month = moment(String(today.getMonth() + 1)).format('MM');

          let yr = "";
          let mo = "";
          let program_id = "";

          if(this.form.year == '' || this.month == '') {
            yr = curr_year;
            mo = curr_month;
            this.form.year = curr_year;
            this.form.month = curr_month;
          } else {
            yr = this.form.year;
            mo = this.form.month;
          }

          this.form.division = 1;
          this.form.cutoff = 1;

          program_id = (program_id == '') ? 0 : this.form.program_id;
          axios.get('api/all-programs').then(({ data }) => (this.programs = data));

          // this.loadPermission(5); //Can access payroll
          // axios.get("api/divisions").then(({ data }) => (this.divisions = data));
          // axios.get("api/projects/divisions/1/payrolls/" + yr + "/" + mo + "/1").then(({ data }) => (this.projects = data));
          // axios.get("api/payrolls/total/1/" + yr + "/" + mo + "/1").then(({ data }) => (this.grandtotals = data));
          
          axios.get("api/targets/annual-reports/" + yr + '/' + program_id).then(({ data }) => (this.targets = data));
          // axios.get("api/get-count-per-month/" + yr).then(({ data }) => (this.count_per_months = data));
        }
    }
</script>

