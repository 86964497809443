<template>
    <div class="container">
    <div class="row">
        <div class="col-md-12">
            <div class="error-page">
                <h2 class="headline text-orange"> 404</h2>

                <div class="error-content mt-5">
                    <br/>
                    <h1>Oops! Page not found.</h1>

                    <p>
                        <br/>
                        We could not find the page you were looking for.
                        Meanwhile, you may <a href="/dashboard">return to dashboard</a> or try using the search form.
                    </p>
                </div>
                <!-- /.error-content -->
            </div>
        </div>
    </div>
</div>
</template>

<script>
    export default {
        mounted() {
     
        }
    }
</script>
