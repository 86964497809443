<template>
    <div class="col-md-12">
      <div class="">
        <!-- /.card-header -->
        <div class="p-0">
          <div class="uploader"
              @dragenter="OnDragEnter"
              @dragleave="OnDragLeave"
              @dragover.prevent
              @drop="onDrop"
              :class="{ dragging: isDragging }">
              
              <div class="upload-control" v-show="documents.length">
                  <label for="file">Select an image</label>
                  <button @click.prevent="upload">Upload</button>
              </div>
  
  
              <div v-show="!documents.length">
                  <i class="fa fa-cloud-upload"></i>
                  <h3>Drag your images here</h3>
                  <div class="mb-2">OR</div>
                  <div class="file-input btn btn-outline-secondary">
                      <label for="file">Select an image</label>
                      <input type="file" id="file" @change="onInputChange" multiple>
                  </div>
              </div>
              
              <div class="container-fluid">
                  <div class="row" v-show="documents.length">
                      <div class="col-md-6" v-for="(document, index) in documents" :key="document.id">
                          <div class="info-box">   
                              <div class="info-box-content">
                                  <button type="button" @click="onRemove(index)" class="btn float-right"><i class="fas fa-times"></i></button>
                                  <span class="info-box-number">{{ files[index].name }}</span>
                                  <span class="info-box-text"><i>{{ files[index].type }} ({{ getFileSize(files[index].size) }})</i></span>
                              </div>
                          <!-- /.info-box-content -->
                          </div>
                          <!-- /.info-box -->
                      </div>
                  </div>
              </div>
  
              
          </div>
        </div>
        <!-- /.card-body -->
  
      </div>
      <!--/.card -->
      
    </div>
  </template>
  
  <script>
  export default {
      data: () => ({
          isDragging: false,
          dragCount: 0,
          files: [],
          documents: []
      }),
  
      props: {
        //
      },
  
      methods: {
          OnDragEnter(e) {
              e.preventDefault();
              
              this.dragCount++;
              this.isDragging = true;
              return false;
          },
  
          OnDragLeave(e) {
              e.preventDefault();
              this.dragCount--;
              if (this.dragCount <= 0)
                  this.isDragging = false;
          },
  
          onInputChange(e) {
              const files = e.target.files;
              Array.from(files).forEach(file => this.addDocument(file));
          },
  
          onDrop(e) {
              e.preventDefault();
              e.stopPropagation();
              this.isDragging = false;
              const files = e.dataTransfer.files;
              Array.from(files).forEach(file => this.addDocument(file));
          },
  
          onRemove(index) {
              //console.log(index);
              this.documents.splice(index, 1);
              this.files.splice(index, 1);
          },
  
          addDocument(file) {
              if (!file.type.match('image/jpeg|image/png|image/bmp|image/gif')) {
                  toastr.fire({
                        icon: 'error',
                        title: `${file.name} is not an image`
                      });
                  return;
              }
              this.files.push(file);
              const img = new Image(),
                  reader = new FileReader();
              reader.onload = (e) => this.documents.push(e.target.result);
              reader.readAsDataURL(file);
              //console.log(this.execom.id);
          },
  
  
          getFileSize(size) {
              const fSExt = ['Bytes', 'KB', 'MB', 'GB'];
              let i = 0;
              
              while(size > 900) {
                  size /= 1024;
                  i++;
              }
              return `${(Math.round(size * 100) / 100)} ${fSExt[i]}`;
          },
  
          upload() {
              const formData = new FormData();
              
              this.files.forEach(file => {
                  formData.append('documents[]', file, file.name);
              });
              axios.post('/api/certificatesupload', formData)
                  .then(response => {
                      toastr.fire({
                        icon: 'success',
                        title: 'Images uploaded successfully'
                      });
                      this.documents = [];
                      this.files = [];
                      this.$emit('update-table');
                  });
              
              
          },
  
      }
  
  }
  </script>
  
  <style lang="scss" scoped>
  .uploader {
      width: 100%;
      color: #000000;
      background: #F4F6FC;
      padding: 50px 15px 15px 15px;
      text-align: center;
      border-radius: 10px;
      border: 3px dashed #C0C0C0;
      font-size: 20px;
      position: relative;
      &.dragging {
          background: #fff;
          color: #2196F3;
          border: 3px dashed #2196F3;
          .file-input label {
              /*background: #2196F3;*/
              color: #2196F3;
          }
      }
      i {
          font-size: 10px;
      }
      .info-box {
          text-align: left;
          color: #000;
          margin-left: 10px;
          min-height: 55px;
          .info-box-icon {
              width: 45px;
          }
      }
      .info-box-number {
        width: 180px;
        overflow: hidden;
      }
      .file-input {
          width: 150px;
          margin: auto;
          height: 30px;
          position: relative;
          label,
          input {
            /*
              background: #DEDEDE;
              color: #2196F3;
              */
              width: 100%;
              position: absolute;
              left: 0;
              top: 5;
              /*
              padding: 10px;
              border-radius: 4px;
              margin-top: 7px;
              */
              cursor: pointer;
          }
          input {
              opacity: 0;
              z-index: -2;
          }
      }
      .images-preview {
          display: flex;
          flex-wrap: wrap;
          margin-top: 20px;
          .img-wrapper {
              width: 100%;
              display: flex;
              flex-direction: column;
              margin: 10px;
              height: 40px;
              justify-content: space-between;
              background: #fff;
              box-shadow: 5px 5px 20px #3e3737;
              img {
                  max-height: 105px;
              }
          }
          .details {
              font-size: 12px;
              background: #fff;
              color: #000;
              display: flex;
              flex-direction: column;
              align-items: self-start;
              padding: 3px 6px;
              .name {
                  overflow: hidden;
                  height: 18px;
              }
          }
      }
      .upload-control {
          position: absolute;
          width: 100%;
          background: #F4F6FC;
          top: 0;
          left: 0;
          border-top-left-radius: 7px;
          border-top-right-radius: 7px;
          padding: 10px;
          padding-bottom: 4px;
          text-align: right;
          button, label {
              background: #2196F3;
              border: 2px solid #03A9F4;
              border-radius: 3px;
              color: #fff;
              font-size: 15px;
              cursor: pointer;
          }
          label {
              padding: 2px 5px;
              margin-right: 10px;
          }
      }
  }
  </style>