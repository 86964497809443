<template>
    <div>
        <div class="pl-3 pr-3 pt-2 pb-1 bg-black">
            <h6><span class="badge badge-success float-right">{{online.length - 1}}</span> ONLINE</h6>
        </div>
        <div class="p-3" v-show="online.length != 1">
            <span v-for="(ol, index) in online"  v-bind:key="index">
                <span v-if="ol.id !== myid">
                    <span class="text-white">{{ ol.fname + ' ' + ol.lname | stringlimitinverted(25) }}</span> <i class="fa fa-circle text-success"></i><br/>
                </span>
            </span>
        </div>
        <div class="pl-3 pr-3 pt-2 pb-1 bg-black">
            <h6><span class="badge badge-secondary float-right">{{employees.length}}</span> OFFLINE</h6>
        </div>
        <div class="p-3" v-show="online.length != 0">
            <span v-for="emp in employees"  v-bind:key="emp.id">
                <span class="text-white">{{ emp.fname + ' ' + emp.lname | stringlimitinverted(25) }}</span> <i class="fa fa-circle text-secondary"></i><br/>
            </span>
        </div>
    </div>
</template>

<script>

import axios from 'axios'

export default {
    name: 'Online',

    data () {
        return {
            online: {},
            employees: {},
            myid: 0,
        }
    },

    computed: {
        //
    },

    methods: {

        listen() {
            axios.put('api/user/online/' + this.$gate.showMyID());
            Echo.join('chat')
            .here((data) => {
                this.online = data;

                //remove the online users in employee list
                for( var i=this.employees.length - 1; i>=0; i--) {
                    for( var j=0; j<this.online.length; j++) {
                        if(this.employees[i].id === this.online[j].id) {
                            this.employees.splice(i, 1);
                        }
                    }
                }
            })
            .joining(() => {
                //  
            })
            .leaving(() => {
                //
            })
            /*
            .listen('UserOnline', (e) => {
                toastr.fire({
                    icon: 'info',
                    html: '<div style="text-align:left;margin-left:10px;"><span style="font-weight:bold;">' + e.user.fname + ' ' + e.user.lname + ' is </span><span style="font-weight:bold;color:green;">online</span></div>',
                    position: 'bottom-start',
                    timer: 10000,
                    timerProgressBar: true,
                });
                
                // add to online employees object
                const index = this.online.findIndex(object => object.id === e.user.id);
                if (index === -1) {
                    this.online.unshift(e.user);
                }
                // remove all online employees to offline employees object
                this.employees = this.employees.filter(ar => !this.online.find(rm => (rm.id === ar.id) ))
            })*/
            .listen('.pusher:member_added', (e) => {
                /*
                toastr.fire({
                    icon: 'info',
                    html: '<div style="text-align:left;margin-left:10px;"><span style="font-weight:bold;">' + e.info.fname + ' ' + e.info.lname + ' is </span><span style="font-weight:bold;color:green;">online</span></div>',
                    position: 'bottom-start',
                    timer: 10000,
                    timerProgressBar: true,
                });
                */

                // remove to offline employees object
                const idx = this.employees.findIndex(obj => obj.id === e.id);
                if (idx !== -1) { this.employees.splice(idx,1); }

                // add to online employees object
                const index = this.online.findIndex(object => object.id === e.id);
                if (index === -1) { this.online.unshift(e.info); }
                

            })
            .listen('.pusher:member_removed', (e) => {
                /*
                toastr.fire({
                    icon: 'info',
                    html: '<div style="text-align:left;margin-left:10px;"><span style="font-weight:bold;">' + e.info.fname + ' ' + e.info.lname + ' is </span><span style="font-weight:bold;color:red;">offline</span></div>',
                    position: 'bottom-start',
                    timer: 10000,
                    timerProgressBar: true,
                });
                */
                
                // remove to online object
                const index = this.online.findIndex(object => object.id === e.info.id);
                if (index !== -1) { this.online.splice(index,1); }

                // add to offline employees object
                const idx = this.employees.findIndex(obj => obj.id === e.info.id);
                if (idx === -1) { this.employees.unshift(e.info); }
            })
       }
    },

    mounted() {
        //
    },

    created () {
        this.myid = this.$gate.showMyID();
        this.listen();
        const emp = null;
        axios.get("api/users/inservice").then(result => {
            this.employees = result.data;
        }).catch(err => {
            console.log(err);
        });
    },

    beforeDestroy() {
        //console.log('Destroying')
        //Echo.leave('chat'); //this.disconnectPusher();
        //axios.put('api/user/offline/' + this.$gate.showMyID());
    },  


}
</script>
<style scoped>
</style>