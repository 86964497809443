<template>
    <div class="container-fluid">
        <div class="row">
  
          <div class="col-md-6"></div>
 
          <div class="col-md-6"></div>
      
          <div class="col-md-6">
            <div class="form-group">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text bg-info">Looking for?</span>
                </div>
                <input 
                  v-model="form.keyword" 
                  type="text" 
                  name="keyword" 
                  autocomplete="off" 
                  placeholder="Type your keywords here..." 
                  class="form-control form-control-lg" 
                  :class="{ 'is-invalid': form.errors.has('keyword') }">
                <span class="input-group-append">
                  <button
                    @click="resetFilter"
                    title="Reset Filters"
                    type="button" 
                    class="btn btn-primary btn-flat">
                    <i class="fa fa-undo"></i>
                  </button>
                </span>
                <span class="input-group-append">
                  <button
                    @click="getResults"
                    title="Search"
                    type="button" 
                    class="btn btn-success btn-flat">
                    <i class="fa fa-search"></i>
                  </button>
                </span>
                <has-error :form="form" field="keyword"></has-error>
              </div>
            </div>
          </div>

          <!-- <div class="col-md-6">
            <div class="card card-primary card-outline collapsed-card">
              <div class="card-header">
                <h3 class="card-title"><i class="fas fa-search-plus"></i> Advanced Search</h3>

                <div class="card-tools">
                  <button type="button" class="btn btn-tool" data-toggle="collapse" data-card-widget="collapse" aria-expanded="false">
                    <i class="fas fa-minus"></i>
                  </button>
                </div>
              </div>

              <div class="card-body" style="display: none;">   
                <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text bg-info">Program</span>
                      </div>
                      <select @change="getIndicators()" v-model="program_id" name="program_id" class="form-control" autocomplete="off" placeholder="Select Program">
                        <option selected disabled value="">Select Program</option>
                        <option v-for="program in programs" :value="program.id" :key="program.id">{{ program.program_name }}</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text bg-info">Indicator</span>
                      </div>
                      <select @change="getDetails(indicator_id)" v-model="indicator_id" name="indicator_id" class="form-control" autocomplete="off" placeholder="SELECT INDICATOR">
                        <option selected disabled value="">Select Indicator</option> 
                        <option v-for="indicator in indicators.data" :value="indicator.id" :key="indicator.id">{{ indicator.indicator_name }}</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text bg-info">Month</span>
                      </div>
                      <select @change="getDetails(indicator_id)" v-model="month" name="month" class="form-control" autocomplete="off" placeholder="SELECT MONTH">
                          <option selected value="">All Months</option>
                          <option value="1">January</option>
                          <option value="2">February</option>
                          <option value="3">March</option>
                          <option value="4">April</option>
                          <option value="5">May</option>
                          <option value="6">June</option>
                          <option value="7">July</option>
                          <option value="8">August</option>
                          <option value="9">September</option>
                          <option value="10">October</option>
                          <option value="11">November</option>
                          <option value="12">December</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <div class="input-group">
                      <div class="input-group-prepend">
                        <span class="input-group-text bg-info">Year</span>
                      </div>
                      <select v-model="form.year" name="year" class="form-control" placeholder="SELECT YEAR">
                        <option selected disabled value="">Select Year</option>
                        <option v-for="year in years" :value="year" :key="year">{{ year }}</option>
                      </select>
                    </div>
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div> -->
           
          <!-- <div class="col-md-12">
            <div class="card card-secondary card-outline">
              <div class="card-header">
                <h4 class="card-title"><i class="fas fa-tasks"></i> Details</h4>

                <div class="card-tools">
                  <div>
                    <button 
                      data-toggle="tooltip" 
                      data-container="body" 
                      title="Add New Program" 
                      class="btn btn-success btn-sm">
                      <i class="fa fa-plus"></i>
                    </button>
                  </div>
                </div>
              </div>
 
              <div class="card-body table-responsive p-0">
                <table class="table table-hover table-striped">
                  <thead>
                    <tr>  
                      <th v-for="sub_indicator in sub_indicators.data" :key="sub_indicator.id">
                        {{ sub_indicator.sub_indicator_name }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="target_content in target_contents.data" :key="target_content.id">
                      <td v-for="target_sub_content in target_content.target_sub_contents" :key="target_sub_content.id">
                        {{ (target_sub_content.value == null) ? "None" : target_sub_content.value }}
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-show="target_contents.data == 0">
                      <tr><td colspan="10">No details found.</td></tr>
                  </tbody>
                </table>
              </div>
               
              <div class="card-footer">
                <pagination :data="target_contents" @pagination-change-page="getResults" show-disabled :limit="10"></pagination>
              </div>
            </div>
          </div> -->

          <div class="col-md-12">
            <div class="card card-secondary card-outline">
              <div class="card-header">
                <h4 class="card-title"><i class="fas fa-database"></i> Search Data</h4>

                <div class="card-tools">
                  <div>
                  </div>
                </div>
              </div>
 
              <div class="card-body table-responsive p-0">
                <table class="table table-hover table-striped">
                  <thead>
                    <tr>  
                      <th>Value</th>
                      <th>Detail</th>                  
                      <th>Program</th>
                      <th>Indicator</th>
                      <th>Month</th>
                      <th>Year</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(tsc, index) in target_sub_contents.data" :key="index">
                      <td>{{ (tsc.value == null) ? '-' : tsc.value }}</td>
                      <td>{{ (tsc.sub_indicator_id == null) ? '-' : tsc.sub_indicators.sub_indicator_name }}</td>
                      <td>{{ tsc.target_contents.targets.programs.program_name }}</td>
                      <td>{{ tsc.target_contents.indicators.indicator_name }}</td>
                      <td>{{ tsc.target_contents.targets.month | monthwordformat }}</td>
                      <td>{{ tsc.target_contents.targets.year }}</td>
                    </tr>
                  </tbody>
                  <tbody v-show="target_sub_contents.data == 0">
                      <tr><td colspan="10">No data found.</td></tr>
                  </tbody>
                </table>
              </div>
               
              <div class="card-footer">
                <pagination :data="target_sub_contents" @pagination-change-page="getResults" show-disabled :limit="10"></pagination>
              </div>
            </div>
          </div>
 
        </div>
    </div>

</template>

<script>

    import Form from 'vform'; 
 
    export default {
        data() {
          return {
            timer: 30,
            editmode: false,
            emptyflag: false,
            programs: {},
            program_id: '',
            indicators: {},
            indicator_id: '',
            details: {},
            target_contents: {},
            target_sub_contents: {},
            sub_indicators: {},
            month: '',
            year: '',
            form: new Form({
              id: '',
              keyword: '',
              year: ''
            }),
          }
        },

        computed: {
          
          second() {
            return this.time / 1000;
          },

          isIdle() {
            const idlestat =  this.$store.state.idleVue.isIdle;
            if (idlestat == true) {
              $('#sessionmodal').modal('show');
              this.countDownTimer();
            } else {
              $('#sessionmodal').modal('hide');
            }

            return idlestat;

          },

          years() {
            const year = new Date().getFullYear()
            return Array.from({length: year - 1997}, (value, index) => 1998 + index)
          }

        },

        methods: {

          countDownTimer() {
            let timerId = setInterval(() => {
              this.timer -= 1;
              if (!this.$store.state.idleVue.isIdle) clearInterval(timerId);

              if (this.timer < 1) {
                clearInterval(timerId);
                document.getElementById('logout-form').submit();
              }
            }, 1000);

            this.timer = 30;
          },

          resetFilter() {
            // const today = new Date();
          
            // const curr_year = today.getFullYear();

            this.form.keyword = '';

            this.program_id = '';
            this.month = '';
            this.form.year = '';

            this.indicator_id = '';
            this.indicators = {};

            this.target_contents = {};
 
            // this.getDetails(this.indicator_id);
            this.loadDetails();
          },

          getIndicators() {
            // axios.get('/api/get-indicator-program-id',{
            //   params: {
            //     program_id: this.program_id,
            //     year: this.year
            //   }
            // }).then(function(response){
            //     this.indicators = response.data;
            // }.bind(this));

            this.indicator_id = '';
            this.indicators = {};

            axios.get('api/get-indicator-program-id/' + this.program_id + '/' + this.year).then(({ data }) => (this.indicators = data));
          },

          getDetails(indicator_id)
          {
            indicator_id = indicator_id == '' ? 0 : indicator_id;
            // const program_id = parseInt(this.program_id);
            const program_id = this.program_id == '' ? 0 : this.program_id;
            const month = this.month == '' ? 0 : this.month;
            const year = this.year;

            axios.get('api/get-sub-indicator-by-id/' + indicator_id).then(({ data }) => (this.sub_indicators = data));

            axios.get('api/get-target-contents-indicator-id/' + indicator_id + '/' + program_id + '/' + month + '/' + year).then(({ data }) => (this.target_contents = data));
          },
 
          getResults(page = 1) { 
            let query = this.form.keyword;
            let year = this.form.year;

            if(query == ''){
              axios.get('api/get-target-sub-contents?page=' + page).then(response => { this.target_sub_contents = response.data; }); 
            }else{
              axios.get('api/find-target-sub-contents?q=' + query + '&year=' + year + '&page=' + page).then(response => { this.target_sub_contents = response.data; }); 
            }
          },

          loadDetails() {
            this.$Progress.start();
           
            axios.get('api/all-programs').then(({ data }) => (this.programs = data));
 
            this.indicator_id = '';

            axios.get('api/get-target-sub-contents').then(({ data }) => (this.target_sub_contents = data));

            this.$Progress.finish();
          },
 
        },

        created() {
          Trigger.$on('searching',() => {
            let query = this.$parent.search;
            let emptyflag = false;

            if(query == ''){
              Trigger.$emit('RefreshData');
            }else{
              this.$Progress.start();
              axios.get("api/find-detail?q=" + query)
              .then((data) => {
                this.target_sub_contents = data.data;
                
                if (data.data.total == 0){
                  this.emptyflag = true;
                }

                this.$Progress.finish();
              })
              .catch(() => {
                this.$Progress.fail();
              })
            }
            
          });

          // const today = new Date();
          
          // const curr_year = today.getFullYear();

          // let yr = "";

          // if(this.year == '') {
          //   yr = curr_year;
          //   this.year = curr_year;
          // } else {
          //   yr = this.year;
          // }
          
          this.loadDetails();
 
          Trigger.$on('RefreshData',() => {
            this.loadDetails();
          });
        },

    }
</script>

<style lang="scss" scoped>
.a-pointer:hover {
    cursor: pointer;
}
</style>