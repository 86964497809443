<template>
  <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card card-secondary card-outline">
            <div class="card-header">
              <h3 class="card-title"><i class="fas fa-sign-language"></i> Roles / Signatories</h3>

              <div class="card-tools">
                <div>
                  <button class="btn btn-success btn-sm" @click="newModal()"><i class="fa fa-user-plus"></i></button>
                </div>
              </div>
            </div>
            <!-- /.card-header -->
            <div class="card-body table-responsive p-0 m-0">
              <table class="table responsive-table table-sm table-striped">
                <thead>
                  <tr>
                    <th scope="col" class="pl-2">Employee Name</th>
                    <th scope="col">Designation</th>
                    <th scope="col">Office</th>
                    <th scope="col">Level of Access</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="sign in signatories" :key="sign.id">
                    <td scope="row" class="pl-2">{{ sign.user.fname }} {{ sign.user.mname | midname }} {{ sign.user.lname }}</td>
                    <td data-title="Designation">{{ sign.user.position }}</td>
                    <td data-title="Office">{{ sign.office.name }}</td>
                    <td data-title="Level of Access">{{ sign.procuserlevel.levelname }}</td>

                    <td data-title="Options">
                      <div class="btn-group">
                        <button type="button" @click="editModal(sign)" class="btn btn-default"><i class="fas fa-edit text-primary"></i></button>
                        <button type="button" @click="actionDelete(sign.id)" class="btn btn-default"><i class="fas fa-times text-danger"></i></button>
                      </div>
                    </td>
                    

                  </tr>
                </tbody>
                <tbody v-show="!signatories">
                    <tr><td colspan="10">No roles / signatories found.</td></tr>
                </tbody>
              </table>
            </div>
            <!-- /.card-body -->
            <div class="card-footer">
              
            </div>
          </div>
          <!-- /.card -->
        </div>
      </div>

      <!-- Modal -->

      <div class="modal fade" id="actionadd" tabindex="-1" role="dialog" aria-labelledby="actionaddModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-md" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="actionaddModalLabel">Add Role/Signatory</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
                <div class="form-group">
                  <label for="exampleInputEmail1">Employee Name</label>
                  <selectize placeholder="- Select Employee -" v-model="form.userid" :settings="settings" class="form-control" :disabled="editmode">
                    <option v-for="user in users" :key="user.id" v-bind:value="user.id" >{{ user.lname }}, {{ user.fname }} {{ user.mname | midname }}</option>
                  </selectize>
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Office</label>
                  <selectize placeholder="- Select Office -" v-model="form.officeid" :settings="settings" class="form-control">
                    <option v-for="office in offices" :key="office.id" v-bind:value="office.id" >{{ office.alias }}</option>
                  </selectize>
                </div>
                <div class="form-group">
                  <label for="exampleInputEmail1">Level of Access</label>
                  <selectize placeholder="- Select Level -" v-model="form.userlevelid" :settings="settings" class="form-control">
                    <option v-for="lvl in levels" :key="lvl.id" v-bind:value="lvl.id" >{{ lvl.levelname }}</option>
                  </selectize>
                </div>
            </div>
            <div class="card-footer">
              <button v-if="editmode" class="btn btn-primary" data-container="body" type="button" :disabled="this.form.userid === 0 || this.form.officeid === 0  || this.form.userlevelid === 0 " @click="actionUpdate()"><i class="fa fa-save"></i> Update</button>
              <button v-else class="btn btn-primary" data-container="body" type="button" :disabled="this.form.userid === 0 || this.form.officeid === 0  || this.form.userlevelid === 0 " @click="actionSave()"><i class="fa fa-save"></i> Save</button>
              <button class="btn btn-primary" data-container="body" type="button" data-dismiss="modal" aria-label="Close"><i class="fa fa-times"></i> Close</button>
            </div>
          </div>
        </div>
      </div>


      

    </div>

</template>

<script>

  import Form from 'vform';
  import { VueEditor } from "vue2-editor";
  import moment from 'moment';
  
  export default {
      name: 'ProcSignatories',

      components: {
          VueEditor,
      },

      data() {
        return {
          timer: 30,
          editmode: false,
          loading: false,
          emptyflag: false,
          can_access: false,
          can_add: false,
          can_edit: false,
          can_delete: false,
          settings: {},
          users: {},
          offices: {},
          levels: {},
          signatories : [],
          form: new Form({
            id: 0,
            userid: 0,
            officeid: 0,
            userlevelid: 0,
          }),
        }
      },

      props: [
        'todo'
      ],

      computed: {
        
        second() {
          return this.time / 1000;
        },

        isIdle() {
          const idlestat =  this.$store.state.idleVue.isIdle;
          if (idlestat == true) {
            $('#sessionmodal').modal('show');
            this.countDownTimer();
          } else {
            $('#sessionmodal').modal('hide');
          }
          return idlestat;
        },

      },

      methods: {

        countDownTimer() {
          let timerId = setInterval(() => {
            this.timer -= 1;
            if (!this.$store.state.idleVue.isIdle) clearInterval(timerId);

            if (this.timer < 1) {
              clearInterval(timerId);
              document.getElementById('logout-form').submit();
            }
          }, 1000);

          this.timer = 30;
        },

        editModal(payload) {
          this.editmode = true;
          this.form.reset();
          this.form.clear();
          this.form.fill(payload);
          $('#actionadd').modal('show');
        },

        newModal() {
          this.editmode = false;
          this.form.reset();
          this.form.clear();
          $('#actionadd').modal('show');
        },

        actionSave() {
          this.$Progress.start();
            this.form.post('api/procurementroles')
            .then(()=>{
              Trigger.$emit('RefreshData');
              $('#actionadd').modal('hide')
              toastr.fire({
                icon: 'success',
                title: 'Role/Signatory created successfully'
              })
              this.$Progress.finish();
            }).catch(()=>{
              toastr.fire({
                icon: 'error',
                title: 'Role/Signatory creation failed'
              });
              this.$Progress.fail();
            })
        },

        actionUpdate() {
          this.$Progress.start();
            this.form.put('api/procurementroles/' + this.form.id)
            .then(()=>{
              Trigger.$emit('RefreshData');
              $('#actionadd').modal('hide')
              toastr.fire({
                icon: 'success',
                title: 'Role/Signatory updated successfully'
              })
              this.$Progress.finish();
            }).catch(()=>{
              toastr.fire({
                icon: 'error',
                title: 'Role/Signatory update failed'
              });
              this.$Progress.fail();
            })
        },

        actionDelete(id) {
            swal.fire({

              title: 'Are you sure?',
              text: "You won't be able to revert this!",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes, delete signatory/role!'
              }).then((result)=>{
                if (result.value) {
                  this.form.delete('api/procurementroles/'+id).then(()=>{
                    swal.fire(
                      'Deleted!',
                      'Signatory/role has been deleted.',
                      'success'
                    );
                    Trigger.$emit('RefreshData');
                  }).catch(()=>{
                    swal.fire(
                      'Failed!', 
                      'There was something wrong', 
                      'warning'
                    )
                  });
                }
            })
          },

        loadSignatories() {
          this.$Progress.start();
          axios.get('api/procurement-role').then(({ data }) => (this.signatories = data));
          this.$Progress.finish();
        },

        async loadPermission(mid) {
            const response = await axios.get("api/permission/" + this.$gate.showMyID() + "/" + mid).then( 
            res => {
              if (!res.data || res.data.length == 0) {
                return false;
              } else {
                return true;
              }
            })

            const getResponse = async () => {
              const a = await response;

              switch (mid) {
              case 64:
                this.can_access = a;
                if (!this.can_access) {
                  this.$router.push({ name: 'not-found' }) //redirect to not found
                }
                break;
                /*
              case 50:
                this.can_edit = a;
                break;
              case 51:
                this.can_delete = a;
                */
              }
            };
            return getResponse()
        },

        
      },

      created() {
        Trigger.$on('searching',() => {
          let query = this.$parent.search;
          let emptyflag = false;

          if(query == ''){
            Trigger.$emit('RefreshData');
          }else{
            this.$Progress.start();
            axios.get("api/findOffice?q=" + query)
            .then((data) => {
              this.signatories = data;
              
              if (data.total == 0){
                this.emptyflag = true;
              }

              this.$Progress.finish();
            })
            .catch(() => {
              this.$Progress.fail();
            })
          }
          
        });
        
        axios.get("api/users/allusers").then(({ data }) => (this.users = data));
        axios.get("api/offices").then(({ data }) => (this.offices = data));
        axios.get("api/procuserlevels").then(({ data }) => (this.levels = data));
        this.loadSignatories();
        this.loadPermission(64); //Can Manage Signatories
        
        Trigger.$on('RefreshData',() => {
          this.loadSignatories();
        });
      },

  }
</script>
<style scoped>
div >>> p {
font-size: 15px;
}

div >>> strong {
font-size: 15px;
}

div >>> em {
font-size: 15px;
}

div.header >>> p {
line-height: 0.5;
font-size: 20px;
font-family: "Helvetica Narrow","Arial Narrow";
}

div.header >>> strong {
line-height: 0.5;
font-size: 20px;
font-family: "Helvetica Narrow","Arial Narrow";
}

div.header >>> em {
line-height: 0.5;
font-size: 20px;
font-family: "Helvetica Narrow","Arial Narrow";
}

.text-selection-none{
 user-select: none; /* supported by Chrome and Opera */
 -webkit-user-select: none; /* Safari */
 -khtml-user-select: none; /* Konqueror HTML */
 -moz-user-select: none; /* Firefox */
 -ms-user-select: none;
}

</style>
