<template>
    <div class="">

        <div class="row mb-2">
            <div class="col-sm-12">
                <h2>Project Actions</h2>
            </div>
        </div>

        <section class="content">
                <div class="row">
                    <div class="col-md-4">
                        <div class="card">
                            <div class="card-header">
                                <h3 class="card-title">
                                <i class="fa fa-list-ol mr-1"></i>
                                Manage project activities groups
                                </h3>
                                <div class="card-tools">
                                    <button type="button" @click.prevent="newGroupModal" class="btn btn-tool" title="Add new industry"><i class="fas fa-plus"></i></button>
                                </div>
                            </div>

                            <div class="card-body">
                                <draggable v-model="projectactiongroups" handle=".handle">
                                    <transition-group name="list">
                                        <div class="drag-list" v-for="pag in projectactiongroups" :key="pag.id">
                                            <span class="handle ui-sortable-handle">
                                                <i class="fa fa-ellipsis-v handle"></i>
                                                <i class="fa fa-ellipsis-v handle"></i>
                                            </span>
                                            <span class="text ml-2">{{pag.group}}</span>
                                            <div class="tools">
                                                <i @click.prevent="editGroupModal(pag)" class="fa fa-edit"></i>
                                                <i @click.prevent="deleteGroup(pag.id)" class="fa fa-trash"></i>
                                            </div>
                                        </div>
                                    </transition-group>
                                </draggable>
                            </div>

                            <div class="card-footer clearfix">
                                <button type="button" class="btn btn-primary float-left" @click.prevent="saveActionGroupOrder"><i class="fa fa-save"></i> Save Order</button>
                                <button type="button" class="btn btn-primary float-right" @click.prevent="newGroupModal"><i class="fas fa-plus"></i> Add item</button>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="card">
                            <div class="card-header">
                                <h3 class="card-title">
                                <i class="fa fa-list-ol mr-1"></i>
                                Manage project activities, assign role permissions, and drag and drop to arrange actions sequence order
                                </h3>
                                <div class="card-tools">
                                    <button type="button" @click.prevent="newActionModal" class="btn btn-tool" title="Add new industry"><i class="fas fa-plus"></i></button>
                                </div>
                            </div>

                            <div class="card-body">
                                <draggable v-model="projectactions" handle=".handle">
                                    <transition-group name="list">
                                        <div class="drag-list" v-for="pa in projectactions" :key="pa.id">
                                            <span class="handle ui-sortable-handle">
                                                <i class="fa fa-ellipsis-v handle"></i>
                                                <i class="fa fa-ellipsis-v handle"></i>
                                            </span>
                                            <span class="text ml-2"><span class="badge bg-info mr-2">{{pa.percentage}}%</span><b>{{pa.action}}</b></span>

                                            <div class="tools float-right">
                                                <i @click.prevent="editActionModal(pa)" class="fa fa-edit"></i>
                                                <i @click.prevent="deleteAction(pa.id)" class="fa fa-trash"></i>
                                            </div>

                                            <div class="mb-0 mt-2" style="margin-left:24px;">
                                                <span><small><i></i></small></span>
                                            </div>
                                            <div class="mb-0 mt-2" style="margin-left:24px;">
                                                <span><small>Belongs to the group "{{pa.projectactiongroup.group }}"" and changes the project progress to "{{pa.percentage}}%"". {{pa.description}}</small></span>
                                            </div>
                                        </div>

                                    </transition-group>
                                </draggable>
                            </div>

                            <div class="card-footer clearfix">
                                <button type="button" class="btn btn-primary float-left" @click.prevent="saveActionOrder"><i class="fa fa-save"></i> Save Order</button>
                                <button type="button" class="btn btn-primary float-right"  @click.prevent="newActionModal"><i class="fas fa-plus"></i> Add item</button>
                            </div>
                        </div>
                    </div>
                </div>
        </section>

        <!-- Modal : Add/Edit Group -->
        <div class="modal fade" id="groupmodal" tabindex="-1" role="dialog" aria-labelledby="groupModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-md" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 v-show="!editmode" class="modal-title" id="groupModalLabel">Create New Activity Group</h5>
                <h5 v-show="editmode" class="modal-title" id="groupModalLabel">Update Activity Group</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <form @submit.prevent="editmode ? updateGroup() : createGroup()" @keydown="form.onKeydown($event)">
              <div class="modal-body">
                <div class="row">
                    <div class="col-md-12">
                        <!-- Your first column here -->
                        <div class="form-group">
                            <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text">ACTIVITY GROUP</span>
                            </div>
                            <input v-model="form.group" type="text" name="group" autocomplete="off" placeholder="ACTIVITY GROUP.." class="form-control" :class="{ 'is-invalid': form.errors.has('group') }">
                            <has-error :form="form" field="group"></has-error>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-default" data-dismiss="modal"><i class="fa fa-times-circle"></i> CLOSE</button>
                <button v-show="editmode" type="submit" class="btn btn-default"><i class="fa fa-save"></i> UPDATE</button>
                <button v-show="!editmode" type="submit" class="btn btn-default"><i class="fa fa-save"></i> CREATE</button>
              </div>
              </form>
            </div>
          </div>
        </div>

        <!-- Modal : Add/Edit Action -->
        <div class="modal fade" id="actionmodal" tabindex="-1" role="dialog" aria-labelledby="actionModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-md" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 v-show="!editmode" class="modal-title" id="actionModalLabel">Create New Action</h5>
                <h5 v-show="editmode" class="modal-title" id="actionModalLabel">Update Action</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <form @submit.prevent="editmode ? updateAction() : createAction()" @keydown="formAction.onKeydown($event)">
              <div class="modal-body">
                <div class="row">
                    <div class="col-md-12">
                        <!-- Your first column here -->
                        <div class="form-group">
                            <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text">ACTION NAME</span>
                            </div>
                            <input v-model="formAction.action" type="text" name="action" autocomplete="off" placeholder="ACTION NAME.." class="form-control" :class="{ 'is-invalid': formAction.errors.has('action') }">
                            <has-error :form="formAction" field="action"></has-error>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text">ACTIVITY DESCRIPTION</span>
                            </div>
                            <input v-model="formAction.description" type="text" name="description" autocomplete="off" placeholder="DESCRIPTION.." class="form-control" :class="{ 'is-invalid': formAction.errors.has('description') }">
                            <has-error :form="formAction" field="description"></has-error>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text">ACTIVITY GROUP</span>
                            </div>
                            <select v-model="formAction.projectactiongroup_id" name="type" class="form-control" :class="{ 'is-invalid': formAction.errors.has('projectactiongroup_id') }">
                                    <option value="">- Select Industry -</option>
                                    <option v-for="grp in projectactiongroups" :key="grp.id" v-bind:value="grp.id" >{{ grp.group }}</option>
                            </select>
                            <has-error :form="formAction" field="projectactiongroup_id"></has-error>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="percentageSlider">Select Percentage: {{ formAction.percentage }}%</label>
                            <input type="range" class="custom-range" id="percentageSlider" v-model="formAction.percentage" min="0" max="100" step="1">
                            <has-error :form="formAction" field="percentage"></has-error>
                        </div>
                        <div class="form-group">
                            <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text">ACTIVITY BADGE COLOR</span>
                            </div>
                            <select v-model="formAction.color" name="type" class="form-control text-light" :class="['bg-' + formAction.color, { 'is-invalid': formAction.errors.has('color') }]" >
                                    <option value="primary" class="bg-primary">BLUE</option>
                                    <option value="success" class="bg-success">GREEN</option>
                                    <option value="danger" class="bg-danger">RED</option>
                                    <option value="warning" class="bg-warning">YELLOW</option>
                                    <option value="info" class="bg-info">TEAL</option>
                                    <option value="secondary" class="bg-secondary">GRAY</option>
                                    <option value="purple" class="bg-purple">PURPLE</option>
                                    <option value="indigo" class="bg-indigo">INDIGO</option>
                                    <option value="orange" class="bg-orange">ORANGE</option>
                                    <option value="pink" class="bg-pink">PINK</option>
                                    <option value="dark" class="bg-dark">DARK GRAY</option>
                                    <option value="cyan" class="bg-cyan">CYAN</option>
                            </select>
                            <has-error :form="formAction" field="color"></has-error>
                            </div>
                        </div>
                    </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-default" data-dismiss="modal"><i class="fa fa-times-circle"></i> CLOSE</button>
                <button v-show="editmode" type="submit" class="btn btn-default"><i class="fa fa-save"></i> UPDATE</button>
                <button v-show="!editmode" type="submit" class="btn btn-default"><i class="fa fa-save"></i> CREATE</button>
              </div>
              </form>
            </div>
          </div>
        </div>


    </div>
</template>

<script>

    import Form from 'vform';
    import draggable from 'vuedraggable'

    export default {

        components: {
            draggable
        },

        data() {
          return {
            timer: 30,
            editmode: false,
            emptyflag: false,
            can_access: false,
            can_add: false,
            can_edit: false,
            can_delete: false,
            projectactions : [],
            projectactiongroups : [],
            form: new Form({
                id: '',
                group: '',
            }),
            formAction: new Form({
                id: '',
                action: '',
                percentage: null,
                description: '',
                projectactiongroup_id: '',
                color: '',
            })
          }
        },

        computed: {

          second() {
            return this.time / 1000;
          },

          isIdle() {
            const idlestat =  this.$store.state.idleVue.isIdle;
            if (idlestat == true) {
              $('#sessionmodal').modal('show');
              this.countDownTimer();
            } else {
              $('#sessionmodal').modal('hide');
            }

            return idlestat;

          },

        },

        methods: {

            countDownTimer() {
                let timerId = setInterval(() => {
                this.timer -= 1;
                if (!this.$store.state.idleVue.isIdle) clearInterval(timerId);

                if (this.timer < 1) {
                    clearInterval(timerId);
                    document.getElementById('logout-form').submit();
                }
                }, 1000);
                this.timer = 30;
            },

            saveActionOrder() {
                this.$Progress.start();
                //const ordersData = [
                //    { id: 1, order: 3 },
                //    { id: 2, order: 1 },
                //    { id: 3, order: 2 },
                //];
                const ordersData = this.projectactions;
                axios.patch('api/projectactions/order', { orders: ordersData }).then(()=>{
                    toastr.fire({ icon: 'success', title: 'Action order updated'})
                    this.$Progress.finish();
                }).catch(()=>{
                    toastr.fire({ icon: 'error', title: 'Action order update failed'});
                    this.$Progress.fail();
                })
            },

            saveActionGroupOrder() {
                this.$Progress.start();
                const ordergroupsData = this.projectactiongroups;
                axios.patch('api/projectactiongroups/order', { orders: ordergroupsData }).then(()=>{
                    toastr.fire({ icon: 'success', title: 'Action groups order updated'})
                    this.$Progress.finish();
                }).catch(()=>{
                    toastr.fire({ icon: 'error', title: 'Action groups order update failed'});
                    this.$Progress.fail();
                })
            },

            editGroupModal(ind) {
                this.editmode = true;
                this.form.reset();
                this.form.clear();
                $('#groupmodal').modal('show');
                this.form.fill(ind);
            },

            newGroupModal() {
                this.editmode = false;
                this.form.reset();
                this.form.clear();
                $('#groupmodal').modal('show')
            },

            createGroup() {
              this.$Progress.start();
              this.form.post('api/projectactiongroups')
              .then(()=>{
                Trigger.$emit('RefreshData');
                $('#groupmodal').modal('hide')
                toastr.fire({
                  icon: 'success',
                  title: 'Group industry created successfully'
                })
                this.$Progress.finish();
              }).catch(()=>{
                toastr.fire({
                  icon: 'error',
                  title: 'Group information creation failed'
                });
                this.$Progress.fail();
              })
            },

            updateGroup() {
              this.$Progress.start();
              this.form.put('api/projectactiongroups/'+this.form.id).then(()=>{
                Trigger.$emit('RefreshData');
                $('#groupmodal').modal('hide')
                toastr.fire({
                  icon: 'success',
                  title: 'Group information updated successfully'
                })
                this.$Progress.finish();
              }).catch(()=>{
                toastr.fire({
                  icon: 'error',
                  title: 'Group information updating failed'
                });
                this.$Progress.fail();
              });
            },

            async deleteGroup(id) {
                await swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete activity group!'
                }).then((result)=>{
                    if (result.value) {
                        this.form.delete('api/projectactiongroups/'+id).then(()=>{
                            swal.fire(
                                'Deleted!',
                                'Group has been deleted.',
                                'success'
                            );
                            Trigger.$emit('RefreshData');
                            setTimeout(() => {
                                // Code to be executed after the delay
                                this.saveActionGroupOrder();
                            }, 2000);
                        }).catch(error => {
                            if (error.response.status === 422) {
                            swal.fire(
                                'Failed!',
                                'This group has project activities related to it. Please remove them before you can delete this group.',
                                'warning'
                            );
                            } else {
                            swal.fire(
                                'Failed!',
                                'There was something wrong',
                                'warning'
                            );
                            }
                        });
                    }
                });
            },

            // Actions methods
            editActionModal(ind) {
                this.editmode = true;
                this.formAction.reset();
                this.formAction.clear();
                $('#actionmodal').modal('show');
                this.formAction.fill(ind);
            },

            newActionModal() {
                this.editmode = false;
                this.formAction.reset();
                this.formAction.clear();
                this.formAction.percentage = 0;
                $('#actionmodal').modal('show')
            },

            createAction() {
              this.$Progress.start();
              this.formAction.post('api/projectactions')
              .then(()=>{
                Trigger.$emit('RefreshData');
                $('#actionmodal').modal('hide')
                toastr.fire({
                  icon: 'success',
                  title: 'Activity industry created successfully'
                })
                this.$Progress.finish();
              }).catch(()=>{
                toastr.fire({
                  icon: 'error',
                  title: 'Activity information creation failed'
                });
                this.$Progress.fail();
              })
            },

            updateAction() {
              this.$Progress.start();
              this.formAction.put('api/projectactions/'+this.formAction.id).then(()=>{
                Trigger.$emit('RefreshData');
                $('#actionmodal').modal('hide')
                toastr.fire({
                  icon: 'success',
                  title: 'Activity information updated successfully'
                })
                this.$Progress.finish();
              }).catch(()=>{
                toastr.fire({
                  icon: 'error',
                  title: 'Activity information updating failed'
                });
                this.$Progress.fail();
              });
            },

            async deleteAction(id) {
                await swal.fire({
                    title: 'Are you sure?',
                    text: "You won't be able to revert this!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete activity!'
                }).then((result)=>{
                    if (result.value) {
                        this.formAction.delete('api/projectactions/'+id).then(()=>{
                            swal.fire(
                                'Deleted!',
                                'Activity has been deleted.',
                                'success'
                            );
                            Trigger.$emit('RefreshData');
                            setTimeout(() => {
                                this.saveActionOrder();
                            }, 2000);
                        }).catch(error => {
                            if (error.response.status === 422) {
                            swal.fire(
                                'Failed!',
                                error.response.data.message,
                                'warning'
                            );
                            } else {
                            swal.fire(
                                'Failed!',
                                'There was something wrong:' + error,
                                'warning'
                            );
                            }
                        });
                    }
                });
            },

            // trigger in created and RefreshData emit
            loadAll() {
                this.$Progress.start();
                axios.get('api/projectactions').then(({ data }) => ( this.projectactions = data ));
                axios.get('api/projectactiongroups').then(({ data }) => ( this.projectactiongroups = data ));
                this.$Progress.finish();
            },

        },

        created() {
            this.loadAll();

            Trigger.$on('RefreshData',() => {
                this.loadAll();
            });
        },

    }
</script>

<style scoped>


/*image*/
.img-thumbnail{
  height: 164px;
  overflow: hidden;
  margin: auto;

}

.drag-list {
    border-radius: 2px;
    background-color: #f8f9fa;
    border-left: 2px solid #e9ecef;
    color: #495057;
    margin-bottom: 2px;
    padding: 5px;
    display: inline-block;
    font-weight: 600;
    margin-left: 5px;
    width: 100%;
}

.drag-list:hover .tools {
    display: block;
    color: #dc3545;
}

.tools {
    color: #7a8999;
    display: block;
    float: right;
    cursor: pointer;
}

.list-move{
  transition: .5s;
}
.handle {
    cursor: move;
}

@import 'https://adminlte.io/themes/v3/plugins/bs-stepper/css/bs-stepper.min.css';
</style>
