<template>
    <div>
        <div class="row">
          <div class="col-md-12">
            <div class="card card-secondary card-outline">

              <div class="card-header bg-yellow">
                <h4 class="card-title">Project Proposals</h4>

                <div class="card-tools">
                  <div>
                    <button data-toggle="tooltip" data-container="body" title="Add New" class="btn btn-success btn-sm" @click="newModal"><i class="fa fa-plus"></i> NEW PROJECT</button>
                  </div>
                </div>

                <div class="card-tools ml-10">
                    <button data-toggle="tooltip" data-container="body" title="Filter / Sort" class="btn btn-info btn-sm" @click="toggleFilter">
                        <i class="fa fa-filter" aria-hidden="true"></i> <i class="fa fa-sort" aria-hidden="true"></i>
                    </button>

                </div>
              </div>

              <div class="card-body bg-info" v-if="showFilter">
                <div class="row float-right">
                    &nbsp;
                </div>
                <div class="row">
                    <div class="col-lg">
                        <label>FILTER</label>
                        <ul class="products-list product-list-in-card pl-0 pr-2">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">FILTER BY</span>
                                </div>
                                <select name="type" v-model="filterby" class="form-control">
                                    <option value='proposal'>Project Title</option>
                                    <option value='id'>Project ID</option>
                                    <option value='region'>Region</option>
                                    <option value='program_id'>Program</option>
                                    <option value='projectindustry_id'>Industry</option>
                                    <option value='projectaction_id'>Last Action Taken</option>
                                    <option value='undergone_projectaction'>Undergone Action</option>
                                </select>
                            </div>
                        </ul>

                        <ul class="products-list product-list-in-card pl-0 pr-2 mt-3">
                            <div v-if="filterby == 'proposal'">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">PROJECT TITLE</span>
                                    </div>
                                    <input type="text" name="type" v-model="filtertext" class="form-control" />
                                </div>
                            </div>
                            <div v-else-if="filterby == 'id'">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">PROJECT ID</span>
                                    </div>
                                    <input type="number" name="type" v-model="filtertext" class="form-control" />
                                </div>
                            </div>
                            <div v-else-if="filterby == 'region'">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">PROJECT REGION</span>
                                    </div>
                                    <!-- <input type="text" name="type" v-model="filtertext" class="form-control" /> -->
                                    <select v-model="filtertext" name="type" class="form-control">
                                        <option value="">- Select Region -</option>
                                        <option value="BARMM">BARMM</option>
                                        <option value="CAR">CAR</option>
                                        <option value="NCR">NCR</option>
                                        <option value="I">Region I</option>
                                        <option value="II">Region II</option>
                                        <option value="III">Region III</option>
                                        <option value="IV-A">Region IV-A</option>
                                        <option value="IV-B">Region IV-B</option>
                                        <option value="V">Region V</option>
                                        <option value="VI">Region VI</option>
                                        <option value="VII">Region VII</option>
                                        <option value="VIII">Region VIII</option>
                                        <option value="IX">Region IX</option>
                                        <option value="X">Region X</option>
                                        <option value="XI">Region XI</option>
                                        <option value="XII">Region XII</option>
                                        <option value="XIII">Region XIII</option>
                                        <option value="INT">INTERNATIONAL</option>
                                        <option value="OTHERS">OTHERS</option>
                                    </select>
                                </div>
                            </div>
                            <div v-else-if="filterby == 'program_id'">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">PROJECT PROGRAM</span>
                                    </div>
                                    <select v-model="filtertext" name="type" class="form-control">
                                        <option value="">- Select Program -</option>
                                        <option v-for="pg in programs" :key="pg.id" v-bind:value="pg.id" >{{ pg.program_name }}</option>
                                    </select>
                                </div>
                            </div>
                            <div v-else-if="filterby == 'projectindustry_id'">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">PROJECT INDUSTRY</span>
                                    </div>
                                    <select v-model="filtertext" name="type" class="form-control">
                                        <option value="">- Select Industry -</option>
                                        <option v-for="ind in projectindustries" :key="ind.id" v-bind:value="ind.id" >{{ ind.industryname }}</option>
                                    </select>
                                </div>
                            </div>
                            <div v-else-if="filterby == 'projectaction_id'">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">LAST ACTION TAKEN</span>
                                    </div>
                                    <select v-model="filtertext" name="type" class="form-control">
                                        <option value="">- Select Action -</option>
                                        <option v-for="pa in projectactions" :key="pa.id" v-bind:value="pa.id" >{{ pa.action }}</option>
                                    </select>
                                </div>
                            </div>
                            <div v-else-if="filterby == 'undergone_projectaction'">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">ACTION</span>
                                    </div>
                                    <select v-model="filtertext" name="type" class="form-control">
                                        <option value="">- Select Action -</option>
                                        <option v-for="pa in projectactions" :key="pa.id" v-bind:value="pa.id" >{{ pa.action }}</option>
                                    </select>
                                </div>
                            </div>
                        </ul>
                    </div>
                    <div class="col-lg">
                        <label>SORT</label>
                        <ul class="products-list product-list-in-card pl-0 pr-2">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">ORDER BY</span>
                                </div>
                                <select name="type" v-model="orderby" class="form-control">
                                    <option value='created_at'>Date Created</option>
                                    <option value='id'>Project ID</option>
                                    <option value='proposal'>Project Title</option>
                                    <!-- <option value='region'>Region</option>
                                    <option value='program'>Program</option>
                                    <option value='industry'>Industry</option>
                                    <option value='action'>Last Action Taken</option> -->
                                </select>
                            </div>
                        </ul>

                        <ul class="products-list product-list-in-card pl-0 pr-2 mt-3">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">SORT BY</span>
                                </div>
                                <select name="type" v-model="sortby" class="form-control">
                                    <option value='asc'>Ascending</option>
                                    <option value='desc'>Descending</option>
                                </select>
                            </div>
                        </ul>
                    </div>
                    <div class="col-lg">
                        <label>&nbsp;</label>
                        <ul class="products-list product-list-in-card pl-0 pr-2">
                            <div class="form-check">
                            <input class="form-check-input" type="checkbox" @change="fireFilter()" v-model="owner" :checked="owner"><b>SHOW ONLY PROJECTS I CREATED</b></input>
                        </div>
                        </ul>

                        <ul class="products-list product-list-in-card pl-0 pr-2 mt-3">
                            <div class="mt-4">
                                <button data-toggle="tooltip" data-container="body" title="Apply" class="btn btn-purple" @click="fireFilter()">
                                    APPLY
                                </button>
                                <button data-toggle="tooltip" data-container="body" title="Reset" class="btn btn-purple" @click.prevent="resetFilters()">
                                    RESET
                                </button>
                            </div>
                        </ul>

                    </div>
                </div>
              </div>

              <div class="card-body p-0">
                <table class="table table-striped projects">
                  <thead>
                    <tr>
                      <th style="width: 5%">ID</th>
                      <th style="width: 35%">Project Title</th>
                      <th style="width: 5%">Region</th>
                      <th style="width: 5%">Program</th>
                      <th style="width: 15%">Industry</th>
                      <th style="width: 10%">Amt. Requested</th>
                      <th style="width: 10%">Last Action Taken</th>
                      <!-- <th style="width: 5%">Encoder</th> -->
                      <th style="width: 10%">Progress</th>
                      <th style="width: 5%"></th>
                    </tr>
                  </thead>
                  <tbody v-if="execoms.total == 0">
                    <tr class="text-center">
                        <td colspan="8">- NO RESULT -</td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr v-for="exec in execoms.data" :key="exec.id">
                      <td>
                        <b>{{ exec.id }}</b>
                      </td>
                      <td>
                        <b>{{ exec.proposal }}</b><br>
                        <small>
                        Created by {{ exec.user.fname }} {{ exec.user.lname }} on {{ exec.created_at | dobformat }}, received from {{ exec.proponent }}
                        </small>
                      </td>
                      <td>
                        {{ exec.region }}
                      </td>
                      <td>
                        {{ exec.program.program_name }}
                      </td>
                      <td>
                        {{ exec.projectindustry.industryname }}
                      </td>
                      <td>
                        {{ exec.amt_requested | toMoney }}
                      </td>
                      <td>
                        <span :class="['badge', 'bg-' + exec.latest_project_post.projectaction.color]" v-if="exec.latest_project_post">
                            {{ exec.latest_project_post.projectaction_title }}
                        </span>
                        <span class="badge badge-info" v-else>
                            Created a Project
                        </span>
                    </td>
                      <!-- <td>
                        <img :src='"img/profile/" + exec.user.photo'
                            height="45px"
                            alt="creator image"
                            class="img-circle img-bordered-sm"
                            v-tooltip="{
                                            content: exec.user.fname + ' ' + exec.user.lname,
                                            placement: 'top',
                                            classes: ['info'],
                                            targetClasses: ['it-has-a-tooltip'],
                                            offset: -10,
                                            delay: {
                                                show: 500,
                                                hide: 300,
                                            },
                                        }">
                      </td> -->
                      <td>
                        <template v-if="exec.latest_project_post">
                                <div class="progress progress-sm">
                                    <div class="progress-bar bg-green progress-bar-striped"
                                        role="progressbar"
                                        :aria-valuenow="(exec.latest_project_post.projectaction.percentage)"
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                        :style="{ width: (exec.latest_project_post.projectaction.percentage) + '%' }">
                                    </div>
                                </div>
                            <small>{{ exec.latest_project_post.projectaction.percentage }}% Complete</small>
                        </template>
                        <template v-else>
                            <div class="progress progress-sm">
                                <div class="progress-bar bg-green" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%"></div>
                            </div>
                            <small>0% Complete</small>
                        </template>
                      </td>
                      <td>
                        <div class="btn-group btn-group-sm float-right">
                            <router-link class="btn btn-outline-primary btn-sm" :to='"timeline?id=" + exec.id'><i class="fas fa-folder-open"></i></router-link>
                            <!--
                            <button v-if="exec.user_id === $gate.showMyID()" class="btn btn-outline-secondary" data-toggle="tooltip" data-container="body" title="Upload Attachments" type="button" @click="uploadModal(exec)" ><i class="fa fa-upload"></i> UPLOAD ATTACHMENTS</button>
                            -->
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <pagination :data="execoms" @pagination-change-page="getResults" show-disabled :limit="10"></pagination><!--<router-link class="btn btn-outline-primary btn-sm" :to='"timeline?id=1#post0"'><i class="fas fa-folder-open"></i> W/ HASH SAMPLE</router-link>-->
              </div>
            </div>
          </div>
        </div>

        <!-- Modal : Add/Edit Project -->
        <div class="modal fade" id="execommodal" tabindex="-1" role="dialog" aria-labelledby="execomModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-md" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 v-show="!editmode" class="modal-title" id="execomModalLabel">Create New Project Proposal</h5>
                <h5 v-show="editmode" class="modal-title" id="execomModalLabel">Update Project Information</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <form @submit.prevent="editmode ? updateExecom() : createExecom()" @keydown="form.onKeydown($event)">

              <div class="modal-body">
              <div class="row">


                  <div class="col-md-12">
                      <!-- Your first column here -->

                      <div class="form-group">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">PROJECT TITLE</span>
                          </div>
                          <input v-model="form.proposal" type="text" name="proposal" autocomplete="off" placeholder="PROPOSAL TITLE.." class="form-control" :class="{ 'is-invalid': form.errors.has('proposal') }">
                          <has-error :form="form" field="proposal"></has-error>
                        </div>
                      </div>

                      <div class="form-group">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">INDUSTRY/SECTOR</span>
                          </div>
                          <select v-model="form.projectindustry_id" name="type" class="form-control" :class="{ 'is-invalid': form.errors.has('projectindustry_id') }">
                                <option value="">- Select Industry -</option>
                                <option v-for="ind in projectindustries" :key="ind.id" v-bind:value="ind.id" >{{ ind.industryname }}</option>
                          </select>
                          <has-error :form="form" field="projectindustry_id"></has-error>
                        </div>
                      </div>

                      <div class="form-group">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">PROGRAM</span>
                          </div>
                          <select v-model="form.program_id" name="type" class="form-control" :class="{ 'is-invalid': form.errors.has('program_id') }">
                                <option value="">- Select Program -</option>
                                <option v-for="pg in programs" :key="pg.id" v-bind:value="pg.id" >{{ pg.program_name }}</option>
                          </select>
                          <has-error :form="form" field="program_id"></has-error>
                        </div>
                      </div>

                      <div class="form-group">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">AMOUNT REQUESTED</span>
                          </div>
                          <money v-model="form.amt_requested" :precision="2" class="form-control" :class="{ 'is-invalid': form.errors.has('amt_requested') }"></money>
                          <has-error :form="form" field="amt_requested"></has-error>
                        </div>
                      </div>

                      <div class="form-group">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">PROPONENT NAME</span>
                          </div>
                          <input v-model="form.proponent" type="text" name="proponent" autocomplete="off" placeholder="PROPONENT NAME.." class="form-control" :class="{ 'is-invalid': form.errors.has('proponent') }">
                          <has-error :form="form" field="proponent"></has-error>
                        </div>
                      </div>

                        <div class="form-group">
                            <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text">AGE</span>
                            </div>
                            <input type="number" v-model="form.age" class="form-control" :class="{ 'is-invalid': form.errors.has('age') }" />
                            <has-error :form="form" field="age"></has-error>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="input-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text">SEX</span>
                            </div>
                            <div class="form-check form-check-inline ml-3">
                                <input type="radio" id="sexMale" value="MALE" v-model="form.sex" class="form-check-input" />
                                <label for="sexMale" class="form-check-label">MALE</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input type="radio" id="sexFemale" value="FEMALE" v-model="form.sex" class="form-check-input" />
                                <label for="sexFemale" class="form-check-label">FEMALE</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input type="radio" id="sexOthers" value="OTHERS" v-model="form.sex" class="form-check-input" />
                                <label for="sexOthers" class="form-check-label">OTHERS</label>
                            </div>
                            <has-error :form="form" field="sex"></has-error>
                            </div>
                        </div>

                      <div class="form-group">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">COMPANY</span>
                          </div>
                          <input v-model="form.company" type="text" name="company" autocomplete="off" placeholder="COMPANY NAME.." class="form-control" :class="{ 'is-invalid': form.errors.has('company') }">
                          <has-error :form="form" field="company"></has-error>
                        </div>
                      </div>

                      <div class="form-group">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">EMAIL/CONTACTS</span>
                          </div>
                          <input v-model="form.email" type="text" name="email" autocomplete="off" placeholder="EMAIL.." class="form-control" :class="{ 'is-invalid': form.errors.has('email') }">
                          <has-error :form="form" field="email"></has-error>
                        </div>
                      </div>

                      <div class="form-group">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">REGION</span>
                          </div>
                          <select v-model="form.region" name="type" class="form-control" :class="{ 'is-invalid': form.errors.has('region') }">
                            <option value="">- Select Region -</option>
                            <option value="BARMM">BARMM</option>
                            <option value="CAR">CAR</option>
                            <option value="NCR">NCR</option>
                            <option value="I">Region I</option>
                            <option value="II">Region II</option>
                            <option value="III">Region III</option>
                            <option value="IV-A">Region IV-A</option>
                            <option value="IV-B">Region IV-B</option>
                            <option value="V">Region V</option>
                            <option value="VI">Region VI</option>
                            <option value="VII">Region VII</option>
                            <option value="VIII">Region VIII</option>
                            <option value="IX">Region IX</option>
                            <option value="X">Region X</option>
                            <option value="XI">Region XI</option>
                            <option value="XII">Region XII</option>
                            <option value="XIII">Region XIII</option>
                            <option value="INT">INTERNATIONAL</option>
                            <option value="OTHERS">OTHERS</option>
                          </select>
                          <has-error :form="form" field="region"></has-error>
                        </div>
                      </div>

                      <div class="form-group">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">ADDRESS</span>
                          </div>
                          <input v-model="form.address" type="text" name="address" autocomplete="off" placeholder="ADDRESS.." class="form-control" :class="{ 'is-invalid': form.errors.has('address') }">
                          <has-error :form="form" field="address"></has-error>
                        </div>
                      </div>

                      <div class="form-group">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">DATE RECEIVED</span>
                          </div>
                          <input v-model="form.date_received" type="date" name="date_received" autocomplete="off" placeholder="DATE RECEIVED.." class="form-control" :class="{ 'is-invalid': form.errors.has('date_received') }">
                          <has-error :form="form" field="date_received"></has-error>
                        </div>
                      </div>

                      <div class="form-group">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">REMARKS</span>
                          </div>
                          <textarea v-model="form.remarks" rows="4" name="remarks" autocomplete="off" placeholder="REMARKS.." class="form-control" :class="{ 'is-invalid': form.errors.has('remarks') }" />
                          <has-error :form="form" field="remarks"></has-error>
                        </div>
                      </div>



                  </div>


                  <!--
                  <div class="col-md-4">
                      //Your third column here
                  </div>
                  -->


              </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-default" data-dismiss="modal"><i class="fa fa-times-circle"></i> CLOSE</button>
                <button v-show="editmode" type="submit" class="btn btn-default"><i class="fa fa-save"></i> UPDATE</button>
                <button v-show="!editmode" type="submit" class="btn btn-default"><i class="fa fa-save"></i> CREATE</button>
              </div>

              </form>

            </div>
          </div>
        </div>
    </div>
</template>

<script>
    import Form from 'vform';

    export default {
        data() {
          return {
            timer: 30,
            editmode: false,
            emptyflag: false,
            filterflag: false,
            execoms : {},
            execfiles : {},
            showFilter: false,
            projectindustries: {},
            programs: {},
            financial : true,
            nonfinancial : true,
            active: true,
            pending: true,
            cancelled: true,
            owner: false,
            filterby: 'proposal',
            filtertext: '',
            projectactions: {},
            orderby: 'created_at',
            sortby: 'desc',
            is_secretariat: false,
            can_access: false,
            can_add: false,
            can_upload: false,
            form: new Form({
              id: '',
              proposal: '',
              projectindustry_id: '',
              program_id: '',
              proponent: '',
              company:'',
              email:'',
              region:'',
              address:'',
              date_received:'',
              amt_requested:'',
              remarks: '',
              user_id: '',
            }),
            uploadform: new Form({
              execom_id: '',
              filename: '',
              filesize: '',
              filetype: '',
              filepath: '',
              uploader: '',
            })
          }
        },

        components: {

        },

        computed: {

          second() {
            return this.time / 1000;
          },

          isIdle() {
            const idlestat =  this.$store.state.idleVue.isIdle;
            if (idlestat == true) {
              $('#sessionmodal').modal('show');
              this.countDownTimer();
            } else {
              $('#sessionmodal').modal('hide');
            }

            return idlestat;

          },

        },

        methods: {

            goToTimeline(id) {
                this.$router.push("/timeline?id=" + id + '#post2');
            },

            countDownTimer() {
                let timerId = setInterval(() => {
                this.timer -= 1;
                if (!this.$store.state.idleVue.isIdle) clearInterval(timerId);

                if (this.timer < 1) {
                    clearInterval(timerId);
                    document.getElementById('logout-form').submit();
                }
                }, 1000);

                this.timer = 30;
            },

            editModal(execom) {
                this.editmode = true;
                this.form.reset();
                this.form.clear();
                $('#execommodal').modal('show');
                this.form.fill(execom);
            },

            newModal() {
                this.editmode = false;
                this.form.reset();
                this.form.clear();
                this.form.user_id = this.$gate.showMyID();
                $('#execommodal').modal('show')
            },

            uploadModal(execom) {
                $('#uploadmodal').modal('show')
                this.form.fill(execom);
                this.loadExecfiles();
            },

            resetFilters() {
                this.filtertext = '';
                this.sortby = 'desc';
                this.orderby = 'id';
                this.filterby = 'proposal';
                this.loadExecoms();
            },

            toggleFilter() {
                this.showFilter = !this.showFilter;
            },

            createExecom() {
              this.$Progress.start();
              this.form.post('api/execoms')
              .then(()=>{
                Trigger.$emit('RefreshData');
                $('#execommodal').modal('hide')
                toastr.fire({
                  icon: 'success',
                  title: 'Project proposal created successfully'
                })
                this.$Progress.finish();
              }).catch(()=>{
                toastr.fire({
                  icon: 'error',
                  title: 'Project information creation failed'
                });
                this.$Progress.fail();
              })
            },

            updateExecom() {
              this.$Progress.start();
              this.form.put('api/execoms/'+this.form.id).then(()=>{
                Trigger.$emit('RefreshData');
                $('#execommodal').modal('hide')
                toastr.fire({
                  icon: 'success',
                  title: 'Project information updated successfully'
                })
                this.$Progress.finish();
              }).catch(()=>{
                toastr.fire({
                  icon: 'error',
                  title: 'Project information updating failed'
                });
                this.$Progress.fail();
              });
            },

            deleteExecom(id) {
              swal.fire({

                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete project!'
                }).then((result)=>{
                  if (result.value) {
                    this.form.put('api/execoms/cancel/'+id).then(()=>{
                      swal.fire(
                        'Deleted!',
                        'Project has been deleted.',
                        'success'
                      );
                      Trigger.$emit('RefreshData');
                    }).catch(()=>{
                      swal.fire(
                        'Failed!',
                        'There was something wrong',
                        'warning'
                      )
                    });
                  }
              })
            },

            getResults(page = 1) {

                let query = this.$parent.search;

                if (this.filterflag) {
                    this.fireFilter(page);
                } else {
                    if(query == ''){
                        axios.get('api/execoms?page=' + page).then(response => { this.execoms = response.data; });
                    } else {
                        axios.get('api/findExecom?q=' + query + '&page=' + page).then(response => { this.execoms = response.data; });
                    }

                }

                //console.log(query);
            },


            loadExecoms() {

                this.filterflag = false;

                let page = this.execoms.current_page;
                let query = this.$parent.search;
                let o = '';

                if (this.owner === true) {
                  o = this.$gate.showMyID();
                } else {
                  o = '';
                }

                this.$Progress.start();
                if(o == '' && query == '') {
                    axios.get('api/execoms?q=' + query + '&page=' + page).then(({ data }) => ( this.execoms = data ));
                } else {
                    if(o != '') {
                        axios.get('api/findExecom?page=' + page +
                        '&q=' + query +
                        '&o=' + o
                        ).then(({ data }) => (this.execoms = data));
                    } else {
                        axios.get('api/findExecom?q=' + query).then(({ data }) => ( this.execoms = data ));
                    }
                }



                this.$Progress.finish();
            },

            fireFilter(page) {
                this.filterflag = true;
                this.filterExecoms(this.filterby, this.filtertext, this.orderby, this.sortby, this.owner, page)
            },

            async filterExecoms(filterby, filtertext, orderby, sortby, owner, page) {
                this.$Progress.start();
                let o = '';

                if (owner === true) {
                  o = this.$gate.showMyID();
                } else {
                  o = '';
                }

                let url;
                url = 'api/filterExecom?page=' + page;

                await axios.get(url, {
                    params: {
                        filterby: filterby,
                        filtertext: filtertext,
                        orderby: orderby,
                        sortby: sortby,
                        owner: o
                    }
                }).then(response => {
                        this.execoms = response.data
                    })
                    .catch(err => {
                        console.log(err);
                    });

                this.$Progress.finish();

                // this.$Progress.start();
                // if(o == '' && query == '') {
                //     axios.get('api/execoms?q=' + query + '&page=' + page).then(({ data }) => ( this.execoms = data ));
                // } else {
                //     if(o != '') {
                //         axios.get('api/findExecom?page=' + page +
                //         '&q=' + query +
                //         '&o=' + o
                //         ).then(({ data }) => (this.execoms = data));
                //     } else {
                //         axios.get('api/findExecom?q=' + query).then(({ data }) => ( this.execoms = data ));
                //     }
                // }



                // this.$Progress.finish();
            },

            loadExecfiles() {
                this.$Progress.start();
                axios.get('api/execomfiles/' + this.form.id).then(({ data }) => (this.execfiles = data));
                this.$Progress.finish();
            },

            viewPDF(uploader, filepath) {
              window.open("/storage/execom_files/" + uploader + '/' + filepath, "_blank");
            },

            downloadFile(id, title) {
              axios({
                url: 'api/download/' + id,
                method: 'GET',
                responseType: 'blob', // important
              }).then((response) => {
                if (!window.navigator.msSaveOrOpenBlob){
                  // BLOB NAVIGATOR
                  const url = window.URL.createObjectURL(new Blob([response.data]));
                  const link = document.createElement('a');
                  link.href = url;
                  link.setAttribute('download', title);
                  document.body.appendChild(link);
                  link.click();
                  toastr.fire({
                        icon: 'success',
                        title: 'File downloaded'
                      });
                }else{
                  // BLOB FOR EXPLORER 11
                  const url = window.navigator.msSaveOrOpenBlob(new Blob([response.data]), title);
                }
              });
            },

            async loadPermission(mid) {
              const response = await axios.get("api/permission/" + this.$gate.showMyID() + "/" + mid).then(
              res => {
                if (!res.data || res.data.length == 0) {
                  return false;
                } else {
                  return true;
                }
              })

              const getResponse = async () => {
                const a = await response;

                switch (mid) {
                case 29:
                  this.can_access = a;
                  if (!this.can_access) {
                    this.$router.push({ name: 'not-found' }) //redirect to not found
                  }
                  break;
                case 30:
                  this.can_add = a;
                  break;
                case 31:
                  this.is_secretariat = a;
                  break;
                case 32:
                  this.can_upload = a;
                }
              };
              return getResponse()
          },



        },

        created() {
          Trigger.$on('searching',() => {
            let query = this.$parent.search;
            let emptyflag = false;

            if(query == ''){
              Trigger.$emit('RefreshData');
            }else{
              this.$Progress.start();
              this.filterflag = false;
              axios.get("api/findExecom?q=" + query)
              .then((data) => {
                this.execoms = data.data;

                if (data.data.total == 0){
                  this.emptyflag = true;
                }

                this.$Progress.finish();
              })
              .catch(() => {
                this.$Progress.fail();
              })
            }

          });

          axios.get("api/projectindustries").then(({ data }) => (this.projectindustries = data));
          axios.get("api/programs").then(({ data }) => (this.programs = data));
          axios.get("api/projectactions").then(({ data }) => (this.projectactions = data));

          this.loadExecoms();
          //this.loadPermission(29); //Can access project proposal
          //this.loadPermission(30); //Can add new proposal
          //this.loadPermission(31); //Execom secretariat
          //this.loadPermission(32); //Can upload proposal attachments

          Trigger.$on('RefreshData',() => {
            this.loadExecoms();
          });
        },
    }
</script>

<style lang="scss" scoped>

.card-body.p-0 .table tbody>tr>td:first-of-type {
    padding-left: 0.5rem;
}
.controls {
  text-align: right;
}
.card-header>.card-tools {
    float: right;
    margin-right: 1rem;
}
.form-check {
    margin-top: 5px;
}

.bgactive {
  background-color: #DBF3F3 !important;
}

.bgpending {
  background-color: #FFF5DC !important;
}

.bgcancelled {
  background-color: #FFE0E6 !important;
}

/* For mobile friendly data tables */
// Breakpoints
$bp-maggie: 15em;
$bp-lisa: 30em;
$bp-bart: 48em;
$bp-marge: 62em;
$bp-homer: 75em;

.responsive-table {
  width: 100%;
  margin-bottom: 1.5em;
  border-spacing: 0;

  @media (min-width: $bp-bart) {
    font-size: .9em;
  }

  @media (min-width: $bp-marge) {
    font-size: 1em;
  }

  thead {
    // Accessibly hide <thead> on narrow viewports
    position: absolute;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    padding: 0;
    border: 0;
    height: 1px;
    width: 1px;
    overflow: hidden;

    @media (min-width: $bp-bart) {
      // Unhide <thead> on wide viewports
      position: relative;
      clip: auto;
      height: auto;
      width: auto;
      overflow: auto;
    }

    th {
      //background-color: rgba(38,137,13,1);
      //border: 1px solid rgba(134,188,37,1);
      //font-weight: normal;
      //text-align: center;
      //color: white;

      &:first-of-type {
        text-align: left;
      }
    }
  }

  // Set these items to display: block for narrow viewports
  tbody,
  tr,
  th,
  td {
    display: block;
    padding: 0;
    text-align: left;
    white-space: normal;
  }

  tr {
    @media (min-width: $bp-bart) {
      // Undo display: block
      display: table-row;
    }
  }

  th,
  td {
    padding: .5em;
    vertical-align: middle;

    @media (min-width: $bp-lisa) {
      padding: .75em .5em;
    }

    @media (min-width: $bp-bart) {
      // Undo display: block
      display: table-cell;
      padding: .5em;
    }

    @media (min-width: $bp-marge) {
      padding: .75em .5em;
    }

    @media (min-width: $bp-homer) {
      padding: .75em;
    }
  }

  tbody {
    @media (min-width: $bp-bart) {
      // Undo display: block
      display: table-row-group;
    }

    tr {
      margin-bottom: 1em;

      @media (min-width: $bp-bart) {
        // Undo display: block
        display: table-row;
        border-width: 1px;
      }

      &:last-of-type {
        margin-bottom: 0;
      }

      &:nth-of-type(even) {
        @media (min-width: $bp-bart) {
          background-color: rgba(0,0,0,.12);
        }
      }
    }

    th[scope="row"] {
      background-color: #0D4E86;
      color: white;

      @media (min-width: $bp-lisa) {
        border-bottom: 1px solid #DEE2E6;
      }

      @media (min-width: $bp-bart) {
        background-color: transparent;
        color: rgba(0,0,0.87);
        text-align: left;
      }
    }

    td {
      text-align: right;

      @media (min-width: $bp-bart) {
        //border-left: 1px solid rgba(134,188,37,1);
        border-bottom: 1px solid #CCCCCC;
        text-align: left;
      }

      &:last-of-type {
        @media (min-width: $bp-bart) {
          text-align: right;
        }
      }
    }

    td[data-type=currency] {
      text-align: right;
    }

    td[data-title]:before {
      content: attr(data-title);
      float: left;
      font-size: .8em;
      color: rgba(0,0,0,.54);

      @media (min-width: $bp-lisa) {
        font-size: .9em;
      }

      @media (min-width: $bp-bart) {
        // Don’t show data-title labels
        content: none;
      }
    }
  }
}
/* For mobile friendly data tables */
</style>
