<template>
    <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card card-secondary card-outline">
              <div class="card-header bg-yellow">
                <h4 class="card-title"><i class="fas fa-file-alt"></i> Loans List</h4>

                <div class="card-tools">
                  <div>
                    <button data-toggle="tooltip" data-container="body" title="Add New" class="btn btn-outline-secondary btn-sm" @click="newModal"><i class="fa fa-plus"></i></button>
                  </div>
                  
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <table class="table responsive-table table-striped">
                  <thead>
                    <tr>
                      <th scope="col" class="pl-2">Employee Name</th>
                      <th scope="col" class="pl-2">Loan Type</th>
                      <th scope="col">Monthly Amortization</th>
                      <th scope="col">Start Date</th>
                      <th scope="col">Maturity Date</th>
                      <th scope="col">Status</th>
                      <th scope="col" class="text-right">Options</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="lon in loans.data" :key="lon.id">
                      <th scope="row" class="pl-2">{{ lon.user.lname }}, {{ lon.user.fname }} {{ lon.user.mname | midname }}</th>
                      <td data-title="Loan Type" class="pl-2">{{ lon.loan_type }}</td>
                      <td data-title="Monthly Amortization">{{ lon.amortization | toMoney }}</td>
                      <td data-title="Start Date">{{ lon.date_aquired | customdate}}</td>
                      <td data-title="Maturity Date">{{ lon.date_expiration | customdate}}</td>
                      <td data-title="Status">
                          <span v-if="(lon.status == 'Active')" class="badge badge-success">Active</span>
                          <span v-else class="badge badge-danger">Expired</span>
                      </td>
                      <td data-title="Options">
                        <div class="btn-group btn-group-sm">
                          <button v-show="can_edit" class="btn btn-outline-secondary" data-toggle="tooltip" data-container="body" title="Edit" type="button" @click="editModal(lon)" ><i class="fa fa-edit"></i></button>
                          <button v-show="can_delete" class="btn btn-outline-secondary" data-toggle="tooltip" data-container="body" title="Delete" type="button" @click="deleteLoan(lon.id)"><i class="fa fa-trash"></i></button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-show="loans.total==0">
                      <tr><td colspan="10">No Loans found.</td></tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                <pagination :data="loans" @pagination-change-page="getResults" show-disabled :limit="10"></pagination>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="loanmodal" tabindex="-1" role="dialog" aria-labelledby="LoanModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 v-show="!editmode" class="modal-title" id="LoanModalLabel">Create New Loan Information</h5>
                <h5 v-show="editmode" class="modal-title" id="LoanModalLabel">Update Loan Information</h5> 
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <form @submit.prevent="editmode ? updateLoan() : createLoan()" @keydown="form.onKeydown($event)">

              <div class="modal-body">
              <div class="row">


                  <div class="col-md-6">
                      <!-- Your first column here -->
                      <div class="form-group">
                        <div v-show="!editmode" class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">EMPLOYEE</span>
                          </div>
                          <selectize placeholder="- Select Employee -" v-model="form.user_id" name="user_id" class="form-control" :class="{ 'is-invalid': form.errors.has('user_id') }">
                            <option v-for="user in users" :key="user.id" v-bind:value="user.id" >{{ user.lname }}, {{ user.fname }} {{ user.mname | midname }}</option>
                          </selectize>
                          <has-error :form="form" field="user_id"></has-error>
                        </div>

                        <div v-show="editmode" class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">EMPLOYEE</span>
                          </div>
                          <selectize placeholder="- Select Employee -" v-model="form.user_id" name="user_id" class="form-control" :class="{ 'is-invalid': form.errors.has('user_id') }" disabled>
                            <option v-for="user in users" :key="user.id" v-bind:value="user.id" >{{ user.lname }}, {{ user.fname }} {{ user.mname | midname }}</option>
                          </selectize>
                          <has-error :form="form" field="user_id"></has-error>
                        </div>

                      </div>

                      <div class="form-group">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">LOAN TYPE</span>
                          </div>
                          <selectize placeholder="- Select Loan Type -" v-model="form.loan_type" name="loan_type" class="form-control" :class="{ 'is-invalid': form.errors.has('loan_type') }">
                            <option v-for="loantype in loantypes" :key="loantype.id" v-bind:value="loantype.loan_type" >{{ loantype.loan_type }}</option>
                          </selectize>
                          <has-error :form="form" field="loan_type"></has-error>
                        </div>
                      </div>

                      <div class="form-group">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">STATUS</span>
                          </div>
                          <select v-model="form.status" name="status" class="form-control" :class="{ 'is-invalid': form.errors.has('status') }">
                            <option selected disabled value="">SELECT LOAN STATUS..</option>
                            <option value="Active">Active</option>
                            <option value="Expired">Expired</option>
                          </select>
                          <has-error :form="form" field="status"></has-error>
                        </div>
                      </div>

                  </div>

                  <div class="col-md-6">
                      <!-- Your second column here -->

                      <div class="form-group">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">AMORTIZATION</span>
                          </div>
                          <input v-model="form.amortization" type="text" name="amortization" autocomplete="off" placeholder="AMORTIZATION.." class="form-control" :class="{ 'is-invalid': form.errors.has('amortization') }">
                          <has-error :form="form" field="amortization"></has-error>
                        </div>
                      </div>

                      <div class="form-group">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">LOAN DATE</span>
                          </div>
                          <input v-model="form.date_aquired" type="date" name="date_aquired" autocomplete="off" placeholder="LAON DATE.." class="form-control" :class="{ 'is-invalid': form.errors.has('date_aquired') }">
                          <has-error :form="form" field="date_aquired"></has-error>
                        </div>
                      </div>

                      <div class="form-group">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">MATURITY DATE</span>
                          </div>
                          <input v-model="form.date_expiration" type="date" name="date_expiration" autocomplete="off" placeholder="MATURITY DATE.." class="form-control" :class="{ 'is-invalid': form.errors.has('date_expiration') }">
                          <has-error :form="form" field="date_expiration"></has-error>
                        </div>
                      </div>

                      

                  </div>


                  <!--
                  <div class="col-md-4">
                      //Your third column here
                  </div>
                  -->
     
          
              </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-default" data-dismiss="modal"><i class="fa fa-times-circle"></i> CLOSE</button>
                <button v-show="editmode" type="submit" class="btn btn-default"><i class="fa fa-save"></i> UPDATE</button>
                <button v-show="!editmode" type="submit" class="btn btn-default"><i class="fa fa-save"></i> CREATE</button>
              </div>

              </form>

            </div>
          </div>
        </div>



      </div>

</template>

<script>

    import Form from 'vform';

    export default {
        data() {
          return {
            timer: 30,
            editmode: false,
            emptyflag: false,
            loans : {},
            users: {},
            loantypes: {},
            positions: {},
            can_access: false,
            can_edit: false,
            can_delete: false,
            form: new Form({
              id: '',
              user_id: '',
              designation: '',
              loan_type: '',
              amortization: '',
              date_aquired: '',
              date_expiration: '',
              status: '',
            })
          }
        },

        computed: {
          
          second() {
            return this.time / 1000;
          },

          isIdle() {
            const idlestat =  this.$store.state.idleVue.isIdle;
            if (idlestat == true) {
              $('#sessionmodal').modal('show');
              this.countDownTimer();
            } else {
              $('#sessionmodal').modal('hide');
            }

            return idlestat;

          },

        },

        methods: {

          countDownTimer() {
            let timerId = setInterval(() => {
              this.timer -= 1;
              if (!this.$store.state.idleVue.isIdle) clearInterval(timerId);

              if (this.timer < 1) {
                clearInterval(timerId);
                document.getElementById('logout-form').submit();
              }
            }, 1000);

            this.timer = 30;
          },

          createLoan() {
            this.$Progress.start();
            this.form.post('api/loans')
            .then(()=>{
              Trigger.$emit('RefreshData');
              $('#loanmodal').modal('hide')
              toastr.fire({
                icon: 'success',
                title: 'Loan information created successfully'
              })
              this.$Progress.finish();
            }).catch(()=>{
              toastr.fire({
                icon: 'error',
                title: 'Loan information creation failed'
              });
              this.$Progress.fail();
            })
          },

          updateLoan() {
            this.$Progress.start();
            this.form.put('api/loans/'+this.form.id).then(()=>{
              Trigger.$emit('RefreshData');
              $('#loanmodal').modal('hide')
              toastr.fire({
                icon: 'success',
                title: 'Loan information updated successfully'
              })
              this.$Progress.finish();
            }).catch(()=>{
              toastr.fire({
                icon: 'error',
                title: 'Loan information updating failed'
              });
              this.$Progress.fail();
            });
          },

          deleteLoan(id) {
            swal.fire({

              title: 'Are you sure?',
              text: "You won't be able to revert this!",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes, delete record!'
              }).then((result)=>{
                if (result.value) {
                  this.form.delete('api/loans/'+id).then(()=>{
                    swal.fire(
                      'Deleted!',
                      'Loan information has been deleted.',
                      'success'
                    );
                    Trigger.$emit('RefreshData');
                  }).catch(()=>{
                    swal.fire(
                      'Failed!', 
                      'There was something wrong', 
                      'warning'
                    )
                  });
                }
            })
          },

          editModal(Loan) {
            this.editmode = true;
            this.form.reset();
            this.form.clear();
            $('#loanmodal').modal('show');
            this.form.fill(Loan);
          },

          newModal() {
            this.editmode = false;
            this.form.reset();
            this.form.clear();
            $('#loanmodal').modal('show')
          },

          getResults(page = 1) {

            let query = this.$parent.search;

            if(query == ''){
              axios.get('api/loans?page=' + page).then(response => { this.loans = response.data; }); 
            }else{
              axios.get('api/findLoan?q=' + query + '&page=' + page).then(response => { this.loans = response.data; }); 
            }

            console.log(query);

            
          },

          async loadPermission(mid) {
              const response = await axios.get("api/permission/" + this.$gate.showMyID() + "/" + mid).then( 
              res => {
                if (!res.data || res.data.length == 0) {
                  return false;
                } else {
                  return true;
                }
              })

              const getResponse = async () => {
                const a = await response;

                switch (mid) {
                case 12:
                  this.can_access = a;
                  if (!this.can_access) {
                    this.$router.push({ name: 'not-found' }) //redirect to not found page if !can access loans
                  }
                  break;
                case 13:
                  this.can_edit = a;
                  break;
                case 14:
                  this.can_delete = a;
                }
              };

              return getResponse()
          },

          loadLoans() {
            this.$Progress.start();
            
            let query = this.$parent.search;
            let page = this.loans.current_page;
            
            if(query == ''){
              axios.get('api/loans?page=' + page).then(({ data }) => (this.loans = data));
            }else{
              axios.get('api/findLoan?q=' + query + '&page=' + page).then(({ data }) => (this.loans = data));
            }

            this.$Progress.finish();
          },

          
        },

        created() {
          Trigger.$on('searching',() => {
            let query = this.$parent.search;
            let emptyflag = false;

            if(query == ''){
              Trigger.$emit('RefreshData');
            }else{
              this.$Progress.start();
              axios.get("api/findLoan?q=" + query)
              .then((data) => {
                this.loans = data.data;
                
                if (data.data.total == 0){
                  this.emptyflag = true;
                }

                this.$Progress.finish();
              })
              .catch(() => {
                this.$Progress.fail();
              })
            }
            
          });
          
          this.loadLoans();
          axios.get("api/users/allusers").then(({ data }) => (this.users = data));
          axios.get("api/loantypes").then(({ data }) => (this.loantypes = data));

          this.loadPermission(12); //Can access loans
          this.loadPermission(13); //Can edit loan
          this.loadPermission(14); //Can delete loan



          
          Trigger.$on('RefreshData',() => {
            this.loadLoans();
          });
        },

    }
</script>
