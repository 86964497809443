<template>
    <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card card-secondary card-outline">
              <div class="card-header">
                <h3 class="card-title"><i class="fas fa-edit"></i> Procurement Projects</h3>

                <div class="card-tools">
                  <!--
                  <span v-if="(this.ppmpdetails.status == 0)" class="badge badge-info"><i>Status:</i> <b>In-Preparation</b></span>
                  <span v-else-if="(this.ppmpdetails.status == 1)" class="badge badge-warning"><i>Status:</i> <b>Revision</b></span>
                  <span v-else-if="(this.ppmpdetails.status == 2)" class="badge badge-primary"><i>Status:</i> <b>Pending</b></span>
                  <span v-else-if="(this.ppmpdetails.status == 3)" class="badge badge-success"><i>Status:</i> <b>Approved</b></span>
                  <span v-if="ppmpdetails.ppmpitems" class="badge badge-secondary ml-1 mr-1"><i>No. of Items:</i> <b>{{ ppmpdetails.ppmpitems.length }}</b></span>
                  <span v-if="ppmpdetails.ppmpitems" class="badge badge-secondary"><i>Total Amount:</i> <b>{{ this.total_expenses | toMoney }}</b></span>
                  -->
                </div>
              </div>
              <!-- /.card-header -->
              <div class="row m-0 bg-secondary disabled color-palette">
                  <div class="col-md-2">
                    <div class="btn-group mt-2 mb-2 d-flex justify-content-start">
                        <button type="button" class="btn btn-success" @click="newModal()"><i class="fa fa-plus"></i> NEW PROJECT</button>
                    </div>
                  </div>
                  <div class="col-md-8 mt-2 mb-2">

                  </div>
                  <div class="col-md-2">
                    <div class="input-group input-group-md mt-2 mb-2 d-flex justify-content-end">
                      <div class="input-group-prepend">
                        <span class="input-group-text">CALENDAR YEAR</span>
                      </div>
                      <select name="type" @change="loadData(year)" v-model="year" class="form-control">
                          <option v-for="year in years" :value="year" :key="year">{{ year }}</option>
                      </select>
                    </div>
                  </div>

              </div>

              <div class="card-body table-responsive p-0 m-0">
                <table class="table responsive-table table-sm table-striped">
                  <thead>
                      <tr>
                      <th scope="col" class="pl-2">Reference No.</th>
                      <th scope="col">Procurement Project</th>
                      <th scope="col">Mode of Procurement</th>
                      <th scope="col">PMO/Enduser</th>
                      <th scope="col">Status</th>
                      <th scope="col">Date Posted</th>
                      <th scope="col">Remarks</th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr v-for="pp in procprojects" :key="pp.id">
                          <td data-title="Reference No.">{{ pp.refno }}</td>
                          <td data-title="Procurement Project"><a style="cursor: pointer;" class="text-primary font-weight-bold" @click="editModal(pp)">{{ pp.title }}</a></td>
                          <td data-title="Mode of Procurement">{{ pp.procmode.alias }}</td>
                          <td data-title="PMO/Enduser">{{ pp.enduser }}</td>
                          <td data-title="Status">
                            <span v-if="pp.status == 'Ongoing'" class="badge badge-warning">Ongoing</span>
                            <span v-else-if="pp.status == 'Failed'" class="badge badge-danger">Failed</span>
                            <span v-else-if="pp.status == 'Completed'" class="badge badge-success">Completed</span>
                          </td>
                          <td data-title="Date Posted">{{ pp.created_at }}</td>
                          <td data-title="Remarks">{{ pp.remarks  }}</td>
                      </tr>
                  </tbody>
                  <tbody v-if="procprojects" v-show="procprojects.length==0">
                      <tr><td colspan="7">*** NO ITEMS TO DISPLAY ***</td></tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body <pagination :data="ppmpitems" @pagination-change-page="getResults" show-disabled :limit="2"></pagination> -->
              <div class="card-footer">
                <div class="card-tools d-flex justify-content-end">

                </div>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="actionmodal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="actionModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
            <div class="modal-content">
              <div class="modal-header bg-warning">
                <h5 class="modal-title text-dark" id="actionModalLabel"><template v-if="!editmode">Add New </template>Procurement Project</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div class="modal-body">
                <div class="row">
                    <div class="col-sm-4">
                        <div class="form-group">
                        <label>Reference No.</label>
                        <input type="text" class="form-control" v-model="form.refno">
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form-group">
                        <label>Project Title</label>
                        <input type="text" class="form-control" v-model="form.title">
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form-group">
                        <label>Mode of Procurement</label>
                        <select v-model="form.procmode_id" name="type" class="form-control">
                            <option v-for="(pm,idx) in procmodes" :value="pm.id" :key="idx">{{ pm.alias }}</option>
                        </select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-4">
                        <div class="form-group">
                        <label>Status</label>
                        <select class="form-control" v-model="form.status">
                            <option value=""> - Select Status - </option>
                            <option value="Ongoing">Ongoing</option>
                            <option value="Completed">Completed</option>
                        </select>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form-group">
                        <label>PMO/Enduser</label>
                        <select v-model="form.enduser" name="type" class="form-control">
                            <option v-for="(ofc,idx) in offices" :value="ofc.alias" :key="idx">{{ ofc.alias }}</option>
                        </select>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form-group">
                        <label>Remarks</label>
                        <textarea class="form-control" rows="1" data-gramm="false" wt-ignore-input="true" v-model="form.remarks"></textarea>
                        </div>
                    </div>
                </div>

                <div class="p-1">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="d-flex justify-content-end">
                        <small v-if="pritems.length != 0"  class="text-danger mr-4 mt-3">*The project has items. Remove first to delete this project.</small>
                        <button :disabled="pritems.length != 0" class="btn btn-primary mr-1" data-container="body" type="button" @click="deleteDetails()"><i class="fa fa-trash"></i> Delete Project</button>
                        <button class="btn btn-primary mr-1" data-container="body" type="button" @click="saveDetails()"><i class="fa fa-save"></i> Save Details</button>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row p-1" v-show="editmode">
                    <div class="col-12 col-sm-12">
                        <div class="card card-primary card-tabs">
                            <div class="card-header p-0 pt-1">
                                <ul class="nav nav-tabs" id="custom-tabs-one-tab" role="tablist">
                                    <li class="nav-item">
                                        <a class="nav-link active" id="custom-tabs-one-home-tab" data-toggle="pill" href="#custom-tabs-one-home" role="tab" aria-controls="custom-tabs-one-home" aria-selected="true">Items</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" id="custom-tabs-one-profile-tab" data-toggle="pill" href="#custom-tabs-one-profile" role="tab" aria-controls="custom-tabs-one-profile" aria-selected="false">Bidders</a>
                                    </li>
                                    <!--
                                    <li class="nav-item">
                                        <a class="nav-link" id="custom-tabs-one-messages-tab" data-toggle="pill" href="#custom-tabs-one-messages" role="tab" aria-controls="custom-tabs-one-messages" aria-selected="false">Activities</a>
                                    </li>
                                    -->
                                </ul>
                            </div>
                            <div class="card-body">
                                <div class="tab-content" id="custom-tabs-one-tabContent">
                                    <div class="tab-pane fade active show" id="custom-tabs-one-home" role="tabpanel" aria-labelledby="custom-tabs-one-home-tab">
                                        <table class="table table-striped m-0">
                                            <thead class="bg-info">
                                                <tr>
                                                    <th>Office</th>
                                                    <th>PR Number</th>
                                                    <th>Item Description</th>
                                                    <th>Unit of Measure</th>
                                                    <th>Quantity</th>
                                                    <th>Cost</th>
                                                    <th>Total Cost</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="itm in pritems" :key="itm.id">
                                                    <td>{{ itm.purchaserequest.office.alias }}</td>
                                                    <td>{{ itm.purchaserequest.prno }}</td>
                                                    <td v-if="itm.ppmpitems">{{ itm.ppmpitems.description }}</td><td v-else>?</td>
                                                    <td v-if="itm.ppmpitems">{{ itm.ppmpitems.uom_name }}</td><td v-else>?</td>
                                                    <td v-if="edititemmode && itm.id == form_item.id"><input type="text" class="form-control" v-model="form_item.quantity" /></td><td v-else>{{ itm.quantity }}</td>
                                                    <td v-if="edititemmode && itm.id == form_item.id"><input type="text" class="form-control" v-model="form_item.cost" /></td><td v-else>{{ itm.cost }}</td>
                                                    <td>{{ itm.quantity * itm.cost | toMoney }}</td>
                                                    <td><button title="Delete" @click="deleteItem(itm.id)" :disabled="form.status == 'Completed' || bidders.length != 0" type="button" class="btn btn-default"><i class="fa fa-trash"></i></button></td>
                                                </tr>
                                                <tr>
                                                    <td colspan="6" class="text-right"><b>TOTAL: </b></td><td colspan="2"><b>{{ getTotalAmount() | toMoney }}</b></td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <div class="p-1 mt-2">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="d-flex justify-content-end">
                                                        <small v-if="bidders.length != 0"  class="text-danger mr-4 mt-3">*The project has bidders. You can no longer add or edit items to the project.</small>
                                                        <button :disabled="bidders.length != 0" class="btn btn-primary mr-1" data-container="body" type="button" @click="actionAddItem(form.id)"><i class="fa fa-plus"></i> Add Item(s)</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="custom-tabs-one-profile" role="tabpanel" aria-labelledby="custom-tabs-one-profile-tab">
                                        <table class="table table-striped m-0">
                                            <thead class="bg-info">
                                                <tr>
                                                    <th>Name of Supplier</th>
                                                    <th>Address / Contact Details</th>
                                                    <th>Total Bid</th>
                                                    <th>Bid/Rank</th>
                                                    <th>Invitee</th>
                                                    <th>Attendee</th>
                                                    <th>Passed</th>
                                                    <th>Remarks</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="bdr in bidders" :key="bdr.id">
                                                    <td>{{ bdr.procsupplier.name }}</td>
                                                    <td><small>{{ bdr.procsupplier.address }}</small><br/><small>{{ bdr.procsupplier.tel }}</small> <small>{{ bdr.procsupplier.fax }}</small> <small>{{ bdr.procsupplier.cel }}</small></td>
                                                    <td>
                                                        {{ getTotalPrice(bdr) | toMoney() }}

                                                    </td>
                                                    <td style="width:120px">
                                                        <span v-for="bid, idx in bdr.procprojectbidderbids" :key="bid.id">
                                                            <small>
                                                                {{ 'Item ' + idx + '→' }}
                                                                <template v-if="bid.rank">{{ bid.rank }}</template><template v-else>0</template>
                                                                <template v-if="bid.status">
                                                                    <small v-if="bid.status == 1" class="text-success">(Winning Bid)</small>
                                                                    <small v-else-if="bid.status == 2" class="text-danger">(Cancelled)</small>
                                                                    <small v-else-if="bid.status == 3" class="text-danger">(Above ABC)</small>
                                                                    <small v-else-if="bid.status == 4" class="text-danger">(Disqualified)</small>
                                                                </template>
                                                            </small>
                                                            <br>
                                                        </span>
                                                    </td>
                                                    <td v-if="bdr.state.toString()[0] === '1'">✔</td><td v-else></td>
                                                    <td v-if="bdr.state.toString()[1] === '1'">✔</td><td v-else></td>
                                                    <td v-if="bdr.state.toString()[3] === '1'">✔</td><td v-else></td>
                                                    <td>{{ bdr.remarks }}</td>

                                                    <td>
                                                        <div class="btn-group">
                                                            <button title="Bids" @click="bidBidder(bdr.id)" type="button" class="btn btn-default"><b>₱BID</b></button>
                                                            <button title="Edit" @click="actionEditBidder(bdr)" type="button" class="btn btn-default"><i class="fa fa-edit"></i></button>
                                                            <button title="Delete" @click="deleteBidder(bdr.id)" type="button" class="btn btn-default"><i class="fa fa-trash"></i></button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <div class="p-1 mt-2">
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="d-flex justify-content-end">
                                                    <button class="btn btn-primary mr-1" data-container="body" type="button" @click="abstractModal()"><i class="fa fa-print"></i> Abstract of Quotations</button>
                                                    <button class="btn btn-primary mr-1" data-container="body" type="button" @click="actionAddSupplier(form.id)"><i class="fa fa-plus"></i> Add Supplier(s)</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!--
                                    <div class="tab-pane fade" id="custom-tabs-one-messages" role="tabpanel" aria-labelledby="custom-tabs-one-messages-tab">
                                        Morbi turpis dolor, vulputate vitae felis non, tincidunt congue mauris. Phasellus volutpat augue id mi placerat mollis. Vivamus faucibus eu massa eget condimentum. Fusce nec hendrerit sem, ac tristique nulla. Integer vestibulum orci odio. Cras nec augue ipsum. Suspendisse ut velit condimentum, mattis urna a, malesuada nunc. Curabitur eleifend facilisis velit finibus tristique. Nam vulputate, eros non luctus efficitur, ipsum odio volutpat massa, sit amet sollicitudin est libero sed ipsum. Nulla lacinia, ex vitae gravida fermentum, lectus ipsum gravida arcu, id fermentum metus arcu vel metus. Curabitur eget sem eu risus tincidunt eleifend ac ornare magna.
                                    </div>
                                    -->
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

              </div>
              <div class="card-footer">
                <div class="row">
                  <div class="col-md-4">
                    <div class="d-flex justify-content-end">
                      <div class="input-group input-group-md d-flex justify-content-end">
                        <!--
                        <div class="input-group-prepend">
                          <button class="btn btn-success" :disabled="!this.signatory" data-container="body" type="button" @click="printModal()"><i class="fa fa-print"></i> Print Preview</button>
                        </div>
                        <selectize @input="assignSignatory" class="form-control" v-model="signatory" placeholder="- Select Approved by -" :settings="settings">
                          <option v-for="sg in signatories" :key="sg.id" :value="sg.id">{{ sg.user.lname }}, {{ sg.user.fname }} {{ sg.user.mname | midname }}</option>
                        </selectize>
                        -->
                      </div>
                    </div>
                  </div>
                  <div class="col-md-5">
                    <div class="d-flex justify-content-start">

                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="d-flex justify-content-end">
                      <button v-show="form.status === 2 || form.status === 3" class="btn btn-warning mr-2"  type="button" @click="changeStatus(form.id, 1, 'This will change the status of the PR to Revise. Do you really want to proceed?', 1)"><i class="fa fa-undo"></i> REVISE</button>
                      <button v-show="form.status === 2" class="btn btn-success mr-2"  type="button" @click="changeStatus(form.id, 3, 'This will change the status of the PR to Approve. Do you really want to proceed?', 0)"><i class="fa fa-check"></i> APPROVE</button>
                      <button class="btn btn-secondary" data-container="body" type="button" data-dismiss="modal" aria-label="Close"><i class="fa fa-times"></i> Close</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal fade" id="newactionmodal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="newactionModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
            <div class="modal-content">
              <div class="modal-header bg-warning">
                <h5 class="modal-title text-dark" id="newactionModalLabel">Add New Project</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div class="modal-body">
                <div class="row">

                  <div class="col-sm-3">
                    <div class="form-group">
                      <label>Title</label>
                      <input type="date" id="prdate-date-input" class="form-control" v-model="form.title">
                    </div>
                    <div class="form-group">
                      <label>Mode of Procurement</label>
                      <select v-model="form.procmode_id" name="type" class="form-control">
                        <option v-for="(pm,idx) in procmodes" :value="pm.id" :key="idx">{{ pm.alias }}</option>
                      </select>
                    </div>
                    <div class="form-group">
                      <label>PMO/Enduser</label>
                      <input type="text" class="form-control" v-model="form.enduser">
                    </div>
                  </div>
                  <div class="col-sm-3">
                    <div class="form-group">
                      <label>Remarks</label>
                      <textarea class="form-control" rows="4" data-gramm="false" wt-ignore-input="true" v-model="form.remarks"></textarea>
                    </div>
                  </div>

                </div>

              </div>
              <div class="card-footer">
                <div class="row">
                  <div class="col-md-4">
                    <div class="d-flex justify-content-end">

                    </div>
                  </div>
                  <div class="col-md-5">
                    <div class="d-flex justify-content-start">

                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="d-flex justify-content-end">
                      <button v-if="can_edit" class="btn btn-primary mr-1" data-container="body" type="button" @click="saveNewPrDetails()"><i class="fa fa-save"></i> Save</button>
                      <button class="btn btn-primary" data-container="body" type="button" data-dismiss="modal" aria-label="Close"><i class="fa fa-times"></i> Close</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="modal fade" id="printmodal" tabindex="-1" role="dialog" aria-labelledby="printModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
            <div class="modal-content">
              <div class="modal-header bg-success">
                <h5 class="modal-title text-dark" id="printModalLabel">Purchase Request (Print Preview)</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <div class="modal-body">
                <div id="printAppendix60">
                  <div class="card-body" style="font-family: Times New Roman;">

                    <table class="table table-borderless customtextsize">
                      <tr>
                        <td colspan="8" align="right">
                            <i style="font-size: 17px">Appendix 60</i>
                        </td>
                      </tr>
                    </table>
                    <br/>
                    <table class="table table-borderless customtextsize">
                      <tbody>
                        <tr>
                          <td colspan="2" align="center"><h2>PURCHASE REQUEST</h2></td>
                        </tr>
                        <tr>
                          <td>
                            <b>Entity Name : <u>Technology Application & Promotion Institute</u></b>
                          </td>
                          <td align="right">
                            <b>Fund Cluster : ___________________</b>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table class="table table-borderless customtextsize" style="border:2px solid #000000">
                      <thead>
                        <tr>
                          <td style="border:2px solid #000000" colspan="2" align="left"><b>Office/Section:</b> {{ this.office_name }}</td>
                          <td style="border:2px solid #000000" colspan="2" align="left"><b>PR No.:</b> <u>{{ form.prno }}</u><br/><b>Responsibility Center Code:</b> ________________________ </td>
                          <td style="border:2px solid #000000" colspan="2" align="left"><b>Date: {{ form.prdate }}</b></td>
                        </tr>
                        <tr>
                          <td style="border:2px solid #000000;width: 130px;" align="center"><b>Stock/Property No.</b></td>
                          <td style="border:2px solid #000000" align="center"><b>Unit</b></td>
                          <td style="border:2px solid #000000;width: 400px;" align="center"><b>Item Description</b></td>
                          <td style="border:2px solid #000000" align="center"><b>Quantity</b></td>
                          <td style="border:2px solid #000000" align="center"><b>Unit Cost</b></td>
                          <td style="border:2px solid #000000" align="center"><b>Total Cost</b></td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="itm in pritems" :key="itm.id">
                          <td style="border:2px solid #000000" align="center"></td>
                          <td style="border:2px solid #000000" align="center">{{ itm.ppmpitems.uom_name }}</td>
                          <td style="border:2px solid #000000" align="left">{{ itm.ppmpitems.description }}</td>
                          <td style="border:2px solid #000000" align="center">{{ itm.quantity }}</td>
                          <td style="border:2px solid #000000" align="right">{{ itm.cost }}</td>
                          <td style="border:2px solid #000000" align="right">{{ itm.quantity * itm.cost | toMoney }}</td>
                        </tr>
                        <tr class="lastchild">
                          <td colspan="6" style="border:2px solid #000000" align="center">*** NOTHING FOLLOWS ***</td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr class="footer">
                          <td style="border:2px solid #000000" align="center"></td>
                          <td style="border:2px solid #000000" align="center"></td>
                          <td style="border:2px solid #000000" align="center"></td>
                          <td style="border:2px solid #000000" align="center"></td>
                          <td style="border:2px solid #000000" align="right">TOTAL</td>
                          <td v-if="!this.pritems" style="border:2px solid #000000" align="right"></td><td v-else style="border:2px solid #000000" align="right">{{ getTotalAmount() | toMoney }}</td>
                        </tr>
                        <tr style="height: 100px !important">
                          <td colspan="6" style="border:2px solid #000000; vertical-align: top;" align="left">Purpose: {{ form.purpose }}</td>
                        </tr>
                        <tr>
                          <td style="border:2px solid #000000" align="left"></td>
                          <td colspan="2" style="border:2px solid #000000" align="left">Requested By:</td>
                          <td colspan="3" style="border:2px solid #000000" align="left">Approved By:</td>
                        </tr>
                        <tr>
                          <td style="border:2px solid #000000" align="left">Signature</td>
                          <td colspan="2" style="border:2px solid #000000" align="left"></td>
                          <td colspan="3" style="border:2px solid #000000" align="left"></td>
                        </tr>
                        <tr>
                          <td style="border:2px solid #000000" align="left">Designation</td>
                          <td colspan="2" style="border:2px solid #000000" align="center"><b>{{ this.$gate.showFname() }} {{ this.$gate.showMname() | midname }} {{ this.$gate.showLname() }}</b></td>
                          <td v-if="this.signatory_details" colspan="3" style="border:2px solid #000000" align="center"><b>{{ this.signatory_details.user.fname }} {{ this.signatory_details.user.mname | midname }} {{ this.signatory_details.user.lname }}</b></td>
                          <td v-else colspan="3" style="border:2px solid #000000" align="center"><b></b></td>
                        </tr>
                        <tr>
                          <td style="border:2px solid #000000" align="left">Printed Name</td>
                          <td colspan="2" style="border:2px solid #000000" align="center"><small>{{ this.$gate.showPosition() }}, {{ this.office_name }}</small></td>
                          <td v-if="this.signatory_details" colspan="3" style="border:2px solid #000000" align="center"><small>{{ this.signatory_details.user.position }}, {{ this.signatory_details.office.alias }}</small></td>
                          <td v-else colspan="3" style="border:2px solid #000000" align="center"></td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>

              </div>
              <div class="card-footer">
                <button v-print="'#printAppendix60'" class="btn btn-primary" data-container="body" type="button"><i class="fa fa-print"></i> Print</button>
                <button class="btn btn-primary" data-container="body" type="button" data-dismiss="modal" aria-label="Close"><i class="fa fa-times"></i> Close</button>
              </div>
            </div>
          </div>
        </div>

        <div class="modal fade" id="actionadditemmodal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="actionadditemModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="actionadditemModalLabel">Select Item From Approved PRs</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="row m-0 bg-secondary disabled color-palette sticky-top">
                  <div class="col-md-4">

                  </div>
                  <div class="col-md-4 mt-2 mb-2">

                  </div>
                  <div class="col-md-4">
                    <div class="input-group input-group-md mt-2 mb-2 d-flex justify-content-end">
                        <!--
                      <div class="input-group input-group-sm" style="width: 150px;">
                      <input type="text" name="table_search" class="form-control float-right" v-model="searchkey" placeholder="Search" />
                      <div class="input-group-append">
                      <button v-if="!this.searchkey" type="submit" class="btn btn-default"><i class="fas fa-search"></i></button>
                      <button v-else type="submit" @click="clearSearchkey()" class="btn btn-default"><i class="fas fa-undo"></i></button>
                      </div>
                      </div>
                      -->
                    </div>
                  </div>
                </div>
                <div class="row m-0 table-responsive p-0" style="height: 300px;">
                  <table class="table table-head-fixed text-nowrap">
                <thead>
                  <tr>
                    <th><i class="fa fa-check" aria-hidden="true"></i></th>
                    <th>DESCRIPTION</th>
                    <th>PR NO.</th>
                    <th>PROC. PROJ.</th>
                    <th>UOM</th>
                    <th>QTY</th>
                    <th>PRICE</th>
                    <th>AMOUNT</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="itms in approvedprprocurementitems" :key="itms.id">
                    <td><span v-if="itms.procproject_id == 0"><input v-bind:id="itms.id" type="checkbox" :value="itms" v-model="selected_items_form.selected_items" /></span><span v-else class="text-danger"></span></td>
                    <td><span v-if="itms.procproject_id == 0">{{ itms.ppmpitems.description | stringlimit(60) }}</span><span v-else class="text-danger">{{ itms.ppmpitems.description | stringlimit(60) }}</span></td>
                    <td><span v-if="itms.procproject_id == 0">{{ itms.purchaserequest.prno }}</span><span v-else class="text-danger">{{ itms.purchaserequest.prno }}</span></td>
                    <td><span v-if="itms.procproject_id == 0"></span><span v-else class="text-danger">{{ itms.procproject.refno }}</span></td>
                    <td><span v-if="itms.procproject_id == 0">{{ itms.ppmpitems.uom_name }}</span><span v-else class="text-danger">{{ itms.ppmpitems.uom_name }}</span></td>
                    <td><span v-if="itms.procproject_id == 0">{{ itms.quantity }}</span><span v-else class="text-danger">{{ itms.quantity }}</span></td>
                    <td><span v-if="itms.procproject_id == 0">{{ itms.cost }}</span><span v-else class="text-danger">{{ itms.cost }}</span></td>
                    <td><span v-if="itms.procproject_id == 0">{{ itms.quantity * itms.cost | toMoney }}</span><span v-else class="text-danger">{{ itms.quantity * itms.cost | toMoney }}</span></td>
                  </tr>
                </tbody>
                </table>
                </div>
              </div>
              <div class="card-footer">
                <button class="btn btn-primary" v-if="selected_items_form.selected_items.length !== 0" data-container="body" type="button" @click="addToFormSelectedItems(form.id)"><i class="fa fa-save"></i> Add Selected Items</button>
                <button class="btn btn-primary" v-else data-container="body" type="button" disabled><i class="fa fa-save"></i> Add Selected Items</button>
                <button class="btn btn-primary" data-container="body" type="button" data-dismiss="modal" aria-label="Close"><i class="fa fa-times"></i> Close</button>
              </div>
            </div>
          </div>
        </div>

        <div class="modal fade" id="actionaddsuppliermodal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="actionaddsupplierModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="actionaddsupplierModalLabel">Select Supplier as Bidder</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="row m-0 bg-secondary disabled color-palette sticky-top">
                  <div class="col-md-4">

                  </div>
                  <div class="col-md-4 mt-2 mb-2">

                  </div>
                  <div class="col-md-4">
                    <div class="input-group input-group-md mt-2 mb-2 d-flex justify-content-end">

                    </div>
                  </div>
                </div>
                <div class="row m-0 table-responsive p-0" style="height: 300px;">
                  <table class="table table-head-fixed text-nowrap">
                <thead>
                  <tr>
                    <th><i class="fa fa-check" aria-hidden="true"></i></th>
                    <th>SUPPLIER NAME</th>
                    <th>ADDRESS / CONTACT</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="splr in suppliers" :key="splr.id">
                    <td><input v-bind:id="splr.id" type="checkbox" :value="splr" v-model="selected_suppliers_form.selected_suppliers" /></td>
                    <td>{{ splr.name}}</td>
                    <td>{{ splr.address  | stringlimit(60) }}<br><small>{{ splr.tel }}</small> <small>{{ splr.fax }}</small> <small>{{ splr.cel }}</small></td>
                  </tr>
                </tbody>
                </table>
                </div>
              </div>
              <div class="card-footer">
                <button class="btn btn-primary" v-if="selected_suppliers_form.selected_suppliers.length !== 0" data-container="body" type="button" @click="addToFormSelectedSuppliers(form.id)"><i class="fa fa-save"></i> Add Selected Bidders</button>
                <button class="btn btn-primary" v-else data-container="body" type="button" disabled><i class="fa fa-save"></i> Add Selected Bidders</button>
                <button class="btn btn-primary" data-container="body" type="button" data-dismiss="modal" aria-label="Close"><i class="fa fa-times"></i> Close</button>
              </div>
            </div>
          </div>
        </div>

        <div class="modal fade" id="bidderinfomodal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="bidderinfoModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-md" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="bidderinfoModalLabel">Bidder Additional Information</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="row m-0">
                  <div class="col-md-12">
                    <div class="form-group">
                        <div class="form-check form-check-inline">
                            <input
                                class="form-check-input"
                                type="checkbox"
                                id="checkboxInvitee"
                                v-model="checkboxState.invitee"
                                @change="updateState()"
                            >
                            <label class="form-check-label" for="checkboxInvitee">Invitee</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input
                                class="form-check-input"
                                type="checkbox"
                                id="checkboxAttendee"
                                v-model="checkboxState.attendee"
                                @change="updateState()"
                            >
                            <label class="form-check-label" for="checkboxAttendee">Attendee</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input
                                class="form-check-input"
                                type="checkbox"
                                id="checkboxBuyBidDoc"
                                v-model="checkboxState.buyBidDoc"
                                @change="updateState()"
                            >
                            <label class="form-check-label" for="checkboxBuyBidDoc">Buy Bid Doc</label>
                        </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="radioPassed" v-model="radioState" value="1">
                            <label class="form-check-label" for="radioPassed">PASSED</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="inlineRadioOptions" id="radioFailed" v-model="radioState" value="2">
                            <label class="form-check-label" for="radioFailed">FAILED</label>
                        </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group">
                      <label>Remarks</label>
                      <textarea class="form-control" rows="4" data-gramm="false" wt-ignore-input="true" v-model="bidderinfo_form.remarks"></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <button class="btn btn-primary" data-container="body" type="button" @click.prevent="actionUpdateBidderInfo()"><i class="fa fa-save"></i> Save</button>
                <button class="btn btn-primary" data-container="body" type="button" data-dismiss="modal" aria-label="Close"><i class="fa fa-times"></i> Close</button>
              </div>
            </div>
          </div>
        </div>

        <div class="modal fade" id="bidinfomodal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="bidinfoModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="bidinfoModalLabel">Bid Lots/Items</h5>
                <button type="button" @click="closeBidModal" class="close" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body p-0">
                <div class="row m-0">
                  <div class="col-md-12 p-0">
                    <table class="table table-striped m-0">
                      <thead class="bg-info">
                          <tr>
                              <th>No.</th>
                              <th>ABC</th>
                              <th>Bid Price</th>
                              <th>Validity</th>
                              <th>Rank</th>
                              <th>Status</th>
                              <th>Remarks</th>
                              <th></th>
                          </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, index) in itemsWithBids" :key="item.id">
                            <td>{{ index + 1 }}</td>
                            <td>{{ item.quantity * item.cost | toMoney }}</td>
                            <td>
                                <span v-if="editbidmode && item.id == bidinfo_form.id"><input type="number" class="form-control" v-model="bidinfo_form.bidPrice" /></span>
                                <span v-else>{{ item.bidPrice }}</span>
                            </td>
                            <td>
                                <span v-if="editbidmode && item.id == bidinfo_form.id"><input type="text" class="form-control" v-model="bidinfo_form.validity" /></span>
                                <span v-else>{{ item.validity }}</span>
                            </td>
                            <td>
                                <span v-if="editbidmode && item.id == bidinfo_form.id"><input type="number" class="form-control" v-model="bidinfo_form.rank" /></span>
                                <span v-else>{{ item.rank }}</span>
                            </td>
                            <td>
                                <span v-if="editbidmode && item.id == bidinfo_form.id">
                                  <select v-model="bidinfo_form.status" name="type" class="form-control">
                                    <option value="0"></option>
                                    <option value="1">Winning Bid</option>
                                    <option value="2">Cancelled</option>
                                    <option value="3">Above ABC</option>
                                    <option value="4">Disqualified</option>
                                  </select>
                                </span>
                                <span v-else>
                                  <span v-if="item.status == 0"></span>
                                  <span v-else-if="item.status == 1">Winning Bid</span>
                                  <span v-else-if="item.status == 2">Cancelled</span>
                                  <span v-else-if="item.status == 3">Above ABC</span>
                                  <span v-else-if="item.status == 4">Disqualified</span>
                                </span>
                            </td>
                            <td>
                                <span v-if="editbidmode && item.id == bidinfo_form.id"><input type="text" class="form-control" v-model="bidinfo_form.remarks" /></span>
                                <span v-else>{{ item.remarks }}</span>
                            </td>
                            <td>
                                <button v-if="!editbidmode" title="Edit" @click="enableBidEdit(item)" type="button" class="btn btn-default"><i class="fa fa-edit"></i></button>
                                <button v-if="editbidmode && item.id == bidinfo_form.id" title="Save" @click="saveBid()" type="button" class="btn btn-default"><i class="fa fa-save"></i></button>
                            </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <!--<button class="btn btn-primary" data-container="body" type="button" @click.prevent="actionBiddingSave()"><i class="fa fa-save"></i> Save</button>-->
                <button class="btn btn-primary" type="button" @click="closeBidModal"><i class="fa fa-times"></i> Close</button>
              </div>
            </div>
          </div>
        </div>

        <div class="modal fade" id="abstractmodal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="abstractModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="abstractModalLabel">Abstract of Quotations (AoQ)</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body p-0">
                <div class="row-aligncenter">
                  <div class="col-md-12 p-0">
                    <div class="row">
                        <div class="col-md-12" id="printAbstract">
                            <div class="card-body" style="font-family: Times New Roman;">

                                <table class="borderless">
                                <tr>
                                    <td colspan="8" style="text-align:right;">
                                        <span style="border:1px solid #000;padding: 2px;">
                                            <i style="font-size: 10px;">FAD-PRO-F01-00-01/18/2021</i>
                                        </span>
                                        <br/><br/>
                                    </td>
                                </tr>
                                </table>
                                <br/><br/>
                                <table class="borderless">
                                    <tr>
                                    <td colspan="2" style="text-align:center;"><b>Technology Apllication and Promotion Institute</b></td>
                                    </tr>
                                    <tr>
                                    <td colspan="2" style="text-align:center;"><b>DOST Compount, Bicutan, Taguig City</b><br></td>
                                    </tr>
                                    <tr>
                                    <td>
                                    </td>
                                    <td style="text-align:right;">
                                        AoQ No.: <u>{{ form.refno }}</u> <br/>
                                        Date: <u>{{ getCurrentDate() }}</u> <br/>
                                        PR No.: <u>{{ uniquePRNumbers }}</u>
                                    </td>
                                    </tr>
                                    <tr>
                                    <td colspan="2" style="text-align:center;"><b>ABSTRACT OF QUOTATION (AoQ)</b><br></td>
                                    </tr>
                                </table>
                                <table class="table-styled">
                                    <thead>
                                        <tr>
                                        <th style="text-align: center">QTY</th>
                                        <th style="text-align: center">UNIT</th>
                                        <th style="text-align: center">PARTICULARS</th>
                                        <!-- Create headers for each bidder with sub-columns -->
                                        <th v-for="bidder in biddersList" :key="bidder.id" style="text-align: center">
                                            {{ bidder.name }}
                                            <table class="borderless" style="width:100%;">
                                            <thead>
                                                <tr>
                                                <th style="width:50%;border-right:1px solid #000;border-top:1px solid #000;text-align: center">Unit Cost</th>
                                                <th style="width:50%;border-top:1px solid #000;text-align: center">Total Cost</th>
                                                </tr>
                                            </thead>
                                            </table>
                                        </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="item in pritems" :key="item.id">
                                        <td style="text-align: center">{{ item.quantity }}</td>
                                        <td style="text-align: center">{{ item.ppmpitems.uom_name }}</td>
                                        <td style="text-align: left">{{ item.ppmpitems.description }}</td>
                                        <!-- Display Unit Cost and Total Cost for each bidder -->
                                        <td v-for="bidder in biddersList" :key="bidder.id" class="border-middle">
                                            <!-- Check if there's a bid for this item and bidder -->
                                            <template v-if="getBid(item.id, bidder.id)">

                                              <template v-if="getBid(item.id, bidder.id) === 'Disqualified'">
                                                <table class="borderless nested-table" style="width:100%;height:100%;">
                                                  <tr>
                                                    <td colspan="2" style="text-align: center;font-size: 10px !important;background-color: #FFF;color:red;">
                                                      DISQUALIFIED
                                                    </td>
                                                  </tr>
                                                </table>
                                              </template>

                                              <template v-else-if="getBid(item.id, bidder.id) === 'Above ABC'">
                                                <table class="borderless nested-table" style="width:100%;height:100%;">
                                                  <tr>
                                                    <td colspan="2" style="text-align: center;font-size: 10px !important;background-color: #FFF;color:red;">
                                                      ABOVE ABC
                                                    </td>
                                                  </tr>
                                                </table>
                                              </template>

                                              <template v-else-if="getBid(item.id, bidder.id) === 'Cancelled'">
                                                <table class="borderless nested-table" style="width:100%;height:100%;">
                                                  <tr>
                                                    <td colspan="2" style="text-align: center;font-size: 10px !important;background-color: #FFF;color:red;">
                                                      CANCELLED
                                                    </td>
                                                  </tr>
                                                </table>
                                              </template>

                                              <template v-else>
                                                <div class="border-line"></div>
                                                <table class="borderless nested-table" style="width:100%;height:100%;">
                                                  <tr>
                                                    <td :class="{ highlight: isLowestBidder(item.id, bidder.id) }" style="width:50%;text-align:right;padding-right:4px">
                                                      {{ calculateUnitCost(item.id, bidder.id) }}
                                                    </td>
                                                    <td :class="{ highlight: isLowestBidder(item.id, bidder.id) }" style="width:50%;text-align:right;padding-right:4px">
                                                        <small v-if="getBid(item.id, bidder.id).status == 1" class="text-success">(WINNER)</small> {{ getBid(item.id, bidder.id).price }}
                                                    </td>
                                                  </tr>
                                                </table>
                                              </template>
                                            </template>

                                            <!-- If no bid, merge the cells and display NO BID -->
                                            <template v-else>
                                              <table class="borderless nested-table" style="width:100%;height:100%;">
                                                  <tr>
                                                      <td colspan="2" style="text-align:center;font-size:10px !important;">NO BID</td>
                                                  </tr>
                                              </table>
                                            </template>
                                        </td>
                                        </tr>
                                    </tbody>
                                </table>

                                <br/><br/>
                                <table class="borderless" style="width:50%">
                                    <tr>
                                    <td colspan="1" style="text-align:left;"><b>Prepared By:</b></td>
                                    <td colspan="1" style="text-align:left;"><b>Noted By:</b></td>
                                    </tr>
                                    <tr>
                                        <td colspan="1" style="text-align:left;padding-top:20px;"><b><u>{{ this.$gate.showFullName() }}</u></b></td>
                                        <td colspan="1" style="text-align:left;padding-top:20px;">
                                            <b style="border-bottom: 1px solid #000000;">
                                                <u>
                                                <select class="custom-select" v-model="endusersignatory" placeholder="- Select Approved by -">
                                                    <option value='0'>SELECT END USER ▼</option>
                                                    <option v-for="sg in endusersignatories" :key="sg.id" :value="sg.id">{{ sg.user.lname }}, {{ sg.user.fname }} {{ sg.user.mname | midname }}</option>
                                                </select>
                                                </u>
                                            </b>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="1" style="text-align:left;">Authorized Representative</td>
                                        <td colspan="1" style="text-align:left;">End User</td>
                                    </tr>
                                    <tr>
                                        <td colspan="1" style="text-align:left;">Date:</td>
                                        <td colspan="1" style="text-align:left;">Date:</td>
                                    </tr>
                                    <tr>
                                        <td colspan="1" style="text-align:left;padding-top:20px;"><b>Recommended Reviewed by:</b></td>
                                    </tr>
                                    <tr>
                                        <td colspan="1" style="text-align:left;padding-top:20px;">
                                            <b style="border-bottom: 1px solid #000000;">
                                                <u>
                                                <select class="custom-select" v-model="procsignatory" placeholder="- Select Approved by -">
                                                    <option value='0'>SELECT REVIEWER ▼ </option>
                                                    <option v-for="sg in procsignatories" :key="sg.id" :value="sg.id">{{ sg.user.lname }}, {{ sg.user.fname }} {{ sg.user.mname | midname }}</option>
                                                </select>
                                                </u>
                                            </b>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="1" style="text-align:left;">Head, Procurement Section</td>
                                    </tr>
                                    <tr>
                                        <td colspan="1" style="text-align:left;">Date:</td>
                                    </tr>
                                    <tr>
                                        <td colspan="1" style="text-align:left;padding-top:20px;">Approved by:</td>
                                    </tr>
                                    <tr>
                                        <td colspan="1" style="text-align:left;padding-top:20px;">
                                            <b style="border-bottom: 1px solid #000000;">
                                                <u>
                                                <select class="custom-select" v-model="signatory" placeholder="- Select Approved by -">
                                                    <option value='0'>SELECT APPROVER ▼</option>
                                                    <option v-for="sg in signatories" :key="sg.id" :value="sg.id">{{ sg.user.lname }}, {{ sg.user.fname }} {{ sg.user.mname | midname }}</option>
                                                </select>
                                                </u>
                                            </b>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="1" style="text-align:left;">OIC / Office of the Director</td>
                                    </tr>
                                    <tr>
                                        <td colspan="1" style="text-align:left;">Date:</td>
                                    </tr>
                                </table>

                          </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-footer">
                <!--<button class="btn btn-primary" data-container="body" type="button" @click.prevent="actionBiddingSave()"><i class="fa fa-save"></i> Save</button>-->
                <button v-print="'#printAbstract'" @click="print" type="submit" class="btn btn-default"><i class="fa fa-print"></i> PRINT</button>
                <button class="btn btn-primary" data-container="body" type="button" data-dismiss="modal" aria-label="Close"><i class="fa fa-times"></i> Close</button>
              </div>
            </div>
          </div>
        </div>




      </div>

  </template>

  <script>

    import Form from 'vform';
    import { VueEditor } from "vue2-editor";
    import moment from 'moment';

    export default {
        name: 'ProcProjects',

        components: {
            VueEditor,
        },

        data() {
          return {
            timer: 30,
            searchkey: '',
            edititemmode: false,
            editbidmode: false,
            addbidmode: false,
            editmode: false,
            loading: false,
            emptyflag: false,
            can_access: false,
            can_add: false,
            can_edit: false,
            can_delete: false,
            procprojects: {},
            procmodes: {},
            offices: {},
            pritems: [],
            bids: [],
            pritemx: [],
            procurementitems: {},
            bidder: {},
            bidders: {},
            suppliers: {},
            approvedprprocurementitems: {},
            fundsources: {},
            settings: {},



            year: new Date().getFullYear(),
            officeid: 0,
            userlevelid: 0,
            total_expenses: 0,
            role: {},
            office_name: '',
            signatories: {},
            procsignatories: {},
            endusersignatories: {},
            procsignatory: 0,
            signatory: 0,
            endusersignatory: 0,
            signatory_details: '',
            totalItemsAmount: 0.00,

            form_item: new Form({
              id: 0,
              quantity: 0,
              cost: 0.00,
            }),

            form: new Form({
              id: 0,
              refno: '',
              procmode_id: 0,
              title: '',
              enduser: '',
              status: 0,
              remarks: '',
            }),

            selected_items_form: new Form({
              ppmp_id: 0,
              selected_items: [],
            }),
            selected_suppliers_form: new Form({
              procproject_id: 0,
              selected_suppliers: [],
            }),
            ppmp_form: new Form({
              year: 0,
              office_id: 0,
            }),
            prstatus_form: new Form({
              pr_no: '',
            }),
            bidderinfo_form: new Form({
              id: 0,
              state: '',
              remarks: '',
            }),

            bidinfo_form: new Form({
              id: 0,
              procprojectbidder_id: 0,
              bidPrice: 0,
              procprojectitem_id: 0,
              validity: '',
              rank: 0,
              status: '',
              remarks: '',
            })
          }
        },

        props: [
          'todo'
        ],

        computed: {

            biddersList() {
                // Convert the bidders object to an array of {id, name}
                return Object.keys(this.bidders).map(key => ({
                    id: this.bidders[key].id,
                    name: this.bidders[key].procsupplier.name,
                }));
            },

            uniquePRNumbers() {
                const uniquePRNumbers = new Set();
                Object.values(this.pritems).forEach(item => {
                if (item.purchaserequest && item.purchaserequest.prno) {
                    uniquePRNumbers.add(item.purchaserequest.prno);
                }
                });
                return Array.from(uniquePRNumbers).join(', ');
            },

            itemsWithBids() {
                //console.log(this.pritems)

                return this.pritems && this.pritems.map(item => {
                    const bid = this.bidder.procprojectbidderbids && this.bidder.procprojectbidderbids.find(b => b.procprojectitem_id === item.id) || {};
                    return {
                    ...item,
                    procprojectbidder_id: bid.procprojectbidder_id || '0',
                    bidPrice: bid.price || '',
                    validity: bid.validity || '',
                    rank: bid.rank || '',
                    status: bid.status || '',
                    remarks: bid.remarks || ''
                    };
                });
            },

          radioState: {
            get() {
                // Get the fourth character of the state
                return this.bidderinfo_form.state[3];
            },
            set(value) {
                // Set the fourth character of the state
                let stateArray = this.bidderinfo_form.state.split('');
                stateArray[3] = value;
                this.bidderinfo_form.state = stateArray.join('');
            }
          },

          checkboxState: {
            get() {
                return {
                    invitee: this.bidderinfo_form.state[0] === '1',
                    attendee: this.bidderinfo_form.state[1] === '1',
                    buyBidDoc: this.bidderinfo_form.state[2] === '1'
                };
            },
            set(values) {
                let stateArray = this.bidderinfo_form.state.split('');
                stateArray[0] = values.invitee ? '1' : '0';
                stateArray[1] = values.attendee ? '1' : '0';
                stateArray[2] = values.buyBidDoc ? '1' : '0';
                this.bidderinfo_form.state = stateArray.join('');
            }
          },

          second() {
            return this.time / 1000;
          },

          filterByYear() {
            let tempItems = this.prs;
            // Process search input
            if (this.year != '' && this.year) {
              tempItems = tempItems.filter((item) => {
                return item.prdate.includes(this.year)
              })
            }

            return tempItems;
          },

          filteredProcurementitems() {
            let tempItems = this.procurementitems;
            // Process search input
            if (this.searchkey != '' && this.searchkey) {
              tempItems = tempItems.filter((item) => {
                return item.description.toUpperCase().includes(this.searchkey.toUpperCase())
              })
            }

            return tempItems;
          },

          isIdle() {
            const idlestat =  this.$store.state.idleVue.isIdle;
            if (idlestat == true) {
              $('#sessionmodal').modal('show');
              this.countDownTimer();
            } else {
              $('#sessionmodal').modal('hide');
            }
            return idlestat;
          },

          years() {
            const year = new Date().getFullYear()
            return Array.from({length: year - 2018}, (value, index) => 2019 + index)
          },

        },

        methods: {

            print(){
              var style = document.createElement('style');
              style.innerHTML = "@page{size: A4 landscape !important;margin: 25mm !important;}" +
                      " html{background-color: #FFFFFF;}" +
                      " body{border: solid 0px #FFFFFF;margin: 0;}";
              window.document.head.appendChild(style);
            },

            getCurrentDate() {
                const now = new Date();
                const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are 0-based
                const day = String(now.getDate()).padStart(2, '0');
                const year = now.getFullYear();

                return `${month}-${day}-${year}`;
            },

            getBid(itemId, bidderId) {
                // Find the bid for a specific item and bidder
                const item = this.pritems.find(item => item.id === itemId);
                if (item && item.procprojectbidderbids.length) {
                    //console.log(item.procprojectbidderbids.find(bid => bid.procprojectbidder_id === bidderId).status)
                    const bid = item.procprojectbidderbids.find(bid => bid.procprojectbidder_id === bidderId);
                    //const status = item.procprojectbidderbids.find(bid => bid.procprojectbidder_id === bidderId);
                    if (bid) {
                      if (bid.status === 4) {
                        return 'Disqualified';
                      } else if (bid.status === 3) {
                        return 'Above ABC';
                      } else if (bid.status === 2) {
                        return 'Cancelled';
                      }
                      return bid;
                    }
                }
                return null;
            },

            calculateUnitCost(itemId, bidderId) {
                const bid = this.getBid(itemId, bidderId);
                if (bid) {
                const totalCost = parseFloat(bid.price);
                // Find the item and get its quantity, default to 1 if item not found
                let quantity = 1;
                const item = this.pritems.find(item => item.id === itemId);
                if (item) {
                    quantity = item.quantity || 1;
                }
                return (totalCost / quantity).toFixed(2);
                }
                return 'N/A';
            },

            isLowestBidder(itemId, bidderId) {
                // Find the item for the specific item ID
                const item = this.pritems.find(item => item.id === itemId);
                if (item && item.procprojectbidderbids) {
                // Get all bids for the item
                const bids = item.procprojectbidderbids.map(bid => parseFloat(bid.price)).filter(price => !isNaN(price));

                // Find the lowest bid price
                const lowestBid = Math.min(...bids);

                // Get the current bidder's bid for this item
                const currentBid = this.getBid(itemId, bidderId);
                return currentBid ? parseFloat(currentBid.price) === lowestBid : false;
                }
                return false;
            },

            getTotalPrice(bdr) {
                // Calculate the total price by summing up the prices for a specific bidder
                return bdr.procprojectbidderbids.reduce((total, bid) => total + (parseFloat(bid.price) || 0), 0);
            },

            updateState() {
                let stateArray = this.bidderinfo_form.state.split('');
                stateArray[0] = this.checkboxState.invitee ? '1' : '0';
                stateArray[1] = this.checkboxState.attendee ? '1' : '0';
                stateArray[2] = this.checkboxState.buyBidDoc ? '1' : '0';
                this.bidderinfo_form.state = stateArray.join('');
            },

          totalItem(item) {
            let sum = 0;
            for(let i = 0; i < item.length; i++){
              sum += (parseFloat(item[i].cost) * parseFloat(item[i].quantity));
            }

            return sum;
          },

          countDownTimer() {
            let timerId = setInterval(() => {
              this.timer -= 1;
              if (!this.$store.state.idleVue.isIdle) clearInterval(timerId);

              if (this.timer < 1) {
                clearInterval(timerId);
                document.getElementById('logout-form').submit();
              }
            }, 1000);

            this.timer = 30;
          },

          getTotalAmount() {

            return this.pritemx.reduce((accum, item) => {
                  return accum + (parseFloat(item.cost) * parseFloat(item.quantity))
              }, 0.00)
          },

          clearSearchkey() {
            this.searchkey = '';
          },

          computeExpenses() {
              return this.ppmpdetails.ppmpitems.reduce((accum, item) => {
                  return accum + parseFloat(item.tot_amount)
              }, 0.00)
            },

          newPpmpCy() {
            this.editmode = true;
            this.form.reset();
            this.form.clear();
            this.form.fill(payload);
            $('#actionmodal').modal('show');
          },

          updateItem() {
            this.$Progress.start();
            this.form.put('api/ppmpitems/'+this.form.id).then(()=>{
              Trigger.$emit('RefreshItems');
              $('#actionmodal').modal('hide')
              toastr.fire({
                icon: 'success',
                title: 'Item Saved'
              })
              this.$Progress.finish();
            }).catch(()=>{
              toastr.fire({
                icon: 'error',
                title: 'Item saving failed'
              });
              this.$Progress.fail();
            });
          },

          loadPpmpitemsYears() {

            if(!this.officeid) { //check if user is in role/signatory
              this.$router.push({ name: 'not-found' })
            } else {
              if(this.role.userlevelid !== 7) { //check if user is in PMO/End-User level
                this.$router.push({ name: 'not-found' })
              } else {
                axios.get('api/ppmps/years/' + this.officeid).then(({ data }) => (
                  this.ppmpyears = data,
                  this.year = data[0].year,
                  this.loadPpmpitems(this.year)
                ));
              }
            }

          },

          loadFundSources() {
            axios.get('api/fundsources').then(({ data }) => (
              this.fundsources = data
            ));
          },

          changeStatus(prid, status, txt) {
            swal.fire({
            title: 'Warning',
            text: txt,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
            }).then((result)=>{
              if (result.value) {
                axios.put('api/purchase-requests/status/'+ status + '/' + prid).then(()=>{
                  swal.fire(
                    'Confirmation',
                    'PR status has been updated.',
                    'success'
                  );
                  Trigger.$emit('RefreshData');
                  $('#actionmodal').modal('hide');
                }).catch(()=>{
                  swal.fire(
                    'Failed!',
                    'There was something wrong',
                    'warning'
                  )
                });
              }
            })
          },

          zeroPad(num, places) {
            var zero = places - num.toString().length + 1;
            return Array(+(zero > 0 && zero)).join("0") + num;
          },

          async approveStatus(prno, prid) {
            var inpulValue;
            var code;
            const current = new Date();
            const current_year =  moment(String(current)).format('YY');
            const current_month = moment(String(current)).format('MM');

            await axios.get('api/purchase-requests/generate/prno').then(response => {
              if (!response.data) {
                inpulValue = current_month + '-PR' + current_year + '-001';
              } else {
                code = response.data.prno.slice(response.data.prno.length - 3);
                code = parseInt(code) + 1;
                inpulValue = current_month + '-PR' + current_year + '-' + this.zeroPad(code, 3);
              }
            });

            const inputStep = 1;

            swal.fire({
              //title: 'Approve finalizes PR and will disabled editing of data. Do you really want to approve PR# ' + prno + '?',
              html: `<div class="alert alert-warning alert-dismissible" style="text-align: left !important;">
                <h5><i class="icon fas fa-exclamation-triangle"></i> Warning!</h5>
                Approve finalizes PR and will disabled editing of data. Do you really want to approve PR# ` + prno + `?
                </div>
                <br/>
                <h2>Assign PR Number</h2>:
                <input
                  type="text"
                  value="${inpulValue}"
                  step="${inputStep}"
                  class="swal2-input"
                  id="sweettxt">`,
              inputAttributes: {
                autocapitalize: 'off',
                step: inputStep,
              },
              showCancelButton: true,
              confirmButtonText: 'Save',
              showLoaderOnConfirm: true,

              preConfirm: () => {
                if(document.getElementById("sweettxt").value != '')
                {
                    axios.put('api/purchase-requests/activate/' + document.getElementById("sweettxt").value + '/' + prid).then(()=>{
                    swal.fire(
                      'Confirmation',
                      'PR status has been updated.',
                      'success'
                    );
                    Trigger.$emit('RefreshData');
                  }).catch(()=>{
                    swal.fire(
                      'Failed!',
                      'There was something wrong',
                      'warning'
                    )
                  });
                }else{
                    swal.showValidationMessage(
                      `Request failed: PR Number is required.`
                    )
                }
              },
              allowOutsideClick: () => !swal.isLoading()
            }).then((result) => {
              if (result.isConfirmed) {
                swal.fire({
                  title: `${result.value.txt}'s avatar`,
                  imageUrl: result.value.avatar_url
                })
              }
            })
          },


          loadPpmpitems(yr) {
            this.$Progress.start();

            axios.get('api/ppmps/' + this.officeid + '/' + yr).then(({ data }) => (
              this.ppmpitems = data.data[0].ppmpitems,
              this.ppmpdetails = data.data[0],
              this.selected_items_form.ppmp_id = data.data[0].id,
              this.total_expenses = this.computeExpenses(data.data[0]),
              this.office_name = data.data[0].office.name
            ));

            this.$Progress.finish();
          },

          loadProcItems() {
            axios.get('api/procurementitems/all').then(({ data }) => (this.procurementitems = data));
          },

          actionExcel() {
            //
          },

          actionPrint() {
            //
          },

          actionDelete() {
            swal.fire({
            title: 'Are you sure?',
            text: "Deleting will permanently remove the item and all its respective data. Do you really want to delete PPMP CY " + this.year + "?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete PPMP!'
            }).then((result)=>{
              if (result.value) {
                this.ppmp_form.delete('api/ppmps/delete/'+ this.year).then(()=>{
                  swal.fire(
                    'Deleted!',
                    'PPMP has been deleted.',
                    'success'
                  );
                  Trigger.$emit('RefreshData');
                }).catch(()=>{
                  swal.fire(
                    'Failed!',
                    'There was something wrong',
                    'warning'
                  )
                });
              }
            })
          },

          actionAdd() {
            const inputStep = 1;
            const inpulValue = parseInt(this.ppmpdetails.year) + 1;
            swal.fire({
              title: 'Create PPMP for Calendar Year:',
              html: `<input
                  type="number"
                  value="${inpulValue}"
                  step="${inputStep}"
                  class="swal2-input"
                  id="sweettxt">`,
              inputAttributes: {
                autocapitalize: 'off',
                min: 2000,
                max: 3000,
                step: inputStep,
              },
              showCancelButton: true,
              confirmButtonText: 'Save',
              showLoaderOnConfirm: true,
              preConfirm: () => {

                this.ppmp_form.year = document.getElementById("sweettxt").value;

                if(!this.ppmpyears.some(data => data.year == document.getElementById("sweettxt").value)){
                    //save
                    this.$Progress.start();
                    this.ppmp_form.post('api/ppmps/' + document.getElementById("sweettxt").value)
                    .then(()=>{
                          this.ppmpyears.push({"year":document.getElementById("sweettxt").value})
                          this.year = document.getElementById("sweettxt").value;
                          this.loadPpmpitems(document.getElementById("sweettxt").value);
                          toastr.fire({
                            icon: 'success',
                            title: 'PPMP created successfully'
                          })
                          this.$Progress.finish();
                    }).catch(error => {
                      swal.showValidationMessage(
                        `Request failed: ${error}`
                      )
                    })
                    return "ok";
                }else{
                    swal.showValidationMessage(
                      `Request failed: PPMP for the given year already exist.`
                    )
                }
              },
              allowOutsideClick: () => !swal.isLoading()
            }).then((result) => {
              if (result.isConfirmed) {
                swal.fire({
                  title: `${result.value.txt}'s avatar`,
                  imageUrl: result.value.avatar_url
                })
              }
            })
          },

          deletePpmpItem(id) {
            swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, remove item from PPMP!'
            }).then((result)=>{
              if (result.value) {
                this.form.delete('api/ppmpitems/'+id).then(()=>{
                  swal.fire(
                    'Deleted!',
                    'PPMP Item has been deleted.',
                    'success'
                  );
                  Trigger.$emit('RefreshItems');
                }).catch(()=>{
                  swal.fire(
                    'Failed!',
                    'There was something wrong',
                    'warning'
                  )
                });
              }
            })
          },

          closeBidModal() {
            this.loadPrItems(this.form.id);
            this.loadProjectBidders(this.form.id);
            $('#bidinfomodal').modal('hide');
          },

          actionAddItem() {
            this.loadPrProcItems(this.year)
            this.selected_items_form.selected_items= [];
            $('#actionadditemmodal').modal('show');
          },

          actionAddSupplier() {
            this.loadSuppliers()
            this.selected_suppliers_form.selected_items= [];
            $('#actionaddsuppliermodal').modal('show');
          },

          actionEditBidder(bdr) {
            this.bidderinfo_form.reset();
            this.bidderinfo_form.clear();
            this.bidderinfo_form.fill(bdr)
            $('#bidderinfomodal').modal('show');
          },

          bidBidder(bdr) {
            this.editbidmode = false;
            this.getBidder(bdr)
            this.bidinfo_form.reset();
            this.bidinfo_form.clear();
            this.bidinfo_form.fill(bdr)
            $('#bidinfomodal').modal('show');
          },

          actionBidderBids() {
            $('#bidderbidsmodal').modal('show');
          },

          loadPrProcItems() {
            axios.get('api/proc-project-items/approved/pr').then(({ data }) => (this.approvedprprocurementitems = data));
          },

          getBidder(bdr) {
            axios.get('api/proc-project-bidders/project/' + this.form.id + '/bidder/' + bdr).then(({ data }) => (this.bidder = data));
          },

          loadSuppliers() {
            axios.get('api/suppliers').then(({ data }) => (this.suppliers = data));
          },

          actionUpdateBidderInfo() {
            this.$Progress.start();
            this.bidderinfo_form.put('api/procprojectbidders-update/' + this.bidderinfo_form.id)
            .then(()=>{
              this.loadProjectBidders(this.form.id)
              $('#bidderinfomodal').modal('hide')
              toastr.fire({
                icon: 'success',
                title: 'Bidder info updated'
              })
              this.$Progress.finish();
            }).catch(()=>{
              toastr.fire({
                icon: 'error',
                title: 'Bidder info updating failed'
              });
              this.$Progress.fail();
            })
          },

          addToFormSelectedItems(projectid) {
            this.$Progress.start();
            this.selected_items_form.post('api/procprojectitems-add/' + projectid)
            .then(()=>{
              this.loadPrItems(this.form.id)
              $('#actionadditemmodal').modal('hide')
              toastr.fire({
                icon: 'success',
                title: 'Approved PR items added in Poject'
              })
              this.$Progress.finish();
            }).catch(()=>{
              toastr.fire({
                icon: 'error',
                title: 'Approved PR items adding failed'
              });
              this.$Progress.fail();
            })
          },

          addToFormSelectedSuppliers(projectid) {
            this.$Progress.start();
            this.selected_suppliers_form.post('api/procprojectbidders-add/' + projectid)
            .then(()=>{
                this.loadProjectBidders(projectid)
                $('#actionaddsuppliermodal').modal('hide')
                toastr.fire({
                    icon: 'success',
                    title: 'Bidders added in Poject'
                })
                this.$Progress.finish();
            }).catch(()=>{
                toastr.fire({
                    icon: 'error',
                    title: 'Bidder adding failed'
                });
                this.$Progress.fail();
            })
        },

        saveBid() {
            this.$Progress.start();
            this.bidinfo_form.post('api/proc-project-bid/update/' + this.bidder.id)
            .then(()=>{
            this.getBidder(this.bidder.id)
              this.editbidmode = false;
              toastr.fire({
                icon: 'success',
                title: 'Bid updated'
              })
              this.$Progress.finish();
            }).catch(()=>{
              toastr.fire({
                icon: 'error',
                title: 'Bid updating failed'
              });
              this.$Progress.fail();
            })
        },

        zeroPad(num, places) {
            var zero = places - num.toString().length + 1;
            return Array(+(zero > 0 && zero)).join("0") + num;
        },

        async generateRefno() {

            var code;
            const current = new Date();
            const current_yyyy =  moment(String(current)).format('YYYY');
            const current_month = moment(String(current)).format('MM');

            await axios.get('api/procprojects-last').then(response => {
            if (!response.data) {
                this.form.refno = current_yyyy + '-' + current_month + '-' + '0001';
            } else {

                code = response.data.refno.slice(response.data.refno.length - 4);
                console.log(code)
                code = code.slice(0, 4);
                code = parseInt(code) + 1;
                this.form.refno = current_yyyy + '-' + current_month + '-' + this.zeroPad(code, 4);
            }
            });
        },


        assignSignatory() {
            this.signatory_details = this.signatories.find((sg) => sg.id == this.signatory);
        },


        editModal(payload) {
            this.editmode = true;
            this.form.reset();
            this.form.clear();
            this.form.fill(payload);
            this.loadPrItems(payload.id);
            this.loadProjectBidders(payload.id);
            $('#actionmodal').modal('show');
        },

        newModal() {
            this.editmode = false;
            this.form.reset();
            this.form.clear();
            this.generateRefno();
            $('#actionmodal').modal('show');
        },

        enableEdit(payload) {
            this.edititemmode = true;
            this.form_item.reset();
            this.form_item.clear();
            this.form_item.fill(payload);
        },

        enableBidEdit(payload) {
            console.log(payload)

            this.editbidmode = true;
            this.bidinfo_form.reset();
            this.bidinfo_form.clear();

            this.bidinfo_form.id = payload.id;
            this.bidinfo_form.procprojectbidder_id = payload.procprojectbidder_id;
            this.bidinfo_form.bidPrice = payload.bidPrice;
            this.bidinfo_form.procprojectitem_id = payload.procproject_id;
            this.bidinfo_form.validity = payload.validity;
            this.bidinfo_form.rank = payload.rank;
            this.bidinfo_form.status = payload.status;
            this.bidinfo_form.remarks = payload.remarks;
        },

        saveBidEdit() {
            this.editbidmode = false;
        },

        cancelItem() {
            this.edititemmode = false;
            this.form_item.reset();
            this.form_item.clear();
        },

        deleteItem(id) {
            swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, remove item from Project!'
            }).then((result)=>{
                if (result.value) {
                this.form.post('api/procprojectitems-remove/'+id).then(()=>{
                    swal.fire(
                    'Removed!',
                    'Project Item has been removed.',
                    'success'
                    );
                    this.loadPrItems(this.form.id)
                }).catch(()=>{
                    swal.fire(
                    'Failed!',
                    'There was something wrong',
                    'warning'
                    )
                });
                }
            })
        },

        deleteBidder(id) {
            swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, remove bidder from Project!'
            }).then((result)=>{
                if (result.value) {
                this.form.post('api/procprojectbidders-remove/'+id).then(()=>{
                    swal.fire(
                    'Removed!',
                    'Project Item has been removed.',
                    'success'
                    );
                    this.loadProjectBidders(this.form.id)
                }).catch(()=>{
                    swal.fire(
                    'Failed!',
                    'Remove bids first before deletion',
                    'warning'
                    )
                });
                }
            })
        },

          saveItem() {
            this.$Progress.start();
            this.form_item.put('api/proc-project-items/'+this.form_item.id).then(()=>{
              toastr.fire({
                icon: 'success',
                title: 'Item Updated'
              })
              this.loadPrItems(this.form.id)
              this.edititemmode = false;
              this.form_item.reset();
              this.form_item.clear();
              this.$Progress.finish();
            }).catch(()=>{
              toastr.fire({
                icon: 'error',
                title: 'Item updating failed'
              });
              this.$Progress.fail();
            });
          },

          saveDetails() {
            this.$Progress.start();
            if(!this.editmode) {
                this.form.post('api/procprojects').then(()=>{
                    Trigger.$emit('RefreshData');
                    toastr.fire({
                        icon: 'success',
                        title: 'Procurement project created'
                    })
                    $('#actionmodal').modal('hide');
                    this.$Progress.finish();
                    }).catch(()=>{
                    toastr.fire({
                        icon: 'error',
                        title: 'Procurement project creation failed'
                    });
                    this.$Progress.fail();
                });
            } else {
                this.form.put('api/procprojects/'+this.form.id).then(()=>{
                    Trigger.$emit('RefreshData');
                    toastr.fire({
                        icon: 'success',
                        title: 'Details Updated'
                    })
                    this.$Progress.finish();
                    }).catch(()=>{
                    toastr.fire({
                        icon: 'error',
                        title: 'Details updating failed'
                    });
                    this.$Progress.fail();
                });
            }

          },

          deleteDetails() {
            swal.fire({
            title: 'Are you sure?',
            text: "Deleting will permanently remove the project and all its respective data. Do you really want to delete this procurement project?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete Project!'
            }).then((result)=>{
              if (result.value) {
                this.form.delete('api/procprojects/'+this.form.id).then(()=>{
                  swal.fire(
                    'Deleted!',
                    'Project has been deleted.',
                    'success'
                  );
                  Trigger.$emit('RefreshData');
                  $('#actionmodal').modal('hide');
                }).catch(()=>{
                  swal.fire(
                    'Failed!',
                    'There was something wrong',
                    'warning'
                  )
                });
              }
            })
          },

          saveNewPrDetails() {
            this.$Progress.start();
            this.form.post('api/purchase-requests').then(()=>{
              Trigger.$emit('RefreshData');
              toastr.fire({
                icon: 'success',
                title: 'New PR Saved'
              })
              this.$Progress.finish();
            }).catch(()=>{
              toastr.fire({
                icon: 'error',
                title: 'New PR saving failed'
              });
              this.$Progress.fail();
            });
          },

          deleteAction(id) {
            swal.fire({
            title: 'Are you sure?',
            text: "Deleting will permanently remove the item and all its respective data. Do you really want to delete?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete PR!'
            }).then((result)=>{
              if (result.value) {
                this.ppmp_form.delete('api/purchase-requests/'+ id).then(()=>{
                  swal.fire(
                    'Deleted!',
                    'PR has been deleted.',
                    'success'
                  );
                  Trigger.$emit('RefreshData');
                }).catch(()=>{
                  swal.fire(
                    'Failed!',
                    'There was something wrong',
                    'warning'
                  )
                });
              }
            })
          },

          abstractModal() {
            $('#abstractmodal').modal('show');
          },

          printModal() {
            $('#printmodal').modal('show');
          },

          loadPrItems(projid) {
            axios.get('api/proc-project-items/project/' + projid).then(({ data }) => (
              this.pritems = data,
              this.pritemx = data,
              this.getTotalAmount()
            ));
          },

          loadProjectBidders(projid) {
            axios.get('api/proc-project-bidders/project/' + projid).then(({ data }) => (this.bidders = data));
          },

          loadData(year) {
            axios.get('api/procprojects/year/' + year).then(({ data }) => (
              this.procprojects = data
            ));
          },


          loadProcmodes() {
            axios.get('api/procmodes').then(({ data }) => (this.procmodes = data));
          },

          loadOffices() {
            axios.get('api/offices').then(({ data }) => (this.offices = data));
          },

          loadSignatories() {
            axios.get('api/procurement-role/level/17').then(({ data }) => (this.signatories = data ));
          },

          loadProcSignatories() {
            axios.get('api/procurement-role/level/2').then(({ data }) => (this.procsignatories = data ));
          },

          loadEnduserSignatories() {
            axios.get('api/procurement-role/level/7').then(({ data }) => (this.endusersignatories = data ));
          },

          async loadPermission(mid) {
            const response = await axios.get("api/permission/" + this.$gate.showMyID() + "/" + mid).then(
            res => {
              if (!res.data || res.data.length == 0) {
                return false;
              } else {
                return true;
              }
            })

            const getResponse = async () => {
              const a = await response;

              switch (mid) {
              case 57:
                this.can_access = a;
                if (!this.can_access) {
                  this.$router.push({ name: 'not-found' }) //redirect to not found
                }
                break;
              /*
              case 55:
                this.can_add = a;
                this.can_edit = a;
                break;
              case 56:
                this.can_delete = a;
                */
              }
            };
            return getResponse()
          },



        },

        created() {
          Trigger.$on('searching',() => {
            let query = this.$parent.search;
            let emptyflag = false;

            if(query == ''){
              Trigger.$emit('RefreshData');
            }else{
              this.$Progress.start();
              axios.get("api/findPpmpitems?q=" + query)
              .then((data) => {
                this.ppmpitems = data.ppmpitems;

                if (data.data.total == 0){
                  this.emptyflag = true;
                }

                this.$Progress.finish();
              })
              .catch(() => {
                this.$Progress.fail();
              })
            }

          });


          this.loadProcSignatories();
          this.loadSignatories();
          this.loadEnduserSignatories();
          this.loadOffices();
          this.loadProcmodes();
          this.loadData(this.year)

          this.loadPermission(57); //Can Manange PR


          Trigger.$on('RefreshData',() => {
            this.loadOffices();
            this.loadProcmodes();
            this.loadData(this.year);
          });
        },

    }
  </script>
  <style scoped>
  div >>> p {
  font-size: 15px;
  }

  div >>> strong {
  font-size: 15px;
  }

  div >>> em {
  font-size: 15px;
  }

  div.header >>> p {
  line-height: 0.5;
  font-size: 20px;
  font-family: "Helvetica Narrow","Arial Narrow";
  }

  div.header >>> strong {
  line-height: 0.5;
  font-size: 20px;
  font-family: "Helvetica Narrow","Arial Narrow";
  }

  div.header >>> em {
  line-height: 0.5;
  font-size: 20px;
  font-family: "Helvetica Narrow","Arial Narrow";
  }

  .text-selection-none{
   user-select: none; /* supported by Chrome and Opera */
   -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
   -moz-user-select: none; /* Firefox */
   -ms-user-select: none;
  }

  .responsive-table tbody td:last-of-type {
      text-align: center;
  }

  .selectize-control {
      height: 0px !important;
  }

  table.customtextsize tr td {
    font-size: 17px;
    vertical-align: top !important;
  }

  table.table-borderless {
    font-size: 13px !important;
  }

  b, strong {
      font-weight: bolder;
      /*font-size: 17px;*/
  }

  u {
      text-decoration: underline;
      /*font-size: 17px;*/
  }

  .alert-warning {
      text-align: left !important;
  }

  @page {
    size: A4;
  }

  @media print
  {
    table { page-break-after:auto; }
    tr    { page-break-inside:avoid; page-break-after:auto }
    td    { page-break-inside:avoid; page-break-after:auto }
    thead { display:table-header-group }
    tbody:last-child {height:auto !important;}
    tfoot { display:table-footer-group; }

  }

  .table-styled {
  border-collapse: collapse;
  width: 100%;
}

.table-styled th, .table-styled td {
  border: 1px solid black;
  padding: 0px;
  text-align: left;
}

.borderless {
  border-collapse: collapse;
  width: 100%;
}

.borderless th, .borderless td {
  border: 0px solid black;
  padding: 0px;
  text-align: left;
}
.highlight {
  background-color: #dff0d8; /* Light green background for highlighting */
}

.nested-table {
    width: 100%;
    height: 30px;
}

.custom-select{
    border: 1px solid transparent !important;
    padding: 0 !important;
    font-weight: bold !important;
    color: #000000 !important;
    height: 20px !important;
    width: 200px !important;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding: 10px;
    background: transparent;
    box-shadow: inset 0 1px 2px #FFFFFF;
}

.custom-select::after {
  content: '▼'; /* You can use a custom chevron or arrow here */
  font-size: 12px;
  color: #999; /* Color of the chevron */
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.option-text {
  text-decoration: underline;
}

.border-middle {
  position: relative;
  padding: 10px;
}

.border-line {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  /*width: 1px;  Thickness of the vertical border */
  border-right: 1px solid #000; /* Color of the border */
  transform: translateX(-51%);
}

</style>
