<template>
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-md-12">


     
                  <div class="card-header bg-primary pull-right">

  
                        Year
                        <select @change="getPayroll" v-model="form.year" name="year" class="">
                        <option v-for="year in years" :value="year" :key="year">{{ year }}</option>
                        </select>

                        &nbsp;&nbsp;Month
                        <select @change="getPayroll" v-model="form.month" name="month" class="">
                                        <option value="01">JANUARY</option>
                                        <option value="02">FEBRUARY</option>
                                        <option value="03">MARCH</option>
                                        <option value="04">APRIL</option>
                                        <option value="05">MAY</option>
                                        <option value="06">JUNE</option>
                                        <option value="07">JULY</option>
                                        <option value="08">AUGUST</option>
                                        <option value="09">SEPTEMBER</option>
                                        <option value="10">OCTOBER</option>
                                        <option value="11">NOVEMBER</option>
                                        <option value="12">DECEMBER</option>
                                    </select>
                        &nbsp;&nbsp;Cutoff
                        <select @change="getPayroll" v-model="form.cutoff" name="cutoff" class="">
                                        <option value="1">1st Cutt-off</option>
                                        <option value="2">2nd Cutt-off</option>
                                    </select>
                        &nbsp;&nbsp;Source of Fund
                        <select @click="getPayroll" v-model="form.division" name="division" @change="onDivisionChange($event)">
                                    <option v-for="division in divisions" :value="division.id" :key="division.id">{{ division.division_name }}</option>
                                  </select>
                      <div class="card-tools">
                        <button v-print="'#printReport'" @click="print"  class="btn btn-success mr-2"><i class="fas fa-print"></i> Print</button>
                      </div>
                      <!--
                      <div class="card-tools">
                        <div class="form-check" style="padding-right: 15px">
                          <input class="form-check-input" type="checkbox" @change="hideEmpty" v-model="hideempty" :checked="hideempty == true ? true: false" />
                          <label class="form-check-label">Hide empty projects</label>
                        </div>
                      </div>
                      -->
                      
                  </div>
     


                <div class="card">

                    <div class="card-body table-responsive p-0">

                      <div  id="printReport">
                        <table class="table table-borderless" style="font-size: 5px;">
                          <tbody>
                            <tr>
                              <td colspan="9" align="center">
                                <br/>
                                <h3>PAYROLL FOR CONTRACT PRICE OF PROJECT PERSONNEL</h3>
                                <i v-if="selected === 'Finance and Administration Division'">Source of Fund: General Fund</i>
                                <i v-else>Source of Fund: {{ selected }}</i>
                              </td>
                            </tr>
                            <tr>
                              <td colspan="9">
                                <hr/>
                                We hereby acknowledge to have received the sums therein specified opposite our respective names for our services rendered:<br/><br/>
                                <b><span v-if=" this.form.cutoff == '1'"> 1-15</span> <span v-else-if=" this.form.cutoff == '2'"> 16-31</span> {{ this.form.month | monthwordformat }} {{ this.form.year }}</b>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <table class="table table-bordered table-hover" style="font-size: 5px;">
                            <thead>
                            <tr>
                              <th class="card-header">
                                NAME / Position
                              </th>
                              <th class="card-header">
                                Monthly Rate
                              </th>
                              <th class="card-header">
                                Salary Earned
                              </th>
                              <th class="card-header">
                                ABSENCES/LATES/UNDERTIME
                              </th>
                              <th class="card-header">
                                Total Salary
                              </th>
                              <th class="card-header">
                                TAX (EWT: 2%)
                              </th>
                              <th class="card-header">
                                TAX (EWT: 5%)
                              </th>
                              <th class="card-header">
                                Percentage Tax 1%
                              </th>
                              <th class="card-header">
                                NET SALARY
                              </th>
                            </tr>
                            </thead>
                            <tbody v-for="project in projects" :key="project.id">
                              <tr>
                                <template v-if="total_salary_net(project.payrolls) != 0">
                                  <td colspan="9" align="center"><b>{{ project.project_name }}</b></td>
                                </template>
                              </tr>
                              <tr v-for="payroll in project.payrolls" :key="payroll.id">
                                <template v-if="total_salary_net(project.payrolls) != 0">
                                  <td><b>{{ payroll.fullname }}</b><br><i>{{ payroll.position }}</i></td>
                                  <td>{{ payroll.salary_monthly | toMoney }}</td>
                                  <td>{{ payroll.salary_earned | toMoney }}</td>
                                  <td>{{ payroll.lua | toMoney }}</td>
                                  <td>{{ payroll.salary_total | toMoney }}</td>
                                  <td>{{ payroll.ewt_2 | toMoney }}</td>
                                  <td>{{ payroll.ewt_5 | toMoney }}</td>
                                  <td>{{ payroll.ewt_3 | toMoney }}</td>
                                  <td>{{ payroll.salary_net | toMoney }}</td>
                                </template>
                              </tr>
                              <tr>
                                <template v-if="total_salary_net(project.payrolls) != 0">
                                  <td align="right"><b>TOTAL : {{ project.project_name }}</b></td>
                                  <td><b><span id="xxx">{{ total_salary_monthly(project.payrolls) | toMoney }}</span></b></td>
                                  <td><b>{{ total_salary_earned(project.payrolls) | toMoney }}</b></td>
                                  <td><b>{{ total_lua(project.payrolls) | toMoney }}</b></td>
                                  <td><b>{{ total_salary_total(project.payrolls) | toMoney }}</b></td>
                                  <td><b>{{ total_ewt_2(project.payrolls) | toMoney }}</b></td>
                                  <td><b>{{ total_ewt_5(project.payrolls) | toMoney }}</b></td>
                                  <td><b>{{ total_ewt_3(project.payrolls) | toMoney }}</b></td>
                                  <td><b>{{ total_salary_net(project.payrolls) | toMoney }}</b></td>
                                </template>
                              </tr>
                            </tbody>
                            <tbody>
                              <tr>
                                <td colspan="9">&nbsp;</td>
                              </tr>
                              <tr v-for="grand in grandtotals" :key="grand.division_id">
                                <td><b>GRAND TOTAL: </b></td>
                                <td><b>{{ grand.grand_salary_monthly | toMoney }}</b></td>
                                <td><b>{{ grand.grand_salary_earned | toMoney }}</b></td>
                                <td><b>{{ grand.grand_lua | toMoney }}</b></td>
                                <td><b>{{ grand.grand_salary_total | toMoney }}</b></td>
                                <td><b>{{ grand.grand_ewt_2 | toMoney }}</b></td>
                                <td><b>{{ grand.grand_ewt_5 | toMoney }}</b></td>
                                <td><b>{{ grand.grand_ewt_3 | toMoney }}</b></td>
                                <td><b>{{ grand.grand_salary_net | toMoney }}</b></td>
                              </tr>
                            </tbody>
                        </table>
                        <br/>
                      
                        <div class="row" style="margin:0;">
                          <div class="col-6">
                            <div class="text-center mb-5">
                              (1) CERTIFY on my official oath that the above Payroll is correct<br/>
                              and that the services have been duly rendered as stated.
                            </div>
                            <div class="text-center mb-5">
                              <u><b> JOYCEL N. AGUILAR </b></u><br/>
                              Chief Admin. Officer, FAD
                            </div>
                            <div class="text-center mb-5">
                              <u>Prepared by: <b>JUFFREY I. RODRIGUEZ</b></u><br/>
                              CLERK IV, HR Section
                            </div>
                          </div>
                          <div class="col-6">
                            <div class="text-center mb-5">
                              (2) APPROVED, payable for appropriation of ISTE,TAPI-MOOE.<br/>
                              I further certify that this Payroll consists of __ page(s) only.
                            </div>
                            <div class="text-center mb-5">
                              <u><b> ATTY. MARION IVY DECENA </b></u><br/>
                              DIRECTOR, TAPI
                            </div>
                            <div class="text-center mb-5">
                              <u>Checked by: <b>LOURDES C. PALILEO</b></u><br/>
                              AO V, HR Section
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                </div>

                <div class="modal fade" id="sessionmodal" tabindex="-1" role="dialog" aria-labelledby="sessionModalLabel" aria-hidden="true">
                  <div class="modal-dialog modal-dialog-centered modal-md" role="document">
                    <div class="modal-content">
                      <div class="modal-header bg-orange">
                        <h5 class="modal-title" id="sessionModalLabel"><i class="icon fas fa-exclamation-triangle"></i> Security Auto-logout</h5> 
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body">
                      <div class="row-aligncenter">
                        <div id="printPayslip2">
                          <div class="card-body">
                            You have left this browser idle for 10 minutes. Due to our data privacy policy, you will be 
                            automatically logged out in <b>{{ timer }}</b> seconds.
                          </div>
                        </div>
                      </div>
                      </div>
                      <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                      </div>
                    </div>
                  </div>
                </div>
                <span style="display:none;">{{ isIdle }}</span>
                {{ getelementval }}

            </div>
        </div>
    </div>
</template>

<script>
    import Form from 'vform';
    import moment from 'moment';

    export default {
        data() {
          return {
            grandtotals: [],
            timer: 30,
            selected: 'Finance and Administration Division',
            divisions : {},
            year: '',
            projects: [],
            hideempty: false,
            can_access: false,
            form: new Form({
              division: '',
              year: '',
              month: '',
              cutoff: '',
            })
          }
        },

        methods: {

          print(){
              var style = document.createElement('style');
              style.innerHTML = "@page{size: landscape;margin: 25mm;}" +
                      " html{background-color: #FFFFFF;}" +
                      " body{border: solid 0px #FFFFFF;margin: 0;}";
              window.document.head.appendChild(style);
          },

          hideEmpty(e) {
                //this.$Progress.start();
                if(e.target.checked){
                     this.hideempty = true;
                } else {
                     this.hideempty = false;
                }
                //this.$Progress.finish();
            },

          countDownTimer() {
            let timerId = setInterval(() => {
              this.timer -= 1;
              if (!this.$store.state.idleVue.isIdle) clearInterval(timerId);

              if (this.timer < 1) {
                clearInterval(timerId);
                // Your logout function should be over here
                document.getElementById('logout-form').submit();
              }
            }, 1000);

            this.timer = 30;
          },

          onDivisionChange(event) {
            var optionText = event.target.options[event.target.options.selectedIndex].text;
            this.selected = optionText;
          },

          getPayroll(){
            this.$Progress.start();
            axios.get("api/projects/divisions/" + this.form.division + "/payrolls/" + this.form.year + "/" + this.form.month + "/" + this.form.cutoff).then(({ data }) => (this.projects = data));
            axios.get("api/payrolls/total/" + this.form.division + "/" + this.form.year + "/" + this.form.month + "/" + this.form.cutoff).then(({ data }) => (this.grandtotals = data));
            this.$Progress.finish();
          },

          total_salary_monthly: function(payrolls) {
            return payrolls.reduce((acc, pay) => {
              return acc + parseFloat(pay.salary_monthly);
            }, 0);
          },

          grand_total_salary_monthly: function() {

          },

          total_salary_earned: function(payrolls) {
            return payrolls.reduce((acc, pay) => {
              return acc + parseFloat(pay.salary_earned);
            }, 0);
          },

          total_lua: function(payrolls) {
            return payrolls.reduce((acc, pay) => {
              return acc + parseFloat(pay.lua);
            }, 0);
          }, 

          total_salary_total: function(payrolls) {
            return payrolls.reduce((acc, pay) => {
              return acc + parseFloat(pay.salary_total);
            }, 0);
          }, 

          total_ewt_2: function(payrolls) {
            return payrolls.reduce((acc, pay) => {
              return acc + parseFloat(pay.ewt_2);
            }, 0);
          }, 

          total_ewt_5: function(payrolls) {
            return payrolls.reduce((acc, pay) => {
              return acc + parseFloat(pay.ewt_5);
            }, 0);
          }, 

          total_ewt_3: function(payrolls) {
            return payrolls.reduce((acc, pay) => {
              return acc + parseFloat(pay.ewt_3);
            }, 0);
          }, 

          total_salary_net: function(payrolls) {
            console.log(payrolls);
            return payrolls.reduce((acc, pay) => {
              return acc + parseFloat(pay.salary_net);
            }, 0);
          },

          async loadPermission(mid) {
              const response = await axios.get("api/permission/" + this.$gate.showMyID() + "/" + mid).then( 
              res => {
                if (!res.data || res.data.length == 0) {
                  return false;
                } else {
                  return true;
                }
              })

              const getResponse = async () => {
                const a = await response;

                switch (mid) {
                case 5:
                  this.can_access = a;
                  if (!this.can_access) {
                    this.$router.push({ name: 'not-found' }) //redirect to not found
                  }
                }
              };
              return getResponse()
          },

        },

        computed: {

          years() {
            const year = new Date().getFullYear()
            return Array.from({length: year - 2018}, (value, index) => 2019 + index)
          },

          second() {
            return this.time / 1000;
          },

          getelementval() {
            //console.log(document.getElementById('xxx'));
            //console.log($('#xxx').val());
          },

          isIdle() {
            const idlestat =  this.$store.state.idleVue.isIdle;
            if (idlestat == true) {
              $('#sessionmodal').modal('show');
              this.countDownTimer();
            } else {
              $('#sessionmodal').modal('hide');
            }

            return idlestat;

          },

        },

        created() {

          const today = new Date();
          
          const curr_year = today.getFullYear();
          const curr_month = moment(String(today.getMonth() + 1)).format('MM');

          let yr = "";
          let mo = "";

          if(this.year == '' || this.month == '') {
            yr = curr_year;
            mo = curr_month;
            this.form.year = curr_year;
            this.form.month = curr_month;
          } else {
            yr = this.form.year;
            mo = this.form.month;
          }

          this.form.division = 1;
          this.form.cutoff = 1;


          this.loadPermission(5); //Can access payroll
          axios.get("api/divisions").then(({ data }) => (this.divisions = data));
          axios.get("api/projects/divisions/1/payrolls/" + yr + "/" + mo + "/1").then(({ data }) => (this.projects = data));
          axios.get("api/payrolls/total/1/" + yr + "/" + mo + "/1").then(({ data }) => (this.grandtotals = data));
          

        }
    }
</script>

