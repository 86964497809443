<template>
    <div>
      <div ref="map" style="height: 948px;"></div>
    </div>
</template>

<script>

import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

export default {
    data() {
      return {
        map: null
      };
    },
    props: {
        geojson: {},
    },
    mounted() {
      this.initMap();
    },
    methods: {

        initMap() {

            // Set the initial bounds to cover the Philippines
            const initialBounds = L.latLngBounds(L.latLng(4.5, 116), L.latLng(21, 127));

            // Create the Leaflet map
            this.map = L.map(this.$refs.map, {
                maxBounds: initialBounds,  // Set maximum bounds
                maxBoundsViscosity: 0.9,   // Constrain the user from dragging outside the bounds too quickly
                minZoom: 6.23,               // Set minimum zoom level
                zoomSnap: 0.5             // Snap to half zoom levels
            }).fitBounds(initialBounds);

            // Use a custom map style without labels
            L.tileLayer('https://basemaps.cartocdn.com/rastertiles/light_nolabels/{z}/{x}/{y}.png', {
                attribution: "© cmarcelino"
            }).addTo(this.map);

            // Example GeoJSON data for demonstration (replace with actual data)
            const geojson = this.geojson;

            // GeoJSON data for Panay Island
            const panayIslandGeojson = {
                "type": "Feature",
                "properties": {
                    "name": "Panay Island"
                    // Additional properties if needed
                },
                "geometry": {
                    "type": "Polygon",
                    "coordinates": [
                        [
                        [121.019974, 11.569749], // Northwest point (Aklan)
                        [121.021448, 11.668159], // Northernmost point (Aklan)
                        [121.108443, 11.663031], // Northernmost point (Aklan)
                        [121.223542, 11.570500], // Northernmost point (Capiz)
                        [121.308842, 11.515974], // Northernmost point (Capiz)
                        [121.445856, 11.531280], // Northeast point (Iloilo)
                        [121.512542, 11.419613], // Northeast point (Iloilo)
                        [121.594913, 11.415425], // Southeast point (Iloilo)
                        [121.699209, 11.228370], // Southeast point (Antique)
                        [121.561613, 11.151958], // Southeast point (Antique)
                        [121.420670, 11.203670], // Southwest point (Aklan)
                        [121.299106, 11.306822], // Southwest point (Aklan)
                        [121.183862, 11.336537], // Southwest point (Aklan)
                        [121.129624, 11.389996], // Southwest point (Aklan)
                        [121.032774, 11.476899], // Southwest point (Aklan)
                        [121.019974, 11.569749]  // Northwest point (Aklan)
                        ]
                    ]
                }
            };

            /*/ Create the GeoJSON layer for Panay Island
            const panayIslandLayer = L.geoJSON(panayIslandGeojson, {
                style: {
                    fillColor: '#fff', // initial fill color
                    color: '#000', // initial border color
                    weight: 1,
                    opacity: 1,
                    fillOpacity: 0.7
                },
                onEachFeature: (feature, layer) => {
                    // Bind popup with region information
                    layer.bindPopup(`Region: ${feature.properties.name}`);

                    // Define event handlers for hover
                    layer.on({
                        mouseover: (event) => {
                            layer.setStyle({
                                fillColor: '#ff0000', // hover fill color
                                color: '#ff0000' // hover border color
                            });
                        },
                        mouseout: (event) => {
                            layer.setStyle({
                                fillColor: '#fff', // restore initial fill color
                                color: '#000' // restore initial border color
                            });
                        }
                    });
                }
            }).addTo(this.map);*/

            // Add other GeoJSON data
            L.geoJSON(geojson, {
                pointToLayer: (feature, latlng) => {
                    return L.circleMarker(latlng, {
                        radius: Math.sqrt(feature.properties.projects) * 5,
                        fillColor: '#ff7800',
                        color: '#000',
                        weight: 1,
                        opacity: 1,
                        fillOpacity: 0.8
                    });
                },
                onEachFeature: (feature, layer) => {
                    layer.bindPopup(`Region: ${feature.properties.name}<br>Projects: ${feature.properties.projects}`);
                }
            }).addTo(this.map);
        }

    }
};
</script>

<style>
.leaflet-control-attribution.leaflet-control a {
    display: none !important;
}

.leaflet-control-attribution.leaflet-control span {
    display: none !important;
}
</style>
