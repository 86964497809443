<template>
    <div class="container-fluid">
        
        <div class="row">
          <div class="col-md-12">
            <div class="card card-secondary card-outline">
              <div class="card-header">
                <h3 class="card-title"><i class="fas fa-users"></i> Employee List</h3>

                <div class="card-tools">
                  <div>
                    <button v-show="can_add"  class="btn btn-success btn-sm" @click="newModal"><i class="fa fa-user-plus"></i></button>
                  </div>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <table class="table responsive-table table-striped">
                  <thead>
                    <tr>
                      <th scope="col" class="pl-2">Employee ID</th>
                      <th scope="col" class="pl-2">Photo</th>
                      <th scope="col">Name</th>
                      <th scope="col">Username</th>
                      <th scope="col">Position</th>
                      <th scope="col">Appointment Status</th>
                      <th scope="col">Status</th>
                      <th scope="col">Options</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="user in users.data" :key="user.id">
                      <th scope="row" class="pl-2">{{ user.employee_code }}</th>
                      <td data-title="Photo" class="pl-2"><img alt="Avatar" class="img-circle elevation-2" height="25px" width="25px" :src="'img/profile/' + user.photo" /></td>
                      <td data-title="Name" v-if="user.sname === 'NA'">{{ user.lname }}, {{ user.fname }} {{ user.mname | midname }}</td>
                      <td data-title="Name" v-else>{{ user.lname }}, {{ user.fname }} {{ user.mname | midname }}, {{ user.sname }}</td>
                      <td data-title="Username">{{ user.username }}</td>
                      <td data-title="Position">{{ user.position }}</td>
                      <td data-title="Appointment Status">{{ user.appointment }}</td>
                      <td data-title="Status">
                        <span v-if="(user.acct_status == 'In-Service')" class="badge badge-success">{{ user.acct_status }}</span>
                        <span v-else class="badge badge-danger">{{ user.acct_status }}</span>
                      </td>
                      <td data-title="Options">
                        <div class="btn-group btn-group-sm">
                          <button class="btn btn-primary"  v-show="can_edit" type="button" @click="editModal(user)"><i class="fa fa-edit"></i></button>
                          <button class="btn btn-danger" v-show="(user.id !=  profile.id) && can_delete" type="button" @click="deleteUser(user.id)"><i class="fa fa-trash"></i></button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-show="users.total==0">
                      <tr><td colspan="10">No employees found.</td></tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                <pagination :data="users" @pagination-change-page="getResults" show-disabled :limit="2"></pagination>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="usermodal" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="userModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 v-show="!editmode" class="modal-title" id="userModalLabel"><i class="fa fa-user-plus"></i> Create New Employee</h5>
                <h5 v-show="editmode" class="modal-title" id="userModalLabel"><img alt="Avatar" class="img-circle elevation-2" height="30px" :src="'img/profile/' + form.photo" /> Update Employee Information</h5> 
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <form @submit.prevent="editmode ? updateUser() : createUser()" @keydown="form.onKeydown($event)">

              <div class="modal-body">
              <div class="row">


                  <div class="col-md-4">
                      <!-- Your first column here -->
                      <div class="form-group">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">FIRST NAME</span>
                          </div>
                          <input v-model="form.fname" type="text" name="fname" autocomplete="off" placeholder="FIRST NAME" class="form-control" :class="{ 'is-invalid': form.errors.has('fname') }">
                          <has-error :form="form" field="fname"></has-error>
                        </div>
                      </div>

                      <div class="form-group">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">MIDDLE NAME</span>
                          </div>
                          <input v-model="form.mname" type="text" name="mname" autocomplete="off" placeholder="MIDDLE NAME" class="form-control" :class="{ 'is-invalid': form.errors.has('mname') }">
                          <has-error :form="form" field="mname"></has-error>
                        </div>
                      </div>

                      <div class="form-group">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">LAST NAME</span>
                          </div>
                          <input v-model="form.lname" type="text" name="lname" autocomplete="off" placeholder="LAST NAME" class="form-control" :class="{ 'is-invalid': form.errors.has('lname') }">
                          <has-error :form="form" field="lname"></has-error>
                        </div>
                      </div>

                      <div class="form-group">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">NAME EXTENSION</span>
                          </div>
                          <input v-model="form.sname" type="text" name="sname" autocomplete="off" placeholder="SUFFIX" class="form-control" :class="{ 'is-invalid': form.errors.has('sname') }">
                          <has-error :form="form" field="sname"></has-error>
                        </div>
                      </div>

                      <div class="form-group">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">USERNAME</span>
                          </div>
                          <input v-model="form.username" type="text" name="username" autocomplete="off" placeholder="USERNAME" class="form-control" :class="{ 'is-invalid': form.errors.has('username') }">
                          <has-error :form="form" field="username"></has-error>
                        </div>
                      </div>

                      <div class="form-group">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">ACCESS LEVEL</span>
                          </div>
                          <select v-model="form.role" name="role" class="form-control" :class="{ 'is-invalid': form.errors.has('role') }">
                            <option value="">- Select Access Level -</option>
                            <option value="STANDARD USER">STANDARD USER</option>
                            <option value="MIS">MIS</option>
                            <option value="HR">HR</option>
                            <option value="CASHIER">CASHIER</option>
                            <option value="CASHIER">EXECOM SECRETARIAT</option>
                            <option value="CASHIER">PROJECT MANAGER</option>
                          </select>
                          <has-error :form="form" field="role"></has-error>
                        </div>
                      </div>

                      <div class="form-group">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">EMPLOYEE ID</span>
                          </div>
                          <input v-model="form.employee_code" type="text" name="employee_code" autocomplete="off" placeholder="EMPLOYEE ID" class="form-control" :class="{ 'is-invalid': form.errors.has('employee_code') }">
                          <has-error :form="form" field="employee_code"></has-error>
                        </div>
                      </div>

                      <div class="form-group">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">BIOMETRIC ID</span>
                          </div>
                          <input v-model="form.biometric_id" type="number" :maxlength="6" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" name="biometric_id" autocomplete="off" placeholder="BIOMETRIC ID" class="form-control" :class="{ 'is-invalid': form.errors.has('biometric_id') }">
                          <has-error :form="form" field="biometric_id"></has-error>
                        </div>
                      </div>

  
                  </div>


                  <div class="col-md-4">
                      <!-- Your second column here -->

                      <div class="form-group">
                        <div class="input-group">
                          <div class="input-group-prepend">
                              <span class="input-group-text">GENDER</span>
                            </div>
                          <select v-model="form.gender" name="gender" class="form-control" :class="{ 'is-invalid': form.errors.has('gender') }">
                            <option value="">- Select Gender -</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                          </select>
                          <has-error :form="form" field="gender"></has-error>
                        </div>
                      </div>

                      <div class="form-group">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">BIRTH DATE</span>
                          </div>
                          <input v-model="form.dob" type="date" name="dob" id="dob-date-input" class="form-control" :class="{ 'is-invalid': form.errors.has('dob') }">
                          <has-error :form="form" field="dob"></has-error>
                        </div>
                      </div>

                      <div class="form-group">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">OFFICE</span>
                          </div>
                          <select v-model="form.office" name="type" class="form-control" :class="{ 'is-invalid': form.errors.has('office') }">
                            <option value="">- Select Office -</option>
                            <option v-for="division in divisions" :key="division.id" v-bind:value="division.id" >{{ division.division_name }}</option>
                          </select>
                          <has-error :form="form" field="office"></has-error>
                        </div>
                      </div>

                       <div class="form-group">
                         <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">EMAIL</span>
                          </div>
                          <input v-model="form.email" type="text" name="email" autocomplete="off" placeholder="EMAIL" class="form-control" :class="{ 'is-invalid': form.errors.has('email') }">
                          <has-error :form="form" field="email"></has-error>
                         </div>
                      </div>
                      
                      <div class="form-group">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">ELIGIBILITY</span>
                          </div>
                          <select v-model="form.eligibility" name="type" class="form-control" :class="{ 'is-invalid': form.errors.has('eligibility') }">
                            <option value="">- Select Eligibility -</option>
                            <option value="CS Professional Eligibility">CS Professional Eligibility</option>
                            <option value="CS Sub-Professional Eligibility">CS Sub-Professional Eligibility</option>
                            <option value="Professional Board Exam for Teachers">Professional Board Exam for Teachers</option>
                            <option value="Police Officer Examination">Police Officer Examination</option>
                            <option value="Foreign Trade Service Officer Exam">Foreign Trade Service Officer Exam</option>
                            <option value="Teacher Examination">Teacher Examination</option>
                            <option value="First Grade Examination">First Grade Examination</option>
                            <option value="Second Grade Examination">Second Grade Examination</option>
                            <option value="Third Grade Examination">Third Grade Examination</option>
                            <option value="Senior Teacher Examination">Senior Teacher Examination</option>
                            <option value="Junior Teacher Examination">Junior Teacher Examination</option>
                            <option value="Agricultural Officer Examination">Agricultural Officer Examination</option>
                            <option value="Agriculture Extension Officer Exam">Agriculture Extension Officer Exam</option>
                            <option value="Agronomist Examination">Agronomist Examination</option>
                            <option value="Fishery Biologist Examination">Fishery Biologist Examination</option>
                            <option value="Fishery Technologist Examination">Fishery Technologist Examination</option>
                            <option value="Fishery Officer Examination">Fishery Officer Examination</option>
                            <option value="Forestry Technician Examination">Forestry Technician Examination</option>
                            <option value="Farm Management Technician Exam">Farm Management Technician Exam</option>
                            <option value="Real Property Assessing Officer Exam">Real Property Assessing Officer Exam</option>
                            <option value="Superintendent Examination">Superintendent Examination</option>
                            <option value="Soil Technologist Examination">Soil Technologist Examination</option>
                            <option value="Sanitarian Examination">Sanitarian Examination</option>
                            <option value="Meat Inspector Examination">Meat Inspector Examination</option>
                            <option value="Home Economics Extension Officer Exam">Home Economics Extension Officer Exam</option>
                            <option value="Municipal Treasurer Examination">Municipal Treasurer Examination</option>
                            <option value="Local Treasury Officer Examination">Local Treasury Officer Examination</option>
                            <option value="Management Analyst Examination">Management Analyst Examination</option>
                            <option value="Supervising Customs Appraiser Exam">Supervising Customs Appraiser Exam</option>
                            <option value="Custom Examiner Ent. Examination">Custom Examiner Ent. Examination</option>
                            <option value="Food Technician Examination">Food Technician Examination</option>
                            <option value="Statistical Aide Examination">Statistical Aide Examination</option>
                            <option value="Statistician Examination">Statistician Examination</option>
                            <option value="Auditor Examination">Auditor Examination</option>
                            <option value="Postmaster Examination">Postmaster Examination</option>
                            <option value="Money Counter Examination">Money Counter Examination</option>
                            <option value="Manpower Development Officer Exam">Manpower Development Officer Exam</option>
                            <option value="Career Service Executive Examination">Career Service Executive Examination</option>
                            <option value="Foreign Service Officer Examination">Foreign Service Officer Examination</option>
                            <option value="Police Officer I Entrance Examination">Police Officer I Entrance Examination</option>
                            <option value="Penology Officer Examination">Penology Officer Examination</option>
                            <option value="Penology Employee Examination">Penology Employee Examination</option>
                            <option value="Certified Public Accountant">Certified Public Accountant</option>
                            <option value="Licensure Examination for Teachers">Licensure Examination for Teachers</option>
                            <option value="NAPOLCOM Entrance Examination">NAPOLCOM Entrance Examination</option>
                            <option value="Barangay Official Eligibility">Barangay Official Eligibility</option>
                            <option value="Career Executive Service Officer">Career Executive Service Officer</option>
                            <option value="Career Executive Service Eligibility">Career Executive Service Eligibility</option>
                            <option value="Cultural Community Employee">Cultural Community Employee</option>
                            <option value="Cultural Community Officer">Cultural Community Officer</option>
                            <option value="PD 907">PD 907</option>
                            <option value="RA 4670">RA 4670</option>
                            <option value="PD 997">PD 997</option>
                            <option value="PD 1408">PD 1408</option>
                            <option value="RA 1080">RA 1080</option>
                            <option value="RA 6850">RA 6850</option>
                            <option value="RA 7160">RA 7160</option>
                            <option value="MC 11, S. 1996 CAT III">MC 11, S. 1996 CAT III</option>
                            <option value="CSE-Prof">CSE-Prof</option>
                            <option value="CSE-SubProf">CSE-SubProf</option>
                            <option value="NO ELIGIBILITY">No Eligibility</option>
                          </select>
                          <has-error :form="form" field="eligibility"></has-error>
                        </div>
                      </div>

                     <div class="form-group">
                       <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">STATUS</span>
                          </div>
                          <select v-model="form.acct_status" name="type" class="form-control" :class="{ 'is-invalid': form.errors.has('acct_status') }">
                            <option value="">- Select Status -</option>
                            <option value="In-Service">In-Service</option>
                            <option value="Resigned">Resigned</option>
                            <option value="Retired">Retired</option>
                            <option value="Terminated">Terminated</option>
                            <option value="Transferred">Transferred</option>
                            <option value="End of Contract">End of Contract</option>
                            <option value="AWOL">AWOL</option>
                            <option value="Deceased">Deceased</option>
                          </select>
                          <has-error :form="form" field="acct_status"></has-error>
                       </div>
                      </div>

                      <div class="form-group">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">POSITION TITLE</span>
                          </div>
                          <selectize placeholder="- Select Position Title -" v-model="form.position" name="position" class="form-control" :class="{ 'is-invalid': form.errors.has('position') }">
                            <option v-for="position in positions" :key="position.id" v-bind:value="position.position_name" >{{ position.position_name }}</option>
                          </selectize>
                          <has-error :form="form" field="position"></has-error>
                        </div>
                      </div>

                  </div>

                  <div class="col-md-4">
                      <!-- Your third column here -->

                      <div class="form-group">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">SALARY GRADE</span>
                          </div>
                          <the-mask :mask="['##-#', '##-#', '##-#']" v-model="form.sg" name="sg" autocomplete="off" placeholder="## - #" class="form-control"  :class="{ 'is-invalid': form.errors.has('sg') }" />
                          <has-error :form="form" field="sg"></has-error>
                        </div>
                      </div>

                      <div class="form-group">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">ACTUAL MONTHLY SALARY</span>
                          </div>
                          <money v-model="form.salary" :precision="2" class="form-control" :class="{ 'is-invalid': form.errors.has('salary') }"></money>
                          <has-error :form="form" field="salary"></has-error>
                        </div>
                      </div>

                      
                      <div class="form-group">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">APPOINTMENT STATUS</span>
                          </div>
                          <select v-model="form.appointment" name="type" class="form-control" :class="{ 'is-invalid': form.errors.has('appointment') }">
                            <option value="">- Select Appointment Status -</option>
                            <option value="Permanent">Permanent</option>
                            <option value="Contract of Service">Contract of Service</option>
                            <option value="Job Order">Job Order</option>
                          </select>
                          <has-error :form="form" field="appointment"></has-error>
                        </div>
                      </div>

                      <!-- start fields for contractuals -->
                      <span v-show="form.appointment=='Contract of Service' || form.appointment=='Job Order'">
                      <div class="form-group">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">CONTRACT START DATE</span>
                          </div>
                          <input v-model="form.contract_start" type="date" name="contract_start" id="contract_start-date-input" class="form-control" :class="{ 'is-invalid': form.errors.has('contract_start') }">
                          <has-error :form="form" field="contract_start"></has-error>
                        </div>
                      </div>

                      <div class="form-group">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">CONTRACT END DATE</span>
                          </div>
                          <input v-model="form.contract_end" type="date" name="contract_end" id="contract_end-date-input" class="form-control" :class="{ 'is-invalid': form.errors.has('contract_end') }">
                          <has-error :form="form" field="contract_end"></has-error>
                        </div>
                      </div>

                      <div class="form-group">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">PROJECT</span>
                          </div>
                          <select v-model="form.project" name="type" class="form-control" :class="{ 'is-invalid': form.errors.has('project') }">
                            <option value="">- Select Project -</option>
                            <option v-for="project in projects" :key="project.id" v-bind:value="project.id" >{{ project.project_name }}</option>
                          </select>
                          <has-error :form="form" field="project"></has-error>
                        </div>
                      </div>

                      <div class="form-group">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">COR</span>
                          </div>
                          <select v-model="form.cor" name="type" class="form-control" :class="{ 'is-invalid': form.errors.has('cor') }">
                            <option value="">- Select COR -</option>
                            <option value="YES">Yes</option>
                            <option value="NO">No</option>
                          </select>
                          <has-error :form="form" field="cor"></has-error>
                        </div>
                      </div>
                      </span>
                      <!-- end fields for contractuals -->

                      <!-- start fields for permanents -->
                      <span v-show="form.appointment=='Permanent'">
                      <div class="form-group">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">PLANTILLA ITEM NO.</span>
                          </div>
                          <input v-model="form.plantilla" type="text" name="plantilla" autocomplete="off" placeholder="PLANTILLA ITEM NO." class="form-control" :class="{ 'is-invalid': form.errors.has('plantilla') }">
                          <has-error :form="form" field="plantilla"></has-error>
                        </div>
                      </div>

                      
                      <div class="form-group">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">APPOINTMENT NATURE</span>
                          </div>
                          <select v-model="form.nature" name="type" class="form-control" :class="{ 'is-invalid': form.errors.has('nature') }">
                            <option value="">- Select Appointment Nature -</option>
                            <option value="Original">Original</option>
                            <option value="Transfer">Transfer</option>
                          </select>
                          <has-error :form="form" field="nature"></has-error>
                        </div>
                      </div>


                      <div class="form-group">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">Original Appt. Date</span>
                          </div>
                          <input v-model="form.hired" type="date" name="hired" id="hired-date-input" class="form-control" :class="{ 'is-invalid': form.errors.has('hired') }">
                          <has-error :form="form" field="hired"></has-error>
                        </div>
                      </div>

                      <div class="form-group">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">Last Promotion Date</span>
                          </div>
                          <input v-model="form.promotion" type="date" name="promotion" id="promotion-date-input" class="form-control" :class="{ 'is-invalid': form.errors.has('promotion') }">
                          <has-error :form="form" field="promotion"></has-error>
                        </div>
                      </div>
                      </span>
                      <!-- end fields for permanents -->

                      <div class="form-group">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">Password</span>
                          </div>
                          <input v-model="form.password" type="password" name="password" autocomplete="off" placeholder="PASSWORD" id="password" class="form-control" :class="{ 'is-invalid': form.errors.has('password') }">
                          <has-error :form="form" field="password"></has-error>
                        </div>
                      </div>

                      <div class="form-group">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">Confirm Password</span>
                          </div>
                          <input v-model="form.confirm_password" type="password" name="confirm_password" autocomplete="off" placeholder="CONFIRM PASSWORD" id="confirm_password" class="form-control" :class="{ 'is-invalid': form.errors.has('confirm_password') }">
                          <has-error :form="form" field="confirm_password"></has-error>
                        </div>
                      </div>
                  </div>
     
          
              </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button v-show="editmode" type="submit" class="btn btn-success">Update</button>
                <button v-show="!editmode" type="submit" class="btn btn-primary">Create</button>
              </div>

              </form>

            </div>
          </div>
        </div>

        <div class="modal fade" id="sessionmodal" tabindex="-1" role="dialog" aria-labelledby="sessionModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-md" role="document">
            <div class="modal-content">
              <div class="modal-header bg-orange">
                <h5 class="modal-title" id="sessionModalLabel"><i class="icon fas fa-exclamation-triangle"></i> Security Auto-logout</h5> 
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
              <div class="row-aligncenter">
                <div id="printPayslip2">
                  <div class="card-body">
                    You have left this browser idle for 10 minutes. Due to our data privacy policy, you will be 
                    automatically logged out in <b>{{ timer }}</b> seconds.
                  </div>
                </div>
              </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
        <span style="display:none;">{{ isIdle }}</span>



    </div>
</template>

<script>
    import Form from 'vform';

    export default {
        data() {
          return {
            timer: 30,
            editmode: false,
            emptyflag: false,
            can_access: false,
            can_add: false,
            can_edit: false,
            can_delete: false,
            permissions: {},
            users : {},
            profile : {},
            divisions : {},
            projects : {},
            positions : {},
            form: new Form({
              id: '',
              fname: '',
              mname: '',
              lname: '',
              sname: '',
              position: '',
              office: '',
              project: 1,
              role: '',
              password: '',
              photo: '',

              employee_code: '',
              biometric_id: '',
              username: '',
              email: '',
              eligibility: '',
              plantilla: '',
              sg: '',
              appointment: '',
              nature: '',
              hired: '',
              promotion: '',
              acct_status: '',
              salary: '',
              cor: '',

              gender: '',
              dob: '',
              pob: '',
              civil_status: '',
              citizenship: '',
              height: '',
              weight: '',
              blood_type: '',
              telephone_no: '',
              mobile_no: '',
              gsis: '',
              pagibig: '',
              sss: '',
              philhealth: '',
              tin: '',

              res_lot: '',
              res_street: '',
              res_subdivision: '',
              res_barangay: '',
              res_city: '',
              res_province: '',
              res_zip: '',

              perm_lot: '',
              perm_street: '',
              perm_subdivision: '',
              perm_barangay: '',
              perm_city: '',
              perm_province: '',
              perm_zip: '',

              spouse_lastname: '',
              spouse_firstname: '',
              spouse_middlename: '',
              spouse_extension: '',
              spouse_occupation: '',
              spouse_employer: '',
              spouse_business_address: '',
              spouse_telephone_no: '',

              father_lastname: '',
              father_firstname: '',
              father_middlename: '',
              father_extension: '',

              mother_lastname: '',
              mother_firstname: '',
              mother_middlename: '',

              contract_start: '',
              contract_end: '',

            })
          }
        },
        methods: {

          countDownTimer() {
            let timerId = setInterval(() => {
              this.timer -= 1;
              if (!this.$store.state.idleVue.isIdle) clearInterval(timerId);

              if (this.timer < 1) {
                clearInterval(timerId);
                // Your logout function should be over here
                document.getElementById('logout-form').submit();
              }
            }, 1000);

            this.timer = 30;
          },

          getResults(page = 1) {
            let query = this.$parent.search;

            if(query == ''){
              axios.get('api/users?page=' + page).then(response => { this.users = response.data; }); 
            }else{
              axios.get('api/findUser?q=' + query + '&page=' + page).then(response => { this.users = response.data; }); 
            }
          },

          updateUser() {

            this.$Progress.start();

            this.form.put('api/users/'+this.form.id).then(()=>{

                    Trigger.$emit('RefreshData');
                    $('#usermodal').modal('hide')

                    toastr.fire({
                      icon: 'success',
                      title: 'Employee updated successfully'
                    })

                    this.$Progress.finish();

                  }).catch(()=>{

                    toastr.fire({
                      icon: 'error',
                      title: 'Employee updating failed'
                    });

                    this.$Progress.fail();

                  });
          },

          editModal(user) {
            this.editmode = true;
            this.form.reset();
            this.form.clear();
            $('#usermodal').modal('show');
            this.form.fill(user);
          },

          newModal() {
            this.editmode = false;
            this.form.reset();
            this.form.clear();
            $('#usermodal').modal('show');
          },


          loadUsers() {

              this.$Progress.start();
              
              let query = this.$parent.search;
              let page = this.users.current_page;
              
              if(query == ''){
                axios.get('api/users?page=' + page).then(({ data }) => (this.users = data)).catch(error => {
                  this.$router.push({ name: 'not-found'}); //redirect to not-found page if forbidden 403
                });
              }else{
                axios.get('api/findUser?q=' + query + '&page=' + page).then(({ data }) => (this.users = data));
              }
              this.$Progress.finish();

          },


          createUser() {

            this.$Progress.start();

            this.form.post('api/users')
            .then(()=>{
                  Trigger.$emit('RefreshData');
                  $('#usermodal').modal('hide')

                  toastr.fire({
                    icon: 'success',
                    title: 'Employee created successfully'
                  })

                  this.$Progress.finish();
            }).catch(()=>{
                  toastr.fire({
                    icon: 'error',
                    title: 'Employee creation failed'
                  });

                  this.$Progress.fail();
            })

          },

          deleteUser(id) {
            swal.fire({

              title: 'Are you sure?',
              text: "You won't be able to revert this!",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes, delete employee!'
              }).then((result)=>{

                if (result.value) {
                  //send delete ajax tru api request to server
                  this.form.delete('api/users/'+id).then(()=>{

                    swal.fire(
                      'Deleted!',
                      'Employee has been deleted.',
                      'success'
                    );
                    Trigger.$emit('RefreshData');

                  }).catch(()=>{

                    swal.fire(
                      'Failed!', 
                      'There was something wrong', 
                      'warning'
                    )

                  });


                }
                
              
                
            })
          },

          async loadPermission(mid) {
              const response = await axios.get("api/permission/" + this.$gate.showMyID() + "/" + mid).then( 
              res => {
                if (!res.data || res.data.length == 0) {
                  return false;
                } else {
                  return true;
                }
              })

              const getResponse = async () => {
                const a = await response;

                switch (mid) {
                case 1:
                  this.can_access = a;
                  if (!this.can_access) {
                    this.$router.push({ name: 'not-found' }) //redirect to not found
                  }
                  break;
                case 2:
                  this.can_add = a;
                  break;
                case 3:
                  this.can_edit = a;
                  break;
                case 4:
                  this.can_delete = a;
                }
              };
              return getResponse()
          },


        },

        computed: {
          second() {
            return this.time / 1000;
          },

          isIdle() {
            const idlestat =  this.$store.state.idleVue.isIdle;
            if (idlestat == true) {
              $('#sessionmodal').modal('show');
              this.countDownTimer();
            } else {
              $('#sessionmodal').modal('hide');
            }

            return idlestat;

          },

        },

        created() {

          Trigger.$on('searching',() => {
            let query = this.$parent.search;
            let emptyflag = false;

            if(query == ''){
              Trigger.$emit('RefreshData');
            }else{
              this.$Progress.start();
              axios.get("api/findUser?q=" + query)
              .then((data) => {
                this.users = data.data;
                
                if (data.data.total == 0){
                  this.emptyflag = true;
                }

                this.$Progress.finish();
              })
              .catch(() => {
                this.$Progress.fail();
              })
            }
            
          });

          this.loadUsers();
          this.loadPermission(1); //Can access employees
          this.loadPermission(2); //Can add new employee
          this.loadPermission(3); //Can edit employee
          this.loadPermission(4); //Can delete employee
          
          axios.get("api/profile").then(({ data }) => (this.profile = data));
          axios.get("api/divisions").then(({ data }) => (this.divisions = data));
          axios.get("api/projects").then(({ data }) => (this.projects = data));
          axios.get("api/positions").then(({ data }) => (this.positions = data));
          
        
          
          
          Trigger.$on('RefreshData',() => {
            this.loadUsers();
          });
        }
    }


    
</script>
