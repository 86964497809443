<template>
    <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card card-secondary card-outline">
              <div class="card-header">
                <h3 class="card-title"><i class="fas fa-users"></i> TAPIEA Members</h3>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <table class="table responsive-table table-striped">
                  <thead>
                    <tr>
                      <th scope="col" class="pl-2">Name</th>
                      <th scope="col" class="pl-2">Photo</th>
                      <th scope="col">Username</th>
                      <th scope="col">Membership Status</th>
                      <th scope="col">Monthly Contribution</th>
                      <th scope="col" class="text-right">Options</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="user in users.data" :key="user.id">
                      <th scope="row" class="pl-2" v-if="user.sname === 'NA'">{{ user.lname }}, {{ user.fname }} {{ user.mname | midname }}</th>
                      <th scope="row" class="pl-2" v-else>{{ user.lname }}, {{ user.fname }} {{ user.mname | midname }}, {{ user.sname }}</th>
                      <td data-title="Photo" class="pl-2"><img alt="Avatar" class="img-circle elevation-2" height="25px" width="25px" :src="'img/profile/' + user.photo" /></td>
                      <td data-title="Username">{{ user.username }}</td>
                      <td data-title="Membership Status">{{ user.tapiea_membership }}</td>
                      <td data-title="Monthly Contribution">{{ user.tapiea_contribution }}</td>
                      <td>
                        <div class="btn-group btn-group-sm">
                          <button v-show="can_edit" class="btn btn-primary" type="button" @click="editModal(user)"><i class="fa fa-edit"></i></button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-show="users.total==0">
                      <tr><td colspan="10">No employees found.</td></tr>
                  </tbody>
                </table>
              </div>
              <!-- /.card-body -->
              <div class="card-footer">
                <pagination :data="users" @pagination-change-page="getResults" show-disabled :limit="2"></pagination>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="usermodal" tabindex="-1" role="dialog" aria-labelledby="userModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-md" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="userModalLabel"> Update Member Contribution</h5> 
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>

              <form @submit.prevent="updateTapieaUser()" @keydown="form.onKeydown($event)">

              <div class="modal-body">
              <div class="row">


                  <div class="col-md-6">
                      <!-- Your second column here -->

                     <div class="form-group">
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <span class="input-group-text">MONTHLY CONTRIBUTION</span>
                          </div>
                          <input v-model="form.tapiea_contribution" type="text" name="tapiea_contribution" autocomplete="off" placeholder="MONTHLY CONTRIBUTION" class="form-control" :class="{ 'is-invalid': form.errors.has('tapiea_contribution') }">
                          <has-error :form="form" field="tapiea_contribution"></has-error>
                        </div>
                      </div>

                  </div>

          
              </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                <button type="submit" class="btn btn-success">Update</button>
              </div>

              </form>

            </div>
          </div>
        </div>

        <div class="modal fade" id="sessionmodal" tabindex="-1" role="dialog" aria-labelledby="sessionModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered modal-md" role="document">
            <div class="modal-content">
              <div class="modal-header bg-orange">
                <h5 class="modal-title" id="sessionModalLabel"><i class="icon fas fa-exclamation-triangle"></i> Security Auto-logout</h5> 
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
              <div class="row-aligncenter">
                <div id="printPayslip2">
                  <div class="card-body">
                    You have left this browser idle for 10 minutes. Due to our data privacy policy, you will be 
                    automatically logged out in <b>{{ timer }}</b> seconds.
                  </div>
                </div>
              </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
        <span style="display:none;">{{ isIdle }}</span>



    </div>
</template>

<script>
    import Form from 'vform';

    export default {
        data() {
          return {
            timer: 30,
            editmode: false,
            emptyflag: false,
            users : {},
            profile : {},
            can_access: false,
            can_edit: false,
            form: new Form({
              id: '',
              tapiea_membership: '',
              tapiea_contribution: '',
            })
          }
        },
        methods: {

          countDownTimer() {
            let timerId = setInterval(() => {
              this.timer -= 1;
              if (!this.$store.state.idleVue.isIdle) clearInterval(timerId);

              if (this.timer < 1) {
                clearInterval(timerId);
                // Your logout function should be over here
                document.getElementById('logout-form').submit();
              }
            }, 1000);

            this.timer = 30;
          },

          getResults(page = 1) {
              let query = this.$parent.search;

              if(query == ''){
                axios.get('api/users?page=' + page).then(response => { this.users = response.data; }); 
              }else{
                axios.get('api/findUser?q=' + query + '&page=' + page).then(response => { this.users = response.data; }); 
              }
          },

           updateTapieaUser() {

            this.$Progress.start();

            this.form.put('api/profile/updatetapiea/'+this.form.id).then(()=>{

                    Trigger.$emit('RefreshData');
                    $('#usermodal').modal('hide')

                    toastr.fire({
                      icon: 'success',
                      title: 'Member info saved'
                    })

                    this.$Progress.finish();

                  }).catch(()=>{

                    toastr.fire({
                      icon: 'error',
                      title: 'Member updating failed'
                    });

                    this.$Progress.fail();

                  });
          },

          editModal(user) {
            this.editmode = true;
            this.form.reset();
            this.form.clear();
            $('#usermodal').modal('show');
            this.form.fill(user);
          },

          async loadPermission(mid) {
              const response = await axios.get("api/permission/" + this.$gate.showMyID() + "/" + mid).then( 
              res => {
                if (!res.data || res.data.length == 0) {
                  return false;
                } else {
                  return true;
                }
              })

              const getResponse = async () => {
                const a = await response;

                switch (mid) {
                case 10:
                  this.can_access = a;
                  if (!this.can_access) {
                    this.$router.push({ name: 'not-found' }) //redirect to not found
                  }
                  break;
                case 11:
                  this.can_edit = a;
                }
              };
              return getResponse()
          },

          loadUsers() {
              this.$Progress.start();
            
              let query = this.$parent.search;
              let page = this.users.current_page;
              
              if(query == ''){
                axios.get('api/users?page=' + page).then(({ data }) => (this.users = data));
              }else{
                axios.get('api/findUser?q=' + query + '&page=' + page).then(({ data }) => (this.users = data));
              }

              this.$Progress.finish();
          },





        },

        computed: {
          second() {
            return this.time / 1000;
          },

          isIdle() {
            const idlestat =  this.$store.state.idleVue.isIdle;
            if (idlestat == true) {
              $('#sessionmodal').modal('show');
              this.countDownTimer();
            } else {
              $('#sessionmodal').modal('hide');
            }

            return idlestat;

          },

        },

        created() {

          Trigger.$on('searching',() => {
            let query = this.$parent.search;
            let emptyflag = false;

            if(query == ''){
              Trigger.$emit('RefreshData');
            }else{
                this.$Progress.start();
                axios.get("api/findUser?q=" + query)
                .then((data) => {
                  this.users = data.data;
                  
                  if (data.data.total == 0){
                    //console.log("empty");
                    this.emptyflag = true;
                  }

                  this.$Progress.finish();
                })
                .catch(() => {
                  this.$Progress.fail();
                })
            }
            
          });


          axios.get("api/profile").then(({ data }) => (this.profile = data));
          this.loadUsers();
          this.loadPermission(10); //Can access TAPIEA
          this.loadPermission(11); //Can edit TAPIEA contribution
          

          Trigger.$on('RefreshData',() => {
            this.loadUsers();
          });
        }
    }


    
</script>